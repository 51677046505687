import log from 'server/utilities/logger';

const envStartsWith = (prefix) => (process.env.NMG_ENV || process.env.NODE_ENV || '').toLowerCase().startsWith(prefix);
const isProduction = () => envStartsWith('bgprod');
const isPrep = () => envStartsWith('bgprep');

export const getTealiumEnvironment = () => {
  if (isProduction() || isPrep()) {
    return 'prod';
  } else {
    return 'qa';
  }
};
