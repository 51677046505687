import React, { useState } from 'react';
import './index.scss';

const LoginInputField = ({
  id,
  type,
  name,
  placeholder,
  clearErrorMessage,
  value,
  onChange,
}) => {
  const isPassword = type === 'password';
  const mainClass = isPassword
    ? 'login-input-field-password'
    : 'login-input-field-general';

  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const inputType = isPassword && isPasswordVisible ? 'text' : type;
  const toggleShow = e => {
    e && e.preventDefault();
    setPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className={mainClass}>
      <label className={`${mainClass}__label`} htmlFor={id}>
        <input
          id={id}
          className={`${mainClass}__input`}
          type={inputType}
          name={name}
          placeholder=" "
          onFocus={clearErrorMessage}
          value={value}
          onChange={onChange}
        />
        <span className={`${mainClass}__placeholder`}>{placeholder}</span>
        {isPassword && (
          <span
            role="button"
            aria-hidden
            className={`${mainClass}__show-btn`}
            onMouseDown={toggleShow}
          >
            {isPasswordVisible ? 'Hide' : 'Show'}
          </span>
        )}
      </label>
    </div>
  );
};

export default LoginInputField;
