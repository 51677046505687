import React, { Fragment } from 'react';
import { bool, object } from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import merge from 'lodash/merge';
import remove from 'lodash/remove';
import Layout from './layout';
import {
  getAdditionalStyles,
  getAlignmentClass,
  getSpacingClass,
  getTags,
} from '../utils';
import {
  getLayoutSpacing,
  getHorizontalSpacing,
  getVerticalSpacing,
} from './utils';
import ContentItem from '../contentItem/contentItem';
import { defaultTrackingTags } from '../../constants/constants';

const removeLayoutsDrafts = subLayouts => {
  remove(subLayouts, item => !item.fields);
};

/**
 * @function L3Layout
 * @param {Object} props.cmsContentFields
 * @param {Boolean} props.isMobilePhone
 * @param {Boolean} props.isTablet
 * @param {Boolean} props.isDesktop
 * @param {Boolean} props.isNewHomePage
 * @param {String} props.placement
 * @param {Object} props.parentTrackingTags
 * @param {Boolean} props.sessionExpired
 * @param {Boolean} props.validPromo
 * @param {Boolean} props.isSpacingToggle
 * @returns {React.ReactElement}
 */
const L3Layout = ({
  cmsContentFields,
  cmsLayout,
  isMobilePhone,
  isTablet,
  isDesktop,
  isNewHomePage,
  parentTrackingTags,
  sessionExpired,
  validPromo,
  isSpacingToggle,
}) => {
  const type = cmsLayout.contentType.toLowerCase();
  const widthAttribute = isMobilePhone
    ? cmsContentFields.mobileWidth
    : cmsContentFields.desktopWidth;
  const spacingStyles = isSpacingToggle
    ? getLayoutSpacing(cmsContentFields, isMobilePhone, isTablet, isDesktop)
    : '';
  const horizontalSpacingStyles = isSpacingToggle
    ? getHorizontalSpacing(cmsContentFields, isMobilePhone, isTablet, isDesktop)
    : '';
  const verticalSpacingStyles = isSpacingToggle
    ? getVerticalSpacing(cmsContentFields, isMobilePhone, isTablet, isDesktop)
    : '';
  const topStyles = spacingStyles.topStyles ? spacingStyles.topStyles : '';
  const layoutWidth = widthAttribute ? `width-${widthAttribute}` : 'width-100';
  const subLayoutName = `l${+cmsLayout.contentType.substring(1, 2) + 1}Layout`;
  const horizontalStyles = horizontalSpacingStyles.horizontalStyles
    ? horizontalSpacingStyles.horizontalStyles
    : '';
  const verticalStyles =
    verticalSpacingStyles && verticalSpacingStyles.verticalStyles
      ? verticalSpacingStyles.verticalStyles
      : '';

  const subLayouts = get(cmsContentFields, subLayoutName, []);
  const contentItems = get(cmsContentFields, 'components', []);

  removeLayoutsDrafts(subLayouts);

  const subLayoutWidth = subLayouts.reduce(
    (acc, item) =>
      acc +
      parseInt(
        isMobilePhone ? item.fields.mobileWidth : item.fields.desktopWidth,
        10,
      ),
    0,
  );

  const setGrid =
    subLayouts.length !== 1
      ? subLayouts.reduce(
          (reducer, item) => reducer || !!item.fields.components,
          false,
        )
      : false;

  const layoutClassName = `${type.substring(0, 2)}-${type.substring(
    2,
    type.length,
  )} ${
    setGrid ? 'layout-space' : 'layout-center'
  } ${layoutWidth} ${topStyles} ${horizontalStyles} ${verticalStyles}`;

  const alignmentClass = getAlignmentClass(cmsContentFields);
  const spacingClass = getSpacingClass(cmsContentFields);
  const sublayoutMultirowClass =
    subLayoutWidth > 100 ? 'sublayout-multirow' : '';
  const additionalStyles = /l[23]layout/.test(type)
    ? getAdditionalStyles(cmsContentFields)
    : {};

  // Set up Tracking Tags
  const trackingTags = get(cmsContentFields, 'trackingTags', []);
  const currentTags = getTags(trackingTags);
  const mergedTrackingTags = merge({}, parentTrackingTags, currentTags);

  return (
    <div
      className={classNames(
        layoutClassName,
        alignmentClass,
        spacingClass,
        sublayoutMultirowClass,
      )}
      style={{ ...additionalStyles }}
    >
      {!!subLayouts.length && (
        <Fragment>
          {subLayouts.map(item => (
            <Layout
              key={item.sys.id}
              cmsLayout={item}
              isMobilePhone={isMobilePhone}
              isNewHomePage={isNewHomePage}
              parentTrackingTags={mergedTrackingTags}
              sessionExpired={sessionExpired}
              validPromo={validPromo}
            />
          ))}
        </Fragment>
      )}
      {!subLayouts.length &&
        contentItems.map(item => (
          <div className="component content-layout" key={item.sys.id}>
            <ContentItem
              cmsContentItem={item}
              isMobilePhone={isMobilePhone}
              parentTrackingTags={mergedTrackingTags}
              sessionExpired={sessionExpired}
              validPromo={validPromo}
            />
          </div>
        ))}
    </div>
  );
};

L3Layout.defaultProps = {
  parentTrackingTags: defaultTrackingTags,
};

L3Layout.propTypes = {
  cmsContentFields: object,
  isMobilePhone: bool,
  isTablet: bool,
  isDesktop: bool,
  isNewHomePage: bool,
  parentTrackingTags: object,
  sessionExpired: bool,
  validPromo: bool,
  isSpacingToggle: bool,
};

export default L3Layout;
