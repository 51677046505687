import $ from 'jquery';
import React from 'react';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import { closeModal } from 'shared/components/Modal/actions';
import Button from 'shared/components/Button/button';
import './addToBagMobileModal.scss';

const getImageUrl = (imageUrl = '') => {
  if (imageUrl.indexOf(NMConfig.SCENE7_URL) !== -1) {
    return imageUrl;
  }
  return `http://bergdorfgoodman.com${imageUrl}`;
};

const getProductHtmls = content => {
  return $(content)
    .find('tr')
    .map((index, row) => {
      const $row = $(row);
      const $img = $row.find('img');
      return {
        content: $row.find('td').get(0).innerHTML,
        image: {
          url: getImageUrl($img.attr('src')),
          alt: $img.attr('alt'),
        },
      };
    })
    .toArray();
};

const AddtoBagText = totalQuantity => {
  return totalQuantity > 1
    ? ' items in your shopping bag'
    : ' item in your shopping bag';
};

const AddToBagMobileModal = ({
  closeModal,
  content,
  totalQuantity,
  securityStatus,
}) => {
  let checkoutUrl = NMConfig.CHECKOUT_PAGE_URL;
  if (securityStatus && securityStatus.toLowerCase() === 'authenticated') {
    checkoutUrl = NMConfig.ORDER_REVIEW_URL;
  }
  const handleClick = e => {
    e.preventDefault();
    closeModal();
  };

  return (
    <div className="addToBagMobileModal">
      <div>
        <div>
          <h2 className="add-to-bag-title">Added to your shopping bag</h2>
          <hr className="title-separator" />
        </div>
        {getProductHtmls(content).map((product, index) => (
          <div key={index} className="mobile-grid-100 productInfo">
            <div className="mobile-grid-25 image-wrapper">
              <img
                src={product.image.url}
                alt={product.image.alt}
                className="product-image"
              />
            </div>
            <div className="mobile-grid-75 description-wrapper">
              {renderHTML(product.content)}
            </div>
          </div>
        ))}
      </div>
      <div id="total-quantity" className="mobile-grid-100">
        {totalQuantity}
        {AddtoBagText(totalQuantity)}
      </div>
      <div id="checkout-button" className="button-wrapper">
        <a href={checkoutUrl}>
          <Button value="CHECKOUT" className="checkout-label" />
        </a>
        <Button
          secondary
          value="Continue Shopping"
          className="secondary-button"
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  totalQuantity: state.miniCart.noOfItemsInCart,
  content: state.modal.content,
  securityStatus: state.user.securityStatus,
});
const mapDispatchToProps = { closeModal };
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddToBagMobileModal);
