import React from 'react';
import classNames from 'classnames';
import { Field } from 'formik';

import './FormikCardField.scss';

const FormikCardField = ({ id, placeholder, name, cardType, fontSize }) => {
  return (
    <Field name={name}>
      {({ field }) => {
        const placeholderClassName = classNames('form-pre-select__placeholder');
        const inputClassName = classNames(
          'form-pre-select__input disable',
          'read-only',
          fontSize && 'inputFont',
        );
        return (
          <div className="form-field form-pre-select">
            <label className="form-pre-select__label" htmlFor={id}>
              <input
                id={id}
                className={inputClassName}
                type="text"
                placeholder=" "
                value={field.value}
                name={field.name}
                readOnly
              />
              <span className={placeholderClassName}>{placeholder}</span>
              {cardType && (
                <div
                  className={`card-info__logo-wrapper ${cardType
                    .toLowerCase()
                    .replace(/'|\s/g, '')}`}
                />
              )}
            </label>
          </div>
        );
      }}
    </Field>
  );
};

export default FormikCardField;
