import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import get from 'lodash/get';
import {
  loseFocus,
  putComponentInFocus,
} from 'shared/components/App/app-actions'; // 'client/common/components/App/app-actions';
import { ESC_KEYCODE, ENTER_KEYCODE } from 'client-utils/keyCodes';
import { BodyB1 } from '@bgo-ui/common/Styleguide/Typography';
import { openSignInModal } from 'bgo-ui-home/src/client/components/login/actions';
import { AccountMenuItems } from '../../AccountMenuItems/AccountMenuItems';
import './AccountHeaderMenuItem.scss';

const accountDropdownClasses = isAuthenticated =>
  classnames('desktop-tablet-only', {
    'loggedIn-user-account-menu-item': isAuthenticated,
    'anonymous-user-account-menu-item': !isAuthenticated,
  });

const onFocusOut = toggleMenuOff => e => {
  if (e.currentTarget !== e.relatedTarget.offsetParent) {
    toggleMenuOff('.account-header-menu-item');
  }
};
const AccountDropdownMenu = ({
  authenticatedUserName,
  toggleMenuOff,
  shouldShowSpecialOffersLink,
  isDomestic,
  disableATGToggle,
}) => (
  <div
    className={accountDropdownClasses(authenticatedUserName)}
    onBlur={onFocusOut(toggleMenuOff)}
  >
    <AccountMenuItems
      authenticatedUserName={authenticatedUserName}
      shouldShowSpecialOffersLink={shouldShowSpecialOffersLink}
      isDomestic={isDomestic}
      disableATGToggle={disableATGToggle}
    />
  </div>
);

const onAccountMenuKeyDown = (
  e,
  { toggleMenuOff, toggleMenuOn, isVisible },
) => {
  if (isVisible && e.which !== ESC_KEYCODE) return;
  if (e.which === ESC_KEYCODE) {
    toggleMenuOff('.account-header-menu-item');
  } else if (e.which === ENTER_KEYCODE) {
    toggleMenuOn('.account-header-menu-item');
    e.preventDefault();
  }
};

const SignInRegister = props => (
  <div
    className="account-header-menu-item"
    onKeyDown={e => onAccountMenuKeyDown(e, props)}
  >
    <SignInRegisterDesktop {...props} />
    {/* <Toggle feature="HOMEPAGE_FULL_WIDTH" fallback={<SignInRegisterTablet />}>
      <SignInRegisterForDevice {...props}/>
    </Toggle> */}
  </div>
);

const SignInRegisterDesktop = ({
  isVisible,
  toggleMenuOff,
  isFullWidth,
  showWishlist,
  dtSignIn,
  openSignInModal,
  isLoyaltyEnabled: loyEnabled,
}) => {
  const isLoyaltyEnabled = loyEnabled;
  const Tag = isLoyaltyEnabled ? 'span' : 'a';
  return (
    <div className="desktop-only">
      <Tag
        className="account-header-menu-item__signin-register-link desktop-only"
        href={dtSignIn ? '/e/login' : NMConfig.ACCOUNT_PAGE_URL}
      >
        <BodyB1
          onClick={() => isLoyaltyEnabled && openSignInModal()}
          isLoyaltyEnabled={isLoyaltyEnabled}
          className={isLoyaltyEnabled ? 'signin' : ''}
        >
          Sign in
        </BodyB1>
        {/* <span className="account-header-menu-item__down-arrow-container">
            <img src={String(arrowDownGrey)} className="account-header-menu-item__down-arrow arrow-grey" alt="" />
            <img src={String(arrowDown)} className="account-header-menu-item__down-arrow arrow-purple" alt="" />
        </span> */}
      </Tag>
      {showWishlist && (
        <a
          className="account-header-menu-item__signin-register-link desktop-only"
          href={NMConfig.WISHLIST_PAGE_URL}
        >
          Wishlist
        </a>
      )}
      {isVisible ? (
        <AccountDropdownMenu
          authenticatedUserName={false}
          toggleMenuOff={toggleMenuOff}
          isFullWidth={isFullWidth}
        />
      ) : (
        undefined
      )}
    </div>
  );
};

const YourAccount = ({
  showWishlist,
  myAccountRewardsLength,
  handleMiniHubToggle,
  loyaltyProfileId,
  loyMiniHubToggle,
}) => {
  // const usersFirstName = sanitizeUserName(firstName);
  // const touchToggle = isVisible ? () => toggleMenuOff('.account-header-menu-item') : () => toggleMenuOn('.account-header-menu-item');
  const renderMyAccount = () => {
    return (
      <BodyB1>
        {'My Account'}
        {myAccountRewardsLength > 0 && (
          <span className="account-header-menu-item__notify">
            {myAccountRewardsLength}
          </span>
        )}
      </BodyB1>
    );
  };

  return (
    <div>
      {loyaltyProfileId && loyMiniHubToggle ? (
        <span
          className="account-header-menu-item__loggedin-user-link desktop-only"
          onClick={handleMiniHubToggle}
          onKeyDown={handleMiniHubToggle}
        >
          {renderMyAccount()}
        </span>
      ) : (
        <a
          className="account-header-menu-item__loggedin-user-link desktop-only"
          href={`${NMConfig.ACCOUNT_PAGE_URL}?view=history`}
        >
          {renderMyAccount()}
        </a>
      )}
      {showWishlist && (
        <a
          className="account-header-menu-item__signin-register-link desktop-only"
          href={NMConfig.WISHLIST_PAGE_URL}
        >
          Wishlist
        </a>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const isDomestic =
    get(state, 'locale.countryCode') === 'US' ||
    get(state, 'locale.countryCode') === 'Unites_States';
  return {
    isDomestic,
    shouldShowSpecialOffersLink: get(
      state,
      'toggles.WKY_LINK_TO_SPECIAL_OFFERS_PAGE',
      false,
    ),
    isVisible: state.app.componentInFocus === '.account-header-menu-item',
    showWishlist: get(state, 'toggles.BG_PHASE_2_WISHLIST', false),
    dtSignIn: get(state, 'toggles.DT_SIGN_IN', false),
    myAccountRewardsLength: get(state, 'rewards.length', 0),
    disableATGToggle: get(state, 'toggles.DISABLE_ATG_CALLS', false),
    isLoyaltyEnabled: get(state, 'toggles.LOYALTY', false),
    loyMiniHubToggle: get(state, 'toggles.LOY_MINI_HUB', false),
  };
};

const mapDispatchToProps = {
  toggleMenuOn: putComponentInFocus,
  toggleMenuOff: loseFocus,
  openSignInModal,
};

export const ConnectedSignInRegister = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignInRegister);
export const ConnectedYourAccount = connect(
  mapStateToProps,
  mapDispatchToProps,
)(YourAccount);
