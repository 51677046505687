import React from 'react';
import './bopsErrorForReplenishment.scss';

export const BOPS_NO_SELECTION_ERR_MSG_FOR_REPLENISHMENT =
  'Please choose a frequency for the product ';

const BopsNoSelectionErrorForReplenishment = props => {
  return props.isError ? (
    <div className="bopsErrorForReplenishment">
      <p>
        {BOPS_NO_SELECTION_ERR_MSG_FOR_REPLENISHMENT}
        {props.name}.
      </p>
    </div>
  ) : null;
};

export default BopsNoSelectionErrorForReplenishment;
