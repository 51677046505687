import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { Link } from 'react-router';
import './NewAccountAccessLayout.scss';
import SubmitButton from '../../../../common/Forms/FormButtons/SubmitButton/SubmitButton';
import SignInRegisterSwitcher from '../../common/SignInRegisterSwitcher/SignInRegisterSwitcher';
import TermsDisclaimer from '../../common/TermsDisclaimer/TermsDisclaimer';
import NewForgotPassword from '../../forgotPassword/NewForgotPassword/NewForgotPassword';
import { resolvedForgotPasswordPage } from '../../../actions';

const LoginInputField = ({
  id,
  type,
  name,
  placeholder,
  clearErrorMessage,
}) => {
  const isPassword = type === 'password';
  const mainClass = isPassword
    ? 'account-access-new-password-field'
    : 'account-access-new-input-field';

  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const inputType = isPassword && isPasswordVisible ? 'text' : type;
  const toggleShow = e => {
    e && e.preventDefault();
    setPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className={mainClass}>
      <label className={`${mainClass}__label`} htmlFor={id}>
        <input
          id={id}
          className={`${mainClass}__input`}
          type={inputType}
          name={name}
          placeholder=" "
          onFocus={clearErrorMessage}
        />
        <span className={`${mainClass}__placeholder`}>{placeholder}</span>
        {isPassword && (
          <span
            role="button"
            aria-hidden
            className={`${mainClass}__show-btn`}
            onMouseDown={toggleShow}
          >
            {isPasswordVisible ? 'Hide' : 'Show'}
          </span>
        )}
      </label>
    </div>
  );
};

const NewAccountAccessLayout = ({
  onSubmit,
  loading,
  displayError,
  errorMessage,
  clearErrorMessage,
  location = {},
  resolvedForgotPasswordPage,
  isLoyaltyEnabled,
  handleTabSwitch = () => {},
}) => {
  const [isForgotPassword, setForgotPassword] = useState(false);

  useEffect(() => {
    window.sessionStorage.setItem('login_btn_clicked', 'false');
    const shouldOpenForgotPasswordModal =
      get(location, 'query.forgot-password') === 'true';
    if (shouldOpenForgotPasswordModal) {
      setForgotPassword(true);
    }
  }, []);
  useEffect(() => {
    if (isForgotPassword) {
      resolvedForgotPasswordPage();
    }
  }, [isForgotPassword]);

  if (isForgotPassword) {
    return <NewForgotPassword setForgotPassword={setForgotPassword} />;
  }

  const loyaltyInfo = [
    'Earn points on future purchases',
    'Receive personalized offers',
    'Access to exclusive items and events',
    'Checkout faster and enjoy a more personalized experience',
  ];

  const LoyaltyBenefits = ({ benefits }) =>
    benefits.map(data => (
      <div className="loyalty-benefits">
        <span aria-hidden className="password-requirements-box__icon-container">
          <i className="password-requirements-box__icon" />
        </span>
        <span className="point">{data}</span>
      </div>
    ));

  return (
    <div className="account-access-new">
      <SignInRegisterSwitcher
        currentTab="login"
        isLoyaltyEnabled={isLoyaltyEnabled}
        handleTabSwitch={handleTabSwitch}
      />
      <form onSubmit={onSubmit}>
        <LoginInputField
          id="sign-in-email-input"
          type="email"
          name="email"
          placeholder="Email"
          clearErrorMessage={clearErrorMessage}
        />
        <LoginInputField
          id="sign-in-password-input"
          type="password"
          name="password"
          placeholder="Password"
          clearErrorMessage={clearErrorMessage}
        />
        {displayError && (
          <div className="account-access-new__sign-in-error">
            {errorMessage}
          </div>
        )}
        <p className="account-access-new__forgot-password">
          <span
            className="account-access-new__forgot-password-link"
            onClick={setForgotPassword}
          >
            Forgot Password?
          </span>
        </p>
        <SubmitButton
          additionalClasses={`account-access-new__submit-btn ${
            isLoyaltyEnabled ? 'light' : ''
          }`}
          isSubmitting={loading}
          caption="Sign in"
          type="submit"
        />
        <div className="account-access-new__kmsi-container">
          <label
            htmlFor="sign-in-kmsi-checkbox"
            className="account-access-new__kmsi-checkbox"
          >
            <input
              id="sign-in-kmsi-checkbox"
              className="account-access-new__kmsi-checkbox-input"
              name="kmsi"
              type="checkbox"
              defaultChecked
            />
            <span className="account-access-new__kmsi-checkbox-label">
              Keep me signed in
            </span>
          </label>
        </div>
        <TermsDisclaimer />
        <div className="account-access-new__register-link">
          {isLoyaltyEnabled ? (
            <span>New to Bergdorf Goodman? register with us</span>
          ) : (
            <a href="/e/register">New to Bergdorf Goodman? Register</a>
          )}
        </div>
        {isLoyaltyEnabled ? (
          <div className="loyalty-info">
            <div className="benefits">
              <LoyaltyBenefits benefits={loyaltyInfo} />
            </div>
            <button
              className="form-buttons__submit-btn account-access-new__submit-btn"
              type="button"
            >
              <Link to="/e/register">
                <span className="redirect">Create an account</span>
              </Link>
            </button>
          </div>
        ) : (
          ''
        )}
      </form>
    </div>
  );
};

const mapStateToProps = state => ({
  isLoyaltyEnabled: state.toggles.LOYALTY,
});

const mapDispatchToProps = {
  resolvedForgotPasswordPage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewAccountAccessLayout);
