import React, { Component, Fragment } from 'react';
import ErrorMessage from 'productpage/components/ErrorMessage/errorMessage';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { getSelectedCustomizationsAllTypesById } from 'pdp/components/ProductPage/reducers/productReducer';
import { setSelectedCustomizationObjectById } from 'pdp/components/ProductPage/actions';

export class DumbUpperCaseNoNumScInput extends Component {
  constructor(props) {
    super(props);

    this.getErrorMessage = this.getErrorMessage.bind(this);
  }

  componentDidMount() {
    const {
      id = '',
      touched = false,
      isValid = false,
      legacyType = '',
    } = this.props.selectedCustomizationData;
    switch (legacyType) {
      case 'single initial':
        this.props.setSelectedCustomizationObjectById({
          id,
          isValid,
          selectedValues:
            this.props.selectedCustomizationData &&
            this.props.selectedCustomizationData.selectedValues
              ? [...this.props.selectedCustomizationData.selectedValues]
              : [''],
          touched,
        });
        break;
      case 'two initials':
        this.props.setSelectedCustomizationObjectById({
          id,
          isValid,
          selectedValues:
            this.props.selectedCustomizationData &&
            this.props.selectedCustomizationData.selectedValues
              ? [...this.props.selectedCustomizationData.selectedValues]
              : ['', ''],
          touched,
        });
        break;
      default:
        this.props.setSelectedCustomizationObjectById({
          id,
          isValid,
          selectedValues:
            this.props.selectedCustomizationData &&
            this.props.selectedCustomizationData.selectedValues
              ? [...this.props.selectedCustomizationData.selectedValues]
              : ['', ''],
          touched,
        });
    }
  }

  getErrorMessage(initial) {
    const {
      touched = false,
      isValid = false,
      selectedValues = [''],
    } = this.props.selectedCustomizationData;
    const allValues = selectedValues && selectedValues.join('');
    const validLength = allValues.length === initial;
    if (touched && !validLength && !isValid) {
      return 'Enter required information.';
    } else if (touched && !isValid && validLength) {
      return 'Please use letters only, special characters are not allowed.';
    }
    return '';
  }

  handleChange(event) {
    const { selectedValues } = this.props.selectedCustomizationData;

    const { name } = event.target;
    const newValue = this.toCamelCase(event.target.value);
    const trimmed = this.trimSpaceBefore(newValue);
    const { inputLength } = this.props.option.choices[0];

    if (trimmed.length <= inputLength) {
      selectedValues[name] = trimmed;

      this.props.setSelectedCustomizationObjectById({
        id: this.props.selectedCustomizationData.id,
        isValid: this.isValid(),
        selectedValues,
        touched: true,
      });
    }
  }

  trimSpaceBefore(str) {
    return str.replace(/^\s+/g, '');
  }

  isValid() {
    const {
      selectedValues = [''],
      legacyType = '',
    } = this.props.selectedCustomizationData;
    const allValues = selectedValues.join('');
    const isValidated = this.validateText(allValues);

    switch (legacyType) {
      case 'single initial':
        return allValues.length === 1 && isValidated && this.isASCII(allValues);
      case 'two initials':
        return allValues.length === 2 && isValidated && this.isASCII(allValues);
      default:
        return false;
    }
  }

  toCamelCase(str) {
    return str.replace(/\w\S*/g, txt => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  isASCII(str) {
    return /^[\x00-\x7F]*$/.test(str);
  }

  validateText(value) {
    const regex = /^[a-zA-Z\s]*$/;
    return regex.test(value);
  }

  constructInitials() {
    const { displayName, legacyType } = this.props.option;
    const { inputLength } = this.props.option.choices[0];
    const { selectedValues = ['', ''] } = this.props.selectedCustomizationData;

    switch (legacyType) {
      case 'single initial':
        return (
          <div className="monogram-text-dmg__user-input__initials">
            <legend>{displayName}</legend>
            <input
              name="0"
              type="text"
              value={selectedValues[0] || ''}
              maxLength={1}
              onChange={e => this.handleChange(e)}
            />
            <span className="monogram-text-dmg__character">(1 Character)</span>
            <ErrorMessage message={this.getErrorMessage(1)} />
          </div>
        );
      case 'two initials': {
        let className = 'monogram-text-dmg__user-input__initials';
        const errorMessage = this.getErrorMessage(2);
        if (!isEmpty(errorMessage)) {
          className += ' form-error';
        }
        return (
          <div className={className}>
            <legend>{displayName}</legend>
            <input
              name="0"
              type="text"
              value={selectedValues[0] || ''}
              maxLength={1}
              onChange={e => this.handleChange(e)}
            />
            <input
              name="1"
              type="text"
              value={selectedValues[1] || ''}
              maxLength={1}
              onChange={e => this.handleChange(e)}
            />
            <span className="monogram-text-dmg__character">(2 Characters)</span>
            <ErrorMessage message={errorMessage} />
          </div>
        );
      }
      default:
        return (
          <Fragment>
            <legend>{displayName}</legend>
            <input
              type="text"
              value={selectedValues[0] || ''}
              maxLength={inputLength}
              onChange={e => this.handleChange(e)}
            />
            <span className="monogram-text-dmg__character">
              ({inputLength} Characters)
            </span>
            <ErrorMessage message={this.getErrorMessage(2)} />
          </Fragment>
        );
    }
  }

  render() {
    return this.constructInitials();
  }
}

const mapStateToProps = (state, props) => {
  return {
    selectedCustomizationData: getSelectedCustomizationsAllTypesById(
      state,
      props,
    ),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setSelectedCustomizationObjectById: (...args) =>
    dispatch(setSelectedCustomizationObjectById(ownProps.productId, ...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbUpperCaseNoNumScInput);
