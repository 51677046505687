import isEmpty from 'lodash/isEmpty';
import { create } from 'axios';
import uuid from 'uuid/v4';
// eslint-disable-next-line import/no-cycle
import { extractLogData } from 'universal/xhrLogFormatter';
import logger, { X_NMO_TRACE_ID_NAME } from 'server/utilities/logger';
import get from 'lodash/get';

export const X_FEATURE_TOGGLES_HEADER_NAME = 'x-feature-toggles';
export const X_ABTEST_INFO = 'x-abt';
export const X_DEVICE_TYPE = 'x-device';
export const X_PAGE_EXPERIENCE = 'x-page-exp';
export const VISITORID = 'visitorId';

const createApi = (context = {}, timeout = 30000, toggles = {}) => {
  let startTime = null;
  const startApiTime = new Date();
  const api = create({ timeout });

  api.interceptors.request.use(
    config => {
      if (!context.isHeadersPredefined) {
        if (toggles && toggles.NO_TOGGLES_HEADER) {
          if (!IS_CLIENT) {
            config.headers = {
              ...config.headers,
              [X_NMO_TRACE_ID_NAME]: context[X_NMO_TRACE_ID_NAME] || uuid(),
              [X_FEATURE_TOGGLES_HEADER_NAME]:
                config.headers[X_FEATURE_TOGGLES_HEADER_NAME] ||
                JSON.stringify(toggles),
              [X_PAGE_EXPERIENCE]: config.headers[X_PAGE_EXPERIENCE] || '',
            };
          } else {
            config.headers = {
              ...config.headers,
              [X_NMO_TRACE_ID_NAME]: context[X_NMO_TRACE_ID_NAME] || uuid(),
            };
          }
        } else {
          config.headers = {
            ...config.headers,
            [X_NMO_TRACE_ID_NAME]: context[X_NMO_TRACE_ID_NAME] || uuid(),
            [X_FEATURE_TOGGLES_HEADER_NAME]:
              config.headers[X_FEATURE_TOGGLES_HEADER_NAME] ||
              JSON.stringify(toggles),
            [X_PAGE_EXPERIENCE]: config.headers[X_PAGE_EXPERIENCE] || '',
          };
        }
      }
      startTime = new Date();
      logger.debug('HTTP Request (internal)', extractLogData(context, config));
      return config;
    },
    error => {
      logger.error(
        `HTTP Request Error (internal): ${error.message}`,
        extractLogData(context, error.config, error.request, error.response),
      );
      return Promise.reject(error);
    },
  );

  api.interceptors.response.use(
    response => {
      logger.info('HTTP Response (internal)', {
        ...extractLogData(
          context,
          response.config,
          response.request,
          response,
          startTime,
        ),
        TemplateType: get(response.data, 'templateType', '-'),
      });
      logger.info(
        `Response time for ${response.config.url} is  ${new Date() -
          startApiTime}`,
      );
      return Promise.resolve(response);
    },
    error => {
      logger.error(
        `HTTP Response Error (internal): ${error.message}`,
        extractLogData(context, error.config, error.request, error.response),
      );
      return Promise.reject(error);
    },
  );
  return api;
};

const httpWithLogging = (
  state,
  timeout = 3000,
  isHeadersPredefined = false,
  route,
) => {
  let { toggles } = state;
  let isProductListingAPI = false;
  if (route?.length) {
    const [routeName = ''] = (route.split && route.split('/').reverse()) || [];
    isProductListingAPI = routeName?.toLowerCase() === 'productlisting';
  }
  if (state?.toggles?.NO_TOGGLES_HEADER || isProductListingAPI) {
    toggles = state.serviceToggles;
  }
  return createApi(
    {
      ...state.user,
      ...state.session,
      isHeadersPredefined,
    },
    timeout,
    toggles,
  );
};

export default httpWithLogging;

export function apiForHttpRequest(timeout = 3000) {
  return createApi({}, timeout);
}

export const httpWithLoggingWithoutCustomHeaders = (
  state,
  timeout = 3000,
  isHeadersPredefined = false,
) =>
  createApi(
    {
      ...state.user,
      ...state.session,
      isHeadersPredefined,
    },
    timeout,
    state.toggles,
    false,
  );

export const getSessionInfo = (cookies = {}) => {
  return {
    user: {},
    session: {
      JSESSIONID: cookies.JSESSIONID || '',
      DYN_USER_ID: cookies['DYN_USER_ID'] || '',
      TLTSID: cookies['TLTSID'] || '',
      W2A: cookies['W2A'] || '',
      uuid: cookies['__ruid'] || cookies.uuid || '',
      blue: true,
    },
  };
};

export function shouldLoad(status = {}) {
  return (
    isEmpty(status) || (!status.loading && !status.resolved && !status.rejected)
  );
}
