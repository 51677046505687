import React from 'react';
import { connect } from 'react-redux';
import { isObject } from 'util';
import get from 'lodash/get';
import {
  MIN_DESKTOP_WIDTH,
  MIN_TABLET_WIDTH,
  MAX_TABLET_WIDTH,
  MIN_MOBILE_WIDTH,
  MAX_MOBILE_WIDTH,
} from 'bgo-common/client/common/constants';

const generateSrcSetString = srcSetArray => {
  if (!srcSetArray || srcSetArray.length === 0) {
    return null;
  }

  return srcSetArray.map(item => `${item.src} ${item.rule}`).join(', ');
};

const ResponsiveImage = ({
  alt,
  title,
  error,
  name,
  src,
  dataSrc,
  onMouseOver,
  onMouseOut,
  onClick,
  isCloudinary,
  className,
  dynamicDprToggle,
}) => {
  const imageSrc = src || dataSrc;
  if (!isCloudinary || !isObject(imageSrc)) {
    return (
      <img
        src={imageSrc}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        alt={alt}
        title={title}
        onError={error}
        name={name}
        onClick={onClick}
        className={className}
        onKeyPress={e => {
          e.key === 'Enter' && onClick();
        }}
        tabIndex="0"
      />
    );
  } else {
    const desktopSrc = get(imageSrc, 'desktop.imageSrc', '');
    const tabletSrc = get(imageSrc, 'tablet.imageSrc', '');
    const mobileSrc = get(imageSrc, 'mobile.imageSrc', '');

    let desktopSrcSetArray = null;
    let tabletSrcSetArray = null;
    let mobileSrcSetArray = null;

    if (dynamicDprToggle) {
      desktopSrcSetArray = get(imageSrc, 'desktop.srcSet', []);
      tabletSrcSetArray = get(imageSrc, 'tablet.srcSet', []);
      mobileSrcSetArray = get(imageSrc, 'mobile.srcSet', []);
    }

    const desktopSrcSet =
      generateSrcSetString(desktopSrcSetArray) || desktopSrc;
    const tabletSrcSet = generateSrcSetString(tabletSrcSetArray) || tabletSrc;
    const mobileSrcSet = generateSrcSetString(mobileSrcSetArray) || mobileSrc;

    return (
      <picture>
        <source
          media={`(min-width: ${MIN_DESKTOP_WIDTH}px)`}
          srcSet={`${desktopSrcSet}`}
        />
        <source
          media={`(min-width: ${MIN_TABLET_WIDTH}x) and (max-width: ${MAX_TABLET_WIDTH}px)`}
          srcSet={`${tabletSrcSet}`}
        />
        <source
          media={`(min-width: ${MIN_MOBILE_WIDTH}px) and (max-width: ${MAX_MOBILE_WIDTH}px)`}
          srcSet={`${mobileSrcSet}`}
        />
        <img
          src={`${desktopSrc}`}
          srcSet={`${desktopSrcSet}`}
          alt={alt}
          title={title}
          onError={error}
          name={name}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          onClick={onClick}
          className={className}
          tabIndex="0"
          onKeyPress={e => {
            e.key === 'Enter' && onClick();
          }}
        />
      </picture>
    );
  }
};

const mapStateToProps = state => {
  return {
    dynamicDprToggle: get(state, 'toggles.CLOUDINARY_DYNAMIC_DPR', false),
  };
};

export default connect(mapStateToProps)(ResponsiveImage);
