import toInteger from 'lodash/toInteger';

const noop = Function.prototype;
// eslint-disable-next-line import/no-mutable-exports
let mark = noop,
  measure = noop,
  clearMarks = noop,
  clearMeasures = noop,
  getEntriesByName = noop,
  getEntriesByType = noop,
  updateMark = noop,
  clearStartMark = noop,
  markStart = noop,
  markEnd = noop,
  markStartTTD = noop,
  markTTD = noop,
  measureTTD = noop,
  markTTI = noop,
  measureTTI = noop;
let wasMarked = noop;
let wasMeasured = noop;
const validatePerformanceLogLevel = (perfLogLevel) => {
  let level = toInteger(perfLogLevel);
  if (level < 0) {
    level = 0;
  }
  if (level > 5) {
    level = 5;
  }
  return level;
};
let performanceLogLevel = validatePerformanceLogLevel(NMConfig.PERF_LOG_LEVEL);
let isPerormanceUtilitiesEnabled = performanceLogLevel > 0;
let limitMarkEntries = true;
let limitMeasureEntries = true;
const markPrefix = 'mark_NM_';
const measurePrefix = 'measure_NM_';
const hasPerformance = typeof window !== 'undefined' && window.performance;
const hasUserTimingAPI = hasPerformance &&
    typeof window.performance.mark === 'function' &&
    typeof window.performance.measure === 'function' &&
    typeof window.performance.clearMarks === 'function' &&
    typeof window.performance.clearMeasures === 'function' &&
    typeof window.performance.getEntriesByName === 'function' &&
    typeof window.performance.getEntriesByType === 'function';
const locationSearchProperty = typeof window !== 'undefined' ? window.location.search : '';
const hasPerfFlag = hasUserTimingAPI && /[?&]perf(?=&|$)/.test(locationSearchProperty);
const hasPerfLogLevelFlag = hasUserTimingAPI && /[?&]PERF_LOG_LEVEL=([0-9])(?=&|$)/.test(locationSearchProperty);

const formatStartMarkName = (markName) => {
  return `${markPrefix}${markName}_start`;
};
const formatMeasureName = (measureName) => {
  return `${measurePrefix}${measureName}`;
};
const formatStartTTDMarkName = (markName) => {
  return `${markPrefix}TTD_${markName}_start`;
};
const formatTTDMarkName = (markName) => {
  return `${markPrefix}TTD_${markName}`;
};
const formatTTDMeasureName = (measureName) => {
  return `${measurePrefix}TTD_${measureName}`;
};
const formatTTIMarkName = (markName) => {
  return `${markPrefix}TTI_${markName}`;
};
const formatTTIMeasureName = (measureName) => {
  return `${measurePrefix}TTI_${measureName}`;
};

const disablePerformanceUtilities = () => {
  mark = noop;
  measure = noop;
  clearMarks = noop;
  clearMeasures = noop;
  getEntriesByName = noop;
  getEntriesByType = noop;
  updateMark = noop;
  clearStartMark = noop;
  markStart = noop;
  markEnd = noop;
  markStartTTD = noop;
  markTTD = noop;
  measureTTD = noop;
  markTTI = noop;
  measureTTI = noop;
  wasMarked = noop;
  wasMeasured = noop;
  isPerormanceUtilitiesEnabled = false;
};

const enablePerformanceUtilities = () => {
  if (hasUserTimingAPI && performanceLogLevel > 0) {
    if (performanceLogLevel > 2) {
      limitMeasureEntries = false;
    }
    if (performanceLogLevel > 4) {
      limitMarkEntries = false;
    }
    mark = window.performance.mark.bind(window.performance);
    measure = window.performance.measure.bind(window.performance);
    clearMarks = window.performance.clearMarks.bind(window.performance);
    clearMeasures = window.performance.clearMeasures.bind(window.performance);
    getEntriesByName = window.performance.getEntriesByName.bind(window.performance);
    getEntriesByType = window.performance.getEntriesByType.bind(window.performance);
    wasMarked = (markName) => {
      return (getEntriesByName(markName, 'mark').length > 0);
    };
    wasMeasured = (measureName) => {
      return (getEntriesByName(measureName, 'measure').length > 0);
    };
    updateMark = (name) => {
      clearMarks(name);
      mark(name);
    };
    clearStartMark = (markName) => {
      clearMarks(formatStartMarkName(markName));
    };
    markStart = (markName) => {
      if (limitMarkEntries) {
        updateMark(formatStartMarkName(markName));
      } else {
        mark(formatStartMarkName(markName));
      }
    };
    markEnd = (markName, label) => {
      const startMarkName = formatStartMarkName(markName);
      let measureName = formatMeasureName(markName);
      if (label && typeof label === 'string' && label.length > 0) {
        measureName = `${measureName}_${label}`;
      }
      if (wasMarked(startMarkName)) {
        if (limitMeasureEntries) {
          clearMeasures(measureName);
        }
        try {
          measure(measureName, startMarkName);
          clearMarks(startMarkName);
        } catch (err) { /* Do Nothing */ }
      }
    };
    markStartTTD = (markName) => {
      updateMark(formatStartTTDMarkName(markName));
    };
    markTTD = (markName) => {
      updateMark(formatTTDMarkName(markName));
    };
    measureTTD = (markName, label) => {
      let startMarkName = formatStartTTDMarkName(markName);
      const endMarkName = formatTTDMarkName(markName);
      let measureName = formatTTDMeasureName(markName);
      if (label && typeof label === 'string' && label.length > 0) {
        measureName = `${measureName}_${label}`;
      }
      if (limitMeasureEntries) {
        clearMeasures(measureName);
      }
      if (!wasMarked(startMarkName)) {
        startMarkName = 'navigationStart';
      }
      if (wasMarked(endMarkName)) {
        try {
          measure(measureName, startMarkName, endMarkName);
        } catch (err) { /* Do Nothing */ }
      } else {
        measure(measureName, startMarkName);
      }
    };
    markTTI = (markName) => {
      const ttiMarkName = formatTTIMarkName(markName);
      if (!wasMarked(ttiMarkName)) {
        mark(ttiMarkName);
      }
    };
    measureTTI = (markName, label) => {
      const endMarkName = formatTTIMarkName(markName);
      let measureName = formatTTIMeasureName(markName);
      if (label && typeof label === 'string' && label.length > 0) {
        measureName = `${measureName}_${label}`;
      }
      if (!wasMeasured(measureName)) {
        if (wasMarked(endMarkName)) {
          try {
            measure(measureName, 'navigationStart', endMarkName);
          } catch (err) { /* Do Nothing */ }
        } else {
          measure(measureName);
        }
      }
    };
    isPerormanceUtilitiesEnabled = true;
  }
};

const setPerformanceLogLevel = (perfLogLevel) => {
  if (typeof perfLogLevel !== 'undefined') {
    performanceLogLevel = validatePerformanceLogLevel(perfLogLevel);
    if (performanceLogLevel !== NMConfig.PERF_LOG_LEVEL) {
      NMConfig.PERF_LOG_LEVEL = performanceLogLevel;
    }
    if (isPerormanceUtilitiesEnabled && performanceLogLevel === 0) {
      disablePerformanceUtilities();
    }
    if (!isPerormanceUtilitiesEnabled && performanceLogLevel > 0) {
      enablePerformanceUtilities();
    }
  }
};

const getPerformanceLogLevel = () => {
  if (performanceLogLevel !== NMConfig.PERF_LOG_LEVEL) {
    performanceLogLevel = validatePerformanceLogLevel(NMConfig.PERF_LOG_LEVEL);
  }
  return performanceLogLevel;
};

if (!hasUserTimingAPI) {
  setPerformanceLogLevel(0);
}
if (hasPerfFlag && performanceLogLevel < 1) {
  setPerformanceLogLevel(1);
}
if (hasPerfLogLevelFlag) {
  setPerformanceLogLevel(/[?&]PERF_LOG_LEVEL=([0-9])(?=&|$)/.exec(locationSearchProperty)[1]);
}

if (isPerormanceUtilitiesEnabled) {
  enablePerformanceUtilities();
}

export {
  mark,
  measure,
  clearMarks,
  clearMeasures,
  getEntriesByName,
  getEntriesByType,
  updateMark,
  clearStartMark,
  markStart,
  markEnd,
  markStartTTD,
  markTTD,
  measureTTD,
  markTTI,
  measureTTI,
  setPerformanceLogLevel,
  getPerformanceLogLevel
};
