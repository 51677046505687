import React from 'react';
import { EyeBrow1, BodyB1 } from 'bgo-common/Styleguide/Typography';

export const CALL_FOR_AVAILABILITY_MESSAGE = 'Available in store only.';

const CallForAvailability = () => {
  return (
    <div className="call-for-availability">
      <p className="hide-on-mobile">
        <span className="availability-text">
          <EyeBrow1>{CALL_FOR_AVAILABILITY_MESSAGE}</EyeBrow1>
        </span>
        <BodyB1>
          <span className="phone-number-text">
            <span>Call </span>
            <a
              className="phone-number"
              href=""
              onClick={e => {
                e.preventDefault();
              }}
              aria-label="Available in store only. Call 1.800.401.2012 for details."
              tabIndex={-1}
            >
              1.800.401.2012
            </a>
            <span> for details.</span>
          </span>
        </BodyB1>
      </p>
      <p className="hide-on-tablet hide-on-desktop">
        <span className="availability-text">
          <EyeBrow1>{CALL_FOR_AVAILABILITY_MESSAGE}</EyeBrow1>
        </span>
        <BodyB1>
          <span className="phone-number-text">
            Call <a href="tel:+18004012012">1.800.401.2012</a> for details.
          </span>
        </BodyB1>
      </p>
    </div>
  );
};

export default CallForAvailability;
