const defaultExpirationTime = 3600; // in seconds

export default class Cache {
  constructor(expirationTime = defaultExpirationTime) {
    this._expirationTime = expirationTime;
    this._data = {};
  }

  exists(key) {
    return !!(
      key &&
      this._data[key] &&
      this._data[key].expiration > new Date()
    );
  }

  get(key) {
    return this.exists(key) ? this._data[key].value : undefined;
  }

  set(key, value) {
    if (key) {
      const expiration = new Date();
      expiration.setSeconds(expiration.getSeconds() + this._expirationTime);
      this._data[key] = { value, expiration };
    }
  }
}
