import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import NoRewards from '../NoRewards/NoRewards';
import RewardsList from '../RewardsList/RewardsList';
import './GiftCards.scss';

const GiftCards = ({ promo, bgoLoyaltyToggle }) => {
  const contentId = bgoLoyaltyToggle
    ? 'MYREWARDS-GIFTCARDS-DEFAULT-LOYALTY'
    : 'MYREWARDS-GIFTCARDS-DEFAULT';

  return (
    <div className="gift-cards">
      {isEmpty(promo) ? (
        <NoRewards
          contentId={contentId}
          ctaId="MYREWARDS-GIFTCARDS-DEFAULT-CTA"
        />
      ) : (
        <RewardsList cards={promo} />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  promo: get(state, 'rewards.items.promo', true),
  bgoLoyaltyToggle: state?.toggles?.BGO_LOYALTY_NEW || false,
});

export default connect(mapStateToProps)(GiftCards);
