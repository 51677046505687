import React from 'react';
import './AccountAccessLayout.scss';
import { policyUri } from '../constants';

const redirectToAtgRegisterPage = () => {
  window.location.href = '/account/register.jsp';
};

const RegisterButton = ({ dtSignUpEnabled }) => {
  const props = {
    id: 'register-button',
    className: 'account-access__button',
    type: 'button',
  };

  const propsWithToggle = dtSignUpEnabled
    ? props
    : {
        ...props,
        onClick: redirectToAtgRegisterPage,
      };

  const button = <button {...propsWithToggle}>Register Now</button>;

  return dtSignUpEnabled ? <a href="/e/register">{button}</a> : button;
};

const AccountAccessLayout = ({
  onSubmit,
  loading,
  displayError,
  errorMessage,
  dtSignUpEnabled,
  openForgotPasswordModal,
}) => {
  return (
    <div className="account-access">
      <p className="account-access__message">
        To manage your BG credit card{' '}
        <a
          href="https://www.hrsaccount.com/hrs/ecare?cmd_Redirect=Home&acctOrg=005&logoCode=000&chkCk=check"
          target="_blank"
          rel="noopener noreferrer"
        >
          click here
        </a>
      </p>
      <div className="account-access__content-container">
        <div className="account-access__form-block account-access__sign-in">
          <h2 className="account-access__header">Sign in</h2>
          <form onSubmit={onSubmit}>
            <div className="account-access-field">
              <label
                className="account-access-field__label"
                htmlFor="sign-in-email-input"
              >
                <input
                  id="sign-in-email-input"
                  className="account-access-field__input"
                  type="email"
                  name="email"
                  placeholder=" "
                />
                <span className="account-access-field__label-text">
                  Email Address
                </span>
              </label>
            </div>
            <div className="account-access-field account-access-field--mb0">
              <label htmlFor="sign-in-password-input">
                <input
                  id="sign-in-password-input"
                  className="account-access-field__input"
                  type="password"
                  name="password"
                  placeholder=" "
                />
                <span className="account-access-field__label-text account-access-field__label-text--small">
                  Password
                </span>
              </label>
            </div>
            {displayError && (
              <div className="account-access__sign-in-error">
                {errorMessage}
              </div>
            )}
            <p className="account-access__forgot-password">
              <span
                className="account-access__forgot-password-link"
                onClick={openForgotPasswordModal}
              >
                Forgot Password?
              </span>
            </p>
            <button
              id="sign-in-button"
              className="account-access__button"
              type="submit"
            >
              {loading ? 'Signing in...' : 'Submit'}
            </button>
            <div className="account-access__kmsi-container">
              <label
                htmlFor="sign-in-kmsi-checkbox"
                className="account-access__kmsi-checkbox"
              >
                <input
                  id="sign-in-kmsi-checkbox"
                  className="account-access__kmsi-checkbox-input"
                  name="kmsi"
                  type="checkbox"
                  defaultChecked
                />
                <span className="account-access__kmsi-checkbox-label">
                  Keep me signed in
                </span>
              </label>
            </div>
            <div className="account-access__disclaimer">
              By continuing, you agree to our{' '}
              <a href={policyUri} target="_blank" rel="noreferrer noopener">
                Privacy Policy
              </a>{' '}
              and{' '}
              <a href={policyUri} target="_blank" rel="noreferrer noopener">
                Terms & Conditions
              </a>
              .
            </div>
          </form>
        </div>
        <div className="account-access__form-block account-access__register">
          <div>
            <h2 className="account-access__header">Create an account</h2>
            <ul>
              <li className="account-access__li">
                Save your Shipping &amp; Billing information for future
                purchases
              </li>
              <li className="account-access__li">
                Access your order status and order history online
              </li>
            </ul>
          </div>
          <RegisterButton dtSignUpEnabled={dtSignUpEnabled} />
        </div>
      </div>
    </div>
  );
};

export default AccountAccessLayout;
