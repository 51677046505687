import React, { useEffect, useRef } from 'react';
import get from 'lodash/get';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import { LoyaltyForm } from '@bgo-ui-resources/bgo-component-library';
import { validateEmail } from '@bgo-ui/common/client/utilities/utilities-amplify';
import { resolvedRegistrationPage, signUp } from '../../../actions';
import SignInRegisterSwitcher from '../../common/SignInRegisterSwitcher/SignInRegisterSwitcher';
import TermsDisclaimer from '../../common/TermsDisclaimer/TermsDisclaimer';
import PasswordRequirementsBox from '../../../../common/Forms/FormikPasswordField/PasswordRequirementsBox/PasswordRequirementsBox';
import SubmitButton from '../../../../common/Forms/FormButtons/SubmitButton/SubmitButton';
import { getPasswordRequirements } from '../../../../myaccount/utilities/validation-utils';
import FormikPasswordField from '../../../../common/Forms/FormikPasswordField/FormikPasswordField';
import FormikTextField from '../../../../common/Forms/FormikTextField/FormikTextField';
import FormikDropDownField from '../../../../common/Forms/FormikDropDown/FormikDropDown';
// import '../../../../common/Forms/FormikCheckbox/FormikCheckbox.scss';
import FormikRadioField from '../../../../common/Forms/FormikRadioField/FormikRadioField';
import { datesForSelect } from '../../../../myaccount/constants';
import { loyaltyTitles, validationErrors } from '../../../constants';
import './NewAccountRegisterPage.scss';

const DumbNewAccountRegisterPage = props => {
  const handleLoad = () => {
    props.resolvedRegistrationPage(true);
    removeEventListener('load', handleLoad);
  };
  const loyaltyFormRef = useRef();

  useEffect(() => {
    if (props.isDomLoadComplete) {
      props.resolvedRegistrationPage(true);
    } else {
      addEventListener('load', handleLoad);
    }
  }, []);

  const initialFormValue = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    month: '',
    day: '',
    mostlyInterestedIn: 'womenswear',
  };

  const isLoyaltyCheckboxChecked = () => {
    const { loyaltyCheckboxDisabledStateCodes, userLocation } = props;
    return (
      userLocation?.country_code === 'US' &&
      !loyaltyCheckboxDisabledStateCodes?.includes(userLocation?.region_code)
    );
  };

  const validateForm = values => {
    const errors = {};
    if (!values.firstName?.trim()) {
      errors.firstName = validationErrors.emptyFirstName;
    }
    if (!values.lastName?.trim()) {
      errors.lastName = validationErrors.emptyLastName;
    }
    if (!values.email?.trim()) {
      errors.email = validationErrors.emptyEmail;
    } else if (!validateEmail(values.email?.trim())) {
      errors.email = validationErrors.invalidEmail;
    }
    if (!values.password) {
      errors.password = validationErrors.emptyPassword;
    } else if (
      getPasswordRequirements(values.password).some(req => !req.checked)
    ) {
      errors.password = validationErrors.badPassword;
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = validationErrors.emptyConfirm;
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = validationErrors.passwordsMatch;
    }
    return errors;
  };

  const monthsList = [
    { value: '', name: '' },
    ...Object.keys(datesForSelect).map(i => ({
      value: i,
      name: i,
    })),
  ];
  const daysList = month => [
    { value: '', name: '' },
    ...Array.from({ length: datesForSelect[month] }, (_, i) => i + 1).map(
      i => ({
        value: i,
        name: i,
      }),
    ),
  ];

  const isLoyalityChecked = () => {
    const {
      current: { submitForm: submitLoyaltyForm, isValid, values },
    } = loyaltyFormRef;
    submitLoyaltyForm();
    return (values.phoneNumber && isValid && values) || !values.enrollLoyalty;
  };

  const submitForm = values => {
    if (!props.isLoyaltyEnabled || isLoyalityChecked()) {
      const formData =
        props.isLoyaltyEnabled && loyaltyFormRef?.current?.values?.enrollLoyalty
          ? {
              ...values,
              loyaltyData: {
                ...isLoyalityChecked(),
                loyaltyToggle: props.isLoyaltyEnabled,
              },
            }
          : values;
      props.signUp(formData);
    }
  };

  const initialLoyaltyValues = {
    month: '',
    day: '',
    enrollLoyalty: isLoyaltyCheckboxChecked(),
    phoneNumber: '',
  };
  return (
    <div className="new-account-register-page">
      <SignInRegisterSwitcher
        currentTab="register"
        isLoyaltyEnabled={props.isLoyaltyEnabled}
        handleTabSwitch={props.handleTabSwitch}
      />
      <Formik
        initialValues={initialFormValue}
        validate={validateForm}
        onSubmit={submitForm}
      >
        {({ values }) => (
          <Form autoComplete="off">
            <div className="new-account-register-page__form-fields-container">
              <FormikTextField
                name="firstName"
                id="first-name-field"
                placeholder="First name"
                maxLength={40}
                fontSize
              />
              <FormikTextField
                name="lastName"
                id="last-name-field"
                placeholder="Last name"
                maxLength={40}
                fontSize
              />
              <FormikTextField
                name="email"
                type="email"
                id="email-field"
                placeholder="Email Address"
                fontSize
              />
              <div className="form-field-wrapper">
                <FormikPasswordField
                  name="password"
                  id="password-field"
                  placeholder="Password"
                  fontSize
                />
                <PasswordRequirementsBox
                  requirements={getPasswordRequirements(values.password)}
                />
              </div>
              <FormikPasswordField
                name="confirmPassword"
                id="confirm-password-field"
                placeholder="Confirm Password"
                fontSize
              />
              {props.isLoyaltyEnabled && (
                <LoyaltyForm
                  checkboxLabel={loyaltyTitles.checkboxLabel}
                  loyaltyText={loyaltyTitles.loyaltyInfo}
                  initialValues={initialLoyaltyValues}
                  loyaltyFormRef={loyaltyFormRef}
                />
              )}
              {!props.isLoyaltyEnabled && (
                <>
                  <div className="new-account-register-page__field-caption">
                    <span>Date of birth (optional):</span>
                  </div>
                  <div className="new-account-register-page__birthday-field">
                    <FormikDropDownField
                      placeholder="Month"
                      valuesArray={monthsList}
                      id="month-field"
                      name="month"
                      value={values.month}
                      fontSize
                    />
                    <FormikDropDownField
                      placeholder="Day"
                      valuesArray={daysList(values.month)}
                      id="day-field"
                      name="day"
                      value={values.day}
                      fontSize
                    />
                  </div>

                  <div className="new-account-register-page__field-caption">
                    <span>Mostly interested in:</span>
                  </div>
                  <FormikRadioField
                    id="womenswear"
                    name="mostlyInterestedIn"
                    label="Womenswear"
                    value="womenswear"
                    checkedValue={values.mostlyInterestedIn}
                  />
                  <FormikRadioField
                    id="menswear"
                    name="mostlyInterestedIn"
                    label="Menswear"
                    value="menswear"
                    checkedValue={values.mostlyInterestedIn}
                  />
                </>
              )}
            </div>
            <TermsDisclaimer />
            {props.error && (
              <p className="new-account-register-page__edit-error">
                {props.error}{' '}
                {props.isLoyaltyEnabled ? (
                  <div className="signin_link">
                    <a href="/e/login">Sign In</a> or{' '}
                    <a href="/e/reset-password">Reset Password</a>
                  </div>
                ) : (
                  ''
                )}
              </p>
            )}
            <div
              className={classnames(
                'new-account-register-page__submit-container',
                getPasswordRequirements(values.password).some(
                  req => !req.checked,
                ) && 'divDisabled',
              )}
            >
              <SubmitButton
                isSubmitting={props.isLoading || props.isUpdating}
                type="submit"
                caption="Register"
                additionalClasses="new-account-register-page__submit-btn"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = state => ({
  isDomLoadComplete: get(state, 'page.isDomLoadComplete', false),
  error: get(state, 'login.registration.error', null),
  isLoading: get(state, 'api.registration.loading', false),
  isUpdating: get(state, 'api.update_additional_user_info.loading', false),
  isLoyaltyEnabled: state.toggles.LOYALTY,
  userLocation: get(state, 'api.requestContext.akamaiEdgescape', ''),
  loyaltyCheckboxDisabledStateCodes: get(
    state,
    'loyaltyCheckboxDisabledStateCodes',
    [],
  ),
});

const mapDispatchToProps = {
  resolvedRegistrationPage,
  signUp,
};

const NewAccountRegisterPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbNewAccountRegisterPage);

export default NewAccountRegisterPage;
