import React, { Fragment } from 'react';
import { renderFormattedPriceWithDecimals } from 'pdp/utilities/utilities-price';
import Toggle from 'shared/components/Toggle/toggle';
import { REQUIRED_MONOGRAM } from 'pdp/constants';
import get from 'lodash/get';
import { SubTitle2 } from 'bgo-common/Styleguide/Typography';

const fallbackShippingMessage = shippingMessage => {
  return <span className="shipping-label">{shippingMessage}</span>;
};

const isRequiredMonogram = customization => {
  const behaviorCode = get(customization, 'behaviorCode', '');
  return behaviorCode === REQUIRED_MONOGRAM;
};

const buildMessages = (
  parentheticalCharge,
  shippingMessage,
  customization,
  isEveningWearReturnsFeeExperience,
  startsWithOB,
  showReturnsText,
) => {
  const returnMessage = 'Return Fees Apply';
  let shippingMesssageDisplay = '';
  if (parentheticalCharge > 0) {
    shippingMesssageDisplay =
      isEveningWearReturnsFeeExperience || startsWithOB ? (
        <Fragment>
          <div className="shipping-label">{shippingMessage}</div>
          {showReturnsText && (
            <div className="returns-label">{returnMessage}</div>
          )}
        </Fragment>
      ) : (
        <span className="shipping-label">{shippingMessage}</span>
      );
  }

  const suppressShippingMessage = () => {
    return isRequiredMonogram(customization) ? (
      <Toggle
        feature="SUPPRESS_FSFR_PDP_MONOGRAM"
        fallback={fallbackShippingMessage(shippingMessage)}
      >
        <span className="shipping-label">Free Shipping</span>
      </Toggle>
    ) : (
      fallbackShippingMessage(shippingMessage)
    );
  };

  return parentheticalCharge > 0
    ? shippingMesssageDisplay
    : suppressShippingMessage();
};

const ShippingLabel = ({
  parentheticalCharge,
  narvarTest,
  customization,
  isEveningWearReturnsFeeExperience,
  catalogCode,
  showReturnsText,
}) => {
  let shippingMessage = '';
  const startsWithOB = catalogCode && catalogCode.toLowerCase().includes('ob');
  if (parentheticalCharge === 0) {
    shippingMessage = 'Free Shipping & Returns';
    if (narvarTest && narvarTest.isTestGroup) {
      shippingMessage = narvarTest.text;
    }
    if (isEveningWearReturnsFeeExperience || startsWithOB) {
      shippingMessage = (
        <div>
          <div className="hide-on-mobile hide-on-tablet">
            Free Shipping | Return Fees Apply
          </div>
          <div className="shipping-label hide-on-desktop shipping-label-mobile">
            Free Shipping
          </div>
          <div className="returns-label hide-on-desktop">Return Fees Apply</div>
        </div>
      );
    }
  } else if (parentheticalCharge > 0) {
    shippingMessage = `Shipping: ${renderFormattedPriceWithDecimals(
      parentheticalCharge,
    )}`;
  }

  return shippingMessage ? (
    <SubTitle2>
      {buildMessages(
        parentheticalCharge,
        shippingMessage,
        customization,
        isEveningWearReturnsFeeExperience,
        startsWithOB,
        showReturnsText,
      )}
    </SubTitle2>
  ) : (
    false
  );
};

export default ShippingLabel;
