import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import PaymentInfo from '../PaymentInfo/PaymentInfo';
import { getAddressForMyAccount } from '../../AddressBookPage/actions';

const PaymentList = ({
  getAddressForMyAccount,
  myAccountAddress,
  payments,
}) => {
  useEffect(() => {
    if (isEmpty(myAccountAddress)) {
      getAddressForMyAccount();
    }
  }, []);

  return (
    <div>
      <PaymentInfo payments={payments} />
    </div>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  getAddressForMyAccount,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentList);
