import React, { useEffect, useRef } from 'react';
import ButtonLoader from 'bgo-ui-home/src/client/components/common/Forms/FormButtons/ButtonLoader/ButtonLoader';
import { useApplePayButtonClick } from './hooks/useApplePayButtonClick';

import './ApplePay.scss';

const ApplePay = ({
  handleApplePay,
  appleUserData,
  productDetails,
  applePayAuthorized,
  productPrice,
  selectedSku,
  getUserData,
  perishableDeliveryByDate,
  isApplePayAuthorized,
}) => {
  const sendUtagLinkData = data =>
    window.utag && window.utag.link && window.utag.link(data);

  const { onApplePayButtonClick } = useApplePayButtonClick({
    appleUserData,
    productDetails,
    applePayAuthorized,
    productPrice,
    handleApplePay,
    selectedSku,
    getUserData,
    perishableDeliveryByDate,
    sendUtagLinkData,
  });

  const applePayButtonProps = {
    buttonstyle: 'white-outline',
    type: 'plain',
    locale: 'en',
  };

  const buttonRef = useRef(null);

  const handleClick = () => {
    if (!handleApplePay()) {
      onApplePayButtonClick(selectedSku);
    }
  };

  useEffect(() => {
    const btn = buttonRef.current;
    btn?.addEventListener('click', handleClick);

    return () => {
      btn?.removeEventListener('click', handleClick);
    };
  }, [onApplePayButtonClick, selectedSku]);
  return (
    <div className="apple-pay-con">
      {isApplePayAuthorized ? (
        <div className="buy-now-button">
          <ButtonLoader />
        </div>
      ) : (
        <apple-pay-button {...applePayButtonProps} disabled ref={buttonRef} />
      )}
    </div>
  );
};

ApplePay.propTypes = {};

export default ApplePay;
