import { getUserInfo } from './utilities-amplify';

export const findAllKeysWithPrefixSubstringAndSuffix = (
  prefix,
  user,
  suffix,
) => {
  const keys = [];
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (
      key &&
      key.startsWith(prefix) &&
      (key.includes(user?.email) || key.includes(user?.ucid)) &&
      key.endsWith(suffix)
    ) {
      keys.push(key);
    }
  }
  return keys;
};

export const getUserAttribute = async (attribute, user, amplifyConfig) => {
  const isLoggedIn = user.name && user.securityStatus === 'Authenticated';
  if (!isLoggedIn || !localStorage) {
    return null;
  }

  const userDataKey = findAllKeysWithPrefixSubstringAndSuffix(
    'CognitoIdentityServiceProvider.',
    user,
    'userData',
  );

  if (!userDataKey) {
    const user = await getUserInfo(amplifyConfig, true, true);
    return user?.attributes?.[attribute] || null;
  }

  const userAttributes = JSON.parse(localStorage.getItem(userDataKey) || 'null')
    ?.UserAttributes;

  const attributeValue = userAttributes?.find(data => data?.Name === attribute);
  return attributeValue?.Value || null;
};

export const getLoyaltyProfileId = (user, amplifyConfig) => {
  return getUserAttribute('custom:loyaltyProfileId', user, amplifyConfig);
};

export const isPhoneAvailableInProfile = async (user, amplifyConfig) => {
  const getPhoneFromLocalStore = getUserAttribute(
    'phone_number',
    user,
    amplifyConfig,
  );
  if (getPhoneFromLocalStore) {
    return getPhoneFromLocalStore;
  } else {
    const userInfo = await getUserInfo(amplifyConfig, true, true);
    return userInfo?.attributes?.['phone_number'] || null;
  }
};
