/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import classnames from 'classnames';
// import { getFooterData } from './FooterContent/footer-actions';
import { connect } from 'react-redux';
// import { shouldLoad } from 'universal/http-client';
import { BodyB2 } from '@bgo-ui/common/Styleguide/Typography';
import ConnectedFooterContent from './FooterContent/footerContent';
import footerData from '../../data/footer_data.json';

import './footer.scss';
import CookiePopup from './FooterContent/CookiePopup/cookiePopup';

class Footer extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {
    // this.props.fetchFooter();
  }

  getCurrentYear = () => {
    const currentYear = new Date().getFullYear();
    return currentYear;
  };

  render() {
    const {
      isFullWidth = false,
      internationalCheckout = false,
      isDomestic,
      internationalToggle,
      dtSitemapToggle,
    } = this.props;

    const { promoMessage, desktop, mobile, legalAndExtras } = footerData;

    if (dtSitemapToggle) {
      legalAndExtras['primaryLinks'] = legalAndExtras['primaryLinks'].map(x =>
        x.linkName === 'Site Map' ? { ...x, linkUrl: '/service/sitemap' } : x,
      );
    } else {
      legalAndExtras['primaryLinks'] = legalAndExtras['primaryLinks'].map(x =>
        x.linkName === 'Site Map'
          ? { ...x, linkUrl: '/service/sitemap.jsp?icid=foot_site_map' }
          : x,
      );
    }

    if (internationalToggle && !isDomestic) {
      const personalInformationIndex = findIndex(
        legalAndExtras.primaryLinks,
        { linkName: 'Do Not Sell My Personal Information' },
        {},
      );
      personalInformationIndex > -1 &&
        legalAndExtras.primaryLinks.splice(personalInformationIndex, 1);
    }
    return (
      <div className="outer-footer-main">
        <div
          className={classnames('footer', { large: isFullWidth })}
          itemScope
          itemType="http://schema.org/WPFooter"
          role="contentinfo"
        >
          <meta itemProp="name" content="BergdorfGoodman.com Footer" />

          {!internationalCheckout &&
            footerData.desktop &&
            footerData.mobile && (
              <ConnectedFooterContent
                promoMessage={promoMessage}
                desktopContent={desktop}
                mobileContent={mobile}
              />
            )}
          <div
            id="footer-links"
            className="footer-content-container lr-container"
          >
            <ul>
              {legalAndExtras.primaryLinks.map((link, id) => (
                <li key={id}>
                  <a
                    href={link.linkUrl}
                    key={id}
                    target={(link.rel === 'blank' && '_blank') || 'false'}
                    className={classnames(
                      'footer-link',
                      { 'has-toggle': link.toggle },
                      this.props.showCCPALink &&
                        link.toggle &&
                        link.toggle.toLowerCase(),
                    )}
                  >
                    <BodyB2>{link.linkName}</BodyB2>
                  </a>
                </li>
              ))}
            </ul>
            <ul className="secondary">
              {legalAndExtras.secondaryLinks.map((link, id) => (
                <li key={id}>
                  <a
                    href={link.linkUrl}
                    rel="noreferrer"
                    target="_blank"
                    title={link.linkName}
                  >
                    <BodyB2>
                      {link.hasYear ? (
                        <span>
                          {' '}
                          &#169; {this.getCurrentYear()}, {link.linkName}{' '}
                        </span>
                      ) : (
                        link.linkName
                      )}
                    </BodyB2>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {IS_CLIENT && <CookiePopup />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isDomestic: get(state, 'locale.countryCode', 'US') === 'US',
  internationalToggle: get(state, 'toggles.INTERNATIONAL', false),
  dtSitemapToggle: get(state, 'toggles.DT_SiteMap', false),
});

// const mapDispatchToProps = ({
//   fetchFooter: getFooterData,
// });

export default connect(mapStateToProps)(Footer);

// export default Footer;
