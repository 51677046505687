import React from 'react';
import get from 'lodash/get';
import classnames from 'classnames';
import { connect } from 'react-redux';
import {
  AccountMenuItems,
  sanitizeUserName,
} from '../../../AccountMenuItems/AccountMenuItems';
import { showSvgContainer, hideSvgContainer } from '../expandableMenu';
import minusWithWhite from '../../../../assets/images/collapse_white.svg';
import plusWithBlack from '../../../../assets/images/expand_light.svg';
import { toggleAccountSelected } from '../navSlider-actions';
import '../navSlider.scss';

const defaultMenuItem =
  'nav-slider-menu__item nav-slider-menu__link nav-slider-menu__signinregister';
let isAccountExpanded = false;

const userSpecificAccount = (
  isExpanded,
  firstName,
  isExpandedAction,
  isDomestic,
  disableATGToggle,
) => {
  const usersFirstName = sanitizeUserName(firstName);

  const whiteMinus = isExpanded ? showSvgContainer : hideSvgContainer;
  const blackPlus = isExpanded ? hideSvgContainer : showSvgContainer;

  const linkClasses = classnames({
    'nav-slider-menu__item': true,
    'nav-slider-menu__item nav-slider-menu__item--primary-selected': isExpanded,
  });

  const handleOnClick = () => {
    isAccountExpanded = !isAccountExpanded;
    isExpandedAction(isAccountExpanded);
  };
  return (
    <ul>
      <li>
        <span className="nav-slider-menu__loggedinuser">
          <a
            role="button"
            tabIndex="0"
            className={classnames(linkClasses)}
            onClick={handleOnClick}
            aria-expanded={isAccountExpanded}
          >
            <div className="svg-container">
              <img
                id="white_minus_icon"
                alt=""
                src={minusWithWhite}
                className={whiteMinus}
              />
              <img
                id="black_plus_icon"
                alt=""
                src={plusWithBlack}
                className={blackPlus}
              />
            </div>
            <span
              className={classnames(
                isExpanded
                  ? 'nav-slider-menu__username-expanded'
                  : 'nav-slider-menu__username-not-expanded',
              )}
            >
              {usersFirstName}&#39;s Account
            </span>
          </a>
        </span>
      </li>
      {isExpanded && (
        <AccountMenuItems
          authenticatedUserName={firstName}
          mobileClass={defaultMenuItem}
          ulClass="nav-slider-menu__secondary-menu"
          isDomestic={isDomestic}
          disableATGToggle={disableATGToggle}
        />
      )}
    </ul>
  );
};

const signInRegister = () => {
  return (
    <li>
      <a className={defaultMenuItem} href={NMConfig.ACCOUNT_PAGE_URL}>
        Sign In / Register
      </a>
    </li>
  );
};

const AccountHamburgerMenu = props => {
  const firstName = props.name;
  const { disableATGToggle } = props;
  return (
    <div>
      {' '}
      {firstName
        ? userSpecificAccount(
            props.isExpanded,
            firstName,
            props.isExpandedAction,
            props.isDomestic,
            disableATGToggle,
          )
        : signInRegister()}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    isDomestic:
      get(state, 'locale.countryCode') === 'US' ||
      get(state, 'locale.countryCode') === 'Unites_States',
    isExpanded: state.navigation.navSlider.accountExpanded,
    name: state.user.name,
    disableATGToggle: get(state, 'toggles.DISABLE_ATG_CALLS', false),
  };
};

const mapDispatchToProps = {
  isExpandedAction: toggleAccountSelected,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountHamburgerMenu);
