import { ourPartnersContent } from '../../common/constants';

export const content = {
  id: ourPartnersContent.id,
  title: 'Our Partners Are Your Partners',
  desc:
    "It's personal.<br /><br />You'll receive insider access at these great venues, where you can expect VIP treatment - from a free night's stay or room upgrade to your favorite welcome drink upon arrival. We take pride in curating a variety of experiences and tailoring them to best fit your needs. All offers are based on availability at time of booking and must be reserved through an InCircle Concierge Travel Advisor.",
  partnerLocations: {
    unitedStates: {
      title: 'UNITED STATES',
      states: [
        {
          id: 'arizona',
          title: 'Arizona',
          desc:
            'Arizona Biltmore, LXR Hotels and Resorts, Phoenix<br />Canyon Ranch Tucson<br />The Global Ambassador Phoenix<br />Mii amo Sedona<br />Miraval Resort & Spa',
        },
        {
          id: 'california',
          title: 'California',
          desc:
            'Cal-a-Vie Health Spa Vista<br />Canyon Ranch Woodside<br />Casa Palmero at Pebble Beach<br />Montage Laguna Beach<br />Pendry Newport Beach<br />SLS Hotel Beverly Hills<br />The Peninsula Beverly Hills<br />The Resort at Pelican Hill Newport Beach<br />The Ritz-Carlton Bacara Santa Barbara<br />Shutters on Beach Santa Monica',
        },
        {
          id: 'florida',
          title: 'Florida',
          desc:
            '1 Hotel South Beach Miami<br />Acqualina Resort & Residences on the Beach Miami <br />Four Seasons Resort Orlando at Walt Disney World Resort<br />Little Palm Island Resort & Spa Little Torch Key',
        },
        {
          id: 'hawaii',
          title: 'Hawaii',
          desc:
            '1 Hotel Hanalei Bay Kauai<br />Four Seasons Resort Lanai<br />Four Seasons Resort Hualalai Kailua-Kona<br />1 Hotel Hanalei Bay Kauai<br />Mauna Lani Auberge Collection Kohala Coast<br />Montage Kapalua<br />Turtle Bay Resort Kahuku',
        },
        {
          id: 'illinois',
          title: 'Illinois',
          desc:
            'The Langham, Chicago<br />The Peninsula Chicago<br />Waldorf Astoria Chicago',
        },
        {
          id: 'massachusetts',
          title: 'Massachusetts',
          desc:
            'Boston Harbor Hotel<br />Canyon Ranch Lenox<br />Chatham Bars Inn Chatham',
        },
        {
          id: 'montana',
          title: 'Montana',
          desc: 'Montage Big Sky<br />The Resort at Paws Up',
        },
        {
          id: 'nevada',
          title: 'Nevada',
          desc: 'Bellagio Las Vegas<br />Wynn & Encore Las Vegas',
        },
        {
          id: 'new-mexico',
          title: 'New Mexico',
          desc: 'The Inn of the Five Graces, Santa Fe',
        },
        {
          id: 'new-york',
          title: 'New York',
          desc:
            'Baccarat Hotel New York<br />The Carlyle New York, A Rosewood Hotel<br />The Fifth Avenue Hotel<br />The Mercer New York<br />The Mark<br />The Ritz-Carlton New York, Central Park<br />The Whitby Hotel New York',
        },
        {
          id: 'south-carolina',
          title: 'South Carolina',
          desc: 'Charleston Place<br />Montage Palmetto Bluff',
        },
        {
          id: 'texas',
          title: 'Texas',
          desc:
            'Commodore Perry Estate Auberge Resort Austin<br />The Crescent Hotel Fort Worth<br />Hotel Emma San Antonio<br />The Joule Dallas<br />La Cantera Resort & Spa San Antonio<br />Rosewood Mansion on Turtle Creek Dallas<br />Lake Austin Spa Resort',
        },
        {
          id: 'utah',
          title: 'Utah',
          desc:
            'Amangiri Canyon Point at Lake Powell<br />Montage Deer Valley Park City<br />Pendry Park City<br />Sundance Mountain Resort Provo',
        },
        {
          id: 'virginia',
          title: 'Virginia',
          desc:
            'The Inn at Little Washington<br />Salamander Resort & Spa Middlesburg',
        },
        {
          id: 'washington-d-c',
          title: 'Washington, D.C.',
          desc: 'Park Hyatt<br />Pendry Washington DC - The Wharf',
        },
        {
          id: 'Wyoming',
          title: 'Wyoming',
          desc: 'Amangani Jackson<br />Four Seasons Resort Jackson Hole',
          isLastOfList: true,
        },
      ],
    },
    worldWide: [
      {
        id: 'mexico',
        title: 'MEXICO',
        desc:
          'The Alexander, Mexico City<br />Casa de Sierra Nevada San Miguel de Allende, A Belmond Hotel<br />Hotel Ensencia Xpu Ha<br />Four Seasons Resort Tamarindo<br />One&Only Palmilla, Los Cabos<br />The St. Regis Punta Mita Resort<br />Viceroy Los Cabos',
      },
      {
        id: 'caribbean',
        title: 'CARIBBEAN',
        desc:
          'Amanyara, Turks and Caicos Islands<br />Cheval Blanc St-Barth<br />Jumby Bay Island, Oetker Collection Antigua<br />Le Sereno, Saint Barthelemy<br />Pink Sands Harbour Island, Bahamas<br />Sugar Beach, A Viceroy Resort St Lucia',
      },
      {
        id: 'french-polynesia',
        title: 'FRENCH POLYNESIA',
        desc: 'The Brando (Tahiti)',
      },
      {
        id: 'asia-africa-india',
        title: 'ASIA, AFRICA & INDIA',
        desc:
          'Aman Tokyo, Japan<br />Amanjena Marrakech, Morocco<br />COMO Shambhala Estate Ubud, Bali, Indonesia<br />The Leela Palace Jaipur, Rajasthan, India<br />Micato Safaris Africa & India<br />One&Only Cape Town, South Africa<br />Royal Mansour Casablanca, Morocco<br />Villa Mabrouka Tangier, Morocco',
      },
      {
        id: 'australia-new-zealand',
        title: 'AUSTRALIA & NEW ZEALAND',
        desc:
          'Longitude 131 Uluru, Australia<br />Park Hyatt Melbourne, Australia<br />SO / Auckland, New Zealand',
      },
      {
        id: 'france',
        title: 'FRANCE',
        desc:
          'Hotel Byblos St. Tropez<br />Cap Estel Eze<br />Carlton Cannes<br />Cheval Blanc Courchevel<br />Hotel de Crillon Paris, A Rosewood Hotel<br />Grand-Hotel du Cap-Ferrat, A Four Seasons Hotel<br />Mandarin Oriental Paris<br />La Réserve Hotel & Spa Paris<br />Le Bristol Paris, Oetker Collection<br />Ritz Paris',
      },
      {
        id: 'greece',
        title: 'GREECE',
        desc: 'Amanzoe Argolida<br />Kalesma Mykonos',
      },
      {
        id: 'italy',
        title: 'ITALY',
        desc:
          "Bvlgari Hotel Roma<br />Caesar Augustus Capri<br />Four Seasons Hotel Firenze<br />Four Seasons Hotel Milan<br />The Grand Hotel et de Milan<br />Grand Hotel Tremezzo Lago di Como<br />Le Sirenuse Positano<br />Hotel Lungarno Florence<br />Palace Merano<br />Villa d'Este Lago Di Como",
      },
      {
        id: 'portugal',
        title: 'PORTUGAL',
        desc: 'Six Senses Duoro Valley<br />Vermelho Melides',
      },
      {
        id: 'spain',
        title: 'SPAIN',
        desc:
          'Hotel Arts Barcelona<br />La Residencia, A Belmond Hotel, Mallorca<br />The Madrid Edition<br />Rosewood Villa Magna Madrid<br />W Barcelona',
      },
      {
        id: 'spain',
        title: 'SPAIN',
        desc:
          'Hotel Arts Barcelona<br />La Residencia, A Belmond Hotel, Mallorca<br />The Madrid Edition<br />Rosewood Villa Magna Madrid<br />W Barcelona',
      },
      {
        id: 'united-kingdom',
        title: 'UNITED KINGDOM',
        desc:
          '100 Princes Street, Edinburgh, Scotland<br />The Beaumont, London, England<br />Hotel At Sloane, London, England<br />Bvlgari Hotel, London, England<br />Heckfield Place, Hook, England<br />The Merrion Hotel, Dublin, Ireland',
      },
      {
        id: 'cruise-lines',
        title: 'CRUISE LINES',
        desc:
          'AMAWaterways<br />Celebrity Cruises<br />Crystal Cruises<br />Lindblad Expeditions - National Geographic<br />Regent Seven Seas Cruises<br />Seabourn<br />Silversea<br />Uniworld River Cruises',
      },
      {
        id: 'private-jet',
        title: 'PRIVATE JET',
        desc: 'Altour Air',
      },
      {
        id: 'private-car',
        title: 'PRIVATE CAR',
        desc: 'Blacklane Chauffeur Service<br />Carey Limousine',
      },
      {
        id: 'TRAVEL INSURANCE',
        title: 'TRAVEL INSURANCE',
        desc:
          'Allianz Insurance Services<br />MedjetAssist / MedjetHorizon<br />Travelex Insurance Services',
        isLastOfList: true,
      },
    ],
  },
};
