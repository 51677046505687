import React from 'react';
import { forgotPasswordDescription, forgotPasswordError } from '../constants';

const ForgotPasswordModalConfirmation = ({
  email,
  isLoading,
  error,
  onConfirm,
  onCancel,
}) => (
  <div className="forgot-password__content-container">
    <div className="forgot-password__form-block">
      <h2 className="forgot-password__header">Forgot password</h2>
      <p className="forgot-password__description">
        {forgotPasswordDescription(email)}
      </p>
      <p className="forgot-password__description">
        Are you sure you want to change your password?
      </p>
      {error ? (
        <p className="forgot-password__error-message">{forgotPasswordError}</p>
      ) : null}
      <button
        className="forgot-password__button confirm-button"
        onClick={onConfirm}
        disabled={isLoading}
      >
        {isLoading ? 'Sending data...' : 'Yes'}
      </button>
      <button
        className="forgot-password__button cancel-button"
        onClick={onCancel}
        disabled={isLoading}
      >
        Cancel
      </button>
    </div>
  </div>
);

export default ForgotPasswordModalConfirmation;
