import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import getProduct from 'pdp/components/ProductPage/selectors/getProduct';
import {
  setActiveMediaIndex,
  setProductVideo,
  unsetProductVideo,
} from 'pdp/components/ProductPage/actions';
import Image, {
  NO_IMAGE_AVAILABLE_CDN,
} from 'pdp/components/ProductPage/components/Image/image';
import { getPDPImageMediumUrl } from 'bgo-common/components/Util/product-cloudinary';
import { generatePdpAlt } from 'pdp/utilities/utilities-alt';
import Slider from 'react-slick';
import { InView } from 'react-intersection-observer';
import './alternateImages.scss';

export class DumbAlternateImagesOpti extends Component {
  constructor() {
    super();
    this.toggleVideo = this.toggleVideo.bind(this);
    this.setAltImage = this.setAltImage.bind(this);
    this.showSlides = this.showSlides.bind(this);
    this.hideSlides = this.hideSlides.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  setAltImage(index) {
    const { unsetProductVideo, setActiveMediaIndex } = this.props;
    unsetProductVideo();
    setActiveMediaIndex(index);

    const altImgButton = document.querySelectorAll('.slick-slide button');
    altImgButton &&
      Array.from(altImgButton).map(elem =>
        elem.classList.remove('active-button'),
      );
    altImgButton &&
      altImgButton[index] &&
      altImgButton[index].classList.add('active-button');
  }

  toggleVideo() {
    const { videoActive, unsetProductVideo, setProductVideo } = this.props;
    videoActive ? unsetProductVideo() : setProductVideo();
  }

  showSlides() {
    const activeSlides = Array.from(
      document.querySelectorAll('.slick-active img'),
    );
    activeSlides.forEach(slide => {
      slide.setAttribute('tabIndex', '0');
    });
  }

  hideSlides() {
    const hiddenSlides = Array.from(
      document.querySelectorAll('.slick-slide button'),
    );
    hiddenSlides.forEach(slide => {
      slide.setAttribute('tabIndex', '-1');
    });
  }

  handleFocus(prev, next) {
    const activeSlides = Array.from(
      document.querySelectorAll('.slick-active button'),
    );
    if (!isEmpty(activeSlides)) {
      if (prev < next) {
        activeSlides[activeSlides.length - 1].focus();
      } else if (prev > next) {
        activeSlides[0].focus();
      }
    }
  }

  render() {
    const {
      name,
      brand = '',
      isCloudinary,
      images = [],
      video = {},
    } = this.props;

    const settings = {
      beforeChange: (oldIndex, newIndex) => {
        this.hideSlides();
        setTimeout(() => {
          this.handleFocus(oldIndex, newIndex);
        }, 500);
      },
      afterChange: this.showSlides,
      infinite: false,
      speed: 500,
      slidesToShow: 4.5,
      slidesToScroll: 1,
      lazyLoad: true,
      vertical: true,
      responsive: [
        {
          breakpoint: 1601,
          settings: {
            infinite: false,
            slidesToShow: 5,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            infinite: false,
            slidesToShow: 3,
          },
        },
      ],
    };
    const videoThumbnail = video.thumbnail && (
      <li key="video" className="video">
        <button
          onClick={this.toggleVideo}
          className="grid-100 tablet-grid-100 mobile-grid-100"
        >
          <div className="alternate-images__video-overlay" />
          <InView rootMargin="500px" triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                {inView && (
                  <Image
                    alt={`Video: ${name}`}
                    src={get(video, 'thumbnail.url', NO_IMAGE_AVAILABLE_CDN)}
                  />
                )}
              </div>
            )}
          </InView>
        </button>
      </li>
    );

    const isPDP = true;

    const altImages = images.map((image, index) => (
      <li key={index}>
        <button
          onClick={() => this.setAltImage(index)}
          className={`grid-100 tablet-grid-100 mobile-grid-100 ${index === 0 &&
            'active-button'}`}
        >
          <InView rootMargin="500px" triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                {inView && (
                  <Image
                    alt={generatePdpAlt(brand, name, index, images.length)}
                    isCloudinary={isCloudinary}
                    isPDP={isPDP}
                    src={getPDPImageMediumUrl(image, isCloudinary)}
                  />
                )}
              </div>
            )}
          </InView>
        </button>
      </li>
    ));

    return !isEmpty(images) && images.length > 1 ? (
      <div className="alternate-images__Optimization hide-on-mobile grid-100 tablet-grid-100 mobile-grid-100">
        <ul>
          <Slider {...settings}>{altImages.concat(videoThumbnail)}</Slider>
        </ul>
      </div>
    ) : (
      false
    );
  }
}

const mapStateToProps = (state, props) => ({
  videoActive: get(getProduct(state, props.productId), 'videoActive'),
});

const mapDispatchToProps = (dispatch, { productId }) => ({
  setActiveMediaIndex: index => dispatch(setActiveMediaIndex(productId, index)),
  setProductVideo: () => dispatch(setProductVideo(productId)),
  unsetProductVideo: () => dispatch(unsetProductVideo(productId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbAlternateImagesOpti);
