import React from 'react';
import { connect } from 'react-redux';
import AppWrapper from '../common/app-common';
import MyAccountPage from '../../myaccount/pages/MyAccountPage/MyAccountPage';
import { isNewMyAccountEnabled } from '../../myaccount/utilities/is-new-myaccount';

export function DumbMyAccountAppWrapper({ isNewMyAccountEnabled, children }) {
  return (
    <AppWrapper forceFullWidth>
      {isNewMyAccountEnabled ? (
        <MyAccountPage>{children}</MyAccountPage>
      ) : (
        'BGOMYACCOUNT feature is disabled'
      )}
    </AppWrapper>
  );
}

const mapStateToProps = state => ({
  isNewMyAccountEnabled: isNewMyAccountEnabled(state),
});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbMyAccountAppWrapper);
