import React, { Component } from 'react';
import Button from 'shared/components/Button/button';
import './shopNow.scss';

export default class ShopNowButton extends Component {
  constructor() {
    super();
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick(designerBoutiqueUrl) {
    window.location.assign(designerBoutiqueUrl);
  }

  render() {
    const {
      zeroDollarPageAllBeautyLink,
      designer: { name = '', designerBoutiqueUrl = null } = {},
    } = this.props;
    const designerShopNowValue = name ? `Shop ${name}` : 'Shop Now';
    const buttonLink =
      designerBoutiqueUrl !== null
        ? designerBoutiqueUrl
        : zeroDollarPageAllBeautyLink;
    return (
      <div className="shop-now ">
        <div className="">
          <Button
            ariaLabel={designerShopNowValue}
            value={designerShopNowValue}
            onClick={() => this.handleOnClick(buttonLink)}
          />
        </div>
      </div>
    );
  }
}
