import {
  markStart,
  markEnd,
  markStartTTD,
  measureTTD,
  markTTI,
  measureTTI,
  clearStartMark,
  setPerformanceLogLevel,
  getPerformanceLogLevel,
} from 'client-utils/utilities-performance';
import { LOCATION_CHANGE } from 'react-router-redux';
import { types as spinnerActionTypes } from 'client/common/components/Spinner/spinner-actions';
import {
  LOADING_ADD_TO_BAG,
  RESOLVED_ADD_TO_BAG,
  REJECTED_ADD_TO_BAG,
  ADD_TO_BAG_ERROR,
  SET_HOVERED_COLOR_INDEX,
  SET_HOVERED_SIZE_INDEX,
  INCREASE_PRODUCT_QUANTITY,
  DECREASE_PRODUCT_QUANTITY,
  UPDATE_PRODUCT_QUANTITY,
  SET_ACTIVE_MEDIA_INDEX,
  SET_PRODUCT_VIDEO,
  ZOOM_PRODUCT_IMAGE,
  GET_STORES,
  BOPS_ERROR,
} from 'pdp/components/ProductPage/actions';
import {
  SELECT_FILTER_OPTION,
  CLEAR_ALL_FILTER_OPTION,
  SUBMIT_SEARCH_BY_STORE,
  CANCEL_SEARCH_BY_STORE,
} from 'plp/ProductListPage/components/FacetedLeftNav/actions';
import { OPEN_MODAL } from 'client/common/components/Modal/actions';
import { types as MiniCartTypes } from '@bgo-ui/common/components/Header/MiniCart/miniCart-actions';

let performanceLogLevel = getPerformanceLogLevel();
/* eslint no-unused-vars: ["error", { "args": "none" }]*/
export const usertimingMiddleware = store => next => action => {
  if (action.type === LOCATION_CHANGE) {
    if (
      action.payload &&
      action.payload.query &&
      typeof action.payload.query.PERF_LOG_LEVEL === 'string'
    ) {
      setPerformanceLogLevel(action.payload.query.PERF_LOG_LEVEL);
      performanceLogLevel = getPerformanceLogLevel();
    }
  }
  if (performanceLogLevel === 0) {
    return next(action);
  }
  switch (action.type) {
    case spinnerActionTypes.SHOW_SPINNER:
      markStart('SpinnerVisible');
      break;
    case spinnerActionTypes.HIDE_SPINNER:
      markEnd('SpinnerVisible');
      markEnd('PLP_TimeToApplyFilter');
      markEnd('PLP_TimeToApplyFilterFacet');
      markEnd('PLP_TimeToApplySortBy');
      markEnd('PLP_TimeToChangePaginationPage');
      markEnd('PLP_TimeToFindInStoreSearchFacet');
      break;
    case 'SET_PRODUCT_LIST_DATA_TO_UTAG':
      if (action.payload) {
        if (!action.payload.previousViewFilterSubmit) {
          clearStartMark('PLP_TimeToApplyFilter');
        }
        if (!action.payload.previousViewFacetSubmit) {
          clearStartMark('PLP_TimeToApplyFilterFacet');
        }
        if (!action.payload.previousViewSortSubmit) {
          clearStartMark('PLP_TimeToApplySortBy');
        }
      }
      break;
    case SELECT_FILTER_OPTION:
      markStart('PLP_TimeToApplyFilterFacet');
      break;
    case CLEAR_ALL_FILTER_OPTION:
      markStart('PLP_TimeToApplyFilterFacet');
      break;
    case SUBMIT_SEARCH_BY_STORE:
      markStart('PLP_TimeToFindInStoreSearchFacet');
      break;
    case CANCEL_SEARCH_BY_STORE:
      clearStartMark('PLP_TimeToFindInStoreSearchFacet');
      break;
    case LOADING_ADD_TO_BAG:
      markStart('PDP_TimeToAddToBagAPI');
      break;
    case RESOLVED_ADD_TO_BAG:
      markEnd('PDP_TimeToAddToBagAPI');
      break;
    case REJECTED_ADD_TO_BAG:
      markEnd('PDP_TimeToAddToBagAPI', 'WithError');
      break;
    case ADD_TO_BAG_ERROR:
      markEnd('PDP_TimeToAddToBag', 'WithError');
      break;
    case OPEN_MODAL:
      if (action.payload && action.payload.type === 'AddToBagMobileModal') {
        markEnd('PDP_TimeToAddToBag', 'FromMobile');
      }
      break;
    case GET_STORES:
      if (action.payload && action.payload.status === 200) {
        markEnd('PDP_TimeToFindInStoreSearch');
      } else {
        markEnd('PDP_TimeToFindInStoreSearch', 'WithError');
      }
      break;
    case BOPS_ERROR:
      measureTTD('PDP_FindInStoreButton', 'WithError');
      if (performanceLogLevel > 4) {
        measureTTI('PDP_FindInStoreButton', 'WithError');
      }
      break;
    case SET_HOVERED_SIZE_INDEX:
      if (performanceLogLevel > 3 && action.payload && action.payload !== -1) {
        markTTI('PDP_sizeHover');
        if (performanceLogLevel > 4) {
          measureTTI('PDP_sizeHover');
        }
      }
      break;
    case SET_HOVERED_COLOR_INDEX:
      if (performanceLogLevel > 3 && action.payload && action.payload !== -1) {
        markTTI('PDP_colorHover');
        if (performanceLogLevel > 4) {
          measureTTI('PDP_colorHover');
        }
      }
      break;
    case INCREASE_PRODUCT_QUANTITY:
      if (performanceLogLevel > 2) {
        markTTI('PDP_quantityIncrease');
      }
      if (performanceLogLevel > 3) {
        measureTTI('PDP_quantityIncrease');
      }
      break;
    case DECREASE_PRODUCT_QUANTITY:
      if (performanceLogLevel > 2) {
        markTTI('PDP_quantityDecrease');
      }
      if (performanceLogLevel > 3) {
        measureTTI('PDP_quantityDecrease');
      }
      break;
    case UPDATE_PRODUCT_QUANTITY:
      if (performanceLogLevel > 2) {
        markTTI('PDP_quantityChange');
      }
      if (performanceLogLevel > 3) {
        measureTTI('PDP_quantityChange');
      }
      break;
    case SET_ACTIVE_MEDIA_INDEX:
      if (performanceLogLevel > 2) {
        markTTI('PDP_AltImage');
      }
      if (performanceLogLevel > 3) {
        measureTTI('PDP_AltImage');
      }
      break;
    case SET_PRODUCT_VIDEO:
      if (performanceLogLevel > 2) {
        markTTI('PDP_ProductVideo');
      }
      if (performanceLogLevel > 3) {
        measureTTI('PDP_ProductVideo');
      }
      break;
    case ZOOM_PRODUCT_IMAGE:
      if (performanceLogLevel > 2) {
        markTTI('PDP_ZoomProductImg');
      }
      if (performanceLogLevel > 3) {
        measureTTI('PDP_ZoomProductImg');
      }
      break;
    case MiniCartTypes.LOADING_MINI_CART:
      markStartTTD('miniCart');
      break;
    case MiniCartTypes.RESOLVED_MINI_CART:
      measureTTD('miniCart');
      markEnd('PDP_TimeToAddToBag');
      break;
    case MiniCartTypes.REJECTED_MINI_CART:
      measureTTD('miniCart', 'WithError');
      break;
    default:
      break;
  }
  return next(action);
};

export default usertimingMiddleware;
