import httpWithLogging from 'universal/http-client';
import { ACTION_TYPES } from './constants';

export const submitConciergeData = payload => {
  return (dispatch, getState) => {
    dispatch({
      type: ACTION_TYPES.CONCIERGE_FORM_SUBMIT_PROCESSING,
    });

    const state = getState();
    const requestApi = httpWithLogging(state);
    const body = {
      firstName: payload.firstName,
      lastName: payload.lastName,
      address: payload.address,
      city: payload.city,
      state: payload.state,
      postalCode: payload.postalCode,
      phoneNumber: payload.phoneNumber,
      email: payload.email,
      subject: payload.subject,
      messageBody: payload.comments,
    };
    const headers = {};
    const callString = `${NMConfig.API_INCIRCLE_CONCIERGE_FORM_SUBMIT}`;

    return requestApi
      .post(callString, body, { headers })
      .then(() => {
        dispatch({
          type: ACTION_TYPES.CONCIERGE_FORM_SUBMIT_SUCCESS,
        });
      })
      .catch(() => {
        dispatch({
          type: ACTION_TYPES.CONCIERGE_FORM_SUBMIT_ERROR,
        });
      });
  };
};
