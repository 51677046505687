import React from 'react';
import { BodyB1, EyeBrow1 } from '@bgo-ui/common/Styleguide/Typography';
import './column.scss';
import { SHOP_BY } from 'bgo-common/components/constants';
import { getLinkDomain } from 'client-utils/utilities-navigation';

const CreateCategory = (
  columnIndex,
  l1Category,
  getPlpSinglePage = {},
  closeFunction,
  lengthOfColumn,
) => {
  const chunk = (array, size) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i++) {
      const last = chunkedArr[chunkedArr.length - 1];
      if (!last || last.length === size) {
        chunkedArr.push([array[i]]);
      } else {
        last.push(array[i]);
      }
    }
    return chunkedArr;
  };
  const domain = getLinkDomain();

  const listOfSubCategories =
    l1Category.categories &&
    l1Category.categories.map((l2Category, index) => {
      const l2redText =
        l2Category.attributes && l2Category.attributes.redTextFlag
          ? 'red-text'
          : '';
      const l2AnchorProps = l2redText ? { className: l2redText } : {};
      if (
        getPlpSinglePage.isPLPSinglePageOn &&
        getPlpSinglePage.templateType === 'P3'
      ) {
        return (
          <li
            key={index}
            onClick={() => {
              closeFunction();
              getPlpSinglePage.router.replace({ pathname: l2Category.url });
            }}
          >
            <EyeBrow1>{l2Category.name}</EyeBrow1>
            {l2Category.attributes &&
            l2Category.attributes.boutiqueTextAdornments ? (
              <span className="boutique-text-adornments">
                {l2Category.attributes.boutiqueTextAdornments}
              </span>
            ) : (
              false
            )}
          </li>
        );
      }
      return (
        <li
          key={index}
          className={
            l1Category.name.toLowerCase() === SHOP_BY ? 'showFirstListItem' : ''
          }
        >
          <a href={`${domain}${l2Category.url}`} {...l2AnchorProps}>
            <EyeBrow1>{l2Category.name} </EyeBrow1>
            {l2Category.attributes &&
            l2Category.attributes.boutiqueTextAdornments ? (
              <span className="boutique-text-adornments">
                {l2Category.attributes.boutiqueTextAdornments}
              </span>
            ) : (
              false
            )}
          </a>
        </li>
      );
    });

  const itemCount = l1Category.name.toLowerCase() === SHOP_BY ? 7 : 6;
  const chunkedCategories =
    listOfSubCategories.length < 7
      ? listOfSubCategories
      : chunk(listOfSubCategories, itemCount);

  return (
    <div key={columnIndex}>
      <h6 className={columnIndex !== 0 ? 'nav-item-spacing' : ''}>
        <EyeBrow1>{l1Category.name} </EyeBrow1>
      </h6>

      {lengthOfColumn < 3 && listOfSubCategories.length > 6 ? (
        <div className="multi-cols">
          {chunkedCategories.map((chunk, index) =>
            l1Category.categories && l1Category.categories.length > 0 ? (
              <ul key={index}>
                {index === 0 && l1Category.name.toLowerCase() !== SHOP_BY && (
                  <li>
                    <a href={`${domain}${l1Category.url}`}>
                      <BodyB1>Shop All</BodyB1>
                    </a>
                  </li>
                )}
                {chunk}
              </ul>
            ) : (
              false
            ),
          )}
        </div>
      ) : (
        <div>
          {l1Category.categories && l1Category.categories.length > 0 ? (
            <div className="single-col">
              <ul>
                <li>
                  <a href={`${domain}${l1Category.url}`}>
                    <BodyB1>Shop All</BodyB1>
                  </a>
                </li>
                {listOfSubCategories}
              </ul>
            </div>
          ) : (
            false
          )}
        </div>
      )}
    </div>
  );
};

const Column = (
  { key, categories },
  getPlpSinglePage,
  closeFunction,
  arrayOfCategoriesGrouped,
  promoBannerJsx,
) => {
  const lengthOfColumn =
    arrayOfCategoriesGrouped && arrayOfCategoriesGrouped.length;

  const listOfCategories = categories.map((l1Category, columnIndex) =>
    CreateCategory(
      columnIndex,
      l1Category,
      getPlpSinglePage,
      closeFunction,
      lengthOfColumn,
    ),
  );

  return (
    <div key={key} className={`silo-column col-${key}`}>
      <div className="menu-wrapper">{listOfCategories}</div>
      {key === 3 && lengthOfColumn === 4 ? promoBannerJsx : null}
    </div>
  );
};

export default Column;
