import React, { Component } from 'react';
import Slider from 'react-slick';
import WithTappable from 'client/common/components/Tappable/Tappable';
import classnames from 'classnames';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { getSelectedCustomizationsAllTypesById } from 'pdp/components/ProductPage/reducers/productReducer';
import screenReaderAnnouncer from 'bgo-common/components/ScreenReaderAlert/screenReaderAnnouncer-actions';
import { setSelectedCustomizationObjectById } from 'pdp/components/ProductPage/actions';
import Image from 'pdp/components/ProductPage/components/Image/image';
import './monogramOptionSetDropship.scss';

export class DumbMonogramOptionSetDropship extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: props.selectedCustomizationData.selectedValues || 0,
      hoveredIndex: -1,
    };
    this.resetMonoOptionHoverIndex = this.resetMonoOptionHoverIndex.bind(this);
    this.showSlidesForSlides = this.showSlidesForSlides.bind(this);
    this.hideSlidesForSlides = this.hideSlidesForSlides.bind(this);
    this.handleFocusForSlides = this.handleFocusForSlides.bind(this);
    this.dropListOnChange = this.dropListOnChange.bind(this);
    this.selectInput = null;
    this.selectInputRef = element => {
      this.selectInput = element;
    };
    this.enableCarousel = this.props.enableCarousel || true;
  }

  componentDidMount() {
    setImmediate(() => {
      this.hideSlidesForSlides();
      this.showSlidesForSlides();
    });

    let { selectedValues = 0 } = this.props.selectedCustomizationData;
    if (!selectedValues.toString().length) {
      selectedValues = 0;
    }
    this.props.setSelectedCustomizationObjectById({
      id: get(this.props.selectedCustomizationData, 'id', ''),
      isValid: true,
      selectedValues,
      touched: true,
    });
  }

  setMonoOptionIndex(index, displayName) {
    return () => {
      this.props.setSelectedOption(this.props.option.legacyType, index);
      this.setState({ selectedIndex: index });
      this.props.setSelectedCustomizationObjectById({
        id: get(this.props.selectedCustomizationData, 'id', ''),
        isValid: true,
        selectedValues: index,
        touched: true,
      });
      this.props.screenReaderAnnouncer(
        `${this.props.titlePrepend} ${displayName} selected`,
      );
    };
  }

  setMonoOptionHoverIndex(index) {
    return () => {
      this.setState({ hoveredIndex: index });
      this.props.setHoverIndex(this.props.option.legacyType, index);
    };
  }

  getMonoOptionClass(index) {
    const { selectedIndex, hoveredIndex } = this.state;
    return classnames(
      'monogram-option__image',
      { 'monogram-option__image--active': index === selectedIndex },
      {
        'monogram-option__image--hover':
          index === hoveredIndex && selectedIndex !== hoveredIndex,
      },
    );
  }

  getLabel(index, title, name) {
    if (index === this.state.selectedIndex) {
      return `${title}${name} selected`;
    } else {
      return `${title}${name}`;
    }
  }

  getLabelMonoStyle(choiceType) {
    switch (choiceType) {
      case 'NAME':
        return 'for name';
      case 'SINGLE_INIT':
        return 'for first initial';
      case 'THREE_INIT':
      case 'THREE_INIT_FML':
        return 'for initials in order of first, middle, and last name';
      default:
        return '';
    }
  }

  resetMonoOptionHoverIndex() {
    this.props.resetHoverIndex(this.props.option.legacyType);
    this.setState({ hoveredIndex: -1 });
  }

  option(choice, index) {
    const label = `${this.getLabel(
      index,
      this.props.titlePrepend,
      choice.displayName,
    )} ${this.getLabelMonoStyle(choice.type)}`;
    return (
      <li key={index} style={{ width: 64 }}>
        <WithTappable
          mouseOver={this.setMonoOptionHoverIndex(index)}
          mouseOut={this.resetMonoOptionHoverIndex}
          style={{ width: 64 }}
        >
          {tapProps => (
            <button
              aria-label={label}
              tabIndex="0"
              onClick={this.setMonoOptionIndex(index, choice.displayName)}
              onFocus={this.setMonoOptionHoverIndex(index)}
              onBlur={this.resetMonoOptionHoverIndex}
              className={this.getMonoOptionClass(index)}
              {...tapProps}
            >
              <Image
                src={`${choice.url}`}
                title={`${this.props.titlePrepend}${choice.displayName}`}
                alt={`${this.props.titlePrepend}${choice.displayName}`}
                aria-hidden="true"
              />
            </button>
          )}
        </WithTappable>
      </li>
    );
  }

  showSlidesForSlides() {
    const container = document.getElementById(this.props.id);
    let activeSlides;
    if (container) {
      activeSlides = Array.from(
        container.querySelectorAll('.slick-active button'),
      );
      activeSlides.forEach(slide => {
        slide.setAttribute('tabIndex', '0');
      });
    }
  }

  hideSlidesForSlides() {
    const container = document.getElementById(this.props.id);
    let hiddenSlides;
    if (container) {
      hiddenSlides = Array.from(
        container.querySelectorAll('.slick-slide button'),
      );
      hiddenSlides.forEach(slide => {
        slide.setAttribute('tabIndex', '-1');
      });
    }
  }

  handleFocusForSlides(prev, next) {
    const container = document.getElementById(this.props.id);
    let activeSlides;
    if (container) {
      activeSlides = Array.from(
        container.querySelectorAll('.slick-active button'),
      );
      if (prev < next) {
        activeSlides[activeSlides.length - 1].focus();
      } else if (prev > next) {
        activeSlides[0].focus();
      }
    }
  }

  checkIfAllMonoChoicesURLExists() {
    let allURLSAvailable = true;
    this.props.option.choices.forEach(choice => {
      choice.url && choice.url !== 'UNKNOWN'
        ? null
        : (allURLSAvailable = false);
    });
    return allURLSAvailable;
  }

  dropListOnChange(event) {
    const styleHeader = document.getElementById(this.props.option.id);
    styleHeader.innerHTML = `${this.props.titlePrepend} ${event.target.value}`;
    this.props.setSelectedCustomizationObjectById({
      id: get(this.props.selectedCustomizationData, 'id', ''),
      isValid: true,
      selectedValues: this.selectInput.selectedIndex,
      touched: true,
    });
  }

  renderDropList() {
    return (
      <select
        ref={this.selectInputRef}
        className="monogram-style-droplist"
        onChange={this.dropListOnChange}
      >
        {this.props.option.choices.map((choice, index) => (
          <option
            selected={
              get(this.props.selectedCustomizationData, 'selectedValues', 0) ===
              index
            }
            value={choice.displayName}
            key={index}
          >
            {choice.displayName}
          </option>
        ))}
      </select>
    );
  }

  render() {
    if (!this.props.option.choices || this.props.option.choices.length === 0) {
      return null;
    }

    const { choices } = this.props.option;
    const { selectedIndex, hoveredIndex } = this.state;
    const activeOptionIndex =
      hoveredIndex === -1 ? selectedIndex : hoveredIndex;
    const { displayName } = choices[activeOptionIndex];
    const monoOptionCarouselSettings = {
      beforeChange: (oldIndex, newIndex) => {
        this.hideSlidesForSlides();
        setTimeout(() => {
          this.handleFocusForSlides(oldIndex, newIndex);
        }, 500);
      },
      afterChange: this.showSlides,
      infinite: false,
      speed: 500,
      slidesToShow: this.props.slidesToShow ? this.props.slidesToShow : 4,
      slidesToScroll: 1,
      className: 'slider variable-width',
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 7,
          },
        },
        {
          breakpoint: 470,
          settings: {
            slidesToShow: this.props.slidesToShow ? this.props.slidesToShow : 4,
          },
        },
      ],
    };

    return (
      <div className="monogram-option grid-100 tablet-grid-100 mobile-grid-100 grid-parent monogram_title_options">
        <div className="monogram-option__text">
          {this.props.optionLabel}:{' '}
          <p className="monogram-option__name" id={this.props.option.id}>
            {this.props.titlePrepend}
            {displayName}
          </p>
        </div>
        <ul
          id={this.props.id}
          className={`${this.props.enableCarousel === false &&
            'no-slider-lists'}`}
        >
          {// eslint-disable-next-line no-nested-ternary
          (this.props.nonInHouseMono &&
            !this.checkIfAllMonoChoicesURLExists()) ||
          this.props.forceDropDownRender ? (
            this.renderDropList()
          ) : this.props.enableCarousel !== false ? (
            <Slider {...monoOptionCarouselSettings} slideWidth={64}>
              {choices.map((choice, index) => this.option(choice, index))}
            </Slider>
          ) : (
            <div className="no-slider">
              {choices.map((choice, index) => this.option(choice, index))}
            </div>
          )}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    selectedCustomizationData: getSelectedCustomizationsAllTypesById(
      state,
      props,
    ),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  screenReaderAnnouncer: (...args) => dispatch(screenReaderAnnouncer(...args)),
  setSelectedCustomizationObjectById: (...args) =>
    dispatch(setSelectedCustomizationObjectById(ownProps.productId, ...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbMonogramOptionSetDropship);
