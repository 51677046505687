import React from 'react';
import window from 'window-or-global';
import { v4 as uuid } from 'uuid';
import { connect } from 'react-redux';
import get from 'lodash/get';

class OwnAffirmCta extends React.Component {
  onLearnMoreClick(e, affirmAmount) {
    e.preventDefault();
    const productDetail = {
      amount: affirmAmount * 100,
      pageType: 'product',
      type: 'product_page_modal',
    };
    window.affirm.widgets.learn_more.openModal(this.props, productDetail);
  }

  render() {
    const { productId, affirmAmount } = this.props;

    return (
      <div id={`own-affirm-cta-info-${productId}`} className="affirm-cta">
        Pay over time with&nbsp;
        <span className="__affirm-logo __affirm-logo-blue __ligature__affirm_full_logo__ __processed">
          Affirm.
        </span>
        &nbsp;
        <button
          type="button"
          aria-label="Learn more - Affirm Financing Promotions (opens in modal)"
          className="affirm-modal-trigger"
          onClick={e => this.onLearnMoreClick(e, affirmAmount)}
        >
          Learn more
        </button>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    affirmConfig: state.affirmConfig,
  };
};

export default connect(mapStateToProps)(OwnAffirmCta);
