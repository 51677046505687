import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';
import { isNewCartCheckoutEnabled } from 'client-utils/is-new-cart-checkout';
import Copy from '../../../../common/Copy/Copy';
import AddressBookInfo from '../AddressBookInfo/AddressBookInfo';
import {
  openAddressModal,
  setAddressId,
  addMyAccountAddress,
  editMyAccountAddress,
} from '../actions';
import {
  setDefaultButtonText,
  editButtonText,
  deleteButtonText,
  CTA_LINK_FOR_MOBILE,
  CTA_MODAL_TYPE,
  CTA_MODAL_STYLE,
  DAB_MODAL_TYPE,
  DAB_MODAL_STYLE,
} from '../../../constants';
import './AddressBookList.scss';

const ADDRESS_TYPE = 'shipping';
const DEFAULT_TEXT = 'Default Address';

class AddressBookList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      addressId: '',
    };
    this.setIsEdit = this.setIsEdit.bind(this);
    this.addAddress = this.addAddress.bind(this);
  }

  getShippingAddress(myAccountAddress) {
    return myAccountAddress
      .filter(data => data.addressType === ADDRESS_TYPE)
      .sort((x, y) => y.defaultAddress - x.defaultAddress);
  }

  setDefaultAddress(addressId) {
    this.props.setAddressId(addressId);
    const payload = {
      defaultAddress: true,
    };
    this.props.editMyAccountAddress(payload);
  }

  setIsEdit(isEdit, addressId = '') {
    this.setState({ isEdit, addressId });
  }

  addAddress(payload) {
    const selectedAddressId = this.state.addressId;
    if (isEmpty(selectedAddressId)) {
      this.props.addMyAccountAddress(payload);
    } else {
      this.props.editMyAccountAddress(
        this.props.isNewCartCheckoutEnabled
          ? { ...payload, flgAddressStandardized: '0' }
          : payload,
        selectedAddressId,
      );
    }
    this.setIsEdit(false);
  }

  openDABModal(e, addressId) {
    e.preventDefault();
    if (addressId) {
      this.props.setAddressId(addressId);
    }
    this.props.openAddressModal(DAB_MODAL_TYPE, DAB_MODAL_STYLE);
  }

  openCTAModal(e, addressId) {
    e.preventDefault();
    if (addressId) {
      this.props.setAddressId(addressId);
    }
    this.props.openAddressModal(
      CTA_MODAL_TYPE,
      CTA_MODAL_STYLE,
      this.setIsEdit,
    );
  }

  renderNoAddress(content, shippingAddressData) {
    const ADDRESSBOOKDEFAULT = get(content, 'ADDRESSBOOK-DEFAULT');
    const ADDRESSBOOKBUTTON = get(content, 'ADDRESSBOOK-BUTTON');
    return (
      <React.Fragment>
        {ADDRESSBOOKDEFAULT && isEmpty(shippingAddressData) && (
          <Copy content={ADDRESSBOOKDEFAULT} />
        )}
        {ADDRESSBOOKBUTTON && (
          <div className="button-wrapper" onClick={() => this.setIsEdit(true)}>
            <Copy content={ADDRESSBOOKBUTTON} />
          </div>
        )}
      </React.Fragment>
    );
  }

  renderShippingAddress(shippingAddressData) {
    return (
      <React.Fragment>
        {shippingAddressData.map(address => {
          const { addressId } = address;

          return (
            <div
              className="shipping-address-container"
              key={address.addressId}
              aria-live="polite"
            >
              <div className="shipping-address-container__address-details">
                {address.defaultAddress && (
                  <p className="shipping-address-container__address-details__default-address">
                    {DEFAULT_TEXT}
                  </p>
                )}
                {(address.firstName || address.lastName) && (
                  <p>
                    {address.firstName && address.firstName}{' '}
                    {address.lastName && address.lastName}
                  </p>
                )}
                {address.address1 && <p>{address.address1}</p>}
                {address.address2 && <p>{address.address2}</p>}
                {(address.city || address.state) && (
                  <p>
                    {address.city && `${address.city}${address.state && ', '}`}
                    {address.state && address.state}
                  </p>
                )}
                {address.postalCode && <p>{address.postalCode}</p>}
                {address.phoneNumber && <p>{address.phoneNumber}</p>}
              </div>
              <div className="shipping-address-container__cta-buttons">
                <div className="shipping-address-container__cta-buttons__desktop">
                  {!address.defaultAddress && (
                    <button
                      type="button"
                      onClick={() => this.setDefaultAddress(addressId)}
                    >
                      {setDefaultButtonText}
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={() => this.setIsEdit(true, addressId)}
                  >
                    {editButtonText}
                  </button>
                  {address.defaultAddress &&
                  shippingAddressData.length === 1 ? null : (
                    <button
                      type="button"
                      onClick={e => this.openDABModal(e, addressId)}
                    >
                      {deleteButtonText}
                    </button>
                  )}
                </div>
                <div className="shipping-address-container__cta-buttons__mobile">
                  <p onClick={e => this.openCTAModal(e, addressId)}>
                    {CTA_LINK_FOR_MOBILE}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }

  render() {
    const { content, myAccountAddress } = this.props;
    const ADDRESSBOOKTITLE = get(content, 'ADDRESSBOOK-TITLE');
    const ADDRESSBOOKADDDEFAULT = get(content, 'ADDRESSBOOK-ADD-DEFAULT');
    const ADDRESSBOOKEDITDEFAULT = get(content, 'ADDRESSBOOK-EDIT-DEFAULT');
    const { isEdit, addressId } = this.state;
    const shippingAddressData =
      myAccountAddress && this.getShippingAddress(myAccountAddress);

    const className = isEmpty(shippingAddressData)
      ? 'no-address'
      : 'no-address no-address-padding';
    return (
      <div className="address-wrapper">
        {ADDRESSBOOKTITLE && (
          <div
            className={classnames(
              { 'my-account-page__main-header desktop-tablet-only': true },
              { 'address-title': isEdit },
            )}
          >
            <Copy content={ADDRESSBOOKTITLE} />
          </div>
        )}
        {!isEdit ? (
          (myAccountAddress?.length > 0 || isEmpty(shippingAddressData)) && (
            <React.Fragment>
              <div className={className}>
                {content && this.renderNoAddress(content, shippingAddressData)}
              </div>
              <div>
                {!isEmpty(shippingAddressData) &&
                  this.renderShippingAddress(shippingAddressData)}
              </div>
            </React.Fragment>
          )
        ) : (
          <div className="address-form-container">
            {isEmpty(addressId) && ADDRESSBOOKADDDEFAULT && (
              <Copy content={ADDRESSBOOKADDDEFAULT} />
            )}
            {!isEmpty(addressId) && ADDRESSBOOKEDITDEFAULT && (
              <Copy content={ADDRESSBOOKEDITDEFAULT} />
            )}
            <AddressBookInfo
              addressId={addressId}
              addressType={ADDRESS_TYPE}
              onSave={this.addAddress}
              onCancel={this.setIsEdit}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  myAccountAddress: get(state, 'myaccount.myAccountAddress', true),
  isNewCartCheckoutEnabled: isNewCartCheckoutEnabled(state),
});

const mapDispatchToProps = {
  openAddressModal,
  setAddressId,
  addMyAccountAddress,
  editMyAccountAddress,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddressBookList);
