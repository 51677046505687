import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import './NoBGcard.scss';
import Copy from '../../../../common/Copy/Copy';

const NoBGcard = ({ myAccountContent }) => {
  return (
    <Fragment>
      {!isEmpty(myAccountContent) && (
        <div className="no-bg-card">
          <div className="no-bg-card__content">
            <Copy content={get(myAccountContent, 'CREDITCARD-DEFAULT')} />
          </div>
          <div className="no-bg-card__buttons">
            <Copy
              newTab
              content={get(myAccountContent, 'CREDITCARD-DEFAULT-BUTTON-APPLY')}
            />
            <Copy
              newTab
              content={get(myAccountContent, 'CREDITCARD-DEFAULT-BUTTON-LEARN')}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  myAccountContent: get(state, 'myaccount.myAccountContent', true),
});

export default connect(mapStateToProps)(NoBGcard);
