import React from 'react';
import { Field } from 'formik';
import classNames from 'classnames';

import './FormikRadioField.scss';

const FormikRadioField = ({ id, name, label, value, checkedValue }) => {
  return (
    <div className="radio-field">
      <Field
        className={classNames(
          'radio-field__input',
          checkedValue === value && 'checked',
        )}
        type="radio"
        name={name}
        id={id}
        value={value}
      />
      <label className="radio-field__label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default FormikRadioField;
