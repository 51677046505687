import React, { Component } from 'react';
import size from 'lodash/size';
import get from 'lodash/get';
import find from 'lodash/find';
import sumBy from 'lodash/sumBy';
import map from 'lodash/map';
import values from 'lodash/values';
import uniqBy from 'lodash/uniqBy';
import { connect } from 'react-redux';
import { localStorage } from 'window-or-global';
import httpWithLogging, { shouldLoad } from 'universal/http-client';
import { getCMSContent } from 'bgo-common/client/cms/actions/get-cms-content';
import RenderContent from 'bgo-common/client/cms/components/content/content';
import logger from 'server-utils/logger';
import axios from 'axios';
import NO_IMAGE_AVAILABLE from 'assets/images/no-image.jpeg';
import {
  showSpinner,
  hideSpinner,
} from 'shared/components/Spinner/spinner-actions';
import { localCheckInventory } from './miniCart-actions';
import './internationalCartStyle.scss';

class InternationalCart extends Component {
  constructor(props) {
    super(props);
    const { countryCode, buyerCurrency } = props;
    this.intlCartName = `internationalCart_${countryCode}_${buyerCurrency}`;
    this.state = {
      localInternationalCart: '',
      cartQuantity: 0,
      currencyCode: '',
      borderFreeError: false,
    };
    this.props.showSpinner();
    this.onCheckout = this.onCheckout.bind(this);
    const { apiStatus, getCMSContent } = this.props;
    if (shouldLoad(apiStatus)) {
      getCMSContent('/cart');
    }
  }

  componentDidMount() {
    const localInternationalCart = JSON.parse(
      localStorage.getItem(this.intlCartName),
    );
    const cartDataAvailable =
      localInternationalCart && localInternationalCart.length > 0;
    const currencyCode =
      cartDataAvailable && localInternationalCart[0].price.currencyCode;
    const cartQuantity =
      cartDataAvailable &&
      sumBy(localInternationalCart, item => Number(item.productQty));
    this.setState({
      localInternationalCart,
      cartQuantity,
      currencyCode,
    });
    this.currencyQuoteId();
    const merchantOrderId = window?.sessionStorage.getItem('merchantOrderId');
    if (!merchantOrderId) {
      let saveCartTotal = 0;
      let saveCartTotalIntlParentheticalAmount = 0;
      const lineItems = [];
      const { countryCode, buyerCurrency } = this.props;
      (localInternationalCart && localInternationalCart.length > 0)
      && localInternationalCart.map((cartItem) => {
        let listPrice = cartItem.price.retailPrice;
        let salePrice = cartItem.price.retailPrice;
        if (cartItem.price.adornments) {
          listPrice = cartItem.price.adornments[0].price;
          salePrice = cartItem.price.adornments[1].price;
        }
        const lineItem = {
          skuId: cartItem.skuId,
          qty: cartItem.productQty,
          listPrice,
          salePrice,
          surcharge: cartItem.intlParentheticalAmount,
        };
        lineItems.push(lineItem);
        const saveCartIntlParentheticalAmount = cartItem.intlParentheticalAmount
          ? Number(cartItem.intlParentheticalAmount) : 0;
        saveCartTotalIntlParentheticalAmount += saveCartIntlParentheticalAmount;
        const saveCartProductPrice = Number(cartItem.price.retailPrice) * cartItem.productQty;
        saveCartTotal += Number(saveCartProductPrice);
        return true;
      });
      const requestBody = {
        orderReq: {
          countryCode,
          currencyCode: buyerCurrency,
          profileId: this.props.profileId,
          orderTotal: saveCartTotalIntlParentheticalAmount + saveCartTotal,
          lineItems,
        },
      };
      this.saveIntlCartData(requestBody);
    }
    this.props.hideSpinner();
  }

  onDelete(skuIndex) {
    const localInternationalCart = JSON.parse(
      localStorage.getItem(this.intlCartName),
    );
    const { inStockObj } = this.state;
    const newInStockObj = {};
    if (inStockObj) {
      delete inStockObj[`${localInternationalCart[skuIndex].skuId}_${skuIndex}`];
      Object.entries(inStockObj).forEach(([key, value], index) => {
        const splitKey = key.split('_');
        newInStockObj[`${splitKey[0]}_${index}`] = value;
      });
    }
    localInternationalCart.splice(skuIndex, 1);
    const cartDataAvailable =
      localInternationalCart && localInternationalCart.length > 0;
    const currencyCode =
      cartDataAvailable && localInternationalCart[0].price.currencyCode;
    const cartQuantity =
      cartDataAvailable &&
      sumBy(localInternationalCart, item => Number(item.productQty));
    this.setState({
      localInternationalCart,
      cartQuantity,
      currencyCode,
      inStockObj: size(newInStockObj) > 0 ? newInStockObj : inStockObj,
    });
    localStorage.setItem(
      this.intlCartName,
      JSON.stringify(localInternationalCart),
    );
  }

  async onCheckout() {
    this.props.showSpinner();
    await this.checkInventory();
    const merchantOrderId = window?.sessionStorage.getItem('merchantOrderId');
    const inventoryProducts = this.props.cartInventoryCheckData.products;
    const localInternationalCart = (() => {
      const localInternationalCartData = localStorage.getItem(this.intlCartName);
      return localInternationalCartData
        ? JSON.parse(localInternationalCartData)
        : [];
    })();
    const {
      buyerCurrency,
      countryCode,
      jSessionId,
      clientIp,
      profileId,
    } = this.props;
    const checkoutData = {
      localInternationalCart,
      merchantOrderId,
      buyerCurrency,
      countryCode,
      jSessionId,
      clientIp,
      origin: window.location.origin,
      currencyQuoteIdValue: window?.sessionStorage.getItem(`currencyQuoteId_${buyerCurrency}`) ? window?.sessionStorage.getItem(`currencyQuoteId_${buyerCurrency}`) : 1,
    };
    const inStockObj = {};
    let saveCartTotalIntlParentheticalAmount = 0;
    let saveCartTotal = 0;
    const lineItems = [];
    (localInternationalCart && localInternationalCart.length > 0)
    && localInternationalCart.map((cartItem, index) => {
      const currentProduct = find(inventoryProducts, { id: cartItem.productId });
      if (currentProduct) {
        const skuInStock = find(currentProduct.skus, { metadata: { cmosSkuId: cartItem.skuId } });
        inStockObj[`${cartItem.skuId}_${index}`] = (skuInStock && skuInStock.sellable && currentProduct.price.retailPrice ? skuInStock.sellable : false);
        const saveCartIntlParentheticalAmount = cartItem.intlParentheticalAmount
          ? Number(cartItem.intlParentheticalAmount) : 0;
        saveCartTotalIntlParentheticalAmount += saveCartIntlParentheticalAmount;
        const saveCartProductPrice = Number(cartItem.price.retailPrice) * cartItem.productQty;
        saveCartTotal += Number(saveCartProductPrice);
        let listPrice = cartItem.price.retailPrice;
        let salePrice = cartItem.price.retailPrice;
        if (cartItem.price.adornments) {
          listPrice = cartItem.price.adornments[0].price;
          salePrice = cartItem.price.adornments[1].price;
        }
        const lineItem = {
          skuId: cartItem.skuId,
          qty: cartItem.productQty,
          listPrice,
          salePrice,
          surcharge: cartItem.intlParentheticalAmount,
        };
        lineItems.push(lineItem);
      }
      return true;
    });
    if (merchantOrderId) {
      this.setState({ inStockObj }, async () => {
        inStockObj && values(inStockObj).includes(false) && this.props.hideSpinner();
        if (inStockObj && !values(inStockObj).includes(false)) {
          const requestBody = {
            orderReq: {
              countryCode,
              currencyCode: buyerCurrency,
              profileId,
              orderId: merchantOrderId,
              orderTotal: saveCartTotalIntlParentheticalAmount + saveCartTotal,
              lineItems,
            },
          };
          await this.saveIntlCartData(requestBody);
          this.invokeBorderFreeCheckout(checkoutData);
        }
      });
    } else {
      this.setState({ borderFreeError: true });
      this.props.hideSpinner();
    }
  }

  invokeBorderFreeCheckout(borderFreeXmlData) {
    const parser = new window.DOMParser();
    const config = {
      method: 'post',
      url: `${NMConfig.API_BORDERFREE_CHECKOUT}`,
      data: { borderFreeXmlData },
    };
    axios(config)
      .then(response => {
        if (response && response.data) {
          const responseXML = parser.parseFromString(response.data, 'text/xml');
          const fullEnvoyUrl =
            responseXML.getElementsByTagName('fullEnvoyUrl') &&
            responseXML.getElementsByTagName('fullEnvoyUrl')[0].textContent;
          fullEnvoyUrl &&
            window.sessionStorage.setItem('fullEnvoyUrl', fullEnvoyUrl);
          fullEnvoyUrl && (window.location.href = '/international/checkout');
        }
      })
      .catch(() => {
        this.setState({ borderFreeError: true });
        this.props.hideSpinner();
      });
  }

  async currencyQuoteId() {
    const { buyerCurrency } = this.props;
    let currencyQuoteId = window.sessionStorage.getItem(
      `currencyQuoteId_${buyerCurrency}`,
    );
    if (!currencyQuoteId) {
      const getConfig = {
        method: 'get',
        url: `${NMConfig.API_CURRENCY_QUOTE_ID}/${buyerCurrency}`,
      };
      await axios(getConfig)
        .then(response => {
          currencyQuoteId = response.data.quote;
          window.sessionStorage.setItem(
            `currencyQuoteId_${buyerCurrency}`,
            currencyQuoteId,
          );
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  createRandom() {
    const characters = 'abcdefghijklmnopqrstuvwxyz1234567890';
    let randomStr = '';
    for (let i = 0; i < 32; i++) {
      const randomNum = Math.floor(Math.random() * characters.length);
      randomStr += characters[randomNum];
    }
    return randomStr;
  }

  async checkInventory() {
    const localInternationalCart = JSON.parse(
      localStorage.getItem(this.intlCartName),
    );
    const productIds = uniqBy(map(localInternationalCart, 'productId'));
    await this.props.localCheckInventory(productIds.join());
  }

  async saveIntlCartData(requestBody) {
    const requestApi = httpWithLogging({}, 6000);
    await requestApi.post(
      NMConfig.API_SAVE_INTL_CART,
      requestBody,
    ).then((successResponse) => {
      if (successResponse) {
        window?.sessionStorage.setItem('merchantOrderId', successResponse.data.orderId);
        logger.info('International Cart Data Saved');
      }
    }).catch((error) => {
      logger.error('Error sending international Cart Details ', error);
    });
  }

  render() {
    const {
      localInternationalCart,
      cartQuantity,
      currencyCode,
      inStockObj,
      borderFreeError,
    } = this.state;
    const cartDataAvailable =
      localInternationalCart && localInternationalCart.length > 0;
    let cartTotal = 0;
    let totalAdditionalCharge = 0;
    return (
      <div className="internationalCartContainer grid-100">
        <div className="grid-70">
          <h1 className="shoppingBagTitle">Your Shopping Bag</h1>
          {cartQuantity > 0 && (
            <div className="grid-100">{`${cartQuantity} items in your bag`}</div>
          )}
          {cartDataAvailable ? (
            localInternationalCart.map((cartItem, index) => {
              cartTotal += cartItem.price.retailPrice * cartItem.productQty;
              const additionalCharge =
                cartItem.intlParentheticalAmount * cartItem.productQty;
              totalAdditionalCharge += additionalCharge;
              return (
                <div
                  key={`${cartItem.skuId}_${index}`}
                  className="item grid-100 tablet-grid-100 mobile-grid-100"
                >
                  <div className="itemImg grid-20">
                    <a href={cartItem.productUrl}>
                      <img
                        src={
                          cartItem.images.small.url ||
                          cartItem.images.thumbnail.url ||
                          cartItem.images.dynamic.url ||
                          NO_IMAGE_AVAILABLE
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="itemDetails grid-55">
                    {cartItem.name && (
                      <div className="itemName grid-100">
                        <a href={cartItem.productUrl}>{cartItem.name}</a>
                      </div>
                    )}
                    {cartItem.description && (
                      <div className="itemDescription grid-100">
                        {cartItem.description}
                      </div>
                    )}
                    {cartItem.color && (
                      <div className="itemParams grid-100">{`Color: ${cartItem.color}`}</div>
                    )}
                    {cartItem.size && (
                      <div className="itemParams grid-100">{`Size: ${cartItem.size}`}</div>
                    )}
                    {cartItem.productQty && (
                      <div className="itemParams grid-100">{`Quantity: ${cartItem.productQty}`}</div>
                    )}
                    {additionalCharge && additionalCharge > 0 ? (
                      <div className="itemParams grid-100">{`Additional Charge: ${additionalCharge}`}</div>
                    ) : (
                      ''
                    )}
                    {cartItem.shippingStatusMessage && (
                      <div className="itemParams grid-100">
                        {cartItem.shippingStatusMessage}
                      </div>
                    )}
                  </div>
                  <div className="grid-20">
                    {cartItem.price.adornments ? (
                      <React.Fragment>
                        <div className="strikeThrough itemPrice grid-100">{`${
                          cartItem.price.currencyCode
                        } ${cartItem.price.adornments[0].price *
                          cartItem.productQty +
                          additionalCharge}`}
                        </div>
                        <div className="itemPrice grid-100">{`${
                          cartItem.price.currencyCode
                        } ${cartItem.price.adornments[1].price *
                          cartItem.productQty +
                          additionalCharge}`}
                        </div>
                      </React.Fragment>
                    ) : (
                      <div className="itemPrice grid-100">{`${
                        cartItem.price.currencyCode
                      } ${cartItem.price.retailPrice * cartItem.productQty +
                        additionalCharge}`}
                      </div>
                    )}
                    {inStockObj &&
                      !inStockObj[`${cartItem.skuId}_${index}`] && (
                        <div className="grid-100 outOfStock">Out of Stock</div>
                      )}
                  </div>
                  <div className="itemDelete grid-5">
                    <button
                      onClick={() => {
                        this.onDelete(index);
                      }}
                      type="button"
                    >
                      X
                    </button>
                  </div>
                </div>
              );
            })
          ) : (
            <div>No items in your cart</div>
          )}
        </div>
        <div
          className={`grid-30 ${
            cartDataAvailable
              ? 'internationalCheckoutBanner'
              : 'internationalCheckoutBanner internationalCheckoutBannerEmpty'
          }`}
        >
          {cartDataAvailable && (
            <React.Fragment>
              <div className="grid-100 checkoutCharges">
                <p className="totalLabel">Order Subtotal</p>
                <p className="totalAmount">{`${currencyCode} ${cartTotal}`}</p>
              </div>
              {totalAdditionalCharge > 0 && (
                <div className="grid-100 checkoutCharges">
                  <p className="totalLabel">Additional Charges</p>
                  <p className="totalAmount">{`${currencyCode} ${totalAdditionalCharge}`}</p>
                </div>
              )}
              <div className="grid-100 checkoutOrderTotal">
                <p className="totalLabel">Order Total</p>
                <p className="totalAmount">{`${currencyCode} ${cartTotal +
                  totalAdditionalCharge}`}
                </p>
              </div>
              <div className="grid-100 checkoutButton">
                <button type="button" onClick={this.onCheckout}>
                  Checkout
                </button>
              </div>
              {inStockObj && values(inStockObj).includes(false) && (
                <div className="grid-100 checkoutOOS">
                  Few items are not available. Please remove the items and
                  Checkout
                </div>
              )}
              {(borderFreeError || this.props.cartInventoryCheckError) && (
                <div className="grid-100 checkoutOOS">
                  There is a problem during checkout. Please refresh the page
                  and try again
                </div>
              )}
              <div className="grid-100 checkoutNote">
                <p>
                  Please note: Delivery and processing fees, duties, and taxes
                  will be calculated during Checkout.
                </p>
              </div>
            </React.Fragment>
          )}
          <div className="checkoutBanner grid-100">
            <RenderContent placement="Promo" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isDomestic: get(state, 'locale.countryCode', 'US') === 'US',
    buyerCurrency: get(state, 'locale.currencyCode', 'USD'),
    countryCode: get(state, 'locale.countryCode', 'US'),
    jSessionId: get(state, 'session.JSESSIONID', ''),
    clientIp: get(state, 'api.requestContext.TRUE_CLIENT_IP', ''),
    cartInventoryCheckData: get(state, 'miniCart.cartInventoryCheck', ''),
    cartInventoryCheckError: get(state, 'miniCart.cartInventoryCheckError', ''),
    profileId: get(state.session, 'WID', '12345'),
    apiStatus: get(state.api, 'cms_content', {}),
  };
};

const mapDispatchToProps = {
  localCheckInventory,
  showSpinner,
  hideSpinner,
  getCMSContent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InternationalCart);
