import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CloseIcon from '@bgo-ui/common/client/common/components/CloseIcon/closeIcon';
import { closeModal } from '@bgo-ui/common/client/common/components/Modal/actions';
import './modal.scss';
import { Form, Formik } from 'formik';
import FormikNumberField from '../../../../common/Forms/FormikNumberField/FormikNumberField';
import SubmitButton from '../../../../common/Forms/FormButtons/SubmitButton/SubmitButton';
import { editAccountDetails } from '../../AccountDetailsPage/actions';
import { validationErrors } from '../../../../login/constants';

const phoneModal = props => {
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    const { phoneNumberField, closeModal } = props;
    if (phoneNumberField) {
      closeModal();
      setLoading(false);
    }
  }, [props.phoneNumberField]);

  const handleCloseClick = e => {
    props.closeModal();
    e.preventDefault();
  };

  const validatePhone = values => {
    const errors = {};
    if (!values.phoneNumber?.trim()) {
      errors.phoneNumber = validationErrors.phoneNumberEmpty;
    } else if (values.phoneNumber.length < 12) {
      errors.phoneNumber = validationErrors.invalidPhoneNumber;
    }
    return errors;
  };

  const onSubmit = values => {
    setLoading(true);
    const phoneNumber = `+1${values?.phoneNumber.replaceAll('-', '')}`;
    props.editAccountDetails({
      phoneNumber,
    });
  };

  const initialValues = {
    phoneNumber: '',
  };

  return (
    <div className="phone-popup">
      <CloseIcon onClick={handleCloseClick} />
      <div className="phone-container-popup-wrapper">
        <span className="title">Enter Your Phone Number</span>
        <Formik
          validate={validatePhone}
          onSubmit={onSubmit}
          initialValues={initialValues}
        >
          <Form autoComplete="off">
            <FormikNumberField
              name="phoneNumber"
              id="address-phone"
              placeholder="Phone Number"
              options={{ blocks: [3, 3, 4], delimiter: '-' }}
              fontSize
            />
            <SubmitButton
              isSubmitting={isLoading}
              type="submit"
              caption="Save"
              additionalClasses="new-forgot-password__submit-btn"
            />
          </Form>
        </Formik>
      </div>
    </div>
  );
};

phoneModal.propTypes = {};

const mapStateToProps = state => ({
  phoneNumberField:
    state?.myaccount?.myAccountDetails?.fields?.phoneNumber || false,
});

const mapDispatchToProps = {
  closeModal,
  editAccountDetails,
};

const PhoneModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(phoneModal);

export default PhoneModal;
