import React from 'react';
import get from 'lodash/get';
import startOfDay from 'date-fns/start_of_day';
import endOfDay from 'date-fns/end_of_day';
import isWithinRange from 'date-fns/is_within_range';
import './bossMessage.scss';

function BossMessage({ selectedSku, startDate, endDate, message }) {
  if (!startDate || !endDate) return null;

  const isTodayEligibleForBOSS = isWithinRange(
    startOfDay(new Date()),
    startOfDay(startDate),
    endOfDay(endDate),
  );
  const isSkuEligibleForBOSS =
    get(selectedSku, 'sellable') && get(selectedSku, 'shipFromStore');

  return isTodayEligibleForBOSS && isSkuEligibleForBOSS ? (
    <div className="grid-75 tablet-grid-100 mobile-grid-100 pdp-boss-msg">
      <div className="bossMessage">{message}</div>
    </div>
  ) : null;
}

export default BossMessage;
