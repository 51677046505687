import React from 'react';
import take from 'lodash/take';
import get from 'lodash/get';
import classnames from 'classnames';
import { unescape } from 'he';
import LazyLoad from 'react-lazy-load';
import SwatchImage from 'pdp/components/ProductPage/components/Image/image';
import Toggle from 'shared/components/Toggle/toggle';
import { isMobile, isDesktop } from 'client-utils/utilities-page';
import {
  SWATCHES_LIMIT_MOBILE,
  SWATCHES_LIMIT_TABLET,
  SWATCHES_LIMIT_DESKTOP,
  SWATCHES_LIMIT_MOBILE_MODERN,
  SWATCHES_LIMIT_TABLET_MODERN,
  SWATCHES_LIMIT_DESKTOP_MODERN,
  SWATCH_IMAGE_LOAD_TIMEOUT,
} from 'plp/constants';
import './colorSwatches.scss';

export default function ColorSwatches({
  colorOptions = [],
  selectedColorSwatchIndex,
  changeSelectedColorSwatchIndex,
  onMouseOverHandler,
  onMouseOutHandler,
  errorMessage,
  productId,
  updateSwatchesValue,
  plpImageSwatchLazyLoadBeta = false,
  moveProductTogglesToList = false,
  isPlp,
  hideSwatchOnErrorToggle = false,
  swatchOptimizationToggle = false,
  productComponentRedesign = false,
}) {
  const options = [...colorOptions];
  // See BGO-12485 for details.
  // eslint-disable-next-line no-unused-vars
  function filterMissingColorSwatches(colorOptions) {
    // options = Object.assign({}, colorOptions);
    // const optionsPartition = partition(options.values, 'displaySkuImg');
    // options.values = optionsPartition[0];
    // options.missingColorSwatchesCount = optionsPartition[1].length;
    // const optionsEmptyUrlPartition = partition(options.values, { url: '' });
    // options.values = optionsEmptyUrlPartition[1];
  }

  // filterMissingColorSwatches(colorOptions);

  const swatches = productComponentRedesign
    ? take(options, SWATCHES_LIMIT_DESKTOP_MODERN + 1)
    : take(options, SWATCHES_LIMIT_DESKTOP);

  function isDisplayableSwatch(options) {
    const swatchLength = options && options.length;
    return swatchLength > 1;
  }

  // const swatchList = classnames(
  //   'product-thumbnail__swatches__item',
  //   { 'product-thumbnail__swatches__item__modern': productComponentRedesign },
  //   'product-thumbnail__swatches__item--more-swatches',
  //   {
  //     'product-thumbnail__swatches__item__modern--more-swatches': productComponentRedesign,
  //   },
  //   {
  //     'hide-on-desktop':
  //       !productComponentRedesign &&
  //       shouldHidePlusSign(
  //         options.missingColorSwatchesCount,
  //         options.values.length,
  //         SWATCHES_LIMIT_DESKTOP
  //       ),
  //     'hide-on-mobile':
  //       !productComponentRedesign &&
  //       shouldHidePlusSign(
  //         swatches.missingColorSwatchesCount,
  //         swatches.values.length,
  //         SWATCHES_LIMIT_MOBILE
  //       ),
  //     'hide-on-tablet':
  //       !productComponentRedesign &&
  //       shouldHidePlusSign(
  //         swatches.missingColorSwatchesCount,
  //         swatches.values.length,
  //         SWATCHES_LIMIT_TABLET
  //       ),
  //   },
  //   {
  //     'hide-on-desktop':
  //       productComponentRedesign &&
  //       shouldHidePlusSign(
  //         options.missingColorSwatchesCount,
  //         options.values.length,
  //         SWATCHES_LIMIT_DESKTOP_MODERN
  //       ),
  //     'hide-on-mobile':
  //       productComponentRedesign &&
  //       shouldHidePlusSign(
  //         swatches.missingColorSwatchesCount,
  //         swatches.values.length,
  //         SWATCHES_LIMIT_MOBILE_MODERN
  //       ),
  //     'hide-on-tablet':
  //       productComponentRedesign &&
  //       shouldHidePlusSign(
  //         swatches.missingColorSwatchesCount,
  //         swatches.values.length,
  //         SWATCHES_LIMIT_TABLET_MODERN
  //       ),
  //   }
  // );

  function changeAlertText(currentTarget, swatchName) {
    currentTarget.parentNode.lastChild.innerText = `${swatchName} selected`;
  }

  function renderSwatch(
    swatch,
    swatchName,
    errorMessage,
    index,
    productId,
    updateSwatchesValue,
    isPlp,
    hideSwatchOnErrorToggle,
    swatchOptimizationToggle,
  ) {
    const swatchResizeUrl = swatch.url
      ? swatch.url.replace('/f_auto,q_auto', `/f_auto,q_auto,dpr_2.0,w_20`)
      : swatch.url;
    let defaultProps = {
      'data-color-name': swatchName,
      'data-color-key': swatch.key,
      src: swatchResizeUrl,
      alt: swatchName,
      title: swatchName,
      errorImage: swatch.errorUrl,
      errorMessage,
      timeout: SWATCH_IMAGE_LOAD_TIMEOUT,
    };
    if (hideSwatchOnErrorToggle === true) {
      defaultProps = {
        ...defaultProps,
        swatchIndex: index,
        productId,
        updateSwatchesValue,
        isPlp,
      };
    }
    let swatchMarkup;

    if (moveProductTogglesToList) {
      swatchMarkup = plpImageSwatchLazyLoadBeta ? (
        <LazyLoad debounce={false} throttle={0} offset={500}>
          <SwatchImage {...defaultProps} />
        </LazyLoad>
      ) : (
        <SwatchImage {...defaultProps} />
      );
    } else {
      swatchMarkup = (
        <Toggle
          feature="PLP_IMAGE_SWATCH_LAZY_LOAD_BETA"
          fallback={<SwatchImage {...defaultProps} />}
        >
          <LazyLoad debounce={false} throttle={0} offset={500}>
            <SwatchImage {...defaultProps} />
          </LazyLoad>
        </Toggle>
      );
    }

    if (swatchOptimizationToggle === true) {
      swatchMarkup = <SwatchImage {...defaultProps} />;
    }

    return swatchMarkup;
  }
  const totalColors = options.length;
  const hasMoreColorSwatches =
    totalColors > SWATCHES_LIMIT_DESKTOP ||
    (isMobile() && totalColors > SWATCHES_LIMIT_MOBILE);
  return (
    isDisplayableSwatch(options) && (
      <ul className="product-thumbnail__swatches__list">
        {swatches.map((swatch, index) => {
          if (isPlp === true && swatch.isVisible === false) return null;
          const classList = classnames(
            'product-thumbnail__swatches__item',
            {
              'product-thumbnail__swatches__item__modern': productComponentRedesign,
            },
            {
              'hide-on-medium-pro':
                (!productComponentRedesign &&
                  index >= SWATCHES_LIMIT_TABLET_MODERN) ||
                (productComponentRedesign &&
                  shouldHideSwatch(
                    swatches.length,
                    SWATCHES_LIMIT_TABLET_MODERN,
                    index,
                  )),

              'hide-on-tablet':
                (!productComponentRedesign && index >= SWATCHES_LIMIT_TABLET) ||
                (productComponentRedesign &&
                  shouldHideSwatch(
                    swatches.length,
                    SWATCHES_LIMIT_TABLET,
                    index,
                  )),
              'hide-on-mobile':
                (!productComponentRedesign && index >= SWATCHES_LIMIT_MOBILE) ||
                (productComponentRedesign &&
                  shouldHideSwatch(
                    swatches.length,
                    SWATCHES_LIMIT_MOBILE_MODERN,
                    index,
                  )),
              'hide-on-desktop':
                productComponentRedesign &&
                shouldHideSwatch(
                  swatches.length,
                  SWATCHES_LIMIT_DESKTOP_MODERN,
                  index,
                ),
              'product-thumbnail__swatches__item--selected':
                index === selectedColorSwatchIndex,
            },
            {
              'product-thumbnail__swatches__item__modern--selected':
                productComponentRedesign && index === selectedColorSwatchIndex,
            },
          );

          const swatchName = unescape(get(swatch, 'name', ''));

          return (
            <li
              key={index}
              className={classList}
              onClick={e => {
                changeAlertText(e.currentTarget, swatchName);
                changeSelectedColorSwatchIndex(index, swatch.key);
              }}
              onMouseOver={() => onMouseOverHandler(index)}
              onMouseOut={onMouseOutHandler}
            >
              <button
                className={classnames(
                  'product-thumbnail__swatches__item__button',
                  {
                    'product-thumbnail__swatches__item__button__modern': productComponentRedesign,
                  },
                )}
                title={swatchName}
                aria-label={
                  index === selectedColorSwatchIndex
                    ? `${swatchName} selected`
                    : swatchName
                }
              >
                {renderSwatch(
                  swatch,
                  swatchName,
                  errorMessage,
                  index,
                  productId,
                  updateSwatchesValue,
                  isPlp,
                  hideSwatchOnErrorToggle,
                  swatchOptimizationToggle,
                )}
              </button>
            </li>
          );
        })}
        {hasMoreColorSwatches && (
          <span
            className={`has-more-color-swatches${isMobile() ? '-mobile' : ''}`}
          >
            +{' '}
            {isDesktop()
              ? totalColors - SWATCHES_LIMIT_DESKTOP
              : totalColors - SWATCHES_LIMIT_MOBILE}{' '}
            more
          </span>
        )}

        <li role="alert" className="sr-only" />
      </ul>
    )
  );
}

export function shouldHideSwatch(swatchesCount, limit, index) {
  const listGreaterThanLimit = swatchesCount > limit;
  const isLastOrRemaining = index + 1 >= limit;
  return listGreaterThanLimit && isLastOrRemaining;
}

export function shouldHidePlusSign(
  missingColorSwatchesCount,
  swatchCount,
  limit,
) {
  return (
    (missingColorSwatchesCount === 0 || swatchCount === 0) &&
    swatchCount <= limit
  );
}
