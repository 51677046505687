import styled from 'styled-components';
import { isMobile } from 'client/utilities/utilities-page';

export const H1 = styled.h1`
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-size: 44px;
  line-height: 1.05;
  letter-spacing: 0.4px;

  ${isMobile &&
    `
    font-size: 34px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: normal;
  `}
`;

export const H2 = styled.h2`
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.07;
  letter-spacing: normal;
`;

export const H3 = styled.h3`
  font-size: 26px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.08;
  letter-spacing: normal;
`;

export const Subtitle1 = styled.h4`
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.2px;
`;

export const SubTitle2 = styled.h5`
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.2px;
`;

export const Subtitle3 = styled.h4`
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: 0.2px;
`;

export const BodyB1 = styled.span`
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: 0.4px;
`;

export const BodyB2 = styled.span`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.2px;
`;

export const BodyMsg = styled.span`
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.2px;
`;

export const EyeBrow1 = styled.span`
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 0.4px;
`;

export const EyeBrow2 = styled.span`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 0.2px;
`;

export const EyeBrow3 = styled.span`
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.67px;
  text-align: center;
`;

export const EyeBrow4 = styled.span`
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
`;

export const EyeBrow5 = styled.span`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.81;
  letter-spacing: 0.53px;
`;

export const EyeBrow6 = styled.span`
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
`;

export const EyeBrow7 = styled.span`
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 0.2px;
`;

export const CTAButton1 = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 0.3px;
`;

export const CTAButton2 = styled.span`
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.08;
  letter-spacing: 0.2px;
  border-bottom: 1px solid;
  border-color: inherit;
`;

export const QuoteText = styled.span`
  font-size: 26px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.54;
  letter-spacing: 0.46px;
`;
