import { commonContent } from '../../common/constants';

export const content = {
  title: 'READY FOR A<br />ONCE-IN-A-LIFETIME<br />EXPERIENCE?',
  desc:
    "Let our experienced, trusted Advisors arrange your inspiring adventure, secure those hard-to-get tickets, or reserve a table at that new restaurant*. We're here to handle all the details for your overnight recharge or bucket-list excursion — seamlessly.",
  links: [
    {
      id: commonContent.contactUs.id,
      content: commonContent.contactUs.title,
      hasScrollOffset: false,
    },
    {
      id: commonContent.bespokeExperiences.id,
      content: commonContent.bespokeExperiences.title,
      hasScrollOffset: true,
    },
    {
      id: commonContent.ourPartners.id,
      content: commonContent.ourPartners.title,
      hasScrollOffset: false,
    },
  ],
};
