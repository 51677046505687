import get from 'lodash/get';
import httpWithLogging from 'universal/http-client';
import { getIdToken } from 'client-utils/utilities-amplify';
import { isNewCartCheckoutEnabled } from 'client-utils/is-new-cart-checkout';

export const LOADING_REWARDS = 'LOADING_REWARDS';
export const RESOLVED_REWARDS_POINT = 'RESOLVED_REWARDS_POINT';
export const RESOLVED_REWARDS_PROMO = 'RESOLVED_REWARDS_PROMO';
export const RESOLVED_REWARDS_LENGTH = 'RESOLVED_REWARDS_LENGTH';
export const RESOLVED_REWARDS = 'RESOLVED_REWARDS';
export const REJECTED_REWARDS = 'REJECTED_REWARDS';

export function getRewards() {
  return (dispatch, getState) => {
    const state = getState();
    const disableATGToggle = get(state, 'toggles.DISABLE_ATG_CALLS', false);
    const isDataLoaded = get(state, 'rewards.loaded', false);
    if (!isDataLoaded) {
      const { session, amplifyConfig } = state;
      const requestApi = httpWithLogging(state, 10000);
      dispatch({ type: LOADING_REWARDS });
      getIdToken(
        amplifyConfig,
        isNewCartCheckoutEnabled(state),
        disableATGToggle,
      )
        .then(tokenInfo => {
          const ucaProfileId = tokenInfo?.user?.sub;
          const profileId = tokenInfo?.user?.preferred_username || ucaProfileId;
          const headers = {
            accountId: disableATGToggle
              ? profileId
              : get(session, 'DYN_USER_ID', ''),
            authorization: tokenInfo.idToken,
          };
          const callString = `${NMConfig.API_MY_ACCOUNT_REWARDS}`;
          return requestApi.get(`${callString}`, { headers });
        })
        .then(res => {
          if (res && res.data) {
            const rewardsLength = [...res.data.point, ...res.data.promo].length;
            dispatch({
              type: RESOLVED_REWARDS_POINT,
              payload: res.data.point,
            });
            dispatch({
              type: RESOLVED_REWARDS_PROMO,
              payload: res.data.promo,
            });
            dispatch({
              type: RESOLVED_REWARDS_LENGTH,
              payload: rewardsLength,
            });
            dispatch({ type: RESOLVED_REWARDS });
          }
        })
        .catch(() => {
          dispatch({ type: REJECTED_REWARDS });
        });
    }
    return undefined;
  };
}
