import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Copy from '../../../../common/Copy/Copy';
import SubmitButton from '../../../../common/Forms/FormButtons/SubmitButton/SubmitButton';
import CancelButton from '../../../../common/Forms/FormButtons/CancelButton/CancelButton';
import FormikTextField from '../../../../common/Forms/FormikTextField/FormikTextField';
import FormikNumberField from '../../../../common/Forms/FormikNumberField/FormikNumberField';
import FormikDropDownField from '../../../../common/Forms/FormikDropDown/FormikDropDown';
import { STATE_LIST } from '../../../stateList';
import { saveButtonText, cancelButtonText } from '../../../constants';
import FormikCardField from '../../../../common/Forms/FormikCardField/FormikCardField';

import './PaymentDetails.scss';

const cardMask = '•••• ';
const openModalThreeDots = '•••';
const ADDRESS_BOOK_MISSING_FILEDS_ERROR =
  'Entry error(s). Correct red item(s).';
const COUNTRY_LIST = [{ value: 'US', name: 'United States' }];

const PaymentDetails = ({
  paymentId,
  addressId,
  onSave,
  onCancel,
  myAccountContent,
  myAccountPayment,
  myAccountAddress,
}) => {
  const PAYMENT_EDIT_BILLING_DEFAULT = get(
    myAccountContent,
    'PAYMENTDETAILS-EDIT-BILLING-DEFAULT',
  );
  const PAYMENT_EDIT_PAYMENT_DEFAULT = get(
    myAccountContent,
    'PAYMENTDETAILS-EDIT-PAYMENT-DEFAULT',
  );
  const payment = myAccountPayment.filter(
    item => item.paymentId === paymentId,
  )[0];

  const initialPaymentFormValue = {
    cardNumber: `${payment.maskedCardNumber.replace(/X{4}/gi, cardMask)}`,
    expiryDate: `${payment.expMonth}/${payment.expYear}`,
    cvv: openModalThreeDots,
  };

  const isUS = country => {
    return country === 'US';
  };

  const validateForm = values => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = '';
    }
    if (!values.lastName) {
      errors.lastName = '';
    }
    const isUnitedStates = isUS(values.country);
    if (isUnitedStates && !values.state) {
      errors.state = '';
    }
    if (!isUnitedStates && !values.province) {
      errors.province = '';
    }
    if (!values.address1) {
      errors.address1 = '';
    }
    if (!values.city) {
      errors.city = '';
    }
    const minPostalCodeLength = isUnitedStates ? 5 : 1;
    if (!values.postalCode || values.postalCode.length < minPostalCodeLength) {
      errors.postalCode = '';
    }
    if (isUnitedStates) {
      const minPhoneNumberLength = 12;
      if (
        !values.phoneNumber ||
        values.phoneNumber.length < minPhoneNumberLength
      ) {
        errors.phoneNumber = '';
      }
    }
    return errors;
  };

  const onCountryChange = (e, formik) => {
    const newValue = e.target.value;
    const oldValue = formik.values.country;
    if (newValue !== oldValue && (isUS(newValue) || isUS(oldValue))) {
      formik.setFieldValue('state', '', false);
      formik.setFieldValue('province', '', false);
      formik.setFieldValue('postalCode', '', false);
      formik.setFieldValue('phoneNumber', '', false);
    }
  };

  const addressDetails =
    !isEmpty(addressId) &&
    myAccountAddress.find(address => address.addressId === addressId);
  const editFormValue = addressDetails || {};
  const countryList =
    myAccountContent && myAccountContent['MYACCOUNT-COUNTRYLIST']
      ? JSON.parse(
          myAccountContent['MYACCOUNT-COUNTRYLIST'].content[0].content[0].value,
        )
      : COUNTRY_LIST;
  const stateKey = `MYACCOUNT-STATELIST-US`;
  const rawStateList =
    myAccountContent && myAccountContent[stateKey]
      ? JSON.parse(myAccountContent[stateKey].content[0].content[0].value)
      : STATE_LIST;
  const stateList = rawStateList.some(i => i.value === '') // contains empty item?
    ? rawStateList
    : [{ value: '', name: '' }, ...rawStateList]; // add empty item
  const ADDRESS_BOOK_MISSING_FIELDS = get(
    myAccountContent,
    'ADDRESSBOOK-MISSING-FIELDS-ERROR',
    ADDRESS_BOOK_MISSING_FILEDS_ERROR,
  );
  return (
    <div className="payment-container">
      {PAYMENT_EDIT_PAYMENT_DEFAULT && (
        <Copy content={PAYMENT_EDIT_PAYMENT_DEFAULT} />
      )}
      <Formik initialValues={initialPaymentFormValue}>
        {() => (
          <Form>
            <div className="payment-form-field-container">
              <div className="form-full-width">
                <FormikCardField
                  name="cardNumber"
                  id="payment-card-number"
                  placeholder="Card Number"
                  cardType={payment.cardType}
                  fontSize
                />
              </div>
              <FormikCardField
                name="expiryDate"
                id="payment-expiry-date"
                placeholder="Expiry Date"
                fontSize
              />
              <FormikCardField
                name="cvv"
                id="payment-cvv"
                placeholder="CVV"
                fontSize
              />
            </div>
          </Form>
        )}
      </Formik>
      <div className="address-form-container">
        {PAYMENT_EDIT_BILLING_DEFAULT && (
          <Copy content={PAYMENT_EDIT_BILLING_DEFAULT} />
        )}
        <Formik
          initialValues={editFormValue}
          validate={validateForm}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              onSave(values);
              actions.setSubmitting(false);
            }, 1000);
          }}
          onReset={() => onCancel(false)}
        >
          {props => (
            <Form>
              <div className="form-fields-container">
                <FormikTextField
                  name="firstName"
                  id="address-first-name"
                  placeholder="First Name"
                  maxLength={40}
                  fontSize
                />
                <FormikTextField
                  name="lastName"
                  id="address-last-name"
                  placeholder="Last Name"
                  maxLength={40}
                  fontSize
                />
                <div className="form-full-width">
                  <FormikDropDownField
                    caption="Country:"
                    name="country"
                    id="address-country"
                    placeholder="Country"
                    onChange={e => onCountryChange(e, props)}
                    valuesArray={countryList}
                    value={props.values.country}
                    fontSize
                  />
                </div>
                <div className="form-full-width">
                  <FormikTextField
                    className="form-full-width"
                    name="address1"
                    id="address-address1"
                    placeholder="Address Line 1"
                    maxLength={30}
                    fontSize
                  />
                </div>
                <div className="form-full-width">
                  <FormikTextField
                    className="form-full-width"
                    name="address2"
                    id="address-address2"
                    placeholder="Address Line 2"
                    maxLength={30}
                    fontSize
                  />
                </div>
                <FormikTextField
                  name="city"
                  id="address-city"
                  placeholder="City"
                  maxLength={30}
                  fontSize
                />
                {isUS(props.values.country) ? (
                  <React.Fragment>
                    <FormikDropDownField
                      caption="States:"
                      placeholder="State"
                      valuesArray={stateList}
                      id="address-state"
                      name="state"
                      value={props.values.state}
                      fontSize
                    />
                    <FormikNumberField
                      name="postalCode"
                      id="address-postalCode"
                      placeholder="ZIP"
                      options={{ blocks: [5] }}
                      fontSize
                    />
                    <FormikNumberField
                      name="phoneNumber"
                      id="address-phone"
                      placeholder="Phone Number"
                      options={{ blocks: [3, 3, 4], delimiter: '-' }}
                      fontSize
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <FormikTextField
                      name="province"
                      id="address-state-non-us"
                      placeholder="State/Province/Area"
                      maxLength={40}
                      fontSize
                    />
                    <FormikTextField
                      name="postalCode"
                      id="address-postalCode-non-us"
                      placeholder="ZIP"
                      maxLength={30}
                      fontSize
                    />
                  </React.Fragment>
                )}
              </div>
              <div
                className={
                  props.submitCount > 0 && !isEmpty(props.errors)
                    ? 'actions-container-with-error'
                    : 'actions-container'
                }
              >
                <SubmitButton
                  isSubmitting={props.isSubmitting}
                  type="submit"
                  caption={saveButtonText}
                />
                <CancelButton
                  onClick={props.handleReset}
                  caption={cancelButtonText}
                />
              </div>
              {props.submitCount > 0 && !isEmpty(props.errors) && (
                <div className="error-msg">
                  <Copy content={ADDRESS_BOOK_MISSING_FIELDS} />
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  myAccountContent: get(state, 'myaccount.myAccountContent', false),
  myAccountPayment: get(state, 'myaccount.myAccountPayment', []),
  myAccountAddress: get(state, 'myaccount.myAccountAddress', []),
});

export default connect(mapStateToProps)(PaymentDetails);
