import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { connect } from 'react-redux';
import DomesticFooter from './DomesticFooter';

const FooterLinks = props => (
  <div>
    {/* {isCountry(props.country, UNITED_STATES) ?
      <DomesticFooter user={props.user} footerLinks={props.footerLinks} /> :
      <InternationalFooter />
    } */}
    <DomesticFooter footerLinks={props.footerLinks} />
  </div>
);

const mapStateToProps = state => ({
  country: state.locale.countryCode,
  user: state.user,
});

export default connect(mapStateToProps)(FooterLinks);
