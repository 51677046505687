import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { Link } from 'react-router';
import {
  setPageId,
  setPageVisited,
  PAGE_ID_RESET_PASSWORD,
} from 'shared/actions/actions-page';
import {
  hideSpinner,
  showSpinner,
} from 'shared/components/Spinner/spinner-actions';
import { resetPassword } from '../actions';
import { RegisterInputField } from '../../common/RegisterInputField/RegisterInputField';
import { PasswordInputWidget } from '../PasswordInputWidget/PasswordInputWidget';
import { validationErrors } from '../constants';
import './ResetPassword.scss';

const ResetPasswordWrapper = ({ children }) => (
  <div className="account-registration reset-password">
    <div className="account-registration__content-container">
      <div className="account-registration__form-block account-registration__form">
        <h2 className="account-registration__header">Reset password</h2>
        {children}
      </div>
      <div className="account-registration__form-block" />
    </div>
  </div>
);

export class DumbResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
      submitted: false,
      passwordValid: false,
    };
    this.props.setPageId(PAGE_ID_RESET_PASSWORD);
    this.props.setPageVisited({
      event_name: 'pageLoad',
      page_definition_id: 'reset_pw_incomplete',
      page_name: 'password reset',
      page_template: 'account',
      page_type: 'account',
    });
  }

  onSubmit = e => {
    e.preventDefault();

    const validations = this.validate();
    if (!Object.values(validations).includes(false)) {
      const { code: confirmationCode, id: username } = get(
        this.props,
        'location.query',
        {},
      );

      this.props.resetPassword({
        username,
        confirmationCode,
        password: this.state.password,
      });
    }
    return this.setState({ submitted: true });
  };

  handlePasswordValidChange = passwordValid => {
    this.setState({ passwordValid });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validate = () => {
    const { password, passwordValid, confirmPassword } = this.state;

    return {
      passwordIsValid: passwordValid,
      confirmPasswordIsNotEmpty: !!confirmPassword,
      passwordsMatch: password === confirmPassword,
    };
  };

  render() {
    const { isLoading, isLoaded, error } = this.props;

    if (isLoaded) {
      return (
        <ResetPasswordWrapper>
          <p className="password-has-been-reset-header">
            Your password has been reset
          </p>
          <Link to="/e/login">
            <button className="account-registration__button" type="button">
              Sign In
            </button>
          </Link>
        </ResetPasswordWrapper>
      );
    }

    const { submitted, confirmPassword } = this.state;
    let confirmPasswordError = null;

    if (submitted) {
      const { confirmPasswordIsNotEmpty, passwordsMatch } = this.validate();

      if (!confirmPasswordIsNotEmpty) {
        confirmPasswordError = validationErrors.confirmPasswordIsNotEmpty;
      } else if (!passwordsMatch) {
        confirmPasswordError = validationErrors.passwordsMatch;
      }
    }

    return (
      <ResetPasswordWrapper>
        <form onSubmit={this.onSubmit}>
          <PasswordInputWidget
            title="New password"
            name="password"
            submitted={submitted}
            onValidChange={this.handlePasswordValidChange}
            onChange={this.handleChange}
          />
          <RegisterInputField
            title="Confirm password"
            name="confirmPassword"
            error={confirmPasswordError}
            maxLength={40}
            type="password"
            value={confirmPassword}
            onChange={this.handleChange}
          />
          {error ? (
            <p className="account-registration__error-message">{error}</p>
          ) : null}
          <button
            type="submit"
            className="account-registration__button"
            disabled={isLoading}
          >
            Change Password
          </button>
        </form>
      </ResetPasswordWrapper>
    );
  }
}

const mapDispatchToProps = {
  startLoading: showSpinner,
  endLoading: hideSpinner,
  resetPassword,
  setPageId,
  setPageVisited,
};

const mapStateToProps = state => ({
  isLoading: get(state, 'login.resetPassword.isLoading', false),
  isLoaded: get(state, 'login.resetPassword.isLoaded', false),
  error: get(state, 'login.resetPassword.error', false),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbResetPassword);
