import React from 'react';
import { object, number, oneOfType, string } from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';

import TextOverImage from '../textOverImage/textOverImage';
import {
  defaultTrackingTags,
  PROMO_TILE_REPLACE_PATTERN,
} from '../../constants/constants';
import { getTags } from '../utils';
import { formatNumber } from '../../../utilities/utilities-number';

const updateTextWithNA = (textValue, NAValue) => {
  textValue.content[0].content.forEach(text => {
    text.value = text.value.replace(PROMO_TILE_REPLACE_PATTERN, NAValue);
  });

  return textValue;
};

/**
 * @function DumbCarouselPromoTile
 * @param {Object} props.parentTrackingTags
 * @param {Object} props.promoItem
 * @param {Number|String} props.totalNA
 * @returns {React.ReactElement}
 */
export const DumbCarouselPromoTile = ({
  parentTrackingTags,
  promoItem,
  totalNA,
}) => {
  const updatedPromo = cloneDeep(promoItem);
  const totalNAreplace = totalNA ? `${formatNumber(totalNA)}` : '';
  const { desktopText, tabletText, mobileText } = get(
    updatedPromo,
    'fields.text.fields',
  );
  if (!isEmpty(desktopText)) {
    updateTextWithNA(desktopText, totalNAreplace);
  }
  if (!isEmpty(tabletText)) {
    updateTextWithNA(tabletText, totalNAreplace);
  }
  if (!isEmpty(mobileText)) {
    updateTextWithNA(mobileText, totalNAreplace);
  }

  const trackingTags = get(promoItem, 'fields.trackingTags', []);
  const currentTags = getTags(trackingTags);
  const mergedTrackingTags = merge({}, parentTrackingTags, currentTags);

  return (
    <TextOverImage
      cmsContentItem={updatedPromo}
      parentTrackingTags={mergedTrackingTags}
    />
  );
};

DumbCarouselPromoTile.defaultProps = {
  parentTrackingTags: defaultTrackingTags,
};

DumbCarouselPromoTile.propTypes = {
  parentTrackingTags: object,
  promoItem: object,
  totalNA: oneOfType([number, string]),
};

const mapStateToProps = state => {
  return {
    totalNA: get(state, 'ecm.ecmDynamicProductInfo.totalNA'),
  };
};

export default connect(mapStateToProps)(DumbCarouselPromoTile);
