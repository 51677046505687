import {
  RESOLVED_RECOMMENDATIONS,
  RESET_RECOMMENDATIONS,
  RESOLVED_OOS_RECOMMENDATIONS,
  RESET_OOS_RECOMMENDATIONS,
} from '../actions';

export const defaultState = { ymal: [], oos: [] };

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESOLVED_RECOMMENDATIONS:
      return { ...state, ymal: action.payload };
    case RESOLVED_OOS_RECOMMENDATIONS:
      return { ...state, oos: action.payload };
    case RESET_RECOMMENDATIONS:
      return { ...state, ymal: [] };
    case RESET_OOS_RECOMMENDATIONS:
      return { ...state, oos: [] };
    default:
      return state;
  }
};
