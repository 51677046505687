import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import httpWithLogging from 'universal/http-client';
import { parseCopyData } from '../../utilities/copy-helpers';
import { defaultApiTimeout } from '../bg360/constants';
import {
  CLOSE_MY_ACCOUNT_MOBILE_NAV,
  IS_LOADING_FALSE,
  LOADING_CONTENT,
  OPEN_MY_ACCOUNT_MOBILE_NAV,
  REJECTED_CONTENT,
  REJECTED_CUSTOMER_EMAIL,
  REJECTED_UNSUBSCRIBED_NON_AUTH,
  RESOLVED_CONTENT,
  RESOLVED_CUSTOMER_EMAIL,
  RESOLVED_UNSUBSCRIBED_NON_AUTH,
} from './constants';

export function openMobileNav() {
  return dispatch => dispatch({ type: OPEN_MY_ACCOUNT_MOBILE_NAV });
}

export function closeMobileNav() {
  return dispatch => dispatch({ type: CLOSE_MY_ACCOUNT_MOBILE_NAV });
}

/**
 * @function getContentForMyAccount
 * @returns {Promise}
 */
export function getContentForMyAccount() {
  return (dispatch, getState) => {
    const state = getState();
    const content = state?.myaccount?.myAccountContent || {};
    if (isEmpty(content)) {
      const isMobile = state?.device?.isMobilePhone || false;
      const requestApi = httpWithLogging(state, 8000);
      const callString = `${NMConfig.API_MY_ACCOUNT_CONTENT}/${
        isMobile ? 'app' : 'desktop'
      }`;
      dispatch({ type: LOADING_CONTENT });
      return requestApi
        .get(callString, {})
        .then(res => {
          if (res && res.data) {
            dispatch({
              type: RESOLVED_CONTENT,
              payload: parseCopyData(res.data),
            });
          } else {
            dispatch({ type: REJECTED_CONTENT });
          }
        })
        .catch(() => dispatch({ type: REJECTED_CONTENT }));
    } else {
      return Promise.resolve();
    }
  };
}

const handleLoad = (dispatch, pageType) => {
  dispatch({ type: pageType });
  removeEventListener('load', handleLoad);
};

export function setResolvedPage(pageType) {
  return (dispatch, getState) => {
    const state = getState();
    const isDomLoadComplete = state?.page?.isDomLoadComplete || {};

    if (isDomLoadComplete) {
      return dispatch({ type: pageType });
    } else {
      addEventListener('load', handleLoad.bind(null, dispatch, pageType));
    }
    return undefined;
  };
}

export function getEmailFromCustomerKey(payload) {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state, defaultApiTimeout, true);
    const headers = {
      'Content-Type': 'application/json',
    };
    requestApi
      .post(
        `${NMConfig.API_COMM_PREF}/customer-key/decrypt`,
        payload.data,
        headers,
      )
      .then(res => {
        dispatch({
          type: RESOLVED_CUSTOMER_EMAIL,
          payload: res.data,
        });

        const data = {
          email: res.data.email,
          channel: 'ONLINE',
          updatedTime: moment()
            .utc()
            .toISOString(),
          source: 'Unsubscribe All',
          preferences: {
            marketing: {
              email: {
                optout: true,
              },
            },
          },
        };
        dispatch(unsubscribeWithUnAuth(data));
        // }
      })
      .catch(error => {
        dispatch({
          type: REJECTED_CUSTOMER_EMAIL,
        });
        dispatch({
          type: IS_LOADING_FALSE,
        });
      });
  };
}

export function unsubscribeWithUnAuth(payload) {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state, defaultApiTimeout, true);
    const headers = {
      'Content-Type': 'application/json',
      'X-NMG-BRAND': 'BG',
      'access-control-request-headers': 'content-type',
    };
    requestApi
      .put(
        `${NMConfig.API_COMM_PREF}/communication-preferences`,
        payload,
        headers,
      )
      .then(res => {
        dispatch({
          type: RESOLVED_UNSUBSCRIBED_NON_AUTH,
          payload: res.data,
        });
      })
      .catch(error => {
        dispatch({
          type: REJECTED_UNSUBSCRIBED_NON_AUTH,
        });
      });
    dispatch({
      type: IS_LOADING_FALSE,
    });
  };
}
