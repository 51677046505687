import get from 'lodash/get';
import { PAGE_ID_HOME } from 'shared/actions/actions-page';
import { NEW_HOMEPAGE_COOKIE_VALUE } from 'bgo-common/client/common/constants';

export const isNewHomePageEnabled = state => {
  const toggleValue = get(state, 'toggles.NEW_HOMEPAGE', false);
  const cookieValue =
    get(state, 'session.bgoHomePageCookie', '').toLowerCase() ===
    NEW_HOMEPAGE_COOKIE_VALUE;

  return toggleValue && cookieValue;
};

export const isNewHomePage = state => {
  const isHomePage = get(state, 'page.pageId', '') === PAGE_ID_HOME;

  return isHomePage && isNewHomePageEnabled(state);
};
