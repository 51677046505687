import get from 'lodash/get';
import { buildCookieString } from 'universal/utilities-cookies';
import httpWithLogging from 'universal/http-client';

function getFavoriteDesigners(state) {
  return new Promise((resolve) => {
    const requestApi = httpWithLogging(state);
    const session = get(state, 'session', {});
    let favoriteDesigners = {
      action: 'all',
      ids: []
    };
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        DYN_USER_CONFIRM: get(session, 'DYN_USER_CONFIRM', ''),
        W2A: get(session, 'W2A', ''),
        TLTSID: get(session, 'TLTSID', ''),
        AGA: get(session, 'AGA', '')
      })
    };
    requestApi
      .get(`${NMConfig.API_GET_FAVORITE_CATEGORIES}`, { headers })
      .then(successResponse => {
        favoriteDesigners.ids = successResponse.data.favoriteIds;
        resolve({ Favorite: favoriteDesigners });
      })
      .catch((err) => {
        if (!(err.response.status === 404)) {
          favoriteDesigners = null;
        }
        resolve({ Favorite: favoriteDesigners });
      });
  });
}

export default getFavoriteDesigners;
