import React from 'react';
import App from 'bgo-common/client/common/components/App/app';
import Loadable from 'react-loadable';
import { Route, IndexRoute } from 'react-router';
import { getParams } from 'bgo-common/client/utilities/utilities-router';

/* istanbul ignore next */
/* webpackChunkName: 'home' */
const AsyncHome = Loadable({
  loader: () => import('bgo-common/client/ecm/components/Home'),
  loading: () => false,
});

/* istanbul ignore next */
const AppNotFoundWrapper = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'app-notfound' */ 'bgo-common/client/common/components/App/app-notFound-wrapper'
    ),
  loading: () => false,
});

/* istanbul ignore next */
function scrollToTop() {
  const scrollToTop = getParams(window.location).scroll || 'true';
  if (scrollToTop === 'true') window.scrollTo(0, 0);
}

const routes = () => (
  <Route
    path="/(en-:countryCode)"
    component={App}
    onChange={scrollToTop}
    deferScripts
  >
    <IndexRoute component={AsyncHome} deferScripts />
    <Route status={404} path="*" component={AppNotFoundWrapper} />
  </Route>
);

export default routes;
