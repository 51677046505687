import React from 'react';
import ProductOptionSet from 'productpage/components/ProductOptionSet/productOptionSet';
import { getColorOptions } from 'productpage/selectors/getOptions';
import getColorSkus from 'productpage/selectors/getColorSkus';
import get from 'lodash/get';
import some from 'lodash/some';
import map from 'lodash/map';

const getColorOptionsForMonogramColorCarousel = product => {
  const allColorOptions = getColorOptions(product);
  const selectedSizeIndex = get(product, 'options.selectedSizeIndex', -1);
  const colorSkus = getColorSkus(product, selectedSizeIndex);

  if (!colorSkus || !colorSkus.length) {
    return allColorOptions.values;
  }
  const availableColorsExists = some(colorSkus, 'sellable');

  return availableColorsExists
    ? map(allColorOptions.values, (colorOption, index) => {
        colorOption.hideOnMonogramModal =
          !colorSkus[index] || !get(colorSkus[index], 'sellable');
        return colorOption;
      })
    : [];
};

export default props => {
  const {
    product = {},
    setSelectedColorIndex,
    setHoveredColorIndex,
    selectedColorIndex,
    hoveredColorIndex,
    id,
  } = props;

  const carouselColors = getColorOptionsForMonogramColorCarousel(product);
  if (carouselColors.length === 0) return null;

  return (
    <div className="monogram-color-dmg grid-100 tablet-grid-100 mobile-grid-100 grid-parent monogram_title_options">
      <div className="grid-100 tablet-grid-100 mobile-grid-100 grid-parent">
        <ProductOptionSet
          labelHeading="Choose Item"
          label="color"
          enableCarousel
          options={carouselColors}
          selectedIndex={selectedColorIndex}
          onClick={setSelectedColorIndex}
          hoveredIndex={hoveredColorIndex}
          onHover={setHoveredColorIndex}
          id={id}
        />
      </div>
    </div>
  );
};
