import httpWithLogging from 'universal/http-client';
import {
  hideSpinner,
  showSpinner,
} from 'shared/components/Spinner/spinner-actions';
import { defaultApiTimeout } from './constants';

export const RSVP_FORM_LOADING = 'RSVP_FORM_LOADING';
export const RSVP_FORM_RESOLVED = 'RSVP_FORM_RESOLVED';
export const RSVP_FORM_REJECTED = 'RSVP_FORM_REJECTED';

export function sendRsvp(data) {
  return (dispatch, getState) => {
    const requestApi = httpWithLogging(getState(), defaultApiTimeout);
    dispatch(showSpinner());
    dispatch({ type: RSVP_FORM_LOADING });
    return requestApi
      .post(NMConfig.API_BG360_SIGNUP, data)
      .then(() => dispatch({ type: RSVP_FORM_RESOLVED }))
      .catch(() => dispatch({ type: RSVP_FORM_REJECTED }))
      .finally(() => dispatch(hideSpinner()));
  };
}
