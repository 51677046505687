import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { EyeBrow1 } from '@bgo-ui/common/Styleguide/Typography';
import { getLinkDomain } from 'client-utils/utilities-navigation';
import './featuredDesigners.scss';
import classnames from 'classnames';

const BuildColumnWithCategories = categories => {
  return categories.map((category, index) => {
    const categoryRedText =
      category.attributes && category.attributes.redTextFlag ? 'red-text' : '';
    const anchorProps = categoryRedText ? { className: categoryRedText } : {};
    const domain = getLinkDomain();
    return (
      <li key={index}>
        <a href={`${domain}${category.url}`} {...anchorProps}>
          <EyeBrow1>{category.name} </EyeBrow1>
          {!isEmpty(
            category.attributes && category.attributes.boutiqueTextAdornments,
          ) ? (
            <span className="boutique-text-adornments">
              {category.attributes.boutiqueTextAdornments}
            </span>
          ) : (
            false
          )}
        </a>
      </li>
    );
  });
};

const BuildColumns = dataToDisplay => {
  const groupedCategories = dataToDisplay
    .reduce(
      (columns, category) => {
        // eslint-disable-next-line no-lone-blocks
        {
          columns.length < 5
            ? columns[columns.length - 1].push(category)
            : undefined;
        }
        return columns;
      },
      [[]],
    )
    .filter(col => col.length > 0);

  const arrayOfCategoriesGrouped = groupedCategories;
  return arrayOfCategoriesGrouped.map((categories, index) => (
    <div key={index}>
      <ul>{BuildColumnWithCategories(categories)}</ul>
    </div>
  ));
};

const FeaturedDesigners = ({ dataToDisplay, titleToDisplay }) => (
  <div
    className={classnames('col-item column is-4', {
      'mostWanted-designers-section':
        titleToDisplay.toLowerCase() === 'most wanted',
      'newToBg-designers-section': titleToDisplay.toLowerCase() === 'new to bg',
    })}
  >
    <h6 className="featured-heading">
      <EyeBrow1>{titleToDisplay}</EyeBrow1>
    </h6>
    {BuildColumns(dataToDisplay)}
  </div>
);

export default FeaturedDesigners;
