import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import get from 'lodash/get';
import { shouldLoad } from 'universal/http-client';
import isEmpty from 'lodash/isEmpty';
import reactCookie from 'react-cookie';
import SkipToContent from 'storefront/components/SkipToContent/skipToContent';
import Header from '@bgo-ui/common/components/Header/header';
import Navigation from '@bgo-ui/common/components/Navigation/navigation';
import Loadable from 'react-loadable';
import ScreenReaderAnnouncer from '@bgo-ui/common/components/ScreenReaderAlert/screenReaderAnnouncer';
import IdleTimerWrapper from '@bgo-ui/common/components/Util/IdleTimerWrapper';
import EcmGet, { ECMSlots } from 'ecm/components/EcmGet';
import { loseFocus } from 'shared/components/App/app-actions';
import OldFooter from 'bgo-common/components/Footer/footer';
import { Footer } from '@bgo-ui-resources/bgo-component-library';
import PromoCarousel from 'storefront/components/PromoCarousel/promoCarousel';
import Toggle from 'shared/components/Toggle/toggle';
import {
  PAGE_ID_HOME,
  PAGE_ID_PLP,
  PAGE_ID_SRP,
  PAGE_ID_PDP,
  PAGE_ID_CAT_TMPL,
  domLoadCompleted,
  PAGE_ID_CONF,
  PAGE_ID_PLA,
} from 'shared/actions/actions-page';
import ContentGlobal from 'bgo-common/client/cms/components/contentGlobal/contentGlobal';
import DownloadappBanner from '@bgo-ui/common/components/DownloadappBanner/banner';
import { configureAmplify } from '@bgo-ui/common/client/utilities/utilities-amplify';
import { createCookiesFromAbTests } from 'client-utils/utilities-abtest';
import { onModalAnchorClick } from 'shared/components/App/utilities';
import NavSliderContianer from '@bgo-ui/common/components/Navigation/NavSlider/navSliderContainer';
import { ECMParams } from 'ecm/constants';
import { openSignInModal } from 'bgo-ui-home/src/client/components/login/actions';
import { getSiteMapS3Data } from '../actions';
import './sitemap.scss';
import { SitemapFooter } from './SitemapFooter';

const Modal = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'modal' */ 'shared/components/Modal/modal'),
  loading: () => false,
});

class SiteMap extends Component {
  constructor() {
    super();
    this.handleModalAnchorClick = this.handleModalAnchorClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { sitemapApi, location } = this.props;

    if (!isEmpty(get(location, `query.${ECMParams.PARAM_LAUNCH}`, ''))) {
      reactCookie.save(
        ECMParams.PARAM_LAUNCH,
        get(location, `query.${ECMParams.PARAM_LAUNCH}`),
        { path: '/' },
      );
    }
    this.handleClickOutsideFocus = this.handleClickOutsideFocus.bind(this);
    if (this.props.haveAppSetDomLoadComplete) {
      this.handleLoad = this.handleLoad.bind(this);
    }

    if (shouldLoad(sitemapApi)) {
      this.props.getSiteMapS3Data();
    }
  }

  componentDidMount() {
    const { sitemapApi: { resolved = false } = {} } = this.props;
    // Added additional loading check as sometimes loading state is coming true from server
    if (!resolved) {
      this.props.getSiteMapS3Data();
    }

    if (this.props.dtSignInEnabled) {
      configureAmplify(
        this.props.amplifyConfig,
        this.props.isNewCartCheckoutEnabled,
        this.props.disableATGToggle,
      );
    }

    addEventListener('click', this.handleModalAnchorClick);
    if (this.props.haveAppSetDomLoadComplete) {
      addEventListener('load', this.handleLoad);
    }

    if (this.props.useInternalAbTestService) {
      createCookiesFromAbTests(this.props.abTestResults);
    }
  }

  componentWillUnmount() {
    removeEventListener('click', this.handleModalAnchorClick);
  }

  handleModalAnchorClick(e) {
    onModalAnchorClick(e, this.props);
  }

  handleClickOutsideFocus(e, currentComponentInFocus) {
    const component = this.container.querySelector(currentComponentInFocus);
    if (component && !component.contains(e.target)) {
      this.props.dispatch(loseFocus(currentComponentInFocus));
    }
  }

  handleLoad() {
    this.props.dispatch(domLoadCompleted());
    removeEventListener('load', this.handleLoad);
  }

  render() {
    const {
      isSliderMenuVisible,
      componentInFocus,
      modal = {},
      pageId = '',
      assistanceToggle,
      storesToggle,
      sitemapToggle,
      showCCPALink,
      specialOffersToggle,
      isBgoUiResourceFooter,
      oneTrustScriptToggle,
      loyaltyToggle,
      loyaltyTLToggle,
    } = this.props;
    const styles = isSliderMenuVisible
      ? {
          height: '87vh',
          overflow: 'hidden',
          position: 'relative',
          top: 0,
          left: 0,
        }
      : {};
    const outsideClickHandler = componentInFocus
      ? e => this.handleClickOutsideFocus(e, componentInFocus)
      : undefined;
    const isFullWidth =
      this.props.forceFullWidth ||
      pageId === PAGE_ID_HOME ||
      pageId === PAGE_ID_CONF ||
      pageId === PAGE_ID_PLP ||
      pageId === PAGE_ID_PDP ||
      (this.props.showPla && pageId === PAGE_ID_PLA) ||
      (this.props.isSRPFullWidth && pageId === PAGE_ID_SRP) ||
      pageId === PAGE_ID_CAT_TMPL;
    const isConfPage = pageId === PAGE_ID_CONF;
    const isPlpPage = pageId === PAGE_ID_PLP;
    const useCMSTicker =
      this.props.isSiteTickerAvailable && !isEmpty(this.props.cmsGlobal);

    return (
      <div id="appContainer">
        <ScreenReaderAnnouncer />
        <IdleTimerWrapper />
        <div
          id="contentContainer"
          className="application-container"
          ref={ref => {
            this.container = ref;
          }}
          onClick={outsideClickHandler}
          onTouchEnd={outsideClickHandler}
          aria-hidden={modal.open || isSliderMenuVisible}
          tabIndex="-1"
        >
          <DownloadappBanner />
          <SkipToContent />
          <div
            className={`grid-container full-bleed ${
              useCMSTicker ? 'cms-siteticker' : 'ecm-siteticker'
            }`}
          >
            {useCMSTicker ? (
              <ContentGlobal
                cmsContent={this.props.cmsGlobal}
                placement="SiteTicker"
              />
            ) : (
              <Toggle
                feature="ECM_SITETICKER"
                fallback={<PromoCarousel tabIndex={0} />}
              >
                <EcmGet ecmSlot={ECMSlots.SITE_TICKER} />
              </Toggle>
            )}
          </div>
          <Header />
          <Toggle
            feature="HIDE_MAIN_NAV"
            fallback={<Navigation router={this.props.router} />}
          >
            {false}
          </Toggle>

          {Object.keys(this.props.siteMapS3Data).length !== 0 && (
            <div
              className={classnames('grid-container full-width-container', {
                'mainContent-large grid-parent': isFullWidth,
                'plp-full-width': isPlpPage,
                'configurator-full-style': isConfPage,
              })}
            >
              <main role="main">
                <div id="sitemap" className="grid-100" style={styles}>
                  <div
                    id="content-header"
                    className="grid-parent grid-100 sitemap-header-container"
                  >
                    <div
                      id="sitemap-header-nav"
                      className="grid-parent grid-25 tablet-grid-30 mobile-grid-100 hide-on-mobile"
                    >
                      &nbsp;
                    </div>
                    <div
                      id="sitemap-header-content"
                      className="grid-75 tablet-grid-70 mobile-grid-100 grid-parent sitemap-header-content"
                    >
                      <div className="grid-100 grid-parent gutter-top">
                        <h1 id="sitemapdisplayname">Site Map</h1>
                      </div>
                    </div>
                  </div>
                  <div
                    id="content"
                    className="grid-parent grid-100 sitemap-container"
                  >
                    <div
                      id="sitemap-nav"
                      className="grid-parent grid-25 tablet-grid-30 mobile-grid-100 gutter-bottom"
                    >
                      {this.props.siteMapS3Data.sitemap.categories.map(
                        (category, i) => {
                          return category.featured ? (
                            <>
                              <h2
                                key={i}
                                className={
                                  i !== 0
                                    ? 'sitemap-category gutter-top'
                                    : 'sitemap-category'
                                }
                              >
                                {category.name}
                              </h2>
                              {category.categories.map((innerCategory, j) => {
                                if (
                                  innerCategory.name ===
                                  'Click for our Designer Index'
                                ) {
                                  return (
                                    <h3 key={j} className="sitemap-link">
                                      <a href={innerCategory.urn}>
                                        {innerCategory.name}{' '}
                                        <span className="arrowImage" />
                                      </a>
                                    </h3>
                                  );
                                }
                                return (
                                  <h3 key={j} className="sitemap-link">
                                    <a href={innerCategory.urn}>
                                      {innerCategory.name}
                                    </a>
                                  </h3>
                                );
                              })}
                            </>
                          ) : (
                            ''
                          );
                        },
                      )}
                    </div>
                    <div
                      id="sitemap-content"
                      className="grid-75 tablet-grid-70 mobile-grid-100 grid-parent sitemap-content"
                    >
                      <div className="grid-100 grid-parent">
                        {this.props.siteMapS3Data.sitemap.categories.map(
                          (category, i) => {
                            return category.featured === undefined ? (
                              <>
                                <div
                                  key={i}
                                  className="grid-33 tablet-grid-50 grid-parent gutter-bottom sitemap-column"
                                >
                                  <h2 className="sitemap-category">
                                    <a href={category.urn}>{category.name}</a>
                                  </h2>
                                  {category.categories.map(
                                    (innerCategory, j) => {
                                      return (
                                        <h3 key={j} className="sitemap-link">
                                          <a href={innerCategory.urn}>
                                            {innerCategory.name}
                                          </a>
                                        </h3>
                                      );
                                    },
                                  )}
                                </div>
                              </>
                            ) : (
                              ''
                            );
                          },
                        )}
                      </div>
                    </div>
                  </div>
                  <SitemapFooter
                    assistanceToggle={this.props.assistanceToggle}
                    storesToggle={this.props.storesToggle}
                  />
                </div>
              </main>
            </div>
          )}
        </div>
        {!isBgoUiResourceFooter ? (
          <OldFooter isFullWidth showCCPALink={showCCPALink} />
        ) : (
          <Footer
            configData={{ ...this.props, isFullWidth }}
            toggles={{
              assistanceToggle,
              storesToggle,
              sitemapToggle,
              showCCPALink,
              specialOffersToggle,
              oneTrustScriptToggle,
              loyaltyToggle,
              loyaltyTLToggle,
            }}
            openSignInModal={() => this.props.dispatch(openSignInModal())}
          />
        )}
        <Modal />
        <NavSliderContianer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    assistanceToggle: get(state, 'toggles.NEW_ASSISTANCE_UI', false),
    storesToggle: get(state, 'toggles.NEW_STORES_UI', false),
    siteMapS3Data: get(state, 'siteMapS3Data.siteMapData', {}),
    sitemapApi: state.api.sitemap_data,
    pageId: get(state, 'page.pageId', ''),
    isSliderMenuVisible: get(
      state,
      'navigation.navSlider.sliderMenuVisible',
      false,
    ),
    componentInFocus: get(state, 'app.componentInFocus', ''),
    modal: get(state, 'modal', {}),
    showPla: get(state, 'toggles.SHOW_PLA', false),
    isSRPFullWidth: get(state, 'toggles.SRP_FULL_WIDTH', false),
    haveAppSetDomLoadComplete: get(
      state,
      'toggles.HAVE_APP_SET_DOMLOADCOMPLETE',
      false,
    ),
    useInternalAbTestService: get(
      state,
      'toggles.USE_INTERNAL_ABTEST_SERVICE',
      false,
    ),
    abTestResults: get(state, 'abTests.abTestResults', {}),
    isFullWidth: get(state, 'toggles.HOMEPAGE_FULL_WIDTH', false),
    showCCPALink: get(state, 'toggles.BG_PHASE_2_CCPA_LINK', false),
    dtSignInEnabled: get(state, 'toggles.DT_SIGN_IN', false),
    amplifyConfig: get(state, 'amplifyConfig', {}),
    cmsGlobal: get(state, 'cms.global', []),
    isSiteTickerAvailable: get(state, 'toggles.CMS_SITE_TICKER', false),
    disableATGToggle: state?.toggles?.DISABLE_ATG_CALLS || false,
    sitemapToggle: state.toggles.DT_SiteMap,
    specialOffersToggle: state.toggles.DT_SPECIAL_OFFERS,
    isBgoUiResourceFooter: state.toggles.RESOURCE_FOOTER,
    oneTrustScriptToggle: get(state, 'toggles.ONE_TRUST_SCRIPT_BG', false),
    loyaltyToggle: state?.toggles?.LOYALTY || false,
    loyaltyTLToggle: state?.toggles?.BGO_LOYALTY_NEW || false,
  };
};

const mapDispatchToProps = {
  getSiteMapS3Data,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SiteMap);
