import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  PAGE_ID_RESET_PASSWORD_NEW,
  setPageId,
} from '@bgo-ui/common/client/common/actions/actions-page';
import AppLoginCommon from './app-new-login-common';
import NewResetPassword from '../../../login/v2/resetPassword/NewResetPassword/NewResetPassword';

class AppNewResetPassword extends Component {
  constructor(props) {
    super(props);
    props.setPageId(PAGE_ID_RESET_PASSWORD_NEW);
  }

  render() {
    return (
      <AppLoginCommon {...this.props}>
        <NewResetPassword location={this.props.location} />
      </AppLoginCommon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  setPageId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppNewResetPassword);
