/* eslint-disable no-else-return, no-unused-vars */

import { UNIT, GRAVITY } from './constants';

class BaseCropTransformationApplier {
  apply({ width, height }, transformation) {
    const dimensionUnit = this.getDimensionUnit(transformation);
    if (dimensionUnit === UNIT.PIXEL) {
      if (transformation.width && !transformation.height) {
        return this.applyForWidthInPixels({ width, height }, transformation);
      } else if (!transformation.width && transformation.height) {
        return this.applyForHeightInPixels({ width, height }, transformation);
      } else if (transformation.width && transformation.height) {
        return this.applyForWidthAndHeightInPixels(
          { width, height },
          transformation,
        );
      }
    } else if (dimensionUnit === UNIT.PERCENT) {
      if (transformation.width && !transformation.height) {
        return this.applyForWidthInPercents({ width, height }, transformation);
      } else if (!transformation.width && transformation.height) {
        return this.applyForHeightInPercents({ width, height }, transformation);
      } else if (transformation.width && transformation.height) {
        return this.applyForWidthAndHeightInPercents(
          { width, height },
          transformation,
        );
      }
    }
    return this.applyWithoutHeightAndWidth({ width, height }, transformation);
  }

  getDimensionUnit(transformation) {
    const widthUnit = transformation.width && transformation.width.unit;
    const heightUnit = transformation.height && transformation.height.unit;

    if (!widthUnit && !heightUnit) {
      return undefined;
    } else if (this.checkDimensionUnit(transformation, UNIT.PIXEL)) {
      return UNIT.PIXEL;
    } else if (this.checkDimensionUnit(transformation, UNIT.PERCENT)) {
      return UNIT.PERCENT;
    }

    throw new Error('Width and height should have consistent units.');
  }

  checkDimensionUnit(transformation, unit) {
    const widthUnit = transformation.width && transformation.width.unit;
    const heightUnit = transformation.height && transformation.height.unit;
    return (
      (widthUnit === unit && !heightUnit) ||
      (!widthUnit && heightUnit === unit) ||
      (widthUnit === unit && heightUnit === unit)
    );
  }

  applyForWidthInPixels({ width, height }, transformation) {}

  applyForWidthInPercents({ width, height }, transformation) {}

  applyForHeightInPixels({ width, height }, transformation) {}

  applyForHeightInPercents({ width, height }, transformation) {}

  applyForWidthAndHeightInPixels({ width, height }, transformation) {}

  applyForWidthAndHeightInPercents({ width, height }, transformation) {}

  applyWithoutHeightAndWidth({ width, height }, transformation) {
    return { width, height };
  }
}

export class ScaleTransformationApplier extends BaseCropTransformationApplier {
  applyForWidthInPixels({ width, height }, transformation) {
    return {
      width: transformation.width.value,
      height: height * (transformation.width.value / width),
    };
  }

  applyForWidthInPercents({ width, height }, transformation) {
    return {
      width: width * transformation.width.value,
      height: height * transformation.width.value,
    };
  }

  applyForHeightInPixels({ width, height }, transformation) {
    return {
      width: width * (transformation.height.value / height),
      height: transformation.height.value,
    };
  }

  applyForHeightInPercents({ width, height }, transformation) {
    return {
      width: width * transformation.height.value,
      height: height * transformation.height.value,
    };
  }

  applyForWidthAndHeightInPixels({ width, height }, transformation) {
    return {
      width: transformation.width.value,
      height: transformation.height.value,
    };
  }

  applyForWidthAndHeightInPercents({ width, height }, transformation) {
    return {
      width: width * transformation.width.value,
      height: height * transformation.height.value,
    };
  }
}

export class FitTransformationApplier extends ScaleTransformationApplier {
  applyForWidthAndHeightInPixels({ width, height }, transformation) {
    const boxWidth = transformation.width.value;
    const boxHeight = transformation.height.value;
    return this.fitToBox({ width, height }, { boxWidth, boxHeight });
  }

  applyForWidthAndHeightInPercents({ width, height }, transformation) {
    const boxWidth = width * transformation.width.value;
    const boxHeight = height * transformation.height.value;
    return this.fitToBox({ width, height }, { boxWidth, boxHeight });
  }

  fitToBox({ width, height }, { boxWidth, boxHeight }) {
    const horizontalDifference = boxWidth - width;
    const verticalDifference = boxHeight - height;

    if (verticalDifference < horizontalDifference) {
      return {
        width: (width * boxHeight) / height,
        height: boxHeight,
      };
    }
    return {
      width: boxWidth,
      height: (height * boxWidth) / width,
    };
  }
}

export class FillTransformationApplier extends ScaleTransformationApplier {
  applyForWidthInPixels({ width, height }, transformation) {
    if (transformation.aspectRatio) {
      return {
        width: transformation.width.value,
        height: transformation.width.value / transformation.aspectRatio,
      };
    }
    return super.applyForWidthInPixels({ width, height }, transformation);
  }

  applyForWidthInPercents({ width, height }, transformation) {
    if (transformation.aspectRatio) {
      const newWidth = width * transformation.width.value;
      return {
        width: newWidth,
        height: newWidth / transformation.aspectRatio,
      };
    }
    return super.applyForWidthInPercents({ width, height }, transformation);
  }

  applyForHeightInPixels({ width, height }, transformation) {
    if (transformation.aspectRatio) {
      return {
        width: transformation.height.value * transformation.aspectRatio,
        height: transformation.height.value,
      };
    }
    return super.applyForHeightInPixels({ width, height }, transformation);
  }

  applyForHeightInPercents({ width, height }, transformation) {
    if (transformation.aspectRatio) {
      const newHeight = height * transformation.height.value;
      return {
        width: newHeight * transformation.aspectRatio,
        height: newHeight,
      };
    }
    return super.applyForHeightInPercents({ width, height }, transformation);
  }

  applyWithoutHeightAndWidth({ width, height }, transformation) {
    if (!transformation.aspectRatio) {
      return { width, height };
    }
    if (transformation.aspectRatio <= 1) {
      return {
        width: height * transformation.aspectRatio,
        height,
      };
    } else {
      return {
        width,
        height: width / transformation.aspectRatio,
      };
    }
  }
}

export class PadTransformationApplier extends ScaleTransformationApplier {}

export class CropTransformationApplier extends FillTransformationApplier {
  apply({ width, height }, transformation) {
    const resWithoutOffset = super.apply({ width, height }, transformation);
    if (transformation.gravity !== GRAVITY.XY_CENTER) {
      const offsets = this.offsetToPixels({ width, height }, transformation);
      return {
        width: Math.min(width - offsets.x, resWithoutOffset.width),
        height: Math.min(height - offsets.y, resWithoutOffset.height),
      };
    }
    return {
      width: Math.min(width, resWithoutOffset.width),
      height: Math.min(height, resWithoutOffset.height),
    };
  }

  offsetToPixels({ width, height }, transformation) {
    return {
      x:
        transformation.x.unit === UNIT.PIXEL
          ? transformation.x.value
          : width * transformation.x.value,
      y:
        transformation.y.unit === UNIT.PIXEL
          ? transformation.y.value
          : height * transformation.y.value,
    };
  }
}
