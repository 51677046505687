import React from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';
import CardSlide from '../CardSlide/CardSlide';
import './BGcardSlider.scss';

function CustomSliderArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={classNames(className, 'incircle-slider__arrow')}
      onClick={onClick}
    />
  );
}

const getSliderSettings = () => {
  return {
    infinite: false,
    arrows: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    nextArrow: <CustomSliderArrow />,
    prevArrow: <CustomSliderArrow />,
    dots: true,
    dotsClass: 'incircle-slider__amount-info',
    appendDots: dots => {
      const activeSlide =
        Number(
          dots.filter(dot => dot.props.className === 'slick-active')[0].key,
        ) + 1;
      return (
        <div>
          Account {activeSlide} of {dots.length}
        </div>
      );
    },
  };
};

const BGcardSlider = ({ cards }) => {
  return (
    <div className="incircle-slider">
      {cards.length === 1 && (
        <div className="incircle-slider__amount-info one-account">
          Account 1 of 1
        </div>
      )}
      <Slider {...getSliderSettings()}>
        {cards.map(card => (
          <CardSlide key={card.tenderId} card={card} />
        ))}
      </Slider>
    </div>
  );
};

export default BGcardSlider;
