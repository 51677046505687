import ProductListErrorModal from 'plp/ProductListPage/components/ProductListErrorModal/productListErrorModal';
import ModalWithoutHeader from 'plp/ProductListPage/components/MessageModals/modalWithoutHeader';
import ModalWithHeader from 'plp/ProductListPage/components/MessageModals/modalWithHeader';
import QLProductSummary from 'plp/ProductListPage/components/QuickLook/components/QLProductSummary/qlProductSummary';
import AddToBagQLModal from 'plp/ProductListPage/components/QuickLook/components/AddToBagQlModal/addToBagQLModal';

import BopsModal from 'pdp/components/ProductPage/components/BopsModal/bopsModal';
import BopsModalOptimized from 'pdp/components/ProductPage/components/BopsModalOptimization/bopsModal';
import AddToBagMobileModal from 'pdp/components/AddToBagMobileModal/addToBagMobileModal';
import gwpMultiSkuModal from 'pdp/components/ProductPage/components/GwpModal/gwpMultiSkuModal';
import CutlineVideoModal from 'pdp/components/ProductPage/components/CutlineVideoModal/cutlineVideoModal';
import BeautyReplenishmentInfoModal from 'pdp/components/ProductPage/components/BeautyReplenishmentInfoModal/beautyReplenishmentInfoModal';
import MonogramModal from 'pdp/components/ProductPage/components/MonogramModal/monogramModal';
import MonogramModalDropship from 'pdp/components/ProductPage/components/MonogramModal/monogramModalDropship';
import AddToBagConfigModal from 'pdp/components/AddToBagConfigModal/addToBagConfigModal';
import ProductZoomModal from 'pdp/components/ProductPage/components/ProductZoomModal/ProductZoomModal';
import Bg360Modal from 'homeRoot/client/components/bg360/components/Bg360Modal/Bg360Modal';
import ForgotPasswordModal from 'homeRoot/client/components/login/ForgotPasswordModal/ForgotPasswordModal';
import ChangeEmailModal from 'homeRoot/client/components/myaccount/pages/AccountDetailsPage/totp/modals/ChangeEmailModal/ChangeEmailModal';
import ChangePasswordModal from 'homeRoot/client/components/myaccount/pages/AccountDetailsPage/totp/modals/ChangePasswordModal/ChangePasswordModal';
import PaymentActionModal from 'homeRoot/client/components/myaccount/pages/PaymentDetailsPage/PaymentActionModal/PaymentActionModal';
import PaymentDeleteModal from 'homeRoot/client/components/myaccount/pages/PaymentDetailsPage/PaymentActionModal/PaymentDeleteModal';
import AddressActionModal from 'homeRoot/client/components/myaccount/pages/AddressBookPage/AddressActionModal/AddressActionModal';
import AddressCTAModal from 'homeRoot/client/components/myaccount/pages/AddressBookPage/AddressActionModal/AddressCTAModalMobile';
// import QLOutfittingSummary from 'pdp/components/ProductPage/optimizationcomponents/QuickLook/components/QLProductSummary/qlProductSummary';

import EditWishlistModal from 'wishlist/components/WishlistPage/components/Wishlist/editWishlistModal';

import WelcomeMatPopup from 'client/storefront/components/WelcomeMat/components/welcomeMatPopup/welcomeMatPopup';

import LoginModal from 'homeRoot/client/components/login/v2/signupModal/index.js';
import PhoneModal from 'homeRoot/client/components/myaccount/pages/BgCreditCardPage/PhoneModal';
import ApplyNowModal from 'homeRoot/client/components/myaccount/pages/BgCreditCardPage/NewBgCreditCardPage/WhyInCircle/ApplyNowModal';
import ShopTheStyleModalComponent from './common/components/ShopTheStyle/ShopTheStyleModal/shopTheStyleModal';
import ShopTheLookModal from './common/components/ShopTheLookModal/shopTheLookModal';
import CountrySelectorModal from '../components/CountrySelector/CountrySelectorModal/countrySelectorModal';
import RestrictedItemsModal from '../components/Header/RestrictedItems/RestrictedItemsModal/restrictedItemsModal';
import InfoModal from './cms/components/infoModal/infoModal';
import HomeMenuModal from './cms/components/homeMenuModal/homeMenuModal';

export const ModalsWithHTMLContent = [
  CutlineVideoModal,
  BeautyReplenishmentInfoModal,
];

export default {
  BopsModal,
  BopsModalOptimized,
  AddToBagMobileModal,
  CountrySelectorModal,
  GwpMultiSkuModal: gwpMultiSkuModal,
  RestrictedItemsModal,
  ProductListErrorModal,
  QLProductSummary,
  // QLOutfittingSummary,
  ModalWithoutHeader,
  ModalWithHeader,
  MonogramModal,
  MonogramModalDropship,
  AddToBagQLModal,
  AddToBagConfigModal,
  ShopTheStyleModalComponent,
  ShopTheLookModal,
  ProductZoomModal,
  EditWishlistModal,
  WelcomeMatPopup,
  Bg360Modal,
  InfoModal,
  ForgotPasswordModal,
  ChangeEmailModal,
  ChangePasswordModal,
  HomeMenuModal,
  PaymentActionModal,
  PaymentDeleteModal,
  AddressActionModal,
  AddressCTAModal,
  LoginModal,
  PhoneModal,
  ApplyNowModal,
};
