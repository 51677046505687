import classNames from 'classnames';
import React from 'react';
import './EditButton.scss';

export default function EditButton({
  editText,
  cancelText,
  isEditing,
  isDisabled,
  onButtonClick,
  isGreyCssFix,
}) {
  return (
    <button
      type="button"
      className={classNames('account-details-edit-btn', {
        'black-colored-text': isGreyCssFix,
      })}
      onClick={onButtonClick}
      disabled={!!isDisabled}
    >
      {isEditing ? cancelText : editText}
    </button>
  );
}
