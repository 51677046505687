/* eslint import/no-cycle: 0 */
import React from 'react';
import merge from 'lodash/merge';
import ContentItem from '../contentItem/contentItem';
import { getTags } from '../utils';
import './buttonsGrid.scss';

const ButtonsGrid = ({
  cmsContentItem: { fields },
  trackTags: propsTrackTags = {},
}) => {
  const {
    numberOfColumns,
    buttonsWidth,
    buttonsHeight,
    buttons,
    trackingTags: ownTrackTags = [],
  } = fields;
  const btnsCopy = [...buttons];

  // Set up Tracking Tags
  const currentTags = getTags(ownTrackTags);
  const mergedTrackingTags = merge({}, propsTrackTags, currentTags);

  const getLayout = buttons => {
    return (
      <div>
        {buttons.map((item, index) => (
          <div
            style={{ width: buttonsWidth, height: buttonsHeight }}
            className="button--wrapper"
            key={index}
          >
            <ContentItem
              parentTrackingTags={mergedTrackingTags}
              cmsContentItem={item}
            />
          </div>
        ))}
      </div>
    );
  };

  const bpArray = new Array(Math.ceil(btnsCopy.length / numberOfColumns)).fill(
    numberOfColumns,
  );
  const layout = [];
  for (let i = 0; i < bpArray.length; i++) {
    if (btnsCopy.length === 0) break;
    layout.push(getLayout(btnsCopy.splice(0, bpArray[i])));
  }

  return <div className="buttons-grid--wrapper">{layout}</div>;
};

export default ButtonsGrid;
