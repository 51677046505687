import { parse as parseCookieString } from 'cookie';
import log from 'server/utilities/logger';

const buildCookieString = object => {
  const mappings = Object.keys(object)
    .map(key => {
      const value = object[key] || '';
      return value || value.length > 0 ? `${key}=${value};` : '';
    })
    .filter(string => string !== '');
  if (mappings.length > 0) {
    const first = mappings.pop();
    return mappings.reduce(
      (string, mapping) => `${string} ${mapping}`,
      `${first}`
    );
  }
  return '';
};

const safelyParse = data => {
  try {
    if (data !== 'undefined') {
      return JSON.parse(data || '{}');
    } else {
      return {};
    }
  } catch (e) {
    log.error(`Profile data cookie is malformed: ${data}`);
    return {};
  }
};

export { buildCookieString, parseCookieString, safelyParse };
