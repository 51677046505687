import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import './MyAccountPage.scss';
import MyAccountNavigation from './MyAccountNavigation/MyAccountNavigation';
import { closeMobileNav } from '../../actions';

export function DumbMyAccountPage(props) {
  return (
    <main
      className={classnames('my-account-page', {
        'my-account-page--mobile-nav-opened': props.mobileNavOpened,
      })}
    >
      <div className="my-account-page__nav-wrapper">
        <MyAccountNavigation onNavClick={props.closeMobileNav} />
      </div>
      <div className="my-account-page__content-wrapper">{props.children}</div>
    </main>
  );
}

const mapStateToProps = state => {
  return {
    mobileNavOpened: state.myaccount.mobileNavOpened,
  };
};
const mapDispatchToProps = {
  closeMobileNav,
};

const MyAccountPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbMyAccountPage);
export default MyAccountPage;
