import { ACTION_TYPES, MODAL_CONTENT_TYPES } from './constants';

const initialState = {
  email: '',
  processEmail: {
    isLoading: false,
    error: false,
  },
  contentType: MODAL_CONTENT_TYPES.ENTER_EMAIL,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOADING_PROCESS_EMAIL:
      return {
        ...state,
        processEmail: {
          isLoading: true,
          error: false,
        },
      };
    case ACTION_TYPES.REJECTED_PROCESS_EMAIL:
      return {
        ...state,
        processEmail: {
          isLoading: false,
          error: true,
        },
      };
    case ACTION_TYPES.PROCEED_TO_SIGN_IN:
      return {
        ...state,
        email: action.payload,
        processEmail: {
          isLoading: false,
          error: false,
        },
        contentType: MODAL_CONTENT_TYPES.SIGN_IN,
      };
    case ACTION_TYPES.PROCEED_TO_SIGN_UP:
      return {
        ...state,
        email: action.payload,
        processEmail: {
          isLoading: false,
          error: false,
        },
        contentType: MODAL_CONTENT_TYPES.SIGN_UP,
      };
    case ACTION_TYPES.CLEAR_PROCESS_EMAIL_ERROR:
      return {
        ...state,
        processEmail: {
          isLoading: false,
          error: false,
        },
      };
    case ACTION_TYPES.EDIT_EMAIL:
      return {
        ...state,
        contentType: MODAL_CONTENT_TYPES.ENTER_EMAIL,
      };
    case ACTION_TYPES.CLOSE_APPLY_NOW_MODAL:
      return {
        ...state,
        contentType: MODAL_CONTENT_TYPES.ENTER_EMAIL,
        email: '',
      };
    default:
      return state;
  }
};
