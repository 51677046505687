import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

const WelcomeModalContent = ({ shopCollectionsUrl }) => (
  <div className="welcome-container">
    <div className="title">
      Welcome to <span>BG 360°</span>!
    </div>
    <div className="description">
      One of our associates will be in touch shortly. In the meantime, explore
      the latest collections at bergdorfgoodman.com
    </div>
    <a href={shopCollectionsUrl} className="white-button">
      Shop collections
    </a>
  </div>
);

const mapStateToProps = state => ({
  shopCollectionsUrl: get(state, 'bg360.content.urls.shopCollections', null),
});

export default connect(mapStateToProps)(WelcomeModalContent);
