import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import unionBy from 'lodash/unionBy';
import find from 'lodash/find';
import every from 'lodash/every';
import some from 'lodash/some';
import { unescape } from 'he';
import { closeModal } from 'shared/components/Modal/actions';
import {
  setPersonalization,
  setSelectedCustomizations,
  setSelectedIndex,
} from 'pdp/components/ProductPage/actions';
import Button from 'shared/components/Button/button';
import { isMobile } from 'client-utils/utilities-page';
import getSelectedColorIndex from 'productpage/selectors/getSelectedColorIndex';
import MonogramOptionSet from './components/MonogramOptions/monogramOptionSet';
import Image from '../Image/image';
import ItemColorCarousel from './components/ItemColorCarousel/itemColorCarousel';
import { getDynamicScene7ImageURL } from '../Monogram/dynamicImageUtils/getDynamicScene7ImageURL';
import MonogramText from './components/MonogramText/monogramText';
import './monogramModal.scss';

export class DumbMonogramModal extends Component {
  constructor(props) {
    super(props);
    const { product = {} } = this.props;
    const defaultCustomizations = [
      { legacyType: 'mono style', selectedValue: 0 },
      { legacyType: 'thread color', selectedValue: 0 },
    ];
    const storedCustomizations = get(
      product,
      'customization.selectedCustomizations',
      [],
    );
    this.state = {
      selectedCustomizations:
        storedCustomizations.length === 0
          ? defaultCustomizations
          : storedCustomizations,
      selectedColorIndex: getSelectedColorIndex(product),
      hoveredColorIndex: -1,
      hoveredStyleIndex: -1,
      hoveredThreadColorIndex: -1,
      isIncompleteText: false,
      isInvalidText: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.getDefaultText = this.getDefaultText.bind(this);
    this.setSelectedOptionIndex = this.setSelectedOptionIndex.bind(this);
    this.setHoveredOptionIndex = this.setHoveredOptionIndex.bind(this);
    this.resetHoveredOptionIndex = this.resetHoveredOptionIndex.bind(this);
    this.setSelectedColorIndex = this.setSelectedColorIndex.bind(this);
    this.setHoveredColorIndex = this.setHoveredColorIndex.bind(this);
    this.setSelectedText = this.setSelectedText.bind(this);
    this.setIsIncompleteText = this.setIsIncompleteText.bind(this);
    this.setIsInvalidText = this.setIsInvalidText.bind(this);
    this.getSelectedText = this.getSelectedText.bind(this);
    this.getActiveMonoStyleChoiceIndex = this.getActiveMonoStyleChoiceIndex.bind(
      this,
    );
    this.getActiveThreadColorChoiceIndex = this.getActiveThreadColorChoiceIndex.bind(
      this,
    );
    this.scrollToMonogramText = this.scrollToMonogramText.bind(this);
  }

  getActiveMonoStyleChoiceIndex() {
    const monoStyle = find(this.state.selectedCustomizations, [
      'legacyType',
      'mono style',
    ]);
    return this.state.hoveredStyleIndex !== -1
      ? this.state.hoveredStyleIndex
      : get(monoStyle, 'selectedValue', 0);
  }

  getActiveThreadColorChoiceIndex() {
    const monoStyle = find(this.state.selectedCustomizations, [
      'legacyType',
      'thread color',
    ]);
    return this.state.hoveredThreadColorIndex !== -1
      ? this.state.hoveredThreadColorIndex
      : get(monoStyle, 'selectedValue', 0);
  }

  getSelectedText() {
    return get(
      find(this.state.selectedCustomizations, ['legacyType', 'output data']),
      'selectedValue',
      [],
    );
  }

  getDefaultText(monoStyleChoice, maxLengthOfText) {
    const type = get(monoStyleChoice, 'type');
    const name = 'Emily';
    switch (type) {
      case 'NAME':
        return [name.substring(0, maxLengthOfText)];
      case 'SINGLE_INIT':
        return ['W'];
      case 'THREE_INIT':
      case 'THREE_INIT_FML':
        return ['C', 'S', 'W'];
      default:
        return [];
    }
  }

  setSelectedOptionIndex(legacyType, index) {
    const selectedCustomizations = unionBy(
      [{ legacyType, selectedValue: index }],
      this.state.selectedCustomizations,
      'legacyType',
    );
    const newState = { selectedCustomizations };
    if (legacyType === 'mono style') {
      newState.isIncompleteText = false;
    }
    this.setState(newState);
  }

  setHoveredOptionIndex(legacyType, index) {
    if (legacyType === 'mono style') {
      this.setState({ hoveredStyleIndex: index });
    } else if (legacyType === 'thread color') {
      this.setState({ hoveredThreadColorIndex: index });
    }
  }

  setSelectedText(text) {
    const newState = unionBy(
      [{ legacyType: 'output data', selectedValue: text }],
      this.state.selectedCustomizations,
      'legacyType',
    );
    this.setState({ selectedCustomizations: newState });
  }

  setSelectedColorIndex(index) {
    this.setState({
      selectedColorIndex: index,
    });
  }

  setHoveredColorIndex(index) {
    this.setState({
      hoveredColorIndex: index,
    });
  }

  setIsIncompleteText(isIncompleteText) {
    this.setState({ isIncompleteText });
  }

  setIsInvalidText(isInvalidText) {
    this.setState({ isInvalidText });
  }

  resetHoveredOptionIndex(legacyType) {
    if (legacyType === 'mono style') {
      this.setState({ hoveredStyleIndex: -1 });
    } else if (legacyType === 'thread color') {
      this.setState({ hoveredThreadColorIndex: -1 });
    }
  }

  handleClose() {
    this.props.closeModal();
  }

  scrollToMonogramText() {
    if (!isMobile()) {
      return;
    }
    const monogramTextElement = document.getElementsByClassName(
      'monogram-text',
    )[0];
    if (typeof monogramTextElement !== 'undefined') {
      monogramTextElement.scrollIntoView(true);
    }
  }

  handleSave() {
    if (this.state.isInvalidText) {
      this.scrollToMonogramText();
      return;
    }
    const selectedText = this.getSelectedText();
    const isTextIncomplete =
      selectedText.length === 0 || some(selectedText, value => !value);
    if (isTextIncomplete) {
      this.setState({ isIncompleteText: true });
      this.scrollToMonogramText();
      return;
    }
    this.props.setSelectedCustomizations(this.state.selectedCustomizations);
    this.props.setSelectedIndex(this.state.selectedColorIndex, 'color');
    this.props.setPersonalization(true);
    this.props.closeModal('MonogramModal');
  }

  render() {
    const { product = {} } = this.props;
    const { customization = {}, designer = {} } = product;
    const customizationOptions = get(customization, 'customizationOptions', []);
    const monoStyle =
      find(customizationOptions, { legacyType: 'mono style' }) || {};
    const threadColor =
      find(customizationOptions, { legacyType: 'thread color' }) || {};
    const outputData =
      find(customizationOptions, { legacyType: 'output data' }) || {};
    const outputDataChoices = get(outputData, 'choices', []);
    const maxLengthOfText = get(outputDataChoices[0], 'inputLength');
    const monoStyleChoices = get(monoStyle, 'choices', []);

    const selectedColorIndex = this.state.selectedColorIndex;
    const hoveredColorIndex = this.state.hoveredColorIndex;
    const activeColorIndex =
      hoveredColorIndex === -1 ? selectedColorIndex : hoveredColorIndex;

    const selectedText = this.getSelectedText();
    const activeMonostyleChoiceIndex = this.getActiveMonoStyleChoiceIndex();
    const activeThreadColorChoiceIndex = this.getActiveThreadColorChoiceIndex();
    const isEmptyText = every(selectedText, value => {
      return !value;
    });

    const activeText = isEmptyText
      ? this.getDefaultText(
          monoStyleChoices[activeMonostyleChoiceIndex],
          maxLengthOfText,
        )
      : selectedText;

    const imageURL = getDynamicScene7ImageURL(
      product,
      activeColorIndex,
      activeMonostyleChoiceIndex,
      activeThreadColorChoiceIndex,
      activeText,
    );
    const productName = unescape(product.name || '');
    const altText = designer.name
      ? `${unescape(designer.name)}, ${productName}`
      : productName;
    const activeMonoStyleChoice =
      monoStyleChoices[this.getActiveMonoStyleChoiceIndex()];
    const activeMonoStyleChoiceType = get(activeMonoStyleChoice, 'type', '');
    const defaultText = this.getDefaultText(
      activeMonoStyleChoice,
      maxLengthOfText,
    );

    return (
      <div
        className="monogram-modal grid-100 tablet-grid-100 mobile-grid-100 grid-parent"
        role="document"
      >
        <div className="monogram-modal__options grid-60 push-40 tablet-grid-60 tablet-push-40 mobile-grid-100 monogram-modal__spacing">
          <ItemColorCarousel
            product={product}
            setSelectedColorIndex={this.setSelectedColorIndex}
            setHoveredColorIndex={this.setHoveredColorIndex}
            selectedColorIndex={this.state.selectedColorIndex}
            hoveredColorIndex={this.state.hoveredColorIndex}
            id="colorMono"
          />
          <MonogramOptionSet
            optionLabel="Choose Monogram Style"
            option={monoStyle}
            selectedIndex={this.getActiveMonoStyleChoiceIndex()}
            setSelectedOption={this.setSelectedOptionIndex}
            setHoverIndex={this.setHoveredOptionIndex}
            resetHoverIndex={this.resetHoveredOptionIndex}
            titlePrepend="Style "
            id="styleMono"
          />
          <MonogramText
            maxLengthOfText={maxLengthOfText}
            monoStyleChoiceType={activeMonoStyleChoiceType}
            selectedText={[...selectedText]}
            defaultText={defaultText}
            setSelectedText={this.setSelectedText}
            isIncompleteText={this.state.isIncompleteText}
            isInvalidText={this.state.isInvalidText}
            setIsIncompleteText={this.setIsIncompleteText}
            setIsInvalidText={this.setIsInvalidText}
          />
          <MonogramOptionSet
            optionLabel="Choose Thread Color"
            option={threadColor}
            selectedIndex={this.getActiveThreadColorChoiceIndex()}
            setSelectedOption={this.setSelectedOptionIndex}
            setHoverIndex={this.setHoveredOptionIndex}
            resetHoverIndex={this.resetHoveredOptionIndex}
            titlePrepend=""
            id="threadMono"
          />
          {isMobile() ? (
            <span className="grid-parent">
              Preview:
              {!product.productFlags.previewSupported && (
                <span className="monogram-modal__no-preview-text">
                  {' '}
                  No Preview Available
                </span>
              )}
            </span>
          ) : null}
        </div>

        <div className="monogram-modal__image grid-40 pull-60 tablet-grid-40 tablet-pull-60 mobile-grid-100 grid-parent">
          <div className="monogram-modal__image__overlay">
            {!product.productFlags.previewSupported && !isMobile() && (
              <div className="monogram-modal__image__overlay__no-preview">
                <span>No Preview Available</span>
              </div>
            )}
            <Image src={imageURL} alt={altText} />
          </div>
        </div>
        <div className="monogram-modal__action grid-60 push-40 tablet-grid-60 tablet-push-40 mobile-grid-100 monogram-modal__spacing">
          <div className="monogram-modal__disclaimer-text">
            For assistance, call 1-888-774-2424 or{' '}
            <a
              id="email-us"
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.bergdorfgoodman.com/assistance/contactUs.jsp?emailType=custemailforwarding&icid=foot_email_us"
            >
              email us
            </a>
            . <b>Personalized items cannot be returned or exchanged;</b> take
            special care when making selections. Text will be proportionate to
            item size.
            <br />
            <br />
            <b>Consider delivery time when placing your order:</b> Linens,
            shoes, and handbags 2-4 weeks; stationery 3-4 weeks. For other
            items, see the excepted ship date in your shopping bag at checkout.
          </div>
          <div className="monogram-modal__save-button grid-30 tablet-grid-30 mobile-grid-100 grid-parent">
            <Button ariaLabel="Save" value="Save" onClick={this.handleSave} />
          </div>
          <div className="monogram-modal__cancel-button grid-30 tablet-grid-30 mobile-grid-100 grid-parent">
            <Button
              ariaLabel="Cancel"
              secondary
              value="Cancel"
              onClick={this.handleClose}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, { product: { id: productId } }) => ({
  closeModal: type => dispatch(closeModal(type)),
  setPersonalization: (...args) =>
    dispatch(setPersonalization(productId, ...args)),
  setSelectedCustomizations: (...args) =>
    dispatch(setSelectedCustomizations(productId, ...args)),
  setSelectedIndex: (...args) => dispatch(setSelectedIndex(productId, ...args)),
});
export default connect(
  undefined,
  mapDispatchToProps,
)(DumbMonogramModal);
