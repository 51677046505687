import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import EditButton from '../EditButton/EditButton';
import {
  cancelButtonText,
  EDIT_SECTION_INTERESTED_IN,
  editButtonText,
} from '../../../constants';
import {
  closeAccountDetailsEdit,
  openAccountDetailsEdit,
  updateAdditionalAccountInfo,
} from '../actions';
import { getEditState } from '../helpers';
import SubmitButton from '../../../../common/Forms/FormButtons/SubmitButton/SubmitButton';
import './InterestedInField.scss';
import FormikRadioField from '../../../../common/Forms/FormikRadioField/FormikRadioField';

const SECTION = EDIT_SECTION_INTERESTED_IN;

function DumbInterestedInField(props) {
  const {
    mostlyInterestedIn,
    isEditOpened,
    isEditDisabled,
    closeAccountDetailsEdit,
    openAccountDetailsEdit,
    error,
    updateAdditionalAccountInfo,
    isUpdating,
    isGreyCssFix,
  } = props;

  const handleEditClick = () => {
    isEditOpened ? closeAccountDetailsEdit() : openAccountDetailsEdit(SECTION);
  };

  const initialFormValue = {
    mostlyInterestedIn,
  };

  const submitForm = values => {
    if (values.mostlyInterestedIn !== mostlyInterestedIn) {
      updateAdditionalAccountInfo({
        mostlyInterestedIn: values.mostlyInterestedIn,
      });
    } else {
      closeAccountDetailsEdit();
    }
  };

  return (
    <div className="account-details-page__field-wrapper" aria-live="polite">
      <div className="account-details-page__field-header">
        <h3 className="account-details-page__field-label">Interested in</h3>
        <div className="account-details-page__field-edit">
          <EditButton
            editText={editButtonText}
            cancelText={cancelButtonText}
            isEditing={isEditOpened}
            isDisabled={isEditDisabled || isUpdating}
            onButtonClick={handleEditClick}
            isGreyCssFix={isGreyCssFix}
          />
        </div>
      </div>
      <div className="account-details-page__field-content">
        {isEditOpened ? (
          <Formik initialValues={initialFormValue} onSubmit={submitForm}>
            {({ values }) => (
              <Form>
                <div className="custom-mobile-field interested-in__container">
                  <FormikRadioField
                    id="womenswear"
                    name="mostlyInterestedIn"
                    label="Womenswear"
                    value="womenswear"
                    checkedValue={values.mostlyInterestedIn}
                  />
                  <FormikRadioField
                    id="menswear"
                    name="mostlyInterestedIn"
                    label="Menswear"
                    value="menswear"
                    checkedValue={values.mostlyInterestedIn}
                  />
                </div>
                {error && (
                  <p className="account-details-page__edit-error interested-in__error">
                    {error}
                  </p>
                )}
                <div className="account-details-page__edit-submit">
                  <SubmitButton
                    isSubmitting={isUpdating}
                    type="submit"
                    caption="Save"
                    additionalClasses="account-details-page__edit-submit-btn"
                  />
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <p className="account-details-page__field-value interested-in__value">
            {mostlyInterestedIn || 'Select your fashion interests'}
          </p>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  mostlyInterestedIn: get(
    state,
    'myaccount.myAccountDetails.fields.mostlyInterestedIn',
    null,
  ),
  isUpdating: get(state, 'api.update_additional_user_info.loading', false),
  error: get(
    state,
    'myaccount.myAccountDetails.updateAdditionalInfoError',
    null,
  ),
  ...getEditState(state, SECTION),
  isGreyCssFix: get(state, 'toggles.GREYCOLOR_CSS_FIX', false),
});

const mapDispatchToProps = {
  openAccountDetailsEdit,
  closeAccountDetailsEdit,
  updateAdditionalAccountInfo,
};

const InterestedInField = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbInterestedInField);

export default InterestedInField;
