import React, { Component } from 'react';
import MonogramHeader from './MonogramHeader/MonogramHeader';
import CamelCaseNoNumScInput from './MonogramInputs/CamelCaseNoNumScInput';
import CamelCaseInput from './MonogramInputs/CamelCaseInput';
import CamelCaseNotReqInput from './MonogramInputs/CamelCaseNotReqInput';
import EmailInput from './MonogramInputs/EmailInput';
import GenericTextInput from './MonogramInputs/GenericTextInput';
import GenericTextNotReqInput from './MonogramInputs/GenericTextNotReqInput';
import NumberInput from './MonogramInputs/NumberInput';
import PhoneInput from './MonogramInputs/PhoneInput';
import UpperCaseNoNumScInput from './MonogramInputs/UpperCaseNoNumScInput';
import './MonogramTextDropship.scss';

export default class MonogramTextDropship extends Component {
  constructor(props) {
    super(props);
    this.constructInputs = this.constructInputs.bind(this);
  }

  constructInputs() {
    return this.props.options.map(option => {
      if (option.type === 'TEXT') {
        const choiceType = option.choices[0].type;
        switch (choiceType) {
          case 'CAMEL_CASE':
            return (
              <CamelCaseInput
                key={option.id}
                option={option}
                productId={this.props.productId}
              />
            );
          case 'CAMEL_CASE_NOT_REQ':
            return (
              <CamelCaseNotReqInput
                key={option.id}
                option={option}
                productId={this.props.productId}
              />
            );
          case 'GENERIC_TEXT':
            return (
              <GenericTextInput
                key={option.id}
                option={option}
                productId={this.props.productId}
              />
            );
          case 'GENERIC_TEXT_NOT_REQ':
            return (
              <GenericTextNotReqInput
                key={option.id}
                option={option}
                productId={this.props.productId}
              />
            );
          case 'NUMBER':
            return (
              <NumberInput
                key={option.id}
                option={option}
                productId={this.props.productId}
              />
            );
          case 'EMAIL':
            return (
              <EmailInput
                key={option.id}
                option={option}
                productId={this.props.productId}
              />
            );
          case 'CAMEL_CASE_NO_NUM_SC':
            return (
              <CamelCaseNoNumScInput
                key={option.id}
                option={option}
                productId={this.props.productId}
              />
            );
          case 'PHONE':
            return (
              <PhoneInput
                key={option.id}
                option={option}
                productId={this.props.productId}
              />
            );
          case 'UPPER_CASE_NO_NUM_SC':
            return (
              <UpperCaseNoNumScInput
                key={option.id}
                option={option}
                productId={this.props.productId}
              />
            );
          default:
            return (
              <GenericTextInput
                key={option.id}
                option={option}
                productId={this.props.productId}
              />
            );
        }
      }
      return null;
    });
  }

  render() {
    return (
      <div className="monogram-text-dmg monogram_title_options">
        <MonogramHeader
          group={this.props.group}
          name={this.props.options[0].displayName}
        />
        <div className="monogram-text-dmg__user-input grid-100 tablet-grid-100 mobile-grid-100 grid-parent">
          <fieldset>{this.constructInputs()}</fieldset>
        </div>
      </div>
    );
  }
}
