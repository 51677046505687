import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import RewardInfo from '../RewardInfo/RewardInfo';
import Copy from '../../../../common/Copy/Copy';
import BackUpCopy from '../BackUpCopy/BackUpCopy';
import './RewardsList.scss';

const RewardsList = ({
  cards,
  myAccountContent,
  isIncircle,
  loyHubToggle,
  bgoLoyaltyToggle,
  yourPointsDescription,
}) => {
  const getContentId = () => {
    if (isIncircle) {
      return 'MYREWARDS-INCIRLE-HAS-CARDS';
    } else if (bgoLoyaltyToggle) {
      return 'MYREWARDS-GIFTCARDS-HAS-CARDS-LOYALTY';
    }

    return 'MYREWARDS-GIFTCARDS-HAS-CARDS';
  };

  const contentId = getContentId();

  return (
    <div
      className={classNames('rewards-container', loyHubToggle && 'loyalty-hub')}
    >
      {!loyHubToggle && !isEmpty(get(myAccountContent, contentId, [])) ? (
        <div className="rewards-container__info">
          <Copy content={get(myAccountContent, contentId)} />
        </div>
      ) : (
        <BackUpCopy
          isIncircle={isIncircle}
          loyaltyHub={loyHubToggle}
          bgoLoyaltyToggle={bgoLoyaltyToggle}
          yourPointsDescription={yourPointsDescription}
        />
      )}
      <div
        className={classNames(
          'rewards-container__list-wrapper',
          isIncircle && cards?.length === 1 && 'text-center',
        )}
      >
        <div className="rewards-container__list">
          {cards.map(card => (
            <RewardInfo
              key={card.accountNumber}
              card={card}
              isIncircle={isIncircle}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  myAccountContent: get(state, 'myaccount.myAccountContent', true),
  loyHubToggle: state?.toggles?.LOY_HUB || false,
  bgoLoyaltyToggle: state?.toggles?.BGO_LOYALTY_NEW || false,
  yourPointsDescription: get(
    state,
    'loyaltyConfig.textConstants.yourPointsDescription',
    '',
  ),
});

export default connect(mapStateToProps)(RewardsList);
