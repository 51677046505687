import favOverIcon from 'bgo-common/assets/images/favorite-outlined.svg';
import selectedFavIcon from 'bgo-common/assets/images/favorites_selected.svg';
import classNames from 'classnames';
import { isTouchDevice } from 'client-utils/utilities-page';
import { toggleFavorite } from 'pdp/components/ProductPage/actions';
import getProduct from 'pdp/components/ProductPage/selectors/getProduct';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import screenReaderAnnouncer from 'bgo-common/components/ScreenReaderAlert/screenReaderAnnouncer-actions';
import './favoriteComponent.scss';

export class FavoriteComponent extends Component {
  constructor(props) {
    super(props);
    this.toggleFavoriteStatus = this.toggleFavoriteStatus.bind(this);
    this.handlerFavoriteHoverOn = this.handlerFavoriteHoverOn.bind(this);
    this.handlerFavoriteHoverOff = this.handlerFavoriteHoverOff.bind(this);
    this.state = {
      isFavorite: props.isFavorite,
      favorite: props.isFavorite
        ? { icon: selectedFavIcon }
        : { icon: favOverIcon },
      favText: 'Add to favorites',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isFavorite, favAddRemoveStatus } = nextProps;

    if (favAddRemoveStatus === 'error') {
      return;
    }

    if (favAddRemoveStatus !== this.props.favAddRemoveStatus) {
      if (!this.state.isFavorite) {
        this.props.screenReaderAnnouncer('Added');
      } else {
        this.props.screenReaderAnnouncer('Removed');
      }
    }

    if (favAddRemoveStatus && favAddRemoveStatus !== '') {
      this.setFavoriteStatus(favAddRemoveStatus === 'favorite');
      return;
    }

    this.setFavoriteStatus(isFavorite);
  }

  setFavoriteStatus(status) {
    this.setState({
      isFavorite: status,
      favorite: status ? { icon: selectedFavIcon } : { icon: favOverIcon },
    });
  }

  handlerFavoriteHoverOn() {
    if (!this.state.isFavorite && !isTouchDevice()) {
      this.setState({ favorite: { ...this.state.favorite } });
    }
  }

  handlerFavoriteHoverOff() {
    if (!this.state.isFavorite && !isTouchDevice()) {
      this.setState({
        favorite: { ...this.state.favorite },
      });
    }
  }

  toggleFavoriteStatus() {
    const { product, toggleFavorite } = this.props;
    const {
      id,
      metadata: { cmosCatalogId, cmosItem, pimStyle },
    } = product;

    toggleFavorite(
      { id, cmosCatalogId, cmosItem, pimStyle },
      this.state.isFavorite,
    );
  }

  render() {
    const { isFavorite } = this.state;
    const favoriteClassNames = classNames('favorite', 'controls-icon');
    const mainMediaFavText = classNames('controls-label', {
      'fav-text--selected': isFavorite,
    });
    return (
      <div id="favContainer">
        <button
          aria-label={
            this.state.isFavorite ? 'Remove from favorites' : 'Add to favorites'
          }
          className={favoriteClassNames}
          onClick={this.toggleFavoriteStatus}
          onMouseEnter={this.handlerFavoriteHoverOn}
          onMouseLeave={this.handlerFavoriteHoverOff}
          onFocus={this.handlerFavoriteHoverOn}
          onBlur={this.handlerFavoriteHoverOff}
        >
          <img
            className="fav-btn-img"
            src={this.state.favorite.icon}
            alt="favorite icon"
          />
          <span className={mainMediaFavText}>
            {this.state.isFavorite ? 'Added to favorites' : 'Add to favorites'}
          </span>
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const product = getProduct(state, props.productId);
  return {
    isFavorite: product.isFavorite,
    product,
    favAddRemoveStatus: product.favAddRemoveStatus,
  };
};
const mapDispatchToProps = dispatch => ({
  toggleFavorite: (...args) => dispatch(toggleFavorite(...args)),
  screenReaderAnnouncer: (...args) => dispatch(screenReaderAnnouncer(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FavoriteComponent);
