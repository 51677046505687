import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { ecmGetOverlayClasses } from '../../utilities';
import {
  getButtonPropValue,
  getButtonStyleAndLink,
  getDesktopCustomButtonStyles,
  getTabMobileCustomButtonStyles,
} from './buttonOverlayUtilities';

const EcmButtonOverlays = ({
  dataObj,
  inheritedTabImage,
  inheritedMobileImage,
}) => {
  const overlayClasses = ecmGetOverlayClasses(dataObj);
  const desktopData = get(dataObj, 'desktop', []);
  const desktopMapData = map(desktopData, 'map', []);
  const desktopButtonClass = map(desktopData, 'buttonClass', []);
  const desktopText = map(desktopData, 'text', []);

  return (
    <div className="ecm-container__buttonoverimage__buttonoverlays">
      {get(dataObj, 'desktop', []).map((buttonOverlay, index) => {
        if (!buttonOverlay.map) return false;
        const overlayStyle = {
          top: `${buttonOverlay.map.top}%`,
          left: `${buttonOverlay.map.left}%`,
          maxWidth: `calc(100% - ${buttonOverlay.map.left}%)`,
          maxHeight: `calc(100% - ${buttonOverlay.map.top}%)`,
        };
        const { componentId, buttonClass, text } = buttonOverlay;

        return (
          <div
            className={overlayClasses.desktopOverlayClasses}
            key={index}
            style={overlayStyle}
          >
            <a href={buttonOverlay.map.linkurl}>
              <button
                id={componentId}
                className={buttonClass}
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </a>
            {buttonClass !== null &&
              buttonClass !== '' &&
              buttonClass.includes('custom') &&
              getDesktopCustomButtonStyles(buttonOverlay, componentId)}
          </div>
        );
      })}
      {get(dataObj, 'tablet', []).map((buttonOverlay, index) => {
        const args = [buttonOverlay, index, desktopMapData, inheritedTabImage];
        const overlayStyleAndLink = getButtonStyleAndLink(...args);

        if (isEmpty(overlayStyleAndLink)) return false;

        const { componentId, text } = buttonOverlay;
        const buttonText = getButtonPropValue(
          text,
          index,
          desktopText,
          inheritedTabImage
        );
        const buttonClassArgs = [
          buttonOverlay.buttonClass,
          index,
          desktopButtonClass,
        ];
        const buttonClass = getButtonPropValue(
          ...buttonClassArgs,
          inheritedTabImage
        );
        const identifyInheritence = overlayStyleAndLink.isDesktopInherited
          ? 'tab_image_is_inherited'
          : '';

        const desktopIndexData = !isEmpty(desktopData[index])
          ? desktopData[index]
          : {};
        const styleArgs = [
          buttonOverlay,
          componentId,
          desktopIndexData,
          inheritedTabImage,
          768,
          1024,
        ];
        return (
          <div
            className={`${overlayClasses.tabletOverlayClasses} ${identifyInheritence}`}
            key={index}
            style={overlayStyleAndLink.overlayStyle}
          >
            <a href={overlayStyleAndLink.linkurl}>
              <button
                id={componentId}
                className={buttonClass}
                dangerouslySetInnerHTML={{ __html: buttonText }}
              />
            </a>
            {buttonClass !== null &&
              buttonClass !== '' &&
              buttonClass.includes('custom') &&
              getTabMobileCustomButtonStyles(...styleArgs)}
          </div>
        );
      })}
      {get(dataObj, 'mobile', []).map((buttonOverlay, index) => {
        const args = [
          buttonOverlay,
          index,
          desktopMapData,
          inheritedMobileImage,
        ];
        const overlayStyleAndLink = getButtonStyleAndLink(...args);

        if (isEmpty(overlayStyleAndLink)) return false;

        const { componentId, text } = buttonOverlay;
        const buttonText = getButtonPropValue(
          text,
          index,
          desktopText,
          inheritedMobileImage
        );
        const buttonClassArgs = [
          buttonOverlay.buttonClass,
          index,
          desktopButtonClass,
        ];
        const buttonClass = getButtonPropValue(
          ...buttonClassArgs,
          inheritedMobileImage
        );
        const identifyInheritence = overlayStyleAndLink.isDesktopInherited
          ? 'mobile_image_is_inherited'
          : '';

        const desktopIndexData = !isEmpty(desktopData[index])
          ? desktopData[index]
          : {};
        const styleArgs = [
          buttonOverlay,
          componentId,
          desktopIndexData,
          inheritedMobileImage,
          1,
          767,
        ];
        return (
          <div
            className={`${overlayClasses.mobileOverlayClasses} ${identifyInheritence}`}
            key={index}
            style={overlayStyleAndLink.overlayStyle}
          >
            <a href={overlayStyleAndLink.linkurl}>
              <button
                id={componentId}
                className={buttonClass}
                dangerouslySetInnerHTML={{ __html: buttonText }}
              />
            </a>
            {buttonClass !== null &&
              buttonClass !== '' &&
              buttonClass.includes('custom') &&
              getTabMobileCustomButtonStyles(...styleArgs)}
          </div>
        );
      })}
    </div>
  );
};

export default EcmButtonOverlays;
