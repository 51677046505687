import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
  updateParams,
  updateParamsWithStoreName,
} from 'client-utils/utilities-router';
import httpWithLogging from 'universal/http-client';
import { isMobile } from 'client-utils/utilities-page';

export const SELECT_FILTER = 'SELECT_FILTER';
export const SELECT_FILTER_OPTION = 'SELECT_FILTER_OPTION';
export const CLEAR_ALL_FILTER_OPTION = 'CLEAR_ALL_FILTER_OPTION';
export const UPDATE_URL_SEARCH_PARAM = 'UPDATE_URL_SEARCH_PARAM';
export const FILTER_DESIGNER_BY_NAME = 'FILTER_DESIGNER_BY_NAME';
export const OPEN_FACETED_MOBILE_FILTER = 'OPEN_FACETED_MOBILE_FILTER';
export const CLOSE_FACETED_MOBILE_FILTER = 'CLOSE_FACETED_MOBILE_FILTER';
export const SELECT_FILTER_BY_ROUTE_PARAM = 'SELECT_FILTER_BY_ROUTE_PARAM';
export const UPDATE_STORE_SEARCH_ADDRESS = 'UPDATE_STORE_SEARCH_ADDRESS';
export const UPDATE_STORE_SEARCH_MILES = 'UPDATE_STORE_SEARCH_MILES';
export const SUBMIT_SEARCH_BY_STORE = 'SUBMIT_SEARCH_BY_STORE';
export const CANCEL_SEARCH_BY_STORE = 'CANCEL_SEARCH_BY_STORE';
export const IN_STORE = 'In Store';
export const LOADING_ALL_STORES = 'LOADING_ALL_STORES';
export const RESOLVED_ALL_STORES_BY_ADDRESS = 'RESOLVED_ALL_STORES_BY_ADDRESS';
export const RESOLVED_ALL_STORES_BY_GEOLOCATION =
  'RESOLVED_ALL_STORES_BY_GEOLOCATION';
export const REJECTED_ALL_STORES = 'REJECTED_ALL_STORES';
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE';
export const NO_LAT_LONG_FAVSTORE = 'NO_LAT_LONG_FAVSTORE';
export const TOGGLE_CHANGE_YOUR_LOCATION = 'TOGGLE_CHANGE_YOUR_LOCATION';
export const SELECT_YOUR_STORE = 'SELECT_YOUR_STORE';
export const UPDATE_IN_STORE_SEARCH_ADDRESS = 'UPDATE_IN_STORE_SEARCH_ADDRESS';
export const UPDATE_IN_STORE_SEARCH_MILES = 'UPDATE_IN_STORE_SEARCH_MILES';
export const SUBMIT_SEARCH_STORE_BY_LOCATION =
  'SUBMIT_SEARCH_STORE_BY_LOCATION';
export const CANCEL_SEARCH_STORE_BY_LOCATION =
  'CANCEL_SEARCH_STORE_BY_LOCATION';
export const PHASE3_SHOP_YOUR_STORE_ON = 'PHASE3_SHOP_YOUR_STORE_ON';
export const TOGGLE_CATEGORY_SELECTOR = 'TOGGLE_CATEGORY_SELECTOR';

export function handleFilterSelection(selectedFilterKey) {
  return dispatch =>
    dispatch({
      type: SELECT_FILTER,
      payload: {
        selectedFilterKey,
      },
    });
}

export function handleFilterOptionSelection(
  selectedFilterKey,
  selectedFilterOptionKey,
) {
  return {
    type: SELECT_FILTER_OPTION,
    payload: {
      selectedFilterOptionKey,
      selectedFilterKey,
    },
  };
}

export function handleClearAllFilters() {
  return { type: CLEAR_ALL_FILTER_OPTION };
}

export function toggleCategorySelector() {
  return { type: TOGGLE_CATEGORY_SELECTOR };
}

export function handleURLUpdate(router = {}, isClearAll = false) {
  return (dispatch, getState) => {
    const state = getState();
    const isShopYourStore = get(state, 'toggles.SHOP_YOUR_STORE', false);

    if (isClearAll && isEmpty(router) === false) {
      if (isShopYourStore) {
        updateParamsWithStoreName(router, { filterOptions: null, page: 1 }, '');
      } else {
        updateParams(router, { filterOptions: null, page: 1 });
      }
      dispatch({ type: UPDATE_URL_SEARCH_PARAM, param: null });
      return true;
    }

    const facetedFiltersList = get(
      state,
      'facetedLeftNav.facetedFiltersList',
      null,
    );
    const inStoreAddress = get(state, 'facetedLeftNav.address', '');
    const inStoreRadius = get(state, 'facetedLeftNav.radius', 100);
    const selectedStoreNumber = get(
      state,
      'facetedLeftNav.selectedStoreNumber',
      '',
    );

    if (facetedFiltersList !== null && isEmpty(router) === false) {
      const results = {};
      Object.keys(facetedFiltersList).forEach(facetFilterKey => {
        if (!results[facetFilterKey]) {
          results[facetFilterKey] = [];
        }
        Object.keys(facetedFiltersList[facetFilterKey]).forEach(facetKey => {
          if (facetedFiltersList[facetFilterKey][facetKey]) {
            results[facetFilterKey].push(facetKey);
          }
        });
      });
      results[IN_STORE] = [];
      if (inStoreAddress !== '') {
        results[IN_STORE] = [inStoreAddress, inStoreRadius];
      }
      let selectedStoreName = '';
      if (selectedStoreNumber !== '') {
        results[IN_STORE] = [selectedStoreNumber];
        selectedStoreName = get(state, 'facetedLeftNav.selectedStore.name', '');
      }
      if (isShopYourStore && selectedStoreName !== '') {
        updateParamsWithStoreName(
          router,
          { filterOptions: JSON.stringify(results), page: 1 },
          selectedStoreName,
        );
      } else {
        updateParams(router, {
          filterOptions: JSON.stringify(results),
          page: 1,
        });
      }
      dispatch({ type: UPDATE_URL_SEARCH_PARAM, param: results });
    } else {
      dispatch({ type: UPDATE_URL_SEARCH_PARAM, param: 'Error Occured' });
    }
    return true;
  };
}

export function handleDesignerFilterChange(searchText) {
  return { type: FILTER_DESIGNER_BY_NAME, designerSearchText: searchText };
}

export function openFacetedMobileFilter() {
  return dispatch => dispatch({ type: OPEN_FACETED_MOBILE_FILTER });
}

export function closeFacetedMobileFilter() {
  return dispatch => dispatch({ type: CLOSE_FACETED_MOBILE_FILTER });
}

export function handleSelectFilterByRouteParam(
  filterOptions,
  isShopYourStore = false,
  storeName,
) {
  return {
    type: SELECT_FILTER_BY_ROUTE_PARAM,
    routeParam: {
      filterOptions,
      isShopYourStore,
      storeName,
    },
  };
}

export function handleFreeFormAddressChange(address) {
  return { type: UPDATE_STORE_SEARCH_ADDRESS, address };
}

export function handleRadiusChange(radius) {
  return { type: UPDATE_STORE_SEARCH_MILES, radius };
}

export function handleInStoreSubmit() {
  return { type: SUBMIT_SEARCH_BY_STORE };
}

export function handleInStoreCancel() {
  return { type: CANCEL_SEARCH_BY_STORE };
}

export function fetchListOfStoreByAddress(address, mileRadius) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_ALL_STORES });
    const state = getState();
    const qs = `storesByAddressOrCoordinates?brandCode=bg&freeFormAddress=${address}&mileRadius=${mileRadius}`;
    const STORE_LAMBA_API_TIMEOUT = get(
      state,
      'apiTimeouts.STORE_LAMBA_API_TIMEOUT',
    );
    const requestApi = httpWithLogging(state, STORE_LAMBA_API_TIMEOUT);
    return requestApi
      .get(`${NMConfig.API_STORE_LIST}/${qs}`)
      .then(successResponse => {
        dispatch({
          type: RESOLVED_ALL_STORES_BY_ADDRESS,
          payload: {
            storeList: successResponse.data,
          },
        });
      })
      .catch(() => {
        dispatch({ type: REJECTED_ALL_STORES });
        dispatch({ type: SHOW_ERROR_MESSAGE });
      });
  };
}

export function fetchListOfStore() {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_ALL_STORES });
    const state = getState();
    const context = state.api && state.api.requestContext;
    const lat = get(context, 'akamaiEdgescape.lat', ''); // '32.8140'
    const long = get(context, 'akamaiEdgescape.long', ''); // '-96.9489'
    const dtFavoriteStore = get(context, 'dtFavoriteStore', '');
    let storeNumber;
    let filterOptions = get(
      state,
      'routing.locationBeforeTransitions.query.filterOptions',
      null,
    );
    if (filterOptions !== '') {
      filterOptions = JSON.parse(filterOptions);
      const storeFilter = get(filterOptions, 'In Store', []);
      if (storeFilter.length === 1) {
        storeNumber = storeFilter[0];
      }
    }
    let qs;
    if (storeNumber) {
      qs = `storeById?storeId=${storeNumber}`;
    } else if (dtFavoriteStore.length > 0 && isMobile() === false) {
      qs = `storeById?storeId=${dtFavoriteStore}`;
    } else if (lat.length > 0 && long.length > 0) {
      qs = `storesByAddressOrCoordinates?brandCode=bg&latitude=${lat}&longitude=${long}&mileRadius=100`;
    }
    if (qs) {
      const STORE_LAMBA_API_TIMEOUT = get(
        state,
        'apiTimeouts.STORE_LAMBA_API_TIMEOUT',
      );
      const requestApi = httpWithLogging(state, STORE_LAMBA_API_TIMEOUT);
      return requestApi
        .get(`${NMConfig.API_STORE_LIST}/${qs}`)
        .then(successResponse => {
          dispatch({
            type: RESOLVED_ALL_STORES_BY_GEOLOCATION,
            payload: {
              storeList: successResponse.data,
            },
          });
        })
        .catch(err => dispatch({ type: REJECTED_ALL_STORES, error: err }));
    } else {
      dispatch({ type: NO_LAT_LONG_FAVSTORE });
    }
    return true;
  };
}

export function handleToggleChangeyourLocation() {
  return { type: TOGGLE_CHANGE_YOUR_LOCATION };
}

export function handleStoreSelection(selectedStore) {
  return { type: SELECT_YOUR_STORE, selectedStore };
}

export function handleFreeFormAddressFieldChange(address) {
  return { type: UPDATE_IN_STORE_SEARCH_ADDRESS, address };
}

export function handleRadiusFieldChange(radius) {
  return { type: UPDATE_IN_STORE_SEARCH_MILES, radius };
}

export function handleSearchForStoreSubmit() {
  return { type: SUBMIT_SEARCH_STORE_BY_LOCATION };
}

export function handleSearchForStoreCancel() {
  return { type: CANCEL_SEARCH_STORE_BY_LOCATION };
}

export function shopYourStorePhase3Enabled() {
  return { type: PHASE3_SHOP_YOUR_STORE_ON };
}
