import React from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import * as PropTypes from 'prop-types';

import { Field } from 'formik';
import './FormikPasswordField.scss';

class FormikPasswordField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPasswordVisible: false,
    };
  }

  toggleShow = e => {
    e && e.preventDefault();
    this.setState({
      isPasswordVisible: !this.state.isPasswordVisible,
    });
  };

  render() {
    const { id, placeholder, name, autoComplete, fontSize } = this.props;
    const { isPasswordVisible } = this.state;
    const inputType = isPasswordVisible ? 'text' : 'password';
    console.log(placeholder, 'font');

    return (
      <Field name={name}>
        {({ field, form, meta }) => {
          const isError = meta.touched && !isEmpty(meta.error);
          const placeholderClassName = classNames(
            'form-password-field__placeholder',
            isError && 'error',
          );
          const inputClassName = classNames(
            'form-password-field__input',
            isError && 'error',
            fontSize && 'inputFont',
          );
          return (
            <div className="form-field form-password-field">
              <label className="form-password-field__label" htmlFor={id}>
                <input
                  id={id}
                  className={inputClassName}
                  type={inputType}
                  placeholder=" "
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={field.value}
                  name={field.name}
                  autoComplete={autoComplete}
                />
                <span className={placeholderClassName}>{placeholder}</span>
                <span
                  role="button"
                  aria-hidden
                  className="form-password-field__show-btn"
                  onMouseDown={this.toggleShow}
                >
                  {isPasswordVisible ? 'Hide' : 'Show'}
                </span>
              </label>
              {isError && (
                <div className="form-password-field__error-message">
                  {meta.error}
                </div>
              )}
            </div>
          );
        }}
      </Field>
    );
  }
}

FormikPasswordField.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
};

export default FormikPasswordField;
