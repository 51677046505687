import React from 'react';
import Button from '@bgo-ui/common/client/common/components/Button/button';
import { Link, Element } from 'react-scroll';
import { content } from './constants';
import './index.scss';

const IncircleConciergeBespokeExperiences = () => {
  return (
    <Element
      name={content.id}
      id={content.id}
      className="incircle-concierge-bespoke-experiences"
    >
      <div className="incircle-concierge-bespoke-experiences__heading">
        <h3 className="incircle-concierge-bespoke-experiences__heading__title">
          {content.title}
        </h3>
        <p
          className="incircle-concierge-bespoke-experiences__heading__desc"
          dangerouslySetInnerHTML={{
            __html: content.desc,
          }}
        />
        <div className="incircle-concierge-bespoke-experiences__cta">
          <Link
            to={content.cta.to}
            href={`#${content.cta.to}`}
            smooth
            spy
            hashSpy
            duration={500}
            className="incircle-concierge-bespoke-experiences__cta__link"
          >
            <Button value={content.cta.title} className="contact-us" />
          </Link>
        </div>
      </div>
      <div className="incircle-concierge-bespoke-experiences__gallery">
        {content.galleryItems.map(item => (
          <div
            key={item.id}
            className="incircle-concierge-bespoke-experiences__gallery__item"
          >
            <img src={item.src} alt={item.title} />
            <span className="incircle-concierge-bespoke-experiences__gallery__item__content">
              <b>{item.title}</b>
              <br />
              <br />
              {item.desc}
            </span>
          </div>
        ))}
      </div>
    </Element>
  );
};

export default IncircleConciergeBespokeExperiences;
