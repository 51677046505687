import React, { Fragment, useEffect, useState } from 'react';
import { array, bool, func, object } from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import {
  PAGE_ID_WISHLIST_NEW,
  setPageId,
} from '@bgo-ui/common/client/common/actions/actions-page';
import Spinner from 'shared/components/Spinner/simpleSpinner';
import { useConstructor } from '../../../../utilities/hooks';
import { getContentForMyAccount, setResolvedPage } from '../../actions';
import {
  RESOLVED_NEW_WISHLIST_PAGE,
  ITEM_NOT_AVAILABLE,
} from '../../constants';
import { getWishlistProducts } from '../../../../../../../bgo-ui-pdp/src/client/components/wishlist/components/WishlistPage/actions';
import MyAccountMobileBackHeader from '../MyAccountPage/MyAccountBackHeader/MyAccountMobileBackHeader';
import Wishlist from './Wishlist/Wishlist';

const getOnlyActiveLength = (
  myAccountWishlist,
  wishlistItems,
  wishlistCountSku,
) => {
  const updatedWithStock = wishlistItems.map(wishlistItem => {
    const prodId = wishlistItem.productId;
    const { skuId } = wishlistItem;
    let result = {};
    let sku = {};
    const prod = find(myAccountWishlist, obj => obj.id === prodId);

    if (prod && prod.skus && prod.skus.length > 0) {
      sku = find(prod.skus, sku => sku.id === skuId);
    }
    result = {
      ...wishlistItem,
      inStock: sku.inStock,
      stockStatus: sku.stockStatusMessage,
    };
    return result;
  });
  if (wishlistCountSku) {
    return updatedWithStock.filter(
      i => i.stockStatus && i.stockStatus !== ITEM_NOT_AVAILABLE,
    ).length;
  } else {
    return updatedWithStock.filter(i => i.stockStatus !== ITEM_NOT_AVAILABLE)
      .length;
  }
};

/**
 * @function WishlistPage
 * @param {Function} props.getContentForMyAccount
 * @param {Function} props.getWishlistProducts
 * @param {Boolean} props.isLoadingContent
 * @param {Boolean} props.isLoadingProducts
 * @param {Boolean} props.wishlistCountSku
 * @param {Object} props.myAccountContent
 * @param {Array} props.myAccountWishlist
 * @param {Function} props.setPageId
 * @param {Function} props.setResolvedPage
 * @param {Array} props.wishlistItems
 * @returns {React.ReactElement}
 */
const WishlistPage = ({
  getContentForMyAccount,
  getWishlistProducts,
  isLoadingContent,
  isLoadingProducts,
  myAccountContent,
  myAccountWishlist,
  setPageId,
  setResolvedPage,
  wishlistItems,
  wishlistCountSku,
  bgoLoyaltyToggle,
  isCheckedLoyaltyUser,
}) => {
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const onlyInStockLength = !isEmpty(myAccountWishlist)
    ? getOnlyActiveLength(myAccountWishlist, wishlistItems, wishlistCountSku)
    : 0;
  const PAGE_NAME = !isEmpty(myAccountWishlist)
    ? `WishList(${onlyInStockLength})`
    : 'WishList';

  useConstructor(() => {
    setPageId(PAGE_ID_WISHLIST_NEW);
  });

  useEffect(() => {
    if (bgoLoyaltyToggle && isCheckedLoyaltyUser) {
      setResolvedPage(RESOLVED_NEW_WISHLIST_PAGE);
    } else if (!bgoLoyaltyToggle) {
      setResolvedPage(RESOLVED_NEW_WISHLIST_PAGE);
    }
  }, [bgoLoyaltyToggle, isCheckedLoyaltyUser]);

  useEffect(() => {
    Promise.all([getContentForMyAccount(), getWishlistProducts()]).then(() => {
      setIsDataLoaded(true);
    });
  }, []);

  return isDataLoaded ? (
    <Fragment>
      <MyAccountMobileBackHeader title={PAGE_NAME} />
      {!isLoadingProducts && !isLoadingContent ? (
        <Wishlist
          content={myAccountContent || null}
          onlyInStockLength={onlyInStockLength}
          wishlist={myAccountWishlist}
        />
      ) : null}
    </Fragment>
  ) : (
    <Spinner />
  );
};

WishlistPage.propTypes = {
  getContentForMyAccount: func.isRequired,
  getWishlistProducts: func.isRequired,
  isLoadingContent: bool.isRequired,
  isLoadingProducts: bool.isRequired,
  wishlistCountSku: bool.isRequired,
  myAccountContent: object.isRequired,
  myAccountWishlist: array.isRequired,
  setPageId: func.isRequired,
  setResolvedPage: func.isRequired,
  wishlistItems: array.isRequired,
};

const mapStateToProps = state => ({
  myAccountContent: state?.myaccount?.myAccountContent || false,
  myAccountWishlist:
    state?.wishlist?.wishlistListing?.minifiedProductSkuInfo || [],
  wishlistItems: state?.wishlist?.wishlist?.items || [],
  isLoadingProducts: state?.api?.wishlist_products?.loading || false,
  isLoadingContent: state?.api?.content?.loading || false,
  wishlistCountSku: state?.toggles?.WISHLIST_COUNT_WITHOUT_SKU || false,
  bgoLoyaltyToggle: state?.toggles?.BGO_LOYALTY_NEW || false,
  isCheckedLoyaltyUser: state.user.loyaltyUser.isChecked || false,
});

const mapDispatchToProps = {
  getContentForMyAccount,
  getWishlistProducts,
  setResolvedPage,
  setPageId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WishlistPage);
