import React from 'react';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { renderMultiDeviceText } from '../../../cms/components/utils';

const SimpleText = ({ cmsContentItem }) => {
  const {
    desktopText,
    tabletText,
    mobileText,
    link,
    textColor,
    openLinkInNewTab,
  } = _get(cmsContentItem, 'fields', {});
  const Wrapper = ({ children }) => (
    <div className="simple-text" style={{ color: textColor }}>
      {link ? (
        <a
          href={link}
          target={openLinkInNewTab ? '_blank' : '_self'}
          className="text-link"
        >
          <span>{children}</span>
        </a>
      ) : (
        <>{children}</>
      )}
    </div>
  );

  return renderMultiDeviceText(Wrapper, {
    desktopText,
    tabletText,
    mobileText,
  });
};

export default SimpleText;
