import {
  RESOLVED_CMS_CONTENT,
  REJECTED_CMS_CONTENT,
  RESOLVED_CMS_GLOBAL,
  REJECTED_CMS_GLOBAL,
  RESOLVED_MODAL_CONTENT,
  REJECTED_MODAL_CONTENT,
  SET_ACTIVE_ENTRY_ID,
} from '../constants/action-types';

const initialState = {
  entries: {},
  modal: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RESOLVED_CMS_CONTENT:
      return {
        ...state,
        entries: payload,
      };

    case REJECTED_CMS_CONTENT: {
      return {
        ...state,
        entries: [],
      };
    }

    case RESOLVED_CMS_GLOBAL:
      return {
        ...state,
        global: payload,
      };

    case REJECTED_CMS_GLOBAL: {
      return {
        ...state,
        global: null,
      };
    }

    case RESOLVED_MODAL_CONTENT: {
      return {
        ...state,
        modal: [...state.modal, ...payload],
      };
    }

    case REJECTED_MODAL_CONTENT: {
      return {
        ...state,
        modal: [],
      };
    }

    case SET_ACTIVE_ENTRY_ID: {
      return {
        ...state,
        activeEntryId: payload,
      };
    }

    default:
      return state;
  }
};
