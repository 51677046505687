import isEmpty from 'lodash/isEmpty';
import logger from 'server/utilities/logger';
import httpWithLogging from 'universal/http-client';
import { buildCookieString } from 'universal/utilities-cookies';
import { constructECMContent } from './whatsNewHelperService';
import { COMPONENT_CACHE_TTL } from 'bgo-common/components/constants';

let cacheManager = {}; // require('server-utils/utilities-cache-manager');
if (!IS_CLIENT) {
  cacheManager = require('server-utils/utilities-cache-manager');
}
const whatsNewCacheTTL = COMPONENT_CACHE_TTL;
const whatsNewCacheKey = 'whatsnew_cache';

const setCache = (name, data, ttl) => {
  if (!IS_CLIENT) {
    cacheManager.setCache(name, data, ttl);
  }
};

const getCache = name => {
  if (!IS_CLIENT) {
    return cacheManager.getCache(name);
  }
  return false;
};

export const types = {
  LOADING_WHATSNEWCONTENT: 'LOADING_whatsnew',
  RESOLVED_WHATSNEWCONTENT: 'RESOLVED_whatsnew',
  REJECTED_WHATSNEWCONTENT: 'REJECTED_whatsnew',
};

export const getHeaderConfig = ({
  JSESSIONID = '',
  DYN_USER_ID = '',
  TLTSID = '',
  W2A = '',
}) => {
  return {
    headers: {
      Cookie: buildCookieString({
        JSESSIONID,
        DYN_USER_ID,
        TLTSID,
        W2A,
      }),
    },
  };
};

export function getWhatsNewData(catId, textRef) {
  return (dispatch, getState) => {
    dispatch({ type: types.LOADING_WHATSNEWCONTENT });
    const state = getState();

    const startTime = new Date();
    const whatsNewCache = getCache(whatsNewCacheKey);
    if (whatsNewCache) {
      const endTime = new Date() - startTime;
      logger.info(`whatsnew with cache=${endTime}`);
      dispatch({
        type: types.RESOLVED_WHATSNEWCONTENT,
        payload: { data: whatsNewCache },
      });
      return Promise.resolve();
    }
    const requestApi = httpWithLogging(state, 10000);
    const apiPath = `${NMConfig.API_PRODUCT_LIST}?categoryId=${catId}`;
    // const apiPath="http://www.mocky.io/v2/5d78f9fd3000002d0031f8ca";
    const { session } = state;
    const headers = getHeaderConfig(session);

    return requestApi
      .get(apiPath, { headers })
      .then(successResponse => {
        if (isEmpty(successResponse) || isEmpty(successResponse.data.products))
          throw new Error('Whats new data is empty');
        const content = constructECMContent(
          successResponse.data.products,
          textRef,
          state.toggles.PHASE2_PLP_CLOUDINARY,
        );
        const endTime = new Date() - startTime;
        logger.info(`whatsnew without cache=${endTime}`);
        setCache(whatsNewCacheKey, content, whatsNewCacheTTL);
        dispatch({
          type: types.RESOLVED_WHATSNEWCONTENT,
          payload: { data: content },
        });
      })
      .catch(() => {
        logger.info('An error occurred in Whats new Service API');
        dispatch({
          type: types.REJECTED_WHATSNEWCONTENT,
          payload: null,
        });
      });
  };
}
