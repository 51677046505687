import get from 'lodash/get';
import { buildCookieString } from 'universal/utilities-cookies';
import httpWithLogging from 'universal/http-client';
import { setRedirectURL } from 'profile/actions/actions';

export const TOGGLE_WKY_WIDGET_CONTAINER_TYPE = 'TOGGLE_WKY_WIDGET_CONTAINER_TYPE';
export const TOGGLE_WKY_WIDGET_MAIN_STATUS = 'TOGGLE_WKY_WIDGET_MAIN_STATUS';
export const TOGGLE_WKY_WIDGET_NOTIFICATION_STATUS = 'TOGGLE_WKY_WIDGET_NOTIFICATION_STATUS';
export const SET_WKY_WIDGET_NOTIFICATION_TYPE = 'SET_WKY_WIDGET_NOTIFICATION_TYPE';
export const UPDATE_WKY_VIEW = 'UPDATE_WKY_VIEW';
export const LOADING_PERSONALIZED_PROMOTIONS = 'LOADING_PERSONALIZED_PROMOTIONS';
export const RESOLVED_PERSONALIZED_PROMOTIONS = 'RESOLVED_PERSONALIZED_PROMOTIONS';
export const REJECTED_PERSONALIZED_PROMOTIONS = 'REJECTED_PERSONALIZED_PROMOTIONS';
export const WKY_SESSION_TIMEOUT = 'WKY_SESSION_TIMEOUT';
export const NO_PERSONALIZED_PROMOTIONS_AVAILABLE = 'NO_PERSONALIZED_PROMOTIONS_AVAILABLE';

export const WKY_CARD_DISPLAY = 'WKY_CARD_DISPLAY';
export const WKY_BELL_CLICK = 'WKY_BELL_CLICK';
export const WKY_LANDING_PAGE = 'WKY_LANDING_PAGE';
export const WKY_COPY_PROMO_CLICK = 'WKY_COPY_PROMO_CLICK';

export function toggleWkyWidgetContainerType(containerStatus) {
  return dispatch => {
    dispatch({ type: TOGGLE_WKY_WIDGET_CONTAINER_TYPE, payload: containerStatus });
  };
}

export function toggleWkyWidgetMainStatus(mainStatus) {
  return dispatch => {
    dispatch({ type: TOGGLE_WKY_WIDGET_MAIN_STATUS, payload: mainStatus });
  };
}

export function toggleWkyWidgetNotificationStatus(notificationStatus) {
  return dispatch => {
    dispatch({ type: TOGGLE_WKY_WIDGET_NOTIFICATION_STATUS, payload: notificationStatus });
  };
}

export function setWkyWidgetNotificationType(notificationType) {
  return dispatch => {
    dispatch({ type: SET_WKY_WIDGET_NOTIFICATION_TYPE, payload: notificationType });
  };
}

export function updateWkyCurrentView(currentView) {
  return dispatch => {
    dispatch({ type: UPDATE_WKY_VIEW, payload: currentView });
  };
}

export function getPersonalizedPromotions() {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_PERSONALIZED_PROMOTIONS });
    const state = getState();
    const requestApi = httpWithLogging(state);
    const wkySessionTimeoutToggle = get(state, 'toggles.WKY_SESSION_TIMEOUT', false);
    const session = get(state, 'session', {});
    const email = get(state, 'user.email', '');
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        DYN_USER_CONFIRM: get(session, 'DYN_USER_CONFIRM', ''),
        W2A: get(session, 'W2A', ''),
        TLTSID: get(session, 'TLTSID', '')
      }),
      url: get(session, 'url', '').split('?')[0],
      'Customer-Email-Id': email,
    };
    return requestApi
      .get(`${NMConfig.API_PERSONALIZED_PROMOTIONS}`, { headers }).then(successResponse => {
        const { promotions = [] } = successResponse.data;
        if (successResponse.status === 204) {
          dispatch({ type: NO_PERSONALIZED_PROMOTIONS_AVAILABLE });
        }
        return dispatch({
          type: RESOLVED_PERSONALIZED_PROMOTIONS,
          payload: promotions
        });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            dispatch({ type: WKY_SESSION_TIMEOUT });
            wkySessionTimeoutToggle && setRedirectURL(NMConfig.ACCOUNT_PAGE_URL)(dispatch);
          }
        }
        dispatch({ type: REJECTED_PERSONALIZED_PROMOTIONS });
      });
  };
}
export function wkyTriggerAnalytics(type, payload = '') {
  return dispatch => dispatch({ type, payload });
}
