import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import classnames from 'classnames';
import renderHTML from 'react-render-html';
import { Helmet } from 'react-helmet';
import reactCookie from 'react-cookie';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addEventListener, removeEventListener } from 'window-or-global';
import { sanitize } from 'client-utils/utilities-html-sanitizer';
import { createCookiesFromAbTests } from 'client-utils/utilities-abtest';
import Navigation from '@bgo-ui/common/components/Navigation/navigation';
import SkipToContent from 'storefront/components/SkipToContent/skipToContent';
// eslint-disable-next-line import/no-named-as-default
import Header from '@bgo-ui/common/components/Header/header';
import DownloadappBanner from '@bgo-ui/common/components/DownloadappBanner/banner';
import Loadable from 'react-loadable';
import WelcomeMat from 'storefront/components/WelcomeMat/welcomeMat';
import NavSliderContianer from '@bgo-ui/common/components/Navigation/NavSlider/navSliderContainer';
import ScreenReaderAnnouncer from '@bgo-ui/common/components/ScreenReaderAlert/screenReaderAnnouncer';
import IdleTimerWrapper from '@bgo-ui/common/components/Util/IdleTimerWrapper';
import Spinner from 'shared/components/Spinner/spinner';
import { ECMParams } from 'ecm/constants';
import CPPixel from 'ecm/components/CPPixel/cpPixel';
import EcmGet, { ECMSlots } from 'ecm/components/EcmGet';
import EcmPreviewControl from 'ecm/components/EcmPreviewControl/ecmPreviewControl';
import { openModal } from 'shared/components/Modal/actions';
import { loseFocus } from 'shared/components/App/app-actions';
import { configureAmplify } from 'client-utils/utilities-amplify';
import { isNewCartCheckoutEnabled } from 'client-utils/is-new-cart-checkout';
import ContentGlobal from 'bgo-common/client/cms/components/contentGlobal/contentGlobal';
import { getCMSGlobal } from 'bgo-common/client/cms/actions/get-cms-content';
import { shouldLoad } from 'universal/http-client';
import PromoCarousel from 'storefront/components/PromoCarousel/promoCarousel';
import NotFound from '../NotFound';
import {
  PAGE_ID_HOME,
  PAGE_ID_PLP,
  PAGE_ID_SRP,
  PAGE_ID_PDP,
  PAGE_ID_CAT_TMPL,
  domLoadCompleted,
  PAGE_ID_CONF,
  PAGE_ID_PLA,
} from '../../actions/actions-page';
import Toggle from '../Toggle/toggle';

const Footer = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'footer' */ '@bgo-ui/common/components/Footer/footer'
    ),
  // 'storefront/components/Footer/footer'),
  loading: () => false,
});

const Modal = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'modal' */ 'shared/components/Modal/modal'),
  loading: () => false,
});

export class DumbNotFoundApp extends Component {
  constructor() {
    super();
    this.handleModalAnchorClick = this.handleModalAnchorClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { location } = this.props;
    if (!isEmpty(get(location, `query.${ECMParams.PARAM_LAUNCH}`, ''))) {
      reactCookie.save(
        ECMParams.PARAM_LAUNCH,
        get(location, `query.${ECMParams.PARAM_LAUNCH}`),
        { path: '/' },
      );
    }
    this.handleClickOutsideFocus = this.handleClickOutsideFocus.bind(this);
    if (this.props.haveAppSetDomLoadComplete) {
      this.handleLoad = this.handleLoad.bind(this);
    }
    if (
      this.props.isSiteTickerAvailable &&
      shouldLoad(this.props.cmsGlobalApi)
    ) {
      this.props.dispatch(getCMSGlobal('global'));
    }
  }

  componentDidMount() {
    if (this.props.dtSignInEnabled) {
      configureAmplify(
        this.props.amplifyConfig,
        this.props.isNewCartCheckoutEnabled,
        this.props.disableATGToggle,
      );
    }

    addEventListener('click', this.handleModalAnchorClick);
    if (this.props.haveAppSetDomLoadComplete) {
      addEventListener('load', this.handleLoad);
    }

    if (this.props.useInternalAbTestService) {
      createCookiesFromAbTests(this.props.abTestResults);
    }
  }

  componentWillUnmount() {
    removeEventListener('click', this.handleModalAnchorClick);
  }

  getLinkTarget(target) {
    if (target.getAttribute('data-modal-url')) {
      return target;
    }
    return target.parentElement;
  }

  handleModalAnchorClick(e) {
    const el = this.getLinkTarget(e.target);
    if (el) {
      const url = el.getAttribute('data-modal-url');
      const width = el.getAttribute('data-modal-width');
      if (url) {
        e.preventDefault();
        this.props.dispatch(openModal({ url, width }));
      }
    }
  }

  handleClickOutsideFocus(e, currentComponentInFocus) {
    const component = this.container.querySelector(currentComponentInFocus);
    if (component && !component.contains(e.target)) {
      this.props.dispatch(loseFocus(currentComponentInFocus));
    }
  }

  handleLoad() {
    this.props.dispatch(domLoadCompleted());
    removeEventListener('load', this.handleLoad);
  }

  render() {
    const {
      isSliderMenuVisible,
      componentInFocus,
      session = {},
      location = {},
      modal = {},
      pageId = '',
    } = this.props;
    const styles = isSliderMenuVisible
      ? {
          height: '87vh',
          overflow: 'hidden',
          position: 'fixed',
          top: 0,
          left: 0,
        }
      : {};

    const outsideClickHandler = componentInFocus
      ? e => this.handleClickOutsideFocus(e, componentInFocus)
      : undefined;
    const isFullWidth =
      pageId === PAGE_ID_HOME ||
      pageId === PAGE_ID_CONF ||
      pageId === PAGE_ID_PLP ||
      pageId === PAGE_ID_PDP ||
      (this.props.showPla && pageId === PAGE_ID_PLA) ||
      (this.props.isSRPFullWidth && pageId === PAGE_ID_SRP) ||
      pageId === PAGE_ID_CAT_TMPL;
    const isConfPage = pageId === PAGE_ID_CONF;
    const isPlpPage = pageId === PAGE_ID_PLP;
    const useCMSTicker =
      this.props.isSiteTickerAvailable && !isEmpty(this.props.cmsGlobal);

    return (
      <div id="appContainer">
        <ScreenReaderAnnouncer />
        <IdleTimerWrapper />
        <div
          id="contentContainer"
          className="application-container"
          ref={ref => {
            this.container = ref;
          }}
          onClick={outsideClickHandler}
          onTouchEnd={outsideClickHandler}
          aria-hidden={modal.open || isSliderMenuVisible}
          tabIndex="-1"
        >
          <Helmet>
            {Boolean(this.props.canonical) && (
              <link rel="canonical" href={this.props.canonical} />
            )}
          </Helmet>
          <Helmet>
            {Boolean(this.props.metaSEOOverride) &&
              renderHTML(sanitize(this.props.metaSEOOverride))}
          </Helmet>

          <DownloadappBanner />
          <SkipToContent />
          {session.personalize && <EcmPreviewControl location={location} />}
          <div
            className={`grid-container full-bleed ${
              useCMSTicker ? 'cms-siteticker' : 'ecm-siteticker'
            }`}
          >
            {useCMSTicker ? (
              <ContentGlobal
                cmsContent={this.props.cmsGlobal}
                placement="SiteTicker"
              />
            ) : (
              <Toggle
                feature="ECM_SITETICKER"
                fallback={<PromoCarousel tabIndex={0} />}
              >
                <EcmGet ecmSlot={ECMSlots.SITE_TICKER} />
              </Toggle>
            )}
          </div>
          <div className="grid-container large grid-parent">
            <Header />
            <Toggle
              feature="HIDE_MAIN_NAV"
              fallback={<Navigation router={this.props.router} />}
            >
              {false}
            </Toggle>
          </div>
          <div
            className={classnames('grid-container full-width-container', {
              'mainContent-large grid-parent': isFullWidth,
              'plp-full-width': isPlpPage,
              'configurator-full-style': isConfPage,
            })}
          >
            <div id="mainContent" style={styles}>
              <NotFound {...this.props} />
            </div>
            <Spinner />
          </div>
          <Footer isFullWidth />
          <WelcomeMat />
        </div>
        <Modal />
        <NavSliderContianer />
        {/* {enableWKYWidgetThirdParty ? (
          <WkyWidgetBase />
        ) : (
          <Toggle feature="WKY_WIDGET">
            <WkyWidgetFallback />
          </Toggle>
        )} */}
        <Toggle feature="COHERENT_PATH_PERSONALIZATION">
          <CPPixel />
        </Toggle>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  session: state.session,
  pageId: state.page.pageId,
  canonical: state.page.canonical,
  metaSEOOverride: state.page.metaSEOOverride,
  isSliderMenuVisible: state.navigation.navSlider.sliderMenuVisible,
  componentInFocus: state.app.componentInFocus,
  modal: state.modal,
  showPla: state.toggles.SHOW_PLA,
  isSRPFullWidth: state.toggles.SRP_FULL_WIDTH,
  enableWKYWidgetThirdParty: state.toggles.WKY_WIDGET_THIRDPARTY,
  haveAppSetDomLoadComplete: state.toggles.HAVE_APP_SET_DOMLOADCOMPLETE,
  useInternalAbTestService: state.toggles.USE_INTERNAL_ABTEST_SERVICE,
  abTestResults: state.abTests.abTestResults,
  isFullWidth: state.toggles.HOMEPAGE_FULL_WIDTH,
  dtSignInEnabled: state.toggles.DT_SIGN_IN,
  amplifyConfig: state.amplifyConfig,
  cmsGlobalApi: state.api.cms_global,
  cmsGlobal: state.cms.global,
  isSiteTickerAvailable: state.toggles.CMS_SITE_TICKER,
  isNewCartCheckoutEnabled: isNewCartCheckoutEnabled(state),
  disableATGToggle: state?.toggles?.DISABLE_ATG_CALLS || false,
});

export default connect(mapStateToProps)(DumbNotFoundApp);
