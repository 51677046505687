import React from 'react';
import facebookLogo from '../../../../assets/images/icons-social-facebook.svg';
import twitterLogo from '../../../../assets/images/twitter.svg';
import instagramLogo from '../../../../assets/images/instagram.svg';
import './socialMediaLinks.scss';

const RenderSocialMediaIcon = ({ url, title, logo, imgClass }) => (
  <a className="social-media__logo" href={url} title={title} target="_blank" rel="noreferrer noopener">
    <img className={imgClass} src={logo} alt={title} />
  </a>
  );

const SocialMediaLinks = () => (
  <div className="social-media-section">
      <RenderSocialMediaIcon url="https://instagram.com/bergdorfs/" title="Instagram" logo={instagramLogo} />   
      <RenderSocialMediaIcon url="https://www.facebook.com/bergdorfgoodman" title="Facebook" logo={facebookLogo} />
      <RenderSocialMediaIcon url="https://www.twitter.com/bergdorfs" title="Twitter" logo={twitterLogo} />
  </div>
);
export default SocialMediaLinks;
