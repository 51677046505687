import classnames from 'classnames';
import { dangerousProperty } from 'client-utils/utilities-html-sanitizer';
import React, { Fragment } from 'react';
import SalePrice from '../SalePrice/salePrice';

const ProductNameAndPrice = ({
  name = '',
  retailPrice,
  originalPrice,
  productComponentRedesign,
  isTalonOne,
}) => {
  return (
    <Fragment>
      <h2
        className={classnames({
          'seo-wrapper': true,
          'product-name': true,
          'inherit-font-size': true,
          'text-truncate': productComponentRedesign,
        })}
      >
        <span
          className="name"
          dangerouslySetInnerHTML={dangerousProperty(name)}
        />
      </h2>
      <div className="product__description__price">
        <SalePrice
          retailPrice={retailPrice}
          isTalonOne={isTalonOne}
          originalPrice={originalPrice}
        />
      </div>
    </Fragment>
  );
};

export default ProductNameAndPrice;
