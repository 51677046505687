import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import CloseIcon from '@bgo-ui/common/client/common/components/CloseIcon/closeIcon';
import EnterEmailModalContent from './components/EnterEmailModalContent';
import SignInModalContent from './components/SignInModalContent';
import SignUpModalContent from './components/SignUpModalContent';
import { MODAL_CONTENT_TYPES } from './constants';
import { closeApplyNowModal } from './actions';
import './index.scss';

const contentComponentByType = {
  [MODAL_CONTENT_TYPES.ENTER_EMAIL]: EnterEmailModalContent,
  [MODAL_CONTENT_TYPES.SIGN_IN]: SignInModalContent,
  [MODAL_CONTENT_TYPES.SIGN_UP]: SignUpModalContent,
};

const showClassNameByType = {
  [MODAL_CONTENT_TYPES.ENTER_EMAIL]: 'show-enter-email',
  [MODAL_CONTENT_TYPES.SIGN_IN]: 'show-sign-in',
  [MODAL_CONTENT_TYPES.SIGN_UP]: 'show-sign-up',
};

const ApplyNowModal = ({ contentType, closeApplyNowModal }) => {
  const showClassName = showClassNameByType[contentType];
  const ContentComponent = contentComponentByType[contentType];

  const mainClassName = 'apply-now-modal-content';

  return (
    <div
      className={classNames({
        [mainClassName]: true,
        [`${mainClassName}--${showClassName}`]: true,
      })}
    >
      <CloseIcon onClick={closeApplyNowModal} />
      {ContentComponent ? <ContentComponent /> : null}
    </div>
  );
};

const mapStateToProps = state => ({
  contentType: state.applyNowModal.contentType,
});
const mapDispatchToProps = {
  closeApplyNowModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplyNowModal);
