import React from 'react';
import { unescape } from 'he';
import { EyeBrow1 } from 'bgo-common/Styleguide/Typography';
import './designerName.scss';

const DefaultHeading = ({ designerName = '', isChanel }) => {
  if (!designerName) {
    return null;
  }
  return (
    <span className={`product-heading__designer${isChanel ? '--chanel' : ''}`}>
      <EyeBrow1>{designerName}</EyeBrow1>
    </span>
  );
};

const DesignerWithBoutique = ({ name = '', url = '', isChanel }) => {
  if (!name) {
    return null;
  }
  if (!url) {
    return <DefaultHeading designerName={name} isChanel={isChanel} />;
  }
  return (
    <a
      href={url}
      className={`product-heading__designer${isChanel ? '--chanel' : ''}`}
    >
      <EyeBrow1>{name}</EyeBrow1>
    </a>
  );
};

const DesignerName = ({ name = '', designerBoutiqueUrl = '', isChanel }) => (
  <DesignerWithBoutique
    name={unescape(name || '')}
    url={designerBoutiqueUrl}
    isChanel={isChanel}
  />
);

export default DesignerName;
