import React from 'react';
import { content } from './constants';
import './index.scss';

const IncircleConciergeTerms = () => {
  return (
    <div className="incircle-concierge-terms">
      <p className="incircle-concierge-terms__content">{content.terms}</p>
    </div>
  );
};

export default IncircleConciergeTerms;
