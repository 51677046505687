export const Channels = {
  DESKTOP: 'Desktop',
  MOBILE: 'Mobile',
};

export const CMSParams = {
  CMS_PREVIEW: 'CMS_Preview',
};

export const NEW_HOMEPAGE_PATH = '/new-homepage';

export const EntityWithSlideBarParams = {
  tag: 'HP_Main_Edits',
  landscapeTag: 'ME-Landscape',
  itemsPerView: [7, 12, 15],
  viewClasses: ['free', 'medium', 'small'],
};

export const WhatToWearParams = {
  TAG: 'HP_WhatToWear',
};

export const PROMO_TILE_REPLACE_PATTERN = '###';

// Tracking Tag constants

const defaultBrandChannel = 'XXXX';
const defaultSecondGroup = 'XX';
const defaultThirdGroup = 'XXXX';
const defaultCampaignType = 'XXXX';
const defaultVendor = 'XXXXXXXXXXXXXXXX';
const defaultCategory = 'XXXXXXXX';
const defaultCampaignName = 'ThemeName_010121';

export const defaultTrackingTags = {
  brandChannel: defaultBrandChannel,
  secondGroup: defaultSecondGroup,
  thirdGroup: defaultThirdGroup,
  campaignType: defaultCampaignType,
  vendor: defaultVendor,
  category: defaultCategory,
  campaignName: defaultCampaignName,
};

export const defaultTrackingString = `${defaultBrandChannel}-${defaultSecondGroup}-${defaultThirdGroup}-${defaultCampaignType}-${defaultVendor}-${defaultCategory}-${defaultCampaignName}`;

export const tagMap = {
  'Brand + Channel': 'brandChannel',
  '2nd Group': 'secondGroup',
  '3rd Group': 'thirdGroup',
  'Campaign Type': 'campaignType',
  Vendor: 'vendor',
  Category: 'category',
  'Campaign Name': 'campaignName',
};
