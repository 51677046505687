import isEmpty from 'lodash/isEmpty';
import React from 'react';
import classNames from 'classnames';

import './productOptionTooltip.scss';

export default ({ displayText, show }) => {
  const classes = classNames({
    'availability-tooltip': true,
    'availability-tooltip--show': !isEmpty(displayText) && show,
  });

  return (
    <div className={classes}>
      <div className="availability-tooltip__text">{displayText}</div>
    </div>
  );
};
