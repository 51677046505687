import httpWithLogging from 'universal/http-client';
import { getUserDataAsGuest } from '@bgo-ui/common/client/utilities/utilities-amplify';
import { openModal, closeModal } from 'shared/components/Modal/actions';
import { ACTION_TYPES, ALLOWED_STATUS_TYPES } from './constants';

export const openApplyNowModal = () => {
  return dispatch =>
    dispatch(
      openModal({
        type: 'ApplyNowModal',
        className: 'apply-now-modal',
        closeDisabled: true,
      }),
    );
};

export const checkUserExists = (email, errorCallback = () => {}) => {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state);

    dispatch({ type: ACTION_TYPES.LOADING_PROCESS_EMAIL });

    return getUserDataAsGuest(state)
      .then(info => {
        const accountId = info?.ucaId || null;
        const idToken = info?.idToken || null;
        const headers = {
          accountId,
          authorization: idToken,
          'guest-email-id': email,
        };
        const callString = `${NMConfig.API_GUEST_CHECK_USER_EXISTS}`;
        return requestApi.get(callString, { headers });
      })
      .then(res => {
        const allowed = [
          ALLOWED_STATUS_TYPES.EMAIL_ALREADY_EXISTS,
          ALLOWED_STATUS_TYPES.EMAIL_DOESNT_EXISTS,
        ];
        if (res && res.data?.status && allowed.includes(res.data.status)) {
          if (res.data.status === ALLOWED_STATUS_TYPES.EMAIL_ALREADY_EXISTS) {
            dispatch({
              type: ACTION_TYPES.PROCEED_TO_SIGN_IN,
              payload: email,
            });
          } else {
            // res.data.status === ALLOWED_STATUS_TYPES.EMAIL_DOESNT_EXISTS
            dispatch({
              type: ACTION_TYPES.PROCEED_TO_SIGN_UP,
              payload: email,
            });
          }
        } else {
          dispatch({
            type: ACTION_TYPES.REJECTED_PROCESS_EMAIL,
          });
          errorCallback();
        }
      })
      .catch(() => {
        dispatch({
          type: ACTION_TYPES.REJECTED_PROCESS_EMAIL,
        });
        errorCallback();
      });
  };
};

export const clearProcessEmailError = () => {
  return dispatch =>
    dispatch({
      type: ACTION_TYPES.CLEAR_PROCESS_EMAIL_ERROR,
    });
};

export const editEmail = () => {
  return dispatch =>
    dispatch({
      type: ACTION_TYPES.EDIT_EMAIL,
    });
};

export const closeApplyNowModal = () => {
  return dispatch => {
    dispatch(closeModal());
    dispatch({
      type: ACTION_TYPES.CLOSE_APPLY_NOW_MODAL,
    });
  };
};
