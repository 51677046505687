import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import React from 'react';
import Toggle from 'shared/components/Toggle/toggle';
import ConnectedAnchorWithModalKeysIfImageMapTargetIsTop from './components/AnchorWithModalKeysIfImageMapTargetIsTop';

const EcmImageMap = ({ picture }) => {
  const ecmImages = get(picture, 'properties.images', '');
  const ecmTransImage =
    'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
  let desktopClasses = 'ecm-container__imagemap';
  if (!isEmpty(get(ecmImages, 'tablet.imagesrc')))
    desktopClasses += ' hide-on-tablet';
  if (!isEmpty(get(ecmImages, 'mobile.imagesrc')))
    desktopClasses += ' hide-on-mobile';
  let tabletClasses = 'ecm-container__imagemap';
  if (!isEmpty(get(ecmImages, 'desktop.imagesrc')))
    tabletClasses += ' hide-on-desktop';
  if (!isEmpty(get(ecmImages, 'mobile.imagesrc')))
    tabletClasses += ' hide-on-mobile';
  let mobileClasses = 'ecm-container__imagemap';
  if (!isEmpty(get(ecmImages, 'desktop.imagesrc')))
    mobileClasses += ' hide-on-desktop';
  if (
    !isEmpty(get(ecmImages, 'tablet.imagesrc')) ||
    !isEmpty(get(ecmImages, 'desktop.imagesrc'))
  )
    mobileClasses += ' hide-on-tablet';
  const AnchorWithModalKeysIfImageMapTargetIsTop = imageMapAttributes => {
    let anchorProps = {
      href: imageMapAttributes.imageMap.linkurl,
    };
    if (imageMapAttributes.imageMap.target === '_top') {
      anchorProps = {
        href: '#',
        'data-modal-url': imageMapAttributes.imageMap.linkurl,
        'data-modal-width': 500,
      };
    } else if (imageMapAttributes.imageMap.target === '_blank') {
      anchorProps = {
        ...anchorProps,
        target: imageMapAttributes.imageMap.target,
      };
    }
    return (
      <a
        {...anchorProps}
        className={imageMapAttributes.breakPointClasses}
        style={imageMapAttributes.mapStyle}
        key={imageMapAttributes.index}
      >
        <img
          src={ecmTransImage}
          alt={imageMapAttributes.imageMap.alttext}
          title={imageMapAttributes.imageMap.alttext}
        />
      </a>
    );
  };
  return (
    <div className="ecm-container__imagemaps">
      {!isEmpty(get(ecmImages, 'desktop.imagesrc')) &&
        get(ecmImages, 'desktop.map', []).map((imageMap, index) => {
          const mapStyle = {
            top: `${imageMap.top}%`,
            left: `${imageMap.left}%`,
            width: `${imageMap.width}%`,
            height: `${imageMap.height}%`,
          };
          return (
            <Toggle
              key={index}
              feature="ECM_IMAGE_MAP_QUICK_LOOK"
              fallback={
                <AnchorWithModalKeysIfImageMapTargetIsTop
                  imageMap={imageMap}
                  breakPointClasses={desktopClasses}
                  mapStyle={mapStyle}
                  key={index}
                />
              }
            >
              <ConnectedAnchorWithModalKeysIfImageMapTargetIsTop
                imageMap={imageMap}
                breakPointClasses={desktopClasses}
                mapStyle={mapStyle}
                key={index}
              />
            </Toggle>
          );
        })}
      {!isEmpty(get(ecmImages, 'tablet.imagesrc')) &&
        get(ecmImages, 'tablet.map', []).map((imageMap, index) => {
          const mapStyle = {
            top: `${imageMap.top}%`,
            left: `${imageMap.left}%`,
            width: `${imageMap.width}%`,
            height: `${imageMap.height}%`,
          };
          return (
            <Toggle
              key={index}
              feature="ECM_IMAGE_MAP_QUICK_LOOK"
              fallback={
                <AnchorWithModalKeysIfImageMapTargetIsTop
                  imageMap={imageMap}
                  breakPointClasses={tabletClasses}
                  mapStyle={mapStyle}
                  key={index}
                />
              }
            >
              <ConnectedAnchorWithModalKeysIfImageMapTargetIsTop
                imageMap={imageMap}
                breakPointClasses={tabletClasses}
                mapStyle={mapStyle}
                key={index}
              />
            </Toggle>
          );
        })}
      {!isEmpty(get(ecmImages, 'mobile.imagesrc')) &&
        get(ecmImages, 'mobile.map', []).map((imageMap, index) => {
          const mapStyle = {
            top: `${imageMap.top}%`,
            left: `${imageMap.left}%`,
            width: `${imageMap.width}%`,
            height: `${imageMap.height}%`,
          };
          return (
            <Toggle
              key={index}
              feature="ECM_IMAGE_MAP_QUICK_LOOK"
              fallback={
                <AnchorWithModalKeysIfImageMapTargetIsTop
                  imageMap={imageMap}
                  breakPointClasses={mobileClasses}
                  mapStyle={mapStyle}
                  key={index}
                />
              }
            >
              <ConnectedAnchorWithModalKeysIfImageMapTargetIsTop
                imageMap={imageMap}
                breakPointClasses={mobileClasses}
                mapStyle={mapStyle}
                key={index}
              />
            </Toggle>
          );
        })}
    </div>
  );
};

export default EcmImageMap;
