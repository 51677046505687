export const defaultApiTimeout = 20000;
export const forgotPasswordError = 'Something went wrong. Please try again.';
export const forgotPasswordDescription = email =>
  `We'll send an email to ${email} with a link to change your password.
  Please only submit one request to change, and be aware it can take up to
  5 minutes to receive your email. For security purposes, we will remove
  the credit card information in your account.`;
export const resetPasswordError = 'Something went wrong. Please try again.';
export const policyUri =
  '/assistance/assistance.jsp?itemId=cat403606&icid=foot_secur_priv';
export const cartLoginRedirectUri =
  '/checkout/orderreview.jsp?justloggedin=true';
export const orderHistoryRedirectUri =
  '/account/account.jsp?view=history&list=default';
export const validationErrors = {
  confirmPasswordIsNotEmpty: 'Passwords must be populated.',
  passwordsMatch: 'Passwords entered do not match.',
  emptyFirstName: 'Please enter your first name.',
  emptyLastName: 'Please enter your last name.',
  emptyEmail: 'Please enter your email.',
  invalidEmail: 'Please enter a valid email.',
  emptyPassword: 'Please enter your password.',
  badPassword: "Password doesn't meet the requirements.",
  emptyConfirm: 'Please confirm your password.',
  emptyBirthday: 'Select your birth day.',
  emptyBirthmonth: 'Select your birth month.',
  incorrectDay: 'Select the correct day.',
  phoneNumberEmpty: 'Please enter your phone number.',
  invalidPhoneNumber: 'Please enter valid phone number',
  sameEmailID: 'The new email address must not match the current email address',
};
export const contentfulIdentifier = {
  registrationForm: 'registration-form',
};

export const registrationErrors = {
  exist: 'A user already exists with the login',
  invalidEmail: 'Email address is invalid.',
  general: 'Something went wrong, please try again.',
};

export const loyaltyTitles = {
  checkboxLabel: 'JOIN LOYALTY LOREM IPSUM DOLOR',
  loyaltyInfo:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
};
