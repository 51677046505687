import React from 'react';
import { formatNumber } from 'client-utils/utilities-number';
import './index.scss';
import lt from 'lodash/lt';
import gt from 'lodash/gt';
import IncircleImg from 'assets/images/InCircleLogo.svg';
import classNames from 'classnames';
import { Status } from '../widgets';
import {
  CHECK_LATER,
  MAX_POINT,
  MIN_POINT,
  YOUR_POINTS_TITLE,
} from '../constants';

const YourStatusAndPoints = ({
  summaryDetails,
  strikingPointsText,
  manualRedemptionText,
}) => {
  const points = gt(summaryDetails.pointBalance, 9999)
    ? 9999
    : summaryDetails.pointBalance;
  const rangeOfPoints = lt(points, 0) ? 0 : (points % 10000) / 10000;
  const radius = 123;
  const circumference = 2 * Math.PI * radius;
  const mainDash = `${circumference / 2}, ${circumference}`;
  const rangeDash = `${(circumference / 2) * rangeOfPoints}, ${circumference}`;
  const pointsBalance = formatNumber(summaryDetails.pointBalance);
  const strikingPoints =
    summaryDetails.totalPointsToNextReward - summaryDetails.pointBalance;
  const validStrikingPoints = Math.max(strikingPoints, 0);
  return (
    <>
      {!summaryDetails?.tryAgainLater ? (
        <>
          <Status loyaltySummary={summaryDetails} IncircleImg={IncircleImg} />
          <div className="card-slide">
            <h3 className="card-slide__circle-name">{YOUR_POINTS_TITLE}</h3>
            <div className="card-slide__diagram-wrapper">
              <svg className="card-slide__diagram-circle">
                <circle
                  r={radius}
                  cx="50%"
                  cy="50%"
                  stroke="#E1E1EB"
                  strokeWidth="15"
                  strokeDasharray={mainDash}
                  fill="none"
                  strokeLinecap="round"
                />
                {summaryDetails?.pointBalance > 0 && (
                  <circle
                    r={radius}
                    cx="50%"
                    cy="50%"
                    stroke="#000000"
                    strokeWidth="15"
                    strokeDasharray={rangeDash}
                    fill="none"
                    strokeLinecap="round"
                  />
                )}
              </svg>
              <div className="card-slide__points">
                <h3>{pointsBalance}</h3>
              </div>
            </div>
            <div className="card-slide__card-info">
              <div>
                <p>{MIN_POINT}</p>
              </div>
              <div>
                <p>{MAX_POINT}</p>
              </div>
            </div>
            <span
              className={classNames({
                center: !summaryDetails?.autoRewardOptInInd,
              })}
            >
              {summaryDetails?.autoRewardOptInInd && validStrikingPoints <= 3000
                ? `${strikingPointsText.replace(
                    '{strikingPoints}',
                    validStrikingPoints,
                  )}`
                : ''}
              {!summaryDetails?.autoRewardOptInInd && (
                <p className="manual-redemption-text">{manualRedemptionText}</p>
              )}
            </span>
          </div>
        </>
      ) : (
        <div className="card-slide">
          <h3 className="card-slide__circle-name">{YOUR_POINTS_TITLE}</h3>
          <div className="card-slide">
            <span>{CHECK_LATER}</span>
          </div>
        </div>
      )}
    </>
  );
};

export default YourStatusAndPoints;
