import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';
import renderHTML from 'react-render-html';
import { Helmet } from 'react-helmet';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { sanitize } from 'client-utils/utilities-html-sanitizer';
import SkipToContent from 'storefront/components/SkipToContent/skipToContent';
import Header from '@bgo-ui/common/components/Header/header';
import Navigation from '@bgo-ui/common/components/Navigation/navigation';
import Loadable from 'react-loadable';
import WelcomeMat from 'storefront/components/WelcomeMat/welcomeMat';
import ScreenReaderAnnouncer from '@bgo-ui/common/components/ScreenReaderAlert/screenReaderAnnouncer';
import Spinner from 'shared/components/Spinner/spinner';
import IdleTimerWrapper from '@bgo-ui/common/components/Util/IdleTimerWrapper';
import EcmGet, { ECMSlots } from 'ecm/components/EcmGet';
import EcmPreviewControl from 'ecm/components/EcmPreviewControl/ecmPreviewControl';
import { loseFocus } from 'shared/components/App/app-actions';
import Footer from 'bgo-common/components/Footer/footer';
import PromoCarousel from 'storefront/components/PromoCarousel/promoCarousel';
import Toggle from '../Toggle/toggle';
import InternationalCart from '../../../../components/Header/MiniCart/internationalCart';
import {
  PAGE_ID_HOME,
  PAGE_ID_PLP,
  PAGE_ID_SRP,
  PAGE_ID_PDP,
  PAGE_ID_CAT_TMPL,
  PAGE_ID_CONF,
  PAGE_ID_PLA,
  setPageId as setPageIdAction,
  PAGE_ID_INTL_CART,
} from '../../actions/actions-page';
import ContentGlobal from '../../../cms/components/contentGlobal/contentGlobal';
import DownloadappBanner from '../../../../components/DownloadappBanner/banner';
import { isNewHomePage } from '../../../utilities/is-new-home-page';

const Modal = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'modal' */ 'shared/components/Modal/modal'),
  loading: () => false,
});

export class DumbCartAppWrapper extends Component {
  constructor(props) {
    super(props);
    this.handleClickOutsideFocus = this.handleClickOutsideFocus.bind(this);
    this.props.setPageId(PAGE_ID_INTL_CART);
  }

  handleClickOutsideFocus(e, currentComponentInFocus) {
    const component = this.container.querySelector(currentComponentInFocus);
    if (component && !component.contains(e.target)) {
      this.props.dispatch(loseFocus(currentComponentInFocus));
    }
  }

  render() {
    const {
      isSliderMenuVisible,
      componentInFocus,
      session = {},
      location = {},
      modal = {},
      pageId = '',
    } = this.props;
    const styles = isSliderMenuVisible
      ? {
          height: '87vh',
          overflow: 'hidden',
          position: 'relative',
          top: 0,
          left: 0,
        }
      : {};
    const outsideClickHandler = componentInFocus
      ? e => this.handleClickOutsideFocus(e, componentInFocus)
      : undefined;
    const isFullWidth =
      pageId === PAGE_ID_HOME ||
      pageId === PAGE_ID_CONF ||
      pageId === PAGE_ID_PLP ||
      pageId === PAGE_ID_PDP ||
      (this.props.showPla && pageId === PAGE_ID_PLA) ||
      (this.props.isSRPFullWidth && pageId === PAGE_ID_SRP) ||
      pageId === PAGE_ID_CAT_TMPL;
    const isConfPage = pageId === PAGE_ID_CONF;
    const isPlpPage = pageId === PAGE_ID_PLP;
    const useCMSTicker =
      this.props.isSiteTickerAvailable && !isEmpty(this.props.cmsGlobal);
    const isFullBleed = this.props.isNewHomePage;

    return (
      <div id="appContainer">
        <ScreenReaderAnnouncer />
        <IdleTimerWrapper />
        <div
          id="contentContainer"
          className="application-container"
          ref={ref => {
            this.container = ref;
          }}
          onClick={outsideClickHandler}
          onTouchEnd={outsideClickHandler}
          aria-hidden={modal.open || isSliderMenuVisible}
          tabIndex="-1"
        >
          <Helmet>
            {Boolean(this.props.canonical) && (
              <link rel="canonical" href={this.props.canonical} />
            )}
          </Helmet>
          <Helmet>
            {Boolean(this.props.metaSEOOverride) &&
              renderHTML(sanitize(this.props.metaSEOOverride))}
          </Helmet>

          <DownloadappBanner />
          <SkipToContent />
          {session.personalize && <EcmPreviewControl location={location} />}
          <div
            className={`grid-container full-bleed ${
              useCMSTicker ? 'cms-siteticker' : 'ecm-siteticker'
            }`}
          >
            {useCMSTicker ? (
              <ContentGlobal
                cmsContent={this.props.cmsGlobal}
                placement="SiteTicker"
              />
            ) : (
              <Toggle
                feature="ECM_SITETICKER"
                fallback={<PromoCarousel tabIndex={0} />}
              >
                <EcmGet ecmSlot={ECMSlots.SITE_TICKER} />
              </Toggle>
            )}
          </div>
          <Header />
          <Toggle
            feature="HIDE_MAIN_NAV"
            fallback={<Navigation router={this.props.router} />}
          >
            {false}
          </Toggle>
          <div
            className={classnames('grid-container full-width-container', {
              'mainContent-large grid-parent': isFullWidth,
              'plp-full-width': isPlpPage,
              'configurator-full-style': isConfPage,
              'full-bleed': isFullBleed,
            })}
          >
            <div id="mainContent" style={styles}>
              <InternationalCart />
            </div>
            <Spinner />
          </div>
          <Footer isFullWidth showCCPALink={this.props.showCCPALink} />
          <Toggle feature="BG_PHASE_2_WELCOME_MAT">
            <WelcomeMat />
          </Toggle>
        </div>
        <Modal />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  session: state.session,
  pageId: state.page.pageId,
  canonical: state.page.canonical,
  metaSEOOverride: state.page.metaSEOOverride,
  isSliderMenuVisible: state.navigation.navSlider.sliderMenuVisible,
  componentInFocus: state.app.componentInFocus,
  modal: state.modal,
  showPla: state.toggles.SHOW_PLA,
  isSRPFullWidth: state.toggles.SRP_FULL_WIDTH,
  enableWKYWidgetThirdParty: state.toggles.WKY_WIDGET_THIRDPARTY,
  haveAppSetDomLoadComplete: state.toggles.HAVE_APP_SET_DOMLOADCOMPLETE,
  useInternalAbTestService: state.toggles.USE_INTERNAL_ABTEST_SERVICE,
  abTestResults: state.abTests.abTestResults,
  isFullWidth: state.toggles.HOMEPAGE_FULL_WIDTH,
  showCCPALink: state.toggles.BG_PHASE_2_CCPA_LINK,
  dtSignInEnabled: state.toggles.DT_SIGN_IN,
  amplifyConfig: state.amplifyConfig,
  cmsGlobalApi: state.api.cms_global,
  cmsGlobal: state.cms.global,
  isSiteTickerAvailable: state.toggles.CMS_SITE_TICKER,
  isNewHomePage: isNewHomePage(state),
});

const mapDispatchToProps = {
  setPageId: setPageIdAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbCartAppWrapper);
