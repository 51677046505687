export const priceFormat = price => {
  return new Intl.NumberFormat().format(price);
};

/**
 * @function formatNumber
 * @description adds commas as appropriate to numbers
 * @param {Number} num
 * @returns {String}
 */
export const formatNumber = num => {
  const intNum = parseInt(num, 10);

  if (!intNum || !Number.isInteger(intNum) || intNum < 1000) return num;

  return `${intNum}`
    .split('')
    .reverse()
    .reduce((acc, val, index) => {
      if (index !== 0 && index % 3 === 0) acc.unshift(',');

      acc.unshift(val);
      return acc;
    }, [])
    .join('');
};
