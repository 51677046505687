import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';
import ExpandableMenu from './expandableMenu';

const finalItem = (silo, selectedSiloId) => {
  const designerAlphaCategory = get(silo, 'attributes.designerAlphaCategory', false);
  const navSliderMenuClassName = classnames({
    'nav-slider-menu__item': true,
    'nav-slider-menu__item--red-text': (silo.attributes && silo.attributes.redTextFlag),
    'nav-slider-menu__link': true,
    'nav-slider-menu__item__selected-item': (silo.catmanId === selectedSiloId),
  });

  const navSliderMenuItemClassName = classnames({
    'nav-slider-menu__item': true,
    'nav-slider-menu__item__selected-item': (silo.catmanId === selectedSiloId),
    'nav-slider-menu__disabled': true,
  });

  const boutiqueTextAdornment = (silo.attributes && silo.attributes.textAdornment) ?
    (<span className="nav-slider-menu__boutique-text-adornments">
      {silo.attributes.textAdornment}</span>) : false;

  return !designerAlphaCategory ?
    (<a className={navSliderMenuClassName} href={silo.url}>
      {silo.name}{' '}
      {boutiqueTextAdornment}
    </a>)
    :
    (<div className={navSliderMenuItemClassName}>
      {silo.name}
    </div>);
};

const MenuItem = ({ breadcrumbs, silo }) => {
  let last = '';
  let parent = '';
  if (breadcrumbs && breadcrumbs.length >= 2) {
    last = breadcrumbs[breadcrumbs.length - 1].id;
    parent = breadcrumbs[breadcrumbs.length - 2].id;
    if (parent === silo.catmanId) {
      silo.latestSelected = true;
    }
  }

  const content = isEmpty(silo.categories)
      ? finalItem(silo, last)
      : (<ExpandableMenu silo={silo} />);

  const subMenu = silo.selected
      ? (<ul className="nav-slider-menu__secondary-menu">
        {silo.categories && silo.categories.map(
          cat => (<MenuItem silo={cat} key={cat.id} breadcrumbs={breadcrumbs} />))
        }
      </ul>)
      : null;
  return (
    <li>
      {content}
      {subMenu}
    </li>);
};

const mapStateToProps = state => ({
  breadcrumbs: state.navigation.breadcrumbs,
});

export default connect(mapStateToProps)(MenuItem);
