import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  showSpinner,
  hideSpinner,
} from 'shared/components/Spinner/spinner-actions';

class DumbAppCheckout extends Component {
  constructor(props) {
    super(props);
    this.state = { fullEnvoyUrl: '' };
    this.props.showSpinner();
  }

  componentDidMount() {
    const getScriptId = document.getElementById('borderFreeMerchant');
    if (!getScriptId) {
      const createScript = document.createElement('script');
      createScript.id = 'borderFreeMerchant';
      createScript.type = 'text/javascript';
      createScript.src = `${NMConfig.BORDERFREE_URL}/cdn/checkout/v5/dist/merchant.js`;
      document.head.appendChild(createScript);
    }
    const ppStatus = window.location.search.match(/ppStatus=([^&]*)/);
    const token = window.location.search.match(/token=([^&]*)/);
    let fullEnvoyUrl;
    if (ppStatus && token) {
      fullEnvoyUrl = `${NMConfig.PAYPAL_ENVOY_URL}?${ppStatus[0]}&${token[0]}`;
    } else {
      fullEnvoyUrl = sessionStorage.getItem('fullEnvoyUrl');
    }
    this.setState({ fullEnvoyUrl }, () => {
      setTimeout(() => {
        this.props.hideSpinner();
      }, 5000);
    });
  }

  render() {
    const { fullEnvoyUrl } = this.state;
    return (
      <React.Fragment>
        {fullEnvoyUrl && (
          <React.Fragment>
            <iframe
              name="envoy"
              title="envoyId"
              id="envoyId"
              src={fullEnvoyUrl}
              frameBorder="0"
              scrolling="no"
              style={{ width: '100%' }}
            />
            <iframe
              title="__frame"
              id="__frame"
              width="0"
              height="0"
              frameBorder="0"
              src=""
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  showSpinner,
  hideSpinner,
};

export default connect(
  null,
  mapDispatchToProps,
)(DumbAppCheckout);
