/* eslint react/no-danger: 0 */
import React from 'react';
import classnames from 'classnames';
import './promotions.scss';

const innerHTML = (templateHTML, promotionalPrice) => {
  const validHtmlText = templateHTML || '';
  return `${validHtmlText}${promotionalPrice ? `: ${promotionalPrice}` : ''}`;
};

const Promotions = ({ promotions = [], promotionalPrice, isTalonOne }) => (
  <div className="product-thumbnail__promotions">
    {promotions.map((promotion, index) => {
      // const style = { color: thumbnailPromoColor && promotionalPrice ? `#${thumbnailPromoColor}` : undefined };
      const style = {
        color: isTalonOne ? '#b30015' : '#b30015',
        fontSize: isTalonOne ? '12px' : '12px',
      };
      const className = classnames(
        'product-thumbnail__promotions__element',
        {
          'product-thumbnail__promotions__element--priced': promotionalPrice,
        },
        'promotion-text',
      );
      return (
        <div
          key={index}
          style={style}
          className={isTalonOne ? className : className}
          dangerouslySetInnerHTML={{
            __html: innerHTML(promotion, promotionalPrice),
          }}
        />
      );
    })}
  </div>
);

export default Promotions;
