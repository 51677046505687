import {
  RESOLVED_WISHLIST,
  RESOLVED_WISHLIST_PRODUCTS,
  ADD_WISHLIST,
  REMOVE_WISHLIST,
  UPDATE_WISHLIST,
  RESOLVED_WISHLIST_PDP,
} from './actions';

const removeWishlist = (wishlist, skuId) => {
  const items = wishlist.items.filter(item => item.skuId !== skuId);
  return {
    profile_id: wishlist.profile_id,
    items,
  };
};

const addWishlist = (wishlist, product) => {
  const wishlistItems = wishlist.items;
  wishlistItems.push(product);
  return {
    profile_id: wishlist.profile_id,
    items: wishlistItems,
  };
};

const updateWishlist = (wishlist, productDetails) => {
  const wishlistItems = wishlist.items.map(item =>
    item.skuId === productDetails.existingItemId ? productDetails : item,
  );
  return {
    profile_id: wishlist.profile_id,
    items: wishlistItems,
  };
};

const updateWishlistListing = (wishlistListing, productDetails) => {
  const wishlist = wishlistListing.minifiedProductSkuInfo.map(item => {
    if (item.id === productDetails.productId) {
      item.skus.push(productDetails.sku);
    }
    return item;
  });

  return {
    minifiedProductSkuInfo: wishlist,
  };
};

const defaultState = {
  wishlist: {
    profile_id: null,
    items: [],
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESOLVED_WISHLIST_PRODUCTS:
      return {
        ...state,
        wishlistListing: action.payload,
      };

    case RESOLVED_WISHLIST:
      return {
        ...state,
        wishlist: action.payload,
      };

    case RESOLVED_WISHLIST_PDP:
      return {
        ...state,
        wishlist: action.payload,
      };

    case REMOVE_WISHLIST:
      return {
        ...state,
        wishlist: removeWishlist(state.wishlist, action.payload),
      };

    case ADD_WISHLIST:
      return {
        ...state,
        wishlist: addWishlist(state.wishlist, action.payload),
      };

    case UPDATE_WISHLIST:
      return {
        ...state,
        wishlist: updateWishlist(state.wishlist, action.payload),
        wishlistListing: updateWishlistListing(
          state.wishlistListing,
          action.payload,
        ),
      };

    default:
      return state;
  }
};
