import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { isGiftCategory } from 'client-utils/utilities-navigation';
// import minusWithBlack from 'assets/images/collapse_light.svg';
// import minusWithWhite from 'assets/images/collapse_white.svg';
// import plusWithBlack from 'assets/images/expand_light.svg';
import { toggleSiloSelected } from './navSlider-actions';

export const showSvgContainer = 'svg-container__svg';
export const hideSvgContainer = 'svg-container__svg invisible';

const ExpandableMenu = ({ silo, toggleSelected, giftSiloToggle }) => {
  const linkClasses = classnames({
    'nav-slider-menu__item': true,
    'nav-slider-menu__item--primary-selected': silo.level === 0 && silo.selected,
    'nav-slider-menu__item--secondary-selected': silo.level !== 0 && silo.latestSelected && silo.selected,
    'nav-slider-menu__item--red-text': silo.attributes && silo.attributes.redTextFlag,
    'nav-slider-menu__item--gifts-image': isGiftCategory(silo, giftSiloToggle),
  });


  const boutiqueTextAdorment = (silo.attributes && silo.attributes.textAdornment) ?
    (<span className="nav-slider-menu__boutique-text-adornments">
      {silo.attributes.textAdornment}</span>) : false;

  const whiteMinus = silo.level === 0 && silo.selected ? showSvgContainer : hideSvgContainer;
  const blackPlus = silo.selected ? hideSvgContainer : showSvgContainer;
  const blackMinus = silo.selected ? showSvgContainer : hideSvgContainer;

  return (

    <a
      id={`expandable-${silo.id}`}
      role="button"
      className={classnames(linkClasses)}
      onClick={() => toggleSelected(silo.id, silo.catmanId)}
      aria-expanded={silo.selected}
    >
      {/* <div className="svg-container">
        <img alt="" src={minusWithWhite} className={whiteMinus} />
        <img alt="" src={minusWithBlack} className={blackMinus} />
        <img alt="" src={plusWithBlack} className={blackPlus} />
      </div> */}
      {silo.name}{' '}
      {boutiqueTextAdorment}

    </a>
  );
};

const mapStateToProps = (state, ownProps) => ({
  silo: ownProps.silo,
  giftSiloToggle: state.toggles.GIFT_SILO_IMAGE,
});

const mapDispatchToProps = {
  toggleSelected: toggleSiloSelected,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpandableMenu);
