import get from 'lodash/get';
import find from 'lodash/find';

export default (product, id) => {
  const { customization = {} } = product;

  const selectedCustomizations = get(
    customization,
    'selectedCustomizationsAllTypes',
    [],
  );
  const selectedCustomizationOption =
    find(selectedCustomizations, { id }) || {};
  const { savedValues = '' } = selectedCustomizationOption;

  return savedValues;
};
