import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import he from 'he';
import {
  DISCONTINUED_CODE_V,
  DISCONTINUED_CODE_W,
  DISCONTINUED_CODE_Q,
  ITEM_NOT_AVAILABLE,
} from 'pdp/constants';
import { normalize } from './utilities-url';

export function stripHtml(string = '') {
  return string
    .toString()
    .replace(/(<([^>]+)>)/gi, ' ')
    .replace(/\s\s+/g, ' ')
    .trim();
}

export function mapSkuAvailabilitySchema(stockStatusMessage) {
  switch (stockStatusMessage) {
    case 'Pre-Order':
      return 'PreOrder';
    case 'Item Not Available':
      return 'OutOfStock';
    default:
      return 'InStock';
  }
}

const isSkuEligibleForLinkedData = skuObj =>
  !(
    skuObj.discontinuedCode &&
    (skuObj.discontinuedCode === DISCONTINUED_CODE_V ||
      skuObj.discontinuedCode === DISCONTINUED_CODE_W ||
      skuObj.discontinuedCode === DISCONTINUED_CODE_Q)
  );

export function getLinkedDataPDP(product) {
  const name = get(product, 'name');
  const brand = get(product, 'designer.name');
  const image = get(product, 'media.main.medium.url');
  const description = get(product, 'details.longDesc', '').replace(
    /"/gi,
    '%22',
  );
  const url = get(product, 'details.canonicalUrl');
  const price = get(
    product,
    'price.promotionalPrice',
    get(product, 'price.retailPrice'),
  );
  const skus = get(product, 'skus', []);
  const availability = skus.filter(sku => sku.sellable).length
    ? 'InStock'
    : 'OutOfStock';

  const priceValidUntil = get(product, 'priceValidUntil');

  const formatDateToISO = date => {
    return date?.toISOString().split('T')[0];
  };

  const mapSkuAvailabilitySchema = stockStatusMessage => {
    switch (stockStatusMessage) {
      case ITEM_NOT_AVAILABLE:
        return true;
      default:
        return false;
    }
  };

  const offersArray = skus.filter(isSkuEligibleForLinkedData).map(skuObj => {
    const dateToday = new Date();
    const sku = get(skuObj, 'id');
    const skuStatus = get(skuObj, 'stockStatusMessage');
    const color = get(skuObj, 'color.name');
    const isOOS = mapSkuAvailabilitySchema(skuStatus);
    const availabilityStatus = isOOS
      ? 'https://schema.org/OutofStock'
      : 'https://schema.org/InStock';
    const offerObj = {
      '@type': 'Offer',
      name: `${
        !isEmpty(color) && skuStatus !== ITEM_NOT_AVAILABLE ? `${color}` : ''
      } ${name}`,
      priceCurrency: 'USD',
      availability: availabilityStatus,
    };
    if (price) offerObj.price = price;
    if (sku) offerObj.sku = sku;
    if (!priceValidUntil) {
      if (skuStatus !== ITEM_NOT_AVAILABLE)
        dateToday.setFullYear(dateToday.getFullYear() + 1);
      offerObj.priceValidUntil = formatDateToISO(dateToday);
    }
    return offerObj;
  });

  const offers = offersArray.length
    ? {
        '@type': 'AggregateOffer',
        priceCurrency: 'USD',
        availability,
        offers: offersArray,
        lowPrice: price,
        highPrice: price,
      }
    : undefined;

  const linkedData = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
  };
  if (name) linkedData.name = he.decode(name);
  if (brand) linkedData.brand = he.decode(brand);
  if (image) linkedData.image = normalize(image);
  if (description) linkedData.description = stripHtml(description);
  if (url) linkedData.url = normalize(url);
  if (offers) linkedData.offers = offers;

  return linkedData;
}

export function getGroupLinkData(productLinkData) {
  const linkData = {
    '@context': 'https://schema.org/',
    '@graph': [...productLinkData],
  };

  return linkData;
}
