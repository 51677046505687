import React, { Component } from 'react';
import classnames from 'classnames';
import minus from 'assets/images/collapse-white.svg';
import plus from 'assets/images/expand.svg';
import './button.scss';

class Button extends Component {
  constructor() {
    super();
    this.state = {
      hovered: false
    };
    this.handleHoverOn = this.handleHoverOn.bind(this);
    this.handleHoverOff = this.handleHoverOff.bind(this);
  }

  handleHoverOn() {
    this.setState({ hovered: true });
  }

  handleHoverOff() {
    this.setState({ hovered: false });
  }

  focus() {
    this.buttonDomElement.focus();
  }

  render() {
    const { secondary = false, inverse = false, expandable = false, expanded = false } = this.props;
    const { hovered } = this.state;
    const className = classnames(
      this.props.className,
      'button',
      { 'button--hovered': hovered },
      { 'button--secondary': secondary },
      { 'button--inverse': inverse },
      { 'button--expanded': expanded },
    );
    return (
      <button
        aria-controls={this.props.ariaControls}
        aria-expanded={this.props.ariaExpanded}
        aria-hidden={this.props.ariaHidden}
        aria-label={this.props.ariaLabel}
        className={className}
        id={this.props.id}
        data-testid={this.props.dataTestid}
        onClick={this.props.onClick}
        onKeyDown={this.props.onKeyDown}
        onMouseEnter={this.handleHoverOn}
        onMouseOut={this.handleHoverOff}
        onFocus={this.handleHoverOn}
        onBlur={this.handleHoverOff}
        ref={(button) => { this.buttonDomElement = button; }}
        tabIndex={this.props.tabIndex}
        title={this.props.title}
        disabled={this.props.disabled}
      >
        <label>{this.props.value}</label>    
        {expandable && (
          <img
            className="svg-container__svg"
            alt={expanded ? 'minus sign' : 'plus sign'}
            src={String(expanded ? minus : plus)}
          />
        )}
      </button>
    );
  }
}

export default Button;
