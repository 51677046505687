import isEmpty from 'lodash/isEmpty';
import { OPEN_MODAL } from 'shared/components/Modal/actions';
import httpWithLogging from 'universal/http-client';
import { buildCookieString } from 'universal/utilities-cookies';
import logger from 'server-utils/logger';
import { updateProfile } from '../../Profile/profile-actions';

export const RESTRICTED_MODAL_ERROR = 'RESTRICTED_MODAL_ERROR';

/* istanbul ignore next */
export function checkRestrictedItemsAndUpdateProfile(
  previousCountryCode, selectedCountryCode, selectedCurrencyCode, selectedLocaleUrl) {
  return (dispatch, getState) => {
    return getRestrictedProductsFromCart(selectedCountryCode)(getState)
        .then((data) => {
          const productIds = data.RestrictedProductsResponse.productIds;
          if (isEmpty(productIds)) {
            updateProfile(previousCountryCode,
              selectedCountryCode, selectedCurrencyCode, selectedLocaleUrl)(dispatch, getState);
          } else {
            const promises = productIds.string.map(id => getProduct(id)(getState));
            Promise.all(promises).then((products) => {
              dispatch({
                type: OPEN_MODAL,
                payload: {
                  type: 'RestrictedItemsModal',
                  className: 'restricted-items-modal',
                  closeDisabled: true,
                  content: {
                    previousCountryCode,
                    selectedCountryCode,
                    products,
                    selectedCurrencyCode,
                    selectedLocaleUrl
                  }
                }
              });
            }).catch((error) => {
              logger.error('Fetching restricted product details failed', error);
              dispatch({
                type: RESTRICTED_MODAL_ERROR
              });
            });
          }
        });
  };
}

/* istanbul ignore next */
export function getRestrictedProductsFromCart(selectedCountryCode) {
  return (getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state);

    return requestApi.post(`${NMConfig.API_RESTRICTED_ITEMS}`, {
      selectedCountryCode
    }, { timeout: 5000 })
    .then(({ data }) => data)
    .catch((error) => {
      logger.error('ATG service failed to fetch restricted products', error);
      throw error;
    });
  };
}

/* istanbul ignore next */
export function removeRestrictedItems(previousCountryCode,
  selectedCountryCode, selectedCurrencyCode, selectedLocaleUrl) {
  return (dispatch, getState) => {
    return removeRestrictedProductsFromCart(selectedCountryCode)(getState)
      .then((data) => {
        const updateSuccessful = data.RemoveRestrictedProductsResponse.updateSuccessful;
        if (updateSuccessful) {
          updateProfile(previousCountryCode,
            selectedCountryCode, selectedCurrencyCode, selectedLocaleUrl)(dispatch, getState);
        }
      });
  };
}

/* istanbul ignore next */
export function removeRestrictedProductsFromCart(selectedCountryCode) {
  return (getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state);

    return requestApi.post(`${NMConfig.API_REMOVE_RESTRICTED_ITEMS}`, {
      selectedCountryCode
    }, { timeout: 5000 })
    .then(({ data }) => data)
    .catch((error) => {
      logger.error('ATG service failed to remove restricted products');
      throw error;
    });
  };
}

/* istanbul ignore next */
export function getProduct(productId) {
  return (getState) => {
    const state = getState();
    const { user, session } = state;
    const headers = {
      Cookie: buildCookieString({
        ...session,
        ...user,
      })
    };
    const requestApi = httpWithLogging(state);
    return requestApi.get(`${NMConfig.API_PRODUCT}/${productId}`, { headers })
      .then(successResponse => successResponse.data)
      .catch((error) => {
        logger.error(`Product service failed to fetch restricted product ${productId}`, error);
        throw error;
      });
  };
}
