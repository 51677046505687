import React from 'react';
import { object, string } from 'prop-types';
import get from 'lodash/get';
import Layout from '../layout/layout';

/**
 * @function ContentFrame
 * @param {Object} props.cmsContentItem
 * @param {String} props.placement
 * @returns {React.ReactElement}
 */
const ContentFrame = ({ cmsContentItem, placement }) => {
  const contentPlacement = get(cmsContentItem, 'fields.l1Layouts', []);
  const pagePlacement = contentPlacement.find(
    item => get(item, 'fields.placement', '') === placement,
  );

  return pagePlacement ? (
    <Layout cmsLayout={cmsContentItem} placement={placement} />
  ) : null;
};

ContentFrame.proptypes = {
  cmsContentItem: object.isRequired,
  placement: string.isRequired,
};

export default ContentFrame;
