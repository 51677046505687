import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getPDPImageMediumUrl } from 'bgo-common/components/Util/product-cloudinary';
import compact from 'lodash/compact';
import get from 'lodash/get';
import values from 'lodash/values';
import { InView } from 'react-intersection-observer';
import classNames from 'classnames';
import { openProductImageZoom } from 'pdp/components/ProductPage/actions';
import Image from 'pdp/components/ProductPage/components/Image/image';
import ZoomImage from 'pdp/components/ProductPage/components/ProductMedia/components/ZoomImage/zoomImage';
import { generatePdpAlt } from 'pdp/utilities/utilities-alt';
import AlternateImages from './optimizationcomponents/AlternateImages/alternateImages';
import MainMedia from './optimizationcomponents/MainMedia/mainMedia';
import ZoomImageQl from './optimizationcomponents/ZoomImage/zoomImage';
import './productMedia.scss';

const ProductMedia = props => {
  const {
    activeIndex,
    arrows = false,
    brand = '',
    displayOutfittingText = false,
    dots = false,
    firstGroupProduct,
    isAlternateImages = true,
    // PDP Bundle Child Products : if isChildProductOfBundle = true, current Product is childProduct of Bundle
    // if isChildProductOfBundle = true, dont copy and Merge parent{group.media} product media paths with first product
    isChildProductOfBundle = false,
    isCloudinary,
    // PDP CommonBundle Image : MainMedia needs this props (prop-drilling)
    isCommonBundle = false,
    isGroup,
    media,
    name,
    openFromEditWishlist = false,
    openProductImageZoom,
    productId,
    showSoldOutOverlay = false,
    showAltImages = true,
    sourceIsQL = false,
    templateType,
    zoomPdpToggle,
  } = props;
  let groupMedia = [];
  if (
    isGroup &&
    props.productId === firstGroupProduct &&
    !isChildProductOfBundle
  ) {
    groupMedia = props.groupMedia;
  }

  const images = compact([
    media.main,
    ...values(media.alternate || []),
    ...groupMedia,
  ]);
  const video = media?.video || {};

  const handleZoom = (images, index) => {
    openProductImageZoom(images, index, isCloudinary, zoomPdpToggle);
  };

  useEffect(() => {
    if (props.preloadPdpImage) {
      images.forEach(image => {
        const deviceType =
          (props.device.isDesktop && 'desktop') ||
          (props.device.isTablet && 'tablet') ||
          (props.device.isMobile && 'mobile');
        const imageUrlObject = getPDPImageMediumUrl(image, isCloudinary);
        const preloadLink = document.createElement('link');
        preloadLink.href = get(imageUrlObject, `${deviceType}.imageSrc`, '');
        preloadLink.src = get(imageUrlObject, `${deviceType}.imageSrc`, '');
        preloadLink.rel = 'preload';
        preloadLink.as = 'image';
        document.head.appendChild(preloadLink);
      });
    }
  }, []);

  return (
    <div
      className={`product-media product-media-b item-count-${images.length}`}
    >
      {!openFromEditWishlist && isAlternateImages && (
        <div className="hide-on-mobile hide-on-desktop main-media-wrapper">
          {templateType === 'B' &&
            images &&
            images.map((image, index) => {
              return index < 2 ? (
                <div
                  className="column product-image"
                  key={`product-image-${index}`}
                >
                  <Image
                    alt={generatePdpAlt(brand, name, index, images.length)}
                    src={getPDPImageMediumUrl(image, isCloudinary)}
                    onClick={() => handleZoom(images, index)}
                    isCloudinary={isCloudinary}
                  />
                  <div className="product-image__overlay" />
                </div>
              ) : (
                <InView
                  rootMargin="500px"
                  triggerOnce
                  key={`product-image-${index}`}
                >
                  {({ inView, ref }) => (
                    <div ref={ref} className="product-image">
                      {inView && (
                        <div
                          className="column product-image"
                          onClick={() => handleZoom(images, index)}
                          onKeyPress={() => handleZoom(images, index)}
                        >
                          <Image
                            alt={generatePdpAlt(
                              brand,
                              name,
                              index,
                              images.length,
                            )}
                            src={getPDPImageMediumUrl(image, isCloudinary)}
                            isCloudinary={isCloudinary}
                          />
                        </div>
                      )}
                      <div
                        className={classNames(
                          `product-image__overlay`,
                          inView && 'product-image__overlay__fade-out',
                        )}
                      />
                    </div>
                  )}
                </InView>
              );
            })}
        </div>
      )}
      {showAltImages && !openFromEditWishlist && isAlternateImages && (
        <div className="product-media__left-media hide-on-tablet">
          <AlternateImages
            name={name}
            brand={brand}
            productId={productId}
            images={images}
            video={video}
            isCloudinary={isCloudinary}
          />
        </div>
      )}

      <MainMedia
        productId={productId}
        name={name}
        brand={brand}
        images={images}
        video={video}
        activeIndex={activeIndex}
        showSoldOutOverlay={showSoldOutOverlay}
        dots={dots}
        arrows={arrows}
        displayOutfittingText={displayOutfittingText}
        sourceIsQL={sourceIsQL}
        isCloudinary={isCloudinary}
        openFromEditWishlist={openFromEditWishlist}
        isCommonBundle={isCommonBundle}
      />
      {!openFromEditWishlist && sourceIsQL && isAlternateImages ? (
        <ZoomImageQl productId={productId} isCloudinary={isCloudinary} />
      ) : (
        <ZoomImage productId={productId} isCloudinary={isCloudinary} />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const isGroup = get(state, 'productCatalog.product.isGroup');
  const firstGroupProduct = get(
    state,
    'productCatalog.group.childProducts.productIds[0]',
  );
  const media = get(state, 'productCatalog.group.media', {});
  const groupMedia = compact([media.main, ...values(media.alternate || [])]);
  const device = get(state, 'device', {});
  const preloadPdpImage = get(state.toggles, 'PDP_PRELOAD_MAIN_IMAGE', false);
  const zoomPdpToggle = get(state, 'toggles.ZOOM_FN_PDP', false);

  return {
    isGroup,
    firstGroupProduct,
    groupMedia,
    device,
    preloadPdpImage,
    zoomPdpToggle,
  };
};

ProductMedia.defaultProps = {
  templateType: 'B',
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  openProductImageZoom: (...args) =>
    dispatch(openProductImageZoom(productId, ...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductMedia);
