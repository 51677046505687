import React, { Fragment } from 'react';
import { array, bool, object, string } from 'prop-types';
import classNames from 'classnames';
import ImageMapsComponent from './imageMapsComponent';

/**
 * @function Image
 * @param {Object} props.actualImageDimensions
 * @param {String} [props.altText]
 * @param {String} props.cdnImageSrc
 * @param {String} props.CN
 * @param {Array} props.imageMaps
 * @param {Boolean} [props.isLazyLoad]
 * @param {Boolean} [props.inView]
 * @param {Object} [props.parentTrackingTags]
 * @param {String} [props.title]
 * @returns {React.ReactElement}
 */
const Image = ({
  actualImageDimensions,
  altText,
  cdnImageSrc,
  CN,
  imageMaps,
  isLazyLoad,
  inView,
  parentTrackingTags,
  title,
  hoverColorOverlay,
  desktopHoverImageSrc,
  isMobilePhone,
  mobileImageSrc,
  hoverToggle,
}) => {
  const imgClassName = classNames(CN, isLazyLoad && `${CN}--lazyload`);
  const aspectRatio =
    actualImageDimensions &&
    (actualImageDimensions.height / actualImageDimensions.width) * 100;
  const shouldRenderImage = !isLazyLoad || (isLazyLoad && inView);
  const hoverColorOverlayEl = hoverColorOverlay ? (
    <div
      className="overlay-color-element"
      style={{ backgroundColor: hoverColorOverlay }}
    />
  ) : null;

  const desktopHoverImageEl = desktopHoverImageSrc ? (
    <img className="hover-image" src={desktopHoverImageSrc} alt="" />
  ) : null;

  const doRenderHoverImgOverlay =
    !isMobilePhone || (isMobilePhone && !mobileImageSrc);

  return (
    <Fragment>
      {hoverToggle && doRenderHoverImgOverlay && desktopHoverImageEl}
      {shouldRenderImage && (
        <img
          alt={altText}
          className={imgClassName}
          src={cdnImageSrc}
          title={title || altText}
        />
      )}
      {hoverToggle && doRenderHoverImgOverlay && hoverColorOverlayEl}

      {isLazyLoad && (
        <div
          className={classNames(
            `${CN}-overlay`,
            shouldRenderImage && 'image-asset-overlay-fade-out',
          )}
          style={{ paddingTop: `${aspectRatio}%` }}
        />
      )}

      {title && <p className={`${CN}-caption`}>{title}</p>}
      <ImageMapsComponent
        imageMaps={imageMaps}
        parentTrackingTags={parentTrackingTags}
        CN={CN}
      />
    </Fragment>
  );
};

Image.defaultProps = {
  isLazyLoad: false,
  inView: true,
};

Image.propTypes = {
  actualImageDimensions: object.isRequired,
  altText: string,
  cdnImageSrc: string.isRequired,
  CN: string.isRequired,
  imageMaps: array,
  isLazyLoad: bool,
  inView: bool,
  parentTrackingTags: object,
  title: string,
  hoverColorOverlay: bool,
  desktopHoverImageSrc: string,
  isMobilePhone: bool,
  mobileImageSrc: string,
  hoverToggle: bool,
};

export default Image;
