import React from 'react';
import classNames from 'classnames';
import { renderFormattedPrice } from 'client-utils/utilities-price';
import { renderFormattedPriceWithDecimals } from 'pdp/utilities/utilities-price';
import { unescape } from 'he';
import './priceAdornment.scss';

const PriceAdornment = ({
  currentPrice,
  // label,
  price,
  // color,
  currencyCode,
  showPriceInDecimals = false,
  // showAdornLabel = true,
  hasPromoPrice = false,
}) => (
  <span
    className={classNames(
      'adornment',
      { currentPrice },
      { hasPromoPrice: currentPrice && hasPromoPrice }
    )}
    // style={{ color }}
  >
    {/* {label && showAdornLabel && (
      <span className="label">{`${unescape(label)} `}</span>
    )} */}
    <span className={classNames('price', { originalPrice: !currentPrice })}>
      {showPriceInDecimals
        ? renderFormattedPriceWithDecimals(price, currencyCode)
        : renderFormattedPrice(price, currencyCode)}
    </span>
  </span>
);

export default PriceAdornment;
