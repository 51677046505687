import { contactUsContent } from '../../common/constants';

export const ACTION_TYPES = {
  CONCIERGE_FORM_SUBMIT_PROCESSING: 'CONCIERGE_FORM_SUBMIT_PROCESSING',
  CONCIERGE_FORM_SUBMIT_SUCCESS: 'CONCIERGE_FORM_SUBMIT_SUCCESS',
  CONCIERGE_FORM_SUBMIT_ERROR: 'CONCIERGE_FORM_SUBMIT_ERROR',
};

export const content = {
  id: contactUsContent.id,
  title: contactUsContent.title,
  desc: 'How can we make your life better?',
  items: [
    {
      id: 'email-us',
      title: 'Email us',
      desc: '',
    },
    {
      id: 'call-us',
      title: 'Call Us at 1.888.570.6756',
      desc: 'Press 1 for travel, press 2 for tickets, sporting events, dining.',
    },
  ],
};

export const formInfo = {
  thankYou:
    'Thank you for contacting us. An InCircle Concierge representative will be in touch with you shortly.',
  inputInfo: {
    subject: 'What can we do for you?',
    contact: 'Please enter your contact information',
    comments: 'Please provide further details about your inquiry.',
  },
  error: 'Something went wrong, please try again.',
};

export const validationErrors = {
  emptySubject: 'Please provide an entry for Subject Field.',
  emptyFirstName: 'Please provide an entry for First Name Field.',
  emptyLastName: 'Please provide an entry for Last Name Field.',
  emptyAddress: 'Please provide an entry for Address Line Field.',
  emptyCity: 'Please provide an entry for City Field.',
  incorrectState: 'Please provide an entry for State Field.',
  emptyPostalCode: 'Please provide an entry for Postal Code Field.',
  invalidPostalCode: 'Please provide a valid Postal Code.',
  emptyPhoneNumber: 'Please provide an entry for Phone Number Field.',
  invalidPhoneNumber: 'Please provide a valid Phone Number.',
  emptyEmail: 'Please provide an entry for Email Field.',
  invalidEmail: 'Please provide a valid Email.',
  emptyComments: 'Please provide an entry for Comments Field.',
};
