import React from 'react';

const ForgotPasswordModalForm = ({ onSubmit }) => (
  <div className="forgot-password__content-container">
    <div className="forgot-password__form-block">
      <h2 className="forgot-password__header">Forgot password</h2>
      <p className="forgot-password__description">
        Please enter the email address you used to sign in.
      </p>
      <form onSubmit={onSubmit}>
        <div className="forgot-password-field">
          <label
            className="forgot-password-field__label"
            htmlFor="forgot-password-email-input"
          >
            <input
              id="forgot-password-email-input"
              className="forgot-password-field__input"
              type="email"
              name="email"
              placeholder=" "
              required
            />
            <span className="forgot-password-field__label-text forgot-password-field__label-text--small">
              Email Address
            </span>
          </label>
        </div>
        <button className="forgot-password__button" type="submit">
          Continue
        </button>
      </form>
    </div>
  </div>
);

export default ForgotPasswordModalForm;
