import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import every from 'lodash/every';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import ErrorMessage from 'productpage/components/ErrorMessage/errorMessage';
import { isMobile } from 'client-utils/utilities-page';
import { MONOGRAM_HELP_TEXT } from 'pdp/constants';
import { getSelectedCustomizationsAllTypesById } from 'pdp/components/ProductPage/reducers/productReducer';
import { setSelectedCustomizationObjectById } from 'pdp/components/ProductPage/actions';
import { EyeBrow1 } from 'bgo-common/Styleguide/Typography';
import './MonogramOutputDataDropship.scss';

export class DumbMonogramOutputDataDropship extends Component {
  constructor(props) {
    super(props);
    this.state = {
      NAME: [''],
      1: [''],
      3: ['', '', ''],
      validated: false,
    };

    this.scrollToTop = this.scrollToTop.bind(this);
    this.getErrorMessage = this.getErrorMessage.bind(this);
  }

  componentDidMount() {
    const {
      monoStyleChoiceType = '',
      selectedCustomizationData = {},
    } = this.props;
    const { selectedValues = [] } = selectedCustomizationData;
    const isEmptyText = every(selectedValues, value => {
      return !value;
    });
    if (isEmptyText) {
      return;
    }

    const newState = {};
    const key = this.getStateKeyByType(monoStyleChoiceType);
    newState[key] = [...selectedValues];
    this.setState(newState);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { monoStyleChoiceType = '' } = nextProps;
    const { monoStyleChoiceType: prevMonoStyleChoiceType = '' } = this.props;

    const key = this.getStateKeyByType(monoStyleChoiceType);
    const selectedText = this.state[key] || [];
    const isValidText = every(selectedText, value => {
      return value ? /^[a-zA-Z]+$/.test(value) : true;
    });

    if (!isEqual(monoStyleChoiceType, prevMonoStyleChoiceType)) {
      if (isValidText) {
        this.props.setSelectedText([...selectedText]);
      } else {
        this.props.setSelectedText([]);
      }
      this.props.setIsInvalidText(!isValidText);
      this.props.setSelectedCustomizationObjectById({
        id: get(this.props.selectedCustomizationData, 'id', ''),
        selectedValues: selectedText,
        isValid: get(this.props.selectedCustomizationData, 'isValid', false),
        touched: false,
      });
    }
  }

  getStateKeyByType(choiceType) {
    switch (choiceType) {
      case 'SINGLE_INIT':
        return 1;
      case 'THREE_INIT':
      case 'THREE_INIT_FML':
        return 3;
      default:
        return choiceType;
    }
  }

  getLabel(choiceType) {
    switch (choiceType) {
      case 'NAME':
        return 'Name';
      case 'SINGLE_INIT':
        return 'Initial';
      case 'THREE_INIT':
      case 'THREE_INIT_FML':
        return 'Initials';
      default:
        return '';
    }
  }

  getTextBoxes(choiceType) {
    const { maxLengthOfText = 0 } = this.props;
    const { selectedValues = [] } = this.props.selectedCustomizationData;

    switch (choiceType) {
      case 'NAME':
        return (
          <div className="monogram-text__user-input__name">
            <span>
              <input
                key="Name"
                aria-label="Name"
                type="text"
                value={selectedValues[0] || ''}
                maxLength={maxLengthOfText}
                onChange={e => this.handleChange(e, 'NAME', 0)}
                onFocus={this.scrollToTop}
                id="text"
                placeholder=" "
              />
              <label htmlFor="text">Text</label>
            </span>
          </div>
        );
      case 'SINGLE_INIT':
        return (
          <div className="monogram-text__user-input__initials">
            <span>
              <input
                key="First initial"
                aria-label="First initial"
                type="text"
                value={selectedValues[0] || ''}
                size={1}
                maxLength={1}
                onChange={e => this.handleChange(e, 1, 0)}
                onFocus={this.scrollToTop}
                placeholder="First initial"
                className="first-initial-single"
                id="first-initial-single"
                placeholder=" " // eslint-disable-line react/jsx-no-duplicate-props
              />
              <label htmlFor="first-initial-single">First Initial</label>
            </span>
          </div>
        );
      case 'THREE_INIT':
      case 'THREE_INIT_FML':
        return (
          <div className="monogram-text__user-input__initials">
            <span>
              <input
                key="First initial"
                aria-label="First initial"
                type="text"
                value={selectedValues[0] || ''}
                size={1}
                maxLength={1}
                onChange={e => this.handleChange(e, 3, 0)}
                onBlur={e => this.validateInitial(e)}
                onFocus={this.scrollToTop}
                className="first-initial"
                id="first-initial"
                placeholder=" "
              />
              <label htmlFor="first-initial">First Initial</label>
            </span>

            <span>
              <input
                key="Middle initial"
                aria-label="Middle initial"
                type="text"
                value={selectedValues[1] || ''}
                size={1}
                maxLength={1}
                onChange={e => this.handleChange(e, 3, 1)}
                onBlur={e => this.validateInitial(e)}
                onFocus={this.scrollToTop}
                className="middle-initial"
                id="middle-initial"
                placeholder=" "
              />
              <label htmlFor="middle-initial">Middle Initial</label>
            </span>

            <span>
              <input
                key="Last initial"
                aria-label="Last initial"
                type="text"
                value={selectedValues[2] || ''}
                size={1}
                maxLength={1}
                onChange={e => this.handleChange(e, 3, 2)}
                onBlur={e => this.validateInitial(e)}
                onFocus={this.scrollToTop}
                className="last-initial"
                id="last-initial"
                placeholder=" "
              />
              <label htmlFor="last-initial">Last initial</label>
            </span>
          </div>
        );
      default:
        return <div />;
    }
  }

  getDisplay() {
    const { monoStyleChoiceType = {}, defaultText = [] } = this.props;
    const { selectedValues = [] } = this.props.selectedCustomizationData;
    let activeText = [...selectedValues];
    const isEmptyText = every(activeText, value => {
      return !value;
    });
    if (isEmptyText) {
      activeText = defaultText;
    }

    if (monoStyleChoiceType === 'THREE_INIT') {
      const [input1 = '', input2 = '', input3 = ''] = activeText;
      return input1 + input3 + input2;
    }
    return activeText.join('');
  }

  getErrorMessage() {
    const {
      isValid = false,
      touched = false,
      selectedValues = [],
    } = this.props.selectedCustomizationData;
    const { validated } = this.state;
    const allValues = selectedValues && selectedValues.join('');

    if (
      touched &&
      validated &&
      !(allValues.length === selectedValues.length) &&
      isValid
    ) {
      return 'Enter required information.';
    } else if (touched && selectedValues.every(value => value.length === 0)) {
      return 'Enter required information.';
    } else if (touched && !isValid) {
      return 'Please use letters only, special characters are not allowed.';
    } else {
      return '';
    }
  }

  validateInitial(event) {
    event.preventDefault();
    let { validated } = this.state;
    validated = true;
    this.setState({
      validated,
    });
  }

  handleChange(e, type, index) {
    const { maxLengthOfText = 0 } = this.props;
    let value = e.target.value || '';
    value =
      type === 'NAME'
        ? capitalize(value.substr(0, maxLengthOfText))
        : value.substr(0, 1).toUpperCase();

    const selectedText = this.state[type] || [];
    selectedText[index] = value;
    const newState = {};
    newState[type] = selectedText;
    this.setState(newState);

    this.props.isIncompleteText && this.props.setIsIncompleteText(false);

    const isValidText = every(selectedText, value => {
      return value ? /^[a-zA-Z]+$/.test(value) : true;
    });
    this.props.setIsInvalidText(!isValidText);

    this.props.setSelectedCustomizationObjectById({
      id: get(this.props.selectedCustomizationData, 'id', ''),
      selectedValues: selectedText,
      isValid: isValidText,
      touched: true,
    });

    if (!isValidText) {
      this.props.setSelectedText([]);
      e.target.classList && e.target.classList.add('error');
      return;
    }
    e.target.classList && e.target.classList.remove('error');

    this.props.setSelectedText([...newState[type]]);
  }

  scrollToTop() {
    if (!isMobile()) {
      return;
    }
    const monogramTextElement = document.getElementsByClassName(
      'monogram-text',
    )[0];
    if (typeof monogramTextElement !== 'undefined') {
      monogramTextElement.scrollIntoView(true);
    }
  }

  render() {
    const { monoStyleChoiceType = '' } = this.props;
    const label = this.getLabel(monoStyleChoiceType);
    // const displayText = this.getDisplay();

    return (
      <div className="monogram-text monogram_title_options">
        <div className="monogram-text__user-input grid-100 tablet-grid-100 mobile-grid-100 grid-parent">
          <fieldset>
            <legend>
              <EyeBrow1>Enter Your {label}:</EyeBrow1>
            </legend>
            {this.getTextBoxes(monoStyleChoiceType)}
          </fieldset>
          {monoStyleChoiceType !== 'NAME' && (
            <p className="monogram-text__help-text">{MONOGRAM_HELP_TEXT}</p>
          )}
        </div>
        {/* <div className="monogram-text__preview grid-55 tablet-grid-55 mobile-grid-50 grid-parent">
          Preview Your {label}:
          <div className="monogram-text__preview-text">{displayText}</div>
          {monoStyleChoiceType !== 'NAME' && (
            <p className="monogram-text__help-text">{MONOGRAM_PREVIEW_HELPTEXT}</p>
          )}
          </div> */}
        <ErrorMessage message={this.getErrorMessage()} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    selectedCustomizationData: getSelectedCustomizationsAllTypesById(
      state,
      props,
    ),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setSelectedCustomizationObjectById: (...args) =>
    dispatch(setSelectedCustomizationObjectById(ownProps.productId, ...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbMonogramOutputDataDropship);
