export const getGuestTokens = keys => {
  const localStorageKeys = Object.keys(localStorage);
  const tokenObj = keys.reduce((acc, curr) => {
    localStorageKeys.forEach(key => {
      if (key.startsWith('guestUser.') && key.endsWith(curr)) {
        acc[curr] = localStorage.getItem(key);
      }
    });
    return acc;
  }, {});

  return tokenObj;
};
