import log from 'server/utilities/logger';
import httpWithLogging from 'universal/http-client';
import get from 'lodash/get';

export const RESOLVED_SITEMAP_DATA = 'RESOLVED_SITEMAP_DATA';
export const REJECTED_SITEMAP_DATA = 'REJECTED_SITEMAP_DATA';
export const REJECTED_TOGGLE = 'REJECTED_TOGGLE';

export function getSiteMapS3Data() {
  return (dispatch, getState) => {
    const state = getState();
    const dtSitemapToggle = get(state, 'toggles.DT_SiteMap', false);

    if (dtSitemapToggle) {
      const requestApi = httpWithLogging(state);
      return requestApi
        .get('/dt/sitemap')
        .then(res => {
          dispatch({
            type: RESOLVED_SITEMAP_DATA,
            payload: res.data,
          });
        })
        .catch(error => {
          dispatch({
            type: REJECTED_SITEMAP_DATA,
          });
          log.error(`Error getting sitemap data: ${error}`);
        });
    }
    return dispatch({ type: REJECTED_TOGGLE });
  };
}
