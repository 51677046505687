import { ACTION_TYPES } from './constants';

const initialState = {
  isSubmitting: false,
  isSubmitted: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.CONCIERGE_FORM_SUBMIT_PROCESSING:
      return {
        ...state,
        isSubmitting: true,
        isSubmitted: false,
        error: false,
      };
    case ACTION_TYPES.CONCIERGE_FORM_SUBMIT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isSubmitted: true,
        error: false,
      };
    case ACTION_TYPES.CONCIERGE_FORM_SUBMIT_ERROR:
      return {
        ...state,
        isSubmitting: false,
        isSubmitted: false,
        error: true,
      };
    default:
      return state;
  }
};
