import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { isLoadingFinished } from '@bgo-ui/common/client/utilities/utilities-api';
import { closeModal } from '@bgo-ui/common/client/common/components/Modal/actions';
import { silentLogout } from '@bgo-ui/common/client/utilities/utilities-amplify';
import Copy from '../../../../../../../common/Copy/Copy';
import SubmitButton from '../../../../../../../common/Forms/FormButtons/SubmitButton/SubmitButton';
import { redirectToLoginPage } from '../../../../helpers';
import { useConstructor } from '../../../../../../../../utilities/hooks';

export const DumbChangeEmailSuccess = props => {
  useConstructor(async () => {
    await silentLogout(props.disableATGToggle);
  });

  return (
    <div className="change-email-modal__success">
      <Copy
        content={get(props.myAccountContent, 'CHANGE-EMAIL-SUCCESS')}
        loaded={props.isContentLoaded}
        fallback={(
          <React.Fragment>
            <p>Your email has been successfully changed.</p>
            <p>Please log in with the new address.</p>
          </React.Fragment>
        )}
      />
      <SubmitButton
        caption="OK"
        onClick={redirectToLoginPage}
        additionalClasses="change-email-modal__submit-btn"
      />
    </div>
  );
};

DumbChangeEmailSuccess.propTypes = {
  myAccountContent: PropTypes.object.isRequired,
  isContentLoaded: PropTypes.bool.isRequired,
  disableATGToggle: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  myAccountContent: get(state, 'myaccount.myAccountContent', {}),
  isContentLoaded: isLoadingFinished(state, 'content'),
  disableATGToggle: get(state, 'toggles.DISABLE_ATG_CALLS', false),
});

const mapDispatchToProps = {
  closeModal,
};

const ChangeEmailSuccess = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbChangeEmailSuccess);

export default ChangeEmailSuccess;
