import React from 'react';
import AppMyAccountWrapper from './app-my-account-wrapper';
import MyRewardsPage from '../../myaccount/pages/MyRewardsPage/MyRewardsPage';

export default function AppMyRewardsWrapper() {
  return (
    <AppMyAccountWrapper>
      <MyRewardsPage />
    </AppMyAccountWrapper>
  );
}
