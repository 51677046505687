import React from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Field } from 'formik';
import Cleave from 'cleave.js/react';

import './FormikNumberField.scss';

const FormikNumberField = ({
  id,
  placeholder,
  name,
  type,
  options,
  fontSize,
}) => {
  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        const isError =
          form.submitCount > 0 && (meta.error === '' ? true : meta.error);
        const placeholderClassName = classNames(
          'form-text-field__placeholder',
          isError && 'error',
        );
        const inputClassName = classNames(
          'form-text-field__input',
          isError && 'error',
          fontSize && 'inputFont',
        );
        return (
          <div className="form-field form-text-field">
            <label className="form-text-field__label" htmlFor={id}>
              <Cleave
                id={id}
                value={field.value}
                name={field.name}
                type={type || 'text'}
                placeholder=" "
                className={inputClassName}
                options={{ numericOnly: true, ...options }}
                onChange={form.handleChange}
              />
              <span className={placeholderClassName}>{placeholder}</span>
            </label>
            {isError && !isEmpty(meta.error) && (
              <div className="form-text-field__error-message">{meta.error}</div>
            )}
          </div>
        );
      }}
    </Field>
  );
};

export default FormikNumberField;
