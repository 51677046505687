import React, { useState, useEffect, useContext } from 'react';
import get from 'lodash/get';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import { isLoadingFinished } from '@bgo-ui/common/client/utilities/utilities-api';
import {
  PAGE_ID_RESET_PASSWORD_CONFIRMATION,
  setPageId,
} from '@bgo-ui/common/client/common/actions/actions-page';
import {
  resolvedRegistrationPage,
  signUp,
  resetPassword,
  resolvedResetPasswordNewPage,
} from '../../../actions';
import PasswordRequirementsBox from '../../../../common/Forms/FormikPasswordField/PasswordRequirementsBox/PasswordRequirementsBox';
import SubmitButton from '../../../../common/Forms/FormButtons/SubmitButton/SubmitButton';
import { getPasswordRequirements } from '../../../../myaccount/utilities/validation-utils';
import FormikPasswordField from '../../../../common/Forms/FormikPasswordField/FormikPasswordField';
import Copy from '../../../../common/Copy/Copy';
import { validationErrors } from '../../../constants';

import { LogoContext } from '../../common/LoginPageCommonLayout/LoginPageCommonLayout';
import './NewResetPassword.scss';

const NewResetPassword = ({
  isLoading,
  isLoaded,
  error,
  resetPassword,
  location,
  loginContent,
  isContentLoaded,
  setPageId,
  resolvedResetPasswordNewPage,
  isDomLoadComplete,
}) => {
  const [reseted, setReseted] = useState(false);
  const { setMobileLogo } = useContext(LogoContext);

  const handleLoad = () => {
    resolvedResetPasswordNewPage();
    removeEventListener('load', handleLoad);
  };

  useEffect(() => {
    setMobileLogo(false);
    if (isDomLoadComplete) {
      resolvedResetPasswordNewPage();
    } else {
      addEventListener('load', handleLoad);
    }
  }, []);

  useEffect(() => {
    if (isLoaded && !isLoading) {
      setReseted(true);
      setPageId(PAGE_ID_RESET_PASSWORD_CONFIRMATION);
    }
  }, [isLoaded, isLoading]);

  const initialFormValue = {
    password: '',
    confirmPassword: '',
  };

  const validateForm = values => {
    const errors = {};
    if (!values.password) {
      errors.password = validationErrors.emptyPassword;
    } else if (
      getPasswordRequirements(values.password).some(req => !req.checked)
    ) {
      errors.password = validationErrors.badPassword;
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = validationErrors.emptyConfirm;
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = validationErrors.passwordsMatch;
    }
    return errors;
  };

  const submitForm = values => {
    const { code: confirmationCode, id: username } = get(location, 'query', {});
    resetPassword(
      {
        username,
        confirmationCode,
        password: values.password,
      },
      true,
    );
  };

  return (
    <div className="new-reset-password-page">
      {reseted ? (
        <div className="new-reset-password-page__feedback">
          <Copy
            content={get(loginContent, 'RESET-PASSWORD-FEEDBACK')}
            loaded={isContentLoaded}
            fallback={(
              <React.Fragment>
                <h3>Reset password</h3>
                <p>Your password has been reset.</p>
                <a href="/e/login">Sign In</a>
              </React.Fragment>
            )}
          />
        </div>
      ) : (
        <React.Fragment>
          <Copy
            content={get(loginContent, 'RESET-PASSWORD-DEFAULT')}
            loaded={isContentLoaded}
            fallback={(
              <React.Fragment>
                <h3>Reset your password</h3>
                <p>Please enter your new password.</p>
              </React.Fragment>
            )}
          />
          <Formik
            initialValues={initialFormValue}
            validate={validateForm}
            onSubmit={submitForm}
          >
            {({ values }) => (
              <Form autoComplete="off">
                <FormikPasswordField
                  name="password"
                  id="password-field"
                  placeholder="Password"
                  fontSize
                />
                <PasswordRequirementsBox
                  requirements={getPasswordRequirements(values.password)}
                />
                <FormikPasswordField
                  name="confirmPassword"
                  id="confirm-password-field"
                  placeholder="Confirm password"
                  fontSize
                />
                {error && (
                  <p className="new-reset-password-page__error">{error}</p>
                )}
                <div
                  className={classnames(
                    'new-reset-password-page__submit-container',
                    getPasswordRequirements(values.password).some(
                      req => !req.checked,
                    ) && 'divDisabled',
                  )}
                >
                  <SubmitButton
                    isSubmitting={isLoading}
                    type="submit"
                    caption="Confirm"
                    additionalClasses="new-reset-password-page__submit-btn"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </React.Fragment>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  isLoading: get(state, 'login.resetPassword.isLoading', false),
  isLoaded: get(state, 'login.resetPassword.isLoaded', false),
  error: get(state, 'login.resetPassword.error', false),
  loginContent: get(state, 'login.loginContent', {}),
  isContentLoaded: isLoadingFinished(state, 'login_content'),
});

const mapDispatchToProps = {
  resetPassword,
  setPageId,
  resolvedResetPasswordNewPage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewResetPassword);
