import React from 'react';
import { connect } from 'react-redux';
import { CHINA, UNITED_STATES } from 'storefront/components/constants';
import { isCountry } from 'client-utils/utilities-country';
import AppDownLoadLink from 'shared/components/appDownloadLink';
import CountrySelector from '../../../CountrySelector/countrySelector';
import EmailSignup from '../EmailSignup/emailSignup';
import SocialMediaLinks from '../SocialMediaLinks/socialMediaLinks';
import './footerLinks.scss';
import Toggle from '../../../../client/common/components/Toggle/toggle';
// import NMApp from '../NMApp/NMApp';

const FooterRightContainer = props => (
  <div
    className={`grid-footer-40 grid-footer-100 footer-right-container horizontal-padding-nil ${
      props.isFullWidth && isCountry(props.country, UNITED_STATES)
        ? ''
        : 'footer-international'
    }`}
  >
    {props.isFullWidth ? (
      <div className="country-selector-wrapper nation-box">
        <Toggle feature="INTERNATIONAL">
          <CountrySelector visibleOn="desktop-tablet-only" />
        </Toggle>
      </div>
    ) : (
      false
    )}
    {isCountry(props.country, CHINA) ? (
      <div className="grid-footer-100 grid-footer-50 for-china">
        <EmailSignup />
      </div>
    ) : (
      <div className="emailSignupWrapper">
        <div className="grid-footer-100 horizontal-padding-nil">
          <EmailSignup />
        </div>
        <div className="grid-footer-100 tablet-view">
          <div className="social-media-domestic">
            <SocialMediaLinks />
            <div className="hide-on-footer-mobile">
              <Toggle feature="DOWNLOAD_APP_LINK">
                <AppDownLoadLink />
              </Toggle>
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
);

const mapStateToProps = state => ({
  country: state.locale.countryCode,
  isFullWidth: state.toggles.HOMEPAGE_FULL_WIDTH,
});

export default connect(mapStateToProps)(FooterRightContainer);
