import React from 'react';
import { Form, Formik } from 'formik';
import FormikTextField from '../../common/Forms/FormikTextField/FormikTextField';
import FormikNumberField from '../../common/Forms/FormikNumberField/FormikNumberField';
import FormikDropDownField from '../../common/Forms/FormikDropDown/FormikDropDown';
import SubmitButton from '../../common/Forms/FormButtons/SubmitButton/SubmitButton';

const IncircleConciergeForm = ({
  formInfo,
  stateList,
  isSubmitting,
  isSubmitted,
  error,
  initialFormValue,
  validateForm,
  handleSubmit,
}) => {
  if (isSubmitted) {
    return formInfo.thankYou;
  }

  const { inputInfo } = formInfo;

  return (
    <Formik
      initialValues={initialFormValue}
      validate={validateForm}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Form autoComplete="off" className="incircle-concierge-form">
          <div className="incircle-concierge-form__inputs">
            <p className="input-info">{inputInfo.subject}</p>
            <FormikTextField
              name="subject"
              id="subject-field"
              placeholder="Subject*"
              maxLength={40}
              fontSize
            />
            <p className="input-info">{inputInfo.contact}</p>
            <div className="input-col-container">
              <FormikTextField
                name="firstName"
                id="first-name-field"
                placeholder="First Name*"
                maxLength={40}
                fontSize
              />
              <FormikTextField
                name="lastName"
                id="last-name-field"
                placeholder="Last Name*"
                maxLength={40}
                fontSize
              />
            </div>
            <FormikTextField
              name="address"
              id="address-field"
              placeholder="Address Line 1*"
              maxLength={40}
              fontSize
            />
            <FormikTextField
              name="city"
              id="city-field"
              placeholder="City*"
              maxLength={40}
              fontSize
            />
            <div className="input-col-container">
              <FormikDropDownField
                caption="States:"
                placeholder="State*"
                valuesArray={stateList}
                id="state-field"
                name="state"
                value={values.state}
                fontSize
              />
              <FormikNumberField
                name="postalCode"
                id="postal-code-field"
                placeholder="Zip/Postal Code*"
                options={{ blocks: [5] }}
                fontSize
              />
            </div>
            <div className="input-col-container">
              <FormikNumberField
                name="phoneNumber"
                id="phone-number-field"
                placeholder="Phone Number*"
                options={{ blocks: [3, 3, 4], delimiter: '-' }}
                fontSize
              />
              <FormikTextField
                name="email"
                type="email"
                id="email-field"
                placeholder="Email Address*"
                fontSize
              />
            </div>
            <p className="input-info">{inputInfo.contact}</p>
            <FormikTextField
              name="comments"
              id="comments-field"
              placeholder="Comments*"
              fontSize
            />
            {error && (
              <div className="incircle-concierge-form__inputs__error">
                {formInfo.error}
              </div>
            )}
          </div>
          <SubmitButton
            isSubmitting={isSubmitting}
            additionalClasses="incircle-concierge-form__submit-btn"
            caption="Submit"
            type="submit"
          />
        </Form>
      )}
    </Formik>
  );
};

export default IncircleConciergeForm;
