import React from 'react';
import { getWishlistImage } from 'bgo-common/components/Util/product-cloudinary';
import { unescape } from 'he';
import get from 'lodash/get';
import classnames from 'classnames';
import Image from '../../../../../../../../bgo-ui-pdp/src/client/components/pdp/components/ProductPage/components/Image/image';
import { ITEM_NOT_AVAILABLE } from '../../../constants';
import WishlistButtons from '../WishlistButtons/WishlistButtons';
import WishlistProdDetails from '../WishlistProdDetails/WishlistProdDetails';
import './ItemList.scss';

const ItemList = ({ wishlistData, removeWishlist, isCloudinary }) => {
  const colorOnly = get(wishlistData, `options.productOptions`, []).filter(
    item => item.label && item.label === 'color',
  )[0];
  const colorValues = get(colorOnly, 'values', []);
  let mediaObjectByColor = null;

  if (colorValues && colorValues.length && colorValues.length > 1) {
    const color = get(wishlistData, 'sku.color.name');
    const index = colorValues.findIndex(({ name }) => name === color);
    mediaObjectByColor = colorValues[index]?.media;
  }

  const mediaUrl = getWishlistImage(
    mediaObjectByColor || wishlistData.media,
    mediaObjectByColor ? 'main.medium.url' : 'media.main.dynamic.url',
    isCloudinary,
  );

  wishlistData.quantity = 1;
  const prodAvailable = get(wishlistData, 'sku.stockLevel');
  const stockStatusMessage = get(wishlistData, 'sku.stockStatusMessage');
  const skuId = get(wishlistData, 'sku.id');
  const colorKey = get(wishlistData, 'sku.color.key', '');
  const sizeKey = get(wishlistData, 'sku.size.key', '');
  const canonicalUrlwithParams = `${get(
    wishlistData,
    'details.canonicalUrl',
    '',
  )}?color=${colorKey}&size=${sizeKey}`;
  return (
    <div className="new-wishlist-item">
      <div className={classnames('new-wishlist-item__prod-detail-container')}>
        <div
          className={classnames(
            'new-wishlist-item__img-wrapper',
            prodAvailable === 0 &&
              stockStatusMessage === ITEM_NOT_AVAILABLE &&
              'divDisabled',
          )}
        >
          <a href={canonicalUrlwithParams}>
            <Image
              alt={unescape(wishlistData.name)}
              isCloudinary={isCloudinary}
              src={mediaUrl}
            />
          </a>
        </div>
        <WishlistProdDetails
          wishlistData={wishlistData}
          prodDisabled={
            prodAvailable === 0 && stockStatusMessage === ITEM_NOT_AVAILABLE
          }
          canonicalUrl={canonicalUrlwithParams}
        />
      </div>
      <WishlistButtons
        removeWishlist={removeWishlist}
        skuId={skuId}
        productId={wishlistData.id}
        selectedSku={wishlistData.skus[0]}
        product={wishlistData}
        prodAvailable={
          prodAvailable === 0 && stockStatusMessage === ITEM_NOT_AVAILABLE
        }
        buttonEnabled={get(wishlistData, 'preOrder', false)}
      />
    </div>
  );
};
export default ItemList;
