import {
  RSVP_FORM_LOADING,
  RSVP_FORM_RESOLVED,
  RSVP_FORM_REJECTED,
} from './actions';
import { loadingStatus } from './constants';

const defaultState = {
  rsvpFormLoadingStatus: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case RSVP_FORM_LOADING: {
      return { ...state, rsvpFormLoadingStatus: loadingStatus.loading };
    }
    case RSVP_FORM_RESOLVED: {
      return { ...state, rsvpFormLoadingStatus: loadingStatus.resolved };
    }
    case RSVP_FORM_REJECTED: {
      return { ...state, rsvpFormLoadingStatus: loadingStatus.rejected };
    }
    default: {
      return state;
    }
  }
};
