import React from 'react';
import get from 'lodash/get';
import merge from 'lodash/merge';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { calculateTextOverlayPositionProps, getTags } from '../utils';
import ImageAsset from '../imageAsset/imageAsset';
import VideoAsset from '../video/video';
import RichText from '../richText/richText';
import { defaultTrackingTags } from '../../constants/constants';
import './textOverImage.scss';

const CN = 'text-over-image';
const mobileViewTextPosition = 'Below';

/**
 * @function DumbTextOverImage
 * @param {Object} props.cmsContentItem
 * @param {Boolean} props.isMobilePhone
 * @param {Object} props.parentTrackingTags
 * @returns {React.ReactElement}
 */
export function DumbTextOverImage({
  cmsContentItem = {},
  isMobilePhone,
  parentTrackingTags,
  isDesktop,
  hoverToggle,
}) {
  const fields = get(cmsContentItem, 'fields', {});
  const {
    image,
    text,
    desktopTextPosition,
    desktopTextWidth = 'auto',
    desktopTextMarginHorizontal,
    desktopTextMarginVertical,
    tabletTextPosition,
    tabletTextWidth,
    tabletTextMarginHorizontal,
    tabletTextMarginVertical,
    mobileTextPosition,
    mobileTextWidth,
    mobileTextMarginHorizontal,
    mobileTextMarginVertical,
    trackingTags,
    enableHoverLogic = false,
  } = fields;

  // Set up Tracking Tags
  const currentTags = getTags(trackingTags);
  const mergedTrackingTags = merge({}, parentTrackingTags, currentTags);

  const hasTabletProps = tabletTextPosition;
  const hasMobileProps = isMobilePhone && mobileTextPosition;

  const desktopProps = {
    position: desktopTextPosition,
    marginHorizontal: desktopTextMarginHorizontal,
    marginVertical: desktopTextMarginVertical,
    width: desktopTextWidth,
  };

  const tabletProps = hasTabletProps
    ? {
        position: tabletTextPosition,
        marginHorizontal: tabletTextMarginHorizontal,
        marginVertical: tabletTextMarginVertical,
        width: tabletTextWidth || desktopTextWidth,
      }
    : desktopProps;

  const mobileProps = hasMobileProps
    ? {
        position: mobileTextPosition,
        marginHorizontal: mobileTextMarginHorizontal,
        marginVertical: mobileTextMarginVertical,
        width: mobileTextWidth || tabletTextWidth,
      }
    : tabletProps;

  const overlayPropsToCss = ({
    width,
    position,
    marginHorizontal,
    marginVertical,
  }) => {
    return Object.entries({
      top: 'auto',
      right: 'auto',
      bottom: 'auto',
      left: 'auto',
      width: `${width}%`,
      ...calculateTextOverlayPositionProps(
        position,
        marginHorizontal,
        marginVertical,
      ),
    })
      .map(([k, v]) => `${k}: ${v};`)
      .join('');
  };

  const assetClassName = `cms-asset-${cmsContentItem.sys.id}`;
  const overlappedTextClassName = `${CN}__text--overlapped`;

  const styles = (
    <style
      dangerouslySetInnerHTML={{
        __html: `
        .${assetClassName} .${overlappedTextClassName} {
          ${overlayPropsToCss(mobileProps)}
        }

        @media screen and (min-width: 768px) and (max-width: 1024px) {
          .${assetClassName} .${overlappedTextClassName} {
            ${overlayPropsToCss(tabletProps)}
          }
        }

        @media screen and (min-width: 1025px) {
          .${assetClassName} .${overlappedTextClassName} {
            ${overlayPropsToCss(desktopProps)};
          }
        }`,
      }}
    />
  );

  return (
    <div
      className={classNames(assetClassName, CN, {
        'enable-hover-logic': hoverToggle && enableHoverLogic && isDesktop,
        'enable-hovered-view': hoverToggle && enableHoverLogic && !isDesktop,
      })}
    >
      {styles}
      {image.contentType && image.contentType === 'videoContentAsset' ? (
        <VideoAsset
          cmsContentItem={image}
          parentTrackingTags={mergedTrackingTags}
        />
      ) : (
        <ImageAsset
          cmsContentItem={image}
          parentTrackingTags={mergedTrackingTags}
        />
      )}
      <div
        className={
          mobileTextPosition === mobileViewTextPosition && isMobilePhone
            ? `${CN}__text`
            : overlappedTextClassName
        }
      >
        <RichText
          cmsContentItem={text}
          parentTrackingTags={mergedTrackingTags}
        />
      </div>
    </div>
  );
}

DumbTextOverImage.defaultProps = {
  parentTrackingTags: defaultTrackingTags,
};

const mapStateToProps = state => ({
  isMobilePhone: get(state, 'device.isMobilePhone', false),
  isDesktop: get(state, 'device.isDesktop', false),
  hoverToggle: state.toggles.HOVER_BG_IMAGE,
});

export default connect(mapStateToProps)(DumbTextOverImage);
