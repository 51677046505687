import React, { Component } from 'react';
import { connect } from 'react-redux';
import { shouldLoad } from 'universal/http-client';
import {
  getABTestAssignments,
  NEW_MOBILE_NAV_MBOX_ID,
  AEM_ABTEST_USERGROUP_MBOX_ID,
} from 'shared/actions/actions-page';
import NotFoundApp from './app-notFound';

export class DumbNotFoundAppWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = { callAbTestService: true };
  }

  UNSAFE_componentWillMount() {
    let callAbTestService = true;
    const mBoxIds = [];
    this.props.newMobileNavToggle && mBoxIds.push(NEW_MOBILE_NAV_MBOX_ID);
    this.props.abtestAemContentToggle &&
      mBoxIds.push(AEM_ABTEST_USERGROUP_MBOX_ID);
    if (mBoxIds.length > 0) {
      if (shouldLoad(this.props.abTestApi)) {
        this.props.getABTestAssignments(mBoxIds.join(','), true);
      }
    } else {
      callAbTestService = false;
    }
    this.state = { callAbTestService };
  }

  render() {
    const shouldContinue =
      !this.state.callAbTestService ||
      (this.state.callAbTestService &&
        (this.props.abTestApi.resolved || this.props.abTestApi.rejected));
    return (
      <React.Fragment>
        {shouldContinue ? <NotFoundApp {...this.props} /> : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  newMobileNavToggle: state.toggles.NEW_MOBILE_NAV,
  abtestAemContentToggle: state.toggles.ABTEST_AEM_CONTENT_TARGETING,
  abTestApi: state.api.abtest || {},
});

export default connect(
  mapStateToProps,
  { getABTestAssignments },
)(DumbNotFoundAppWrapper);
