import get from 'lodash/get';
import React from 'react';
import classNames from 'classnames';
import { ecmGetAdaptiveStyleBlock } from '../../utilities';

const EcmIframe = ({ content, ecmSlot, formatAdaptiveStyleBlock = true }) => {
  const ecmStyle = get(ecmSlot, 'className', '');
  const componentId = get(content, 'componentId', '');
  const componentClass = `ecm-container__iframe__${componentId}`;
  const componentWrapperClass = `ecm-container__${componentId}`;
  const adaptiveStyleBlock = formatAdaptiveStyleBlock
    ? ecmGetAdaptiveStyleBlock(
        get(content, 'properties.padding', {}),
        componentClass,
      )
    : '';
  const formatDimensions = () => {
    const adaptiveStyle = `
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .${componentWrapperClass} { width: ${get(
      content,
      'properties.padding.mobile.width',
      100,
    )}%; }
        .${componentWrapperClass} iframe { height: ${get(
      content,
      'properties.padding.mobile.height',
      100,
    )}px !important; }
      }
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        .${componentWrapperClass} { width: ${get(
      content,
      'properties.padding.tablet.width',
      100,
    )}%; }
        .${componentWrapperClass} iframe { height: ${get(
      content,
      'properties.padding.tablet.height',
      100,
    )}px !important; }
      }
      @media screen and (min-width: 1025px) {
        .${componentWrapperClass} { width: ${get(
      content,
      'properties.padding.desktop.width',
      100,
    )}%; }
        .${componentWrapperClass} iframe { height: ${get(
      content,
      'properties.padding.desktop.height',
      100,
    )}px !important; }
      }`;
    return <style dangerouslySetInnerHTML={{ __html: adaptiveStyle }} />;
  };
  return (
    <div className={classNames('ecm-container__iframe', ecmStyle)}>
      <div
        tabIndex="0"
        className={classNames(
          'ecm-container__iframe__container',
          componentWrapperClass,
        )}
      >
        <div
          tabIndex="0"
          className={classNames(
            'ecm-container__iframe__wrapper',
            componentClass,
          )}
        >
          <iframe
            tabIndex="0"
            src={get(content, 'properties.url', '')}
            className="ecm-container__iframe__frame"
          />
        </div>
        {adaptiveStyleBlock}
        {formatDimensions()}
      </div>
    </div>
  );
};

export default EcmIframe;
