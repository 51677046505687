import get from 'lodash/get';
import { UPDATE_SEARCHLIST_CHECKVALUE } from 'srp/actions/actions';
import {
  UPDATE_SEARCH,
  TOGGLE_SEARCH_PANEL,
  AUTO_SUGGESTION_LIST,
  CLEAR_AUTO_SUGGESTION_LIST,
  ENDECA_SUGGESTION_LIST,
  CLEAR_SUGGESTION_LIST,
  types as SearchTypes,
  OPEN_SEARCH_PANEL,
  CLOSE_SEARCH_PANEL,
  SET_RECENTLY_SEARCHED_ANALYTICS,
} from './search-actions';

const defaultState = {
  searchTerm: '',
  isVisible: false,
  placeholderValue: '',
  mobileSearchTerm: '',
  expand: false,
  autoSuggestionList: [],
  endecaSuggestions: [],
  recentlySearchedAnalytics: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_SEARCH: {
      return { ...state, searchTerm: action.searchTerm };
    }
    case TOGGLE_SEARCH_PANEL: {
      return { ...state, isVisible: !state.isVisible };
    }
    case OPEN_SEARCH_PANEL: {
      return { ...state, isVisible: true };
    }
    case CLOSE_SEARCH_PANEL: {
      return { ...state, isVisible: false };
    }
    case SearchTypes.SET_PLACEHOLDER_VALUE: {
      return {
        ...state,
        placeholderValue: action.placeholderValue,
      };
    }
    case SearchTypes.SET_MOBILE_SEARCH_TERM: {
      return {
        ...state,
        mobileSearchTerm: action.mobileSearchTerm,
      };
    }
    case UPDATE_SEARCHLIST_CHECKVALUE: {
      return {
        ...state,
        expand: action.expand,
      };
    }
    case AUTO_SUGGESTION_LIST: {
      return {
        ...state,
        autoSuggestionList: action.payload,
      };
    }
    case CLEAR_AUTO_SUGGESTION_LIST: {
      return {
        ...state,
        autoSuggestionList: [],
      };
    }
    case ENDECA_SUGGESTION_LIST: {
      return {
        ...state,
        endecaSuggestions: action.payload,
      };
    }
    case CLEAR_SUGGESTION_LIST: {
      return {
        ...state,
        endecaSuggestions: [],
      };
    }
    case SET_RECENTLY_SEARCHED_ANALYTICS: {
      return {
        ...state,
        recentlySearchedAnalytics: true,
      };
    }
    default: {
      return state;
    }
  }
};

export const getSearchTerm = state => get(state, 'search.searchTerm', {});
export const getSearchVisible = state => get(state, 'search.isVisible', {});
export const getAutoSuggestionList = state =>
  get(state, 'search.autoSuggestionList', []);
export const getPlaceholderValue = state =>
  get(state, 'search.placeholderValue', {});
export const getMobileSearchTerm = state =>
  get(state, 'search.mobileSearchTerm', {});
export const getEndecaSuggestionList = state =>
  get(state, 'search.endecaSuggestions', []);
