import React, { Fragment } from 'react';

const IncircleConciergeLocation = ({ location }) => (
  <Fragment>
    <b>{location.title}</b>
    <br />
    <span
      dangerouslySetInnerHTML={{
        __html: location.desc,
      }}
    />
    {!location.isLastOfList && (
      <Fragment>
        <br />
        <br />
      </Fragment>
    )}
  </Fragment>
);

export default IncircleConciergeLocation;
