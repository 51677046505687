import get from 'lodash/get';
import { getWhatsNewImage } from 'bgo-common/components/Util/product-cloudinary';
import { isObject } from 'util';

export function constructECMContent(products, textRef, isClodinary) {
  const content = [];
  const finalProducts = products.slice(0, +textRef.noOfProducts);
  finalProducts.forEach(element => {
    content.push(constructECMContentForImageWithText(element, isClodinary));
  });
  content.push(constructECMContentForTextOverImage(textRef));
  return content;
}

function constructECMContentForImageWithText(element, isCloudinary) {
  const colorOptions = element.options.productOptions
    ? element.options.productOptions.length > 0
      ? element.options.productOptions.filter(
          option => option.label === 'color',
        )
      : []
    : [];
  const isCloudinaryColorPresent =
    colorOptions.length > 0
      ? get(colorOptions[0].values[0], 'media.main.dynamic.url', undefined)
      : undefined;
  const mainObj =
    isCloudinaryColorPresent === undefined
      ? element.media.main
      : colorOptions[0].values[0].media.main;
  // const mainObj =
  //   colorOptions.length > 0
  //     ? colorOptions[0].values[0].media.main
  //     : element.media.main;
  const isCloudinaryAltColorPresent =
    colorOptions.length > 0
      ? get(
          colorOptions[0].values[0],
          'media.alternate.a.dynamic.url',
          undefined,
        )
      : undefined;
  const altObj =
    isCloudinaryAltColorPresent === undefined
      ? get(element, 'media.alternate', undefined)
        ? get(element, 'media.alternate.a', element.media.alternate)
        : undefined
      : colorOptions[0].values[0].media.alternate.a;
  // const altObj =
  //   colorOptions.length > 0
  //     ? colorOptions[0].values[0].media.alternate &&
  //       colorOptions[0].values[0].media.alternate.a
  //       ? colorOptions[0].values[0].media.alternate.a
  //       : element.media.alternate.a
  //     : element.media.alternate && element.media.alternate.a
  //     ? element.media.alternate.a
  //     : element.media.alternate;

  const mainImg = getWhatsNewImage(mainObj, 'medium.url', isCloudinary, 'main');
  const altImage = getWhatsNewImage(altObj, 'medium.url', isCloudinary, 'alt');

  return {
    componentType: 'imagewithtext',
    image: {
      properties: {
        images: {
          tablet: {
            'padding-top': null,
            'border-top-width': null,
            'border-right-width': null,
            'padding-left': null,
            'padding-bottom': null,
            'border-color': null,
            imagesrc: isObject(mainImg) ? mainImg.tablet.imageSrc : mainImg,
            'border-left-width': null,
            'border-bottom-width': null,
            'padding-right': null,
          },
          desktop: {
            'padding-top': null,
            'border-top-width': null,
            'border-right-width': null,
            'padding-left': null,
            'padding-bottom': null,
            'border-color': null,
            imagesrc: isObject(mainImg) ? mainImg.desktop.imageSrc : mainImg,
            'border-left-width': null,
            'border-bottom-width': null,
            altSrc: isObject(altImage) ? altImage.desktop.imageSrc : altImage,
            'padding-right': null,
          },
          mobile: {
            'padding-top': null,
            'border-top-width': null,
            'border-right-width': null,
            'padding-left': null,
            'padding-bottom': null,
            'border-color': null,
            imagesrc: isObject(mainImg) ? mainImg.mobile.imageSrc : mainImg,
            'border-left-width': null,
            'border-bottom-width': null,
            'padding-right': null,
          },
        },
        alttext: 'alt',
        description: null,
        linkurl: null,
        title: null,
      },
    },
    componentId: element.id,
    properties: {
      tablet: {
        'padding-top': null,
        'border-top-width': null,
        'border-right-width': null,
        'padding-left': null,
        'padding-bottom': null,
        'border-color': null,
        'border-left-width': null,
        'border-bottom-width': null,
        'padding-right': null,
        imageWidth: null,
        textPosition: null,
      },
      desktop: {
        'padding-top': null,
        'border-top-width': null,
        'border-right-width': null,
        'padding-left': null,
        'padding-bottom': null,
        'border-color': null,
        'border-left-width': null,
        'border-bottom-width': null,
        'padding-right': null,
        imageWidth: 50,
        textPosition: 'bottom',
      },
      mobile: {
        'padding-top': null,
        'border-top-width': null,
        'border-right-width': null,
        'padding-left': null,
        'padding-bottom': null,
        'border-color': null,
        'border-left-width': null,
        'border-bottom-width': null,
        'padding-right': null,
        imageWidth: null,
        textPosition: null,
      },
      linkurl: element.canonical,
    },
    text: {
      properties: {
        tablet: {
          'padding-top': null,
          'border-top-width': null,
          'border-right-width': null,
          'padding-left': null,
          'border-bottom-width': null,
          'padding-bottom': null,
          'border-color': null,
          'border-left-width': null,
          'padding-right': null,
        },
        desktop: {
          'padding-top': null,
          'border-top-width': null,
          'border-right-width': null,
          'padding-left': null,
          'border-bottom-width': null,
          'padding-bottom': null,
          'border-color': null,
          'border-left-width': null,
          'padding-right': null,
        },
        mobile: {
          'padding-top': null,
          'border-top-width': null,
          'border-right-width': null,
          'padding-left': null,
          'border-bottom-width': null,
          'padding-bottom': null,
          'border-color': null,
          'border-left-width': null,
          'padding-right': null,
        },
        bgcolor: null,
        text: `<p class='brand'><a href=${element.canonical}>${
          element.designer.name ? element.designer.name : element.designer
        }</a></p><p class='product_details'><a href=${element.canonical}>${
          element.name
        }<a></p>`,
        mobileText: `<p class='brand'><a href=${element.canonical}>${
          element.designer.name ? element.designer.name : element.designer
        }</a></p><p class='product_details'><a href=${element.canonical}>${
          element.name
        }<a></p>`,
        tabletText: `<p class='brand'><a href=${element.canonical}>${
          element.designer.name ? element.designer.name : element.designer
        }</a></p><p class='product_details'><a href=${element.canonical}>${
          element.name
        }<a></p>`,
      },
    },
  };
}

function constructECMContentForTextOverImage(refObj) {
  return {
    componentType: 'textoverimage',
    image: {
      componentType: 'adaptive-image',
      componentId: refObj.componentId,
      properties: {
        lazyLoadFlag: 'true',
        images: {
          tablet: {
            imageWidth: null,
            'padding-top': null,
            'border-top-width': null,
            'border-right-width': null,
            'padding-left': null,
            'padding-bottom': null,
            'border-color': null,
            imagesrc: refObj.src,
            'border-left-width': null,
            imageHeight: null,
            'margin-right': null,
            'margin-left': null,
            hide: false,
            'border-bottom-width': null,
            'margin-top': null,
            'margin-bottom': null,
            map: [],
            'padding-right': null,
          },
          desktop: {
            imageWidth: null,
            'padding-top': null,
            'border-top-width': null,
            'border-right-width': null,
            'padding-left': null,
            'padding-bottom': null,
            'border-color': null,
            imagesrc: refObj.src,
            'border-left-width': null,
            imageHeight: null,
            'margin-right': null,
            'margin-left': null,
            hide: false,
            'border-bottom-width': null,
            'margin-top': null,
            'margin-bottom': null,
            map: [],
            'padding-right': null,
          },
          mobile: {
            imageWidth: null,
            'padding-top': null,
            'border-top-width': null,
            'border-right-width': null,
            'padding-left': null,
            'padding-bottom': null,
            'border-color': null,
            imagesrc: refObj.src,
            'border-left-width': null,
            imageHeight: null,
            'margin-right': null,
            'margin-left': null,
            hide: false,
            'border-bottom-width': null,
            'margin-top': null,
            'margin-bottom': null,
            map: [],
            'padding-right': null,
          },
        },
        alttext: null,
        description: null,
        linkurl: refObj.linkTo,
        title: null,
      },
    },
    componentId: refObj.componentId,
    textoverlayelements: {
      tablet: [
        {
          componentType: 'textoverlay1',
          'padding-top': null,
          componentId: null,
          'border-top-width': null,
          'border-right-width': null,
          'padding-left': null,
          'padding-bottom': null,
          'border-color': null,
          'border-left-width': null,
          'margin-right': null,
          'margin-left': null,
          bgcolor: null,
          'border-bottom-width': null,
          'margin-top': null,
          text: `<p>${refObj.imageText}</p>`,
          'margin-bottom': null,
          map: {},
          'padding-right': null,
        },
      ],
      desktop: [
        {
          componentType: 'textoverlay1',
          'padding-top': null,
          componentId: null,
          'border-top-width': null,
          'border-right-width': null,
          'padding-left': null,
          'padding-bottom': null,
          'border-color': null,
          'border-left-width': null,
          'margin-right': null,
          'margin-left': null,
          bgcolor: null,
          'border-bottom-width': null,
          'margin-top': null,
          text: `<p>${refObj.imageText}</p>`,
          'margin-bottom': null,
          map: {},
          'padding-right': null,
        },
      ],
      mobile: [
        {
          componentType: 'textoverlay1',
          'padding-top': null,
          componentId: null,
          'border-top-width': null,
          'border-right-width': null,
          'padding-left': null,
          'padding-bottom': null,
          'border-color': null,
          'border-left-width': null,
          'margin-right': null,
          'margin-left': null,
          bgcolor: null,
          'border-bottom-width': null,
          'margin-top': null,
          text: `<p>${refObj.imageText}</p>`,
          'margin-bottom': null,
          map: {},
          'padding-right': null,
        },
      ],
    },
    callToActionCustomStyles: {
      tablet: [],
      desktop: [],
      mobile: [],
    },
    properties: {
      tablet: refObj.properties.tablet,
      desktop: refObj.properties.desktop,
      mobile: refObj.properties.mobile,
    },
    calltoactionbuttons: {
      tablet: [],
      desktop: [],
      mobile: [],
    },
  };
}
