import isEmpty from 'lodash/isEmpty';
import { RESOLVED_NA_TOTAL } from '../actions/actions-ecmcontent';

const defaultState = {
  total: null,
  products: [],
  curratedProducts: [],
  totalNA: null,
};

const productArrayUtil = (products, additionalProducts) =>
  products.concat(additionalProducts);

export default (state = defaultState, action) => {
  const { prodId = '', catID = '' } = action;
  switch (action.type) {
    case `RESOLVED_ECM_QL_PRODUCT_INFO_${prodId}`:
      if (isEmpty(state.products)) {
        return {
          ...state,
          total: action.payload.total,
          products: action.payload.products,
        };
      } else {
        const newProducts = productArrayUtil(
          state.products,
          action.payload.products,
        );
        return {
          ...state,
          products: newProducts,
          total: newProducts.length,
        };
      }
    case `RESOLVED_ECM_CURRATED_PRODUCTS_${catID}`:
      return {
        ...state,
        curratedProducts: [
          ...state.curratedProducts,
          {
            catID,
            products: action.payload,
          },
        ],
      };
    case RESOLVED_NA_TOTAL:
      return {
        ...state,
        totalNA: action.payload,
      };
    default:
      return state;
  }
};
