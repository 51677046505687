import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  PAGE_ID_REGISTRATION,
  setPageId,
} from '@bgo-ui/common/client/common/actions/actions-page';
import AppLoginCommon from './app-new-login-common';
import NewAccountRegisterPage from '../../../login/v2/register/NewAccountRegisterPage/NewAccountRegisterPage';

class AppNewRegister extends Component {
  constructor(props) {
    super(props);
    props.setPageId(PAGE_ID_REGISTRATION);
  }

  render() {
    return (
      <AppLoginCommon>
        <NewAccountRegisterPage />
      </AppLoginCommon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  setPageId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppNewRegister);
