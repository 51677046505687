import React from 'react';
import incircleArrowRight from 'assets/images/incircle-arrow-right.svg';
import { CallToActionTexts } from '../constants';
import './callToActions.scss';

const CallToActions = () => {
  const handleCta = key => {
    window.sessionStorage.setItem('minihub_cta_referrer', key);
  };

  return (
    <div className="call-to-actions">
      {CallToActionTexts.ctaList.map(cta => (
        <div className="call-to-actions__item" key={cta.id}>
          <h3 className="call-to-actions__item__title">{cta.title}</h3>
          <a href={cta.path} onClick={() => handleCta(cta.id)}>
            <img src={incircleArrowRight} alt={cta.title} alr={cta.title} />
          </a>
        </div>
      ))}
    </div>
  );
};

export default CallToActions;
