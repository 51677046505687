import React from 'react';
import { array, bool, object, string } from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y, Keyboard, Navigation } from 'swiper';
import isEmpty from 'lodash/isEmpty';
import CarouselPromoTile from '../productCarouselPromoTile';
import ProductCarouselTile from '../productCarouselTile';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/a11y/a11y.scss';

SwiperCore.use([A11y, Keyboard, Navigation]);

const SwiperProductCarousel = props => {
  const {
    cmsContentItem,
    combinedProducts,
    firstPromoItem,
    lastPromoItem,
    carouselDirection,
    mergedTrackingTags,
    isNewHomePage,
  } = props;
  const direction =
    carouselDirection === 'Vertical' ? 'vertical ' : 'horizontal';

  return (
    <Swiper
      slidesPerView={2.45}
      slidesPerGroup={2}
      breakpoints={{
        768: {
          slidesPerView: 5,
          slidesPerGroup: 4,
        },
        1025: {
          slidesPerView: 7,
          slidesPerGroup: 6,
        },
      }}
      direction={direction}
      freeMode
      navigation
      keyboard={{
        enabled: true,
      }}
    >
      {!isEmpty(firstPromoItem) && (
        <SwiperSlide>
          <div className="promo-tile">
            <CarouselPromoTile
              parentTrackingTags={mergedTrackingTags}
              promoItem={firstPromoItem}
            />
          </div>
        </SwiperSlide>
      )}
      {!isEmpty(combinedProducts) &&
        combinedProducts.map(
          ({
            designerName,
            displayable,
            driveToUrl,
            id,
            imageUrl,
            name,
            price,
            promotions,
          }) => (
            <SwiperSlide key={id}>
              <ProductCarouselTile
                designer={designerName}
                displayable={displayable}
                displayOptions={cmsContentItem && cmsContentItem.fields}
                fromNewHPCarousel={isNewHomePage}
                id={id}
                imageUrl={imageUrl}
                name={name}
                parentTrackingTags={mergedTrackingTags}
                price={price}
                promotions={promotions}
                url={driveToUrl}
              />
            </SwiperSlide>
          ),
        )}
      {!isEmpty(lastPromoItem) && (
        <SwiperSlide>
          <div className="promo-tile">
            <CarouselPromoTile
              parentTrackingTags={mergedTrackingTags}
              promoItem={lastPromoItem}
            />
          </div>
        </SwiperSlide>
      )}
    </Swiper>
  );
};

SwiperProductCarousel.propTypes = {
  cmsContentItem: object.isRequired,
  combinedProducts: array.isRequired,
  mergedTrackingTags: object.isRequired,
  firstPromoItem: object,
  lastPromoItem: object,
  carouselDirection: string,
  isNewHomePage: bool,
};

SwiperProductCarousel.defaultProps = {
  carouselDirection: 'Horizontal',
  firstPromoItem: {},
  lastPromoItem: {},
  isNewHomePage: true,
};

export default SwiperProductCarousel;
