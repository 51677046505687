import React from 'react';
import window from 'window-or-global';
import { connect } from 'react-redux';
import get from 'lodash/get';
import './AffirmCta.scss';
import OwnAffirmCta from '../OwnAffirmCta/OwnAffirmCta';

export class AffirmCTA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ctaLoaded: false,
      firstLoad: true,
    };
  }

  getAffirmAmount() {
    const { product } = this.props;
    const { price } = product;
    const parentheticalCharge = get(product, 'details.parentheticalCharge', 0);

    return (
      parseFloat(price.promotionalPrice || price.retailPrice) +
        parseFloat(parentheticalCharge || 0) || 0
    );
  }

  getAffirmCta() {
    const { product } = this.props;
    const productId = product.id;

    const affirmAmount = this.getAffirmAmount();

    const affirmComponent = window.affirm.ui.components.create('promo', {
      amount: affirmAmount * 100,
      pageType: 'product',
    });
    affirmComponent && affirmComponent.render(`#affirm-cta-info-${productId}`);
    affirmComponent &&
      affirmComponent.render(
        `.product-bundle-for-large #affirm-cta-info-${productId}`,
      );
    affirmComponent &&
      affirmComponent.render(
        `.product-bundle-for-medium #affirm-cta-info-${productId}`,
      );

    this.setState({
      ctaLoaded: true,
    });
  }

  render() {
    const {
      product,
      isDomLoadComplete,
      affirmToggle,
      affirmConfig,
      ownAffirmLink,
    } = this.props;

    if (!affirmToggle) {
      return null;
    }

    const { excludeProducts = [], excludeDepartments = [] } = affirmConfig;
    const cmosItem = get(product, 'metadata.cmosItem');
    const departmentCode = get(product, 'departmentCode', '');
    const excludedProduct =
      cmosItem && excludeProducts && excludeProducts.includes(cmosItem);
    const excludedDepartment =
      departmentCode &&
      excludeDepartments &&
      excludeDepartments.includes(departmentCode);
    const preciousJewelryItem = get(product, 'details.preciousJewelryItem');
    const preOrder = Boolean(product?.preOrder) || false;
    const replenishEnabled = product.replenishInterval > 0;

    const lowerLimit = parseFloat(affirmConfig.lowerPriceLimit) || 0;
    const upperLimit = parseFloat(affirmConfig.upperPriceLimit) || 0;
    const affirmAmount = this.getAffirmAmount();
    const lowerLimitExceeded = lowerLimit && affirmAmount < lowerLimit;
    const upperLimitExceeded = upperLimit && affirmAmount > upperLimit;

    const affirmDisabled =
      preciousJewelryItem ||
      excludedProduct ||
      excludedDepartment ||
      preOrder ||
      lowerLimitExceeded ||
      upperLimitExceeded;

    if (affirmDisabled) {
      return null;
    }

    const affirmHidden = replenishEnabled;

    const productId = product.id;
    const { ctaLoaded, firstLoad } = this.state;

    if (
      !ctaLoaded &&
      !firstLoad &&
      isDomLoadComplete &&
      window.affirm &&
      window.affirm.jsReady() &&
      !ownAffirmLink
    ) {
      this.getAffirmCta();
    }

    firstLoad && this.setState({ firstLoad: false });

    const style = affirmHidden ? { display: 'none' } : {};

    return ownAffirmLink ? (
      <OwnAffirmCta productId={productId} affirmAmount={affirmAmount} />
    ) : (
      <div
        id={`affirm-cta-info-${productId}`}
        style={style}
        className="affirm-cta"
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    isDomLoadComplete: get(state, 'page.isDomLoadComplete', false),
    affirmToggle: state.toggles.AFFIRM_PAYMENT,
    affirmConfig: state.affirmConfig,
    ownAffirmLink: state.toggles.OWN_AFFIRM_LINK,
  };
};

export default connect(mapStateToProps)(AffirmCTA);
