import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import InCircleLogoBgCreditCard from 'assets/images/InCircleLogoBgCreditCard.svg';
import './index.scss';

const IncircleTermsAndConditions = ({ memberBenefitTerms }) => {
  return (
    <Fragment>
      <div className="loyalty-terms">
        <section className="loyalty-terms__header">
          <img src={InCircleLogoBgCreditCard} alt="InCircle" alr="InCircle" />
        </section>
        <section className="loyalty-terms__content">
          <h1>{memberBenefitTerms.title}</h1>
          <div
            className="loyalty-terms__content-terms"
            dangerouslySetInnerHTML={{
              __html: memberBenefitTerms.desc,
            }}
          />
        </section>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  memberBenefitTerms: get(state, 'loyaltyConfig.memberBenefitTerms', ''),
});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IncircleTermsAndConditions);
