import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import whatsNewReducer from 'client/storefront/components/WhatsNew/whatsNew-reducers';
import rewardsReducer from 'shared/reducers/reducers-rewards';
import apiReducers from 'shared/reducers/reducers-api';
import pageReducers from 'shared/reducers/reducers-page';
import navigationReducers from 'bgo-common/components/Navigation/reducers/reducers';
import appReducers, {
  abTestSessionReducer,
  countrySelectorReducer,
  sessionReducer,
  togglesReducer,
  serviceTogglesReducer,
  userReducer,
} from 'client/common/components/App/app-reducers';
import modalReducers from 'shared/reducers/reducers-modal';
import abTestReducer from 'shared/reducers/abTestReducer';
import screenReaderReducer from 'bgo-common/components/ScreenReaderAlert/screenReaderAnnouncer-reducer';
import templatesReducer from 'bgo-common/client/templates/dux/templatesReducer';
import ecmReducers from 'bgo-common/client/ecm/reducers';
import miniCartReducers from 'bgo-common/components/Header/MiniCart/miniCart-reducers';
import miniCartDTReducers from 'bgo-common/components/Header/MiniCartDT/miniCartDT-reducers';
import miniHubReducers from 'bgo-common/components/Header/MiniHub/miniHub-reducers';
import spinnerReducers from 'shared/components/Spinner/spinner-reducers';
import refreshableContentReducer from 'bgo-common/components/RefreshableContent/refreshableContent-reducers';
import searchReducer from 'bgo-common/components/Header/Search/search-reducers';
import utagReducers from 'server/components/Utag/reducers/reducers';
import cmsContentReducer from 'bgo-common/client/cms/reducers/get-cms-content';
import stsReducer from 'shared/reducers/stsReducer';
import shopTheLookReducer from 'bgo-common/client/common/reducers/reducers-shopthelook';
import siteMapReducer from 'bgo-common/components/SiteMap/reducers';
import specialOfferReducer from 'bgo-common/components/SpecialOffer/reducers';
import productReducers from 'pdp/components/ProductPage/reducers';
import wishlistReducer from 'wishlist/components/WishlistPage/reducer';
import contentfulReducer from 'client/contentful/reducers';
import abTestOptReducer from '@bgo-ui/common/client/common/reducers/abTestOptReducer';
import bgxHamptonsReducer from './client/components/bgxhamptons/reducers';
import loginReducer from './client/components/login/reducers';
import bg360Reducer from './client/components/bg360/reducers';
import myAccountReducer from './client/components/myaccount/reducers';
import thReducers from './client/components/thematic/reducers';
import applyNowModalReducers from './client/components/myaccount/pages/BgCreditCardPage/NewBgCreditCardPage/WhyInCircle/ApplyNowModal/reducers';
import conciergeFormReducers from './client/components/bgincircleconcierge/components/IncircleConciergeContactUs/reducers';

export default combineReducers({
  routing: routerReducer,
  whatsNew: whatsNewReducer,
  serviceToggles: serviceTogglesReducer,
  toggles: togglesReducer,
  api: apiReducers,
  page: pageReducers,
  navigation: navigationReducers,
  app: appReducers,
  modal: modalReducers,
  screenReader: screenReaderReducer,
  templates: templatesReducer,
  ecm: ecmReducers,
  env_name: (state = {}) => state,
  user: userReducer,
  device: (state = {}) => state,
  miniCart: miniCartReducers,
  miniCartDT: miniCartDTReducers,
  miniHub: miniHubReducers,
  mobileAppDownloadLink: (state = {}) => state,
  mobileAppBanner: (state = {}) => state,
  onlyXLeftThresholds: (state = {}) => state,
  locale: countrySelectorReducer,
  session: sessionReducer,
  abTestIds: (state = {}) => state,
  abTests: abTestReducer,
  abTestsOpt: abTestOptReducer,
  abTestSession: abTestSessionReducer,
  spinner: spinnerReducers,
  refreshableContent: refreshableContentReducer,
  search: searchReducer,
  utag: utagReducers,
  config: (state = {}) => state,
  content: (state = {}) => state,
  bg360: bg360Reducer,
  bgxHamptons: bgxHamptonsReducer,
  login: loginReducer,
  amplifyConfig: (state = {}) => state,
  cms: cmsContentReducer,
  sts: stsReducer,
  productCatalog: productReducers,
  shopTheLookReducer,
  wishlist: wishlistReducer,
  contentful: contentfulReducer,
  myaccount: myAccountReducer,
  rewards: rewardsReducer,
  siteMapS3Data: siteMapReducer,
  emailId: specialOfferReducer,
  thematicHtml: thReducers,
  applyNowModal: applyNowModalReducers,
  conciergeForm: conciergeFormReducers,
  loyaltyCheckboxDisabledStateCodes: (state = {}) => state,
  loyaltyConfig: (state = {}) => state,
});
