import get from 'lodash/get';
import { RESOLVED_CRP, REJECTED_CRP } from 'shared/actions/actions-crp';
import { types } from './templateActions';

const defaultState = {
  X0redirectURL: '',
  templateType: '',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.RESOLVED_TEMPLATE_TYPE:
      return {
        ...state,
        templateType: action.payload.templateType,
        redirectType: get(
          action.payload,
          'templateAttributes.redirectType',
          ''
        ),
        templateDetails: action.payload,
        X0redirectURL: get(
          action.payload,
          'templateAttributes.redirectUrl',
          ''
        ),
      };
    case types.REJECTED_TEMPLATE_TYPE:
      return {
        ...state,
        X0redirectURL: `${NMConfig.ERROR_PAGE_URL}`,
      };
    case RESOLVED_CRP:
      return {
        ...state,
        templateType: action.payload.templateType,
        redirectType: get(
          action.payload,
          'templateAttributes.redirectType',
          ''
        ),
        X0redirectURL: get(
          action.payload,
          'templateAttributes.redirectUrl',
          ''
        ),
        templateDetails: action.payload,
        // templateDetails: ''
      };
    case REJECTED_CRP:
      return {
        ...state,
        X0redirectURL: `${NMConfig.ERROR_PAGE_URL}`,
      };
    case types.SET_SILO_THUMBNAIL_DATA:
      return {
        ...state,
        siloThumbnailData: action.payload,
      };
    case types.DESIGNER_INDEX_EMPTY_DYNAMIC_CONTENT:
      return {
        ...state,
        X0redirectURL: `${NMConfig.ERROR_PAGE_URL}?source=DesignerIndex`,
      };
    default:
      return state;
  }
};
