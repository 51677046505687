export const getPasswordRequirements = value => {
  const length = value && value.length > 9;
  const lower = /([a-z])/.test(value);
  const upper = /([A-Z])/.test(value);
  const numbers = /([0-9])/.test(value);
  const special = /[^A-Za-z0-9]/.test(value);
  const repeat = /(.)\1\1/.test(value);

  return [
    {
      text: 'More than 9 characters',
      checked: length,
    },
    {
      text: 'Letters and numbers',
      checked: (lower || upper) && numbers,
    },
    {
      text: 'Upper and lower case',
      checked: lower && upper,
    },
    {
      text: 'A special character',
      checked: special,
    },
    {
      text: 'No more than 2 consecutive repeating characters',
      checked: !repeat && value.length,
    },
  ];
};
