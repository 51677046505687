import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import './ticker.scss';

const DIRECTION = {
  LEFT: 'left',
  REVERSE: 'reverse',
};

export default class Ticker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Width: 0,
    };
  }

  componentDidMount() {
    this.setContainerWidth();
  }

  setContainerWidth() {
    const { container } = this;
    if (container) {
      const containerWidth = container.offsetWidth;
      this.setState({ width: containerWidth });
    }
  }

  renderTickerWithChainMode(text, chainMode, styles) {
    const tickerText = `${text}${' '}`.repeat(
      this.state.width / text.length / 8,
    );
    return [...Array.from({ length: 2 })].map((e, i) => {
      return (
        <div className="ticker-items" style={styles} key={i}>
          {tickerText}
        </div>
      );
    });
  }

  render() {
    const { chainMode, direction, text, speed } = this.props;
    const containerWidth = this.state.width;
    const ANIMATION_DIRECTION =
      direction === DIRECTION.LEFT ? DIRECTION.LEFT : DIRECTION.REVERSE;
    const ANIMATION_DURATION = containerWidth / speed;
    const styles = {
      animationDirection: ANIMATION_DIRECTION,
      animationDuration: `${ANIMATION_DURATION}s`,
    };

    return (
      <Fragment>
        {!isEmpty(text) ? (
          <div
            ref={el => {
              this.container = el;
            }}
            className="ticker-container"
          >
            {chainMode ? (
              this.renderTickerWithChainMode(text, chainMode, styles)
            ) : (
              <div
                className="ticker-items ticker-scroll-padding"
                style={styles}
              >
                {text}
              </div>
            )}
          </div>
        ) : null}
      </Fragment>
    );
  }
}

Ticker.propTypes = {
  text: PropTypes.string,
  chainMode: PropTypes.bool,
  direction: PropTypes.string,
  speed: PropTypes.number,
};

Ticker.defaultProps = {
  text: '',
  chainMode: true,
  direction: 'left',
  speed: 30,
};
