import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Button from '@bgo-ui/common/client/common/components/Button/button';
import { LOYALTY_LANDING_PAGE_LINK } from '../constants';

const TermsAndConditions = ({ termsSection }) => {
  const handleOnIncircleClick = () => {
    window.location.href = LOYALTY_LANDING_PAGE_LINK;
  };

  return (
    <Fragment>
      <section className="center-section content-section">
        <h2 className="title title-header">{termsSection.title}</h2>
        <div className="center-button">
          <Button
            className="incircle-button"
            onClick={handleOnIncircleClick}
            value={termsSection.buttonText}
          />
        </div>
      </section>
      <section className="center-section content-section">
        <h4 className="title-header terms-conditions">
          {termsSection.termsAndConditions}
        </h4>
        <h4 className="title-header terms-conditions">
          {termsSection.subTitle}
        </h4>
        <p
          className="terms-conditions"
          dangerouslySetInnerHTML={{
            __html: termsSection.desc,
          }}
        />
      </section>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  termsSection: get(state, 'loyaltyConfig.americanExpress.termsSection', ''),
});

export default connect(mapStateToProps)(TermsAndConditions);
