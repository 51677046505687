import React from 'react';
import { defaultInputMaxLength } from '../../../constants';

export default ({
  type = 'text',
  name,
  value,
  error,
  placeholder = '',
  onChange,
  maxLength = defaultInputMaxLength,
}) => (
  <div className={`input-wrapper ${error ? 'not-valid' : ''}`}>
    <input
      type={type}
      name={name}
      value={value}
      onChange={e => onChange(e.target.value)}
      placeholder={placeholder}
      maxLength={maxLength}
    />
    <p className="validation-error">{error}</p>
  </div>
);
