/* eslint-disable no-lone-blocks */
export default function buildArrayOfCategoriesByColumnBreakFlg(categories) {
  return categories
    .reduce(
      (columns, category) => {
        if (category.attributes && category.attributes.flgColumnBreak) {
          // Create a new column for this category
          {
            columns.length < 5 ? columns.push([]) : undefined;
          }
        }

        // Add category to last column
        {
          columns.length < 5
            ? columns[columns.length - 1].push(category)
            : undefined;
        }

        return columns;
      },
      [[]]
    )
    .filter(col => col.length > 0);
}
