import React from 'react';
import { renderFormattedPriceWithDecimals } from 'pdp/utilities/utilities-price';
import Toggle from 'shared/components/Toggle/toggle';
import { REQUIRED_MONOGRAM } from 'pdp/constants';
import get from 'lodash/get';

const buildMessages = (parentheticalCharge, isFinalSaleExperience) => {
  let shippingMessage = 'Free Shipping';
  let returnMessage = 'Return Fees Apply';
  if (isFinalSaleExperience) {
    returnMessage = 'Final Sale';
  }

  if (parentheticalCharge > 0) {
    shippingMessage = `Shipping: ${renderFormattedPriceWithDecimals(
      parentheticalCharge,
    )}`;
  }

  return { shippingMessage, returnMessage };
};

const isRequiredMonogram = customization => {
  const behaviorCode = get(customization, 'behaviorCode', '');
  return behaviorCode === REQUIRED_MONOGRAM;
};

const buildMessagesSuppressReturnsMessage = parentheticalCharge => {
  let shippingMessage = 'Free Shipping';
  if (parentheticalCharge > 0) {
    shippingMessage = `Shipping: ${renderFormattedPriceWithDecimals(
      parentheticalCharge,
    )}`;
  }
  return (
    <div className="product-heading__clearance-message">
      <div className="shipping-label">{shippingMessage}</div>
    </div>
  );
};

const ClearanceMessages = (parentheticalCharge, isFinalSaleExperience) => {
  const { shippingMessage, returnMessage } = buildMessages(
    parentheticalCharge,
    isFinalSaleExperience,
  );
  const textInSameLine = (
    <div className="product-heading__clearance-message">
      <span className="shipping-label">{shippingMessage}</span>
      <span> | </span>
      <span className="returns-label">{returnMessage}</span>
    </div>
  );
  const textInDifferentLine = (
    <div className="product-heading__clearance-message">
      <div className="shipping-label">{shippingMessage}</div>
      <div className="returns-label">{returnMessage}</div>
    </div>
  );
  return parentheticalCharge > 0 ? textInDifferentLine : textInSameLine;
};

const FinalSaleMessage = (parentheticalCharge, isFinalSaleExperience) => {
  return ClearanceMessages(parentheticalCharge, isFinalSaleExperience);
};

const ClearanceShippingMessages = (parentheticalCharge, customization) => {
  return isRequiredMonogram(customization) ? (
    <Toggle
      feature="SUPPRESS_FSFR_PDP_MONOGRAM"
      fallback={ClearanceMessages(parentheticalCharge)}
    >
      {buildMessagesSuppressReturnsMessage(parentheticalCharge)}
    </Toggle>
  ) : (
    ClearanceMessages(parentheticalCharge)
  );
};

const ClearanceText = ({
  parentheticalCharge,
  customization,
  isFinalSaleExperience,
}) => {
  return isFinalSaleExperience
    ? FinalSaleMessage(parentheticalCharge, isFinalSaleExperience)
    : ClearanceShippingMessages(parentheticalCharge, customization);
};

export default ClearanceText;
