import reactCookie from 'react-cookie';

export function getActiveAbTest(abTestName = {}, abTestIds = {}) {
  const abTest = abTestIds[abTestName];
  if (abTest) {
    for (const key of Object.keys(abTest)) {
      if (abTest[key].active) {
        return ({
          abTestName,
          abTestId: key,
          abTestCookieName: abTest[key].cookieName,
        });
      }
    }
  }
  return null;
}

export function getActiveAbTests(abTestIds = {}) {
  const activeAbTests = [];
  for (const name of Object.keys(abTestIds)) {
    const abTest = getActiveAbTest(name, abTestIds);
    if (abTest) activeAbTests.push(abTest);
  }
  return activeAbTests;
}

export function parseAbTestValue(abTestIds, responseData) {
  for (let i = 0; i < abTestIds.length; i++) {
    let experience = null;
    let value = null;
    for (let j = 0; j < responseData.length; j++) {
      if (!value) {
        if (responseData[j][abTestIds[i].abTestName]) {
          const testIdObj = responseData[j][abTestIds[i].abTestName][abTestIds[i].abTestId];
          for (const key of Object.keys(testIdObj)) {
            experience = key;
            value = testIdObj[key].expType;
          }
        }
      }
    }
    if (value) {
      abTestIds[i].abTestExperience = experience;
      abTestIds[i].abTestCookieValue = value;
    }
  }
}

export function createCookiesFromAbTests(abTests) {
  const nowPlus30Days = new Date(Date.now() + (30 * 24 * 60 * 60 * 1000));
  for (const name of Object.keys(abTests)) {
    reactCookie.save(abTests[name].abTestCookieName, abTests[name].abTestCookieValue, { path: '/', expires: nowPlus30Days });
  }
}
