import React from 'react';
import { bool, object, string } from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import merge from 'lodash/merge';
import ContentItem from '../contentItem/contentItem';
import { isNewHomePage } from '../../../utilities/is-new-home-page';
import LayoutFrame from './layoutFrame';
import L1Layout from './l1Layout';
import L2Layout from './l2Layout';
import L3Layout from './l3Layout';
import L5Layout from './l5Layout';
import { getTags } from '../utils';
import { defaultTrackingTags } from '../../constants/constants';
import './layout.scss';

/**
 * @function Layout
 * @param {Object} props.cmsLayout
 * @param {Boolean} props.isMobilePhone
 * @param {Boolean} props.isTablet
 * @param {Boolean} props.isDesktop
 * @param {Boolean} props.isNewHomePage
 * @param {Object} props.parentTrackingTags
 * @param {String} props.placement
 * @param {Boolean} props.sessionExpired
 * @param {Boolean} props.validPromo
 * @param {Boolean} props.isSpacingToggle
 * @returns {React.ReactElement}
 */
const Layout = ({
  cmsLayout = {},
  isMobilePhone,
  isTablet,
  isDesktop,
  isNewHomePage,
  parentTrackingTags,
  placement,
  sessionExpired,
  validPromo,
  isCmsL1FullBleedToggle,
  isSpacingToggle,
  isSRGBOptionEnabled,
  isImageToggle,
  isLazyBgImageToggle,
  isMainEditLandscapeToggle,
  loggedInCustomerEmail,
  isHomePage,
}) => {
  const cmsContentFields = get(cmsLayout, 'fields', {});

  // build cascading tracking tags
  const trackingTags = get(cmsContentFields, 'trackingTags', []);
  const currentTags = getTags(trackingTags);
  const mergedTrackingTags = merge({}, parentTrackingTags, currentTags);

  const isPromoItem =
    get(cmsLayout, 'fields.tags.fields.custom') === 'LoggedInPromo';
  const promoEligible = validPromo && !sessionExpired;

  if (isPromoItem && !promoEligible) return <div />;

  const subLayoutProps = {
    cmsLayout,
    cmsContentFields,
    isMobilePhone,
    isTablet,
    isDesktop,
    isNewHomePage,
    parentTrackingTags: mergedTrackingTags,
    placement,
    sessionExpired,
    validPromo,
    isCmsL1FullBleedToggle,
    isSpacingToggle,
    isSRGBOptionEnabled,
    isImageToggle,
    isLazyBgImageToggle,
    isMainEditLandscapeToggle,
    loggedInCustomerEmail,
    isHomePage,
  };

  switch (cmsLayout.contentType) {
    case 'frame': {
      return <LayoutFrame {...subLayoutProps} />;
    }
    case 'l1Layout': {
      return <L1Layout {...subLayoutProps} />;
    }
    case 'l2Layout': {
      return <L2Layout {...subLayoutProps} />;
    }
    case 'l3Layout': {
      return <L3Layout {...subLayoutProps} />;
    }
    case 'l4Layout': {
      return <L3Layout {...subLayoutProps} />;
    }
    case 'l5Layout': {
      return <L5Layout {...subLayoutProps} />;
    }

    case 'components': {
      return (
        <div className="component">
          <ContentItem
            cmsContentItem={cmsContentFields}
            isMobilePhone
            key={cmsContentFields.sys.id}
            parentTrackingTags={mergedTrackingTags}
            sessionExpired={sessionExpired}
            validPromo={validPromo}
          />
        </div>
      );
    }
    default: {
      return <div />;
    }
  }
};

Layout.defaultProps = {
  parentTrackingTags: defaultTrackingTags,
};

Layout.propTypes = {
  cmsLayout: object,
  isMobilePhone: bool,
  isTablet: bool,
  isDesktop: bool,
  isNewHomePage: bool,
  parentTrackingTags: object,
  placement: string,
  sessionExpired: bool,
  validPromo: bool,
  isCmsL1FullBleedToggle: bool,
  isSpacingToggle: bool,
  isSRGBOptionEnabled: bool,
  isImageToggle: bool,
  isLazyBgImageToggle: bool,
  isMainEditLandscapeToggle: bool,
  isHomePage: string,
};

const mapStateToProps = state => ({
  isMobilePhone: get(state, 'device.isMobilePhone', false),
  isTablet: get(state, 'device.isTablet', false),
  isDesktop: get(state, 'device.isDesktop', false),
  isSRGBOptionEnabled: get(state, 'toggles.SRGB_IMAGE_OPTION', false),
  validPromo: get(
    state,
    'navigation.segmentSailo.eliteCustomer.validPromo',
    false,
  ),
  sessionExpired: get(state, 'user.sessionExpired', true),
  isNewHomePage: isNewHomePage(state),
  isCmsL1FullBleedToggle: state.toggles.CMS_L1_FULL_BLEED,
  isSpacingToggle: state.toggles.EDT_SPACE_CONTROLS,
  isImageToggle: get(state, 'toggles.LSS_EDT_BG_IMAGE', false),
  isLazyBgImageToggle: get(state, 'toggles.LAZYLOAD_HOME_BG_IMAGE', false),
  isMainEditLandscapeToggle: get(state, 'toggles.MAIN_EDIT_LANDSCAPE', false),
  loggedInCustomerEmail: get(state, 'utag.userSession.customer_email', false),
  isHomePage: get(state, 'page.location.pathname', false),
});

export default connect(mapStateToProps)(Layout);
