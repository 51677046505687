import React from 'react';
import favIconActive from 'assets/images/favorite-active.svg';

const DisplayMyFavorites = ({ favMessageClass, favIconClass }) => (
  <a href="/myfavorites/myFavoriteItems.jsp?view=favItems&forOmniLN=1&favin=1" className={favMessageClass}>
      My Favorites
      <img alt="my favorite icon" src={favIconActive} className={favIconClass} />
  </a>
);

export default DisplayMyFavorites;
