import get from 'lodash/get';
import flatten from 'lodash/flatten';
import findIndex from 'lodash/findIndex';
import curry from 'lodash/curry';
import find from 'lodash/find';

const getBuckleFinishChoices = productState => {
  const customizationOptions = [];
  productState.customization &&
    productState.customization.customizationOptions &&
    productState.customization.customizationOptions.forEach(element => {
      element && element.choices && customizationOptions.push(element.choices);
    });
  return flatten(customizationOptions);
};

export function getBuckleFinishDisplayName(state) {
  const selectedBuckleFinishIndex = get(
    state,
    'productCatalog.product.options.selectedBuckleFinishIndex',
    '',
  );
  const product = get(state, 'productCatalog.product', '');
  const customizationChoices = product ? getBuckleFinishChoices(product) : [];
  return get(
    find(
      customizationChoices,
      (choice, index) => index === selectedBuckleFinishIndex,
      {},
    ),
    'displayName',
    '',
  );
}

export default productState => {
  // if state has a selectedColorIndex, then use it
  const chosenIndex = get(productState, 'options.selectedBuckleFinishIndex');
  if (chosenIndex !== undefined) {
    return chosenIndex;
  }

  const arity = 2;
  const findBuckleFinishIndex = curry(findIndex, arity)(
    getBuckleFinishChoices(productState),
  );

  const selectedBuckleFinishIndex = findBuckleFinishIndex(
    ({ defaultBuckleFinish }) => defaultBuckleFinish,
  );

  return selectedBuckleFinishIndex;
};
