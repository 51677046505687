import window from 'window-or-global';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { RESOLVED_QUICK_LOOK_PRODUCT } from 'plp/ProductListPage/actions';
import { loopSkus } from '../common/components/Granify/granify';

export default store => next => action => {
  if (
    action.type === RESOLVED_QUICK_LOOK_PRODUCT &&
    !isEmpty(action.payload) &&
    !action.payload.isGroup
  ) {
    const isGranifyExp = store.getState().toggles.GRANIFY;
    const isInternational = store.getState().locale.countryCode === 'US';
    if (
      isGranifyExp &&
      isInternational &&
      process.browser &&
      window &&
      window.Granify
    ) {
      const stateData = store.getState();
      const breadcrumbs = get(stateData, 'navigation.breadcrumbs');
      const categoryId = get(
        stateData.navigation,
        `breadcrumbs[${breadcrumbs.length - 1}].id`,
        ''
      );
      const onlyXLeftThresholds = stateData.onlyXLeftThresholds;
      const onlyXLeftThreshold = get(
        onlyXLeftThresholds,
        categoryId,
        onlyXLeftThresholds['defaultThreshold']
      );
      const productData = action.payload;

      const adornmentPrice = get(productData, 'price.retailPrice', 0);
      const price = get(productData, 'price.promotionalPrice', adornmentPrice);
      const originalPrice = get(
        productData,
        'price.adornments[0].price',
        adornmentPrice
      );
      const color = get(productData, 'options.productOptions[1].values[0]', {});
      const colorValue = get(color, 'name', '');
      const doesCatlogCodeStartsWithOC = get(
        productData,
        'metadata.cmosCatalogId',
        ''
      ).startsWith('OC');
      const isparentheticalChargeGreaterThanZero =
        Number(get(productData, 'details.parentheticalCharge', '')) > 0;
      const isPerishableProduct = get(productData, 'perishable', false);
      const isMonogramProduct = get(productData, 'isCustomizable', false);
      const isEveningWear = get(productData, 'productFlags.isEvening', false);
      const isFreeReturnsEligible =
        !doesCatlogCodeStartsWithOC &&
        !isparentheticalChargeGreaterThanZero &&
        !isPerishableProduct &&
        !isMonogramProduct &&
        !isEveningWear;
      const initialElement = `[{
        "id": "${get(productData, 'id')}",
        "title": "${get(productData, 'name')}",
        "brand": "${get(productData, 'designer.name')}",
        "price": ${price},
        "regular_price": ${originalPrice},
        "image": "${get(productData, 'media.main.medium.url')}",
        "color": "${colorValue || ''}",
        "discount_eligible": ${!!(
          !isEmpty(get(productData, 'price.adornments')) ||
          !isEmpty(get(productData, 'promotions'))
        )},
        "excluded_category": ${get(productData, 'details.clearanceItem')},
        "parentheticalCharge": ${get(
          productData,
          'details.parentheticalCharge'
        )},
        "free_returns_eligible": ${isFreeReturnsEligible},
        "nmexclusive": ${get(productData, 'productFlags.isOnlyAtNM')}
      },`;
      const loopElements = loopSkus(
        productData,
        price,
        originalPrice,
        onlyXLeftThreshold,
        isFreeReturnsEligible
      );
      const trackProductData = `${initialElement + loopElements}]`;
      window.Granify('trackProduct', JSON.parse(trackProductData));
      window.Granify('setCurrentProduct', get(productData, 'id'));
    }
  }
  return next(action);
};
