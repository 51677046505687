import React from 'react';
import App from 'bgo-common/client/common/components/App/app-v1';
import Loadable from 'react-loadable';
import { Route, IndexRoute } from 'react-router';
import { getParams } from 'bgo-common/client/utilities/utilities-router';

const AppHomeWrapper = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'app-home' */ 'bgo-common/client/common/components/App/app-home-wrapper'
    ),
  loading: () => false,
});

const AppBgIncircleConciergeWrapper = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'app-home' */ '../client/components/App/app-bg-incircle-concierge-wrapper'
    ),
  loading: () => false,
});

const AppBg360Wrapper = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'app-home' */ '../client/components/App/app-bg360-wrapper'
    ),
  loading: () => false,
});

const AppBGxHamptonsWrapper = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'app-home' */ '../client/components/App/app-bgxhamptons-wrapper'
    ),
  loading: () => false,
});

const AppLoginWrapper = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'app-home' */ '../client/components/App/app-login-wrapper'
    ),
  loading: () => false,
});

const AppRegisterWrapper = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'app-home' */ '../client/components/App/app-register-wrapper'
    ),
  loading: () => false,
});

const AppResetPasswordWrapper = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'app-home' */ '../client/components/App/app-reset-password-wrapper'
    ),
  loading: () => false,
});

const AppMyOrdersWrapper = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'app-home' */ '../client/components/App/myaccount/app-my-orders-wrapper'
    ),
  loading: () => false,
});

const AppAccountWrapper = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'app-home' */ '../client/components/App/myaccount/app-my-account-no-content-wrapper'
    ),
  loading: () => false,
});

const AppAccountDetailsWrapper = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'app-home' */ '../client/components/App/myaccount/app-account-details-wrapper'
    ),
  loading: () => false,
});

const AppPaymentDetailsWrapper = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'app-home' */ '../client/components/App/myaccount/app-payment-details-wrapper'
    ),
  loading: () => false,
});

const AppAddressBookWrapper = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'app-home' */ '../client/components/App/myaccount/app-address-book-wrapper'
    ),
  loading: () => false,
});

const AppBgCreditCardWrapper = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'app-home' */ '../client/components/App/myaccount/app-bg-credit-card-wrapper'
    ),
  loading: () => false,
});

const AppBgPublicLoyaltyWrapper = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'app-home' */ '../client/components/App/app-bg-public-loyalty-wrapper'
    ),
  loading: () => false,
});

const IncircleTermsAndConditions = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'app-home' */ '../client/components/App/myaccount/app-loyalty-terms-wrapper'
    ),
  loading: () => false,
});

const AppWishlistWrapper = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'app-home' */ '../client/components/App/myaccount/app-wishlist-wrapper'
    ),
  loading: () => false,
});

const AppMyRewardsWrapper = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'app-home' */ '../client/components/App/myaccount/app-my-rewards-wrapper'
    ),
  loading: () => false,
});

const AppCommunicationPref = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'app-home' */ '../client/components/App/myaccount/app-my-communication-pref-wrapper'
    ),
  loading: () => false,
});

const AmericanExpressUpdate = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'app-home' */ '../client/components/App/app-american-express-update-wrapper'
    ),
  loading: () => false,
});

const AppNotFoundWrapper = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'app-notfound' */ 'bgo-common/client/common/components/App/app-notFound-wrapper'
    ),
  loading: () => false,
});

const cartWrapper = Loadable({
  loader: () =>
    import('bgo-common/client/common/components/App/app-cart-wrapper'),
  loading: () => false,
});

const checkoutWrapper = Loadable({
  loader: () =>
    import('bgo-common/client/common/components/App/app-checkout-wrapper'),
  loading: () => false,
});

const orderConfirmationInternational = Loadable({
  loader: () =>
    import('bgo-common/client/common/components/App/app-orderConfirmation'),
  loading: () => false,
});

const SiteMap = Loadable({
  loader: () => import('bgo-common/components/SiteMap/components/sitemap'),
  loading: () => false,
});

const SpecialOffer = Loadable({
  loader: () =>
    import('bgo-common/components/SpecialOffer/components/specialOffer'),
  loading: () => false,
});

const Unsubscribe = Loadable({
  loader: () =>
    import('../client/components/App/myaccount/app-unsubscibe-email-wrapper'),
  loading: () => false,
});

const ThematicPageWrapper = Loadable({
  loader: () =>
    import('../client/components/thematic/components/app-th-wrapper'),
  loading: () => false,
});

const AppCommunicationPrefChangeEmail = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'app-home' */ '../client/components/App/myaccount/app-email-communication-update-wrapper'
    ),
  loading: () => false,
});

/* istanbul ignore next */
function scrollToTop() {
  const scrollToTop = getParams(window.location).scroll || 'true';
  if (scrollToTop === 'true') window.scrollTo(0, 0);
}

const routes = () => (
  <Route
    path="/(en-:countryCode)"
    component={App}
    onChange={scrollToTop}
    deferScripts
  >
    <IndexRoute component={AppHomeWrapper} deferScripts />
    <Route
      path="/e/bgincircle-concierge"
      component={AppBgIncircleConciergeWrapper}
      deferScripts
    />
    <Route path="/e/bg360" component={AppBg360Wrapper} deferScripts />
    <Route
      path="/e/bgxhamptons"
      component={AppBGxHamptonsWrapper}
      deferScripts
    />
    <Route path="/e/login" component={AppLoginWrapper} deferScripts />
    <Route path="/e/register" component={AppRegisterWrapper} deferScripts />
    <Route
      path="/e/reset-password"
      component={AppResetPasswordWrapper}
      deferScripts
    />
    <Route
      path="/e/american-express-update"
      component={AmericanExpressUpdate}
    />
    <Route
      path="/e/myaccount/myorders"
      component={AppMyOrdersWrapper}
      deferScripts
    />
    <Route path="/e/myaccount" component={AppAccountWrapper} deferScripts />
    <Route
      path="/e/myaccount/accountdetails"
      component={AppAccountDetailsWrapper}
      deferScripts
    />
    <Route
      path="/e/myaccount/paymentdetails"
      component={AppPaymentDetailsWrapper}
      deferScripts
    />
    <Route
      path="/e/myaccount/addressbook"
      component={AppAddressBookWrapper}
      deferScripts
    />
    <Route
      path="/e/myaccount/bgcreditcard"
      component={AppBgCreditCardWrapper}
      deferScripts
    />
    <Route
      path="/e/bgcreditcard"
      component={AppBgPublicLoyaltyWrapper}
      deferScripts
    />
    <Route
      path="/e/myaccount/wishlist"
      component={AppWishlistWrapper}
      deferScripts
    />
    <Route
      path="/e/loyalty-terms"
      component={IncircleTermsAndConditions}
      deferScripts
    />
    <Route
      path="/e/myaccount/myrewards"
      component={AppMyRewardsWrapper}
      deferScripts
    />
    <Route
      path="/e/my/communication/preferences"
      component={AppCommunicationPref}
      deferScripts
    />
    <Route path="international/checkout" component={checkoutWrapper} />
    <Route path="international/cart" component={cartWrapper} />
    <Route
      path="international/order-confirmation"
      component={orderConfirmationInternational}
    />
    <Route path="service/sitemap" component={SiteMap} />
    <Route path="/services/SpecialOffer" component={SpecialOffer} />
    <Route
      path="/communication-preferences/unsubscribe"
      component={Unsubscribe}
    />
    <Route path="(*/):thPageParam-th.html" component={ThematicPageWrapper} />
    <Route
      path="/communication-preferences"
      component={AppCommunicationPrefChangeEmail}
    />
    <Route status={404} path="*" component={AppNotFoundWrapper} />
  </Route>
);

export default routes;
