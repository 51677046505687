import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { closeModal } from 'shared/components/Modal/actions';
import { sendVerificationCode } from '../../actions';
import SubmitButton from '../../../../../common/Forms/FormButtons/SubmitButton/SubmitButton';
import CancelButton from '../../../../../common/Forms/FormButtons/CancelButton/CancelButton';
import './TotpConfirmation.scss';
import { genericError } from '../../../../constants';

export const DumbTotpConfirmation = props => {
  return (
    <div className="totp-confirmation">
      <div className="totp-confirmation__description">{props.children}</div>
      {props.error ? (
        <p className="totp-confirmation__error">{genericError}</p>
      ) : null}
      <SubmitButton
        onClick={() => props.sendVerificationCode()}
        isSubmitting={props.isLoading}
        type="submit"
        caption="Continue"
        additionalClasses="totp-confirmation__submit-btn"
      />
      <CancelButton
        caption="Cancel"
        onClick={props.closeModal}
        disabled={props.isLoading}
        additionalClasses="totp-confirmation__cancel-btn"
      />
    </div>
  );
};

DumbTotpConfirmation.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  sendVerificationCode: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

const mapStateToProps = state => ({
  isLoading: get(state, 'api.send_verification_code.loading', false),
  error: get(state, 'myaccount.myAccountDetails.verificationCode.error', false),
});
const mapDispatchToProps = {
  closeModal,
  sendVerificationCode,
};

const TotpConfirmation = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbTotpConfirmation);

export default TotpConfirmation;
