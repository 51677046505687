import React from 'react';
import EcmRow from '../EcmRow';

const EcmComplex = ({
  ecmCallToActionButtonWithTextToggle,
  ecmContent,
  ecmSlot,
  improvePerformanceOfStyleToggle,
  removeEcmVideoHeightToggle,
  // isMobile,
  // isMobilePhone,
}) => {
  const rows = ecmContent.rows
    ? JSON.parse(JSON.stringify(ecmContent.rows))
    : Object.assign(ecmContent.rows);

  // Refer to JIRA - BGO-12498 for details
  // if (isMobilePhone) {
  //   rows.map((obj)=>{
  //     if(obj.columns.length && obj.columns[0].componentType==="text"){
  //       obj.columns[0].properties.text=obj.columns[0].properties.mobileText;
  //       obj.columns[0].properties.tabletText=obj.columns[0].properties.mobileText;
  //     }
  //   });
  //   const index = rows.findIndex(obj => {
  //     return obj.columns[0].experience === 'bubble';
  //   });
  //   if (index > -1) {
  //     const temp = rows[index];
  //     rows.splice(index, 1);
  //     rows.unshift(temp);
  //   }
  // }

  return (
    <div className="ecm-container__complex grid-100 mobile-grid-100 tablet-grid-100">
      {rows &&
        rows.map((row, index) => (
          <EcmRow
            ecmCallToActionButtonWithTextToggle={
              ecmCallToActionButtonWithTextToggle
            }
            ecmContent={row}
            ecmSlot={ecmSlot}
            key={index}
            improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
            removeEcmVideoHeightToggle={removeEcmVideoHeightToggle}
          />
        ))}
    </div>
  );
};

export default EcmComplex;
