import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import {
  getEmail,
  updateEmail,
} from '@bgo-ui/common/client/utilities/utilsCommPref';
import { validateEmail } from '@bgo-ui/common/client/utilities/utilities-amplify';
import { getQueryParam } from '@bgo-ui/common/client/utilities/utilities-url';
import FormikTextField from '../common/Forms/FormikTextField/FormikTextField';
import SubmitButton from '../common/Forms/FormButtons/SubmitButton/SubmitButton';
import FormikCardField from '../common/Forms/FormikCardField/FormikCardField';
import { validationErrors } from '../login/constants';
import './CommunicationPref.scss';

function CommunicationPref() {
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const hasDomLoaded = typeof window !== 'undefined';
  const initialFormValue = {
    email,
    newEmail: '',
  };
  useEffect(() => {
    if (hasDomLoaded) {
      getEmail(setEmail, setLoading, initialFormValue); // Using the imported function
    }
  }, [hasDomLoaded]);
  const validateForm = values => {
    const errors = {};
    if (values.email === values.newEmail) {
      errors.newEmail = validationErrors.sameEmailID;
    }
    if (!validateEmail(values.newEmail)) {
      errors.newEmail = validationErrors.invalidEmail;
    }
    return errors;
  };

  const submitForm = async values => {
    const ck = getQueryParam('CustomerKey');
    const payload = {
      ck,
      newEmail: values.newEmail,
      email: values.email,
    };
    updateEmail(payload);
  };

  const unsubscribe = async () => {
    const customerKey = getQueryParam('CustomerKey');
    const newURL = `/communication-preferences/unsubscribe?CustomerKey=${customerKey}`;
    window.location.href = newURL;
  };

  return (
    <>
      {!loading && (
        <div className="communication-form">
          <div className="header">MANAGE EMAIL PREFERENCES</div>
          <div className="sub-header">Change your email address</div>
          <Formik
            initialValues={initialFormValue}
            validate={validateForm}
            onSubmit={submitForm}
          >
            {() => (
              <Form autoComplete="off">
                <FormikCardField
                  name="email"
                  id="current-email-address"
                  placeholder="Current Email Address"
                  fontSize
                />
                <FormikTextField
                  name="newEmail"
                  id="newEmail"
                  placeholder="New Email Address"
                  fontSize
                />
                <SubmitButton
                  className="form-full-width"
                  caption="UPDATE"
                  type="submit"
                />
              </Form>
            )}
          </Formik>
          <div className="or-seperator">or</div>
          <SubmitButton
            additionalClasses="comm_pref_form__submit-btn"
            caption="UNSUBSCRIBE"
            type="submit"
            onClick={unsubscribe}
          />
          <div className="sub-txt">
            You may still receive personalized emails from your store sales
            associate. if you wish to unsubscribe from their emails, you will
            need to directly contact your sales associate.
          </div>
        </div>
      )}
    </>
  );
}

export default CommunicationPref;
