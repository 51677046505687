import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import './SignInRegisterSwitcher.scss';

const login = 'login';
const register = 'register';
const loginRoute = '/e/login';
const registerRoute = '/e/register';

const baseClass = 'sign-in-register-switcher';
const linkContainerClass = `${baseClass}__link-container`;

const SignInRegisterSwitcher = ({
  currentTab,
  handleTabSwitch,
  isLoyaltyEnabled,
}) => {
  const [queryParam, updateQueryParam] = useState('');
  const [pathname, setPathname] = useState('');
  const isLogin = currentTab === login;
  const isRegister = currentTab === register;

  useEffect(() => {
    const returnToUrl = new URLSearchParams(location.search).get('returnToURL');
    if (returnToUrl) updateQueryParam(`?returnToURL=${returnToUrl}`);
    if (window?.location?.pathname) {
      setPathname(window?.location?.pathname);
    }
  }, []);

  const Tag =
    isLoyaltyEnabled && !['/e/register', '/e/login'].includes(pathname)
      ? 'span'
      : 'a';
  return (
    <div className={baseClass}>
      <div
        className={classNames(
          linkContainerClass,
          isLogin && `${linkContainerClass}--selected`,
        )}
        onClick={() => isLoyaltyEnabled && handleTabSwitch('Signin')}
        aria-hidden="true"
      >
        <Tag
          className={`${baseClass}__link`}
          href={isRegister ? `${loginRoute}${queryParam}` : undefined}
        >
          Sign in
        </Tag>
      </div>
      <div
        className={classNames(
          linkContainerClass,
          isRegister && `${linkContainerClass}--selected`,
        )}
        onClick={() => isLoyaltyEnabled && handleTabSwitch('Register')}
        aria-hidden="true"
      >
        <Tag
          className={`${baseClass}__link`}
          href={isLogin ? `${registerRoute}${queryParam}` : undefined}
        >
          Register
        </Tag>
      </div>
    </div>
  );
};

SignInRegisterSwitcher.propTypes = {
  currentTab: PropTypes.oneOf([login, register]),
};
export default SignInRegisterSwitcher;
