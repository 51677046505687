import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { openPaymentCTAmodal, openPaymentDeletemodal } from '../actions';
import { setAddressId } from '../../AddressBookPage/actions';
import { editButtonText, deleteButtonText } from '../../../constants';
import './PaymentBillingInfo.scss';

const cardMask = '••••';
const openModalThreeDots = '•••';
const PaymentBillingInfo = ({
  payments,
  editDetails,
  setAddressId,
  openPaymentCTAmodal,
  openPaymentDeletemodal,
  myAccountAddress,
  myAccountContent,
}) => {
  const openPDeleteModal = (paymentId, billingAddressId) => {
    if (billingAddressId) {
      setAddressId(billingAddressId);
    }
    openPaymentDeletemodal(paymentId, billingAddressId);
  };

  const openCTAModal = (paymentId, billingAddressId) => {
    if (billingAddressId) {
      setAddressId(billingAddressId);
    }
    openPaymentCTAmodal(paymentId, billingAddressId, editDetails);
  };

  const renderLogo = cardType => {
    return (
      <div
        className={`payment-info__card-info__logo-wrapper ${
          cardType ? cardType.toLowerCase().replace(/'|\s/g, '') : ''
        }`}
      />
    );
  };

  const renderAddress = (addressId, myAccountAddress) => {
    const addressData = myAccountAddress.filter(
      addr => addr.addressId === addressId,
    )[0];
    const address = addressData || {};
    const countryListJSON =
      myAccountContent['MYACCOUNT-COUNTRYLIST']?.content[0]?.content[0]?.value;
    const countryList = countryListJSON ? JSON.parse(countryListJSON) : [];

    const stateKey = `MYACCOUNT-STATELIST-${address.country}`;
    const stateListJSON =
      myAccountContent[stateKey]?.content[0]?.content[0]?.value;
    const stateList = stateListJSON ? JSON.parse(stateListJSON) : [];

    const countryName =
      countryList.find(c => c.value === address.country)?.name ||
      address.country;
    const stateName =
      stateList.find(s => s.value === address.state)?.name || address.state;

    return (
      <div className="payment-info__card-info__address">
        {(address.firstName || address.lastName) && (
          <p>
            {address.firstName && address.firstName}{' '}
            {address.lastName && address.lastName}
          </p>
        )}
        {address.address1 && <p>{address.address1}</p>}
        {address.address2 && <p>{address.address2}</p>}
        {address.country &&
          (address.country === 'US' ? (
            <p>
              {address.city && `${address.city}${address.state && ', '}`}
              {stateName}
            </p>
          ) : (
            <p>
              {address.city &&
                `${address.city}${(address.province || address.country) &&
                  ', '}`}
              {address.province &&
                `${address.province}${address.country && ', '}`}
              {countryName}
            </p>
          ))}
        {address.postalCode && <p>{address.postalCode}</p>}
        {address.phoneNumber && <p>{address.phoneNumber}</p>}
      </div>
    );
  };

  return (
    <div className="payment-info-container">
      {payments.map(payment => (
        <div key={payment.paymentId} className="payment-info">
          <div className="payment-info__logo">
            {renderLogo(payment.cardType)}
          </div>
          <div className="payment-info__card-info" aria-live="polite">
            <p>
              <strong>
                {payment.cardType} ( {cardMask}{' '}
                {payment.maskedCardNumber &&
                  payment.maskedCardNumber.replace(/X/gi, '')}
                )
              </strong>
            </p>
            <p className="payment-info__exp-info">
              Expires {payment.expMonth}/{payment.expYear}
            </p>
            {!isEmpty(myAccountAddress) &&
              renderAddress(payment.billingAddressId, myAccountAddress)}
          </div>
          <div className="payment-info__cta-buttons">
            <div className="payment-info__cta-buttons__desktop">
              <button
                type="button"
                onClick={() =>
                  editDetails(true, payment.paymentId, payment.billingAddressId)}
              >
                {editButtonText}
              </button>
              <button
                type="button"
                onClick={() =>
                  openPDeleteModal(payment.paymentId, payment.billingAddressId)}
              >
                {deleteButtonText}
              </button>
            </div>
            <div className="payment-info__cta-buttons__mobile">
              <span
                onClick={() =>
                  openCTAModal(payment.paymentId, payment.billingAddressId)}
              >
                {openModalThreeDots}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = state => ({
  myAccountAddress: get(state, 'myaccount.myAccountAddress', false),
  myAccountContent: get(state, 'myaccount.myAccountContent', {}),
});

const mapDispatchToProps = {
  openPaymentCTAmodal,
  openPaymentDeletemodal,
  setAddressId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentBillingInfo);
