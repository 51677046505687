import React from 'react';
import IdleTimer from 'react-idle-timer';
import {
  getKmsiStatus,
  isKmsiSet,
} from '../../client/utilities/utilities-kmsi';
import { INACTIVITY_TIMEOUT } from '../../client/common/constants';
import { signOutAndDeleteJSessionIdCookie } from '../../client/utilities/utilities-amplify';
import Toggle from '../../client/common/components/Toggle/toggle';

export default class IdleTimerWrapper extends React.Component {
  onIdle = () => {
    const kmsiValueSet = isKmsiSet();
    const kmsiEnabled = getKmsiStatus();
    if (kmsiValueSet && !kmsiEnabled) {
      signOutAndDeleteJSessionIdCookie();
    }
  };

  render() {
    if (IS_CLIENT) {
      return (
        <Toggle feature="DT_SIGN_IN">
          <IdleTimer
            ref={ref => {
              this.idleTimer = ref;
            }}
            onIdle={this.onIdle}
            timeout={INACTIVITY_TIMEOUT}
          />
        </Toggle>
      );
    }
    return null;
  }
}
