import { RESOLVED_PERSONALIZED_PROMOTIONS } from 'client/common/components/WkyWidgetFallback/actions';

export default (state = {}, action) => {
  switch (action.type) {
    case RESOLVED_PERSONALIZED_PROMOTIONS:
      return {
        page_definition_id: 'account',
        page_name: 'We Know You',
        page_template: 'Profile',
        page_type: 'account',
      };
    default:
      return state;
  }
};
