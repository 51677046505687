import {
  RESOLVED_SITEMAP_DATA,
  REJECTED_SITEMAP_DATA,
  REJECTED_TOGGLE,
} from './actions';

const defaultState = {
  siteMapData: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESOLVED_SITEMAP_DATA: {
      return {
        ...state,
        siteMapData: action.payload,
      };
    }
    case REJECTED_TOGGLE: {
      return {
        ...state,
        redirectURL: `${NMConfig.ERROR_PAGE_URL}`,
      };
    }
    case REJECTED_SITEMAP_DATA: {
      return {
        ...state,
        siteMapData: {},
      };
    }
    default: {
      return state;
    }
  }
};
