import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { renderFormattedPrice } from 'client-utils/utilities-price';
import { renderFormattedPriceWithDecimals } from 'pdp/utilities/utilities-price';
import PriceAdornment from './components/PriceAdornment/priceAdornment';
import './salePrice.scss';

function displayPriceAdornments(
  adornments,
  currencyCode,
  promotionalAdornmentColor,
  showPriceInDecimals,
  rrPromoInfoAvailable,
) {
  return adornments.map(({ label, price }, key) => {
    const currentPrice = key === adornments.length - 1 && !rrPromoInfoAvailable;
    let color;

    if (currentPrice && promotionalAdornmentColor) {
      color = `#${promotionalAdornmentColor}`;
    }

    return (
      <span key={key}>
        <PriceAdornment
          currentPrice={currentPrice}
          label={label}
          color={color}
          price={price}
          currencyCode={currencyCode}
          showPriceInDecimals={showPriceInDecimals}
        />
        {' ' /* Allow line to wrap */}
      </span>
    );
  });
}

const SalePrice = ({
  price = {},
  promotionAdornmentColor = '',
  showPriceInDecimals = false,
  rrPromoInfoAvailable = false,
}) => {
  const { currencyCode, retailPrice, adornments } = price;
  if (!retailPrice) {
    return false;
  }

  return (
    <div className="sale-price">
      {isEmpty(adornments) ? (
        <span>
          {showPriceInDecimals
            ? renderFormattedPriceWithDecimals(retailPrice, currencyCode)
            : renderFormattedPrice(retailPrice, currencyCode)}
        </span>
      ) : (
        displayPriceAdornments(
          adornments,
          currencyCode,
          promotionAdornmentColor,
          showPriceInDecimals,
          rrPromoInfoAvailable,
        )
      )}
    </div>
  );
};

export default SalePrice;
