import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import FormDisclaimer from '../FormDisclaimer';
import SubmitButton from '../../../../../../../../common/Forms/FormButtons/SubmitButton/SubmitButton';
import FormikTextField from '../../../../../../../../common/Forms/FormikTextField/FormikTextField';
import FormikPasswordField from '../../../../../../../../common/Forms/FormikPasswordField/FormikPasswordField';
import FormikNumberField from '../../../../../../../../common/Forms/FormikNumberField/FormikNumberField';
import PasswordRequirementsBox from '../../../../../../../../common/Forms/FormikPasswordField/PasswordRequirementsBox/PasswordRequirementsBox';
import { getPasswordRequirements } from '../../../../../../../utilities/validation-utils';
import { validationErrors } from '../../../../../../../../login/constants';
import { signUp } from '../../../../../../../../login/actions';
import { IN_CIRCLE_APPLY_NOW_LINK } from '../../../../constants';
import { signUpContent, commonContent } from '../../constants';
import { editEmail } from '../../actions';

const SignUpModalContent = ({
  email,
  error,
  isLoading,
  isUpdating,
  editEmail,
  signUp,
}) => {
  const initialFormValue = {
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    month: '',
    day: '',
    mostlyInterestedIn: 'womenswear',
  };

  const validateForm = values => {
    const errors = {};

    if (!values.firstName?.trim()) {
      errors.firstName = validationErrors.emptyFirstName;
    }

    if (!values.lastName?.trim()) {
      errors.lastName = validationErrors.emptyLastName;
    }

    if (!values.password) {
      errors.password = validationErrors.emptyPassword;
    } else if (
      getPasswordRequirements(values.password).some(req => !req.checked)
    ) {
      errors.password = validationErrors.badPassword;
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = validationErrors.emptyConfirm;
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = validationErrors.passwordsMatch;
    }

    if (!values.phoneNumber?.trim()) {
      errors.phoneNumber = validationErrors.phoneNumberEmpty;
    } else if (values.phoneNumber.length < 12) {
      errors.phoneNumber = validationErrors.invalidPhoneNumber;
    }

    return errors;
  };

  const handleSubmit = values => {
    const formData = {
      ...values,
      email,
    };

    const payload = {
      ...formData,
      redirectLink: IN_CIRCLE_APPLY_NOW_LINK,
      enableKmsi: true,
    };

    signUp(payload);
  };

  return (
    <div className="sign-up-modal-content">
      <div className="sign-up-modal-content__heading">
        <h2 className="sign-up-modal-content__heading__title">
          {signUpContent.title}
        </h2>
      </div>
      <div className="sign-up-modal-content__sub">
        <div className="sign-up-modal-content__sub__item">
          <div className="sign-up-modal-content__sub__item__cnt">
            <p className="sign-up-modal-content__sub__item__cnt__title">
              {commonContent.email}
            </p>
            <p className="sign-up-modal-content__sub__item__cnt__desc">
              {email}
            </p>
          </div>
          <span
            className="sign-up-modal-content__sub__item__edit"
            onClick={editEmail}
            onKeyDown={editEmail}
          >
            {commonContent.edit}
          </span>
        </div>
      </div>
      <Formik
        initialValues={initialFormValue}
        validate={validateForm}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form autoComplete="off" className="sign-up-modal-content__form">
            <div className="sign-up-modal-content__form__inputs">
              <FormikTextField
                name="firstName"
                id="first-name-field"
                placeholder="First name *"
                maxLength={40}
                fontSize
              />
              <FormikTextField
                name="lastName"
                id="last-name-field"
                placeholder="Last name *"
                maxLength={40}
                fontSize
              />
              <div className="form-field-wrapper">
                <FormikPasswordField
                  name="password"
                  id="password-field"
                  placeholder="Password *"
                  fontSize
                />
                <PasswordRequirementsBox
                  requirements={getPasswordRequirements(values.password)}
                />
              </div>
              <FormikPasswordField
                name="confirmPassword"
                id="confirm-password-field"
                placeholder="Confirm Password *"
                fontSize
              />
              <FormikNumberField
                name="phoneNumber"
                id="phoneNumber"
                placeholder="Phone Number *"
                options={{ blocks: [3, 3, 4], delimiter: '-' }}
                fontSize
              />
              {error && (
                <div className="sign-up-modal-content__form__inputs__error">
                  {error}
                </div>
              )}
            </div>
            <div
              className={classNames(
                'sign-up-modal-content__form__submit-container',
                getPasswordRequirements(values.password).some(
                  req => !req.checked,
                ) && 'sign-up-modal-content__form__submit-container--disabled',
              )}
            >
              <SubmitButton
                isSubmitting={isLoading || isUpdating}
                additionalClasses="sign-up-modal-content__form__submit-container__submit-btn"
                caption="Register"
                type="submit"
              />
            </div>
            <FormDisclaimer />
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = state => ({
  email: state.applyNowModal.email,
  error: get(state, 'login.registration.error', null),
  isLoading: get(state, 'api.registration.loading', false),
  isUpdating: get(state, 'api.update_additional_user_info.loading', false),
});
const mapDispatchToProps = {
  editEmail,
  signUp,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignUpModalContent);
