import React, { Fragment } from 'react';
import classNames from 'classnames';

const IncircleConciergeTitle = ({ content, contentClassName }) => {
  return (
    <Fragment>
      <h2
        className={classNames({
          [contentClassName]: true,
          [`${contentClassName}--general`]: true,
        })}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
      <h2
        className={classNames({
          [contentClassName]: true,
          [`${contentClassName}--xxsm-mobile`]: true,
        })}
        dangerouslySetInnerHTML={{
          __html: content.replace(/<br \/>/g, ' '),
        }}
      />
    </Fragment>
  );
};

export default IncircleConciergeTitle;
