import { X_NMO_TRACE_ID_NAME } from 'server/utilities/logger';
// eslint-disable-next-line import/no-cycle
import { X_ABTEST_INFO, X_DEVICE_TYPE } from 'universal/http-client';

export function extractLogData(
  context,
  config = {},
  request = {},
  response = {},
  startTime = new Date(),
) {
  const whitelist = [
    'method',
    'url',
    'baseUrl',
    'params',
    'status',
    'statusText',
    'statusMessage',
    'JSESSIONID',
    'DYN_USER_ID',
    'TLTSID',
    'DYN_USER_CONFIRM',
    'W2A',
    'Cookie',
  ];
  const extra = { requestOrigin: 'internal' };
  const headers = config.headers || {};

  whitelist.forEach(prop => {
    if (context[prop]) {
      extra[prop] = context[prop];
    }

    if (config[prop]) {
      extra[`request.${prop}`] = config[prop];
    }

    if (config.headers && config.headers[prop]) {
      extra[prop] = config.headers[prop];
    }

    if (request[prop]) {
      extra[`request.${prop}`] = request[prop];
    }

    if (response[prop]) {
      extra[`response.${prop}`] = response[prop];
    }
  });
  if (response['status']) {
    extra['responseTime'] = new Date() - startTime;
  }

  extra[X_NMO_TRACE_ID_NAME] = headers[X_NMO_TRACE_ID_NAME];
  if (config.headers && config.headers[X_ABTEST_INFO]) {
    extra['tnt'] = config.headers[X_ABTEST_INFO];
  }
  if (config.headers && config.headers[X_DEVICE_TYPE]) {
    extra['device'] = config.headers[X_DEVICE_TYPE];
  }
  return extra;
}
