import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  hideSpinner,
  showSpinner,
} from 'shared/components/Spinner/spinner-actions';
import {
  amplifyErrors,
  amplifyLogin,
  configureAmplify,
  entryValidation,
} from 'client-utils/utilities-amplify';
import { setKmsiStatus } from 'client-utils/utilities-kmsi';
import {
  PAGE_ID_LOGIN,
  PAGE_ID_FORGOT_PASSWORD,
  setPageId,
} from '@bgo-ui/common/client/common/actions/actions-page';
import { isNewCartCheckoutEnabled } from 'client-utils/is-new-cart-checkout';
import {
  loginFailureUtagData,
  openForgotPasswordModal,
  resolvedLoginPage,
} from '../actions';
import AccountAccessLayout from './AccountAccessLayout';
import NewAccountAccessLayout from '../v2/login/NewAccountAccessLayout/NewAccountAccessLayout';

export class DumbAccountAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayError: false,
      loading: false,
      errorMessage: '',
    };
    if (
      get(props, 'location.query.forgot-password') !== 'true' ||
      !this.props.newLayout
    ) {
      props.setPageId(PAGE_ID_LOGIN);
    } else if (
      get(props, 'location.query.forgot-password') === 'true' &&
      this.props.newLayout
    ) {
      props.setPageId(PAGE_ID_FORGOT_PASSWORD);
    }
  }

  componentDidMount() {
    configureAmplify(
      this.props.amplifyConfig,
      this.props.isNewCartCheckoutEnabled,
      this.props.disableATGToggle,
    ).then(status => {
      if (status.atgSession) {
        this.props.startLoading();
        this.successCallback();
      }
    });

    const shouldOpenForgotPasswordModal =
      get(this.props, 'location.query.forgot-password') === 'true';

    if (!shouldOpenForgotPasswordModal || !this.props.newLayout) {
      if (this.props.isDomLoadComplete) {
        this.props.resolvedLoginPage();
      } else {
        addEventListener('load', this.handleLoad);
      }
    }
    if (shouldOpenForgotPasswordModal && !this.props.newLayout) {
      this.props.openForgotPasswordModal();
    }
  }

  clearErrorMessage = () => {
    this.setState({
      displayError: false,
      errorMessage: '',
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    const kmsi = e.target.kmsi.checked;

    if ((!email || !password) && !this.props.newLayout) {
      return;
    }
    if (!entryValidation(email, password)) {
      this.props.loginFailureUtagData(!!this.props.newLayout);
      this.setState({
        displayError: true,
        errorMessage: 'The supplied email address or password is incorrect.',
      });
    } else {
      if (this.props.newLayout) {
        this.clearErrorMessage();
      }
      setKmsiStatus(kmsi);
      this.userLogin(email, password);
    }
  };

  handleLoad = () => {
    this.props.resolvedLoginPage(!!this.props.newLayout);
    removeEventListener('load', this.handleLoad);
  };

  errorCallback = error => {
    this.props.loginFailureUtagData(!!this.props.newLayout);
    let errorMessage = 'Something went wrong, Please try again';
    if (
      error === amplifyErrors.NOT_AUTHORIZED_EXCEPTION ||
      error === amplifyErrors.USER_NOT_FOUND_EXCEPTION ||
      error === amplifyErrors.INVALID_PARAMETER_EXCEPTION ||
      error === amplifyErrors.PASSWORD_RESET_REQUIRED_EXCEPTION
    ) {
      errorMessage = 'The supplied email address or password is incorrect.';
    }
    this.props.endLoading();
    this.setState({
      errorMessage,
      displayError: true,
      loading: false,
    });
  };

  successCallback = () => {
    const returnToURL = new URLSearchParams(location.search).get('returnToURL');

    const defaultRedirect = this.props.newLayout
      ? '/e/myaccount/myorders'
      : NMConfig.ACCOUNT_PAGE_URL;

    window.location.href = returnToURL || defaultRedirect;
  };

  userLogin = (email, password) => {
    const {
      startLoading,
      disableATGToggle,
      ucaProfileCookieToggle,
      dtLoginFlow,
    } = this.props;

    startLoading();
    this.setState({
      loading: true,
    });
    window.sessionStorage.setItem('login_btn_clicked', 'true');

    amplifyLogin(
      email,
      password,
      this.errorCallback,
      false,
      this.successCallback,
      disableATGToggle,
      ucaProfileCookieToggle,
      dtLoginFlow,
    );
  };

  render() {
    const { displayError, loading, errorMessage } = this.state;
    const { dtSignUpEnabled, openForgotPasswordModal, newLayout } = this.props;
    const Layout = newLayout ? NewAccountAccessLayout : AccountAccessLayout;
    return (
      <Layout
        onSubmit={this.handleSubmit}
        displayError={displayError}
        loading={loading}
        errorMessage={errorMessage}
        dtSignUpEnabled={dtSignUpEnabled}
        openForgotPasswordModal={openForgotPasswordModal}
        clearErrorMessage={this.clearErrorMessage}
        {...this.props}
      />
    );
  }
}

const mapDispatchToProps = {
  startLoading: showSpinner,
  endLoading: hideSpinner,
  openForgotPasswordModal,
  setPageId,
  resolvedLoginPage,
  loginFailureUtagData,
};

const mapStateToProps = state => ({
  isDomLoadComplete: get(state, 'page.isDomLoadComplete', false),
  dtSignUpEnabled: state.toggles.DT_SIGN_UP,
  isNewCartCheckoutEnabled: isNewCartCheckoutEnabled(state),
  disableATGToggle: state?.toggles?.DISABLE_ATG_CALLS || false,
  ucaProfileCookieToggle: state?.toggles.UCA_PROFILE_COOKIE || false,
  dtLoginFlow: state?.toggles?.DT_LOGIN_FLOW || false,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbAccountAccess);
