import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import classnames from 'classnames';
import Copy from '../../../../common/Copy/Copy';
import ItemList from '../ItemList/ItemList';
import './Wishlist.scss';
import {
  removeWishlist,
  getWishlistProducts,
} from '../../../../../../../../bgo-ui-pdp/src/client/components/wishlist/components/WishlistPage/actions';

const DELEVERY_DATE_PREFIX = 'DELIVERYDATE-';

class Wishlist extends Component {
  renderNoWishList(content) {
    const WISHLISTDEFAULT = get(content, 'WISHLIST-DEFAULT');
    const WISHLISTCTA = get(content, 'WISHLIST-CTA');

    return (
      <React.Fragment>
        {WISHLISTDEFAULT && isEmpty(this.props.myAccountWishlist) && (
          <Copy content={WISHLISTDEFAULT} />
        )}
        {WISHLISTCTA && isEmpty(this.props.myAccountWishlist) && (
          <Copy content={WISHLISTCTA} />
        )}
      </React.Fragment>
    );
  }

  getSkuDetails = wishlistItem => {
    const prodId = wishlistItem.productId;
    const { skuId } = wishlistItem;
    let result = {};
    let sku = {};
    let deliveryDate = '';
    let replenishInterval = '';
    replenishInterval = wishlistItem.replenishInterval;
    if (replenishInterval.indexOf(DELEVERY_DATE_PREFIX) !== -1) {
      deliveryDate = replenishInterval.slice(
        replenishInterval.indexOf(DELEVERY_DATE_PREFIX) +
          DELEVERY_DATE_PREFIX.length,
      );
      replenishInterval = '';
    }
    const prod = find(this.props.wishlistListingData, obj => obj.id === prodId);
    if (prod && prod.skus && prod.skus.length > 0) {
      sku = find(prod.skus, sku => sku.id === skuId);
    }
    result = {
      ...wishlistItem,
      ...prod,
      ...{
        sku,
        deliveryDate,
        replenishInterval,
      },
    };
    return result;
  };

  renderWishList() {
    const { wishlistItems, isCloudinary } = this.props;
    return (
      <div className="wishlist__content">
        {wishlistItems.length > 0 &&
          wishlistItems.length > 0 &&
          wishlistItems.map(wishlistItem => {
            const wishlistData = this.getSkuDetails(wishlistItem);
            if (
              wishlistData.id &&
              wishlistData.sku &&
              !isEmpty(wishlistData.sku)
            ) {
              return (
                <ItemList
                  wishlistData={wishlistData}
                  removeWishlist={async () => {
                    await this.props.removeWishlist(wishlistData.sku.id, true);
                  }}
                  key={wishlistData.sku.id}
                  isCloudinary={isCloudinary}
                />
              );
            } else {
              return <React.Fragment />;
            }
          })}
      </div>
    );
  }

  render() {
    const { content } = this.props;
    const WISHLISTTITLE = get(content, 'WISHLIST-TITLE');
    const WISHLISTDATA = get(content, 'WISHLIST-DYNAMIC');
    return (
      <div
        className={classnames(
          'wishlist',
          isEmpty(this.props.wishlistItems) && 'empty',
        )}
      >
        {WISHLISTTITLE && (
          <div className="my-account-page__main-header desktop-tablet-only">
            <Copy content={WISHLISTTITLE} />
            {!isEmpty(this.props.wishlistItems) && (
              <h1>({this.props.onlyInStockLength})</h1>
            )}
          </div>
        )}
        {!isEmpty(this.props.wishlistItems) && <Copy content={WISHLISTDATA} />}
        {content &&
          isEmpty(this.props.wishlistItems) &&
          this.renderNoWishList(content)}
        {content && !isEmpty(this.props.wishlistItems) && this.renderWishList()}
      </div>
    );
  }
}

const mapDispatchToProps = {
  removeWishlist,
  getWishlistProducts,
};

const mapStateToProps = state => ({
  wishlistListingData: get(
    state,
    'wishlist.wishlistListing.minifiedProductSkuInfo',
    [],
  ),
  wishlistItems: get(state, 'wishlist.wishlist.items', []),
  isCloudinary: state.toggles.PHASE2_PLP_CLOUDINARY,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Wishlist);
