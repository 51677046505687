import React from 'react';
import './miniCartErrorModal.scss';

export default () => (
  <div id="cartlinkspandivgutter" className="error-modal header-drop-down layer m-popUp-minicart">
    <button className="cart-close-x close_btn" title="Close" aria-label="Close" />
    <div className="header-drop-down-content" id="miniCartDisplayDiv">
      <p>To review your items, please visit your shopping bag.</p>
      <a id="shopping-bag-link" href={NMConfig.CHECKOUT_PAGE_URL}> View shopping bag</a>
    </div>
  </div>
);
