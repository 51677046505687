import React from 'react';
import { Element } from 'react-scroll';
import { content } from './constants';
import IncircleConciergeLocation from '../../widgets/IncircleConciergeLocation';
import './index.scss';

const IncircleConciergeOurPartners = () => {
  return (
    <Element
      name={content.id}
      id={content.id}
      className="incircle-concierge-our-partners"
    >
      <div className="incircle-concierge-our-partners__heading">
        <h3 className="incircle-concierge-our-partners__heading__title">
          {content.title}
        </h3>
        <p
          className="incircle-concierge-our-partners__heading__desc"
          dangerouslySetInnerHTML={{
            __html: content.desc,
          }}
        />
      </div>
      <div className="incircle-concierge-our-partners__locations">
        <div className="incircle-concierge-our-partners__locations__united-states">
          <b>{content.partnerLocations.unitedStates.title}</b>
          <br />
          <br />
          {content.partnerLocations.unitedStates.states.map(location => (
            <IncircleConciergeLocation key={location.id} location={location} />
          ))}
        </div>
        <div className="incircle-concierge-our-partners__locations__world-wide">
          {content.partnerLocations.worldWide.map(location => (
            <IncircleConciergeLocation key={location.id} location={location} />
          ))}
        </div>
      </div>
    </Element>
  );
};

export default IncircleConciergeOurPartners;
