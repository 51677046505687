import get from 'lodash/get';
import find from 'lodash/find';
import getCustomizationOptionById from 'productpage/selectors/getCustomizationOptionById';

export default (product, id) => {
  const { customization = {} } = product;
  const customizationOption = getCustomizationOptionById(product, id);

  const selectedCustomizations = get(
    customization,
    'selectedCustomizationsAllTypes',
    [],
  );
  const selectedCustomizationOption =
    find(selectedCustomizations, { id }) || {};

  const choices = get(customizationOption, 'choices', []);
  const index =
    (selectedCustomizationOption && selectedCustomizationOption.savedValues) ||
    0;
  return choices.length && choices[index] ? choices[index].value : '';
};
