import React from 'react';
import { connect } from 'react-redux';
import Contentful from '@bgo-ui/common/client/contentful/contentful';
import classnames from 'classnames';
import get from 'lodash/get';
import './BGxHamptonsPage.scss';
import { contentfulIdentifier, loadingStatus } from '../../constants';
import BGxHamptonsForm from '../BGxHamptonsForm/BGxHamptonsForm';

export function DumbBGxHamptonsPage({ rsvpFormLoadingStatus }) {
  const resolved = rsvpFormLoadingStatus === loadingStatus.resolved;

  return (
    <main className="rsvp-page">
      <section
        className={classnames(
          'rsvp-page__section rsvp-page__thank-you',
          !resolved && 'rsvp-page--hide',
        )}
      >
        <Contentful identifier={contentfulIdentifier.thankYou} version={2} />
      </section>
      {!resolved && (
        <React.Fragment>
          <section className="rsvp-page__section rsvp-page__details">
            <Contentful identifier={contentfulIdentifier.details} version={2} />
          </section>
          <section className="rsvp-page__section rsvp-page__form">
            <Contentful identifier={contentfulIdentifier.summary} version={2} />
            <BGxHamptonsForm />
          </section>
        </React.Fragment>
      )}
    </main>
  );
}

const mapStateToProps = state => ({
  rsvpFormLoadingStatus: get(state, 'bgxHamptons.rsvpFormLoadingStatus', null),
});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbBGxHamptonsPage);
