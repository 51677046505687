import isEmpty from 'lodash/isEmpty';
import React, { Component } from 'react';
import { renderFormattedPrice } from 'client-utils/utilities-price';
import { renderFormattedPriceWithDecimals } from 'pdp/utilities/utilities-price';
import PromotionItemHtml from 'pdp/components/ProductPage/components/ProductDetails/components/Heading/components/PromotionItemHtml/promotionItemHtml';
import PriceAdornment from '../SalePrice/components/PriceAdornment/priceAdornment';
import parseInt from 'lodash/parseInt';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import flow from 'lodash/flow';
import partialRight from 'lodash/partialRight';
import property from 'lodash/property';
import { SubTitle2 } from 'bgo-common/Styleguide/Typography';

function displayPriceAdornments(
  adornments,
  currencyCode,
  promotionalAdornmentColor,
  showPriceInDecimals,
  rrPromoInfoAvailable,
  hasPromoPrice = false,
) {
  const getMinPriceAdornment = partialRight(
    minBy,
    flow(
      property('price'),
      parseInt,
    ),
  );
  const getMaxPriceAdornment = partialRight(
    maxBy,
    flow(
      property('price'),
      parseInt,
    ),
  );
  let prices = [];
  if (adornments && adornments.length) {
    prices = [
      getMaxPriceAdornment(adornments),
      getMinPriceAdornment(adornments),
    ];
  }

  return prices.map(({ label, price }, key) => {
    const currentPrice = key === adornments.length - 1 && !rrPromoInfoAvailable;
    let color;

    if (currentPrice && promotionalAdornmentColor) {
      color = `#${promotionalAdornmentColor}`;
    } else if (key === 1) {
      color = 'black';
    }

    return (
      <span key={key}>
        <PriceAdornment
          currentPrice={currentPrice}
          label={label}
          color={color}
          price={price}
          currencyCode={currencyCode}
          showPriceInDecimals={showPriceInDecimals}
          showAdornLabel={false}
          hasPromoPrice={hasPromoPrice}
        />
        {' ' /* Allow line to wrap */}
      </span>
    );
  });
}

class SalePriceWithPromotion extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }
  render() {
    const {
      price = {},
      promotionAdornmentColor = '',
      showPriceInDecimals = false,
      promotions,
      promotionalPrice,
      rrPromoInfoAvailable = false,
    } = this.props;

    const { currencyCode, retailPrice, adornments } = price;

    const retailClass =
      !isEmpty(promotions) && promotionalPrice ? 'hasPromoPrice' : '';
    const retailWithPromo =
      !isEmpty(promotions) && promotionalPrice && retailPrice
        ? 'retailWithPromo'
        : '';
    return (
      <div className="sale-price">
        <SubTitle2>
          {retailPrice && isEmpty(adornments) ? (
            <span className={`retailPrice ${retailClass} ${retailWithPromo}`}>
              {showPriceInDecimals
                ? renderFormattedPriceWithDecimals(retailPrice, currencyCode)
                : renderFormattedPrice(retailPrice, currencyCode)}
            </span>
          ) : (
            displayPriceAdornments(
              adornments,
              currencyCode,
              promotionAdornmentColor,
              showPriceInDecimals,
              rrPromoInfoAvailable,
              !isEmpty(promotions) && promotionalPrice,
            )
          )}
          {!isEmpty(promotions) ? (
            <PromotionItemHtml
              promotions={promotions}
              currencyCode={currencyCode}
              promotionalPrice={promotionalPrice}
              isNewPDP
            />
          ) : null}
        </SubTitle2>
      </div>
    );
  }
}
export default SalePriceWithPromotion;
