import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getUserData } from 'productpage/actions';
import './MyCommunicationPrefPage.scss';
import { getAccountDetailsForMyAccount } from '../AccountDetailsPage/actions';
import { openMobileNav } from '../../actions';
import CommunicationPrefPage from './CommunicationPrefPage';
import { redirectToLoginPage } from '../AccountDetailsPage/helpers';

function MyCommunicationPrefPage({
  userId,
  emailID,
  getUserData,
  getAccountDetailsForMyAccount,
  openMobileNav,
}) {
  const [isloading, setIsLoading] = useState(true);
  const handleOnload = async () => {
    // se email and user details
    try {
      await getAccountDetailsForMyAccount();
    } catch (error) {
      console.warn('something went wrong', error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (userId) {
      handleOnload();
    } else {
      redirectToLoginPage();
    }
  }, []);

  return (
    <>
      {!isloading && (
        <CommunicationPrefPage
          emailID={emailID}
          getUserData={getUserData}
          openMobileNav={openMobileNav}
        />
      )}
    </>
  );
}

const mapStateToProps = state => ({
  userId: state.user?.ucid,
  amplifyConfig: state.amplifyConfig,
  emailID: get(state, 'myaccount.myAccountDetails.fields.email', null),
});
const mapDispatchToProps = dispatch => ({
  getUserData: () => dispatch(getUserData()),
  getAccountDetailsForMyAccount: () =>
    dispatch(getAccountDetailsForMyAccount()),
  openMobileNav: () => dispatch(openMobileNav()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MyCommunicationPrefPage),
);
