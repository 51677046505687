import React from 'react';
import Toggle from 'shared/components/Toggle/toggle';
import TrueFit from '../SizeRecommendation/TrueFit';

export default class SizeGuide extends React.Component {
  getDisplayText(hovered = {}, selected = {}) {
    if (this.props.optionType !== 'PDP') {
      if (hovered.name || selected.name) {
        return `${
          hovered.name
            ? hovered.name.toLowerCase()
            : selected.name.toLowerCase()
        }`;
      } else {
        return '';
      }
    } else if (hovered.displayName || selected.displayName) {
      return `${
        hovered.displayName
          ? hovered.displayName
              .replace(/[0-9]+/g, '')
              .trim()
              .toLowerCase()
          : selected.displayName
              .replace(/[0-9]+/g, '')
              .trim()
              .toLowerCase()
      }`;
    } else {
      return '';
    }
  }

  isSizeOption(label) {
    return label === 'size';
  }

  render() {
    const {
      label,
      labelHeading,
      options,
      selectedIndex,
      hoveredIndex,
      sizeGuide,
    } = this.props;

    return (
      <div>
        <span className="product-options__heading">
          {sizeGuide && (
            <div
              className="product-options__size-guide hide-on-desktop hide-on-tablet"
              dangerouslySetInnerHTML={{ __html: sizeGuide }}
            />
          )}
          <span className="product-options__label">
            {labelHeading} {label}:{' '}
          </span>
          {this.getDisplayText(options[hoveredIndex], options[selectedIndex])}
          {sizeGuide && (
            <div
              className="product-options__size-guide hide-on-mobile"
              dangerouslySetInnerHTML={{ __html: sizeGuide }}
            />
          )}
          <Toggle feature="TRUEFIT">
            <Toggle
              feature="DISABLE_NMO_UI_EXTERNAL_LIBRARIES"
              fallback={
                this.isSizeOption(label) && (
                  <TrueFit productId={this.props.productId} />
                )
              }
            >
              {false}
            </Toggle>
          </Toggle>
        </span>
      </div>
    );
  }
}
