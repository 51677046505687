import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import { shouldLoad } from 'universal/http-client';
import { isNewHomePage } from '@bgo-ui/common/client/utilities/is-new-home-page';
import classNames from 'classnames';

import {
  getCurratedProductInfo,
  getTotalForNewArrival,
} from '../../../ecm/actions/actions-ecmcontent';
import Ticker from '../ticker/ticker';
import {
  defaultTrackingTags,
  PROMO_TILE_REPLACE_PATTERN,
} from '../../constants/constants';
import { getTags } from '../utils';
import { formatNumber } from '../../../utilities/utilities-number';
import './productCarousel.scss';
import SlickProductCarousel from './slick/slickProductCarousel';
import SwiperProductCarousel from './swiper/swiperProductCarousel';

const hasPattern = textObject => {
  if (textObject && textObject.content) {
    const contentArray = textObject.content;
    const textString = contentArray.reduce((acc, item) => {
      return acc + item.content.reduce((a, i) => a + i.value, '');
    }, '');
    return textString.match(PROMO_TILE_REPLACE_PATTERN);
  }
  return false;
};

export class ProductCarousel extends Component {
  componentDidMount() {
    const {
      visualExperience,
      title,
      bgoNewArrivalsFirstPositon: firstPromoItem = {},
      ids,
    } = get(this.props.cmsContentItem, 'fields');
    let promoHasPattern = false;
    if (!isEmpty(firstPromoItem)) {
      const { desktopText, tabletText, mobileText } = get(
        firstPromoItem,
        'fields.text.fields',
        undefined,
      );
      promoHasPattern =
        hasPattern(desktopText) ||
        hasPattern(tabletText) ||
        hasPattern(mobileText);
    }
    if (
      visualExperience === 'New Arrivals' &&
      (title.match(PROMO_TILE_REPLACE_PATTERN) || promoHasPattern) &&
      ids[1]
    ) {
      this.props.dispatch(getTotalForNewArrival(ids[1]));
    }
  }

  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    const { cmsContentItem = {}, ecmCurratedProductsApi } = this.props;
    const { ids } = get(cmsContentItem, 'fields');
    if (ids && ids.length > 0 && shouldLoad(ecmCurratedProductsApi)) {
      this.props.dispatch(getCurratedProductInfo(ids[0]));
    }
  }

  combineProducts(ecmProducts, childProducts) {
    return ecmProducts.map(product =>
      /* eslint-disable max-len */
      product.isGroup && product.childProductIds
        ? childProducts.find(
            childProduct => childProduct.id === product.childProductIds[0],
          ) || product
        : product,
    );
  }

  render() {
    const {
      cmsContentItem,
      isMobilePhone = false,
      ecmProducts = [],
      childProducts = [],
      isNewHomePage,
      parentTrackingTags,
      totalNA,
    } = this.props;
    let filteredProducts = [];
    if (!isEmpty(childProducts)) {
      filteredProducts = this.combineProducts(ecmProducts, childProducts);
    }
    const combinedProducts = !isEmpty(filteredProducts)
      ? filteredProducts
      : ecmProducts;
    const carouselDirection = get(cmsContentItem, 'fields.carouselDirection');
    const firstPromoItem = get(
      cmsContentItem,
      'fields.bgoNewArrivalsFirstPositon',
      {},
    );
    const lastPromoItem = get(
      cmsContentItem,
      'fields.bgoNewArrivalsLastPositon',
      {},
    );
    const { visualExperience, title, trackingTags = [] } = get(
      cmsContentItem,
      'fields',
    );
    const isNewArrivals = visualExperience === 'New Arrivals';
    const verticalCarouselHeight = combinedProducts.length * 425;
    const totalNAreplace =
      isNewArrivals && totalNA ? `${formatNumber(totalNA)}` : '';
    const replaceRegExp = new RegExp(PROMO_TILE_REPLACE_PATTERN, 'g');
    const productTile = title
      ? title.replace(replaceRegExp, totalNAreplace)
      : '';

    // Set up Tracking Tags
    const currentTags = getTags(trackingTags);
    const mergedTrackingTags = merge({}, parentTrackingTags, currentTags);

    const innerCarouselProps = {
      cmsContentItem,
      combinedProducts,
      firstPromoItem,
      lastPromoItem,
      carouselDirection,
      mergedTrackingTags,
      isNewHomePage,
    };

    const { isNewCarousel } = this.props;

    const InnerCarousel = isNewCarousel
      ? SwiperProductCarousel
      : SlickProductCarousel;

    return (
      <Fragment>
        {isNewHomePage && !isEmpty(productTile) && (
          <div className="ticker_wrapper">
            <div className="ticker-new-arrivals">
              <Ticker text={productTile} />
            </div>
          </div>
        )}
        <div
          className={classNames(
            'product-carousel-slider-container',
            isNewHomePage && 'new-home-page-product-carousel',
            isNewArrivals && 'new-arrivals-product-carousel',
            isMobilePhone && 'display-phone',
          )}
          style={carouselDirection === 'Vertical' ? { width: '100%' } : {}}
        >
          <div
            style={
              carouselDirection === 'Vertical'
                ? { height: `${verticalCarouselHeight}px` }
                : {}
            }
          >
            <InnerCarousel {...innerCarouselProps} />
          </div>
        </div>
      </Fragment>
    );
  }
}

ProductCarousel.defaultProps = {
  parentTrackingTags: defaultTrackingTags,
};

const mapStateToProps = (state, ownProps) => {
  const contentId = get(ownProps, 'cmsContentItem.sys.id');
  const categoryId = get(ownProps, 'cmsContentItem.fields.ids[0]');
  const curratedProducts = get(
    state,
    'ecm.ecmDynamicProductInfo.curratedProducts',
  );
  const ecmProductsByCatID = curratedProducts.filter(
    products => products.catID === categoryId,
  );

  return {
    isMobilePhone: get(state, 'device.isMobilePhone'),
    ecmProducts: get(ecmProductsByCatID[0], 'products'),
    ecmCurratedProductsApi:
      state.api[`ecm_currated_products_${categoryId}`.toLowerCase()],
    childProducts: get(state, `cms.childProducts[${contentId}]`),
    isNewHomePage: isNewHomePage(state),
    totalNA: get(state, 'ecm.ecmDynamicProductInfo.totalNA'),
    isNewCarousel: state?.toggles?.NEW_HP_CAROUSEL,
  };
};

export default connect(mapStateToProps)(ProductCarousel);
