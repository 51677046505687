import React from 'react';
import { bool, object, string } from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import merge from 'lodash/merge';
import Slider from 'react-slick';
// eslint-disable-next-line import/no-cycle
import RenderContentItem from '../contentItem/contentItem';
import { getTags } from '../utils';
import { defaultTrackingTags } from '../../constants/constants';
import './slideShow.scss';

/**
 * @function DumbSlideShow
 * @param {Object} props.cmsContentItem
 * @param {Boolean} props.enableAutoPlay
 * @param {Object} props.parentTrackingTags
 * @param {String} props.type
 * @returns {React.ReactElement}
 */
export const DumbSlideShow = ({
  cmsContentItem,
  enableAutoPlay,
  parentTrackingTags,
  type,
}) => {
  const contentItemFields = get(cmsContentItem, 'fields', {});
  const components = get(contentItemFields, 'components', []);
  const transitionTime = get(contentItemFields, 'transitionTime', 7000);

  // Set up tracking tags
  const { trackingTags } = contentItemFields;
  const currentTags = getTags(trackingTags);
  const mergedTrackingTags = merge({}, parentTrackingTags, currentTags);

  const settings = {
    autoplaySpeed: transitionTime,
    autoplay: enableAutoPlay,
    arrows: true,
    className: type,
    fade: true,
  };

  return (
    <Slider {...settings}>
      {components.map(contentItem => {
        if (contentItem.fields) {
          return (
            <RenderContentItem
              cmsContentItem={contentItem}
              key={contentItem.sys.id}
              parentTrackingTags={mergedTrackingTags}
            />
          );
        }
        return null;
      })}
    </Slider>
  );
};

DumbSlideShow.defaultProps = {
  parentTrackingTags: defaultTrackingTags,
};

DumbSlideShow.propTypes = {
  cmsContentItem: object,
  enableAutoPlay: bool,
  parentTrackingTags: object,
  type: string,
};

const mapStateToProps = state => ({
  enableAutoPlay:
    get(state, 'page.location.query.ENABLE_AUTOPLAY', 'true') === 'true',
});

export default connect(mapStateToProps)(DumbSlideShow);
