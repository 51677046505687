import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import './Bg360Modal.scss';
import Bg360Logo from '../../../../assets/bg-360-logo.gif';
import { modalContentType } from '../../constants';
import SignUpModalContent from './SignUpModalContent/SignUpModalContent';
import WelcomeModalContent from './WelcomeModalContent/WelcomeModalContent';

const contentComponentByType = {
  [modalContentType.welcome]: WelcomeModalContent,
  [modalContentType.signUp]: SignUpModalContent,
};

const Bg360Modal = ({ contentType }) => {
  const ContentComponent = contentComponentByType[contentType];

  return (
    <div className="bg-360-modal-content">
      <div className="logo">
        <img src={Bg360Logo} alt="" />
      </div>
      {ContentComponent ? <ContentComponent /> : null}
    </div>
  );
};

const mapStateToProps = state => ({
  contentType: get(state, 'modal.contentType', modalContentType.signUp),
});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Bg360Modal);
