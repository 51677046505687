import isUndefined from 'lodash/isUndefined';
import isString from 'lodash/isString';
import { isMobile } from 'client-utils/utilities-page';

function handlePrevFocus(prevOpen, prevElement) {
  switch (prevOpen) {
    case 'GwpMultiSkuModal':
      return gwpMultiSkuModal();
    case 'MonogramModal':
      return monogramModal(prevElement);
    case isUndefined(prevElement) || !isString(prevOpen):
      return prevElement;
    default:
      return prevElement;
  }
}

function gwpMultiSkuModal() {
  if (isMobile()) {
    return 'miniCartButtonMobile';
  } else {
    return 'miniCartButton';
  }
}

function monogramModal(prevElement) {
  if (prevElement.textContent === 'Personalize this item') {
    return 'personalizationEdit';
  } else {
    return prevElement;
  }
}

export default handlePrevFocus;
