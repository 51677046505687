import React, { useEffect } from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { localStorage } from 'window-or-global';
import httpWithLogging from 'universal/http-client';
import logger from 'server-utils/logger';

const OrderConfirmation = props => {
  useEffect(() => {
    const { profileId, countryCode, currencyCode } = props;
    const lineItems = [];
    const intlCartName = `internationalCart_${countryCode}_${currencyCode}`;
    const localInternationalCart =
      localStorage &&
      localStorage.getItem(intlCartName) &&
      JSON.parse(localStorage.getItem(intlCartName));
    let cartTotal = 0;
    let totalIntlParentheticalAmount = 0;
    localInternationalCart &&
      localInternationalCart.map(cartItem => {
        let listPrice = cartItem.price.retailPrice;
        let salePrice = cartItem.price.retailPrice;
        if (cartItem.price.adornments) {
          listPrice = cartItem.price.adornments[0].price;
          salePrice = cartItem.price.adornments[1].price;
        }
        const lineItem = {
          skuId: cartItem.skuId,
          qty: cartItem.productQty,
          listPrice,
          salePrice,
          surcharge: cartItem.intlParentheticalAmount,
        };
        lineItems.push(lineItem);
        const intlParentheticalAmount = cartItem.intlParentheticalAmount
          ? Number(cartItem.intlParentheticalAmount)
          : 0;
        totalIntlParentheticalAmount += intlParentheticalAmount;
        const productPrice =
          Number(cartItem.price.retailPrice) * cartItem.productQty;
        cartTotal += Number(productPrice);
        return true;
      });
    const requestApi = httpWithLogging({}, 6000);
    const fullEnvoyUrl = window?.sessionStorage.getItem('fullEnvoyUrl');
    const splitUrl = fullEnvoyUrl?.split('/');
    const orderUrl = splitUrl[splitUrl.length - 1];
    const borderFreeOrderId = orderUrl?.split('-')[0];
    const merchantOrderId = window?.sessionStorage.getItem('merchantOrderId');
    const statusUrl = window.location.href.match(/status=([^&]*)/);
    const orderStatus = statusUrl && statusUrl[1];
    const requestBody = {
      orderReq: {
        countryCode,
        currencyCode,
        profileId,
        orderId: merchantOrderId,
        orderTotal: totalIntlParentheticalAmount + cartTotal,
        orderStatus,
        lineItems,
        borderFreeOrderId,
      },
    };
    async function saveIntlCartData() {
      await requestApi.post(
        NMConfig.API_SAVE_INTL_CART,
        requestBody,
      ).then((successResponse) => {
        if ((orderStatus === 'success' || orderStatus === 'pending') && successResponse) {
          localStorage && localStorage.removeItem(intlCartName);
          window?.sessionStorage.removeItem('merchantOrderId');
        }
      }).catch((error) => {
        logger.error('Error sending international Cart Details ', error);
      });
    }
    saveIntlCartData();
  }, []);
  return <div id="orderConfirmation" />;
};

const mapStateToProps = state => {
  return {
    profileId: get(state.session, 'WID', '12345'),
    countryCode: get(state, 'locale.countryCode', 'US'),
    currencyCode: get(state, 'locale.currencyCode', 'USD'),
  };
};

export default connect(mapStateToProps)(OrderConfirmation);
