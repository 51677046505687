import sanitizeHtml from 'sanitize-html-react';

// This list was pulled from dompurify to obtain feature parity.
const defaultAllowedTags = [
  'a',
  'abbr',
  'acronym',
  'address',
  'area',
  'article',
  'aside',
  'audio',
  'b',
  'bdi',
  'bdo',
  'big',
  'blink',
  'blockquote',
  'body',
  'br',
  'button',
  'canvas',
  'caption',
  'center',
  'cite',
  'code',
  'col',
  'colgroup',
  'content',
  'data',
  'datalist',
  'dd',
  'decorator',
  'del',
  'details',
  'dfn',
  'dir',
  'div',
  'dl',
  'dt',
  'element',
  'em',
  'fieldset',
  'figcaption',
  'figure',
  'font',
  'footer',
  'form',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'head',
  'header',
  'hgroup',
  'hr',
  'html',
  'i',
  'img',
  'input',
  'ins',
  'kbd',
  'label',
  'legend',
  'li',
  'main',
  'map',
  'mark',
  'marquee',
  'menu',
  'menuitem',
  'meter',
  'nav',
  'nobr',
  'ol',
  'optgroup',
  'option',
  'output',
  'p',
  'pre',
  'progress',
  'q',
  'rp',
  'rt',
  'ruby',
  's',
  'samp',
  'section',
  'select',
  'shadow',
  'small',
  'source',
  'spacer',
  'span',
  'strike',
  'strong',
  'style',
  'sub',
  'summary',
  'sup',
  'table',
  'tbody',
  'td',
  'template',
  'textarea',
  'tfoot',
  'th',
  'thead',
  'time',
  'tr',
  'track',
  'tt',
  'u',
  'ul',
  'var',
  'video',
  'wbr',
  'meta',
  'link',
  'base',
];

// This list was pulled from dompurify to obtain feature parity.
const defaultAllowedAttributes = {
  '*': [
    'alt',
    'class',
    'for',
    'id',
    'label',
    'name',
    'pattern',
    'placeholder',
    'summary',
    'title',
    'value',
    'style',
    'xmlns',
    'charset',
    'content',
    'http-equiv',
    'name',
    'scheme',
    'href',
    'hreflang',
    'media',
    'rel',
    'rev',
    'sizes',
    'target',
    'type',
  ],
};

const defaultNonTextTags = ['style', 'script'];

export function sanitize(html, allowedTags = [], allowedAttributes = {}) {
  if (!html) return '';
  return sanitizeHtml(html, {
    allowedTags: [...defaultAllowedTags, ...allowedTags, 'title'],
    allowedAttributes: {
      ...defaultAllowedAttributes,
      ...allowedAttributes,
    },
  });
}

export function sanitizeBloomReach(
  html,
  allowedTags = [],
  allowedAttributes = {},
  nonTextTags = [],
) {
  if (!html) return '';
  return sanitizeHtml(html, {
    allowedTags: [...defaultAllowedTags, ...allowedTags],
    allowedAttributes: {
      ...defaultAllowedAttributes,
      ...allowedAttributes,
    },
    nonTextTags: [...defaultNonTextTags, ...nonTextTags],
  });
}

export function dangerousProperty(html, extraTags, extraAttributes) {
  return { __html: sanitize(html, extraTags, extraAttributes) };
}
