/* eslint-disable import/no-extraneous-dependencies */
import ResponsiveImage from 'bgo-common/client/common/components/ResponsiveImage/responsiveImage';
import { isMobile } from 'client-utils/utilities-page';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { NO_IMAGE_AVAILABLE_CDN } from 'pdp/components/ProductPage/components/Image/image';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  closeShopTheStyleModal,
  getStsProduct,
  clickTracker,
} from '../../../actions/actions-shopthestyle';
import ShopTheStyleGridContainer from '../ShopTheStyleGrid/shopTheStyleGridContainer';
import './shopTheStyleModal.scss';
import { SHOP_THE_STYLE } from '../../../constants';
import {
  H2,
  EyeBrow1,
  EyeBrow2,
  BodyB2,
} from 'bgo-common/Styleguide/Typography';
import SimpleSpinner from 'shared/components/Spinner/simpleSpinner';

export class ShopTheStyleModal extends Component {
  constructor() {
    super();
    this.state = {
      isMobile: isMobile(),
    };
  }

  componentDidMount() {
    const { getStsProduct, stsProduct } = this.props;
    getStsProduct(stsProduct);
  }

  componentWillUnmount() {
    this.props.clearSTSProduct();
  }

  render() {
    const { product, stsCategory } = this.props;

    return (
      <div className="shop-the-style-modal-content">
        {!isEmpty(product) ? (
          <Fragment>
            <EyeBrow1 className="sts-modal-heading">{SHOP_THE_STYLE}</EyeBrow1>
            {isEmpty(product) ? null : (
              <Fragment>
                <H2 className="sts-product-name">{product.name}</H2>
                <div className="sts-product-image">
                  <div className="sts-main-image" onClick={clickTracker}>
                    <a href={product.canonicalUrl}>
                      <ResponsiveImage
                        src={get(
                          product,
                          'media.main.dynamic.url',
                          NO_IMAGE_AVAILABLE_CDN,
                        )}
                        alt={product.name}
                        title={product.name}
                        name={product.name}
                        isCloudinary
                      />
                    </a>
                    <div className="sts-product-info">
                      <EyeBrow2 className="sts-designer-name">
                        {product.designerName}
                      </EyeBrow2>
                      <BodyB2>{product.name}</BodyB2>
                      <BodyB2>{`${product.price.currencyCode}${product.price.retailPrice}`}</BodyB2>
                    </div>
                  </div>
                  <div className="sts-alternate-image" onClick={clickTracker}>
                    <a href={product.canonicalUrl}>
                      <ResponsiveImage
                        src={get(
                          product,
                          'media.alternate.a.dynamic.url',
                          NO_IMAGE_AVAILABLE_CDN,
                        )}
                        alt={product.name}
                        title={product.name}
                        name={product.name}
                        isCloudinary
                      />
                    </a>
                    <div className="sts-product-info">
                      <EyeBrow2 className="sts-designer-name">
                        {product.designerName}
                      </EyeBrow2>
                      <BodyB2>{product.name}</BodyB2>
                      <BodyB2>{`${product.price.currencyCode}${product.price.retailPrice}`}</BodyB2>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
            <ShopTheStyleGridContainer catagoryId={stsCategory} />
          </Fragment>
        ) : (
          <SimpleSpinner />
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({ sts: { stsProduct } }) => ({
  product: stsProduct,
  stsProduct: get(state, 'modal.stsProduct.productId', ''),
  stsCategory: get(state, 'modal.stsProduct.categoryId', ''),
});

const mapDispatchToProps = dispatch => ({
  getStsProduct: stsProduct => dispatch(getStsProduct(stsProduct)),
  clearSTSProduct: () => dispatch(closeShopTheStyleModal),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShopTheStyleModal);
