import get from 'lodash/get';
import find from 'lodash/find';
import getCustomizationOption from 'productpage/selectors/getCustomizationOption';

export default (product, legacyType) => {
  const { customization = {} } = product;
  const customizationOption = getCustomizationOption(product, legacyType);

  const selectedCustomizations = get(
    customization,
    'selectedCustomizations',
    [],
  );
  const selectedCustomizationOption =
    find(selectedCustomizations, { legacyType }) || {};

  if (legacyType === 'output data') {
    return selectedCustomizationOption.selectedValue;
  }
  const choices = get(customizationOption, 'choices', []);
  return choices[selectedCustomizationOption.selectedValue];
};
