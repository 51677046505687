import React from 'react';
import classNames from 'classnames';
import ButtonLoader from '../ButtonLoader/ButtonLoader';
import '../FormButtons.scss';

const SubmitButton = ({
  isSubmitting,
  type = 'button',
  caption,
  onClick,
  disabled,
  additionalClasses,
}) => {
  const btnProps = { type };
  if (onClick) {
    btnProps.onClick = onClick;
  }
  return (
    <button
      className={classNames('form-buttons__submit-btn', additionalClasses)}
      disabled={isSubmitting || disabled}
      {...btnProps}
    >
      {isSubmitting ? <ButtonLoader /> : caption}
    </button>
  );
};

export default SubmitButton;
