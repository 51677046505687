import React, { Component } from 'react';
import { connect } from 'react-redux';

class EcmRecommendedProducts extends Component {
  constructor() {
    super();
    this.state = { clicked: false };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedItemIndex !== this.props.position) {
      this.setState({ clicked: false });
    }
  }
  replaceImageShotFromRR(imgURL) {
    let newImgUrl = imgURL;
    if (newImgUrl.indexOf('_mh') !== -1) {
      newImgUrl = newImgUrl.replace('_mh', '_mk');
      if (newImgUrl.indexOf('/mh/') !== -1)
        newImgUrl = newImgUrl.replace('/mh/', '/mk/');
    } else if (newImgUrl.indexOf('_mz') !== -1) {
      newImgUrl = newImgUrl.replace('_mz', '_mk');
      if (newImgUrl.indexOf('/mz/') !== -1)
        newImgUrl = newImgUrl.replace('/mz/', '/mk/');
    } else if (newImgUrl.indexOf('.scene7') !== -1) {
      const imgURLArr = newImgUrl.split('?');
      newImgUrl = `${imgURLArr[0]}?&width=100%`;
    }
    return newImgUrl;
  }
  handleClick(e, index) {
    const { registerClick } = this.props;
    const { clicked } = this.state;
    if (!clicked) {
      this.setState({ clicked: true });
      registerClick(index);
      e.preventDefault();
    }
  }
  render() {
    const { ecmRecommendedProduct, position } = this.props;
    const figureId = `ecm-container__recommendations__figure${ecmRecommendedProduct.id}`;
    const classes = this.state.clicked
      ? 'ecm-container__recommendations__figure active'
      : 'ecm-container__recommendations__figure';
    return (
      <div className="grid-25 tablet-grid-25 mobile-grid-50 ecm-container__recommendations-grid-25-50">
        <a
          href={ecmRecommendedProduct.clickURL}
          onTouchEnd={e => this.handleClick(e, position)}
          className="ecm-container__recommendations__figure_link jfy-product-link"
        >
          <figure className={classes} id={figureId}>
            <img
              src={this.replaceImageShotFromRR(ecmRecommendedProduct.imageURL)}
              alt={ecmRecommendedProduct.name}
            />
            <figcaption className="ecm-container__recs__figure__caption">
              <span className="ecm-container__recs__figure__caption_designerName">
                {ecmRecommendedProduct.designerName}
              </span>
              <span className="ecm-container__recs__figure__caption_name">
                {ecmRecommendedProduct.name}
              </span>
            </figcaption>
          </figure>
        </a>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedItemIndex: state.ecm.ecmRecommendations.selectedItemIndex,
});

export default connect(mapStateToProps)(EcmRecommendedProducts);
