import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import './scrollButton.scss';
import {focusSkipToMainComponent} from 'client/common/actions/actions-page';
import { ENTER_KEYCODE, SPACE_KEYCODE } from 'client-utils/keyCodes';

export class ScrollToTopButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hide: true
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    const scrollRatio = 0.5;
    this.scrollAfter = window.innerHeight * scrollRatio;
    this.handleScroll();
    document.addEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    this.setState({ hide: window.pageYOffset < this.scrollAfter });
  }

  handleKeyDown(e) {
    if (e.which === ENTER_KEYCODE || e.which === SPACE_KEYCODE || e.type === 'click') {
      this.props.focusSkipToMainComponent(e);
    }
  }

  render() {
    const { isContainerType, isSRP } = this.props;
    return (
      !this.state.hide && (
        <button
          className={isContainerType === 'BELL' || isContainerType === 'NOTIFICATION' || isContainerType === 'MAIN'
            ? 'scroll-to-top scroll-to-top-active'
            : 'scroll-to-top scroll-to-top-no-promo'
          }
          aria-label="Link:Back to top"
          onClick={e => {
            this.props.focusSkipToMainComponent(e);
          }}
          onKeyDown={e => this.handleKeyDown(e)}
        >
        </button>
      )
    );
  }
}
const mapStateToProps = state => {
  return {
    isContainerType: get(state, 'wkyWidget.container.type', null)
  };
};
const mapDispatchToProps = ({
  focusSkipToMainComponent
});

export default connect(mapStateToProps, mapDispatchToProps)(ScrollToTopButton);
