import React from 'react';

export default ({ name, value, onChange }) => (
  <label className="checkbox-wrapper" htmlFor={`service-${name}`}>
    <div className={`checkbox-emulator ${value ? 'checked' : ''}`}>
      <input
        id={`service-${name}`}
        type="checkbox"
        checked={value}
        onChange={onChange}
      />
    </div>
    <span>{name}</span>
  </label>
);
