import get from 'lodash/get';
import find from 'lodash/find';
import merge from 'lodash/merge';
import values from 'lodash/values';
import {
  getColorOptions,
  getSizeOptions,
} from 'productpage/selectors/getOptions';
import { getMedia } from 'productpage/selectors/getMedia';
import getSizeSkus from 'productpage/selectors/getSizeSkus';
import getColorSkus from 'productpage/selectors/getColorSkus';
import getSelectedSku from 'productpage/selectors/getSelectedSku';
import generateScene7ImageURL from './generateScene7ImageURL';

const getSelectedText = selectedCustomizations => {
  return get(
    find(selectedCustomizations, ['legacyType', 'output data']),
    'selectedValue',
    [],
  );
};

const getSelectedMonoStyleIndex = selectedCustomizations => {
  const monoStyle = find(selectedCustomizations, ['legacyType', 'mono style']);
  return get(monoStyle, 'selectedValue', 0);
};

const getSelectedThreadColorIndex = selectedCustomizations => {
  const threadColor = find(selectedCustomizations, [
    'legacyType',
    'thread color',
  ]);
  return get(threadColor, 'selectedValue', 0);
};

export function getDynamicScene7ImageURL(
  product,
  activeColorIndex,
  activeMonostyleChoiceIndex,
  activeThreadColorChoiceIndex,
  activeText,
  width = 400,
  height,
) {
  const { customization = {}, productFlags = {} } = product;
  const productState = product;

  const customizationOptions = get(customization, 'customizationOptions', []);
  const monoStyle =
    find(customizationOptions, { legacyType: 'mono style' }) || {};
  const threadColor =
    find(customizationOptions, { legacyType: 'thread color' }) || {};
  const monoStyleChoices = get(monoStyle, 'choices', []);
  const threadColorChoices = get(threadColor, 'choices', []);

  const colorOptions = getColorOptions(productState);

  if (!productFlags.previewSupported) {
    if (productFlags.dynamicImageSkuColor) {
      const optionMedia = get(
        colorOptions,
        `values[${activeColorIndex}].media`,
        {},
      );
      const baseMedia = getMedia(product);
      const activeMedia = merge(
        {},
        baseMedia.main,
        values(optionMedia.alternate)[0],
        optionMedia.main,
      );
      return get(activeMedia, 'medium.url');
    }
    const { media = {} } = product;
    return get(media.main, 'medium.url');
  }

  const selectedSizeIndex = get(productState, 'options.selectedSizeIndex', -1);
  const sizeSkus = getSizeSkus(productState, activeColorIndex);
  const colorSkus = getColorSkus(productState, selectedSizeIndex);
  const sizeOptions = getSizeOptions(productState);

  const selectedSku = getSelectedSku(
    { colorSkus, sizeSkus },
    { colorOptions, sizeOptions },
    { activeColorIndex, selectedSizeIndex },
  );
  const sku = selectedSku || sizeSkus[0];

  return generateScene7ImageURL({
    useSkuAsset: get(sku, 'useSkuAsset', false),
    cmosItem: get(product, 'metadata.cmosItem'),
    dynamicImageSkuColor: get(productFlags, 'dynamicImageSkuColor'),
    previewShotType: get(customization, 'previewShotType'),
    skuColorKey: get(sku, 'color.key'),
    selectedMonoStyle: monoStyleChoices[activeMonostyleChoiceIndex],
    selectedThread: threadColorChoices[activeThreadColorChoiceIndex],
    text: activeText,
    width,
    height,
  });
}

export function getDynamicImageURLForCustomizedProduct(product, width, height) {
  const customization = get(product, 'customization', {});
  const selectedCustomizations = get(
    customization,
    'selectedCustomizations',
    [],
  );
  const activeMonogramStyleIndex = getSelectedMonoStyleIndex(
    selectedCustomizations,
  );
  const activeThreadColorChoiceIndex = getSelectedThreadColorIndex(
    selectedCustomizations,
  );
  const selectedText = getSelectedText(selectedCustomizations);
  const activeColorIndex =
    !product.options.hoveredColorIndex ||
    product.options.hoveredColorIndex === -1
      ? product.options.selectedColorIndex
      : product.options.hoveredColorIndex;

  return getDynamicScene7ImageURL(
    product,
    activeColorIndex,
    activeMonogramStyleIndex,
    activeThreadColorChoiceIndex,
    selectedText,
    width,
    height,
  );
}
