import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Button from '@bgo-ui/common/client/common/components/Button/button';

const AmericanExpressUpdate = ({ amexUpdateSection, user }) => {
  const isLoggedIn = user.name && user.securityStatus === 'Authenticated';

  const handleOnAccountClick = () => {
    window.location.href = isLoggedIn
      ? '/e/myaccount/accountdetails'
      : '/e/login';
  };

  return (
    <section className="center-section content-section">
      <div>
        <h2 className="title title-header">{amexUpdateSection.title}</h2>
        {amexUpdateSection.desc?.map(section => (
          <div key={section.id} className="text-container">
            <h3 className="description title-header">{section.title}</h3>
            <p className="description">{section.desc}</p>
            <br />
          </div>
        ))}
        <div className="center-button">
          <Button
            className="amex-button"
            onClick={handleOnAccountClick}
            value={amexUpdateSection.buttonText}
          />
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = state => ({
  amexUpdateSection: get(
    state,
    'loyaltyConfig.americanExpress.amexUpdateSection',
    '',
  ),
  user: state.user,
});

export default connect(mapStateToProps)(AmericanExpressUpdate);
