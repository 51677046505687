import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import classNames from 'classnames';
import { shouldLoad } from 'universal/http-client';
import Slider from 'react-slick';
import {
  getEcmStoreEvents,
  clearEcmStoreEvents,
} from 'ecm/actions/actions-ecmstoreevents';
import { ecmGetAdaptiveStyleBlock } from 'ecm/utilities';

class EcmStoreEvents extends Component {
  constructor(props) {
    super(props);
    this.loadEvent = this.loadEvent.bind(this);
  }
  componentDidMount() {
    document.addEventListener('readystatechange', this.loadEvent);
  }
  componentWillUnmount() {
    this.props.clearEcmStoreEvents();
  }
  loadEvent() {
    const { api } = this.props;
    if (document.readyState === 'complete') {
      if (shouldLoad(api.ecmstoreevents)) this.props.getEcmStoreEvents();
      document.removeEventListener('readystatechange', this.loadEvent);
    }
  }
  normalizeForDesktop(storeDetails) {
    return `/category/stores/r_${storeDetails.storeId.replace('/', '')}.jpg`;
  }
  normalizeForMobile(storeDetails) {
    return `/category/stores/m_${storeDetails.storeId.replace('/', '')}.jpg`;
  }
  render() {
    const { ecmStoreEvents, dataObj, ecmSlot } = this.props;
    const componentClass = `ecm-container__storecarousel__${get(
      dataObj,
      'componentId',
      ''
    )}`;
    const ecmStyle = get(ecmSlot, 'className', '');
    const classes = classNames(
      'grid-100 tablet-grid-100 mobile-grid-100 ecm-container__storecarousel grid-parent',
      ecmStyle,
      componentClass
    );
    const ecmImages = get(dataObj, 'properties.images', {});
    const adaptiveStyleBlock = ecmGetAdaptiveStyleBlock(
      ecmImages,
      componentClass
    );
    const stores = get(ecmStoreEvents, 'stores', []);
    let storeDetails = {};
    if (stores.length > 0) {
      storeDetails = {
        ...stores[0],
        desktopImgSrc: this.normalizeForDesktop(stores[0]),
        mobileImgSrc: this.normalizeForMobile(stores[0]),
      };
    } else {
      const desktopImgSrc = get(ecmImages, 'desktop.imagesrc', '');
      const mobileImgSrc =
        get(ecmImages, 'mobile.imagesrc', '') === null ||
        isEmpty(get(ecmImages, 'mobile.imagesrc', ''))
          ? ''
          : get(ecmImages, 'mobile.imagesrc', desktopImgSrc);
      storeDetails = { desktopImgSrc, mobileImgSrc };
    }
    const pictureElt = (
      <picture>
        <source
          media="(min-width: 1025px)"
          srcSet={storeDetails.desktopImgSrc}
        />
        <source
          media="(min-width: 768px) and (max-width: 1024px)"
          srcSet={storeDetails.desktopImgSrc}
        />
        <source
          media="(min-width: 1px) and (max-width: 767px)"
          srcSet={
            storeDetails.mobileImgSrc
              ? storeDetails.mobileImgSrc
              : storeDetails.desktopImgSrc
          }
        />
        <img
          className="ecm-container__storecarousel_storeImage"
          srcSet={storeDetails.desktopImgSrc}
          alt={storeDetails.name}
          title={storeDetails.name}
        />
      </picture>
    );
    const settings = {
      dots: false,
      speed: 500,
      autoplay: false,
      arrows: true,
      infinite: false,
    };
    return (
      <div className={classes}>
        {storeDetails.storeId && (
          <div className="ecm-container__storecarousel_container">
            {pictureElt}
            {adaptiveStyleBlock}
            <div className="ecm-container__storecarousel_storeDetails ecm_fontfamily_futura">
              <p className="ecm-container__storecarousel_storeDetails_name">{`WHAT'S HAPPENING AT BERGDORF GOODMAN NEIMAN MARCUS ${storeDetails.name}`}</p>
              <div className="ecm-container__storecarousel_storeDetails_events">
                {!isEmpty(storeDetails.events) && (
                  <Slider {...settings}>
                    {storeDetails.events.map((event, index) => (
                      <div
                        className="ecm-container__storecarousel_storeDetails_event"
                        key={index}
                      >
                        <p className="ecm-container__storecarousel_storeDetails_event_name">
                          {event.name}
                        </p>
                        <p className="ecm-container__storecarousel_storeDetails_event_time">
                          {event.eventTimeDuration}
                        </p>
                      </div>
                    ))}
                  </Slider>
                )}
              </div>
              <a
                href={`/stores/store.jsp?storeId=${storeDetails.storeId}#storeEvents`}
                className="ecm-container__storecarousel_storeDetails_link"
              >
                View all store events <span className="arrowImage" />
              </a>
            </div>
          </div>
        )}
        {!storeDetails.storeId && (
          <div className="ecm-container__storecarousel_container empty">
            {pictureElt}
            {adaptiveStyleBlock}
            <div className="ecm-container__storecarousel_storeDetails ecm_fontfamily_futura">
              <a
                href="/stores/store.jsp"
                className="ecm-container__storecarousel_storeDetails_link"
              >
                SEE WHAT&#39;S HAPPENING AT A STORE NEAR YOU{' '}
                <span className="arrowImage" />
              </a>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  api: state.api,
  ecmStoreEvents: state.ecm.ecmStoreEvents,
});

const mapDispatchToProps = {
  getEcmStoreEvents,
  clearEcmStoreEvents,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EcmStoreEvents);
