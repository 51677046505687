import React, { Component } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { ECMLayouts } from 'ecm/constants';
import EcmColumn from '../EcmColumn';
import Layout from '@bgo-ui/common/components/Layout/Layout';

export default class EcmRow extends Component {
  renderColumns() {
    const {
      ecmContent,
      ecmSlot,
      ecmCallToActionButtonWithTextToggle,
      improvePerformanceOfStyleToggle,
      removeEcmVideoHeightToggle,
    } = this.props;

    const grid = isEmpty(ECMLayouts[ecmContent.layout])
      ? ECMLayouts.A
      : ECMLayouts[ecmContent.layout];
    while (grid.length < ecmContent.columns.length) grid.push(ECMLayouts.A[0]);

    if (ecmContent.layout === 'Z') {
      return (
        <Layout
          ecmCallToActionButtonWithTextToggle={
            ecmCallToActionButtonWithTextToggle
          }
          ecmContent={ecmContent.columns}
          ecmSlot={ecmSlot}
          improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
          gridData={ecmContent.grid}
        />
      );
    }

    return ecmContent.columns.map((col, index) => (
      <EcmColumn
        ecmCallToActionButtonWithTextToggle={
          ecmCallToActionButtonWithTextToggle
        }
        ecmContent={col}
        ecmSlot={ecmSlot}
        key={index}
        ecmLayout={grid[index]}
        improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
        removeEcmVideoHeightToggle={removeEcmVideoHeightToggle}
      />
    ));
  }
  render() {
    const { ecmContent } = this.props;
    const layoutClass = `ecm-container__layout-${get(
      ecmContent,
      'layout',
      'A',
    )}`;
    const classes = classNames(
      layoutClass,
      'ecm-container__row grid-100 mobile-grid-100 tablet-grid-100 grid-parent',
    );
    return <div className={classes}>{this.renderColumns()}</div>;
  }
}
