import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import renderHTML from 'react-render-html';
import capitalize from 'lodash/capitalize';
import classnames from 'classnames';
import get from 'lodash/get';
import { shouldLoad } from 'universal/http-client';
import isEmpty from 'lodash/isEmpty';
import reactCookie from 'react-cookie';
import SkipToContent from 'storefront/components/SkipToContent/skipToContent';
import Header from '@bgo-ui/common/components/Header/header';
import Navigation from '@bgo-ui/common/components/Navigation/navigation';
import Loadable from 'react-loadable';
import ScreenReaderAnnouncer from '@bgo-ui/common/components/ScreenReaderAlert/screenReaderAnnouncer';
import Spinner from 'shared/components/Spinner/spinner';
import IdleTimerWrapper from '@bgo-ui/common/components/Util/IdleTimerWrapper';
import EcmGet, { ECMSlots } from 'ecm/components/EcmGet';
import { loseFocus } from 'shared/components/App/app-actions';
import OldFooter from 'bgo-common/components/Footer/footer';
import { Footer } from '@bgo-ui-resources/bgo-component-library';
import PromoCarousel from 'storefront/components/PromoCarousel/promoCarousel';
import Toggle from 'shared/components/Toggle/toggle';
import {
  PAGE_ID_HOME,
  PAGE_ID_PLP,
  PAGE_ID_SRP,
  PAGE_ID_PDP,
  PAGE_ID_CAT_TMPL,
  domLoadCompleted,
  PAGE_ID_CONF,
  PAGE_ID_PLA,
} from 'shared/actions/actions-page';
import ContentGlobal from 'bgo-common/client/cms/components/contentGlobal/contentGlobal';
import DownloadappBanner from '@bgo-ui/common/components/DownloadappBanner/banner';
import { configureAmplify } from '@bgo-ui/common/client/utilities/utilities-amplify';
import { createCookiesFromAbTests } from 'client-utils/utilities-abtest';
import { onModalAnchorClick } from 'shared/components/App/utilities';
import NavSliderContianer from '@bgo-ui/common/components/Navigation/NavSlider/navSliderContainer';
import { ECMParams } from 'ecm/constants';
import { getThPageHtml } from '../actions';
import './app-th-wrapper.scss';
import { openSignInModal } from '../../login/actions';

const Modal = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'modal' */ 'shared/components/Modal/modal'),
  loading: () => false,
});

export class ThematicPage extends Component {
  constructor() {
    super();
    this.handleModalAnchorClick = this.handleModalAnchorClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { location, isThematicToggle } = this.props;
    if (!isEmpty(get(location, `query.${ECMParams.PARAM_LAUNCH}`, ''))) {
      reactCookie.save(
        ECMParams.PARAM_LAUNCH,
        get(location, `query.${ECMParams.PARAM_LAUNCH}`),
        { path: '/' },
      );
    }
    this.handleClickOutsideFocus = this.handleClickOutsideFocus.bind(this);
    if (this.props.haveAppSetDomLoadComplete) {
      this.handleLoad = this.handleLoad.bind(this);
    }
    if (!isThematicToggle) {
      this.props.getThPageHtml();
    } else if (shouldLoad(this.props.thHtmlApi)) {
      this.props.getThPageHtml(this.props.params.thPageParam);
    }
  }

  componentDidMount() {
    if (this.props.dtSignInEnabled) {
      configureAmplify(
        this.props.amplifyConfig,
        this.props.isNewCartCheckoutEnabled,
        this.props.disableATGToggle,
      );
    }

    addEventListener('click', this.handleModalAnchorClick);
    if (this.props.haveAppSetDomLoadComplete) {
      addEventListener('load', this.handleLoad);
    }

    if (this.props.useInternalAbTestService) {
      createCookiesFromAbTests(this.props.abTestResults);
    }
  }

  componentWillUnmount() {
    removeEventListener('click', this.handleModalAnchorClick);
  }

  handleModalAnchorClick(e) {
    onModalAnchorClick(e, this.props);
  }

  handleClickOutsideFocus(e, currentComponentInFocus) {
    const component = this.container.querySelector(currentComponentInFocus);
    if (component && !component.contains(e.target)) {
      this.props.dispatch(loseFocus(currentComponentInFocus));
    }
  }

  handleLoad() {
    this.props.dispatch(domLoadCompleted());
    removeEventListener('load', this.handleLoad);
  }

  render() {
    const {
      isSliderMenuVisible,
      componentInFocus,
      modal = {},
      location = {},
      pageId = '',
      thematicHtml,
      assistanceToggle,
      storesToggle,
      sitemapToggle,
      showCCPALink,
      specialOffersToggle,
      isBgoUiResourceFooter,
      thHeader,
      oneTrustScriptToggle,
      loyaltyToggle,
      loyaltyTLToggle,
    } = this.props;

    const styles = isSliderMenuVisible
      ? {
          height: '87vh',
          overflow: 'hidden',
          position: 'relative',
          top: 0,
          left: 0,
        }
      : {};
    const outsideClickHandler = componentInFocus
      ? e => this.handleClickOutsideFocus(e, componentInFocus)
      : undefined;
    const isFullWidth =
      this.props.forceFullWidth ||
      pageId === PAGE_ID_HOME ||
      pageId === PAGE_ID_CONF ||
      pageId === PAGE_ID_PLP ||
      pageId === PAGE_ID_PDP ||
      (this.props.showPla && pageId === PAGE_ID_PLA) ||
      (this.props.isSRPFullWidth && pageId === PAGE_ID_SRP) ||
      pageId === PAGE_ID_CAT_TMPL;
    const isConfPage = pageId === PAGE_ID_CONF;
    const isPlpPage = pageId === PAGE_ID_PLP;
    const useCMSTicker =
      this.props.isSiteTickerAvailable && !isEmpty(this.props.cmsGlobal);

    return (
      <div id="appContainer">
        <ScreenReaderAnnouncer />
        <IdleTimerWrapper />
        <div
          id="contentContainer"
          className="application-container"
          ref={ref => {
            this.container = ref;
          }}
          onClick={outsideClickHandler}
          onTouchEnd={outsideClickHandler}
          aria-hidden={modal.open || isSliderMenuVisible}
          tabIndex="-1"
        >
          <DownloadappBanner />
          <SkipToContent />
          <div
            className={`grid-container full-bleed ${
              useCMSTicker ? 'cms-siteticker' : 'ecm-siteticker'
            }`}
          >
            {useCMSTicker ? (
              <ContentGlobal
                cmsContent={this.props.cmsGlobal}
                placement="SiteTicker"
              />
            ) : (
              <Toggle
                feature="ECM_SITETICKER"
                fallback={<PromoCarousel tabIndex={0} />}
              >
                <EcmGet ecmSlot={ECMSlots.SITE_TICKER} />
              </Toggle>
            )}
          </div>
          <Header />
          <Toggle
            feature="HIDE_MAIN_NAV"
            fallback={<Navigation router={this.props.router} />}
          >
            {false}
          </Toggle>

          <Helmet>
            {
              <title>
                {`${location.pathname
                  .split('-th.html')[0]
                  .substring(1)
                  .split('-')
                  .map(capitalize)
                  .join(' ')} | bergdorfgoodman.com`}
              </title>
            }
            {renderHTML(thHeader)}
          </Helmet>

          <div
            className={classnames('grid-container full-width-container', {
              'mainContent-large grid-parent': isFullWidth,
              'plp-full-width': isPlpPage,
              'configurator-full-style': isConfPage,
            })}
          >
            <div
              style={styles}
              dangerouslySetInnerHTML={{ __html: thematicHtml }}
            />
            <Spinner />
          </div>
          {!isBgoUiResourceFooter ? (
            <OldFooter isFullWidth showCCPALink={showCCPALink} />
          ) : (
            <Footer
              configData={{ ...this.props, isFullWidth }}
              toggles={{
                assistanceToggle,
                storesToggle,
                sitemapToggle,
                showCCPALink,
                specialOffersToggle,
                oneTrustScriptToggle,
                loyaltyToggle,
                loyaltyTLToggle,
              }}
              openSignInModal={() => this.props.dispatch(openSignInModal())}
            />
          )}
        </div>
        <Modal />
        <NavSliderContianer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const thematicHtmlData = get(state, 'thematicHtml.thHtmlData', '');
  const thHeaderData = get(state, 'thematicHtml.thHeader', '');

  return {
    pageId: get(state, 'page.pageId', ''),
    isSliderMenuVisible: get(
      state,
      'navigation.navSlider.sliderMenuVisible',
      false,
    ),
    componentInFocus: get(state, 'app.componentInFocus', ''),
    modal: get(state, 'modal', {}),
    showPla: get(state, 'toggles.SHOW_PLA', false),
    isSRPFullWidth: get(state, 'toggles.SRP_FULL_WIDTH', false),
    haveAppSetDomLoadComplete: get(
      state,
      'toggles.HAVE_APP_SET_DOMLOADCOMPLETE',
      false,
    ),
    useInternalAbTestService: get(
      state,
      'toggles.USE_INTERNAL_ABTEST_SERVICE',
      false,
    ),
    abTestResults: get(state, 'abTests.abTestResults', {}),
    isFullWidth: get(state, 'toggles.HOMEPAGE_FULL_WIDTH', false),
    showCCPALink: get(state, 'toggles.BG_PHASE_2_CCPA_LINK', false),
    dtSignInEnabled: get(state, 'toggles.DT_SIGN_IN', false),
    amplifyConfig: get(state, 'amplifyConfig', {}),
    cmsGlobal: get(state, 'cms.global', []),
    isSiteTickerAvailable: get(state, 'toggles.CMS_SITE_TICKER', false),
    isThematicToggle: get(state, 'toggles.THEMATIC', false),
    thHtmlApi: state.api.th_html,
    thematicHtml: thematicHtmlData ? JSON.parse(thematicHtmlData).trim() : '',
    assistanceToggle: state.toggles.NEW_ASSISTANCE_UI,
    storesToggle: state.toggles.NEW_STORES_UI,
    sitemapToggle: state.toggles.DT_SiteMap,
    specialOffersToggle: state.toggles.DT_SPECIAL_OFFERS,
    isBgoUiResourceFooter: state.toggles.RESOURCE_FOOTER,
    thHeader: thHeaderData ? JSON.parse(thHeaderData).trim() : '',
    disableATGToggle: state?.toggles?.DISABLE_ATG_CALLS || false,
    oneTrustScriptToggle: state?.toggles?.ONE_TRUST_SCRIPT_BG || false,
    loyaltyToggle: state?.toggles?.LOYALTY || false,
    loyaltyTLToggle: state?.toggles?.BGO_LOYALTY_NEW || false,
  };
};

const mapDispatchToProps = {
  getThPageHtml,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ThematicPage);
