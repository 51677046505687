import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import './HowIncircleWorks.scss';
import Copy from '../../../../common/Copy/Copy';

const HowIncircleWorks = ({ myAccountContent }) => {
  return (
    <Fragment>
      {!isEmpty(myAccountContent) && (
        <div className="incircle-content">
          <Copy
            content={get(myAccountContent, 'CREDITCARD-INCIRCLE-DEFAULT')}
          />
          <div className="incircle-content__cta">
            <Copy
              content={get(myAccountContent, 'CREDITCARD-INCIRCLE-LEARN-CTA')}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  myAccountContent: get(state, 'myaccount.myAccountContent', true),
});

export default connect(mapStateToProps)(HowIncircleWorks);
