import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { isNewCartCheckoutEnabled } from 'client-utils/is-new-cart-checkout';
import { editMyAccountAddress } from '../../AddressBookPage/actions';
import NoPayment from '../NoPayment/NoPayment';
import MyAccountMobileBackHeader from '../../MyAccountPage/MyAccountBackHeader/MyAccountMobileBackHeader';
import PaymentDetails from '../PaymentDetails/PaymentDetails';
import PaymentBillingInfo from './PaymentBillingInfo';

const addressType = 'billing';
const paymentDetails = 'Payment Details';
const editPaymentDetails = 'Edit Payment Method';
class PaymentInfo extends Component {
  constructor() {
    super();
    this.state = {
      isEdit: false,
      paymentId: '',
      addressId: '',
    };
    this.editDetails = this.editDetails.bind(this);
    this.updateAddress = this.updateAddress.bind(this);
  }

  editDetails(isEdit, paymentId = '', addressId = '') {
    this.setState({ isEdit, paymentId, addressId });
  }

  updateAddress(payload) {
    const selectedAddressId = this.state.addressId;
    const shouldChangeFlag =
      payload.addressType === 'shipping' && this.props.isNewCartCheckoutEnabled;
    this.props.editMyAccountAddress(
      shouldChangeFlag ? { ...payload, flgAddressStandardized: '0' } : payload,
      selectedAddressId,
    );
    this.editDetails(false);
  }

  render() {
    const { payments } = this.props;
    const { isEdit, paymentId, addressId } = this.state;
    const title = isEdit ? editPaymentDetails : paymentDetails;
    return (
      <Fragment>
        <MyAccountMobileBackHeader title={title} />
        <h1 className="payment-details__header">{title}</h1>
        {isEmpty(payments) && (
          <NoPayment
            content={
              this.props.myAccountContent
                ? this.props.myAccountContent['PAYMENTDETAILS-DEFAULT']
                : null
            }
          />
        )}
        {payments.length > 0 &&
          (!isEdit ? (
            <PaymentBillingInfo
              payments={payments}
              editDetails={this.editDetails}
            />
          ) : (
            <PaymentDetails
              paymentId={paymentId}
              addressId={addressId}
              addressType={addressType}
              onSave={this.updateAddress}
              onCancel={this.editDetails}
            />
          ))}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  myAccountAddress: get(state, 'myaccount.myAccountAddress', false),
  myAccountContent: get(state, 'myaccount.myAccountContent', false),
  isNewCartCheckoutEnabled: isNewCartCheckoutEnabled(state),
});

const mapDispatchToProps = {
  editMyAccountAddress,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentInfo);
