export const NO_RESULTS_FOR_FILTER = 'NO_RESULTS_FOR_FILTER';
export const LOADING_PRODUCT = 'LOADING_PRODUCT';
export const RESOLVED_PRODUCT_LIST = 'RESOLVED_PRODUCT_LIST';
export const SET_INSTORE_FILTER_ERROR = 'SET_INSTORE_FILTER_ERROR';
export const RESET_INSTORE_FILTER_ERROR = 'RESET_INSTORE_FILTER_ERROR';
export const STS_LOADING_PRODUCT = 'STS_LOADING_PRODUCT';
export const STS_RESOLVED_PRODUCT = 'STS_RESOLVED_PRODUCT';
export const STS_REJECTED_PRODUCT = 'STS_REJECTED_PRODUCT';
export const RESOLVED_STS_CLICKED_PRODUCT = 'RESOLVED_STS_CLICKED_PRODUCT';
export const STS_MODAL_OPEN = 'STS_MODAL_OPEN';
export const STS_SEE_MORE = 'SEE_MORE';
export const ALTERNATIVE_PRODUCT = 'ALTERNATIVE_PRODUCT';
export const SUPER_GRID_DEFAULT_ANALYTICS = 'SUPER_GRID_DEFAULT_ANALYTICS';
export const SUPER_GRID_3 = 'SUPER_GRID_3';
export const SUPER_GRID_5 = 'SUPER_GRID_5';
export const SET_LSS_ANALYTICS = 'SET_LSS_ANALYTICS';
