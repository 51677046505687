import React from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'client/utilities/utilities-page';
import '../Header/AccountHeaderMenuItem/AccountHeaderMenuItem.scss';

export const sanitizeUserName = userName => {
  const capitalizeString = s => s[0].toUpperCase() + s.substr(1);
  const restrictToElevenChars = s => s.substr(0, 11);
  return capitalizeString(restrictToElevenChars(userName));
};

const SignOutLink = ({
  usersFirstName,
  signOutClassName,
  disableATGToggle,
}) => {
  const removeWkyCardsInSessionStorage = () => {
    if (window && window.resetWkyExperience) {
      window.resetWkyExperience();
    }
  };
  return (
    <li>
      <div className="horizontal-rule" />
      <div className="loggedin-user-signout-link">
        <a
          tabIndex="0"
          href={!disableATGToggle ? '/profile.service?action=logout' : '/'}
          className={signOutClassName}
          onClick={removeWkyCardsInSessionStorage}
        >
          Not {sanitizeUserName(usersFirstName)}? Sign Out
        </a>
      </div>
    </li>
  );
};

const mapState = (state, ownProps) => ({
  usersFirstName: ownProps.usersFirstName,
});

const ConnectedSignOutLink = connect(mapState)(SignOutLink);

const LoggedInUserOrderHistoryLink = ({ orderHistoryClassName }) => (
  <a
    className={orderHistoryClassName}
    href={`${NMConfig.ACCOUNT_PAGE_URL}?view=history&list=default&forOmniLN=2`}
  >
    Order History
  </a>
);

const GuestUserOrderHistoryLink = ({ orderHistoryClassName }) => (
  <a
    className={orderHistoryClassName}
    href="/orderhistory/orderLookup.jsp?view=guestOrderHistory"
  >
    Order History
  </a>
);
// eslint-disable-next-line no-unused-vars
export const AccountMenuItems = ({
  authenticatedUserName,
  mobileClass,
  ulClass,
  isFullWidth,
  disableATGToggle,
}) => (
  <ul className={ulClass}>
    {!isMobile() && (
      <li>
        <span className="account-menu-title">Your Account</span>
      </li>
    )}
    <li>
      <a
        className={mobileClass}
        href={`${NMConfig.ACCOUNT_PAGE_URL}?fromLN=myNMNav&forOmniLN=2`}
      >
        Overview
      </a>
    </li>
    <li>
      {authenticatedUserName ? (
        <LoggedInUserOrderHistoryLink orderHistoryClassName={mobileClass} />
      ) : (
        <GuestUserOrderHistoryLink orderHistoryClassName={mobileClass} />
      )}
    </li>
    <li>
      <a
        className={mobileClass}
        href={`${NMConfig.ACCOUNT_PAGE_URL}?view=address&fromLN=myNMNav&forOmniLN=2`}
      >
        Address Book
      </a>
    </li>
    <li>
      <a
        className={mobileClass}
        href={`${NMConfig.ACCOUNT_PAGE_URL}?view=pay&fromLN=myNMNav&forOmniLN=2`}
      >
        Payment Information
      </a>
    </li>
    <li>
      <a className={mobileClass} href="/BG/BG-Credit-Card/cat289401/c.cat">
        BG Credit Card
      </a>
    </li>
    {authenticatedUserName ? (
      <ConnectedSignOutLink
        signOutClassName={mobileClass}
        usersFirstName={authenticatedUserName}
        disableATGToggle={disableATGToggle}
      />
    ) : (
      undefined
    )}
  </ul>
);
