import React from 'react';
import reactCookie from 'react-cookie';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { redirect } from 'client-utils/utilities-page';
import CountrySelector from '../../../CountrySelector/countrySelector';
import DisplayMyFavorites from '../../../Profile/DisplayMyFavorites';

import './myAccount.scss';

const userAccountInfos = [
  { title: 'Overview', url: `${NMConfig.ACCOUNT_PAGE_URL}?fromLN=myNMNav` },
  {
    title: 'Order History',
    url: `${NMConfig.ACCOUNT_PAGE_URL}?view=history&list=default&fromLN=myNMNav`,
  },
  {
    title: 'Address Book',
    url: `${NMConfig.ACCOUNT_PAGE_URL}?view=address&fromLN=myNMNav`,
  },
  {
    title: 'Payment Info',
    url: `${NMConfig.ACCOUNT_PAGE_URL}?view=pay&fromLN=myNMNav`,
  },
];

const DisplayUserFirstName = ({ firstName, disableATGToggle }) => {
  const handleLogoutFooter = () => {
    if (window && window.resetWkyExperience) {
      window.resetWkyExperience();
    }
    redirect(!disableATGToggle ? '/profile.service?action=logout' : '/');
  };
  if (!firstName) {
    return false;
  }
  const maxLengthOfUsernameToDisplay = 11;
  const username =
    firstName.length > maxLengthOfUsernameToDisplay
      ? firstName.substr(0, maxLengthOfUsernameToDisplay)
      : firstName;
  return (
    <li>
      <div className="footer-myaccount-section__myaccount-username">
        Not {username}?
      </div>
      <button
        onTouchEnd={handleLogoutFooter}
        onClick={handleLogoutFooter}
        className="footer-myaccount-section__myaccount-signout"
      >
        SIGN OUT
      </button>
    </li>
  );
};

const ConnectedDisplayUserFirstName = connect(() => ({}))(DisplayUserFirstName);

function MyAccount({ firstName, isDomestic, disableATGToggle }) {
  const specialoffers = { title: 'Special Offers', url: '/specialoffers' };
  const userAccInfov2 = [...userAccountInfos];
  if (isDomestic) {
    userAccInfov2.push(specialoffers);
  }
  return (
    <div className="mobile-only footer-myaccount-section">
      <h3>My Account</h3>
      <ul>
        <li>
          <DisplayMyFavorites
            favMessageClass="footer-myaccount-section__link"
            favIconClass="footer-myaccount-section__myfavorites-icon"
          />
        </li>
        {userAccInfov2.map((userAccountInfo, index) => (
          <li key={index}>
            <a
              href={userAccountInfo.url}
              className="footer-myaccount-section__link"
            >
              {userAccountInfo.title}
            </a>
          </li>
        ))}
        <CountrySelector />
        <ConnectedDisplayUserFirstName
          firstName={firstName}
          disableATGToggle={disableATGToggle}
        />
      </ul>
    </div>
  );
}

const mapStateToProps = state => {
  const profileDataCookie = reactCookie.load('profile_data');
  const firstName = profileDataCookie ? profileDataCookie.firstName : undefined;
  const isDomestic =
    get(state, 'locale.countryCode') === 'US' ||
    get(state, 'locale.countryCode') === 'Unites_States';
  const disableATGToggle = get(state, 'toggles.DISABLE_ATG_CALLS', false);
  return {
    firstName,
    isDomestic,
    disableATGToggle,
  };
};

export default connect(mapStateToProps)(MyAccount);
