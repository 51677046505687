import React from 'react';
import { BodyB1, Subtitle1 } from 'bgo-common/Styleguide/Typography';
import './footerLinks.scss';
import FooterRightContainer from './FooterRightContainer';

const DomesticFooter = ({ footerLinks }) => (
  <div className="footer-content-container__footer-links">
    <div className="footer-links-container grid-100">
      <div className="grid-50 mobile-grid-100 tablet-grid-50 footer-links-left-content">
        <div className="footer-links-left">
          {footerLinks.data.map((links, id) => (
            <div className="menu grid-50 tablet-grid-50" key={id}>
              <Subtitle1>{links.title}</Subtitle1>
              <ul>
                {links.content.map((link, id) => (
                  <li key={id}>
                    <a href={link.url}>
                      <BodyB1>{link.label}</BodyB1>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <FooterRightContainer />
    </div>
  </div>
);

export default DomesticFooter;
