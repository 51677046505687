import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { isLoadingFinished } from '@bgo-ui/common/client/utilities/utilities-api';
import Copy from '../../../../common/Copy/Copy';
import { policyUri } from '../../../constants';

import './TermsDisclaimer.scss';

const DumbTermsDisclaimer = props => {
  return (
    <div className="login-register-terms-disclaimer">
      <Copy
        content={get(props.loginContent, 'USERLOGIN-DESKTOP-T&C')}
        loaded={props.isContentLoaded}
        fallback={(
          <React.Fragment>
            By continuing, you agree to our{' '}
            <a href={policyUri} target="_blank" rel="noreferrer noopener">
              Terms and Conditions
            </a>
            {' & '}
            <a href={policyUri} target="_blank" rel="noreferrer noopener">
              Privacy Policy
            </a>
          </React.Fragment>
        )}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  loginContent: get(state, 'login.loginContent', {}),
  isContentLoaded: isLoadingFinished(state, 'login_content'),
});

const TermsDisclaimer = connect(mapStateToProps)(DumbTermsDisclaimer);

export default TermsDisclaimer;
