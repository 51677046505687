export const ACTION_TYPES = {
  LOADING_PROCESS_EMAIL: 'LOADING_PROCESS_EMAIL',
  REJECTED_PROCESS_EMAIL: 'REJECTED_PROCESS_EMAIL',
  PROCEED_TO_SIGN_IN: 'PROCEED_TO_SIGN_IN',
  PROCEED_TO_SIGN_UP: 'PROCEED_TO_SIGN_UP',
  CLEAR_PROCESS_EMAIL_ERROR: 'CLEAR_PROCESS_EMAIL_ERROR',
  EDIT_EMAIL: 'EDIT_EMAIL',
  CLOSE_APPLY_NOW_MODAL: 'CLOSE_APPLY_NOW_MODAL',
};

export const MODAL_CONTENT_TYPES = {
  ENTER_EMAIL: 'ENTER_EMAIL',
  SIGN_IN: 'SIGN_IN',
  SIGN_UP: 'SIGN_UP',
};

export const ALLOWED_STATUS_TYPES = {
  EMAIL_ALREADY_EXISTS: 'UX_2000',
  EMAIL_DOESNT_EXISTS: 'UX_2001',
};

export const enterEmailContent = {
  title: 'Sign In or Create an Account',
  desc:
    "Before you start your credit card application, please sign into your account. If you don't have an account with us, you'll create one as part of this application. Please enter your email address.",
  error: 'The supplied email address is incorrect.',
};

export const signInContent = {
  title: 'Welcome Back',
  passTitle: 'Please enter your password.',
  error: 'The supplied password is incorrect.',
  signInError: 'The supplied email address or password is incorrect.',
};

export const signUpContent = {
  title: 'Create an Account',
};

export const commonContent = {
  email: 'Email',
  edit: 'Edit',
  serverError: 'Something went wrong, Please try again',
};
