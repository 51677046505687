import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import CloseIcon from '@bgo-ui/common/client/common/components/CloseIcon/closeIcon';
import { closeModal } from '@bgo-ui/common/client/common/components/Modal/actions';
import './ChangePasswordModal.scss';
import ChangePasswordForm from './ChangePasswordForm/ChangePasswordForm';
import ChangePasswordConfirmation from './ChangePasswordConfirmation/ChangePasswordConfirmation';
import ChangePasswordSuccess from './ChangePasswordSuccess/ChangePasswordSuccess';

const DumbChangePasswordModal = props => {
  let modalContent;
  if (props.changePasswordSuccess) {
    modalContent = <ChangePasswordSuccess />;
  } else {
    modalContent = props.verificationCodeSent ? (
      <ChangePasswordForm />
    ) : (
      <ChangePasswordConfirmation />
    );
  }

  const handleCloseClick = e => {
    props.closeModal();
    e.preventDefault();
  };

  return (
    <div className="change-password-modal">
      <CloseIcon onClick={handleCloseClick} />
      <div className="change-password-modal__content-wrapper">
        <div className="change-password-modal__content">
          <h1 className="change-password-modal__header">Change password</h1>
          {modalContent}
        </div>
      </div>
    </div>
  );
};

DumbChangePasswordModal.propTypes = {
  verificationCodeSent: PropTypes.bool.isRequired,
  changePasswordSuccess: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  verificationCodeSent: get(
    state,
    'myaccount.myAccountDetails.verificationCode.success',
    false,
  ),
  changePasswordSuccess: get(
    state,
    'myaccount.myAccountDetails.changePasswordSuccess',
    false,
  ),
});

const mapDispatchToProps = {
  closeModal,
};

const ChangePasswordModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbChangePasswordModal);

export default ChangePasswordModal;
