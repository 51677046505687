export const DESIGNER_CAT_ID = 'cat000001';
export const CUSP_CAT_ID = 'cat58930763';
export const UNITED_STATES = 'US';
export const CHINA = 'CN';
export const DESKTOP_DEVICE_TYPE = 'desktop';
export const MOBILE_DEVICE_TYPE = 'mobile';
export const CANADA = 'CA';
export const GIFTS_CATEGORY_NAME = 'Home';
export const LEFT_NAV = 'leftNav';
export const BG_PROD_URL = 'https://www.bergdorfgoodman.com';
export const BG_DEVINT_URL = 'https://devint.bergdorfgoodman.com';
export const BG_STAGE_URL = 'https://stage.bergdorfgoodman.com';
