import React, { Component } from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { validateEmail } from '@bgo-ui/common/client/utilities/utilities-amplify';
import { RegisterInputField } from '../../../common/RegisterInputField/RegisterInputField';
import {
  getRequiredFieldError,
  invalidEmailError,
  loadingStatus,
  somethingWentWrongError,
} from '../../constants';
import { sendRsvp } from '../../actions';

const isBlank = str => {
  return !str || str.trim().length === 0;
};

const fields = {
  firstName: {
    name: 'firstName',
    title: 'First Name*',
  },
  lastName: {
    name: 'lastName',
    title: 'Last Name*',
  },
  email: {
    name: 'email',
    title: 'Email Address*',
  },
  phoneNumber: {
    name: 'phoneNumber',
    title: 'Phone Number (optional)',
  },
};

export class DumbBGxHamptonsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
      },
      submitted: false,
      validEmail: false,
    };
  }

  onSubmit = e => {
    e.preventDefault();

    const validations = this.validate();
    if (!Object.values(validations).includes(false)) {
      const { values } = this.state;

      this.props.sendRsvp({
        name: `${values.firstName} ${values.lastName}`,
        email: values.email,
        phonenumber: values.phoneNumber,
        services: 'bgxhamptons',
      });
    }
    return this.setState({ submitted: true });
  };

  validate = () => {
    const { values, validEmail } = this.state;

    return {
      firstNameIsNotBlank: !isBlank(values.firstName),
      lastNameIsNotBlank: !isBlank(values.lastName),
      emailIsNotBlank: !isBlank(values.email),
      emailIsValid: validEmail,
    };
  };

  handleChange = (e, isEmail) => {
    if (isEmail) {
      this.setState({ validEmail: validateEmail(e.target.value) });
    }
    this.setState({
      values: {
        ...this.state.values,
        [e.target.name]: e.target.value,
      },
    });
  };

  render() {
    const { values, submitted } = this.state;
    const { rsvpFormLoadingStatus } = this.props;

    const validations = this.validate();
    const emailErrorMessage = () => {
      if (submitted) {
        if (!validations.emailIsNotBlank) {
          return getRequiredFieldError('Email Address');
        }
        if (!validations.emailIsValid) {
          return invalidEmailError;
        }
      }
      return null;
    };

    const firstNameError =
      submitted &&
      !validations.firstNameIsNotBlank &&
      getRequiredFieldError('First Name');

    const lastNameError =
      submitted &&
      !validations.lastNameIsNotBlank &&
      getRequiredFieldError('Last Name');

    return (
      <form>
        <RegisterInputField
          title={fields.firstName.title}
          name={fields.firstName.name}
          error={firstNameError}
          maxLength={40}
          value={values.firstName}
          onChange={this.handleChange}
        />

        <RegisterInputField
          title={fields.lastName.title}
          name={fields.lastName.name}
          error={lastNameError}
          maxLength={40}
          value={values.lastName}
          onChange={this.handleChange}
        />

        <RegisterInputField
          title={fields.email.title}
          name={fields.email.name}
          error={emailErrorMessage()}
          maxLength={40}
          value={values.email}
          onChange={e => this.handleChange(e, 'email')}
        />

        <RegisterInputField
          title={fields.phoneNumber.title}
          name={fields.phoneNumber.name}
          maxLength={40}
          value={values.phoneNumber}
          onChange={this.handleChange}
        />

        {rsvpFormLoadingStatus === loadingStatus.rejected && (
          <p className="account-registration__callback-error">
            {somethingWentWrongError}
          </p>
        )}

        <button
          type="submit"
          className="account-registration__button"
          onClick={this.onSubmit}
        >
          Submit RSVP
        </button>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  rsvpFormLoadingStatus: get(state, 'bgxHamptons.rsvpFormLoadingStatus', null),
});

const mapDispatchToProps = {
  sendRsvp,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbBGxHamptonsForm);
