import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import { unescape } from 'he';
import { mark, measure } from 'client/utilities/utilities-performance';
import getProduct from 'pdp/components/ProductPage/selectors/getProduct';
import greyFavIcon from 'bgo-common/assets/images/favorites_up.svg';
import selectedFavIcon from 'bgo-common/assets/images/favorites_selected.svg';
import favOverIcon from 'bgo-common/assets/images/favorites_over.svg';
import videoIcon from 'bgo-common/assets/images/video-grey.svg';
import classNames from 'classnames';
import {
  setProductVideo,
  toggleFavorite,
  unsetProductVideo,
  zoomProductImage,
} from 'pdp/components/ProductPage/actions';
import { addBodyClass } from 'client-utils/bodyClass';
import screenReaderAnnouncer from 'bgo-common/components/ScreenReaderAlert/screenReaderAnnouncer-actions';
import Image, {
  NO_IMAGE_AVAILABLE_CDN,
  NO_IMAGE_AVAILABLE_LARGE_CDN,
} from 'pdp/components/ProductPage/components/Image/image';
import Toggle from 'shared/components/Toggle/toggle';
import { isTouchDevice } from 'client-utils/utilities-page';
import ProductVideo from '../ProductVideo/productVideo';
import './mainMedia.scss';
import { getDynamicImageURLForCustomizedProduct } from '../../../Monogram/dynamicImageUtils/getDynamicScene7ImageURL';

export class DumbMainMedia extends Component {
  constructor(props) {
    super();
    mark('product image (construct)');
    this.handleZoomClick = this.handleZoomClick.bind(this);
    this.toggleVideo = this.toggleVideo.bind(this);
    this.toggleFavoriteStatus = this.toggleFavoriteStatus.bind(this);
    this.handlerVideoHoverOn = this.handlerVideoHoverOn.bind(this);
    this.handlerVideoHoverOff = this.handlerVideoHoverOff.bind(this);
    this.handlerZoomHoverOn = this.handlerZoomHoverOn.bind(this);
    this.handlerZoomHoverOff = this.handlerZoomHoverOff.bind(this);
    this.handlerFavoriteHoverOn = this.handlerFavoriteHoverOn.bind(this);
    this.handlerFavoriteHoverOff = this.handlerFavoriteHoverOff.bind(this);
    this.state = {
      zoomHover: false,
      videoHover: false,
      isFavorite: props.isFavorite,
      favorite: props.isFavorite
        ? { icon: selectedFavIcon }
        : { icon: greyFavIcon },
      favText: 'favorites',
    };
  }

  componentDidMount() {
    measure('product image (TTI)');
    measure('product image (self render time)', 'product image (construct)');
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { activeIndex, isFavorite, favAddRemoveStatus } = nextProps;
    const isSameIndex = activeIndex === this.props.activeIndex;
    if (this.slider && !isSameIndex) this.slider.slickGoTo(activeIndex);

    if (favAddRemoveStatus === 'error') {
      return;
    }

    if (favAddRemoveStatus !== this.props.favAddRemoveStatus) {
      if (!this.state.isFavorite) {
        this.props.screenReaderAnnouncer('Added');
      } else {
        this.props.screenReaderAnnouncer('Removed');
      }
    }

    if (favAddRemoveStatus && favAddRemoveStatus !== '') {
      this.setFavoriteStatus(favAddRemoveStatus === 'favorite');
      return;
    }

    this.setFavoriteStatus(isFavorite);
  }

  setFavoriteStatus(status) {
    this.setState({
      isFavorite: status,
      favorite: status ? { icon: selectedFavIcon } : { icon: greyFavIcon },
      favText: 'favorites',
    });
  }

  handlerFavoriteHoverOn() {
    if (!this.state.isFavorite && !isTouchDevice()) {
      this.setState({
        favorite: { ...this.state.favorite, icon: favOverIcon },
        favText: 'add',
      });
    }
  }

  handlerFavoriteHoverOff() {
    if (!this.state.isFavorite && !isTouchDevice()) {
      this.setState({
        favorite: { ...this.state.favorite, icon: greyFavIcon },
        favText: 'favorites',
      });
    }
  }

  handlerVideoHoverOn() {
    this.setState({ videoHover: true });
  }

  handlerVideoHoverOff() {
    this.setState({ videoHover: false });
  }

  handlerZoomHoverOn() {
    this.setState({ zoomHover: true });
  }

  handlerZoomHoverOff() {
    this.setState({ zoomHover: false });
  }

  toggleFavoriteStatus() {
    const { product, toggleFavorite } = this.props;
    const {
      id,
      metadata: { cmosCatalogId, cmosItem, pimStyle },
    } = product;

    toggleFavorite(
      { id, cmosCatalogId, cmosItem, pimStyle },
      this.state.isFavorite,
    );
  }

  toggleVideo() {
    const { videoActive, setProductVideo, unsetProductVideo } = this.props;
    videoActive ? unsetProductVideo() : setProductVideo();
  }

  handleZoomClick() {
    const {
      images,
      activeIndex,
      unsetProductVideo,
      zoomProductImage,
    } = this.props;
    unsetProductVideo();
    addBodyClass('zoom-open');
    zoomProductImage(images, activeIndex);
  }

  render() {
    const {
      name,
      images = [],
      video = {},
      activeIndex = 0,
      showSoldOutOverlay = false,
      videoActive = false,
      unsetProductVideo,
      dots,
      arrows,
      product,
      imageInfiniteScrollToggle,
    } = this.props;

    const settings = {
      accessibility: true,
      arrows: images.length > 1 && arrows,
      dots: images.length > 1 && dots,
      swipe: false,
      infinite: arrows,
      initialSlide: activeIndex,
      lazyLoad: true,
      fade: arrows,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            dots: images.length > 1,
            swipe: images.length > 1,
            infinite: imageInfiniteScrollToggle,
          },
        },
      ],
    };

    const isValidProductForDynamicImageUpdate = () => {
      return (
        product.isPersonalizationSelected &&
        product.productFlags.previewSupported
      );
    };

    const isPreviewSupported = () => {
      return product.productFlags && !product.productFlags.previewSupported;
    };
    if (images.length > 0 && isValidProductForDynamicImageUpdate()) {
      const thumbnailImageWidth = 75;
      const mediumImageWidth = 456;
      const largeImageWidth = 1200;
      const customizedDynamicImageUrl = getDynamicImageURLForCustomizedProduct(
        product,
      );
      images[0].thumbnail &&
        (images[0].thumbnail.url = `${customizedDynamicImageUrl}&wid=${thumbnailImageWidth}`);
      images[0].medium &&
        (images[0].medium.url = `${customizedDynamicImageUrl}&wid=${mediumImageWidth}`);
      images[0].large &&
        (images[0].large.url = `${customizedDynamicImageUrl}&wid=${largeImageWidth}`);
    }

    const { zoomHover, videoHover, isFavorite } = this.state;
    const zoomClassNames = classNames('main-media__zoom', 'controls-icon', {
      'main-media__zoom--hover': zoomHover,
    });
    const videoClassNames = classNames('main-media__play', 'controls-icon', {
      'main-media__play--hover': videoHover,
    });
    const favoriteClassNames = classNames(
      'main-media__favorite',
      'controls-icon',
    );

    const mainMediaMobile =
      images.length > 1
        ? 'main-media__with-dots--mobile'
        : 'main-media__without-dots--mobile';

    const mainMediaVideo = videoActive
      ? 'main-media__controls--video-active'
      : '';
    const mainMediaFavText = classNames(
      'main-media__controls-label',
      { 'main-media__fav-text--selected': isFavorite },
      { 'main-media__fav--hover': this.state.favText === 'add' },
    );

    const zoomIcon = (
      <button
        id={`mainMediaZoom_${product.id}`}
        className={zoomClassNames}
        onClick={this.handleZoomClick}
        onMouseEnter={this.handlerZoomHoverOn}
        onMouseOut={this.handlerZoomHoverOff}
      >
        <span
          aria-hidden="true"
          className="main-media__zoom-btn-img controls-img"
        />
        <span className="main-media__controls-label">zoom</span>
      </button>
    );

    const defaultVideo = get(video, 'video.url', false) && (
      <button className="main-media__play" onClick={this.toggleVideo}>
        <img
          aria-hidden="true"
          className="main-media__play-btn-img"
          src={videoIcon}
          alt="video icon"
        />
        <span>video</span>
      </button>
    );

    const mainMediaControls = (
      <div
        className={`main-media__controls ${
          videoActive ? 'main-media__controls--video-active' : ''
        }`}
      >
        <div className="center">
          {zoomIcon}
          {defaultVideo}
        </div>
      </div>
    );

    const mainImage = image => (
      <Image
        alt={unescape(name)}
        src={get(image, 'medium.url', NO_IMAGE_AVAILABLE_CDN)}
        onClick={this.handleZoomClick}
        timeout={this.props.productPageImageTimeout}
        quantumImageErrorToggle={this.props.quantumImageErrorToggle}
        isPdp
      />
    );

    return (
      <div className="main-media">
        {videoActive && (
          <ProductVideo video={video} unsetProductVideo={unsetProductVideo} />
        )}

        {!videoActive && (
          <div className="main-media__container">
            {showSoldOutOverlay && (
              <div className="main-media__overlay__soldout">
                <span>Sold Out</span>
              </div>
            )}
            {isPreviewSupported() && product.isPersonalizationSelected && (
              <div className="main-media__overlay__no-preview">
                <span>No Preview Available</span>
              </div>
            )}
            {!isEmpty(images) && (
              <Slider
                {...settings}
                ref={ref => {
                  this.slider = ref;
                }}
              >
                {images.map((image, index) => (
                  <div key={index}>
                    <Toggle
                      feature="LARGE_MAIN_IMAGE_PDP"
                      fallback={mainImage(image)}
                    >
                      <Image
                        alt={unescape(name)}
                        src={get(
                          image,
                          'large.url',
                          NO_IMAGE_AVAILABLE_LARGE_CDN,
                        )}
                        onClick={this.handleZoomClick}
                        timeout={this.props.productPageImageTimeout}
                        quantumImageErrorToggle={
                          this.props.quantumImageErrorToggle
                        }
                        isPdp
                      />
                    </Toggle>
                  </div>
                ))}
              </Slider>
            )}
          </div>
        )}
        {product.isZeroDollarProduct ? (
          mainMediaControls
        ) : (
          <div
            className={`main-media__controls center ${mainMediaVideo} ${mainMediaMobile}`}
          >
            {this.props.showAddToFavorites && (
              <button
                aria-label={
                  this.state.isFavorite
                    ? 'Remove from favorites'
                    : 'Add to favorites'
                }
                className={favoriteClassNames}
                onClick={this.toggleFavoriteStatus}
                onMouseEnter={this.handlerFavoriteHoverOn}
                onMouseLeave={this.handlerFavoriteHoverOff}
                onFocus={this.handlerFavoriteHoverOn}
                onBlur={this.handlerFavoriteHoverOff}
              >
                <img
                  className="main-media__fav-btn-img"
                  src={this.state.favorite.icon}
                  alt="favorite icon"
                />
                <span className={mainMediaFavText}>{this.state.favText}</span>
              </button>
            )}
            {zoomIcon}
            {get(video, 'video.url', false) && (
              <button
                className={videoClassNames}
                onClick={this.toggleVideo}
                onMouseEnter={this.handlerVideoHoverOn}
                onMouseOut={this.handlerVideoHoverOff}
              >
                <span className="main-media__play-btn-img controls-img" />
                <span className="main-media__controls-label">video</span>
              </button>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const product = getProduct(state, props.productId);
  const quantumImageErrorToggle = get(
    state,
    'toggles.QUANTUM_ERROR_IMAGE_REPORT',
    false,
  );
  const showAddToFavorites = get(state, 'toggles.PDP_FAVORITE');
  const imageInfiniteScrollToggle = get(
    state,
    'toggles.IMAGE_INFINITE_SCROLL',
    false,
  );
  return {
    videoActive: product.videoActive,
    isFavorite: product.isFavorite,
    product,
    favAddRemoveStatus: product.favAddRemoveStatus,
    productPageImageTimeout: state.productCatalog.productPageImageTimeout,
    quantumImageErrorToggle,
    showAddToFavorites,
    imageInfiniteScrollToggle,
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  zoomProductImage: (...args) => dispatch(zoomProductImage(productId, ...args)),
  setProductVideo: () => dispatch(setProductVideo(productId)),
  unsetProductVideo: () => dispatch(unsetProductVideo(productId)),
  toggleFavorite: (...args) => dispatch(toggleFavorite(...args)),
  screenReaderAnnouncer: (...args) => dispatch(screenReaderAnnouncer(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbMainMedia);
