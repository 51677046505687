import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { buildCookieString } from 'universal/utilities-cookies';
import httpWithLogging from 'universal/http-client';
import { findAndReplaceLocaleContextInHref } from 'client-utils/utilities-page';
import {
  UNITED_STATES,
  DESKTOP_DEVICE_TYPE,
  MOBILE_DEVICE_TYPE,
} from 'storefront/components/constants';
import logger from 'server/utilities/logger';
import { COMPONENT_CACHE_TTL } from 'bgo-common/components/constants';
// import { setCache, getCache } from 'server-utils/utilities-cache-manager';
import navigation from './fallback.json';

// import { API_CACHE_KEYS } from '../constants';
let cacheManager = {}; // require('server-utils/utilities-cache-manager');
if (!IS_CLIENT) {
  cacheManager = require('server-utils/utilities-cache-manager');
}
const navigationTTL = COMPONENT_CACHE_TTL;

export const types = {
  LOADING_NAVIGATION: 'LOADING_NAVIGATION',
  RESOLVED_NAVIGATION: 'RESOLVED_NAVIGATION',
  REJECTED_NAVIGATION: 'REJECTED_NAVIGATION',
  LOADING_NAVIGATION_MOBILE: 'LOADING_NAVIGATION_MOBILE',
  RESOLVED_NAVIGATION_MOBILE: 'RESOLVED_NAVIGATION_MOBILE',
  REJECTED_NAVIGATION_MOBILE: 'REJECTED_NAVIGATION_MOBILE',
  UPDATE_NAV_BAR: 'UPDATE_NAV_BAR',
  TOGGLE_NAV_SLIDER: 'TOGGLE_NAV_SLIDER',
  LOADING_BREADCRUMB_CONTENT: 'LOADING_BREADCRUMB_CONTENT',
  RESOLVED_BREADCRUMB_CONTENT: 'RESOLVED_BREADCRUMB_CONTENT',
  REJECTED_BREADCRUMB_CONTENT: 'REJECTED_BREADCRUMB_CONTENT',
  RESOLVED_NAVIGATION_SEGMENT_SAILO: 'RESOLVED_NAVIGATION_SEGMENT_SAILO',
  REJECTED_NAVIGATION_SEGMENT_SAILO: 'REJECTED_NAVIGATION_SEGMENT_SAILO',
};

const setCache = (name, data, ttl) => {
  if (!IS_CLIENT) {
    cacheManager.setCache(name, data, ttl);
  }
};

const getCache = name => {
  if (!IS_CLIENT) {
    return cacheManager.getCache(name);
  }
  return false;
};

export const getConfig = ({
  JSESSIONID = '',
  DYN_USER_ID = '',
  TLTSID = '',
  W2A = '',
}) => {
  return {
    headers: {
      Cookie: buildCookieString({
        JSESSIONID,
        DYN_USER_ID,
        TLTSID,
        W2A,
      }),
    },
  };
};

export const getCountryCodeAndLocaleUrl = state => {
  const countryCode =
    state.locale && state.locale.countryCode
      ? state.locale.countryCode
      : UNITED_STATES;
  const localeUrl =
    state.locale && state.locale.localeUrl ? state.locale.localeUrl : '';
  return { countryCode, localeUrl };
};

export function getSilosDesktop() {
  return (dispatch, getState) => {
    dispatch({ type: types.LOADING_NAVIGATION });
    const state = getState();
    const { session = {} } = state;
    const NODE_CLIENT_DEFAULT_TIMEOUT = 3000;
    const apiTimeouts = get(state, 'apiTimeouts', {});
    const NAV_API_TIMEOUT = get(
      apiTimeouts,
      'NAVIGATION_API_TIMEOUT',
      NODE_CLIENT_DEFAULT_TIMEOUT,
    );
    const isCmsDrawerAssets = get(state, 'toggles.CMS_DRAWER_ASSETS', false);
    const useApiKey = get(state, 'toggles.CONTENT_LAMBDA_API_KEY', false);
    const requestApi = httpWithLogging(state, NAV_API_TIMEOUT);
    const { countryCode, localeUrl } = getCountryCodeAndLocaleUrl(state);
    const config = getConfig(session);
    const startTime = new Date();
    const navCache =
      !state.toggles.CMS_DRAWER_ASSETS && getCache('desktop_nav_cache'); // cache mechanism needs refacoring in API
    if (navCache) {
      const endTime = new Date() - startTime;
      logger.info(`desktop navigation with cache=${endTime}`);
      return dispatch({
        type: types.RESOLVED_NAVIGATION,
        payload: transformSilosByCountryCode(navCache, countryCode, localeUrl),
      });
    }

    if (useApiKey && isCmsDrawerAssets) {
      config.headers['use-api-key'] = 'true';
    }

    return requestApi
      .get(getSilosApiUri(state, DESKTOP_DEVICE_TYPE), config)
      .then(successResponse => {
        if (isEmpty(successResponse.data.silos))
          throw new Error('Silos data is empty');
        const endTime = new Date() - startTime;
        logger.info(`desktop navigation without cache=${endTime}`);
        !state.toggles.CMS_DRAWER_ASSETS &&
          setCache(
            'desktop_nav_cache',
            successResponse.data.silos,
            navigationTTL,
          );
        dispatch({
          type: types.RESOLVED_NAVIGATION,
          payload: transformSilosByCountryCode(
            successResponse.data.silos,
            countryCode,
            localeUrl,
          ),
        });
      })
      .catch(() => {
        logger.info(
          'An error occurred in Navigation Service API and static json is being rendered.',
        );
        dispatch({
          type: types.REJECTED_NAVIGATION,
          payload: navigation.silos,
        });
      });
  };
}

export function transformSilosByCountryCode(silos, countryCode, localeUrl) {
  if (!countryCode || !localeUrl) return silos;
  const silosCopy = cloneDeep(silos);
  recursivelyTransformSilos(silosCopy, countryCode, localeUrl);
  return silosCopy;
}

function recursivelyTransformSilos(silos, countryCode, localeUrl) {
  if (isEmpty(silos)) return;
  silos.forEach(silo => {
    if (silo.url) {
      silo.url = findAndReplaceLocaleContextInHref(
        silo.url,
        localeUrl,
        countryCode,
        localeUrl,
      );
    }
    recursivelyTransformSilos(silo.categories, countryCode, localeUrl);
  });
}

export function getSilosMobileInitial() {
  return (dispatch, getState) => {
    dispatch({ type: types.LOADING_NAVIGATION_MOBILE });
    const state = getState();
    const NODE_CLIENT_DEFAULT_TIMEOUT = 3000;
    const apiTimeouts = get(state, 'apiTimeouts', {});
    const NAV_API_TIMEOUT = get(
      apiTimeouts,
      'NAVIGATION_API_TIMEOUT',
      NODE_CLIENT_DEFAULT_TIMEOUT,
    );
    const isCmsDrawerAssets = get(state, 'toggles.CMS_DRAWER_ASSETS', false);
    const useApiKey = get(state, 'toggles.CONTENT_LAMBDA_API_KEY', false);
    const requestApi = httpWithLogging(state, NAV_API_TIMEOUT);
    const { countryCode, localeUrl } = getCountryCodeAndLocaleUrl(state);
    const config = getConfig({ ...state.session, ...state.user });
    const initialValue = state.toggles.CMS_DRAWER_ASSETS ? 'Mobile' : 'initial';

    const startTime = new Date();
    const navCache =
      !state.toggles.CMS_DRAWER_ASSETS && getCache('mobile_nav_cache'); // cache mechanism needs refacoring in API
    if (navCache) {
      const endTime = new Date() - startTime;
      logger.info(`mobile navigation with cache=${endTime}`);
      return dispatch({
        type: types.RESOLVED_NAVIGATION_MOBILE,
        payload: {
          silos: transformSilosByCountryCode(navCache, countryCode, localeUrl),
        },
      });
    }

    if (useApiKey && isCmsDrawerAssets) {
      config.headers['use-api-key'] = 'true';
    }

    return requestApi
      .get(getSilosApiUri(state, initialValue), config)
      .then(successResponse => {
        const endTime = new Date() - startTime;
        logger.info(`mobile navigation without cache=${endTime}`);
        !state.toggles.CMS_DRAWER_ASSETS &&
          setCache(
            'mobile_nav_cache',
            successResponse.data.silos,
            navigationTTL,
          );

        dispatch({
          type: types.RESOLVED_NAVIGATION_MOBILE,
          payload: {
            silos: transformSilosByCountryCode(
              successResponse.data.silos,
              countryCode,
              localeUrl,
            ),
          },
        });
      })
      .catch(() =>
        dispatch({
          type: types.REJECTED_NAVIGATION_MOBILE,
          payload: navigation,
        }),
      );
  };
}

export function updateNavLeftRight(navBar) {
  return dispatch => dispatch({ type: types.UPDATE_NAV_BAR, navBar });
}

export function toggleNavSliderMenu() {
  return dispatch => dispatch({ type: types.TOGGLE_NAV_SLIDER });
}

export function toggleNavSliderAndLoadSilos() {
  return (dispatch, getState) => {
    dispatch({ type: types.TOGGLE_NAV_SLIDER });
    dispatch({ type: types.LOADING_NAVIGATION_MOBILE });
    const state = getState();
    const NODE_CLIENT_DEFAULT_TIMEOUT = 3000;
    const apiTimeouts = get(state, 'apiTimeouts', {});
    const NAV_API_TIMEOUT = get(
      apiTimeouts,
      'NAVIGATION_API_TIMEOUT',
      NODE_CLIENT_DEFAULT_TIMEOUT,
    );
    const isCmsDrawerAssets = get(state, 'toggles.CMS_DRAWER_ASSETS', false);
    const useApiKey = get(state, 'toggles.CONTENT_LAMBDA_API_KEY', false);
    const requestApi = httpWithLogging(state, NAV_API_TIMEOUT);
    const { countryCode, localeUrl } = getCountryCodeAndLocaleUrl(state);
    const headers = {};

    const startTime = new Date();
    const navCache =
      !state.toggles.CMS_DRAWER_ASSETS && getCache('mobile_nav_cache'); // cache mechanism needs refacoring in API
    if (navCache) {
      const endTime = new Date() - startTime;
      logger.info(`mobile navigation with cache=${endTime}`);
      return dispatch({
        type: types.RESOLVED_NAVIGATION_MOBILE,
        payload: {
          silos: transformSilosByCountryCode(navCache, countryCode, localeUrl),
        },
      });
    }

    if (useApiKey && isCmsDrawerAssets) {
      headers['use-api-key'] = 'true';
    }

    return requestApi
      .get(getSilosApiUri(state, MOBILE_DEVICE_TYPE), { headers })
      .then(successResponse => {
        const endTime = new Date() - startTime;
        logger.info(`mobile navigation without cache=${endTime}`);
        !state.toggles.CMS_DRAWER_ASSETS &&
          setCache(
            'mobile_nav_cache',
            successResponse.data.silos,
            navigationTTL,
          );

        dispatch({
          type: types.RESOLVED_NAVIGATION_MOBILE,
          payload: {
            silos: transformSilosByCountryCode(
              successResponse.data.silos,
              countryCode,
              localeUrl,
            ),
          },
        });
      })
      .catch(() =>
        dispatch({
          type: types.REJECTED_NAVIGATION_MOBILE,
          payload: navigation,
        }),
      );
  };
}

export function fetchSilosForNewMobileNav() {
  return (dispatch, getState) => {
    dispatch({ type: types.LOADING_NAVIGATION_MOBILE });
    const state = getState();
    const NODE_CLIENT_DEFAULT_TIMEOUT = 3000;
    const apiTimeouts = get(state, 'apiTimeouts', {});
    const NAV_API_TIMEOUT = get(
      apiTimeouts,
      'NAVIGATION_API_TIMEOUT',
      NODE_CLIENT_DEFAULT_TIMEOUT,
    );
    const requestApi = httpWithLogging(state, NAV_API_TIMEOUT);
    const { countryCode, localeUrl } = getCountryCodeAndLocaleUrl(state);

    return requestApi
      .get(getSilosApiUri(state, MOBILE_DEVICE_TYPE))
      .then(successResponse =>
        dispatch({
          type: types.RESOLVED_NAVIGATION_MOBILE,
          payload: {
            silos: transformSilosByCountryCode(
              successResponse.data.silos,
              countryCode,
              localeUrl,
            ),
          },
        }),
      )
      .catch(() =>
        dispatch({
          type: types.REJECTED_NAVIGATION_MOBILE,
          payload: navigation,
        }),
      );
  };
}

export function updateSilos(silos) {
  return dispatch => {
    dispatch({ type: types.RESOLVED_NAVIGATION_MOBILE, payload: { silos } });
  };
}

function getSilosApiUri(state, deviceType) {
  if (!state.toggles.NAVIGATION_FALLBACK) {
    const navKeyGroup = get(state, 'abTests.topNavABTestGroup', '');
    const { countryCode } = getCountryCodeAndLocaleUrl(state);
    const callUrl = `${NMConfig.API_SILOS}/${countryCode}/${deviceType}`;

    return navKeyGroup !== ''
      ? `${callUrl}?navKeyGroup=${navKeyGroup}`
      : callUrl;
  } else {
    return null;
  }
}

export function getBreadcrumbList(categoryIds, source) {
  return (dispatch, getState) => {
    dispatch({ type: types.LOADING_BREADCRUMB_CONTENT });
    const state = getState();
    const NODE_CLIENT_DEFAULT_TIMEOUT = 3000;
    const apiTimeouts = get(state, 'apiTimeouts', {});
    const NAV_API_TIMEOUT = get(
      apiTimeouts,
      'NAVIGATION_API_TIMEOUT',
      NODE_CLIENT_DEFAULT_TIMEOUT,
    );
    const requestApi = httpWithLogging(state, NAV_API_TIMEOUT);
    const navKeyGroup = get(state, 'abTests.topNavABTestGroup', '');
    let breadCrumpAPIUrl = `${NMConfig.API_BREADCRUMBS}?categoryIds=${categoryIds}&source=${source}`;
    if (navKeyGroup !== '') {
      breadCrumpAPIUrl = `${breadCrumpAPIUrl}&navKeyGroup=${navKeyGroup}`;
    }
    return requestApi
      .get(breadCrumpAPIUrl)
      .then(successResponse => {
        return dispatch({
          type: types.RESOLVED_BREADCRUMB_CONTENT,
          payload: successResponse.data,
        });
      })
      .catch(() => dispatch({ type: types.REJECTED_BREADCRUMB_CONTENT }));
  };
}

export function dispatchPreFetchedBreadcrumbData(data) {
  return dispatch => {
    return dispatch({ type: types.RESOLVED_BREADCRUMB_CONTENT, payload: data });
  };
}

export function validateSegmentSiloStatus(email) {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state, 10000);
    const headers = {
      'x-profile-email': email,
    };
    const apiURI = NMConfig.API_CUSTOMER_SEGMENT_PROMO;
    return requestApi
      .get(apiURI, { headers })
      .then(successResponse => {
        dispatch({
          type: types.RESOLVED_NAVIGATION_SEGMENT_SAILO,
          payload: successResponse.data,
        });
      })
      .catch(() => {
        dispatch({ type: types.REJECTED_NAVIGATION_SEGMENT_SAILO });
      });
  };
}
