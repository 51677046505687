import React, { Component } from 'react';
import { connect } from 'react-redux';
import AppWrapper from './common/app-common';
import BGxHamptonsPage from '../bgxhamptons/components/BGxHamptonsPage/BGxHamptonsPage';

export class DumbBGxHamptonsAppWrapper extends Component {
  componentDidMount() {}

  render() {
    return (
      <AppWrapper>
        <BGxHamptonsPage />
      </AppWrapper>
    );
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbBGxHamptonsAppWrapper);
