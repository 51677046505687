import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import ShippingLabel from './shippingLabel';
import ClearanceText from './clearanceText';

function ShippingAndClearanceText(props) {
  const {
    details,
    narvarTest,
    customization,
    isEveningWearReturnsFeeExperience,
    isFinalSaleExperience,
    catalogCode,
    showReturnsText,
  } = props;
  const isClearanceItem = get(details, 'clearanceItem', false);
  const parentheticalCharge = parseFloat(
    get(details, 'parentheticalCharge', ''),
  );

  const shippingMessage = (
    <div className="product-heading__shipping-msg">
      <ShippingLabel
        parentheticalCharge={parentheticalCharge}
        narvarTest={narvarTest}
        customization={customization}
        isEveningWearReturnsFeeExperience={isEveningWearReturnsFeeExperience}
        catalogCode={catalogCode}
        showReturnsText={showReturnsText}
      />
    </div>
  );

  return (
    <div className="shipping-and-clearance-text">
      {isClearanceItem && (
        <ClearanceText
          parentheticalCharge={parentheticalCharge}
          customization={customization}
          isFinalSaleExperience={isFinalSaleExperience}
        />
      )}
      {!isClearanceItem && shippingMessage}
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    isEveningWearReturnsFeeExperience:
      state.toggles.EVENING_DRESS_RETURNS &&
      state.abTests.eveningWearReturnsFeeExperience &&
      props.isEvening,
    isFinalSaleExperience:
      state.toggles.FINAL_SALE_IN_OC && state.abTests.finalSaleExperience,
    catalogCode: get(
      state,
      'productCatalog.product.metadata.cmosCatalogId',
      '',
    ),
    showReturnsText: get(state, 'toggles.SHOW_RETURNS_TEXT', true),
  };
};

export default connect(mapStateToProps)(ShippingAndClearanceText);
