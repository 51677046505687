import { BodyB1, EyeBrow1, EyeBrow4 } from 'bgo-common/Styleguide/Typography';
import { isMobile } from 'client-utils/utilities-page';
import { unescape } from 'he';
import every from 'lodash/every';
import find from 'lodash/find';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import unionBy from 'lodash/unionBy';
import {
  initializeSelectedCustomizationsAllTypes,
  setPersonalization,
  setSelectedCustomizations,
  setSelectedCustomizationSavedValues,
  setSelectedCustomizationSelectedValuesFromSavedValues,
  setSelectedCustomizationsTouchedTrue,
  setSelectedIndex,
} from 'pdp/components/ProductPage/actions';
import MonogramOutputDataDropship from 'pdp/components/ProductPage/components/MonogramModal/components/MonogramOutputDataDropship/MonogramOutputDataDropship';
import ProductSize from 'pdp/components/ProductPage/optimizationcomponents/ProductSize/productSize';
import { getSelectedCustomizationsAllTypes } from 'pdp/components/ProductPage/reducers/productReducer';
import getProduct from 'pdp/components/ProductPage/selectors/getProduct';
import AddToBagButton from 'productpage/components/AddToBagButton/addToBagButton';
import ProductOptionSet from 'productpage/components/ProductOptionSet/productOptionSet';
import ProductQuantity from 'productpage/components/ProductQuantity/productQuantity';
import getColorSkus from 'productpage/selectors/getColorSkus';
import { getSelectedOptionMedia } from 'productpage/selectors/getMedia';
import {
  getColorOptions,
  getSizeOptions,
} from 'productpage/selectors/getOptions';
import getSelectedColorIndex from 'productpage/selectors/getSelectedColorIndex';
import getSelectedSku from 'productpage/selectors/getSelectedSku';
import getSizeSkus from 'productpage/selectors/getSizeSkus';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeModal } from 'shared/components/Modal/actions';
import ErrorMessage from '../ErrorMessage/errorMessage';
import Image from '../Image/image';
import { getDynamicScene7ImageURL } from '../Monogram/dynamicImageUtils/getDynamicScene7ImageURL';
import MonogramTextDropship from './components/MonogramDropship/MonogramTextDropship';
import MonogramOptionSetDropship from './components/MonogramOptionsDropship/monogramOptionSetDropship';
import './monogramModalDropship.scss';

export class DumbMonogramModal extends Component {
  constructor(props) {
    super(props);
    const { product = {} } = this.props;
    const defaultCustomizations = [
      { legacyType: 'mono style', selectedValue: 0 },
      { legacyType: 'thread color', selectedValue: 0 },
    ];
    const storedCustomizations = get(
      product,
      'customization.selectedCustomizations',
      [],
    );
    this.state = {
      selectedCustomizations:
        storedCustomizations.length === 0
          ? defaultCustomizations
          : storedCustomizations,
      selectedColorIndex: getSelectedColorIndex(product),
      hoveredColorIndex: -1,
      selectedSizeIndex: get(product, 'options.selectedSizeIndex', -1),
      hoveredStyleIndex: -1,
      hoveredThreadColorIndex: -1,
      isIncompleteText: false,
      isInvalidText: false,
      enableThreadCarousel: !!isMobile(),
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.getDefaultText = this.getDefaultText.bind(this);
    this.setSelectedOptionIndex = this.setSelectedOptionIndex.bind(this);
    this.setHoveredOptionIndex = this.setHoveredOptionIndex.bind(this);
    this.resetHoveredOptionIndex = this.resetHoveredOptionIndex.bind(this);
    this.setSelectedColorIndex = this.setSelectedColorIndex.bind(this);
    this.setHoveredColorIndex = this.setHoveredColorIndex.bind(this);
    this.setSelectedText = this.setSelectedText.bind(this);
    this.setIsIncompleteText = this.setIsIncompleteText.bind(this);
    this.setIsInvalidText = this.setIsInvalidText.bind(this);
    this.getSelectedText = this.getSelectedText.bind(this);
    this.getActiveMonoStyleChoiceIndex = this.getActiveMonoStyleChoiceIndex.bind(
      this,
    );
    this.getActiveThreadColorChoiceIndex = this.getActiveThreadColorChoiceIndex.bind(
      this,
    );
    this.scrollToMonogramText = this.scrollToMonogramText.bind(this);
    this.allCustomizationsValid = this.allCustomizationsValid.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (
      !this.props.selectedCustomizationsAllTypes ||
      !this.props.product.isPersonalizationSelected
    ) {
      this.props.initializeSelectedCustomizationsAllTypes(
        this.props.product.id,
      );
    }
    if (this.props.product.isPersonalizationSelected) {
      this.props.setSelectedCustomizationSelectedValuesFromSavedValues();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.modal.open && !isEmpty(nextProps.product.addToBagError)) {
      this.sizeSelectBox.scrollIntoView();
    }
  }

  getActiveMonoStyleChoiceIndex() {
    const monoStyle = find(this.state.selectedCustomizations, [
      'legacyType',
      'mono style',
    ]);
    return this.state.hoveredStyleIndex !== -1
      ? this.state.hoveredStyleIndex
      : get(monoStyle, 'selectedValue', 0);
  }

  getActiveThreadColorChoiceIndex() {
    const monoStyle = find(this.state.selectedCustomizations, [
      'legacyType',
      'thread color',
    ]);
    return this.state.hoveredThreadColorIndex !== -1
      ? this.state.hoveredThreadColorIndex
      : get(monoStyle, 'selectedValue', 0);
  }

  getSelectedText() {
    return get(
      find(this.state.selectedCustomizations, ['legacyType', 'output data']),
      'selectedValue',
      [],
    );
  }

  getDefaultText(monoStyleChoice, maxLengthOfText) {
    const type = get(monoStyleChoice, 'type');
    const name = 'Emily';
    switch (type) {
      case 'NAME':
        return [name.substring(0, maxLengthOfText)];
      case 'SINGLE_INIT':
        return ['W'];
      case 'THREE_INIT':
      case 'THREE_INIT_FML':
        return ['C', 'S', 'W'];
      default:
        return [];
    }
  }

  setSelectedOptionIndex(legacyType, index) {
    const selectedCustomizations = unionBy(
      [{ legacyType, selectedValue: index }],
      this.state.selectedCustomizations,
      'legacyType',
    );
    const newState = { selectedCustomizations };
    if (legacyType === 'mono style') {
      newState.isIncompleteText = false;
    }
    this.setState(newState);
  }

  setHoveredOptionIndex(legacyType, index) {
    if (legacyType === 'mono style') {
      this.setState({ hoveredStyleIndex: index });
    } else if (legacyType === 'thread color') {
      this.setState({ hoveredThreadColorIndex: index });
    }
  }

  setSelectedText(text) {
    const newState = unionBy(
      [{ legacyType: 'output data', selectedValue: text }],
      this.state.selectedCustomizations,
      'legacyType',
    );
    this.setState({ selectedCustomizations: newState });
  }

  setSelectedColorIndex(index) {
    this.setState({
      selectedColorIndex: index,
    });
  }

  setSelectedSizeIndex = index => {
    this.setState({
      selectedSizeIndex: index,
    });
    this.props.setSelectedIndex(this.state.selectedSizeIndex, 'size');
  };

  setHoveredColorIndex(index) {
    this.setState({
      hoveredColorIndex: index,
    });
  }

  setIsIncompleteText(isIncompleteText) {
    this.setState({ isIncompleteText });
  }

  setIsInvalidText(isInvalidText) {
    this.setState({ isInvalidText });
  }

  allCustomizationsValid() {
    return (
      this.props.selectedCustomizationsAllTypes &&
      this.props.selectedCustomizationsAllTypes.every(
        selectedCustomization => selectedCustomization.isValid,
      )
    );
  }

  resetHoveredOptionIndex(legacyType) {
    if (legacyType === 'mono style') {
      this.setState({ hoveredStyleIndex: -1 });
    } else if (legacyType === 'thread color') {
      this.setState({ hoveredThreadColorIndex: -1 });
    }
  }

  handleClose() {
    this.props.closeModal();
  }

  scrollToMonogramText() {
    if (!isMobile()) {
      return;
    }
    const monogramTextElement = document.getElementsByClassName(
      'monogram-text',
    )[0];
    if (typeof monogramTextElement !== 'undefined') {
      monogramTextElement.scrollIntoView(true);
    }
  }

  handleSave() {
    this.props.setSelectedCustomizationsTouchedTrue();
    const customizationOptions = get(
      this.props,
      'product.customization.customizationOptions',
      [],
    );
    const legacyCustomizationOptions = groupBy(
      customizationOptions,
      option => option.legacyType,
    );
    const outputDataCount = legacyCustomizationOptions['output data'];
    const monoStyleCount = legacyCustomizationOptions['mono style'];
    if (
      outputDataCount &&
      outputDataCount.length === 1 &&
      (monoStyleCount && monoStyleCount.length === 1)
    ) {
      if (this.state.isInvalidText) {
        this.scrollToMonogramText();
        return;
      }
      const selectedText = this.getSelectedText();
      const isTextIncomplete =
        selectedText.length === 0 || some(selectedText, value => !value);
      if (isTextIncomplete) {
        this.setState({ isIncompleteText: true });
        this.scrollToMonogramText();
        return;
      }
    }
    if (!this.allCustomizationsValid()) {
      return;
    }
    this.props.setSelectedCustomizationSavedValues();
    this.props.setSelectedCustomizations(this.state.selectedCustomizations);
    this.props.setSelectedIndex(this.state.selectedColorIndex, 'color');
    this.props.setSelectedIndex(this.state.selectedSizeIndex, 'size');
    this.props.setPersonalization(true);
    this.props.closeModal('MonogramModal');
    return true; // eslint-disable-line consistent-return
  }

  toggleThreadCarousel = () => {
    this.setState({ enableThreadCarousel: !this.state.enableThreadCarousel });
  };

  removeCustomizations = () => {
    this.props.setPersonalization(false);
    this.props.setSelectedCustomizations([]);
    this.props.initializeSelectedCustomizationsAllTypes();
    this.props.setSelectedIndex(0, 'color');
    this.props.setSelectedIndex(0, 'size');
    this.props.setSelectedIndex(0, 'quantity');
  };

  renderOptions(customization) {
    const productId = get(this.props.product, 'id', '');
    const customizationOptions = get(customization, 'customizationOptions', []);
    const customizationOrder = get(customization, 'customizationOrder', []);
    const orderArray = customizationOrder.map(group => group.group);

    const groupedCustomizationOptions = groupBy(
      customizationOptions,
      option => option.group,
    );

    const threadColor =
      find(customizationOptions, { legacyType: 'thread color' }) || {};
    const outputData =
      find(customizationOptions, { legacyType: 'output data' }) || {};
    const outputDataChoices = get(outputData, 'choices', []);
    const maxLengthOfText = get(outputDataChoices[0], 'inputLength');

    const monoStyle =
      find(customizationOptions, { legacyType: 'mono style' }) || {};
    const monoStyleChoices = get(monoStyle, 'choices', []);
    const activeMonoStyleChoice =
      monoStyleChoices[this.getActiveMonoStyleChoiceIndex()];
    const activeMonoStyleChoiceType = get(activeMonoStyleChoice, 'type', '');

    const legacyCustomizationOptions = groupBy(
      customizationOptions,
      option => option.legacyType,
    );
    const outputDataCount = legacyCustomizationOptions['output data'];
    const monoStyleCount = legacyCustomizationOptions['mono style'];

    const selectedText = this.getSelectedText();
    const defaultText = this.getDefaultText(
      activeMonoStyleChoice,
      maxLengthOfText,
    );

    const groups = orderArray.map((groupName, index) => {
      const options = groupedCustomizationOptions[groupName];
      switch (options[0].type) {
        case 'TEXT':
          if (options.length === 1) {
            switch (options[0].legacyType) {
              case 'output data':
                if (
                  outputDataCount &&
                  outputDataCount.length === 1 &&
                  (monoStyleCount && monoStyleCount.length === 1)
                ) {
                  return (
                    <MonogramOutputDataDropship
                      key={index}
                      maxLengthOfText={maxLengthOfText}
                      monoStyleChoiceType={activeMonoStyleChoiceType}
                      selectedText={[...selectedText]}
                      defaultText={defaultText}
                      setSelectedText={this.setSelectedText}
                      isIncompleteText={this.state.isIncompleteText}
                      isInvalidText={this.state.isInvalidText}
                      setIsIncompleteText={this.setIsIncompleteText}
                      setIsInvalidText={this.setIsInvalidText}
                      dropshipMonogramToggle={this.props.dropshipMonogramToggle}
                      option={options[0]}
                      productId={productId}
                    />
                  );
                } else {
                  return (
                    <MonogramTextDropship
                      key={index}
                      group={groupName}
                      options={options}
                      productId={productId}
                    />
                  );
                }
              default:
                return (
                  <MonogramTextDropship
                    key={index}
                    group={groupName}
                    options={options}
                    productId={productId}
                  />
                );
            }
          } else {
            return (
              <MonogramTextDropship
                key={index}
                group={groupName}
                options={options}
                productId={productId}
              />
            );
          }
        case 'CHOICE':
          switch (options[0].legacyType) {
            case 'mono style':
              return (
                <div className="monogram-style-carousel" key={index + 100}>
                  <MonogramOptionSetDropship
                    key={index}
                    optionLabel="Monogram Style"
                    option={options[0]}
                    setSelectedOption={this.setSelectedOptionIndex}
                    setHoverIndex={this.setHoveredOptionIndex}
                    resetHoverIndex={this.resetHoveredOptionIndex}
                    titlePrepend="Style "
                    id="styleMono"
                    productId={productId}
                    dropshipMonogramToggle={this.props.dropshipMonogramToggle}
                    slideWidth={64}
                    enableCarousel={false}
                  />
                </div>
              );
            case 'thread color':
              return (
                <div
                  className={`thread-color-lists ${!this.state
                    .enableThreadCarousel && 'thread-lists'}`}
                  key={index + 100}
                >
                  <MonogramOptionSetDropship
                    key={index}
                    optionLabel="Thread Color"
                    option={threadColor}
                    setSelectedOption={this.setSelectedOptionIndex}
                    setHoverIndex={this.setHoveredOptionIndex}
                    resetHoverIndex={this.resetHoveredOptionIndex}
                    titlePrepend=""
                    id="threadMono"
                    productId={productId}
                    dropshipMonogramToggle={this.props.dropshipMonogramToggle}
                    enableCarousel={this.state.enableThreadCarousel}
                    slidesToShow={this.state.enableThreadCarousel ? 6 : 10}
                  />
                  {isMobile() && (
                    <div className="show-all-text">
                      <a onClick={this.toggleThreadCarousel}>
                        {' '}
                        <EyeBrow4>
                          {this.state.enableThreadCarousel
                            ? 'Show All'
                            : 'Hide All'}
                        </EyeBrow4>
                      </a>{' '}
                    </div>
                  )}
                </div>
              );
            case 'type style':
              return (
                <MonogramOptionSetDropship
                  key={index}
                  optionLabel={`Choose ${options[0].displayName}`}
                  option={options[0]}
                  selectedIndex={this.getActiveThreadColorChoiceIndex()}
                  setSelectedOption={this.setSelectedOptionIndex}
                  setHoverIndex={this.setHoveredOptionIndex}
                  resetHoverIndex={this.resetHoveredOptionIndex}
                  titlePrepend=""
                  id="typeStyle"
                  productId={productId}
                  dropshipMonogramToggle={this.props.dropshipMonogramToggle}
                  forceDropDownRender
                />
              );
            default:
              return (
                <MonogramOptionSetDropship
                  key={index}
                  optionLabel={`Choose ${options[0].displayName}`}
                  option={options[0]}
                  setSelectedOption={this.setSelectedOptionIndex}
                  setHoverIndex={this.setHoveredOptionIndex}
                  resetHoverIndex={this.resetHoveredOptionIndex}
                  titlePrepend=""
                  id={options[0].id}
                  productId={productId}
                  dropshipMonogramToggle={this.props.dropshipMonogramToggle}
                  nonInHouseMono
                />
              );
          }
        default:
          return null;
      }
    });

    return groups;
  }

  render() {
    const { product = {} } = this.props;
    const { customization = {}, designer = {} } = product;
    const customizationOptions = get(customization, 'customizationOptions', []);
    const monoStyle =
      find(customizationOptions, { legacyType: 'mono style' }) || {};
    const outputData =
      find(customizationOptions, { legacyType: 'output data' }) || {};
    const outputDataChoices = get(outputData, 'choices', []);
    const maxLengthOfText = get(outputDataChoices[0], 'inputLength');
    const monoStyleChoices = get(monoStyle, 'choices', []);

    const {
      hoveredColorIndex,
      selectedColorIndex,
      selectedSizeIndex,
    } = this.state;
    const activeColorIndex =
      hoveredColorIndex === -1 ? selectedColorIndex : hoveredColorIndex;

    const selectedText = this.getSelectedText();
    const activeMonostyleChoiceIndex = this.getActiveMonoStyleChoiceIndex();
    const activeThreadColorChoiceIndex = this.getActiveThreadColorChoiceIndex();
    const colorOptions = getColorOptions(product);
    const sizeOptions = getSizeOptions(product);
    const isEmptyText = every(selectedText, value => {
      return !value;
    });

    const activeText = isEmptyText
      ? this.getDefaultText(
          monoStyleChoices[activeMonostyleChoiceIndex],
          maxLengthOfText,
        )
      : selectedText;

    const imageURL = getDynamicScene7ImageURL(
      product,
      activeColorIndex,
      activeMonostyleChoiceIndex,
      activeThreadColorChoiceIndex,
      activeText,
    );
    const productName = unescape(product.name || '');
    const altText = designer.name
      ? `${unescape(designer.name)}, ${productName}`
      : productName;
    const selectedOptionMedia = getSelectedOptionMedia(product);
    const colorSkus = getColorSkus(product, selectedSizeIndex);
    const sizeSkus = getSizeSkus(product, selectedColorIndex);

    const selectedSku = getSelectedSku(
      { colorSkus, sizeSkus },
      { colorOptions, sizeOptions },
      { selectedColorIndex, selectedSizeIndex },
    );

    const addToBagButton = (
      <AddToBagButton
        productId={product.id}
        selectedSku={selectedSku}
        customizationOptionTypePDP={false}
        skuMediaUrl={get(selectedOptionMedia, 'main.thumbnail.url', '')}
        onClick={this.handleSave}
        removeCustomizations={this.removeCustomizations}
        source="personalization"
      />
    );

    // const { details = {} } = product;

    let sizeClass = 'size-wrapper';
    if (
      this.props.modal &&
      this.props.modal.open &&
      !isEmpty(product.addToBagError)
    ) {
      sizeClass += ' form-error';
    }
    return (
      <div
        className="monogram-modal grid-100 tablet-grid-100 mobile-grid-100 grid-parent"
        role="document"
      >
        <div className="monogram-modal__options grid-60 push-40 tablet-grid-60 tablet-push-40 mobile-grid-100 monogram-modal__spacing">
          <div className="monogram-modal__disclaimer-text">
            <BodyB1>
              For assistance, call 1-888-774-2424 or{' '}
              <a
                id="email-us"
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.bergdorfgoodman.com/assistance/contactUs.jsp?emailType=custemailforwarding&icid=foot_email_us"
              >
                email us
              </a>
              .
              <br />
              Personalized items cannot be returned or exchanged; take special
              care when making selections. Text will be proportionate to item
              size.
            </BodyB1>
          </div>

          <ProductOptionSet
            productId={product.id}
            labelHeading=""
            label="color"
            options={colorOptions.values}
            selectedIndex={this.state.selectedColorIndex}
            availableSkus={colorSkus}
            onClick={this.setSelectedColorIndex}
            hoveredIndex={this.state.hoveredColorIndex}
            onHover={this.setHoveredColorIndex}
            // noAvailableSizeSelected={noAvailableSizeSelected}
            optionType=""
            isChanel={product.isChanel}
            isGroup={this.props.isGroup}
          />

          {sizeOptions.values.length > 0 && (
            <div
              className={sizeClass}
              ref={selectBox => {
                this.sizeSelectBox = selectBox;
              }}
            >
              <EyeBrow1>
                <span className="size-section">
                  <span>SIZE:</span>
                  {/* <span
                  className="size-guide-link"
                  dangerouslySetInnerHTML={{ __html: sizeGuideHTML }}
                /> */}
                </span>
              </EyeBrow1>
              {/* <DropDownComponent
                productId={product.id}
                labelHeading="Select"
                label="size"
                options={sizeOptions.values}
                selectedIndex={this.state.selectedSizeIndex}
                onClick={this.setSelectedSizeIndex}
                disableCustomDropDown= {true}
                optionSettings = {
                  {
                    label: 'name',
                    value: 'key',
                    selected: 'index'
                  }
                }
              /> */}
              <ProductSize
                productId={product.id}
                labelHeading="Select"
                label="size"
                options={sizeOptions.values}
                selectedIndex={this.state.selectedSizeIndex}
                onClick={this.setSelectedSizeIndex}
                optionType=""
                optionSettings={{
                  label: 'name',
                  value: 'index_index',
                  selectLabel: 'Select Size',
                }}
                disableCustomDropDown={this.props.isMobile}
              />
              {this.props.modal && this.props.modal.open && (
                <ErrorMessage message={product.addToBagError} />
              )}
            </div>
          )}

          {this.renderOptions(customization)}
          {/* isMobile() ? (
            <span className="grid-parent">
              Preview:
              {!product.productFlags.previewSupported && (
                <span className="monogram-modal__no-preview-text"> No Preview Available</span>
              )}
            </span>
              ) : null */}
        </div>
        {!isMobile() && (
          <div className="monogram-modal__image grid-40 pull-60 tablet-grid-40 tablet-pull-60 mobile-grid-100 grid-parent">
            <div className="monogram-modal__image__overlay">
              {!product.productFlags.previewSupported && !isMobile() && (
                <div className="monogram-modal__image__overlay__no-preview">
                  <span>No Preview Available</span>
                </div>
              )}
              <Image src={imageURL} alt={altText} />
            </div>
          </div>
        )}
        <div className="monogram-modal__action grid-60 push-40 tablet-grid-60 tablet-push-40 mobile-grid-100 monogram-modal__spacing">
          {/* <div className="monogram-modal__save-button grid-30 tablet-grid-30 mobile-grid-100 grid-parent">
            <Button ariaLabel="Save" value="Save" onClick={this.handleSave} />
          </div> */}
          <div
            id={`productButtons_${product.id}`}
            className="gutter-bottom-half"
          >
            <div className="quntity-addtobag">
              <ProductQuantity
                product={product}
                selectedSku={selectedSku}
                isGroupPDP={this.props.isGroupPDP}
                disableCustomDropDown={this.props.isMobile}
              />{' '}
              {addToBagButton}
            </div>
          </div>
          {/* <div className="monogram-modal__cancel-button grid-30 tablet-grid-30 mobile-grid-100 grid-parent">
            <Button ariaLabel="Cancel" secondary value="Cancel" onClick={this.handleClose} />
              </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, { product: { id: productId } }) => ({
  dropshipMonogramToggle: state.toggles.DROPSHIP_MONOGRAM,
  selectedCustomizationsAllTypes: getSelectedCustomizationsAllTypes(
    state,
    productId,
  ),
  product: getProduct(state, productId),
  isMobile: state.device.isMobile,
  modal: state.modal,
});

const mapDispatchToProps = (dispatch, { product: { id: productId } }) => ({
  closeModal: type => dispatch(closeModal(type)),
  setPersonalization: (...args) =>
    dispatch(setPersonalization(productId, ...args)),
  setSelectedCustomizations: (...args) =>
    dispatch(setSelectedCustomizations(productId, ...args)),
  setSelectedIndex: (...args) => dispatch(setSelectedIndex(productId, ...args)),
  initializeSelectedCustomizationsAllTypes: (...args) =>
    dispatch(initializeSelectedCustomizationsAllTypes(...args)),
  setSelectedCustomizationsTouchedTrue: (...args) =>
    dispatch(setSelectedCustomizationsTouchedTrue(productId, ...args)),
  setSelectedCustomizationSavedValues: (...args) =>
    dispatch(setSelectedCustomizationSavedValues(productId, ...args)),
  setSelectedCustomizationSelectedValuesFromSavedValues: (...args) =>
    dispatch(
      setSelectedCustomizationSelectedValuesFromSavedValues(productId, ...args),
    ),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbMonogramModal);
