import React from 'react';

const BackUpCopy = ({
  isIncircle,
  loyaltyHub,
  bgoLoyaltyToggle,
  yourPointsDescription,
}) => {
  const renderGiftCardsParagraph = () => (
    <p>
      Apply your available gift cards at checkout. Don&apos;t miss out on any of
      our exclusive offers!&nbsp;<a href="/">Shop Now!</a>
    </p>
  );

  if (isIncircle && loyaltyHub) {
    return (
      <div className="rewards-container__info">
        <h4>YOUR POINT CARDS</h4>
        <p dangerouslySetInnerHTML={{ __html: yourPointsDescription }} />
      </div>
    );
  } else if (isIncircle) {
    return (
      <div className="rewards-container__info">
        <h4>My InCircle Rewards</h4>
        <p>
          Apply your earned rewards at checkout. See how many points you need
          till your next reward.&nbsp;
          <a href="/e/myaccount/bgcreditcard">Go to BG Credit Card</a>
        </p>
      </div>
    );
  } else if (bgoLoyaltyToggle) {
    return (
      <div className="rewards-container__info">
        {renderGiftCardsParagraph()}
      </div>
    );
  }
  return (
    <div className="rewards-container__info">
      <h4>My Gift Cards</h4>
      {renderGiftCardsParagraph()}
    </div>
  );
};

export default BackUpCopy;
