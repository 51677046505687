/* eslint-disable import/no-cycle */
import React from 'react';
import get from 'lodash/get';
import { bool, object, string } from 'prop-types';
import VisualNav from '../visualNav/visualNav';
import ImageAsset from '../imageAsset/imageAsset';
import ImageWithText from '../imageWithText/imageWithText';
import ButtonsGrid from '../buttonsGrid/buttonsGrid';
import SimpleText from '../../../contentful/components/SimpleText/simpleText';
import Carousel from '../carousel/carousel';
import ProductComponent from '../productComponent/productComponent';
import ProductCarouselAsset from '../product/productCarousel';
import RichText from '../richText/richText';
import TextOverImage from '../textOverImage/textOverImage';
import VideoAsset from '../video/video';
import ContentFrame from '../contentFrame/contentFrame';
import { defaultTrackingTags } from '../../constants/constants';

const Default = () => <div />;

const contentComponentMap = {
  frame: ContentFrame,
  visualNav: VisualNav,
  imageContentAsset: ImageAsset,
  carouselComponent: Carousel,
  productComponent: ProductComponent,
  richTextContentAsset: RichText,
  textOverImageContentAsset: TextOverImage,
  textWithImageContentAsset: ImageWithText,
  productCarouselContentAsset: ProductCarouselAsset,
  videoContentAsset: VideoAsset,
  buttonsGrid: ButtonsGrid,
  simpleTextContentAsset: SimpleText,
};

/**
 * @function ContentItem
 * @param {Object} props.cmsContentItem
 * @param {Object} props.parentTrackingTags
 * @param {String} props.placement
 * @param {Boolean} props.sessionExpired
 * @param {Boolean} props.validPromo
 * @returns {React.ReactElement}
 */
const ContentItem = ({
  cmsContentItem = {},
  parentTrackingTags,
  placement,
  sessionExpired,
  validPromo,
}) => {
  const cmsContentId = get(cmsContentItem, 'sys.id');
  const isPromoItem =
    get(cmsContentItem, 'fields.tags.fields.custom') === 'LoggedInPromo';
  const promoEligible = validPromo && !sessionExpired;

  if (isPromoItem && !promoEligible) return <Default />;

  const componentProps = {
    cmsContentItem,
    parentTrackingTags,
    placement,
  };

  const Component = contentComponentMap[cmsContentItem.contentType] || Default;

  return <Component {...componentProps} key={cmsContentId} />;
};

ContentItem.defaultProps = {
  parentTrackingTags: defaultTrackingTags,
};

ContentItem.propTypes = {
  cmsContentItem: object,
  parentTrackingTags: object,
  placement: string,
  sessionExpired: bool,
  validPromo: bool,
};

export default ContentItem;
