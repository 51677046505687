import isEmpty from 'lodash/isEmpty';
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { connect } from 'react-redux';
import get from 'lodash/get';
import { DESIGNER_CAT_ID } from 'storefront/components/constants';
import { addBodyClass, removeBodyClass } from 'client-utils/bodyClass';
import { isNewMyAccountEnabled } from 'bgo-ui-home/src/client/components/myaccount/utilities/is-new-myaccount';
import { EyeBrow1, BodyB1 } from '@bgo-ui/common/Styleguide/Typography';
import { getLinkDomain } from 'client-utils/utilities-navigation';
import Accordion from 'shared/components/Accordion/accordion';
import AccordionItem from 'shared/components/Accordion/accordionItem';
import DisplayMyFavorites from '../../Profile/DisplayMyFavorites';
import closeSrc from '../../../assets/images/close_light.svg';
import MenuItem from './menuItem';
import DesignerMenu from './DesignerMenu/designerMenu';
import './navSlider.scss';
import { getSliderMenuVisible, getNonDesignerSilos } from './navSlider-reducer';
import { toggleNavSliderMenu, updateSilos } from '../actions-navigation';
import AccountHamburgerMenu from './AccountHambugerMenu/AccountHamburgerMenu';

// import navigation from '../fallback.json';
import { verifyPromoSilo } from '../utils';

class NavSlider extends React.Component {
  constructor(props) {
    super(props);
    this.filteredSilos = [];
    this.silosWithSelectedCategoriesExpanded = this.silosWithSelectedCategoriesExpanded.bind(
      this,
    );
    this.focusedElementBeforeNav = '';
    this.state = {
      selectedIndex: -1,
    };
  }

  UNSAFE_componentWillMount() {
    updateSilos(this.silosWithSelectedCategoriesExpanded());
  }

  componentDidMount() {
    const navOpenClass = 'nav-slider-open';
    addBodyClass(navOpenClass);
    this.focusedElementBeforeNav = document.activeElement;
    this.hamburgerCloseButton.focus();

    // Workaround for viewport calculation in mobile devices
    const vh = window.innerHeight * 0.01;
    this.primaryMenuWrapper.style.setProperty('--customVh', `${vh}px`);
  }

  silosWithSelectedCategoriesExpanded() {
    const { breadcrumbs } = this.props;
    const { silos } = this.props;
    if (breadcrumbs && breadcrumbs.length >= 2) {
      let currentSiloList = silos;
      let isDesignerCategory = false;
      breadcrumbs.forEach(breadcrumb => {
        if (isDesignerCategory) {
          const parentCategoryId = breadcrumb.name.substring(0, 1);
          const parentSiloList =
            currentSiloList &&
            currentSiloList.filter(silo => silo.catmanId === parentCategoryId);
          if (!isEmpty(parentSiloList)) {
            parentSiloList[0].selected = true;
            parentSiloList[0].latestSelected = true;
            currentSiloList = parentSiloList[0].categories;
            isDesignerCategory = false;
          }
        }

        const parent = breadcrumb.id;
        const parentSilo =
          currentSiloList &&
          currentSiloList.filter(silo => silo.catmanId === parent);
        if (!isEmpty(parentSilo)) {
          parentSilo[0].selected = true;
          parentSilo[0].latestSelected = true;
          currentSiloList = parentSilo[0].categories;
        }
        if (breadcrumb.id === DESIGNER_CAT_ID) {
          isDesignerCategory = true;
        }
      });
    }
    return silos;
  }

  handleClick(index) {
    const selectedIndex = index === this.state.selectedIndex ? -1 : index;
    this.setState({ selectedIndex }, () => {
      // eslint breaks with document.querySelector here for some reason
      const activeItem = document.querySelectorAll(
        '.nav-slider-sidebar--show .accordion-item--opened',
      )[0];

      if (activeItem) {
        activeItem.scrollIntoView();
      }
    });
  }

  render() {
    const {
      isVisible,
      toggleMenu,
      user,
      showWishlist,
      validPromo,
      sessionExpired,
      segmentSiloAppConfig,
      dtSignIn,
      myAccountRewardsLength,
      isNewMyAccountEnabled,
    } = this.props;

    let { silos } = this.props;

    const domain = getLinkDomain();

    if (this.props.siloSource === 'API') {
      silos = this.silosWithSelectedCategoriesExpanded();
    }

    silos = verifyPromoSilo(
      silos,
      validPromo && !sessionExpired,
      segmentSiloAppConfig,
    );

    const overlayStyle = isVisible
      ? 'nav-slider-overlay nav-slider-overlay--show'
      : 'nav-slider-overlay nav-slider-overlay--hide';
    const sliderContainerStyle = isVisible
      ? 'nav-slider-container nav-slider-container--show'
      : 'nav-slider-container nav-slider-container--hide';
    const sliderSidebarStyle = isVisible
      ? 'nav-slider-sidebar nav-slider-sidebar--show'
      : 'nav-slider-sidebar  nav-slider-sidebar--hide';
    const sliderSidebarMenuStyle = isVisible
      ? 'nav-slider-menu  nav-slider-menu--show'
      : 'nav-slider-menu  nav-slider-menu--hide';

    const topLevelMenuItems = silos.map(silo =>
      silo.catmanId === DESIGNER_CAT_ID ? (
        <DesignerMenu silo={silo} key={silo.id} />
      ) : (
        <MenuItem silo={silo} key={silo.id} />
      ),
    );

    const myAccount = <AccountHamburgerMenu key="account-hamburger-menu" />;
    topLevelMenuItems.push(myAccount);

    const myFavorites = (
      <li>
        <DisplayMyFavorites
          favMessageClass="nav-slider-menu__item nav-slider-menu__link nav-slider-menu__Favorites"
          favIconClass="myFavoriteIcon"
        />
      </li>
    );
    topLevelMenuItems.push(myFavorites);

    const closeHamburgerMenu = () => {
      const navOpenClass = 'nav-slider-open';
      // toggleMenu();
      document.getElementById('navSliderRef') &&
        document
          .getElementById('navSliderRef')
          .classList.add('nav-slider-overlay--hide');
      setTimeout(() => {
        toggleMenu();
      }, 200);
      removeBodyClass(navOpenClass);
      this.focusedElementBeforeNav.focus();
    };

    return (
      <div
        className={sliderContainerStyle}
        hidden={!isVisible}
        aria-hidden={!isVisible}
      >
        <div className={overlayStyle} onClick={closeHamburgerMenu} />
        <div id="navSliderRef" className={sliderSidebarStyle}>
          <div className="nav-slider-header">
            <a
              id="close-hamburger-menu"
              onClick={closeHamburgerMenu}
              ref={ref => {
                this.hamburgerCloseButton = ref;
              }}
              role="button"
              tabIndex="0"
            >
              <img
                className="icon-close"
                alt="Close mobile navigation icon"
                aria-label="Closing mobile navigation"
                src={closeSrc}
              />
            </a>
            <div className="flyout-manage-account">
              {user.securityStatus &&
              (user.securityStatus.toLowerCase() === 'authenticated' ||
                user.securityStatus.toLowerCase() === 'anonymous') &&
              user.name ? (
                <div>
                  <a
                    href={
                      isNewMyAccountEnabled
                        ? NMConfig.ACCOUNT_PAGE_URL_NEW_MOBILE
                        : NMConfig.ACCOUNT_PAGE_URL
                    }
                  >
                    <BodyB1>My Account</BodyB1>
                    {myAccountRewardsLength > 0 && (
                      <span className="flyout-manage-account__notify">
                        {myAccountRewardsLength}
                      </span>
                    )}
                  </a>
                  {showWishlist && (
                    <a href={NMConfig.WISHLIST_PAGE_URL}>
                      <BodyB1>Wishlist</BodyB1>
                    </a>
                  )}
                </div>
              ) : (
                <div>
                  <a href={dtSignIn ? '/e/login' : NMConfig.ACCOUNT_PAGE_URL}>
                    <BodyB1>Sign in</BodyB1>
                  </a>
                  {showWishlist && (
                    <a href={NMConfig.WISHLIST_PAGE_URL}>
                      <BodyB1>Wishlist</BodyB1>
                    </a>
                  )}
                </div>
              )}

              {/* <a href="#">Wishlist</a> */}
            </div>
          </div>
          <div
            className={sliderSidebarMenuStyle}
            ref={r => {
              this.primaryMenuWrapper = r;
            }}
          >
            {/* <ul className="nav-slider-menu__primary-menu">
              {
                (!isEmpty(silos)) ?
                  topLevelMenuItems
                  : <span className="nav-slider-menu__error">Sorry! This functionality is not available right now. Please try again</span>
              }
            </ul> */}
            <Accordion>
              {silos.map((value, index) =>
                value.name.toLowerCase() === 'designers a-z' ? (
                  <li key={index} className="accordion-list__item link">
                    <div className="accordion-item__line">
                      <a href={`${domain}${value.url}`}>
                        {' '}
                        {/* To be replaced with correct data */}
                        <h6 className="accordion-item__title">{value.name}</h6>
                      </a>
                    </div>
                  </li>
                ) : (
                  <AccordionItem
                    key={index}
                    title={value.name}
                    index={index}
                    clickFun={idx => this.handleClick(idx)}
                    clickedIndex={this.state.selectedIndex}
                  >
                    {value.categories &&
                      value.categories.map((v, i) => (
                        <React.Fragment>
                          <h6
                            className={v.name.toLowerCase().replace(/ /gi, '-')}
                          >
                            {v.name}
                          </h6>
                          <ul className="slider-shop-all">
                            {i === 0 && (
                              <li>
                                <a href={`${domain}${v.url}`}>
                                  <EyeBrow1>Shop All</EyeBrow1>
                                </a>
                              </li>
                            )}
                          </ul>
                          <ul key={i}>
                            {v &&
                              v.categories &&
                              v.categories.map(
                                (val, j) =>
                                  !val.mobileHide && (
                                    <li key={j}>
                                      <a href={`${domain}${val.url}`}>
                                        <EyeBrow1>{val.name}</EyeBrow1>
                                      </a>
                                    </li>
                                  ),
                              )}
                          </ul>
                        </React.Fragment>
                      ))}
                    {value.attributes &&
                      value.attributes.aem &&
                      value.attributes.aem.rows[0] &&
                      value.attributes.aem.rows[0].columns[0] &&
                      value.attributes.aem.rows[0].columns[0].properties && (
                        <ul>
                          <li>
                            <a
                              href={
                                value.attributes.aem.rows[0].columns[0]
                                  .properties.linkurl
                              }
                            >
                              <EyeBrow1>
                                {
                                  value.attributes.aem.rows[0].columns[0]
                                    .properties.title
                                }
                              </EyeBrow1>
                            </a>
                          </li>
                        </ul>
                      )}
                  </AccordionItem>
                ),
              )}
            </Accordion>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isVisible: getSliderMenuVisible(state),
  silos: getNonDesignerSilos(state),
  // silos: navigation.silos,
  breadcrumbs: state.navigation.breadcrumbs,
  siloSource: state.navigation.navSlider.siloSource,
  user: state.user,
  showWishlist: get(state, 'toggles.BG_PHASE_2_WISHLIST', false),
  segmentSiloAppConfig: get(state, 'config.customerSegment.targetedSilo', {}),
  validPromo: get(
    state,
    'navigation.segmentSailo.eliteCustomer.validPromo',
    false,
  ),
  sessionExpired: get(state, 'user.sessionExpired', true),
  dtSignIn: state.toggles.DT_SIGN_IN,
  myAccountRewardsLength: get(state, 'rewards.length', 0),
  isNewMyAccountEnabled: isNewMyAccountEnabled(state),
});

const mapDispatchToProps = {
  toggleMenu: toggleNavSliderMenu,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavSlider);
