import React from 'react';
import EcmText from '../EcmText';
import ConnectedEcmAdaptiveImage from '../EcmAdaptiveImage';
import EcmTextWithImage from '../EcmTextWithImage';
import EcmTextOverImage from '../EcmTextOverImage/ecmTextOverImage';

const EcmSwitch = ({
  ecmContent,
  ecmSlot,
  formatAdaptiveStyleBlock = true,
  ecmCallToActionButtonWithTextToggle = false,
  improvePerformanceOfStyleToggle = false,
  showCaption,
}) => {
  switch (ecmContent.componentType) {
    case 'text':
      return (
        <EcmText
          ecmCallToActionButtonWithTextToggle={
            ecmCallToActionButtonWithTextToggle
          }
          text={ecmContent}
          ecmSlot={ecmSlot}
          formatAdaptiveStyleBlock={formatAdaptiveStyleBlock}
        />
      );
    case 'adaptive-image':
      return (
        <ConnectedEcmAdaptiveImage
          picture={ecmContent}
          ecmSlot={ecmSlot}
          formatAdaptiveStyleBlock={formatAdaptiveStyleBlock}
          buttonOverlayElements={ecmContent.calltoactionbuttons}
          improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
          showCaption={showCaption}
        />
      );
    case 'imagewithtext':
      return (
        <EcmTextWithImage
          ecmCallToActionButtonWithTextToggle={
            ecmCallToActionButtonWithTextToggle
          }
          dataObj={ecmContent}
          ecmSlot={ecmSlot}
          formatAdaptiveStyleBlock={formatAdaptiveStyleBlock}
          improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
        />
      );
    case 'textoverimage':
      return (
        <EcmTextOverImage
          ecmCallToActionButtonWithTextToggle={
            ecmCallToActionButtonWithTextToggle
          }
          dataObj={ecmContent}
          ecmSlot={ecmSlot}
          improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
        />
      );
    default:
      return <div />;
  }
};
export default EcmSwitch;
