import React from 'react';
import AppMyAccountWrapper from './app-my-account-wrapper';
import AddressBookPage from '../../myaccount/pages/AddressBookPage/AddressBookPage';

export default function AppAddressBookWrapper() {
  return (
    <AppMyAccountWrapper>
      <AddressBookPage />
    </AppMyAccountWrapper>
  );
}
