import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as reactCookie from 'react-cookie';
import get from 'lodash/get';
import {
  setRevisitUser,
  openWelcomeMat,
} from 'shared/components/App/app-actions';
import { closeModal } from 'shared/components/Modal/actions';

class WelcomeMat extends Component {
  constructor() {
    super();
    this.userDismissed = 'false';
  }

  componentDidMount() {
    this.userDismissed =
      (window.sessionStorage && window.sessionStorage.getItem('WCDismissed')) ||
      'false';
    if (this.showWelcomeMat()) {
      const modal = {
        closeDisabled: true,
      };
      const wcMatProps = {
        closeModal: this.closeWCModal,
        startShopping: this.startShopping,
      };

      this.props.openWelcomeMat(modal, wcMatProps);
    }
    this.userVisited();
  }

  setClosedByUser = () => {
    window.sessionStorage &&
      window.sessionStorage.setItem('WCDismissed', 'true');
  };

  closeWCModal = () => {
    this.setClosedByUser();
    return this.props.closeModal('WelcomeMatPopup');
  };

  startShopping = () => {
    const {
      intWCMContent: { INT_WC_START_SHOPPING_LINK = '' },
    } = this.props;
    this.closeWCModal();
    window.location.href = INT_WC_START_SHOPPING_LINK;
  };

  visitsExeeded() {
    const { intWCMContent } = this.props;
    let totalVisit = reactCookie.load('utag_main') || '';
    totalVisit = totalVisit.match(/\$_sn:\d/gi);
    totalVisit = totalVisit
      ? totalVisit[0].substring(
          totalVisit[0].indexOf(':') + 1,
          totalVisit[0].length,
        )
      : null;
    totalVisit = Number(totalVisit);
    return totalVisit > intWCMContent.MAX_NO_TO_SHOW_WCMAT;
  }

  showWelcomeMat() {
    if (this.props.isDomestic === 'false') return true;
    return (
      !this.visitsExeeded() &&
      this.props.isUnitedStatesUser === 'False' &&
      this.userDismissed === 'false'
    );
  }

  userVisited() {
    reactCookie.save('revisitUser', true, { path: '/' });
    this.props.setRevisitUser(true);
  }

  attachScript() {
    const welcomeMatScript = document.createElement('script');
    welcomeMatScript.src = WelcomeMat.welcomeMatUrl(this.props.countryCode);
    welcomeMatScript.async = true;
    document.body.appendChild(welcomeMatScript);
  }

  render() {
    if (this.showWelcomeMat()) {
      return <div id="welcome-mat" />;
    } else {
      return false;
    }
  }
}

const mapStateToProps = state => ({
  revisitUser: state.user.revisitUser,
  isUnitedStatesUser: get(state, 'session.isUnitedStatesUser', 'True'),
  toggles: state.toggles,
  intWCMContent: get(state, 'content.internationalWelcomeMat', {}),
  isDomestic: get(state, 'page.location.query.domestic', 'true'),
});

const mapDispatchToProps = {
  setRevisitUser,
  openWelcomeMat,
  closeModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WelcomeMat);
