import React from 'react';
import './salePrice.scss';

const SalePrice = ({ retailPrice, originalPrice, isTalonOne }) => {
  if (!retailPrice) {
    return false;
  }

  return (
    <div className="product-thumbnail__sale-price">
      {originalPrice ? (
        <span>
          <span className="adornment currentPrice">
            <span
              className={
                isTalonOne
                  ? 'price adornmentFontSize markDownPrice'
                  : 'price actualPrice'
              }
            >
              {retailPrice}
            </span>
          </span>
          <span className="adornment">
            <span
              className={
                isTalonOne
                  ? 'price originalPrice adornmentFontSize'
                  : 'price originalPrice'
              }
            >
              {originalPrice}
            </span>
          </span>
        </span>
      ) : (
        <span>{retailPrice}</span>
      )}
    </div>
  );
};

export default SalePrice;
