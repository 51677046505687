import { H2, EyeBrow6 } from 'bgo-common/Styleguide/Typography';
import React, { Fragment } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { connect } from 'react-redux';
import get from 'lodash/get';
import compact from 'lodash/compact';
import values from 'lodash/values';
import { closeModal } from 'shared/components/Modal/actions';
import { NO_IMAGE_AVAILABLE_CDN } from 'pdp/components/ProductPage/components/Image/image';
import './shopTheLookModal.scss';
import ResponsiveImage from 'bgo-common/client/common/components/ResponsiveImage/responsiveImage';
import {
  getStlProduct,
  getProductDetails,
  clearShopTheLookData,
} from 'shared/actions/actions-shopthelook';
import {
  getActiveMedia,
  getActiveMediaIndex,
} from 'pdp/components/ProductPage/selectors/getMedia';
import { SHOP_THE_LOOK } from '../../constants';
import closeSVG from 'assets/images/close.svg';
import ProductMedia from 'pdp/components/ProductPage/optimizationcomponents/ProductMediaTemplateB/productMedia';
import CheckoutOptionRenderer from 'pdp/components/ProductPage/optimizationcomponents/Checkout/checkoutOptionRenderer';
import ProductDetails from 'pdp/components/ProductPage/optimizationcomponents/ProductDetails/productDetails';
import SimpleSpinner from 'shared/components/Spinner/simpleSpinner';

class ShopTheLookModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stlProductList: [],
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const productList = this.state.stlProductList;
    if (
      nextProps &&
      nextProps.stlProductList &&
      nextProps.skuStlProductList &&
      Object.keys(nextProps.skuStlProductList).length
    ) {
      nextProps.stlProductList
        .filter(
          product =>
            !nextProps.skuStlProductList[product.id] &&
            productList.indexOf(product.id) === -1,
        )
        .forEach(product => {
          this.props.getStlProduct(product.id);
          productList.push(product.id);
        });
      this.setState({ stlProductList: productList });
    }
  }

  UNSAFE_componentWillUnmount() {
    this.props.clearShopTheLookData();
  }

  getProductDetails = (e, productId) => {
    e.preventDefault();
    this.props.getProductDetails(productId);
  };

  getStlThumbnailImageSrc = productDetails => {
    const thumbnailMedia = getActiveMedia(productDetails);
    const imageSrc = get(
      thumbnailMedia,
      'main.dynamic.url',
      NO_IMAGE_AVAILABLE_CDN,
    );
    return imageSrc;
  };
  closeModal = e => {
    e.preventDefault();
    this.props.closeModal('ShopTheLookModal');
  };

  render() {
    const {
      stlProductList,
      productDetails,
      activeProductId,
      entryPointPage = '',
      skuStlProductList,
    } = this.props;
    const activeIndex = getActiveMediaIndex(productDetails);
    const media = getActiveMedia(productDetails);
    const groupMedia = compact([media.main, ...values(media.alternate || [])]);
    const isSkuStlProductList =
      skuStlProductList && Object.keys(skuStlProductList).length;
    return (
      <div className="stl-modal-content">
        {isSkuStlProductList && skuStlProductList[productDetails.id] ? (
          <Fragment>
            <div className="stl-title">
              <H2>{SHOP_THE_LOOK}</H2>
            </div>

            <div className="stl-thumbnail-container">
              {isSkuStlProductList
                ? Object.keys(skuStlProductList).map((product, index) => (
                  <div
                    key={index}
                    className={`stl-thumbnail ${
                        activeProductId === skuStlProductList[product].id
                          ? 'active-thumbnail'
                          : ''
                      }`}
                  >
                    <div
                      className="stl-thumbnail-details"
                      onClick={e =>
                          this.getProductDetails(
                            e,
                            skuStlProductList[product].id,
                            index,
                          )
                        }
                    >
                      <ResponsiveImage
                        src={this.getStlThumbnailImageSrc(
                            skuStlProductList[product],
                          )}
                        alt={skuStlProductList[product].name}
                        title={skuStlProductList[product].name}
                        name={skuStlProductList[product].name}
                        isCloudinary
                        className={'stl-slide-image'}
                      />
                    </div>
                  </div>
                  ))
                : null}
            </div>
            {(isSkuStlProductList && <hr className="stl-hr" />) || null}
            {productDetails &&
            productDetails.id &&
            isSkuStlProductList &&
            skuStlProductList[productDetails.id] ? (
              <div className="stl-product-container">
                <div className="stl-image-container">
                  <ProductMedia
                    productId={productDetails.id}
                    showSoldOutOverlay={!false}
                    activeIndex={activeIndex}
                    name={productDetails.name}
                    media={media}
                    arrows
                    isGroup={productDetails.isGroup}
                    displayOutfittingText
                    groupMedia={groupMedia}
                    isAlternateImages={false}
                    isCloudinary
                  />
                </div>
                <div className="stl-product-details">
                  <ProductDetails product={productDetails} />
                  <CheckoutOptionRenderer
                    product={productDetails}
                    isFromShopTheLook
                  />
                </div>
              </div>
            ) : null}
            <div className="stl-similar-style-container">
              {(stlProductList && stlProductList.length && (
                <hr className="stl-hr" />
              )) ||
                null}
              {entryPointPage !== 'LSS' && <div id="stlsimilarstyles" />}
              <span
                className="stl-close hide-on-desktop hide-on-tablet"
                onClick={e => this.closeModal(e)}
              >
                <img
                  alt="Close"
                  aria-hidden="true"
                  className="close-icon"
                  height="16"
                  src={closeSVG}
                  width="16"
                />
                <EyeBrow6> Close</EyeBrow6>
              </span>
            </div>
          </Fragment>
        ) : (
          <SimpleSpinner />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    productDetails: state.productCatalog.product,
    activeProductId: state.shopTheLookReducer.activeProductId,
    stlProductList: state.shopTheLookReducer.stlProductList,
    skuStlProductList: { ...state.shopTheLookReducer.skuStlProductList },
    entryPointPage: state.shopTheLookReducer.entryPointPage,
  };
};

const mapDispatchToProps = {
  getStlProduct,
  getProductDetails,
  closeModal,
  clearShopTheLookData,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShopTheLookModal);
