import React from 'react';
// import { isMobile } from 'client-utils/utilities-page';
// import image from 'assets/images/call-outline.svg';
// import chatImage from 'assets/images/chat-square.svg';
import './customerSupportContact.scss';
import Accordion from 'shared/components/Accordion/accordion';
import AccordionItem from 'shared/components/Accordion/accordionItem';
import { BodyB1 } from '@bgo-ui/common/Styleguide/Typography';

import AppDownLoadLink from 'shared/components/appDownloadLink';
// import callImg from '../../../../assets/images/icons-call.svg'
// import emailImg from '../../../../assets/images/icons-email.svg'
// import returnsImg from '../../../../assets/images/icons-returns.svg'
// import trackOrderImg from '../../../../assets/images/icons-track-order.svg'
import Toggle from '../../../../client/common/components/Toggle/toggle';

class CustomerSupportContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: -1,
    };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(index) {
    if (index === this.state.selectedIndex) {
      this.setState({ selectedIndex: -1 });
    } else {
      this.setState({ selectedIndex: index });
    }
  }

  render() {
    return (
      <div className="hide-on-footer-desktop">
        <div className="footerCustomerSupportContainer">
          <ul className="customerSupportLinks">
            {this.props.footerLinks.data.customerSupportData.map(
              (value, idx) => (
                <li key={idx}>
                  <span className={`type-${value.type}`}>
                    <a href={value.url}>
                      <BodyB1>{value.title}</BodyB1>
                    </a>
                  </span>
                </li>
              ),
            )}
          </ul>
          <Accordion>
            {this.props.footerLinks.data.footerLinks.map((value, index) => (
              <AccordionItem
                title={value.title}
                key={index}
                index={index}
                clickFun={this.handleClick}
                clickedIndex={this.state.selectedIndex}
              >
                <ul>
                  {value.content.map((v, i) => (
                    <li key={i}>
                      <a href={v.url}>{v.title}</a>
                    </li>
                  ))}
                </ul>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
        <Toggle feature="DOWNLOAD_APP_LINK">
          <AppDownLoadLink />
        </Toggle>
      </div>
    );
  }
}

export default CustomerSupportContact;
