import { types as NavTypes } from '../actions-navigation';

const defaultState = {
  eliteCustomer: {},
  responseReceived: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case NavTypes.RESOLVED_NAVIGATION_SEGMENT_SAILO:
      return {
        ...state,
        eliteCustomer: action.payload,
        responseReceived: true,
      };

    case NavTypes.REJECTED_NAVIGATION_SEGMENT_SAILO:
      return {
        ...state,
        eliteCustomer: {},
        responseReceived: true,
      };
    default:
      return state;
  }
};
