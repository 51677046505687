import React from 'react';
import { connect } from 'react-redux';
import { editButtonText, deleteButtonText } from '../../../constants';
import { openPaymentDeletemodal, closePaymentCTAmodal } from '../actions';
import './PaymentActionModal.scss';

const PaymentActionModal = props => {
  return (
    <div className="payment-cta-modal__wrapper">
      <span
        className="cls_modal_small"
        onClick={() => props.closePaymentCTAmodal()}
      />
      <div className="payment-cta-modal__content">
        <button type="button" onClick={() => editAddress(props)}>
          {editButtonText}
        </button>
        <button
          type="button"
          onClick={() =>
            props.openPaymentDeletemodal(
              props.paymentId,
              props.billingAddressId,
            )}
        >
          {deleteButtonText}
        </button>
      </div>
      <span
        className="cls_modal_large"
        onClick={() => props.closePaymentCTAmodal()}
      />
    </div>
  );
};

function editAddress(props) {
  props.editDetails(true, props.paymentId, props.billingAddressId);
  props.closePaymentCTAmodal();
}

const mapDispatchToProps = {
  openPaymentDeletemodal,
  closePaymentCTAmodal,
};

export default connect(
  null,
  mapDispatchToProps,
)(PaymentActionModal);
