import React from 'react';
import { connect } from 'react-redux';
import { openSearchPanel } from '../search-actions';
import { getSearchVisible } from '../search-reducers';

import './searchButton.scss';

const focusInput = () => {
  const brSearchInput = document.getElementById('brSearchInput');

  brSearchInput.focus();
  setTimeout(() => brSearchInput.focus(), 0);
};

const SearchButton = ({ isSearchVisible, handleSearchClick }) => {
  const visibilityClass = isSearchVisible ? 'search-button--hidden' : '';

  return (
    <div
      className={`search-button ${visibilityClass}`}
      role="button"
      aria-label="Open search panel"
      aria-haspopup
      aria-expanded={isSearchVisible}
      onClick={() => {
        handleSearchClick();
        focusInput();
      }}
    >
      <i title="Open search" aria-hidden className="search-button__icon" />
      <span className="search-button__text desktop-only">Search</span>
    </div>
  );
};

const mapStateToProps = state => ({
  isSearchVisible: getSearchVisible(state),
});

const mapDispatchToProps = {
  handleSearchClick: openSearchPanel,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchButton);
