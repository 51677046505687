import React from 'react';
import get from 'lodash/get';
// eslint-disable-next-line import/no-cycle
import ProductGridComponent from '../product/productGrid';
// eslint-disable-next-line import/no-cycle
import Products from '../product/products';

const ProductComponent = ({ cmsContentItem = {} }) => {
  const contentId = [];
  const components = cmsContentItem.fields.components;
  switch (cmsContentItem.fields.type) {
    case 'Product Rail 4x1': {
      return components.map(item => {
        const isItemListContent =
          get(item, 'contentType') === 'itemListContentAsset';
        if (isItemListContent) {
          return (
            <Products
              productIds={item.fields.ids.slice(0, 4)}
              contentId={item.sys.id}
            />
          );
        }
        return <div />;
      });
    }
    case 'Product Rail 2x2': {
      return components.map(item => {
        const isItemListContent =
          get(item, 'contentType') === 'itemListContentAsset';
        if (isItemListContent) {
          return (
            <Products
              productIds={item.fields.ids.slice(0, 4)}
              contentId={item.sys.id}
              is2x2
            />
          );
        }
        return <div />;
      });
    }
    case 'Product Grid': {
      return components.map((item, gridIndex) => {
        const promoTiles = components.filter(asset => {
          return asset.contentType === 'promoTileContentAsset';
        });
        const itemListContentAsset = components.find(asset => {
          return asset.contentType === 'itemListContentAsset';
        });
        if (item.contentType === 'itemListContentAsset') {
          contentId.push(item.sys.id);
        }
        if (itemListContentAsset && components.length - 1 === gridIndex) {
          return (
            <ProductGridComponent
              contentId={contentId[0]}
              idsToSearch={itemListContentAsset.fields.ids}
              promoTiles={promoTiles}
            />
          );
        }
        return <div />;
      });
    }
    default: {
      return <div />;
    }
  }
};
export default ProductComponent;
