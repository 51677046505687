import React from 'react';
import { connect } from 'react-redux';

const DesignerMenu = ({ designerSilo }) => {

  return (
    <li>
      <a className="nav-slider-menu__item nav-slider-menu__designerslink" href={designerSilo.url} >
        {designerSilo.name}
      </a>
    </li>
  );
};

const mapStateToProps = (state, ownProps) => ({
  designerSilo: ownProps.silo,
});

export default connect(mapStateToProps)(DesignerMenu);
