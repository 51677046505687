import React, { Component } from 'react';
import { connect } from 'react-redux';
import find from 'lodash/find';
import first from 'lodash/head';
import last from 'lodash/last';
import Button from 'shared/components/Button/button';
import { isMobile } from 'client-utils/utilities-page';
import { closeModal } from 'shared/components/Modal/actions';
import { handleTabEventWith } from 'client-utils/handleTabbing';
import { ESC_KEYCODE } from 'client-utils/keyCodes';
import { countryCurrencyData } from '../../../CountrySelector/CountryChooser/countryCurrencyData';
import RestrictedProduct from '../RestrictedProduct/restrictedProduct';
import { removeRestrictedItems } from '../../RestrictedItems/restrictedItems-actions';
import './restrictedItemsModal.scss';

class RestrictedItemsModal extends Component {
  constructor() {
    super();
    this.onClickConfirm = this.onClickConfirm.bind(this);
    this.onClickCancel = this.OnClickCancel.bind(this);
    this.onEscPress = this.onEscPress.bind(this);
  }

  componentDidMount() {
    addEventListener('keydown', this.onEscPress);
    this.setFocus();
  }

  componentWillUnmount() {
    removeEventListener('keydown', this.onEscPress);
  }

  onClickConfirm() {
    this.props.removeRestrictedItems(this.props.content.previousCountryCode,
                                     this.props.content.selectedCountryCode,
                                     this.props.content.selectedCurrencyCode,
                                     this.props.content.selectedLocaleUrl);
    this.props.closeModal();
  }

  onEscPress(e) {
    if (e.which === ESC_KEYCODE) {
      this.props.closeModal();
      e.preventDefault();
    }
  }

  setFocus() {
    const buttons = document.querySelectorAll('.nm-modal__window button');
    const firstButton = first(buttons);
    const lastButton = last(buttons);

    handleTabEventWith(firstButton, lastButton);
  }

  OnClickCancel() {
    this.props.closeModal();
  }

  render() {
    const {
      previousCountryCode,
      selectedCountryCode,
      products
    } = this.props.content;

    const previousCountry =
            find(countryCurrencyData, (country) => country.countryCode === previousCountryCode);
    const selectedCountry =
            find(countryCurrencyData, (country) => country.countryCode === selectedCountryCode);
    return (
      <div
        className="restricted-modal grid-100 tablet-grid-100 mobile-grid-100 grid-parent"
        tabIndex="-1"
      >
        <h2
          id="restricted-modal-title" aria-labelledby="restricted-modal-title"
          aria-describedby="restricted-modal-description restricted-modal-products restricted-modal-instruction"
        >Shopping Bag Update</h2>
        <p id="restricted-modal-description">The following item(s) in your shopping cart cannot be shipped to
          <b> { selectedCountry && selectedCountry.countryName }</b>.
        </p>

        {products && <div className="restricted-modal__items" id="restricted-modal-products">
          <ul>
            {
                products.map((product, idx) => (
                  <RestrictedProduct key={idx}
                    product={product}
                  />))
              }
          </ul>
        </div>}
        <p id="restricted-modal-instruction">Click <i>remove</i> item to continue shopping.
          Click cancel to <i>revert</i> your ship to country to
          <span> { previousCountry && previousCountry.countryName }.</span>
        </p>
        <div className="restricted-modal__button" onClick={this.onClickConfirm} >
          <Button value="REMOVE ITEM(S)" ref={ref => { !isMobile() && ref && setImmediate(() => ref.focus()); }} />
        </div>
        <div className="restricted-modal__button" onClick={this.onClickCancel}>
          <Button inverse value="CANCEL" />
        </div>


      </div>
    );
  }
}

const mapDispatchToProps = { closeModal, removeRestrictedItems };

const mapStateToProps = state => ({
  content: state.modal.content
});
export default connect(mapStateToProps, mapDispatchToProps)(RestrictedItemsModal);
