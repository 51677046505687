import { buildCookieString } from 'universal/utilities-cookies';
import httpWithLogging from 'universal/http-client';

function getOrderStatus(state) {
  return new Promise((resolve) => {
    const { DYN_USER_CONFIRM, DYN_USER_ID, JSESSIONID, W2A, WID } = state.session;
    let orderStatus;
    const headers = {
      Cookie: buildCookieString({ JSESSIONID }),
      DYN_USER_ID,
      JSESSIONID,
      DYN_USER_CONFIRM,
      W2A,
      WID
    };
    const requestApi = httpWithLogging(state);

    requestApi.get(`${NMConfig.API_GET_ORDER_STATUS}`, {
      headers
    }).then(userOrders => {
      const { statusCode } = userOrders.data.OrderStatusResp;
      if (statusCode === 200) {
        const { orderId, lineItems } = userOrders.data.OrderStatusResp;
        const ordersRefactored = lineItems.CommerceLineItem.map(eachOrder => {
          const newOrder = {
            lastUpdatedDate: eachOrder.lastUpdatedDate || ''
          };
          newOrder[eachOrder['ciLineItemId']] = eachOrder['ciLineItemStatus'];
          return newOrder;
        });
        orderStatus = {
          id: orderId,
          items: ordersRefactored
        };
      } else if (statusCode === 401 || statusCode === 500) {
        orderStatus = null;
      } else if (statusCode === 404) {
        orderStatus = {};
      }

      resolve({ OrderStatus: orderStatus });
    }).catch(() => {
      orderStatus = null;
      resolve({ OrderStatus: orderStatus });
    });
  });
}

export default getOrderStatus;
