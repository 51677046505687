/* eslint react/no-danger: 0 */
import React from 'react';
import classnames from 'classnames';
import partition from 'lodash/partition';
import { renderFormattedPriceWithDecimals } from 'pdp/utilities/utilities-price';
import Badges from 'bgo-common/client/common/components/Badges/badges';

const innerHTML = (itemHTML, promotionalPrice, currencyCode) => {
  return promotionalPrice
    ? `${itemHTML}: ${renderFormattedPriceWithDecimals(
        promotionalPrice,
        currencyCode,
      )}`
    : itemHTML;
};

const innerHTMLNewPDP = (itemHTML, promotionalPrice, currencyCode) => {
  return promotionalPrice
    ? `${itemHTML}: <span class='promo-final-price'>${renderFormattedPriceWithDecimals(
        promotionalPrice,
        currencyCode,
      )}</span>`
    : itemHTML;
};

const PromotionItemHtml = ({
  promotions = [],
  currencyCode,
  promotionalPrice,
  isOOS,
  isNewPDP = false,
}) => {
  const [promotionWithoutPrice] = promotions
    ? partition(promotions, { showPromoPrice: false })
    : [[], []];
  if (!promotions.length) {
    return false;
  }
  if (isOOS) {
    return (
      <div className="oos">
        <div className="product-thumbnail__promotions">
          {promotions.map((promotion, index) => {
            const { templateHTML, type } = promotion;
            const style = { color: '#b30015' };
            const className = classnames(
              'product-thumbnail__promotions__element',
              {
                'product-thumbnail__promotions__element--priced': promotionalPrice,
              },
              'promotion-text',
            );
            return (
              type === '113' && (
                <div
                  key={index}
                  style={style}
                  className={className}
                  dangerouslySetInnerHTML={{
                    __html: innerHTML(
                      templateHTML,
                      promotionalPrice,
                      currencyCode,
                    ),
                  }}
                />
              )
            );
          })}
        </div>
        <Badges
          promotions={{
            promotionWithoutPrice,
            field: 'templateHTML',
          }}
        />
      </div>
    );
  }
  return (
    <div className="promo">
      {promotions.map((promotion, key) => {
        const { itemHTML, itemPromoColor } = promotion;
        const style = itemPromoColor
          ? { color: `#${itemPromoColor}` }
          : undefined;
        const className = classnames('promotion-item-html', {
          'sale-price': promotionalPrice,
        });
        return isNewPDP ? (
          <div
            key={key}
            className={className}
            dangerouslySetInnerHTML={{
              __html: innerHTMLNewPDP(itemHTML, promotionalPrice, currencyCode),
            }}
          />
        ) : (
          <div
            key={key}
            style={style}
            className={className}
            dangerouslySetInnerHTML={{
              __html: innerHTML(itemHTML, promotionalPrice, currencyCode),
            }}
          />
        );
      })}
    </div>
  );
};

export default PromotionItemHtml;
