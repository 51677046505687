import { unescape } from 'he';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { buildCookieString } from 'universal/utilities-cookies';
import httpWithLogging, { shouldLoad } from 'universal/http-client';
import logger, { X_NMO_TRACE_ID_NAME } from 'server/utilities/logger';
import {
  getABTestAssignments,
  HP_SLOTS_MBOX_ID,
  HP_CAROUSEL_SLIDES_MBOX_ID,
  BAZAAR_VOICE_MBOX_ID,
  CP_PERSONALIZATION_MBOX_ID,
} from 'shared/actions/actions-page';
import ECMConstants from 'client/ecm/constants';
import {
  COMPONENT_SITE_TICKER,
  COMPONENT_HOME_TOP,
  COMPONENT_PDP_BOTTOM_BANNER,
} from 'bgo-common/components/constants';
import { ECMSlots } from '../components/EcmGet';

let cacheManager = {};
if (!IS_CLIENT) {
  cacheManager = require('server-utils/utilities-cache-manager');
}

const setCache = (name, data, ttl) => {
  if (!IS_CLIENT) {
    cacheManager.setCache(name, data, ttl);
  }
};

const getCache = name => {
  if (!IS_CLIENT) {
    return cacheManager.getCache(name);
  }
  return false;
};

const benchmarkCache = (startTime, msg) => {
  const endTime = new Date() - startTime;
  logger.info(`${msg}: ${endTime}`);
};

const siteTickerCacheKey = `ecm_cache_${ECMConstants.SITE_TICKER.contentId}`;
const homeTopCacheKey = `ecm_cache_${ECMConstants.HOME_TOP.contentId}`;

export const SET_P13N_PXL = 'SET_P13N_PXL';
export const SET_HIDE_ON_DESKTOP = 'SET_HIDE_ON_DESKTOP';
export const SET_HIDE_ON_TABLET = 'SET_HIDE_ON_TABLET';
export const SET_HIDE_ON_MOBILE = 'SET_HIDE_ON_MOBILE';
export const ADD_PROMO_TILES_FLAG_TO_UTAG_DATA =
  'ADD_PROMO_TILES_FLAG_TO_UTAG_DATA';
export const SET_SELECTED_FACET = 'SET_SELECTED_FACET';
export const SET_ACTIVE_FACET = 'SET_ACTIVE_FACET';
export const LOADING_NA_TOTAL = 'LOADING_NA_TOTAL';
export const RESOLVED_NA_TOTAL = 'RESOLVED_NA_TOTAL';
export const REJECTED_NA_TOTAL = 'REJECTED_NA_TOTAL';

const getConfig = (session = {}, context = {}) => {
  return {
    Cookie: buildCookieString({
      JSESSIONID: session.JSESSIONID,
      DYN_USER_ID: session.DYN_USER_ID,
      TLTSID: session.TLTSID,
      [X_NMO_TRACE_ID_NAME]: context[X_NMO_TRACE_ID_NAME],
      TRUE_CLIENT_IP: context.TRUE_CLIENT_IP,
      W2A: session.W2A,
    }),
  };
};

function trimSpaces(str) {
  return str.trim().replace(/\s\s+/g, ' ');
}
function stripHtml(html) {
  return html.replace(/<[^>]+>/g, '');
}
export function getTitle(product) {
  const { isGroup } = product;
  const cmosItemNum = get(product, 'metadata.cmosItem', product.cmosItem);
  const designerName = unescape(
    get(product, 'designer.name', product.designer) || '',
  );
  let productName = stripHtml(unescape(product.name || ''));
  if (isGroup) {
    const childProducts = get(product, 'childProducts', []);
    productName = stripHtml(
      unescape(
        childProducts
          .map(product => {
            return product.name || '';
          })
          .join(' '),
      ),
    );
  }
  return trimSpaces(`${cmosItemNum || ''} ${designerName} ${productName}`);
}

const getPromoTileFlag = (successResponse, ecmSlot) => {
  return !!(
    get(successResponse, `data[${ecmSlot.elements}][0]`, false) ||
    get(successResponse, `data[${ecmSlot.elements}][1]`, false) ||
    get(successResponse, `data[${ecmSlot.elements}][2]`, false)
  );
};

export function getEcmQLProductInfo(prodId) {
  return (dispatch, getState) => {
    dispatch({
      type: `LOADING_ECM_QL_PRODUCT_INFO_${prodId}`,
      prodId,
    });
    const state = getState();
    const { user, session } = state;
    const requestApi = httpWithLogging(state, 6000);
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        ucid: get(user, 'ucid', ''),
        rid: get(user, 'rid', 'US'),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        W2A: get(session, 'W2A', ''),
      }),
    };
    const prodCallString = `${NMConfig.API_PRODUCT}?productIds=${prodId}`;
    return requestApi
      .get(prodCallString, { headers })
      .then(res => {
        if (get(res.data, 'total', '') !== 0) {
          dispatch({
            type: `RESOLVED_ECM_QL_PRODUCT_INFO_${prodId}`,
            payload: res.data,
            prodId,
          });
        } else {
          dispatch({
            type: `REJECTED_ECM_QL_PRODUCT_INFO_${prodId}`,
            prodId,
          });
        }
      })
      .catch(() => {
        dispatch({
          type: `REJECTED_ECM_QL_PRODUCT_INFO_${prodId}`,
          prodId,
        });
      });
  };
}

export function getCurratedProductInfo(catID = '', parentCatID = '') {
  return (dispatch, getState) => {
    dispatch({
      type: `LOADING_ECM_CURRATED_PRODUCTS_${catID}`,
      catID,
    });
    const state = getState();
    const { user, session } = state;
    const requestApi = httpWithLogging(state, 6000);
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        ucid: get(user, 'ucid', ''),
        rid: get(user, 'rid', 'US'),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        W2A: get(session, 'W2A', ''),
      }),
    };
    const prodCallString = parentCatID
      ? `${NMConfig.API_PRODUCT_LIST}?categoryId=${catID}&parentCategoryId=${parentCatID}&fetchSize=10`
      : `${NMConfig.API_PRODUCT_LIST}?categoryId=${catID}&fetchSize=10`;

    return requestApi
      .get(prodCallString, { headers })
      .then(res => {
        if (res.data && res.data.products) {
          if (res.data.products.length !== 0) {
            const curratedProductList = [];
            for (const product of res.data.products) {
              const returnImage = () => {
                const mainDynamic = get(product, 'main');
                if (mainDynamic) {
                  return mainDynamic;
                }
                const alternateDynamic = get(product, 'alt');
                if (alternateDynamic) {
                  return alternateDynamic;
                }
                return '/assets/images/no-image.c9a49578722aabed021ab4821bf0e705.jpeg';
              };
              const formatPrice = () => {
                if (
                  get(product, 'price.currencyCode', false) &&
                  get(product, 'price.retailPrice', false)
                ) {
                  return `${get(product, 'price.currencyCode', ' ')}${get(
                    product,
                    'price.retailPrice',
                    ' ',
                  )}`;
                }
                return '';
              };

              const designerName = get(
                product,
                'designer.name',
                product.designer,
              );
              const name = product.name || '';
              const { isGroup } = product;
              const driveToUrl = get(
                product,
                'details.canonicalUrl',
                product.canonical,
              );
              const childProducts = isGroup
                ? get(product, 'childProducts', [])
                : [];

              curratedProductList.push({
                id: product.id,
                displayable: product.displayable,
                productId: product.id,
                designerName: designerName || '',
                name,
                driveToUrl: driveToUrl || '',
                price: formatPrice(),
                imageUrl: returnImage(),
                title: getTitle(product),
                isGroup,
                childProducts,
              });
            }

            dispatch({
              type: `RESOLVED_ECM_CURRATED_PRODUCTS_${catID}`,
              payload: curratedProductList,
              catID,
            });
          } else {
            dispatch({
              type: `REJECTED_ECM_CURRATED_PRODUCTS_${catID}`,
              catID,
            });
          }
        } else {
          dispatch({
            type: `REJECTED_ECM_CURRATED_PRODUCTS_${catID}`,
            catID,
          });
        }
      })
      .catch(() => {
        dispatch({
          type: `REJECTED_ECM_CURRATED_PRODUCTS_${catID}`,
          catID,
        });
      });
  };
}

export function getTotalForNewArrival(catID = '') {
  return (dispatch, getState) => {
    const state = getState();
    const { user, session } = state;
    const requestApi = httpWithLogging(state, 6000);
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        ucid: get(user, 'ucid', ''),
        rid: get(user, 'rid', 'US'),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        W2A: get(session, 'W2A', ''),
      }),
    };
    const naCallString = `${NMConfig.API_PRODUCT_LIST}?categoryId=${catID}&fetchSize=1`;
    dispatch({ type: LOADING_NA_TOTAL });
    return requestApi
      .get(naCallString, { headers })
      .then(res => {
        if (res.data && res.data.total) {
          dispatch({
            type: RESOLVED_NA_TOTAL,
            payload: res.data.total,
          });
        } else {
          dispatch({ type: REJECTED_NA_TOTAL });
        }
      })
      .catch(() => dispatch({ type: REJECTED_NA_TOTAL }));
  };
}

export function setHideOnDesktop(ecmSlot) {
  return dispatch => {
    dispatch({ type: SET_HIDE_ON_DESKTOP, contentId: ecmSlot.contentId });
  };
}

export function setHideOnTablet(ecmSlot) {
  return dispatch => {
    dispatch({ type: SET_HIDE_ON_TABLET, contentId: ecmSlot.contentId });
  };
}

export function setHideOnMobile(ecmSlot) {
  return dispatch => {
    dispatch({ type: SET_HIDE_ON_MOBILE, contentId: ecmSlot.contentId });
  };
}

export function getECMRequest(
  ecmSlot,
  catId = '',
  categoryIds = '',
  productId = '',
) {
  return (dispatch, getState) => {
    const pdpBottomBannerCacheKey = `${ECMSlots.PDP_BANNER_BOTTOM.contentId}_cache_${productId}`;
    dispatch({ type: `LOADING_ECM${ecmSlot.contentId}` });
    const state = getState();
    const { locale, session = {}, abTests = {} } = state;
    const context = state.api && state.api.requestContext;
    const requestApi = httpWithLogging(state, 100000);
    const headers = getConfig(session, context);

    if (catId.length > 0) {
      headers.catId = catId;
    }
    if (productId.length > 0) {
      headers.productId = productId;
    }
    if (categoryIds.length > 0) {
      headers.categoryIds = categoryIds;
    }

    let ecmPreviewParam = '';
    if (context) {
      ecmPreviewParam = isEmpty(context.ECMPreview)
        ? ''
        : `ECMPreview=${context.ECMPreview}`;
    }
    const href = `${NMConfig.API_ECM}${ecmSlot.serviceUrl}${
      !isEmpty(ecmPreviewParam) ? '?' : ''
    }${ecmPreviewParam}`;
    if (session.personalize && session.dt_personalize_preview) {
      const dtPersonalizePreview = JSON.parse(session.dt_personalize_preview);
      if (!isEmpty(dtPersonalizePreview.aemAbTestUsergroup)) {
        headers.abTestUserGroup = dtPersonalizePreview.aemAbTestUsergroup;
      }
      if (!isEmpty(dtPersonalizePreview.countryCode)) {
        headers.countryCode = dtPersonalizePreview.countryCode;
      }
      if (!isEmpty(dtPersonalizePreview.bestCustomer)) {
        headers.bc = dtPersonalizePreview.bestCustomer;
      }
      if (!isEmpty(dtPersonalizePreview.customerScore)) {
        headers.customerScore = dtPersonalizePreview.customerScore;
      }
      if (!isEmpty(dtPersonalizePreview.customerJourneySegment)) {
        headers.cjs = dtPersonalizePreview.customerJourneySegment;
      }
      if (!isEmpty(dtPersonalizePreview.emailSubscriber)) {
        headers.emailSub = dtPersonalizePreview.emailSubscriber;
      }
      if (!isEmpty(dtPersonalizePreview.plcc)) {
        headers.plcc = dtPersonalizePreview.plcc;
      }
      if (!isEmpty(dtPersonalizePreview.inCircleLevel)) {
        headers.inCircleLevel = dtPersonalizePreview.inCircleLevel;
      }
    } else {
      if (locale) {
        if (!isEmpty(locale.countryCode)) {
          headers.countryCode = locale.countryCode;
        }
      }
      if (abTests) {
        if (!isEmpty(abTests.aemAbTestUsergroup)) {
          headers.abTestUserGroup = abTests.aemAbTestUsergroup;
        }
      }
      if (context) {
        if (!isEmpty(context.dtPersonalizeData.bestCustomer)) {
          headers.bc = context.dtPersonalizeData.bestCustomer;
        }
        if (!isEmpty(context.dtPersonalizeData.customerScore)) {
          headers.customerScore = context.dtPersonalizeData.customerScore;
        }
        if (!isEmpty(context.dtPersonalizeData.customerJourneySegment)) {
          headers.cjs = context.dtPersonalizeData.customerJourneySegment;
        }
        if (!isEmpty(context.dtPersonalizeData.emailSubscriber)) {
          headers.emailSub = context.dtPersonalizeData.emailSubscriber;
        }
        if (!isEmpty(context.dtPersonalizeData.plcc)) {
          headers.plcc = context.dtPersonalizeData.plcc;
        }
        if (!isEmpty(context.dtPersonalizeData.inCircleLevel)) {
          headers.inCircleLevel = context.dtPersonalizeData.inCircleLevel;
        }
      }
    }
    const startTime = new Date();
    const ecmCache = getCache(homeTopCacheKey);
    const ecmCacheST = getCache(siteTickerCacheKey);
    const ecmPdpBottomBannerCache = getCache(pdpBottomBannerCacheKey);
    const homeTop = ECMConstants.HOME_TOP.contentId;
    const siteTicker = ECMConstants.SITE_TICKER.contentId;

    if (ecmCache && ecmSlot.contentId === homeTop) {
      benchmarkCache(startTime, `getECMRequest ${homeTop} with node-cache`);
      dispatch({
        type: `RESOLVED_ECM${ecmSlot.contentId}`,
        payload: ecmCache,
        contentId: ecmSlot.contentId,
        headers,
      });
      return Promise.resolve();
    }

    if (ecmCacheST && ecmSlot.contentId === siteTicker) {
      benchmarkCache(startTime, `getECMRequest ${siteTicker} with node-cache`);
      dispatch({
        type: `RESOLVED_ECM${ecmSlot.contentId}`,
        payload: ecmCacheST,
        contentId: ecmSlot.contentId,
        headers,
      });
      return Promise.resolve();
    }

    if (
      ecmPdpBottomBannerCache &&
      ecmSlot.contentId === ECMSlots.PDP_BANNER_BOTTOM.contentId
    ) {
      benchmarkCache(
        startTime,
        `getECMRequest ${pdpBottomBannerCacheKey} with node-cache`,
      );

      dispatch({
        type: `RESOLVED_ECM${ecmSlot.contentId}`,
        payload: ecmPdpBottomBannerCache,
        contentId: ecmSlot.contentId,
        headers,
        isMock: state.page.location.query.mock,
      });
      return Promise.resolve();
    }

    return requestApi
      .get(href, { headers })
      .then(successResponse => {
        if (ecmSlot.slots) {
          dispatch({
            type: `RESOLVED_ECM${ecmSlot.contentId}`,
            payload: {},
            contentId: ecmSlot.contentId,
            headers,
            isMock: state.page.location.query.mock,
          });
          dispatch({
            type: `RESOLVED_ECM${ECMSlots[ecmSlot.slots[0]].contentId}`,
            payload: get(successResponse, `data[${ecmSlot.elements}][0]`, null),
            contentId: ECMSlots[ecmSlot.slots[0]].contentId,
            headers,
            isMock: state.page.location.query.mock,
          });
          dispatch({
            type: `RESOLVED_ECM${ECMSlots[ecmSlot.slots[1]].contentId}`,
            payload: get(successResponse, `data[${ecmSlot.elements}][1]`, null),
            contentId: ECMSlots[ecmSlot.slots[1]].contentId,
            headers,
            isMock: state.page.location.query.mock,
          });
          dispatch({
            type: `RESOLVED_ECM${ECMSlots[ecmSlot.slots[2]].contentId}`,
            payload: get(successResponse, `data[${ecmSlot.elements}][2]`, null),
            contentId: ECMSlots[ecmSlot.slots[2]].contentId,
            headers,
            isMock: state.page.location.query.mock,
          });
          dispatch({
            type: ADD_PROMO_TILES_FLAG_TO_UTAG_DATA,
            payload: {
              promoTileFlag: getPromoTileFlag(successResponse, ecmSlot),
            },
          });
        } else {
          if (ecmSlot.contentId === homeTop && !isEmpty(successResponse.data)) {
            benchmarkCache(
              startTime,
              `getECMRequest ${homeTopCacheKey} without node-cache`,
            );
            setCache(homeTopCacheKey, successResponse.data, COMPONENT_HOME_TOP);
          }
          if (ecmSlot.contentId === siteTicker) {
            benchmarkCache(
              startTime,
              `getECMRequest ${siteTickerCacheKey} without node-cache`,
            );
            setCache(
              siteTickerCacheKey,
              successResponse.data,
              COMPONENT_SITE_TICKER,
            );
          }

          // ecmSlot.contentId is pdpBannerBottom
          if (
            ecmSlot.contentId === ECMSlots.PDP_BANNER_BOTTOM.contentId &&
            isEmpty(successResponse.data)
          ) {
            benchmarkCache(
              startTime,
              `getECMRequest ${pdpBottomBannerCacheKey} without node-cache`,
            );
            setCache(
              pdpBottomBannerCacheKey,
              successResponse.data,
              COMPONENT_PDP_BOTTOM_BANNER,
            );
          }

          dispatch({
            type: `RESOLVED_ECM${ecmSlot.contentId}`,
            payload: successResponse.data,
            contentId: ecmSlot.contentId,
            headers,
            isMock: state.page.location.query.mock,
          });
        }
      })
      .catch(() => dispatch({ type: `REJECTED_ECM${ecmSlot.contentId}` }));
  };
}

export function preloadedECMRequest(ecmSlot, data) {
  return dispatch => {
    dispatch({
      type: `RESOLVED_ECM${ecmSlot.contentId}`,
      payload: data,
      contentId: ecmSlot.contentId,
    });
  };
}

export function getECMSimpleRequest(ecmSlot) {
  return (dispatch, getState) => {
    dispatch({ type: `LOADING_ECM${ecmSlot.contentId}` });
    const state = getState();
    const { session = {} } = state;
    const context = state.api && state.api.requestContext;
    const CONTENT_SERVICE_TIMEOUT = get(
      state,
      'apiTimeouts.CONTENT_SERVICE_TIMEOUT',
    );
    const requestApi = httpWithLogging(state, CONTENT_SERVICE_TIMEOUT);
    const headers = getConfig(session, context);

    const startTime = new Date();
    const ecmSimpleRequestCache = getCache('ecm_cache_simple_request'); // cache mechanism needs refacoring in API
    if (ecmSimpleRequestCache) {
      const endTime = new Date() - startTime;
      logger.info(`getECMSimpleRequest homeTop with node-cache=${endTime}`);
      return dispatch({
        type: `RESOLVED_ECM${ecmSlot.contentId}`,
        payload: ecmSimpleRequestCache,
        contentId: ecmSlot.contentId,
        headers,
        isMock: state.page.location.query.mock,
      });
    }

    return requestApi
      .get(`${NMConfig.API_ECM}${ecmSlot.serviceUrl}`, { headers })
      .then(successResponse => {
        if (ecmSlot.contentId === 'homeTop' && !isEmpty(successResponse.data)) {
          setCache(
            'ecm_cache_simple_request',
            successResponse.data,
            COMPONENT_HOME_TOP,
          );
        }
        dispatch({
          type: `RESOLVED_ECM${ecmSlot.contentId}`,
          payload: successResponse.data,
          contentId: ecmSlot.contentId,
          headers,
          isMock: state.page.location.query.mock,
        });
      })
      .catch(() => dispatch({ type: `REJECTED_ECM${ecmSlot.contentId}` }));
  };
}

export function getECMRequestWithPersonalization(
  ecmSlot,
  catId = '',
  personalizeSlotsToggle,
) {
  return (dispatch, getState) => {
    const state = getState();
    const bazaarVoiceToggle = state.toggles.BAZAAR_VOICE;
    let MBOX_IDS = '';
    if (personalizeSlotsToggle) {
      MBOX_IDS = [...HP_SLOTS_MBOX_ID];
    }
    if (bazaarVoiceToggle) {
      MBOX_IDS =
        MBOX_IDS === ''
          ? BAZAAR_VOICE_MBOX_ID
          : `${MBOX_IDS}, ${BAZAAR_VOICE_MBOX_ID}`;
    }
    Promise.all([
      dispatch(getECMRequest(ecmSlot, catId)),
      dispatch(getABTestAssignments(`${MBOX_IDS}`, true)),
    ])
      .then(responses => {
        dispatch({
          type: 'RESOLVED_PERSONALIZATION',
          payload: responses[1],
        });
      })
      .catch(() => {
        dispatch({ type: 'REJECTED_PERSONALIZATION' });
      });
  };
}

export function getPersonalization(custId) {
  return (dispatch, getState) => {
    const state = getState();
    const CP_PERSONALIZATION_API_TIMEOUT = get(
      state,
      'apiTimeouts.CP_PERSONALIZATION_API_TIMEOUT',
      500,
    );
    const requestApi = httpWithLogging(state, CP_PERSONALIZATION_API_TIMEOUT);
    const queryString = `client_id=neimanmarcus&cust_id=${custId}`;
    return requestApi
      .get(`${NMConfig.API_COHERENT_PATH_RECS}?${queryString}`)
      .then(successResponse => {
        logger.info('Successful Coherent Path Recommendations Request', {
          client_id: 'neimanmarcus',
          cust_id: custId,
          WID: get(state, 'session.WID'),
          JSESSIONID: get(state, 'session.JSESSIONID'),
        });
        return successResponse.data;
      })
      .catch(e => {
        logger.error('Exception in Coherent Path Recommendations Request:', e);
      });
  };
}

export function getECMRequestWithCPPersonalization(ecmSlot, catId = '') {
  return (dispatch, getState) => {
    let state = getState();
    const callAbTest = shouldLoad(
      get(state, 'api.abtest', {
        loading: false,
        resolved: false,
        rejected: false,
      }),
    );
    const abTestCP = get(
      state,
      'toggles.ABTEST_COHERENT_PATH_PERSONALIZATION',
      false,
    );
    const custId = state.session._cplid
      ? state.session._cplid
      : new Date().getTime();
    Promise.all([
      dispatch(getECMRequest(ecmSlot, catId)),
      dispatch(getPersonalization(custId)),
      callAbTest &&
        abTestCP &&
        dispatch(getABTestAssignments(CP_PERSONALIZATION_MBOX_ID, true)),
    ])
      .then(responses => {
        state = getState();
        const useCP = abTestCP
          ? get(state, 'abTests.cpPersonalization', false)
          : true;
        const hasContent = !isEmpty(
          get(state, 'ecm.ecmContent', {})[ecmSlot.contentId],
        );
        const cpFailed = responses[1] === undefined;
        const hasCPContent = !isEmpty(responses[1] || {});
        useCP &&
          !cpFailed &&
          hasContent &&
          hasCPContent &&
          dispatch({
            type: 'RESOLVED_CP_PERSONALIZATION',
            payload: responses[1],
            contentId: ecmSlot.contentId,
          });

        const reason = [];
        if (!useCP) reason.push('Control Group');
        if (!hasContent) reason.push('Content Service Failed');
        if (cpFailed) reason.push('Coherent Path Failed');
        if (!cpFailed && !hasCPContent) {
          reason.push('Coherent Path Empty Response');
        }

        const cpFlag = useCP && hasContent && hasCPContent && !cpFailed ? 1 : 0;
        logger.info('Coherent Path Pixel Request', {
          client_id: 'neimanmarcus',
          cust_id: custId,
          WID: get(state, 'session.WID'),
          JSESSIONID: get(state, 'session.JSESSIONID'),
          is_control: cpFlag,
          reason: reason.join(','),
        });
        const queryString = `clientId=neimanmarcus&cplid=${custId}&action=is_control&is_control=${cpFlag}`;
        dispatch({ type: 'SET_P13N_PXL', payload: `${queryString}` });
      })
      .catch(() => {
        dispatch({ type: 'REJECTED_CP_PERSONALIZATION' });
      });
  };
}

export function setSelectedFacet(facetId, ecmSlot) {
  return dispatch => {
    dispatch({
      type: SET_SELECTED_FACET,
      payload: facetId,
      contentId: ecmSlot.contentId,
    });
  };
}

export function loadMoreMagazines(ecmSlot) {
  return (dispatch, getState) => {
    dispatch({
      type: `LOADING_ECM${ecmSlot.contentId}_MAGAZINE_LOAD_MORE`,
    });
    const state = getState();
    const { session = {} } = state;
    const context = state.api && state.api.requestContext;
    const CONTENT_SERVICE_TIMEOUT = get(
      state,
      'apiTimeouts.CONTENT_SERVICE_TIMEOUT',
    );
    const requestApi = httpWithLogging(state, CONTENT_SERVICE_TIMEOUT);
    const slotInState = state.ecm.ecmContent[ecmSlot.contentId] || {};
    const selectedFacet =
      state.ecm.ecmContent[ecmSlot.contentId].selectedFacet || '';
    const lastServedStoryId = get(
      slotInState,
      'storyConfig.lastServedStoryId',
      '',
    );
    const categoryIds = get(slotInState, 'storyConfig.storyIdList', []).join(
      ',',
    );
    const headers = getConfig(session, context);
    headers.categoryIds = categoryIds;
    headers.lastServedStoryId = lastServedStoryId;
    const params = [];
    if (selectedFacet !== '' && selectedFacet.toLowerCase() !== 'featured') {
      params.push(`facet=${selectedFacet}`);
    }
    if (context && !isEmpty(context.ECMPreview)) {
      params.push(`ECMPreview=${context.ECMPreview}`);
    }
    params.push(new Date().getTime());
    const href = `${NMConfig.API_ECM}${ecmSlot.serviceUrl}${
      params.length > 0 ? '?' : ''
    }${params.join('&')}`;
    return requestApi
      .get(href, { headers })
      .then(successResponse => {
        return dispatch({
          type: `RESOLVED_ECM${ecmSlot.contentId}_MAGAZINE_LOAD_MORE`,
          payload: successResponse.data,
          contentId: ecmSlot.contentId,
          headers,
        });
      })
      .catch(() =>
        dispatch({
          type: `REJECTED_ECM${ecmSlot.contentId}_MAGAZINE_LOAD_MORE`,
        }),
      );
  };
}

export function selectFacet(facet, ecmSlot) {
  return (dispatch, getState) => {
    dispatch({ type: `LOADING_ECM${ecmSlot.contentId}_MAGAZINE_FACET` });
    const state = getState();
    const { session = {} } = state;
    const context = state.api && state.api.requestContext;
    const CONTENT_SERVICE_TIMEOUT = get(
      state,
      'apiTimeouts.CONTENT_SERVICE_TIMEOUT',
    );
    const requestApi = httpWithLogging(state, CONTENT_SERVICE_TIMEOUT);
    const slotInState = state.ecm.ecmContent[ecmSlot.contentId] || {};
    const categoryIds = get(slotInState, 'storyConfig.storyIdList', []).join(
      ',',
    );
    const headers = getConfig(session, context);
    headers.categoryIds = categoryIds;
    const params = [];
    if (!isEmpty(facet)) params.push(`facet=${facet}`);
    if (context && !isEmpty(context.ECMPreview)) {
      params.push(`ECMPreview=${context.ECMPreview}`);
    }
    params.push(new Date().getTime());
    const href = `${NMConfig.API_ECM}${ecmSlot.serviceUrl}${
      params.length > 0 ? '?' : ''
    }${params.join('&')}`;
    return requestApi
      .get(href, { headers })
      .then(successResponse => {
        return dispatch({
          type: `RESOLVED_ECM${ecmSlot.contentId}_MAGAZINE_FACET`,
          payload: successResponse.data,
          contentId: ecmSlot.contentId,
          headers,
        });
      })
      .catch(() =>
        dispatch({
          type: `REJECTED_ECM${ecmSlot.contentId}_MAGAZINE_FACET`,
        }),
      );
  };
}
