export const MiniHubTexts = {
  error: 'Something went wrong...',
  statusTitle: 'Your Status:',
};

export const PointCardsTexts = {
  title: 'Your Point Cards',
  info: 'Point cards can be applied at checkout',
};

export const YourPointsTexts = {
  title: 'Your Points',
  strikingDistance: 'points until your next $100 point card',
};

export const CallToActionKeys = {
  INCIRCLE_DETAILS: 'incircle-details',
  ACCOUNT_DETAILS: 'account-details',
  MY_ORDERS: 'my-orders',
};

export const CallToActionTexts = {
  ctaList: [
    {
      id: CallToActionKeys.INCIRCLE_DETAILS,
      title: 'Incircle Details',
      path: '/e/myaccount/bgcreditcard',
    },
    {
      id: CallToActionKeys.ACCOUNT_DETAILS,
      title: 'Account Details',
      path: '/e/myaccount/accountdetails',
    },
    {
      id: CallToActionKeys.MY_ORDERS,
      title: 'My Orders',
      path: '/e/myaccount/myorders',
    },
  ],
};

export const YourBenefitsTexts = {
  title: 'Your Benefits',
  content: {
    text: 'Get free 2-Day shipping with promo code',
    promoCode: 'INCIRCLE',
  },
};
