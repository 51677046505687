import React from 'react';
import { connect } from 'react-redux';

import AppWrapper from './common/app-common';
import AmericanExpressUpdatePage from '../amexUpdate';
import '../amexUpdate/amexUpdate.scss';

const AmericanExpressUpdate = ({ bgoLoyaltyToggle }) => {
  return (
    <AppWrapper forceFullBleed>
      {bgoLoyaltyToggle && <AmericanExpressUpdatePage />}
    </AppWrapper>
  );
};

const mapStateToProps = state => ({
  bgoLoyaltyToggle: state?.toggles?.BGO_LOYALTY_NEW || false,
});

export default connect(mapStateToProps)(AmericanExpressUpdate);
