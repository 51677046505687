import React from 'react';
import { bool, object } from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import merge from 'lodash/merge';
import Slider from 'react-slick';
import { isNewHomePage } from '@bgo-ui/common/client/utilities/is-new-home-page';
import RenderContentItem from '../../../contentItem/contentItem';
import { defaultTrackingTags } from '../../../../constants/constants';
import { getTags } from '../../../utils';
import Image from '../../../../../../../bgo-ui-pdp/src/client/components/pdp/components/ProductPage/components/Image/image';
import './fullWidthCarousel.scss';

const CN = 'full-width-carousel';

/**
 * @function DumbFullWidthCarousel
 * @param {Object} props.cmsContentItem
 * @param {Boolean} props.enableAutoPlay
 * @param {Boolean} props.isFixedHeightCarousel
 * @param {Boolean} props.isNewHomePage
 * @param {Object} props.parentTrackingTags
 * @param {Object} props.isLayoutShiftEnabled
 * @returns {React.ReactElement}
 */
export const DumbFullWidthCarousel = ({
  cmsContentItem,
  enableAutoPlay,
  isNewHomePage,
  parentTrackingTags,
  isFixedHeightCarousel,
  domReady,
  isMobilePhone,
  isLayoutShiftEnabled,
}) => {
  const cmsContentFields = get(cmsContentItem, 'fields', {});
  const cmsContentAssets = get(cmsContentFields, 'components', []);

  // Set up tracking tags
  const { trackingTags } = cmsContentFields;
  const currentTags = getTags(trackingTags);
  const mergedTrackingTags = merge({}, parentTrackingTags, currentTags);

  const settings = {
    autoplaySpeed: 5000,
    autoplay: enableAutoPlay,
    className: `${CN}${
      isNewHomePage && isLayoutShiftEnabled
        ? ' new-home-page-carousel layout-shift'
        : isNewHomePage
        ? ' new-home-page-carousel'
        : ''
    }`,
    dotsClass: `${CN}__indicators-group`,
    dots: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
        },
      },
    ],
    speed: 300,
  };

  const sliderComponent = () => {
    return (
      <Slider {...settings}>
        {cmsContentAssets.map((contentItem, index) => {
          if (contentItem.fields) {
            return (
              <RenderContentItem
                key={index}
                cmsContentItem={contentItem}
                parentTrackingTags={mergedTrackingTags}
              />
            );
          }
          return null;
        })}
      </Slider>
    );
  };

  return (
    <React.Fragment>
      {isFixedHeightCarousel ? (
        <div className={`carousel-container ${domReady ? 'slick-loaded' : ''}`}>
          <div className="img-container">
            <div className=" img-wrapper">
              <Image
                src={
                  isMobilePhone
                    ? cmsContentAssets[0].fields.mobileImage[0].url
                    : cmsContentAssets[0].fields.desktopImage[0].url
                }
              />
            </div>
          </div>
          {sliderComponent()}
        </div>
      ) : (
        sliderComponent()
      )}
    </React.Fragment>
  );
};

DumbFullWidthCarousel.defaultProps = {
  parentTrackingTags: defaultTrackingTags,
};

DumbFullWidthCarousel.propTypes = {
  cmsContentItem: object,
  enableAutoPlay: bool,
  isNewHomePage: bool,
  parentTrackingTags: object,
};

const mapStateToProps = state => ({
  enableAutoPlay:
    get(state, 'page.location.query.ENABLE_AUTOPLAY', 'true') === 'true',
  isNewHomePage: isNewHomePage(state),
  isFixedHeightCarousel: get(state, 'toggles.FIXED_HEIGHT_CAROUSEL', false),
  isMobilePhone: get(state, 'device.isMobilePhone', false),
  isLayoutShiftEnabled: get(state, 'toggles.LAYOUT_SHIFT_HOMEPAGE', false),
});

export default connect(mapStateToProps)(DumbFullWidthCarousel);
