import React from 'react';
import AppNoauth from '../app-noauth';
import CommunicationPref from '../../CommunicationPref/CommunicationPref';

export function NoAuthWrapper() {
  return (
    <AppNoauth forceFullWidth>
      {' '}
      <CommunicationPref />
    </AppNoauth>
  );
}

export default NoAuthWrapper;
