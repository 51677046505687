import React, { Component } from 'react';
import {
  BodyB1,
  EyeBrow5,
  Subtitle1,
} from '@bgo-ui/common/Styleguide/Typography';
import { window } from 'window-or-global';
import { connect } from 'react-redux';
import get from 'lodash/get';
import './emailSignup.scss';

class EmailSignup extends Component {
  constructor() {
    super();
    this.emailSignupFormSubmit = this.emailSignupFormSubmit.bind(this);
    this.state = {
      error: false,
    };
  }

  validateEmailInput(emailId) {
    // const regex = /^((([a-zA-Z0-9$+_-])((\.)?[A-Za-z0-9$_+\-!&'*/=?^|~#]+)*))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regex = /^[a-zA-Z0-9](\.?[a-zA-Z0-9_-]){0,}([a-zA-Z0-9])@[a-zA-Z0-9-]+\.([a-zA-Z]{1,6}\.)?[a-zA-Z]{2,6}$/;
    return emailId && regex.test(emailId);
  }

  checkPlusAndHash(emailId) {
    const regex = /(\+|#)/;
    return emailId && regex.test(emailId);
  }

  clearToolTipErrorMessage() {
    this.toolTipText.className = 'email-signup-tool-tip';
    this.setState({ error: false });
  }

  emailSignupFormSubmit(e) {
    if (this.validateEmailInput(this.emailId.value)) {
      if (this.checkPlusAndHash(this.emailId.value)) {
        if (this.props.dt_special_offer) {
          window?.localStorage?.setItem('emailid', '');
          this.emailSignupForm.action = '/services/SpecialOffer';
        } else {
          this.emailSignupForm.action = '/service/sweepstakesRegEmail.jsp';
        }
      } else if (this.props.dt_special_offer) {
        window?.localStorage?.setItem('emailid', this.emailId.value);
        this.emailSignupForm.action = '/services/SpecialOffer';
      } else {
        this.emailSignupForm.action = '/service/registerForEmail.jsp';
      }
      this.setState({ error: false });
    } else {
      this.toolTipText.className = 'email-signup-tool-tip__text';
      this.setState({ error: true });
      e.preventDefault();
    }
  }

  render() {
    return (
      <div className="email-container">
        <div className="grid-footer-100 grid-parent">
          <div className="email-signup">
            <form
              className="email-form"
              ref={input => {
                this.emailSignupForm = input;
              }}
              method="POST"
              noValidate
              onSubmit={this.emailSignupFormSubmit}
            >
              <Subtitle1>
                Sign up for emails and receive 15% <br />
                off your first purchase:
              </Subtitle1>
              <div className="email_holder">
                <BodyB1>
                  <input
                    className={
                      this.state.error
                        ? 'email-signup__input grid-footer-60 grid-footer-100 email-signup_error'
                        : 'email-signup__input grid-footer-60 grid-footer-100'
                    }
                    type="email"
                    ref={input => {
                      this.emailId = input;
                    }}
                    aria-label="email signup"
                    name="email"
                    placeholder="Enter email"
                    alt="please provide valid e-mail address"
                    onChange={() => this.clearToolTipErrorMessage()}
                  />
                </BodyB1>
                <div
                  className="email-signup-tool-tip"
                  ref={input => {
                    this.toolTipText = input;
                  }}
                >
                  <p aria-live="assertive" className="invalid-email">
                    Please enter a valid email address.
                  </p>
                </div>
                <button
                  className="email-signup__submit grid-footer-40 grid-footer-100"
                  type="submit"
                  value="submit"
                  name="emailSubmit"
                  alt="submit"
                  title="Submit"
                >
                  <EyeBrow5>Submit</EyeBrow5>
                </button>
              </div>
              {/* <div className="email-signup-tool-tip" ref={(input) => { this.toolTipText = input; }}>
                <div className="email-signup-tool-tip__image">
                  <img src={String(image)} alt="" />
                </div>
                <span aria-live="assertive">Please enter a valid email address.</span>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    dt_special_offer: get(state, 'toggles.DT_SPECIAL_OFFERS', false),
  };
};

export default connect(mapStateToProps)(EmailSignup);
