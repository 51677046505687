import React from 'react';
import Badges from 'shared/components/Badges/badges';
import get from 'lodash/get';
import partition from 'lodash/partition';
import classnames from 'classnames';
import { connect } from 'react-redux';
import SalePriceWithPromotion from 'shared/components/SalePriceWithPromotion/salePriceWithPromotion';
import EditWishlist from 'bgo-ui-pdp/src/client/components/wishlist/components/WishlistPage/components/Wishlist/editWishlist';
import {
  getProduct,
  openEditWishlist,
  setSelectedIndex,
} from 'bgo-ui-pdp/src/client/components/pdp/components/ProductPage/actions';
import {
  ESTIMATED_DELIVERY_DATE,
  REPLENISH_PREFIX,
  REPLENISH_SUFFIX,
} from 'bgo-ui-pdp/src/client/components/wishlist/constants';
import { ITEM_NOT_AVAILABLE } from '../../../constants';
import WishlistStockDetails from '../WishlistStockDetails/WishlistStockDetails';
import './WishlistProdDetails.scss';

const WishlistProdDetails = ({
  wishlistData,
  prodDisabled,
  canonicalUrl,
  isGreyCssFix,
}) => {
  const sizeKey = get(wishlistData, 'sku.size.key');
  const sizeName = get(wishlistData, 'sku.size.name');
  const color = get(wishlistData, 'sku.color.name');

  const filterPromotions = promotions => {
    return promotions
      ? partition(promotions, { showPromoPrice: false })
      : [[], []];
  };

  const [promotionWithoutPrice, promotionWithPrice] = filterPromotions(
    wishlistData.promotions,
  );
  const { price } = wishlistData;
  const itemPromotionalPrice = price.promotionalPrice;
  const promotionAdornmentColor = get(wishlistData.promotions, [
    '0',
    'priceAdornmentColor',
  ]);
  const prodAvailable = get(wishlistData, 'sku');
  const preOrder = get(wishlistData, 'preOrder', false);
  const backOrder =
    get(wishlistData, 'sku.stockStatusMessage', false) === 'Back Order';
  const isNewArrival = get(wishlistData, 'newArrival', false);
  const isExclusivelyOurs = get(wishlistData, 'onlyAtNM', false);
  const showBadge = !!(
    preOrder ||
    backOrder ||
    isNewArrival ||
    isExclusivelyOurs ||
    promotionWithoutPrice.length
  );
  const { stockLevel } = prodAvailable;
  const { shippingStatusMessage } = wishlistData.sku;
  const { stockStatusMessage } = wishlistData.sku;
  const stockMessage = stockStatusMessage.replace(/\./g, ''); // To Remove . at the end of response

  const showStockMessage = !(
    (prodAvailable.sellable && stockLevel >= 6) ||
    (stockLevel < 6 && stockLevel !== 0) ||
    (stockLevel === 0 &&
      prodAvailable.stockStatusMessage === ITEM_NOT_AVAILABLE)
  );

  // eslint-disable-next-line react/no-danger
  const renderStringHtml = htmlContent => (
    <span dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );

  const specialCharacterList = ['&egrave', '&#232'];
  const isHtmlItem = name =>
    specialCharacterList.some(item => name.includes(item));
  const renderName = name => (isHtmlItem && renderStringHtml(name)) || name;

  return (
    <div
      className={classnames(
        'prod-details',
        isGreyCssFix && 'prod-details-tablet',
      )}
    >
      <div className={classnames(prodDisabled && 'divDisabled')}>
        <div className="prod-details__title">
          <p className="prod-details__title__brand">
            <a href={canonicalUrl}>{renderName(wishlistData.designerName)}</a>
          </p>
          <p className="prod-details__title__name">
            <a href={canonicalUrl}>{renderName(wishlistData.name)}</a>
          </p>
        </div>

        {showBadge && (
          <div className="prod-details__badges">
            <Badges
              preOrder={preOrder}
              backOrder={backOrder}
              isNewArrival={isNewArrival}
              isExclusivelyOurs={isExclusivelyOurs}
              promotions={{ promotionWithoutPrice, field: 'itemHTML' }}
            />
          </div>
        )}
        <div className="prod-details__options">
          {color && (
            <p className="prod-details__options__option">{`Color: ${color}`}</p>
          )}
          {sizeKey && sizeName && (
            <p className="prod-details__options__option">{`Size: ${sizeName}`}</p>
          )}
        </div>

        <SalePriceWithPromotion
          price={price}
          promotionAdornmentColor={promotionAdornmentColor}
          promotions={promotionWithPrice}
          promotionalPrice={itemPromotionalPrice}
        />
        {showStockMessage &&
          (preOrder || backOrder ? (
            <p className="prod-delDate">{`${shippingStatusMessage}`}</p>
          ) : (
            <p className="prod-delDate">{`${stockMessage} - ${shippingStatusMessage}`}</p>
          ))}
        <WishlistStockDetails prodAvailable={prodAvailable} />
        {get(wishlistData, 'deliveryDate', null) && (
          <p className="prod-delDate">{`${ESTIMATED_DELIVERY_DATE} ${wishlistData.deliveryDate}`}</p>
        )}
        {get(wishlistData, 'replenishInterval', null) && (
          <p className="prod-replenish">{`${REPLENISH_PREFIX} (${wishlistData.replenishInterval} ${REPLENISH_SUFFIX})`}</p>
        )}
      </div>
      <div className="prod-details__edit">
        <EditWishlist wishlistData={wishlistData} />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  isGreyCssFix: state.toggles.GREYCOLOR_CSS_FIX,
});

const mapDispatchToProps = (dispatch, { productId }) => ({
  openEditWishlist: (...args) => dispatch(openEditWishlist(productId, ...args)),
  getProduct: (productId, ...args) => dispatch(getProduct(productId, ...args)),
  setSelectedIndex: (productId, ...args) =>
    dispatch(setSelectedIndex(productId, ...args)),
});

// export default WishlistProdDetails;
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WishlistProdDetails);
