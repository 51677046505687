import React from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { ecmGetAdaptiveStyleBlock } from '../../utilities';
import ConnectedEcmSiteTickerItems from './ecmSiteTickerItems';

const EcmSiteTicker = ({
  dataObj,
  ecmSlot,
  ecmCallToActionButtonWithTextToggle = false,
  improvePerformanceOfStyleToggle,
}) => {
  const ecmStyle = get(ecmSlot, 'className', '');
  const ecmComponentId = get(dataObj, 'componentId', '');
  const ecmProperties = get(dataObj, 'properties', {});
  const ecmObjectStyle = `ecm-container__siteticker__${ecmComponentId}`;
  const classes = classNames(
    'ecm-container__siteticker',
    ecmStyle,
    ecmObjectStyle
  );
  const adaptiveStyleBlock = ecmGetAdaptiveStyleBlock(
    ecmProperties,
    ecmObjectStyle
  );

  return (
    <div className="grid-100 tablet-grid-100 mobile-grid-100 grid-parent">
      <div className={classes}>
        {adaptiveStyleBlock}
        <ConnectedEcmSiteTickerItems
          dataObj={dataObj}
          ecmSlot={ecmSlot}
          ecmCallToActionButtonWithTextToggle={
            ecmCallToActionButtonWithTextToggle
          }
          improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
        />
      </div>
    </div>
  );
};

export default EcmSiteTicker;
