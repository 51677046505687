import React from 'react';
import _get from 'lodash/get';
import { isMobile } from 'client-utils/utilities-page';

const Image = ({ content }) => {
  const {
    altText,
    linkTo,
    desktopImage: [{ secure_url: desktopImage } = {}] = [],
    mobileImage: [{ secure_url: mobileImage } = {}] = [],
  } = _get(content, 'fields', {});
  const cdnHosts = (global.cloudinary && global.cloudinary.cdnHosts) || {};
  const imageSrc = isMobile() && mobileImage ? mobileImage : desktopImage;

  const cdnImageSrc = Object.entries(cdnHosts).reduce(
    (url, [cloudinaryHost, nmHost]) => url.replace(cloudinaryHost, nmHost),
    imageSrc,
  );

  const renderImage = () => (
    <div className="image-container">
      <img src={cdnImageSrc} alt={altText} title={altText} />
    </div>
  );

  if (!cdnImageSrc) return null;

  if (linkTo) return <a href={linkTo}>{renderImage()}</a>;

  return renderImage();
};

export default Image;
