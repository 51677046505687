import React from 'react';

import MobileMiniCart from './MobileMiniCart/MobileMiniCart';
import DesktopTabletMiniCart from './DesktopTabletMiniCart/DesktopTabletMiniCart';

import './miniCart.scss';

const MiniCart = () => (
  <div className="miniCartContainer">
    <DesktopTabletMiniCart />
    <MobileMiniCart />
  </div>
);

export default MiniCart;
