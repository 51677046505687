import React, { Fragment } from 'react';
import _get from 'lodash/get';
import ContentType from '../ContentType/contentType';

const ContentSlot = ({ content }) => {
  const fields = _get(content, 'fields', {});
  switch (fields.type) {
    case 'Page - Category Listing':
    case 'Position - Top Banner':
    case 'Position - Bottom Banner': {
      const contentSlots = _get(fields, 'contentSlots', []);
      return (
        <Fragment>
          {contentSlots.map(item => (
            <ContentSlot content={item} key={item.sys.id} />
          ))}
        </Fragment>
      );
    }
    case 'Layout 50/50':
    case 'Layout 33/33/33':
    case 'Layout 100': {
      const contentAssets = _get(fields, 'contentAssets', []);
      const contentSlots = _get(fields, 'contentSlots', []);
      const items = [...contentAssets, ...contentSlots];
      let className = 'content-grid-100';
      if (fields.type === 'Layout 50/50') className = 'content-grid-50';
      if (fields.type === 'Layout 33/33/33') className = 'content-grid-33';

      return (
        <div className={className}>
          {items.map(item => (
            <ContentType content={item} key={item.sys.id} />
          ))}
        </div>
      );
    }
    default:
      return null;
  }
};

export default ContentSlot;
