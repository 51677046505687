import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  hideSpinner,
  showSpinner,
} from 'shared/components/Spinner/spinner-actions';
import {
  amplifyLogin,
  amplifySignUp,
  validateEmail,
} from 'client-utils/utilities-amplify';
import { setKmsiStatus } from '@bgo-ui/common/client/utilities/utilities-kmsi';
import {
  PAGE_ID_REGISTRATION,
  setPageId,
} from '@bgo-ui/common/client/common/actions/actions-page';
import Contentful from '@bgo-ui/common/client/contentful';
import { RegisterInputField } from '../../common/RegisterInputField/RegisterInputField';
import './AccountRegistration.scss';
import { PasswordInputWidget } from '../PasswordInputWidget/PasswordInputWidget';
import { resolvedRegistrationPage } from '../actions';
import { contentfulIdentifier } from '../constants';

const isBlank = str => {
  return !str || str.trim().length === 0;
};

const fields = {
  firstName: {
    name: 'firstName',
    title: 'First Name',
  },
  lastName: {
    name: 'lastName',
    title: 'Last Name',
  },
  email: {
    name: 'email',
    title: 'Email Address',
  },
  confirmEmail: {
    name: 'confirmEmail',
    title: 'Confirm Email Address',
  },
  password: {
    name: 'password',
    title: 'Create Password',
  },
  confirmPassword: {
    name: 'confirmPassword',
    title: 'Confirm Password',
  },
};

const getRequiredFieldError = field =>
  `Please provide an entry for the ${field} Field.`;

export const somethingWentWrongError =
  'Something went wrong, Please try again.';

export class DumbAccountRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      confirmEmail: '',
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      submitted: false,
      validEmail: false,
      passwordValid: false,
      emailExists: false,
      callbackError: '',
      loginTries: 0,
    };
    props.setPageId(PAGE_ID_REGISTRATION);
  }

  componentDidMount() {
    if (this.props.isDomLoadComplete) {
      this.props.resolvedRegistrationPage();
    } else {
      addEventListener('load', this.handleLoad);
    }
  }

  onSubmit = e => {
    e.preventDefault();

    const validations = this.validate();
    if (!Object.values(validations).includes(false)) {
      this.signUp();
    }
    return this.setState({ submitted: true });
  };

  handleLoad = () => {
    this.props.resolvedRegistrationPage();
    removeEventListener('load', this.handleLoad);
  };

  signupErrCallback = err => {
    const { endLoading } = this.props;

    if (err.indexOf('already exists') !== -1) {
      this.setState({ emailExists: true });
    } else if (
      err.indexOf('Username should be either an email or a phone number') !==
        -1 ||
      err.indexOf('e-mail address entered is invalid') !== -1
    ) {
      this.setState({ validEmail: false });
    } else {
      this.setState({
        callbackError: somethingWentWrongError,
      });
    }
    endLoading();
  };

  loginSuccessCallback = () => {
    const returnToUrl = new URLSearchParams(location.search).get('returnToURL');
    setKmsiStatus(false);
    window.location.href = returnToUrl || NMConfig.ACCOUNT_PAGE_URL;
  };

  loginCallbackError = () => {
    const {
      endLoading,
      disableATGToggle,
      ucaProfileCookieToggle,
      dtLoginFlow,
    } = this.props;
    const { email, password } = this.state;

    const justRegistered = true;
    this.setState({
      loginTries: this.state.loginTries + 1,
    });
    if (this.state.loginTries < 3) {
      amplifyLogin(
        email,
        password,
        this.loginCallbackError,
        justRegistered,
        this.loginSuccessCallback,
        disableATGToggle,
        ucaProfileCookieToggle,
        dtLoginFlow,
      );
    } else {
      this.setState({
        callbackError: somethingWentWrongError,
      });
      endLoading();
    }
  };

  signUp = () => {
    const {
      startLoading,
      disableATGToggle,
      ucaProfileCookieToggle,
      dtLoginFlow,
    } = this.props;
    const { email, password, firstName, lastName } = this.state;

    startLoading();
    this.setState({ callbackError: '' });
    amplifySignUp(
      email,
      password,
      firstName,
      lastName,
      this.signupErrCallback,
      this.loginCallbackError,
      this.loginSuccessCallback,
      disableATGToggle,
      ucaProfileCookieToggle,
      dtLoginFlow,
    );
  };

  validate = () => {
    const {
      firstName,
      lastName,
      email,
      confirmEmail,
      password,
      passwordValid,
      confirmPassword,
      validEmail,
      emailExists,
    } = this.state;

    return {
      firstNameIsNotBlank: !isBlank(firstName),
      lastNameIsNotBlank: !isBlank(lastName),
      emailIsNotBlank: !isBlank(email),
      emailIsValid: validEmail,
      emailIsNotDuplicate: !emailExists,
      confirmEmailIsNotBlank: !isBlank(confirmEmail),
      emailsMatch: email === confirmEmail,
      passwordIsValid: passwordValid,
      confirmPasswordIsNotEmpty: !!confirmPassword,
      passwordsMatch: password === confirmPassword,
    };
  };

  handlePasswordValidChange = passwordValid => {
    this.setState({ passwordValid });
  };

  handleChange = (e, isEmail) => {
    if (isEmail) {
      this.setState({
        validEmail: validateEmail(e.target.value),
        emailExists: false,
      });
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const {
      submitted,
      firstName,
      lastName,
      email,
      confirmEmail,
      passwordValid,
      confirmPassword,
      callbackError,
    } = this.state;

    const validations = this.validate();
    const emailErrorMessage = () => {
      if (submitted) {
        if (!validations.emailIsNotBlank) {
          return getRequiredFieldError('New Email Address');
        }
        if (!validations.emailIsValid) {
          return 'Please provide a valid email address.';
        }
        if (!validations.emailIsNotDuplicate) {
          return `A user already exists with the login ${email}`;
        }
      }
      return null;
    };

    const confirmEmailErrorMessage = () => {
      if (submitted) {
        if (!validations.confirmEmailIsNotBlank) {
          return getRequiredFieldError('Confirm Email Address');
        }
        if (!validations.emailsMatch) {
          return 'The new email address must match the confirm email address.';
        }
      }
      return null;
    };

    const confirmPasswordErrorMessage = () => {
      if (submitted) {
        if (!validations.confirmPasswordIsNotEmpty) {
          return 'Passwords must be populated.';
        }
        if (!validations.passwordsMatch) {
          return 'Passwords entered do not match.';
        }
      }
      return null;
    };

    const firstNameError =
      submitted &&
      !validations.firstNameIsNotBlank &&
      getRequiredFieldError('Account First Name');

    const lastNameError =
      submitted &&
      !validations.lastNameIsNotBlank &&
      getRequiredFieldError('Account Last Name');

    const callbackErrorMessage = callbackError && (
      <p className="account-registration__callback-error">{callbackError}</p>
    );

    const attentionFields = [];
    if (submitted) {
      if (firstNameError) {
        attentionFields.push(fields.firstName);
      }
      if (lastNameError) {
        attentionFields.push(fields.lastName);
      }
      if (emailErrorMessage()) {
        attentionFields.push(fields.email);
      }
      if (confirmEmailErrorMessage()) {
        attentionFields.push(fields.confirmEmail);
      }
      if (!passwordValid) {
        attentionFields.push(fields.password);
      }
      if (confirmPasswordErrorMessage()) {
        attentionFields.push(fields.confirmPassword);
      }
    }

    return (
      <div className="account-registration">
        <p className="account-registration__message">Account Registration</p>
        <div className="account-registration__content-container">
          <div className="account-registration__form-block account-registration__form">
            <h2 className="account-registration__header">Create an account</h2>
            <div className="account-registration__attention-needed">
              {attentionFields.length > 0 && (
                <div>
                  <h3 className="account-registration__attention-needed-header">
                    Your attention is needed at:
                  </h3>
                  <p>
                    {attentionFields.map(field => (
                      <span
                        key={field.title}
                        className="account-registration__attention-needed-link"
                      >
                        <a href={`#register-input-${field.name}`}>
                          {field.title}
                        </a>
                      </span>
                    ))}
                  </p>
                </div>
              )}
            </div>
            <form>
              <RegisterInputField
                title={fields.firstName.title}
                name={fields.firstName.name}
                error={firstNameError}
                maxLength={40}
                value={firstName}
                onChange={this.handleChange}
              />

              <RegisterInputField
                title={fields.lastName.title}
                name={fields.lastName.name}
                error={lastNameError}
                maxLength={40}
                value={lastName}
                onChange={this.handleChange}
              />

              <RegisterInputField
                title={fields.email.title}
                name={fields.email.name}
                error={emailErrorMessage()}
                maxLength={40}
                value={email}
                onChange={e => this.handleChange(e, 'email')}
              />

              <RegisterInputField
                title={fields.confirmEmail.title}
                name={fields.confirmEmail.name}
                error={confirmEmailErrorMessage()}
                maxLength={40}
                value={confirmEmail}
                onChange={this.handleChange}
              />

              <PasswordInputWidget
                title={fields.password.title}
                name={fields.password.name}
                submitted={submitted}
                onValidChange={this.handlePasswordValidChange}
                onChange={this.handleChange}
              />

              <RegisterInputField
                title={fields.confirmPassword.title}
                name={fields.confirmPassword.name}
                error={confirmPasswordErrorMessage()}
                maxLength={40}
                type="password"
                value={confirmPassword}
                onChange={this.handleChange}
              />

              {callbackErrorMessage}

              <button
                type="submit"
                className="account-registration__button"
                onClick={this.onSubmit}
              >
                Register Now
              </button>
            </form>
          </div>
          <div className="account-registration__form-block account-registration__banner">
            <Contentful
              identifier={contentfulIdentifier.registrationForm}
              version={2}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  startLoading: showSpinner,
  endLoading: hideSpinner,
  setPageId,
  resolvedRegistrationPage,
};

const mapStateToProps = state => ({
  isDomLoadComplete: get(state, 'page.isDomLoadComplete', false),
  disableATGToggle: get(state, 'toggles.DISABLE_ATG_CALLS', false),
  ucaProfileCookieToggle: get(state, 'toggles.UCA_PROFILE_COOKIE', false),
  dtLoginFlow: get(state, 'toggles.DT_LOGIN_FLOW', false),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbAccountRegistration);
