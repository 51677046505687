import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Button from '@bgo-ui/common/client/common/components/Button/button';
import BGLogo from 'assets/images/bg-logo-vertical.svg';
import { IN_CIRCLE_APPLY_NOW_LINK } from '../constants';

const NotAMember = ({ applySection }) => {
  return (
    <section className="not-a-member-section content-section">
      <div className="center-section">
        <h2 className="title title-header">{applySection.title}</h2>
        {applySection.desc?.map(section => (
          <div key={section.id} className="text-container">
            {section.title && (
              <h3 className="description title-header">{section.title}</h3>
            )}
            <p className="description">{section.desc}</p>
            <br />
          </div>
        ))}
        <br />
        <img src={BGLogo} alt="bg-logo" alr="bg-logo" />
        <div className="center-button">
          <a
            className="anchor-button"
            href={IN_CIRCLE_APPLY_NOW_LINK}
            target="_blank"
            rel="noreferrer"
          >
            <Button className="amex-button" value={applySection.buttonText} />
          </a>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = state => ({
  applySection: get(state, 'loyaltyConfig.americanExpress.applySection', ''),
});

export default connect(mapStateToProps)(NotAMember);
