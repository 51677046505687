import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Copy from '../../../../common/Copy/Copy';
import { deleteMyAccountPayment, closePaymentDeletemodal } from '../actions';
import { deleteButtonText, cancelButtonText } from '../../../constants';
import './PaymentDeleteModal.scss';

const PaymentDeleteModal = props => {
  const deletePayment = props => {
    const isDefault = [
      props.myAccountAddress.find(
        item => item.addressId === props.billingAddressId,
      ) || {},
    ].some(data => data.defaultAddress);
    if (isDefault) {
      props.deleteMyAccountPayment(
        props.paymentId,
        props.billingAddressId,
        true,
      );
    } else {
      props.deleteMyAccountPayment(props.paymentId);
    }
  };
  return (
    <div className="payment-del-modal__content">
      <Copy
        content={get(props.myAccountContent, 'PAYMENTDETAILS-DELETE-TITLE')}
      />
      <Copy
        content={get(props.myAccountContent, 'PAYMENTDETAILS-DELETE-DEFAULT')}
      />
      <button
        className="default-select"
        type="button"
        onClick={() => deletePayment(props)}
      >
        {deleteButtonText}
      </button>
      <button type="button" onClick={() => props.closePaymentDeletemodal()}>
        {cancelButtonText}
      </button>
    </div>
  );
};

const mapStateToProps = state => ({
  myAccountContent: get(state, 'myaccount.myAccountContent', {}),
  myAccountAddress: get(state, 'myaccount.myAccountAddress', []),
});

const mapDispatchToProps = {
  deleteMyAccountPayment,
  closePaymentDeletemodal,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentDeleteModal);
