import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectUserLocale } from '../../CountrySelector/countrySelector-actions';
import { getCurrencyCode, isMobile } from 'client/utilities/utilities-page';
import { countryCurrencyData } from './countryCurrencyData';

class CountryChooser extends Component {
  constructor() {
    super();
    this.handleCountryChange = this.handleCountryChange.bind(this);
  }

  handleCountryChange(e) {
    const userLocale = countryCurrencyData[e.target.selectedIndex];
    this.props.selectUserLocale(
      userLocale.countryCode,
      getCurrencyCode(userLocale.countryCode),
      userLocale.url,
    );
  }

  render() {
    const optionList = countryCurrencyData.map((countryValues, index) => (
      <option key={index} value={countryValues.countryCode}>
        {countryValues.countryName}
      </option>
    ));
    return (
      <p>
        <b>Destination Shipping Country</b>
        <select
          className="country-select"
          onChange={this.handleCountryChange}
          value={this.props.selectedCountryCode}
          ref={ref => {
            !isMobile() && ref && setImmediate(() => ref.focus());
          }}
        >
          {optionList}
        </select>
      </p>
    );
  }
}

const mapStateToProps = state => ({
  selectedCountryCode: state.locale.selectedCountryCode,
});
const mapDispatchToProps = {
  selectUserLocale,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CountryChooser);
