import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import {
  DISCONTINUED_CODES_YR,
  NON_DISCONTINUED_CODE,
  LIMITED_INVENTORY,
} from 'pdp/constants';
import { EyeBrow1, EyeBrow2 } from 'bgo-common/Styleguide/Typography';

const BACK_ORDER = 'Back Order';
const IN_STOCK = 'In Stock';
const MERCHANDISE_TYPE_OTHERS = 'OTHERS';
const onlyXLeft = quantity => `Only ${quantity} Left`;
const shouldShowOnlyXLeft = (sku, threshold) =>
  sku.stockLevel &&
  sku.stockLevel <= threshold &&
  !sku.dropShip &&
  !sku.backOrder &&
  !sku.preOrder;

const updateStockStatusMessageForXLeft = (
  sku,
  preciousJewelryItem,
  isChanel,
  threshold,
) =>
  shouldShowOnlyXLeft(sku, threshold) &&
  !preciousJewelryItem &&
  !isChanel &&
  `${LIMITED_INVENTORY}, ${onlyXLeft(sku.stockLevel)}`;

const updateStockStatusMessageForBackOrder = (sku, selectedSkuQuantity) => {
  if (sku.inStock && selectedSkuQuantity > sku.stockLevel) {
    if (sku.discontinuedCode === NON_DISCONTINUED_CODE) return BACK_ORDER;
    if (
      DISCONTINUED_CODES_YR.includes(sku.discontinuedCode) &&
      sku.poQuantity
    ) {
      return BACK_ORDER;
    }
  }
  return '';
};

const SkuStatusMessages = props => {
  const {
    selectedSku = {},
    perishable,
    selectedSkuQuantity,
    onlyXLeftThreshold,
    preciousJewelryItem,
    isChanel,
    merchandiseType = '',
    isWishlist = false,
  } = props;
  const shippingStatus = selectedSku.shippingStatusMessage;
  const merchandiseTypeValidation =
    merchandiseType === MERCHANDISE_TYPE_OTHERS &&
    selectedSku.dropShip === true &&
    selectedSku.sellable === true;
  const stockStatus = merchandiseTypeValidation
    ? IN_STOCK
    : selectedSku.stockStatusMessage;
  const messages = [];

  if (stockStatus) {
    const backOrderMessage = updateStockStatusMessageForBackOrder(
      selectedSku,
      selectedSkuQuantity,
    );
    const onlyXLeftMessage = updateStockStatusMessageForXLeft(
      selectedSku,
      preciousJewelryItem,
      isChanel,
      onlyXLeftThreshold,
    );

    if (backOrderMessage) {
      messages.push(backOrderMessage);
    } else if (onlyXLeftMessage) {
      messages.push(onlyXLeftMessage);
    } else {
      // Show messages except "In Stock" - BGO-8537
      // eslint-disable-next-line no-lonely-if
      if (isWishlist) {
        // eslint-disable-line no-lonely-if
        const statusMsg =
          stockStatus.toLowerCase() === 'item not available'
            ? 'Out of Stock'
            : stockStatus;
        messages.push(statusMsg);
      } else {
        stockStatus !== IN_STOCK && messages.push(stockStatus);
      }
    }
  }

  if (!merchandiseTypeValidation && shippingStatus && !perishable) {
    messages.push(shippingStatus);
  }

  if (!messages.length) {
    return false;
  }

  return (
    <div className={'sku-status-messages'}>
      {isWishlist
        ? messages.map((message, index) =>
            message.includes('vendor') ? (
              <p key={index} className="stockClass">
                <EyeBrow1>{message}</EyeBrow1>
              </p>
            ) : (
              <p key={index}>
                <EyeBrow1>
                  <span
                    className={
                      message === 'In Stock' || message === 'Out of Stock' // eslint-disable-line no-nested-ternary
                        ? message === 'In Stock'
                          ? 'inStock'
                          : 'outOfStock'
                        : 'stockClass'
                    }
                  >
                    {message}
                  </span>
                </EyeBrow1>
              </p>
            ),
          )
        : messages.map((message, index) =>
            message.includes('vendor') ? (
              <p key={index} className="vendorTxt">
                <EyeBrow1>{message}</EyeBrow1>
              </p>
            ) : (
              <p
                key={index}
                className={
                  message === 'Pre-Order' || message === 'Back Order'
                    ? 'orderTxt'
                    : 'message'
                }
              >
                <EyeBrow2>{message}</EyeBrow2>
              </p>
            ),
          )}
    </div>
  );
};

const mapStateToProps = state => {
  const siloCategory = get(state.navigation, 'breadcrumbs[0].id', '');
  const onlyXLeftThresholds = state.onlyXLeftThresholds;
  const onlyXLeftThreshold = get(
    onlyXLeftThresholds,
    siloCategory,
    onlyXLeftThresholds['defaultThreshold'],
  );
  return { onlyXLeftThreshold };
};

export default connect(mapStateToProps)(SkuStatusMessages);
