import { combineReducers } from 'redux';
import productReducer from './reducers/productReducer';
import groupReducer from './reducers/groupReducer';
import recommendationsReducer from './reducers/recommendationsReducer';
import relatedItemsReducer from './reducers/relatedItemsReducer';
import storeReducer from './reducers/storeReducer';
import sddReducer from './reducers/sddReducer';
import paginationReducer from './reducers/paginationReducer';
import outfittingReducer from './reducers/outfittingReducer';
import applePayReducer from './reducers/applePayReducer';

export default combineReducers({
  product: productReducer,
  group: groupReducer,
  recommendations: recommendationsReducer,
  outfitting: outfittingReducer,
  relatedItems: relatedItemsReducer,
  stores: storeReducer,
  sdd: sddReducer,
  boss: (state = {}) => state,
  vendorQtyRestrictions: (state = {}) => state,
  zeroDollarPageAllBeautyLink: (state = {}) => state,
  maxPageForAll: (state = {}) => state,
  pdpPagination: paginationReducer,
  productPageImageTimeout: (state = {}) => state,
  outfittingConf: (state = {}) => state,
  defaultStoreValues: (state = {}) => state,
  pdpConfigurator: (state = {}) => state,
  applePay: applePayReducer,
  pdpExcludeSeoDesigners: (state = {}) => state,
});
