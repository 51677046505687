import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

// import shoppingBagSvgPurple from 'assets/images/shopping-bag.svg';
// import shoppingBagSvgBalck from 'assets/images/shopping-bag-black.svg'
import { EyeBrow2 } from '@bgo-ui/common/Styleguide/Typography';
import bagIcon from '../../../../assets/images/icons-bag-empty.svg';
import './mobileMiniCart.scss';

const MobileMiniCart = ({
  noOfItemsInCart,
  user,
  internationalToggle,
  isDomestic,
}) => {
  const shoppingBagAltText = `Shopping Bag containing ${noOfItemsInCart} items`; // Changed for BGO-500

  // const shoppingBagSvg = noOfItemsInCart > 0
  //   ? String(shoppingBagSvgPurple)
  //   : String(shoppingBagSvgBalck);
  const shoppingBagSvg = String(bagIcon); // String(shoppingBagSvgBalck);
  const isLoggedIn = user.name && user.securityStatus === 'Authenticated';
  const shoppingBagLink =
    isLoggedIn && noOfItemsInCart
      ? '/checkout/cart.jsp?perCatId=&catqo=&co=true'
      : '/checkout/cart.jsp?perCatId=&catqo=';

  return (
    <div className="device-only mobile-header-shopping-bag-icon-container">
      <a
        id="miniCartButtonMobile"
        href={
          internationalToggle && !isDomestic
            ? '/international/cart'
            : shoppingBagLink
        }
        role="button"
      >
        <div className="items-in-shopping-bag-container">
          {noOfItemsInCart ? (
            <span aria-hidden="true" className="miniCart-count">
              <EyeBrow2>{noOfItemsInCart}</EyeBrow2>
            </span>
          ) : (
            ''
          )}
          <img
            className="mobile-header-shopping-bag-icon-with-items"
            src={shoppingBagSvg}
            alt={shoppingBagAltText}
          />
        </div>
      </a>
    </div>
  );
};

const mapStateToProps = state => ({
  noOfItemsInCart: state.miniCart.noOfItemsInCart,
  user: state.user,
  isDomestic: get(state, 'locale.countryCode', 'US') === 'US',
  internationalToggle: get(state, 'toggles.INTERNATIONAL', false),
});

export default connect(mapStateToProps)(MobileMiniCart);
