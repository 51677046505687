import React from 'react';
import classNames from 'classnames';
import '../FormButtons.scss';

const CancelButton = ({ caption, onClick, additionalClasses }) => {
  return (
    <button
      className={classNames('form-buttons__cancel-btn', additionalClasses)}
      onClick={onClick}
    >
      {caption}
    </button>
  );
};

export default CancelButton;
