import React from 'react';
import { connect } from 'react-redux';
import hamburger from '../../../assets/images/hamburger.svg';
import searchIcon from '../../../assets/images/icons-search.svg';
import {
  toggleNavSliderMenu,
  toggleNavSliderAndLoadSilos,
} from '../actions-navigation';
import { getSliderMenuVisible } from '../NavSlider/navSlider-reducer';
import './mobileNav.scss';

let hasDataLoaded = false;

const MobileNav = ({
  sliderMenuVisible,
  toggleNavSliderMenu,
  toggleNavSliderAndLoadSilos,
}) => {
  const toggleMenu = () => {
    toggleNavSliderMenu();
  };

  const toggleMenuAndLoadData = () => {
    toggleNavSliderAndLoadSilos();
  };

  return (
    <div className="mobile-navigation-hamburger-nav-container hide-on-desktop grid-0 tablet-grid-0 mobile-grid-0">
      <a
        aria-expanded={sliderMenuVisible}
        role="button"
        onClick={() =>
          hasDataLoaded
            ? toggleMenu()
            : (hasDataLoaded = true) && toggleMenuAndLoadData()
        }
        tabIndex="0"
        className="device-only"
      >
        <img
          className="mobile-navigation-hamburger-nav"
          alt="Mobile navigation icon"
          aria-label="Opening mobile navigation"
          src={String(hamburger)}
        />
        <img
          className="mobile-navigation-search-icon"
          alt="Mobile search icon"
          aria-label="Opening mobile search"
          src={String(searchIcon)}
          width="0"
          height="0"
        />
      </a>
    </div>
  );
};

const mapStateToProps = state => ({
  sliderMenuVisible: getSliderMenuVisible(state),
});

const mapDispatchToProps = {
  toggleNavSliderMenu,
  toggleNavSliderAndLoadSilos,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MobileNav);
