import { types as AppTypes } from 'shared/components/App/app-actions';
import { types as MiniCartTypes } from './miniCart-actions';

const initialState = {
  cartInfo: '',
  recentlyAddedItemCount: 0,
  noOfItemsInCart: 0,
  timerId: 0,
  animateClosed: false,
  cartOpened: false,
  mouseOverShoppingBag: false,
  cartInventoryCheck: '',
  cartInventoryCheckError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MiniCartTypes.LOADING_MINI_CART:
      clearTimeout(state.timerId);
      return {
        ...state,
        timerId: action.timerId,
        animateClosed: false,
        cartOpened: true,
      };
    case MiniCartTypes.RESOLVED_MINI_CART:
      return {
        ...state,
        cartInfo: action.payload,
      };
    case AppTypes.LOSE_FOCUS:
      clearTimeout(state.timerId);
      return {
        ...state,
        timerId: 0,
        cartInfo: '',
      };
    case MiniCartTypes.UPDATE_MINI_CART_ITEM_COUNT:
      return {
        ...state,
        noOfItemsInCart: action.noOfItemsInCart,
      };
    case MiniCartTypes.SET_CLOSE_TIMER:
      return {
        ...state,
        timerId: action.timerId,
      };
    case MiniCartTypes.ANIMATE_CART_CLOSED:
      return {
        ...state,
        animateClosed: true,
        cartOpened: false,
      };
    case MiniCartTypes.SET_RECENTLY_ADDED_ITEM_COUNT:
      return {
        ...state,
        recentlyAddedItemCount: action.recentlyAddedItemCount,
      };
    case MiniCartTypes.SET_MOUSE_OVER_SHOPPING_BAG:
      return {
        ...state,
        mouseOverShoppingBag: action.mouseOverShoppingBag,
      };
    case MiniCartTypes.REJECTED_MINI_CART:
      return {
        ...state,
        cartInfo: null,
      };
    case MiniCartTypes.RESOLVED_INVENTORY_CHECK:
      return {
        ...state,
        cartInventoryCheck: action.payload,
      };
    case MiniCartTypes.REJECTED_INVENTORY_CHECK:
      return {
        ...state,
        cartInventoryCheckError: true,
      };
    default:
      return state;
  }
};
