import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { shouldLoad } from 'universal/http-client';
import { addBodyClass, removeBodyClass } from 'client-utils/bodyClass';

import NavSlider from './NavSlider/navSlider';
import ConnectedNavBar from './NavBar/navBar';
import './navigation.scss';
import {
  getSilosDesktop,
  getSilosMobileInitial,
  fetchSilosForNewMobileNav,
  validateSegmentSiloStatus,
} from './actions-navigation';

const Navigation = ({
  apiNavigation,
  apiNavigationMobile,
  fetchNav,
  fetchNavMobile,
  sliderMenuVisible,
  loadNewMobileNav,
  fetchSilosForNewMobileNav,
  router,
  isNewMobileNavABTestOn,
  componentInFocus,
  loggedInCustomerEmail,
  validateSegmentSiloStatus,
  segmentSiloResponseReceived,
}) => {
  if (!segmentSiloResponseReceived && loggedInCustomerEmail) {
    validateSegmentSiloStatus(loggedInCustomerEmail);
  }

  if (shouldLoad(apiNavigation)) {
    fetchNav();
  }

  if (shouldLoad(apiNavigationMobile)) {
    if (loadNewMobileNav) {
      fetchSilosForNewMobileNav();
    } else {
      fetchNavMobile();
    }
  }
  componentInFocus && componentInFocus !== '#miniCartContainer'
    ? addBodyClass('show-overlay')
    : removeBodyClass('show-overlay');

  if (loadNewMobileNav && isNewMobileNavABTestOn) {
    return (
      <div className="navigation" id="mobilenav">
        <hr className="hr-class" />
        <ConnectedNavBar router={router} />
        {sliderMenuVisible
          ? ReactDOM.createPortal(
            <NavSlider />,
              document.getElementById('navSliderContainer'),
            )
          : false}
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <div className="navigation desktop-only">
          <hr className="hr-class" />
          <ConnectedNavBar router={router} />
          {sliderMenuVisible
            ? ReactDOM.createPortal(
              <NavSlider />,
                document.getElementById('navSliderContainer'),
              )
            : false}
        </div>
        <div className="nav-slider-overlay nav-slider-overlay-desktop" />
      </React.Fragment>
    );
  }
};

const mapStateToProps = state => ({
  apiNavigation: state.api.navigation,
  apiNavigationMobile: state.api.navigation_mobile,
  sliderMenuVisible: state.navigation.navSlider.sliderMenuVisible,
  loadNewMobileNav: false,
  isNewMobileNavABTestOn: false,
  isNewMobileNavOn: state.abTests.isNewMobileNavOn,
  componentInFocus: state.app.componentInFocus,
  loggedInCustomerEmail: get(state, 'utag.userSession.customer_email', false),
  segmentSiloResponseReceived: get(
    state,
    'navigation.segmentSailo.responseReceived',
    false,
  ),
});

const mapDispatchToProps = {
  fetchNav: getSilosDesktop,
  fetchNavMobile: getSilosMobileInitial,
  fetchSilosForNewMobileNav,
  validateSegmentSiloStatus,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Navigation);
