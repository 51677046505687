import React, { Component } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import { connect } from 'react-redux';
import { shouldLoad } from 'universal/http-client';

import {
  getCmsProducts,
  getCategoryProducts,
} from '../../actions/get-cms-products';
// eslint-disable-next-line import/no-cycle
import ContentItem from '../contentItem/contentItem';
import ProductTile from './productTile';
import './productTile.scss';

export class ProductGrid extends Component {
  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    const {
      cmsProductsApi,
      cmsProductGridApi,
      idsToSearch,
      contentId,
    } = this.props;
    if (idsToSearch.length === 1 && shouldLoad(cmsProductsApi)) {
      this.props.dispatch(getCategoryProducts(idsToSearch));
    } else if (shouldLoad(cmsProductGridApi)) {
      this.props.dispatch(getCmsProducts(idsToSearch, contentId));
    }
  }

  combineProducts(cmsProducts, childProducts) {
    return cmsProducts.map(product =>
      /* eslint-disable max-len */
      product.isGroup && product.childProductIds
        ? childProducts.find(
            childProduct => childProduct.id === product.childProductIds[0],
          ) || product
        : product,
    );
  }

  shiftProductItems(products, promoTileMap) {
    let tilesAdded = 0;
    return products
      .flatMap((p, i) => {
        if (`${i + tilesAdded}` in promoTileMap) {
          tilesAdded += 1;
          return [undefined, p];
        }
        return [p];
      })
      .slice(0, products.length);
  }

  renderProducts(products, promoTiles, is2x2, isMobilePhone) {
    const promoTileMap = keyBy(
      promoTiles,
      promoTile => promoTile.fields.position - 1,
    );
    return this.shiftProductItems(products, promoTileMap).map(
      (product, index) => {
        if (index in promoTileMap) {
          const styles =
            is2x2 || isMobilePhone
              ? classNames(
                  'product-thumbnail-list promo-tile grid-50 tablet-grid-50 mobile-grid-50',
                )
              : classNames(
                  'product-thumbnail-list promo-tile grid-25 tablet-grid-25 mobile-grid-50',
                );
          return (
            <div className={styles}>
              <ContentItem
                cmsContentItem={
                  promoTileMap[index].fields &&
                  promoTileMap[index].fields.content
                }
              />
            </div>
          );
        }
        return (
          <ProductTile
            id={product.id}
            designer={product.designerName}
            name={product.name}
            price={product.price}
            imageUrl={product.imageUrl}
            url={product.url}
            promotions={product.promotions}
            displayable={product.displayable}
            is2x2={is2x2}
            isMobilePhone={isMobilePhone}
          />
        );
      },
    );
  }

  render() {
    const {
      is2x2 = false,
      isMobilePhone = false,
      productsByCategory,
      cmsProducts,
      childProducts,
      promoTiles,
    } = this.props;
    let filteredProducts = [];
    if (!isEmpty(childProducts)) {
      filteredProducts = this.combineProducts(cmsProducts, childProducts);
    }
    const combinedProducts = !isEmpty(filteredProducts)
      ? filteredProducts
      : cmsProducts;

    return (
      <div className="product-grid">
        {productsByCategory &&
          this.renderProducts(
            productsByCategory,
            promoTiles,
            is2x2,
            isMobilePhone,
          )}
        {combinedProducts &&
          this.renderProducts(
            combinedProducts,
            promoTiles,
            is2x2,
            isMobilePhone,
          )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isMobilePhone: get(state, 'device.isMobilePhone'),
  cmsProductsApi:
    state.api[`cms_currated_products_${ownProps.idsToSearch}`.toLowerCase()],
  productsByCategory:
    state.cms.productsByCategory &&
    state.cms.productsByCategory[ownProps.idsToSearch],
  cmsProductGridApi:
    state.api[`cms_products_${ownProps.contentId}`.toLowerCase()],
  cmsProducts: state.cms.products && state.cms.products[ownProps.contentId],
  childProducts:
    state.cms.childProducts && state.cms.childProducts[ownProps.contentId],
});

export default connect(mapStateToProps)(ProductGrid);
