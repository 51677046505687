import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import './InCircleInfo.scss';
import Copy from '../../../../common/Copy/Copy';

const InCircleInfo = ({ myAccountContent }) => {
  return (
    <div className="incircle-info">
      {!isEmpty(myAccountContent) && (
        <Fragment>
          <Copy content={get(myAccountContent, 'CREDITCARD-INCIRCLE-INFO')} />
          <div className="incircle-info__action">
            <Copy
              newTab
              content={get(myAccountContent, 'CREDITCARD-INCIRCLE-PAY-CTA')}
            />
            <Copy
              newTab
              content={get(myAccountContent, 'CREDITCARD-INCIRCLE-MANAGE-CTA')}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  myAccountContent: get(state, 'myaccount.myAccountContent', true),
});

export default connect(mapStateToProps)(InCircleInfo);
