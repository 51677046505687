import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { ECMParams } from 'ecm/constants';
import reactCookie from 'react-cookie';
import {
  loseFocus,
  putComponentInFocus,
} from 'client/common/components/App/app-actions';
import { ecmNormalizeFormFields } from 'ecm/utilities';
import { getECMSimpleRequest } from 'ecm/actions/actions-ecmcontent';
import { ECMSlots } from 'ecm/components/EcmGet';
import { shouldLoad } from 'universal/http-client';
import launchImgNoArrow from 'assets/images/previewControl/launch_no_arrow.png';
import incircleImg from 'assets/images/previewControl/incircle.png';
import bestcustomerImg from 'assets/images/previewControl/bestCustomer.png';
import countryImg from 'assets/images/previewControl/country.png';
import emailImg from 'assets/images/previewControl/email.png';
import plccImg from 'assets/images/previewControl/plcc.png';
import segmentImg from 'assets/images/previewControl/segment.png';
import closeImg from 'assets/images/previewControl/close.png';
import checkmarkImg from 'assets/images/previewControl/checkmark.png';
import ab from 'assets/images/previewControl/a-b.png';
import './styles.scss';

const onFocusOut = toggleMenuOff => e => {
  if (e.currentTarget !== e.relatedTarget.offsetParent) {
    toggleMenuOff('.preview-control__account__selection');
  }
};

// eslint-disable-next-line no-unused-vars
const AccountDropdownMenu = ({
  toggleMenuOff,
  personalize = true,
  // eslint-disable-next-line no-unused-vars
  handleAccountClick,
  handleLaunchClick,
}) => {
  return (
    <div
      className="preview-control__account__selections"
      onBlur={onFocusOut(toggleMenuOff)}
    >
      {/*! personalize &&
        <button
        className="preview-control__account__selections-account" onClick={handleAccountClick}>
          <img
           className="preview-control__account__selections-account-img"
           title="Personalize" alt="Personalize" src={accountImgNoArrow} />
        </button>
      */}
      {personalize && (
        <button
          className="preview-control__account__selections-launch"
          onClick={handleLaunchClick}
        >
          <img
            className="preview-control__account__selections-launch-img"
            title="Launches"
            alt="Launches"
            src={launchImgNoArrow}
          />
        </button>
      )}
    </div>
  );
};

class EcmPreviewControl extends Component {
  constructor(props) {
    super(props);
    this.handleInCircleChange = this.handleInCircleChange.bind(this);
    this.handleBestCustomerChange = this.handleBestCustomerChange.bind(this);
    this.handleSegmentChange = this.handleSegmentChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePlccChange = this.handlePlccChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleLaunchChange = this.handleLaunchChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleAccountClick = this.handleAccountClick.bind(this);
    this.handleLaunchClick = this.handleLaunchClick.bind(this);
    this.handleAbChange = this.handleAbChange.bind(this);
    this.state = {
      personalize: false,
      render: false,
      [ECMParams.PARAM_LAUNCH]: undefined,
      forceFocus: false,
    };
  }

  UNSAFE_componentWillMount() {
    if (
      shouldLoad(
        this.props.api[`ecm${ECMSlots.READY_LAUNCHES.contentId.toLowerCase()}`],
      )
    )
      this.props.getECMSimpleRequest(ECMSlots.READY_LAUNCHES);
    this.normalizeFormFields();
  }

  componentDidMount() {
    const { location } = this.props;
    const shouldRender =
      this.shouldRenderComponent() ||
      get(location, `query.${ECMParams.PARAM_PERSONALIZE}`, false);
    if (shouldRender) {
      if (!isEmpty(get(location, `query.${ECMParams.PARAM_PERSONALIZE}`, ''))) {
        reactCookie.save(
          `ECM${ECMParams.PARAM_PERSONALIZE}`,
          get(location, `query.${ECMParams.PARAM_PERSONALIZE}`),
          { path: '/' },
        );
        reactCookie.remove(ECMParams.PARAM_LAUNCH, { path: '/' });
      }
      if (!reactCookie.load('dt_personalize_preview')) {
        reactCookie.save(
          'dt_personalize_preview',
          reactCookie.load('dt_personalize_data')
            ? reactCookie.load('dt_personalize_data', { doNotParse: true })
            : '',
          { path: '/' },
        );
      }
    }
    this.setState({ render: shouldRender });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const apiProp = `ecm${ECMSlots.READY_LAUNCHES.contentId.toLowerCase()}`;
    const api = this.props.api[apiProp];
    const retVal =
      !isEqual(this.state.render, nextState.render) ||
      !isEqual(
        this.state.dtPersonalizePreview,
        nextState.dtPersonalizePreview,
      ) ||
      !isEqual(this.state.personalize, nextState.personalize) ||
      !isEqual(this.state.forceFocus, nextState.forceFocus) ||
      !isEqual(
        this.state[ECMParams.PARAM_LAUNCH],
        nextState[ECMParams.PARAM_LAUNCH],
      ) ||
      !isEqual(this.props.isVisible, nextProps.isVisible) ||
      !isEqual(api, nextProps.api[apiProp]);
    return retVal;
  }

  shouldRenderComponent() {
    const { location, ecmContent } = this.props;
    const launches = get(
      ecmContent,
      `${ECMSlots.READY_LAUNCHES.contentId}`,
      [],
    );
    const paramLaunch = get(
      location,
      `query.${ECMParams.PARAM_LAUNCH}`,
      reactCookie.load(ECMParams.PARAM_LAUNCH),
    );
    this.setState({ [ECMParams.PARAM_LAUNCH]: paramLaunch });
    let returnVal = true;
    if (paramLaunch) {
      returnVal = false;
      launches.forEach(launch => {
        if (launch.name === paramLaunch) {
          returnVal = true;
          this.setState({ personalize: false });
        }
      });
    }
    return returnVal;
  }

  normalizeFormFields() {
    const { locale, session, abTests } = this.props;
    const dtPersonalizePreview = ecmNormalizeFormFields(
      session,
      locale,
      abTests,
    );
    this.setState({ dtPersonalizePreview });
  }

  handleInCircleChange(event) {
    this.setState({
      dtPersonalizePreview: {
        ...this.state.dtPersonalizePreview,
        inCircleLevel: event.target.value,
      },
    });
  }

  handleBestCustomerChange(event) {
    this.setState({
      dtPersonalizePreview: {
        ...this.state.dtPersonalizePreview,
        bestCustomer: event.target.checked,
      },
    });
  }

  handleSegmentChange(event) {
    this.setState({
      dtPersonalizePreview: {
        ...this.state.dtPersonalizePreview,
        customerJourneySegment: event.target.value,
      },
    });
  }

  handleEmailChange(event) {
    this.setState({
      dtPersonalizePreview: {
        ...this.state.dtPersonalizePreview,
        emailSubscriber: event.target.checked,
      },
    });
  }

  handlePlccChange(event) {
    this.setState({
      dtPersonalizePreview: {
        ...this.state.dtPersonalizePreview,
        plcc: event.target.checked,
      },
    });
  }

  handleCountryChange(event) {
    this.setState({
      dtPersonalizePreview: {
        ...this.state.dtPersonalizePreview,
        countryCode: event.target.value,
      },
    });
  }

  handleLaunchChange(event) {
    this.setState({ [ECMParams.PARAM_LAUNCH]: event.target.value });
  }

  handleAbChange(event) {
    this.setState({
      dtPersonalizePreview: {
        ...this.state.dtPersonalizePreview,
        aemAbTestUsergroup: event.target.value,
      },
    });
  }
  handleSubmit(event) {
    const newState = { ...this.state.dtPersonalizePreview };
    newState.customerScore = newState.bestCustomer ? '1' : '0';
    newState.bestCustomer = newState.bestCustomer ? 'y' : 'n';
    newState.emailSubscriber = newState.emailSubscriber ? 'y' : 'n';
    newState.plcc = newState.plcc ? 'y' : 'n';
    reactCookie.save('dt_personalize_preview', JSON.stringify(newState), {
      path: '/',
    });
    reactCookie.save(`ECM${ECMParams.PARAM_PERSONALIZE}`, 'true', {
      path: '/',
    });

    if (this.state.personalize) {
      reactCookie.remove(ECMParams.PARAM_LAUNCH, { path: '/' });
    } else {
      reactCookie.save(
        ECMParams.PARAM_LAUNCH,
        this.state[ECMParams.PARAM_LAUNCH],
        { path: '/' },
      );
    }
    document.location.href = document.location.pathname;
    event.preventDefault();
  }

  handleClose(event) {
    reactCookie.remove(`ECM${ECMParams.PARAM_PERSONALIZE}`, { path: '/' });
    reactCookie.remove(ECMParams.PARAM_LAUNCH, { path: '/' });
    reactCookie.remove('dt_personalize_preview', { path: '/' });
    document.location.href = document.location.pathname;
    event.preventDefault();
  }

  handleAccountClick() {
    this.setState({ personalize: true });
    this.props.toggleMenuOff('.preview-control__account__selection');
    this.setState({ forceFocus: !this.state.forceFocus });
  }

  handleLaunchClick() {
    this.setState({ personalize: false });
    this.props.toggleMenuOff('.preview-control__account__selection');
    this.setState({ forceFocus: !this.state.forceFocus });
  }

  renderAccount() {
    return (
      <td className="preview-control__scrollable">
        <div className="preview-control__overlay hide-on-tablet hide-on-desktop" />
        <div className="preview-control__scrollable__container ">
          <table className="preview-control__fields">
            <tbody>
              <tr>
                <td className="preview-control__field preview-control__incircle">
                  <table className="preview-control__elements">
                    <tbody>
                      <tr>
                        <td className="preview-control__element preview-control__element-img">
                          <img
                            className="preview-control__incircle-img"
                            alt="inCircle"
                            src={String(incircleImg)}
                          />
                        </td>
                        <td className="preview-control__element preview-control__element-text hide-on-tablet hide-on-mobile">
                          InCircle
                        </td>
                        <td className="preview-control__element preview-control__element-input preview-control__element-input-dd">
                          <select
                            name="inCircle"
                            id="inCircle-dd"
                            value={get(
                              this.state,
                              'dtPersonalizePreview.inCircleLevel',
                              0,
                            )}
                            onChange={this.handleInCircleChange}
                          >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td className="preview-control__field preview-control__bestcustomer">
                  <table className="preview-control__elements">
                    <tbody>
                      <tr>
                        <td className="preview-control__element preview-control__element-img">
                          <img
                            className="preview-control__bestcustomer-img"
                            alt="Best Customer"
                            src={String(bestcustomerImg)}
                          />
                        </td>
                        <td className="preview-control__element preview-control__element-text hide-on-tablet hide-on-mobile">
                          Best Customer
                        </td>
                        <td className="preview-control__element preview-control__element-input preview-control__element-input-cb">
                          <input
                            type="checkbox"
                            name="bestCustomer"
                            id="bestCustomer-cb"
                            checked={get(
                              this.state,
                              'dtPersonalizePreview.bestCustomer',
                              false,
                            )}
                            onChange={this.handleBestCustomerChange}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td className="preview-control__field preview-control__segment">
                  <table className="preview-control__elements">
                    <tbody>
                      <tr>
                        <td className="preview-control__element preview-control__element-img">
                          <img
                            className="preview-control__segment-img"
                            alt="Segment"
                            src={String(segmentImg)}
                          />
                        </td>
                        <td className="preview-control__element preview-control__element-text hide-on-tablet hide-on-mobile">
                          Segment
                        </td>
                        <td className="preview-control__element preview-control__element-input preview-control__element-input-dd">
                          <select
                            name="segment"
                            id="segment-dd"
                            value={get(
                              this.state,
                              'dtPersonalizePreview.customerJourneySegment',
                              0,
                            )}
                            onChange={this.handleSegmentChange}
                          >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td className="preview-control__field preview-control__email">
                  <table className="preview-control__elements">
                    <tbody>
                      <tr>
                        <td className="preview-control__element preview-control__element-img">
                          <img
                            className="preview-control__email-img"
                            alt="Email Subscriber"
                            src={String(emailImg)}
                          />
                        </td>
                        <td className="preview-control__element preview-control__element-text hide-on-tablet hide-on-mobile">
                          Email Subscriber
                        </td>
                        <td className="preview-control__element preview-control__element-input preview-control__element-input-cb">
                          <input
                            type="checkbox"
                            name="email"
                            id="email-cb"
                            checked={get(
                              this.state,
                              'dtPersonalizePreview.emailSubscriber',
                              false,
                            )}
                            onChange={this.handleEmailChange}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td className="preview-control__field preview-control__plcc">
                  <table className="preview-control__elements">
                    <tbody>
                      <tr>
                        <td className="preview-control__element preview-control__element-img">
                          <img
                            className="preview-control__plcc-img"
                            alt="PLCC"
                            src={String(plccImg)}
                          />
                        </td>
                        <td className="preview-control__element preview-control__element-text hide-on-tablet hide-on-mobile">
                          PLCC
                        </td>
                        <td className="preview-control__element preview-control__element-input preview-control__element-input-cb">
                          <input
                            type="checkbox"
                            name="plcc"
                            id="plcc-cb"
                            checked={get(
                              this.state,
                              'dtPersonalizePreview.plcc',
                              false,
                            )}
                            onChange={this.handlePlccChange}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td className="preview-control__field preview-control__ab">
                  <table className="preview-control__elements">
                    <tbody>
                      <tr>
                        <td className="preview-control__element preview-control__element-img">
                          <img
                            className="preview-control__ab-img"
                            alt="ab"
                            src={String(ab)}
                          />
                        </td>
                        <td className="preview-control__element preview-control__element-text hide-on-tablet hide-on-mobile">
                          A/B
                        </td>
                        <td className="preview-control__element preview-control__element-input preview-control__element-input-dd">
                          <select
                            name="ab"
                            id="ab-dd"
                            value={get(
                              this.state,
                              'dtPersonalizePreview.aemAbTestUsergroup',
                              '',
                            )}
                            onChange={this.handleAbChange}
                          >
                            <option value="">Default</option>
                            <option value="Control">Control</option>
                            <option value="Test">Test</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td className="preview-control__field preview-control__country">
                  <table className="preview-control__elements">
                    <tbody>
                      <tr>
                        <td className="preview-control__element preview-control__element-img">
                          <img
                            className="preview-control__country-img"
                            alt="Country"
                            src={String(countryImg)}
                          />
                        </td>
                        <td className="preview-control__element preview-control__element-text hide-on-tablet hide-on-mobile">
                          Country
                        </td>
                        <td className="preview-control__element preview-control__element-input preview-control__element-input-dd">
                          <select
                            name="country"
                            id="country-dd"
                            value={get(
                              this.state,
                              'dtPersonalizePreview.countryCode',
                              0,
                            )}
                            onChange={this.handleCountryChange}
                          >
                            <option value="US">US</option>
                            <option value="ROW">ROW</option>
                            <option value="AU">AU</option>
                            <option value="CA">CA</option>
                            <option value="CN">CN</option>
                            <option value="AE">UAE</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </td>
    );
  }

  renderLaunch() {
    const launches =
      this.props.ecmContent[ECMSlots.READY_LAUNCHES.contentId] || [];
    if (!this.state[ECMParams.PARAM_LAUNCH] && launches.length > 0) {
      this.setState({ [ECMParams.PARAM_LAUNCH]: launches[0].name });
    }
    return (
      <td>
        <table className="preview-control__fields">
          <tbody>
            <tr>
              <td className="preview-control__field preview-control__launches">
                <table className="preview-control__elements">
                  <tbody>
                    <tr>
                      <td className="preview-control__element preview-control__element-text">
                        Launches
                      </td>
                      <td className="preview-control__element preview-control__element-input preview-control__element-input-dd">
                        <select
                          name="launch"
                          id="launches-dd"
                          value={this.state[ECMParams.PARAM_LAUNCH]}
                          onChange={this.handleLaunchChange}
                        >
                          {launches.map((launch, index) => {
                            return (
                              <option value={launch.name} key={index}>
                                {launch.name}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    );
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { isVisible, toggleMenuOn, toggleMenuOff, ecmContent } = this.props;
    const launches = get(
      ecmContent,
      `${ECMSlots.READY_LAUNCHES.contentId}`,
      [],
    );
    if (!this.state.render && launches && !launches.length) return false;
    // const accountImg = launches.length > 0 ? accountImgArrow : accountSelect;
    // const controlImg = this.state.personalize ? accountImg : launchImgArrow;
    return (
      <div className="grid-container full-bleed preview-control">
        <form>
          <table className="preview-control__table">
            <tbody>
              <tr>
                <td>
                  <table className="preview-control__control">
                    <tbody>
                      <tr>
                        <td className="preview-control__account">
                          <div className="preview-control__account__selection">
                            <div
                              className="preview-control__account__selection__item"
                              onMouseEnter={() => {
                                toggleMenuOn(
                                  '.preview-control__account__selection',
                                );
                              }}
                              onMouseLeave={() => {
                                toggleMenuOff(
                                  '.preview-control__account__selection',
                                );
                              }}
                              onTouchEnd={() => {
                                toggleMenuOn(
                                  '.preview-control__account__selection',
                                );
                              }}
                            >
                              <img
                                className="preview-control__account-img"
                                alt="Personalize"
                                src={String(launchImgNoArrow)}
                              />
                              {/*
                                isVisible && launches.length > 0 ?
                                  <AccountDropdownMenu
                                    toggleMenuOff={toggleMenuOff}
                                    personalize={this.state.personalize}
                                    handleAccountClick={this.handleAccountClick}
                                    handleLaunchClick={this.handleLaunchClick}
                                  />
                                  : undefined
                              */}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                {/* this.state.personalize && this.renderAccount() */}
                {!this.state.personalize && this.renderLaunch()}
                {!this.state.personalize && (
                  <td>
                    <table className="preview-control__buttons">
                      <tbody>
                        <tr>
                          <td className="preview-control__submit">
                            <button
                              className="hide-on-tablet hide-on-mobile preview-control__submit__button"
                              name="submit"
                              onClick={this.handleSubmit}
                            >
                              Submit
                            </button>
                            <button
                              className="hide-on-desktop"
                              name="submit"
                              onClick={this.handleSubmit}
                            >
                              <img
                                className="preview-control__submit-img"
                                alt="Submit"
                                src={String(checkmarkImg)}
                              />
                            </button>
                          </td>
                          <td className="preview-control__close">
                            <button
                              className="hide-on-tablet hide-on-mobile preview-control__close__button"
                              name="close"
                              onClick={this.handleClose}
                            >
                              Close
                            </button>
                            <button
                              className="hide-on-desktop"
                              name="close"
                              onClick={this.handleClose}
                            >
                              <img
                                className="preview-control__close-img"
                                alt="Close"
                                src={String(closeImg)}
                              />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = {
  toggleMenuOn: putComponentInFocus,
  toggleMenuOff: loseFocus,
  getECMSimpleRequest,
};

const mapStateToProps = state => ({
  api: state.api,
  session: state.session,
  locale: state.locale,
  abTests: state.abTests,
  ecmContent: state.ecm.ecmContent,
  isVisible:
    state.app.componentInFocus === '.preview-control__account__selection',
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EcmPreviewControl);
