import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import classnames from 'classnames';
import AddToBagButton from 'bgo-ui-pdp/src/client/components/pdp/components/ProductPage/components/AddToBagButton/addToBagButton';
import { removeButtonText } from '../../../constants';
import './WishlistButtons.scss';

const WishlistButtons = ({
  removeWishlist,
  skuId,
  selectedSku,
  productId,
  product,
  prodAvailable,
  buttonEnabled,
  api,
}) => {
  return (
    <div className="new-wishlist-buttons">
      <div
        className={classnames(
          'new-wishlist-buttons__add',
          prodAvailable && !buttonEnabled && 'divDisabled',
        )}
      >
        {get(api, `add_to_bag_${skuId}.loading`, false) ? (
          <div className="new-wishlist-buttons__add__loader-wrapper">
            <div className="new-wishlist-buttons__add__loader" />
          </div>
        ) : (
          <AddToBagButton
            productId={productId}
            selectedSku={selectedSku}
            productDetails={product}
            source="wishlist"
            showLoading={!!true}
            fromWishlist
          />
        )}
      </div>

      <div className="new-wishlist-buttons__remove">
        <button
          className="new-wishlist-buttons__remove__btn"
          onClick={removeWishlist}
        >
          {get(api, `remove_wishlist_${skuId}.loading`, false) ? (
            <div className="new-wishlist-buttons__remove__loader" />
          ) : (
            removeButtonText
          )}
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  api: get(state, 'api', {}),
});

export default connect(mapStateToProps)(WishlistButtons);
