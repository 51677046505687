import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getColorOptions } from 'productpage/selectors/getOptions';
import getSelectedColorIndex from 'productpage/selectors/getSelectedColorIndex';
import getProduct from 'pdp/components/ProductPage/selectors/getProduct';
import get from 'lodash/get';
import './TrueFit.scss';

class TrueFit extends Component {
  getColorName() {
    const colorOptions = getColorOptions(this.props.product);
    const selectedColorIndex = getSelectedColorIndex(this.props.product);
    let colorName;
    if (colorOptions.values && colorOptions.values.length > 0) {
      colorName = colorOptions.values[selectedColorIndex].name;
    }
    return colorName;
  }

  isRegisteredUser() {
    const { loggedInStatus, customerRegistered } = this.props;
    if (loggedInStatus === 'true' || customerRegistered === 'true') {
      return true;
    }
    return false;
  }

  render() {
    const { product, WID } = this.props;
    const colorName = this.getColorName();
    const isRegisteredUser = this.isRegisteredUser();
    return (
      <div>
        <div
          className="tfc-fitrec-product true-fit"
          id={product.id}
          data-userid={WID}
          data-registered={isRegisteredUser}
          data-colorid={colorName}
        />
        <script src={`/assets/scripts/Truefit-${NMConfig.TRUEFIT_ENV}.js`} />
        <script
          dangerouslySetInnerHTML={{
            __html: `
          tfcapi('event','tfc-fitrec-product','success',function tfSuccess() {nm.cookie.set('tf_ck', 'true', 0, '/');})`,
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const loggedInStatus = get(state, 'utag.userSession.logged_in_status', '');
  const customerRegistered = get(
    state,
    'utag.userSession.customer_registered',
    '',
  );
  return {
    product: getProduct(state, props.productId),
    WID: state.session.WID,
    loggedInStatus,
    customerRegistered,
  };
};

export default connect(mapStateToProps)(TrueFit);
