import React from 'react';
import './RewardInfo.scss';
import InCircleLogo from '../../../../../assets/gift-cards/inCircle-logo.svg';
import BGgiftLogo from '../../../../../assets/gift-cards/BG-logo.svg';
import NotificationIcon from '../../../../../assets/gift-cards/notification-icon.svg';

const CARD_PATTERN = '************';
const CARD_REPLACE_VALUE = 'xxxx-xxxx-xxxx-';
const InCircleLogoAlt = 'InCircle reward card logo';
const BGgiftLogoAlt = 'BG gift card logo';

const getExpireDate = date => {
  const daysLeft = Math.ceil(
    (new Date(date).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24),
  );
  const expObject = { value: '', warning: false };
  if (daysLeft > 14) {
    const dateOptions = {
      weekday: undefined,
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    expObject.value = `Exp: ${new Date(date).toLocaleDateString(
      'en-US',
      dateOptions,
    )}`;
  } else {
    expObject.value =
      daysLeft === 1 ? 'Expires in 1 day!' : `Expires in ${daysLeft} days!`;
    expObject.warning = true;
  }
  return expObject;
};

const RewardInfo = ({ card, isIncircle }) => {
  const logoSrc = isIncircle ? InCircleLogo : BGgiftLogo;
  const logoAlt = isIncircle ? InCircleLogoAlt : BGgiftLogoAlt;
  const expDate = getExpireDate(card.expire_date);

  return (
    <div
      key={card.accountNumber}
      className={`reward-card ${isIncircle && 'inCircle'}`}
    >
      <img src={logoSrc} alt={logoAlt} />
      <p>
        <span className="reward-card__card-balance">
          ${parseInt(card.last_ptd_balance, 10)}
        </span>
        Current Balance
      </p>
      <div>
        {isIncircle && (
          <p>{card.accountNumber.replace(CARD_PATTERN, CARD_REPLACE_VALUE)}</p>
        )}
        <p className="reward-card__exp-date">
          {expDate.warning && (
            <img src={NotificationIcon} alt={expDate.value} />
          )}
          <span>{expDate.value}</span>
        </p>
      </div>
    </div>
  );
};

export default RewardInfo;
