import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  setDefaultButtonText,
  editButtonText,
  deleteButtonText,
  DAB_MODAL_TYPE,
  DAB_MODAL_STYLE,
} from '../../../constants';
import {
  editMyAccountAddress,
  openAddressModal,
  closeAddressModal,
} from '../actions';
import './AddressCTAModalMobile.scss';

const ADDRESS_TYPE = 'shipping';

const AddressCTAModalMobile = props => {
  const shippingAddress = props.myAccountAddress.filter(
    data => data.addressType === ADDRESS_TYPE,
  );

  const addressDetails =
    shippingAddress &&
    shippingAddress.find(address => address.addressId === props.addressId);
  return (
    <div className="address-cta-modal__content">
      {addressDetails && !addressDetails.defaultAddress && (
        <button
          type="button"
          onClick={() => props.editMyAccountAddress({ defaultAddress: true })}
        >
          {setDefaultButtonText}
        </button>
      )}
      <button type="button" onClick={() => editAddress(props)}>
        {editButtonText}
      </button>
      {addressDetails.defaultAddress && shippingAddress.length === 1 ? null : (
        <button
          type="button"
          onClick={() =>
            props.openAddressModal(DAB_MODAL_TYPE, DAB_MODAL_STYLE)}
        >
          {deleteButtonText}
        </button>
      )}
    </div>
  );
};

function editAddress(props) {
  props.editDetails(true, props.addressId);
  props.closeAddressModal('AddressCTAModal');
}

const mapStateToProps = state => ({
  addressId: get(state, 'myaccount.addressId', true),
  myAccountAddress: get(state, 'myaccount.myAccountAddress', []),
});

const mapDispatchToProps = {
  editMyAccountAddress,
  openAddressModal,
  closeAddressModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddressCTAModalMobile);
