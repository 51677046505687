import values from 'lodash/values';

export const PREFIX = {
  CROP: 'c_',
  WIDTH: 'w_',
  HEIGHT: 'h_',
  ASPECT_RATIO: 'ar_',
  GRAVITY: 'g_',
  X: 'x_',
  Y: 'y_',
  FORMAT: 'f_',
  QUALITY: 'q_',
  RADIUS: 'r_',
  EFFECT: 'e_',
  OPACY: 'o_',
  BORDER: 'bo_',
  BACKGROUND: 'b_',
  COLOR: 'co_',
  COLOR_SPACE: 'cs_',
};

export const CROP = {
  SCALE: 'c_scale',
  FIT: 'c_fit',
  FILL: 'c_fill',
  CROP: 'c_crop',
  PAD: 'c_pad',
};

export const GRAVITY = {
  NORTH_WEST: 'g_north_west',
  NORTH: 'g_north',
  NORTH_EAST: 'g_north_east',
  WEST: 'g_west',
  CENTER: 'g_center',
  EAST: 'g_east',
  SOUTH_WEST: 'g_south_west',
  SOUTH: 'g_south',
  SOUTH_EAST: 'g_south_east',
  XY_CENTER: 'g_xy_center',
};

export const UNIT = {
  PIXEL: 'pixel',
  PERCENT: 'percent',
};

export const SUPPORTED_CROP_MODES = values(CROP);
export const SUPPORTED_GRAVITY_MODES = values(GRAVITY);

export const TRANSFORMATION_DEFAULT_VALUES = {
  width: undefined,
  height: undefined,
  cropMode: CROP.SCALE,
  gravity: GRAVITY.CENTER,
  x: {
    unit: UNIT.PIXEL,
    value: 0,
  },
  y: {
    unit: UNIT.PIXEL,
    value: 0,
  },
  angle: '',
  aspectRatio: undefined,
};
