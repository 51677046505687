export default {
  PDP_BANNER: {
    serviceUrl: '/pages/pdp/banner',
    className: 'pdp_banner',
    contentId: 'pdpBanner',
    fallback: '',
  },
  PDP_BANNER_BOTTOM: {
    serviceUrl: '/contentservice/pages/pdp/bottomBanner',
    className: 'pdp_banner_bottom',
    contentId: 'pdpBannerBottom',
    fallback: '',
  },
  PLP_BANNER_BOTTOM: {
    serviceUrl: '/contentservice/pages/plp/bottomBanner',
    className: 'plp_banner_bottom',
    contentId: 'plpBannerBottom',
    fallback: '',
  },
  HOME_TOP: {
    serviceUrl: '/pages/home',
    className: 'home_top',
    contentId: 'homeTop',
    fallback: ['/category/cat000000/fallback/bgo-fallback.html'],
    personalize: true,
  },
  SITE_TICKER: {
    serviceUrl: '/fragments/siteticker',
    className: 'site_ticker',
    contentId: 'siteTicker',
    fallback: '',
  },
  READY_LAUNCHES: {
    serviceUrl: '/preview/launchDetails',
    className: 'ready_launches',
    contentId: 'readyLaunches',
    fallback: '',
  },
  PLP_BANNER: {
    serviceUrl: '/fragments/category/banner',
    className: 'plp_banner',
    contentId: 'plpBanner',
    fallback: '',
  },
  PLP_TITLE: {
    serviceUrl: '/fragments/category/title',
    className: 'plp_title',
    contentId: 'plpTitle',
    fallback: '',
  },
  MAIN_CONTENT: {
    serviceUrl: '',
    className: 'main_content',
    contentId: 'mainContent',
    fallback: '',
  },
  PLP_BANNER_F0: {
    serviceUrl: '/fragments/category/collections/',
    className: 'plp_banner',
    contentId: 'plpBannerF0',
    fallback: '',
  },
  MAGAZINE_INDEX: {
    serviceUrl: '/magazine/index',
    className: 'magazine_index',
    contentId: 'plpBanner',
    fallback: '',
  },
  SILO_FULL_ASSET: {
    serviceUrl: '',
    className: 'silo_asset',
    contentId: 'siloAsset',
    fallback: '',
  },
  PLP_PROMO_TILE_1: {
    serviceUrl: '/fragments/category/promotile1',
    className: 'plp_promoTile1',
    contentId: 'plpPromoTile1',
    fallback: '',
  },
  PLP_PROMO_TILE_2: {
    serviceUrl: '/fragments/category/promotile2',
    className: 'plp_promoTile2',
    contentId: 'plpPromoTile2',
    fallback: '',
  },
  PLP_PROMO_TILE_3: {
    serviceUrl: '/fragments/category/promotile3',
    className: 'plp_promoTile3',
    contentId: 'plpPromoTile3',
    fallback: '',
  },
  PLP_PROMO_TILE_4: {
    serviceUrl: '/fragments/category/promotile4',
    className: 'plp_promoTile4',
    contentId: 'plpPromoTile4',
    fallback: '',
  },
  PLP_PROMO_TILES: {
    serviceUrl: '/fragments/category/promotile',
    contentId: 'plpPromoTile',
    elements: 'promotile',
    slots: [
      'PLP_PROMO_TILE_1',
      'PLP_PROMO_TILE_2',
      'PLP_PROMO_TILE_3',
      'PLP_PROMO_TILE_4',
    ],
  },
  DRAWER_AEM_ASSET: {
    serviceUrl: '/fragments/silos/drawers',
    className: 'aem_drawer_asset',
    contentId: 'aemDrawerAsset',
    fallback: '',
  },
};

export const ECMParams = {
  PARAM_LAUNCH: 'ECMPreview',
  PARAM_PERSONALIZE: 'personalize',
};

export const ECMCookies = ['ECMPreview'];

export const ECMLayouts = {
  A: ['grid-100 tablet-grid-100 mobile-grid-100 grid-parent'],
  B: [
    'grid-50 tablet-grid-50 mobile-grid-100 grid-parent',
    'grid-50 tablet-grid-50 mobile-grid-100 grid-parent',
  ],
  BINMOBILE: [
    'grid-50 tablet-grid-50 mobile-grid-50 grid-parent',
    'grid-50 tablet-grid-50 mobile-grid-50 grid-parent',
  ],
  C: [
    'grid-33 tablet-grid-33 mobile-grid-100 grid-parent',
    'grid-33 tablet-grid-33 mobile-grid-100 grid-parent',
    'grid-33 tablet-grid-33 mobile-grid-100 grid-parent',
  ],
  CINMOBILE: [
    'grid-33 tablet-grid-33 mobile-grid-33 grid-parent',
    'grid-33 tablet-grid-33 mobile-grid-33 grid-parent',
    'grid-33 tablet-grid-33 mobile-grid-33 grid-parent',
  ],
  D: [
    'grid-25 tablet-grid-25 mobile-grid-50 grid-parent',
    'grid-25 tablet-grid-25 mobile-grid-50 grid-parent',
    'grid-25 tablet-grid-25 mobile-grid-50 grid-parent',
    'grid-25 tablet-grid-25 mobile-grid-50 grid-parent',
  ],
  DINMOBILE: [
    'grid-25 tablet-grid-25 mobile-grid-25 grid-parent',
    'grid-25 tablet-grid-25 mobile-grid-25 grid-parent',
    'grid-25 tablet-grid-25 mobile-grid-25 grid-parent',
    'grid-25 tablet-grid-25 mobile-grid-25 grid-parent',
  ],
  E: [
    'grid-33 tablet-grid-33 mobile-grid-100 grid-parent',
    'grid-66 tablet-grid-66 mobile-grid-100 grid-parent',
  ],
  EINMOBILE: [
    'grid-33 tablet-grid-33 mobile-grid-33 grid-parent',
    'grid-66 tablet-grid-66 mobile-grid-66 grid-parent',
  ],
  F: [
    'grid-66 tablet-grid-66 mobile-grid-100 grid-parent',
    'grid-33 tablet-grid-33 mobile-grid-100 grid-parent',
  ],
  FINMOBILE: [
    'grid-66 tablet-grid-66 mobile-grid-66 grid-parent',
    'grid-33 tablet-grid-33 mobile-grid-33 grid-parent',
  ],
  G: [
    'grid-20 tablet-grid-20 mobile-grid-50 grid-parent',
    'grid-20 tablet-grid-20 mobile-grid-50 grid-parent',
    'grid-20 tablet-grid-20 mobile-grid-50 grid-parent',
    'grid-20 tablet-grid-20 mobile-grid-50 grid-parent',
    'grid-20 tablet-grid-20 mobile-grid-100 grid-parent',
  ],
};

export const ECMHOMEMETATITLE =
  'Prada, Jimmy Choo, Gucci, Lanvin, Dolce & Gabbana';
export const ECMHOMEMETADESCRIPTION =
  'Free Shipping and Free Returns. Every Day. Shop Bergdorf Goodman for the latest designs from Prada, Jimmy Choo, Gucci, Lanvin, Dolce & Gabbana. Bergdorf Goodman has the best from top designers for men and women.';
export const ECMHOMEMETAKEYWORDS =
  'Prada, Jimmy Choo, Lanvin, Gucci, Dolce & Gabbana';
export const ECMHOMEMETATAGS = [
  {
    name: 'google-site-verification',
    content: 'vNTekraE7Hib-pYv0cMQngERBT48Kl7adRghuXwg5xo',
    instartPatchId: '19',
  },
  {
    name: 'google-site-verification',
    content: '--40NC37lnqkrAImq3nntRY3goQjV3gzv5BOPU2Jcvg',
  },
  {
    name: 'google-site-verification',
    content: '2AHIZFfGfnHAfyPHKo-IGAxuZs_WnyVuFsCkm399ybM',
  },
  {
    name: 'google-site-verification',
    content: 'BQg-UUDlBZq9ETKa2h84fV-YoBSvsf8ZJ-AATf5rbEk',
  },
  { name: 'y_key', content: '3634ae9d99b7dbd8', instartPatchId: '26' },
  { name: 'msvalidate.01', content: 'BE59176161C6983857E2CD5A2DA1B55A' },
];
export const ECMHOMERELLINKS = [
  { rel: 'apple-touch-icon', href: '/category/mobile/NM-icon-129.png' },
];
export const ECMHOMECANONICALURL = 'https://www.bergdorfgoodman.com';
export const ECMHOMEJSONLD = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  name: 'Bergdorf Goodman',
  legalName: 'Bergdorf Goodman',
  url: 'https://www.bergdorfgoodman.com',
  description:
    'Free Shipping & Free Returns at Bergdorf Goodman. Shop the latest styles from top designers including Michael Kors, Tory Burch, Burberry, Christian Louboutin, kate spade & more.',
  potentialAction: {
    '@type': 'SearchAction',
    target:
      'https://www.bergdorfgoodman.com/search.jsp?from=rfkSearch&responsive=true&request_type=search&search_type=keyword&q={search_term_string}&l={search_term_string}&fl=&refpg=SL',
    'query-input': 'required name=search_term_string',
  },
  logo:
    'https://www.bergdorfgoodman.com/category/images/svg/neiman-marcus-logo.svg',
  contactPoint: {
    '@type': 'ContactPoint',
    telephone: '+1-888-888-4757',
    contactType: 'customer service',
  },
  sameAs: [
    'https://www.facebook.com/bergdorfgoodman',
    'https://twitter.com/bergdorfgoodman',
    'https://www.pinterest.com/bergdorfgoodman/',
    'https://www.instagram.com/bergdorfgoodman/',
  ],
};
