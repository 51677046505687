/* eslint-disable import/no-cycle */
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { bool, object } from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import merge from 'lodash/merge';
import remove from 'lodash/remove';
import Layout from './layout';
import {
  getAdditionalStyles,
  getAlignmentClass,
  getSpacingClass,
  getTags,
} from '../utils';
import {
  getLayoutSpacing,
  getHorizontalSpacing,
  getVerticalSpacing,
} from './utils';
import ContentItem from '../contentItem/contentItem';
import {
  defaultTrackingTags,
  EntityWithSlideBarParams,
  WhatToWearParams,
} from '../../constants/constants';
import MainEdits from './mainEdits';
import { validateSegmentSiloStatus } from '../../../../components/Navigation/actions-navigation';

const removeLayoutsDrafts = subLayouts => {
  remove(subLayouts, item => !item.fields);
};

const getSublayoutWidth = (subLayouts, isMobilePhone) =>
  subLayouts.reduce(
    (acc, item) =>
      acc +
      parseInt(
        isMobilePhone ? item.fields.mobileWidth : item.fields.desktopWidth,
        10,
      ),
    0,
  );

/**
 * @function L2Layout
 * @param {Object} props.cmsContentFields
 * @param {Boolean} props.isMobilePhone
 * @param {Boolean} props.isTablet
 * @param {Boolean} props.isDesktop
 * @param {Boolean} props.isNewHomePage
 * @param {Object} props.parentTrackingTags
 * @param {String} props.placement
 * @param {Boolean} props.sessionExpired
 * @param {Boolean} props.validPromo
 * @param {Boolean} props.isSpacingToggle
 * @param {String} props.loggedInCustomerEmail
 * @returns {React.ReactElement}
 */
const L2Layout = ({
  cmsContentFields,
  cmsLayout,
  parentTrackingTags,
  isSpacingToggle,
  loggedInCustomerEmail,
  segmentSiloResponseReceived,
  isPromoEnabled,
  ...props
}) => {
  useEffect(() => {
    if (isPromoEnabled && loggedInCustomerEmail) {
      props.dispatch(validateSegmentSiloStatus(loggedInCustomerEmail));
    }
  }, []);
  const { isMobilePhone, isTablet, isDesktop, isNewHomePage } = props;
  const type = cmsLayout.contentType.toLowerCase();
  const widthAttribute = isMobilePhone
    ? cmsContentFields.mobileWidth
    : cmsContentFields.desktopWidth;
  const spacingStyles = isSpacingToggle
    ? getLayoutSpacing(cmsContentFields, isMobilePhone, isTablet, isDesktop)
    : '';
  const horizontalSpacingStyles = isSpacingToggle
    ? getHorizontalSpacing(cmsContentFields, isMobilePhone, isTablet, isDesktop)
    : '';
  const verticalSpacingStyles = isSpacingToggle
    ? getVerticalSpacing(cmsContentFields, isMobilePhone, isTablet, isDesktop)
    : '';
  const topStyles = spacingStyles.topStyles ? spacingStyles.topStyles : '';
  const layoutWidth = widthAttribute ? `width-${widthAttribute}` : 'width-100';
  const subLayoutName = `l${+cmsLayout.contentType.substring(1, 2) + 1}Layout`;
  const horizontalStyles = horizontalSpacingStyles.horizontalStyles
    ? horizontalSpacingStyles.horizontalStyles
    : '';
  const verticalStyles =
    verticalSpacingStyles && verticalSpacingStyles.verticalStyles
      ? verticalSpacingStyles.verticalStyles
      : '';

  const subLayouts = get(cmsContentFields, subLayoutName, []);
  let contentItems = get(cmsContentFields, 'components', []);

  const contentName = get(cmsContentFields, 'name', '');

  if (isPromoEnabled && contentName.includes('Logged In Promo')) {
    if (loggedInCustomerEmail) {
      const promoBannerContent = contentItems?.filter(
        content => content?.contentType === 'imageContentAsset',
      );
      const promoBanner = Array.isArray(promoBannerContent)
        ? promoBannerContent?.[0]?.fields?.linkTo
        : promoBannerContent?.fields?.linkTo;
      const promoShopLink =
        segmentSiloResponseReceived?.eliteCustomer?.shopNowLink;
      if (!promoBanner?.includes(promoShopLink) || promoShopLink === '') {
        contentItems = null;
        return contentItems;
      }
    } else {
      contentItems = null;
      return contentItems;
    }
  }

  removeLayoutsDrafts(subLayouts);

  const subLayoutWidth = getSublayoutWidth(subLayouts, isMobilePhone);

  const setGrid =
    subLayouts.length !== 1
      ? subLayouts.reduce(
          (reducer, item) => reducer || !!item.fields.components,
          false,
        )
      : false;

  const layoutClassName = `${type.substring(0, 2)}-${type.substring(
    2,
    type.length,
  )} ${
    setGrid ? 'layout-space' : 'layout-center'
  } ${layoutWidth} ${topStyles} ${horizontalStyles} ${verticalStyles}`;

  const alignmentClass = getAlignmentClass(cmsContentFields);
  const spacingClass = getSpacingClass(cmsContentFields);
  const sublayoutMultirowClass =
    subLayoutWidth > 100 ? 'sublayout-multirow' : '';
  const additionalStyles = /l[23]layout/.test(type)
    ? getAdditionalStyles(cmsContentFields)
    : {};

  const limitMaxWidth = isNewHomePage && !cmsContentFields.isFullBleedLayout;
  const tagName = get(cmsLayout, 'fields.tags.fields.name');
  const className = classNames(
    layoutClassName,
    alignmentClass,
    spacingClass,
    sublayoutMultirowClass,
    limitMaxWidth && 'limit-max-width',
    {
      'what-to-wear': tagName === WhatToWearParams.TAG,
    },
  );

  // Set up Tracking Tags
  const trackingTags = get(cmsContentFields, 'trackingTags', []);
  const currentTags = getTags(trackingTags);
  const mergedTrackingTags = merge({}, parentTrackingTags, currentTags);

  if (tagName === EntityWithSlideBarParams.tag) {
    return (
      <MainEdits
        className={className}
        contentItems={contentItems}
        parentTrackingTags={mergedTrackingTags}
        subLayouts={subLayouts}
        {...props}
      />
    );
  }

  return (
    <div className={className} style={{ ...additionalStyles }}>
      {!!subLayouts.length && (
        <Fragment>
          {subLayouts.map(item => (
            <Layout
              key={item.sys.id}
              cmsLayout={item}
              parentTrackingTags={mergedTrackingTags}
              {...props}
            />
          ))}
        </Fragment>
      )}
      {!subLayouts.length &&
        contentItems.map(item => (
          <div className="component content-layout" key={item.sys.id}>
            <ContentItem
              cmsContentItem={item}
              parentTrackingTags={mergedTrackingTags}
              {...props}
            />
          </div>
        ))}
    </div>
  );
};

L2Layout.defaultProps = {
  parentTrackingTags: defaultTrackingTags,
};

L2Layout.propTypes = {
  cmsContentFields: object,
  isMobilePhone: bool,
  isTablet: bool,
  isDesktop: bool,
  isNewHomePage: bool,
  parentTrackingTags: object,
  sessionExpired: bool,
  validPromo: bool,
  isSpacingToggle: bool,
};

const mapStateToProps = state => ({
  segmentSiloResponseReceived: get(state, 'navigation.segmentSailo', false),
  isPromoEnabled: state.toggles.LOGGED_IN_PROMOTIONS,
});

export default connect(mapStateToProps)(L2Layout);
