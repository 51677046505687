import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import find from 'lodash/find';
import {
  SET_HIDE_ON_DESKTOP,
  SET_HIDE_ON_TABLET,
  SET_HIDE_ON_MOBILE,
  SET_SELECTED_FACET,
} from '../actions/actions-ecmcontent';

import ecmHomeTopMock from '../homeTop_fallback.json';

export const defaultState = {};

const mergeContent = (component, ecmProps, targetProps) => {
  return {
    ...component,
    properties: {
      ...ecmProps,
      ...targetProps,
      images: {
        desktop: {
          ...ecmProps.images.desktop,
          ...targetProps.images.desktop,
        },
        tablet: {
          ...ecmProps.images.tablet,
          ...targetProps.images.tablet,
        },
        mobile: {
          ...ecmProps.images.mobile,
          ...targetProps.images.mobile,
        },
      },
    },
  };
};

export default (state = defaultState, action) => {
  const ecmContentSlot = isEmpty(action.contentId)
    ? {}
    : { [action.contentId]: {} };
  const contentId = get(action, 'contentId', '');
  const isMock = get(action, 'isMock', '');

  switch (action.type) {
    case 'RESOLVED_ecmplpPromoTile': {
      return {
        ...state,
        ...{
          promoTilesList: action.payload,
        },
      };
    }
    case `RESOLVED_ECM${contentId}`:
      if (isMock === '1') {
        return {
          ...state,
          ...{
            [contentId]:
              contentId === 'homeTop' ? ecmHomeTopMock : action.payload,
          },
          ...{ headers: action.headers },
        };
      } else {
        return {
          ...state,
          ...{ [contentId]: action.payload },
          ...{ headers: action.headers },
        };
      }

    case `REJECTED_ECM${contentId}`:
    case 'REJECTED_PERSONALIZATION':
      return defaultState;
    case `RESET_ECM${contentId}`:
      return { ...state, ...ecmContentSlot, ...{ headers: {} } };
    case 'RESOLVED_PERSONALIZATION': {
      const ABTestResponse = action.payload;

      if (
        ABTestResponse.type === 'RESOLVED_ABTEST' &&
        ABTestResponse.payload.length > 0
      ) {
        const hpSlots = ABTestResponse.payload.filter(payload =>
          payload.mboxId.match('homepageSlot'),
        );
        const hpSlides = ABTestResponse.payload.filter(payload =>
          payload.mboxId.match('hpCarouselSlide'),
        );

        const slotABTestResponse = [];
        slotABTestResponse.push(
          get(find(hpSlots, { mboxId: 'homepageSlot1' }), 'assignment.value'),
        );
        slotABTestResponse.push(
          get(find(hpSlots, { mboxId: 'homepageSlot2' }), 'assignment.value'),
        );
        slotABTestResponse.push(
          get(find(hpSlots, { mboxId: 'homepageSlot3' }), 'assignment.value'),
        );
        const slideABTestResponse = [];
        slideABTestResponse.push(
          get(
            find(hpSlides, { mboxId: 'hpCarouselSlide1' }),
            'assignment.value',
          ),
        );
        slideABTestResponse.push(
          get(
            find(hpSlides, { mboxId: 'hpCarouselSlide2' }),
            'assignment.value',
          ),
        );
        slideABTestResponse.push(
          get(
            find(hpSlides, { mboxId: 'hpCarouselSlide3' }),
            'assignment.value',
          ),
        );
        const isSlotResponseNotEmpty = !isEmpty(
          slotABTestResponse[0] ||
            slotABTestResponse[1] ||
            slotABTestResponse[2],
        );
        const isSlideResponseNotEmpty = !isEmpty(
          slideABTestResponse[0] ||
            slideABTestResponse[1] ||
            slideABTestResponse[2],
        );

        if (isSlotResponseNotEmpty || isSlideResponseNotEmpty) {
          let isFirstCLayout = true;
          const cloneRows = state.homeTop.rows.map(row => {
            // Expecation is always Row 3 will have 3 slots
            if (
              row.layout.toLowerCase() === 'c' &&
              isFirstCLayout &&
              isSlotResponseNotEmpty
            ) {
              isFirstCLayout = false;
              const cloneColumns = row.columns.map((column, index) => {
                const slotProperties = column.properties;
                let ABTestResponse;
                if (index === 0) {
                  ABTestResponse = slotABTestResponse[0];
                } else if (index === 1) {
                  ABTestResponse = slotABTestResponse[1];
                } else if (index === 2) {
                  ABTestResponse = slotABTestResponse[2];
                }
                if (ABTestResponse && ABTestResponse.images) {
                  return mergeContent(column, slotProperties, ABTestResponse);
                }
                return column;
              });
              return { ...row, columns: cloneColumns };
            } else if (
              row.layout.toLowerCase() === 'a' &&
              isSlideResponseNotEmpty
            ) {
              const cloneColumns = row.columns.map(column => {
                if (
                  column.componentType.toLowerCase() === 'carousel' &&
                  column.slides.length > 0
                ) {
                  const cloneSlides = column.slides.map((slide, slideIndex) => {
                    const slideProperties = slide.properties;
                    let ABTestResponse;
                    if (slideIndex === 0) {
                      ABTestResponse = slideABTestResponse[0];
                    } else if (slideIndex === 1) {
                      ABTestResponse = slideABTestResponse[1];
                    } else if (slideIndex === 2) {
                      ABTestResponse = slideABTestResponse[2];
                    }
                    if (ABTestResponse && ABTestResponse.images) {
                      return mergeContent(
                        slide,
                        slideProperties,
                        ABTestResponse,
                      );
                    }
                    return slide;
                  });
                  return {
                    ...column,
                    slides: cloneSlides,
                  };
                }
                return {
                  ...column,
                };
              });
              return { ...row, columns: cloneColumns };
            }
            return row;
          });
          const homeTopRef = state.homeTop;
          const cloneHomeTop = { ...homeTopRef, rows: cloneRows };
          return {
            ...state,
            homeTop: cloneHomeTop,
          };
        }
      }
      return { ...state };
    }
    case 'REJECTED_CP_PERSONALIZATION':
      return { ...state };
    case 'RESOLVED_CP_PERSONALIZATION': {
      const personalizedContent = action.payload;
      const contentId = action.contentId;
      const cpTemplateType = get(personalizedContent, '[0].layout')
        ? get(personalizedContent, '[0].layout')
        : get(personalizedContent, '[3].layout', 'both');
      const slides = [];
      const promos = [];
      const normalizePersonalizedContent = recNum => {
        const personalizedObject = find(personalizedContent, {
          rec_num: recNum,
        });
        if (personalizedObject) {
          if (
            !isEmpty(personalizedObject.mobile_image_url) ||
            !isEmpty(personalizedObject.tablet_image_url) ||
            !isEmpty(personalizedObject.desktop_image_url)
          ) {
            const returnObject = {
              images: {
                tablet: {
                  imagesrc: personalizedObject.tablet_image_url,
                },
                mobile: {
                  imagesrc: personalizedObject.mobile_image_url,
                },
                desktop: {
                  imagesrc: personalizedObject.desktop_image_url,
                },
              },
            };
            if (!isEmpty(personalizedObject.link_url)) {
              returnObject.linkurl = personalizedObject.link_url;
            }
            if (!isEmpty(personalizedObject.alt_text)) {
              returnObject.alttext = personalizedObject.alt_text;
              returnObject.title = personalizedObject.alt_text;
            }
            return returnObject;
          }
        }
        return null;
      };
      switch (cpTemplateType) {
        case 'both': {
          slides.push(normalizePersonalizedContent(1));
          slides.push(normalizePersonalizedContent(2));
          slides.push(normalizePersonalizedContent(3));
          promos.push(normalizePersonalizedContent(0));
          promos.push(normalizePersonalizedContent(4));
          promos.push(normalizePersonalizedContent(5));
          break;
        }
        case 'carousel only': {
          slides.push(normalizePersonalizedContent(1));
          slides.push(normalizePersonalizedContent(2));
          slides.push(normalizePersonalizedContent(3));
          promos.push(normalizePersonalizedContent(0));
          promos.push(normalizePersonalizedContent(0));
          promos.push(normalizePersonalizedContent(0));
          break;
        }
        case 'third_width only': {
          slides.push(normalizePersonalizedContent(0));
          slides.push(normalizePersonalizedContent(0));
          slides.push(normalizePersonalizedContent(0));
          promos.push(normalizePersonalizedContent(0));
          promos.push(normalizePersonalizedContent(4));
          promos.push(normalizePersonalizedContent(5));
          break;
        }
        default:
          break;
      }

      const hasSlides = !(
        isEmpty(slides[0]) &&
        isEmpty(slides[1]) &&
        isEmpty(slides[2])
      );
      const hasPromos = !(
        isEmpty(promos[0]) &&
        isEmpty(promos[1]) &&
        isEmpty(promos[2])
      );

      if (
        state[contentId] &&
        state[contentId].rows &&
        (hasSlides || hasPromos)
      ) {
        let processedPromos = false;
        let processedSlides = false;
        const cloneRows = state[contentId].rows.map(row => {
          if (
            get(row, 'layout', '').toLowerCase() === 'c' &&
            hasPromos &&
            !processedPromos
          ) {
            processedPromos = true;
            const cloneColumns = get(row, 'columns', []).map(
              (column, index) => {
                if (
                  column.componentType === 'adaptive-image' &&
                  promos[index]
                ) {
                  const promoProperties = get(column, 'properties', {});
                  return mergeContent(column, promoProperties, promos[index]);
                }
                return column;
              },
            );
            return { ...row, columns: cloneColumns };
          } else if (
            get(row, 'layout', '').toLowerCase() === 'a' &&
            hasSlides &&
            !processedSlides
          ) {
            const cloneColumns = get(row, 'columns', []).map(column => {
              if (
                column.componentType === 'carousel' &&
                get(column, 'slides', []).length > 0
              ) {
                processedSlides = true;
                const cloneSlides = column.slides.map((slide, index) => {
                  if (
                    slide.componentType === 'adaptive-image' &&
                    slides[index]
                  ) {
                    const slideProperties = get(slide, 'properties', {});
                    return mergeContent(slide, slideProperties, slides[index]);
                  }
                  return slide;
                });
                return { ...column, slides: cloneSlides };
              }
              return column;
            });
            return { ...row, columns: cloneColumns };
          }
          return row;
        });
        const contentRef = state[contentId];
        const cloneContent = { ...contentRef, rows: cloneRows };
        return {
          ...state,
          [contentId]: cloneContent,
        };
      }
      return { ...state };
    }
    case SET_HIDE_ON_DESKTOP: {
      const content = { ...state[contentId], hideOnDesktop: true };
      return { ...state, [contentId]: content };
    }
    case SET_HIDE_ON_TABLET: {
      const content = { ...state[contentId], hideOnTablet: true };
      return { ...state, [contentId]: content };
    }
    case SET_HIDE_ON_MOBILE: {
      const content = { ...state[contentId], hideOnMobile: true };
      return { ...state, [contentId]: content };
    }
    case `RESOLVED_ECM${contentId}_MAGAZINE_LOAD_MORE`: {
      const slotInState = state[contentId] || {};
      const rows = slotInState.rows || [];
      const storyConfig = slotInState.storyConfig || {};
      const newRows = action.payload.rows || [];
      const newStoryConfig = action.payload.storyConfig || {};
      const clonedStoryConfig = { ...storyConfig };
      clonedStoryConfig.lastServedStoryId = newStoryConfig.lastServedStoryId;
      clonedStoryConfig.loadMoreFlagEnabled =
        newStoryConfig.loadMoreFlagEnabled;
      const clonedRows = [...rows, ...newRows];
      const clonedSlotInState = { ...slotInState };
      clonedSlotInState.rows = clonedRows;
      clonedSlotInState.storyConfig = clonedStoryConfig;
      return {
        ...state,
        ...{ [contentId]: clonedSlotInState },
        ...{ headers: action.headers },
      };
    }
    case `RESOLVED_ECM${contentId}_MAGAZINE_FACET`: {
      const slotInState = state[contentId] || {};
      const storyConfig = slotInState.storyConfig || {};
      const newStoryConfig = action.payload.storyConfig || {};
      const clonedStoryConfig = { ...storyConfig };
      clonedStoryConfig.lastServedStoryId = newStoryConfig.lastServedStoryId;
      clonedStoryConfig.loadMoreFlagEnabled =
        newStoryConfig.loadMoreFlagEnabled;
      const payload = action.payload;
      payload.storyConfig = clonedStoryConfig;
      payload.selectedFacet = state[contentId].selectedFacet;
      return { ...state, ...{ [contentId]: payload } };
    }
    case SET_SELECTED_FACET: {
      const content = {
        ...state[contentId],
        selectedFacet: action.payload,
      };
      return { ...state, [contentId]: content };
    }
    default:
      return state;
  }
};
