import classnames from 'classnames';
import React from 'react';
import './radio.scss';

const Radio = ({
  label,
  value,
  selected = false,
  onClick
}) => (
  <label className="radio" htmlFor={`radio__${value}`} onClick={() => { onClick(value); }}>
    <input
      id={`radio__${value}`}
      className="radio__input"
      type="radio"
      value={value}
      defaultChecked={selected}
    />
    <span className={classnames('radio__element', { 'radio__element--selected': selected })} />
    {label && <span className="radio__label">{label}</span>}
  </label>
);

export default Radio;
