import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { isIpadPro } from 'client-utils/utilities-page';
import './style.scss';

export class DumbEcmHostedVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customContextVisible: false,
      customContextItems: ['Enable Captions'],
      deviceType: '',
    };
    this.ecmVid = null;
    this.posterImg = null;
    this.overLay = null;
    this.contextRoot = null;
    this.setContextRoot = item => {
      this.contextRoot = item;
    };
    this.setEcmVid = video => {
      this.ecmVid = video;
    };
    this.setPosterImg = img => {
      this.posterImg = img;
    };
    this.setOverLay = img => {
      this.overLay = img;
    };

    this.returnDynamicVideo = this.returnDynamicVideo.bind(this);
    this.renderVideoPlayer = this.renderVideoPlayer.bind(this);
    this.getVideoByDevice = this.getVideoByDevice.bind(this);
    this.getPosterByDevice = this.getPosterByDevice.bind(this);
  }

  getVideoByDevice = (url, identifier) => {
    const desktopVideo = url.slice(0, url.indexOf(`${identifier}`) + 3);
    const mobileVideo = url.slice(url.indexOf(`${identifier}`) + 4, url.length);

    const { isDesktop, isTablet } = this.props;
    const videoType = 'video/mp4';

    return isDesktop || isTablet || isIpadPro() ? (
      <source src={desktopVideo} type={videoType} />
    ) : (
      <source src={mobileVideo} type={videoType} />
    );
  };

  getPosterByDevice = (url, identifier) => {
    const { isDesktop, isMobile, isTablet } = this.props;
    const posterImageUrl =
      get(this.props, 'videoData.properties.posterImageUrl', '') === null
        ? ''
        : get(this.props, 'videoData.properties.posterImageUrl', '');

    const desktopPoster = posterImageUrl.slice(
      0,
      posterImageUrl.indexOf(`${identifier}`) + 3,
    );
    const mobilePoster = posterImageUrl.slice(
      posterImageUrl.indexOf(`${identifier}`) + 4,
      posterImageUrl.length,
    );

    let posterURL;

    if (isDesktop) {
      posterURL = desktopPoster;
    }
    if (isMobile) {
      posterURL = mobilePoster;
    }
    if (isTablet || isIpadPro()) {
      posterURL = desktopPoster;
    }

    return `${posterURL}`;
  };

  controlVideo(e, controls) {
    if (this.ecmVid) {
      setTimeout(() => {
        if (controls) this.ecmVid.setAttribute('controls', 'controls');
        if (this.ecmVid.currentTime === 0) this.ecmVid.play();
      }, 50);

      this.ecmVid.onended = () => {
        if (controls) this.ecmVid.removeAttribute('controls', '');
        this.ecmVid.load();
      };
    }

    if (this.props.isMobile && this.ecmVid.currentTime > 0) {
      this.ecmVid.paused ? this.ecmVid.play() : this.ecmVid.pause();
    }
  }

  returnDynamicVideo(
    controls,
    classHtml,
    videoProps,
    videoUrl,
    closedCaptions,
  ) {
    return (
      <div className={classHtml}>
        <video
          onClick={e => this.controlVideo(e, controls)}
          onTouchStart={e => this.controlVideo(e, controls)}
          className="ecm-hosted-video transform"
          {...videoProps}
          ref={this.setEcmVid}
        >
          {this.getVideoByDevice(videoUrl, 'mp4/')}
          {closedCaptions ? (
            <track default kind="captions" srcLang="en" src={closedCaptions} />
          ) : null}
        </video>
      </div>
    );
  }

  renderVideoPlayer() {
    const { videoData } = this.props;
    const { isDesktop, isTablet, isMobile } = this.props;

    let deviceType;
    if (isDesktop) deviceType = 'desktop';
    if (isTablet) deviceType = 'tablet';
    if (isMobile) deviceType = 'mobile';

    const isAutoPlay =
      get(videoData, `properties.breakpoints.${deviceType}.behavior`, '') ===
      'autoplay';
    const showControls = get(
      videoData,
      `properties.breakpoints.${deviceType}.controls`,
      false,
    );
    const isloop = get(
      videoData,
      `properties.breakpoints.${deviceType}.loop`,
      false,
    );

    // const ecmComponentId = get(videoData, 'componentId', '');
    const videoUrl =
      get(this.props.videoData, 'properties.url') === null ||
      isEmpty(get(this.props.videoData, 'properties.url', ''))
        ? ''
        : get(this.props.videoData, 'properties.url');
    const posterImageUrl =
      get(videoData, 'properties.posterImageUrl', '') === null
        ? ''
        : get(videoData, 'properties.posterImageUrl', '');
    const closedCaptions = get(videoData, 'properties.closedCaptionsUrl', '');
    const classesOfHtmlPlayer = 'ecm-hosted-vide__style__container';
    const classes = classNames(`ecm-video adj-height-x`);
    const baseVideoProps = {
      crossOrigin: 'anonymous',
      playsInline: true,
      tabIndex: 0,
      controlsList: 'nodownload',
    };
    if (isloop) {
      baseVideoProps.loop = true;
    }
    if (isAutoPlay) {
      baseVideoProps.autoPlay = true;
    }

    if (posterImageUrl && !isAutoPlay) {
      baseVideoProps.poster = this.getPosterByDevice(posterImageUrl, 'jpg/');
    }
    const videoProps = { ...baseVideoProps, muted: true };
    const returnContextMenuItems = data =>
      data.map((item, index) => {
        return (
          <div
            key={index}
            className="ecm-hosted-video__custom-context-menu__option"
          >
            {item}
          </div>
        );
      });

    return videoUrl ? (
      <Fragment>
        <div className={classes}>
          {this.returnDynamicVideo(
            showControls,
            classesOfHtmlPlayer,
            videoProps,
            videoUrl,
            closedCaptions,
            posterImageUrl,
            isloop,
            deviceType,
          )}
        </div>
        {
          <div
            ref={this.setContextRoot}
            className={
              this.state.customContextVisible
                ? 'ecm-hosted-video__custom-context-menu visible'
                : 'ecm-hosted-video__custom-context-menu'
            }
          >
            {returnContextMenuItems(this.state.customContextItems)}
            <div className="ecm-hosted-video__custom-context-menu__seperator" />
            <div className="ecm-hosted-video__custom-context-menu__option">
              Full Screen
            </div>
          </div>
        }
      </Fragment>
    ) : null;
  }

  render() {
    return this.renderVideoPlayer();
  }
}

const mapStateToProps = state => ({
  isTablet: get(state, 'device.isTablet', false) === true,
  isMobile: get(state, 'device.isMobile', false) === true,
  isDesktop: get(state, 'device.isDesktop', false) === true,
});

export default connect(mapStateToProps)(DumbEcmHostedVideo);
