import React, { Component } from 'react';
import Slider from 'react-slick';
import WithTappable from 'client/common/components/Tappable/Tappable';
import classnames from 'classnames';
import isElement from 'lodash/isElement';
import Image from 'pdp/components/ProductPage/components/Image/image';
import './monogramOptionSet.scss';

export default class MonogramOptionSet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: this.props.selectedIndex,
      hoveredIndex: -1,
    };
    this.resetMonoOptionHoverIndex = this.resetMonoOptionHoverIndex.bind(this);
    this.showSlides = this.showSlides.bind(this);
    this.hideSlides = this.hideSlides.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  componentDidMount() {
    setImmediate(() => {
      this.hideSlides();
      this.showSlides();
    });
  }

  setMonoOptionIndex(index, displayName) {
    return () => {
      this.props.setSelectedOption(this.props.option.legacyType, index);
      this.setState({ selectedIndex: index });
      this.setAlert(
        this.props.titlePrepend,
        displayName,
        document.getElementById('screenReaderAlert'),
      );
    };
  }

  setMonoOptionHoverIndex(index) {
    return () => {
      this.setState({ hoveredIndex: index });
      this.props.setHoverIndex(this.props.option.legacyType, index);
    };
  }

  getMonoOptionClass(index) {
    const { selectedIndex, hoveredIndex } = this.state;
    return classnames(
      'monogram-option__image',
      { 'monogram-option__image--active': index === selectedIndex },
      {
        'monogram-option__image--hover':
          index === hoveredIndex && selectedIndex !== hoveredIndex,
      },
    );
  }

  setAlert(title, displayName, srAlert) {
    if (isElement(srAlert)) {
      srAlert.textContent = `${title} ${displayName} selected`;
    }
  }

  getLabel(index, title, name) {
    if (index === this.state.selectedIndex) {
      return `${title}${name} selected`;
    } else {
      return `${title}${name}`;
    }
  }

  getLabelMonoStyle(choiceType) {
    switch (choiceType) {
      case 'NAME':
        return 'for name';
      case 'SINGLE_INIT':
        return 'for first initial';
      case 'THREE_INIT':
      case 'THREE_INIT_FML':
        return 'for initials in order of first, middle, and last name';
      default:
        return '';
    }
  }

  resetMonoOptionHoverIndex() {
    this.props.resetHoverIndex(this.props.option.legacyType);
    this.setState({ hoveredIndex: -1 });
  }

  option(choice, index) {
    const label = `${this.getLabel(
      index,
      this.props.titlePrepend,
      choice.displayName,
    )} ${this.getLabelMonoStyle(choice.type)}`;
    return (
      <li key={index}>
        <WithTappable
          mouseOver={this.setMonoOptionHoverIndex(index)}
          mouseOut={this.resetMonoOptionHoverIndex}
        >
          {tapProps => (
            <button
              aria-label={label}
              tabIndex="0"
              onClick={this.setMonoOptionIndex(index, choice.displayName)}
              onFocus={this.setMonoOptionHoverIndex(index)}
              onBlur={this.resetMonoOptionHoverIndex}
              className={this.getMonoOptionClass(index)}
              {...tapProps}
            >
              <Image
                src={`${choice.url}`}
                title={`${this.props.titlePrepend}${choice.displayName}`}
                alt={`${this.props.titlePrepend}${choice.displayName}`}
                aria-hidden="true"
              />
            </button>
          )}
        </WithTappable>
      </li>
    );
  }

  showSlides() {
    const container = document.getElementById(this.props.id);
    let activeSlides;
    if (container) {
      activeSlides = Array.from(
        container.querySelectorAll('.slick-active button'),
      );
      activeSlides.forEach(slide => {
        slide.setAttribute('tabIndex', '0');
      });
    }
  }

  hideSlides() {
    const container = document.getElementById(this.props.id);
    let hiddenSlides;
    if (container) {
      hiddenSlides = Array.from(
        container.querySelectorAll('.slick-slide button'),
      );
      hiddenSlides.forEach(slide => {
        slide.setAttribute('tabIndex', '-1');
      });
    }
  }

  handleFocus(prev, next) {
    const container = document.getElementById(this.props.id);
    let activeSlides;
    if (container) {
      activeSlides = Array.from(
        container.querySelectorAll('.slick-active button'),
      );
      if (prev < next) {
        activeSlides[activeSlides.length - 1].focus();
      } else if (prev > next) {
        activeSlides[0].focus();
      }
    }
  }

  render() {
    if (!this.props.option.choices || this.props.option.choices.length === 0) {
      return null;
    }

    const { choices } = this.props.option;
    const { selectedIndex, hoveredIndex } = this.state;
    const activeOptionIndex =
      hoveredIndex === -1 ? selectedIndex : hoveredIndex;
    const { displayName } = choices[activeOptionIndex];
    const monoOptionCarouselSettings = {
      beforeChange: (oldIndex, newIndex) => {
        this.hideSlides();
        setTimeout(() => {
          this.handleFocus(oldIndex, newIndex);
        }, 500);
      },
      afterChange: this.showSlides,
      infinite: false,
      speed: 500,
      slidesToShow: 8,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 7,
          },
        },
        {
          breakpoint: 470,
          settings: {
            slidesToShow: 5,
          },
        },
      ],
    };

    return (
      <div className="monogram-option grid-100 tablet-grid-100 mobile-grid-100 grid-parent monogram_title_options">
        <div className="monogram-option__text">
          {this.props.optionLabel}:{' '}
          <p className="monogram-option__name">
            {this.props.titlePrepend}
            {displayName}
          </p>
        </div>
        <ul id={this.props.id}>
          <Slider {...monoOptionCarouselSettings}>
            {choices.map((choice, index) => this.option(choice, index))}
          </Slider>
        </ul>
      </div>
    );
  }
}
