import React from 'react';
import { defaultTrackingString, tagMap } from '../constants/constants';

export const renderMultiDeviceText = (
  Wrapper,
  {
    isMobilePhone,
    desktopText,
    tabletText,
    mobileText,
    transformText = text => text,
  },
) => {
  if (!isMobilePhone && tabletText) {
    return (
      <Wrapper>
        <div className="hide-on-tablet hide-on-mobile">
          {transformText(desktopText)}
        </div>
        <div className="hide-on-desktop">{transformText(tabletText)}</div>
      </Wrapper>
    );
  }

  if (isMobilePhone && mobileText) {
    return <Wrapper>{transformText(mobileText)}</Wrapper>;
  }

  if (isMobilePhone && !mobileText && tabletText) {
    return <Wrapper>{transformText(tabletText)}</Wrapper>;
  }

  return <Wrapper>{transformText(desktopText)}</Wrapper>;
};

export const getAlignmentClass = cmsContentFields => {
  let classStr = '';
  if (cmsContentFields.verticallyAlign) {
    classStr = `layout-valign-${cmsContentFields.verticallyAlign.toLowerCase()}`;
  }
  return classStr;
};

export const getSpacingClass = cmsContentFields => {
  let classStr = '';
  if (cmsContentFields.contentSpacing) {
    classStr = `layout-spacing-${cmsContentFields.contentSpacing.toLowerCase()}`;
  }
  return classStr;
};

export const calculateTextOverlayPositionProps = (
  position,
  horizontalMargin = 0,
  verticalMargin = 0,
  isInPixels = false,
) => {
  const horizontalMarginValue =
    horizontalMargin && isInPixels
      ? `${horizontalMargin}px`
      : `${horizontalMargin}%`;
  const verticalMarginValue =
    verticalMargin && isInPixels ? `${verticalMargin}px` : `${verticalMargin}%`;

  switch (position) {
    case 'Top Left':
      return {
        top: verticalMarginValue,
        left: horizontalMarginValue,
      };

    case 'Top Center':
      return {
        top: verticalMarginValue,
        left: '50%',
        transform: 'translate(-50%, 0)',
      };

    case 'Top Right':
      return {
        top: verticalMarginValue,
        right: horizontalMarginValue,
      };

    case 'Middle Left':
      return {
        top: '50%',
        left: horizontalMarginValue,
        transform: 'translate(0, -50%)',
      };

    case 'Middle Center':
      return {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      };

    case 'Middle Right':
      return {
        top: '50%',
        right: horizontalMarginValue,
        transform: 'translate(0, -50%)',
      };

    case 'Bottom Left':
      return {
        left: horizontalMarginValue,
        bottom: verticalMarginValue,
      };

    case 'Bottom Center':
      return {
        left: '50%',
        bottom: verticalMarginValue,
        transform: 'translate(-50%, 0)',
      };

    case 'Bottom Right':
      return {
        right: horizontalMarginValue,
        bottom: verticalMarginValue,
      };

    default:
      return {};
  }
};

export const getAdditionalStyles = cmsContentFields => {
  const stylesObject = {};
  if (cmsContentFields && cmsContentFields.backgroundColor) {
    stylesObject.backgroundColor = cmsContentFields.backgroundColor;
  }
  if (cmsContentFields && cmsContentFields.padding) {
    stylesObject.padding = `${cmsContentFields.padding}px`;
  }
  if (
    cmsContentFields &&
    cmsContentFields.layoutAsComponent === 'Expanding Carousel'
  ) {
    stylesObject.display = 'block';
  }

  return stylesObject;
};

/**
 * @function getTags
 * @param {Array} trackingTags
 * @description extracts and formats tracking tags from content data
 * @returns {Object}
 */
export const getTags = (trackingTags = []) => {
  return trackingTags.reduce((acc, tag) => {
    const { fields = {} } = tag;
    const { code, type } = fields;

    if (!code || !type) return acc;

    const key = tagMap[type];
    if (key) acc[key] = code;

    return acc;
  }, {});
};

/**
 * @function getTrackingTagStr
 * @param {Object} mergedTrackingTags
 * @returns {String}
 */
export const getTrackingTagStr = ({
  brandChannel,
  secondGroup,
  thirdGroup,
  campaignType,
  vendor,
  category,
  campaignName,
}) =>
  `${brandChannel}-${secondGroup}-${thirdGroup}-${campaignType}-${vendor}-${category}-${campaignName}`;

export const getParams = url =>
  url.split('&').reduce((acc, val) => {
    const [paramKey, paramVal] = val.split('=');

    acc[paramKey] = paramVal;

    return acc;
  }, {});

/**
 * @function addTrackingParam
 * @param {String} url
 * @param {String} trackingTagStr
 * @returns {String}
 */
export const addTrackingParam = (url, trackingTagStr) => {
  // If the tracking tag string hasn't been unchanged from default, don't add an icid param.
  if (trackingTagStr === defaultTrackingString) return url;

  /*
   * If the url contains a pre-existing icid param, don't add a new icid param.
   * Otherwise, add the new icid param using the trackingTagStr created by cascading tags
   * from Contentful. See BGO-14347.
   */

  const hasParams = url.includes('?');

  if (hasParams) {
    const hasIcidParams = url.includes('icid=');

    return hasIcidParams ? url : `${url}&icid=${trackingTagStr}`;
  }

  return `${url}?icid=${trackingTagStr}`;
};
