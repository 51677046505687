import React from 'react';
import './bopsErrorForReplenishment.scss';

export const BOPS_ERR_MSG_FOR_REPLENISHMENT =
  'Sorry, Auto-Replenishment Services are not available for in-store pickup.';

const BopsErrorForReplenishment = props => {
  return props.isError ? (
    <div className="bopsErrorForReplenishment">
      <p>{BOPS_ERR_MSG_FOR_REPLENISHMENT}</p>
    </div>
  ) : null;
};

export default BopsErrorForReplenishment;
