import isEmpty from 'lodash/isEmpty';
import * as Scroll from 'react-scroll';
import httpWithLogging from 'universal/http-client';
import get from 'lodash/get';
import { types as AppTypes } from 'shared/components/App/app-actions';
import { hideSpinner } from 'shared/components/Spinner/spinner-actions';
import { internationalMiniCartHtml } from './internationalMiniCart';

export const types = {
  LOADING_MINI_CART: 'LOADING_MINI_CART',
  RESOLVED_MINI_CART: 'RESOLVED_MINI_CART',
  REJECTED_MINI_CART: 'REJECTED_MINI_CART',
  UPDATE_MINI_CART_ITEM_COUNT: 'UPDATE_MINI_CART_ITEM_COUNT',
  SET_CLOSE_TIMER: 'SET_CLOSE_TIMER',
  ANIMATE_CART_CLOSED: 'ANIMATE_CART_CLOSED',
  SET_RECENTLY_ADDED_ITEM_COUNT: 'SET_RECENTLY_ADDED_ITEM_COUNT',
  SET_MOUSE_OVER_SHOPPING_BAG: 'SET_MOUSE_OVER_SHOPPING_BAG',
  RESOLVED_INVENTORY_CHECK: 'RESOLVED_INVENTORY_CHECK',
  REJECTED_INVENTORY_CHECK: 'REJECTED_INVENTORY_CHECK',
};

const MINI_CART_CONTAINER_ID = '#miniCartContainer';

const startCloseTimer = dispatch => {
  return setTimeout(() => {
    dispatch({ type: types.ANIMATE_CART_CLOSED });
  }, 4000);
};

export function showMiniCart() {
  return (dispatch, getState, fromPDP = false) => {
    const timerId = startCloseTimer(dispatch);
    dispatch({ type: types.LOADING_MINI_CART, timerId });
    const state = getState();
    const requestApi = httpWithLogging(state);
    return requestApi
      .post(NMConfig.API_MINI_CART)
      .then(successResponse => {
        const isMiniCartOnFocus = getState().miniCart.mouseOverShoppingBag;
        if (isMiniCartOnFocus || fromPDP) {
          dispatch({
            type: AppTypes.PUT_IN_FOCUS,
            componentInFocus: MINI_CART_CONTAINER_ID,
          });
        }
        Scroll.animateScroll.scrollToTop();
        dispatch({
          type: types.RESOLVED_MINI_CART,
          payload: isEmpty(successResponse)
            ? ''
            : successResponse.data.MiniCartResp.html,
        });
      })
      .catch(() => {
        const isMiniCartOnFocus = getState().miniCart.mouseOverShoppingBag;
        if (isMiniCartOnFocus) {
          dispatch({
            type: AppTypes.PUT_IN_FOCUS,
            componentInFocus: MINI_CART_CONTAINER_ID,
          });
        }
        dispatch({ type: types.REJECTED_MINI_CART });
      });
  };
}

export function setRecentlyAddedItemCount(recentlyAddedItemCount) {
  return dispatch =>
    dispatch({
      type: types.SET_RECENTLY_ADDED_ITEM_COUNT,
      recentlyAddedItemCount,
    });
}

export function updateMiniCartItemCount(noOfItemsInCart) {
  return dispatch =>
    dispatch({ type: types.UPDATE_MINI_CART_ITEM_COUNT, noOfItemsInCart });
}

export const startTimer = () => {
  return dispatch => {
    const timerId = startCloseTimer(dispatch);
    dispatch({ type: types.SET_CLOSE_TIMER, timerId });
  };
};

export const startMiniCartCloseAnimation = () => {
  return dispatch => dispatch({ type: types.ANIMATE_CART_CLOSED });
};

export const setMouseOverShoppingBagTo = mouseOverShoppingBag => {
  return dispatch =>
    dispatch({ type: types.SET_MOUSE_OVER_SHOPPING_BAG, mouseOverShoppingBag });
};

export function localCheckInventory(productIds) {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state);
    return requestApi
      .get(`${NMConfig.API_PRODUCT}?productIds=${productIds}`)
      .then(successResponse => {
        dispatch({
          type: types.RESOLVED_INVENTORY_CHECK,
          payload: successResponse.data,
        });
      })
      .catch(() => {
        dispatch({ type: types.REJECTED_INVENTORY_CHECK });
        dispatch(hideSpinner());
      });
  };
}

export function showInternationalMiniCart() {
  return (dispatch, getState) => {
    const state = getState();
    const timerId = startCloseTimer(dispatch);
    dispatch({ type: types.LOADING_MINI_CART, timerId });
    dispatch({
      type: AppTypes.PUT_IN_FOCUS,
      componentInFocus: MINI_CART_CONTAINER_ID,
    });
    Scroll.animateScroll.scrollToTop();
    const countryCode = get(state, 'locale.countryCode', 'US');
    const currencyCode = get(state, 'locale.currencyCode', 'USD');
    const intlCartName = `internationalCart_${countryCode}_${currencyCode}`;
    dispatch({
      type: types.RESOLVED_MINI_CART,
      payload: internationalMiniCartHtml(intlCartName),
    });
  };
}
