import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { getRefreshableHtmlContentFallback } from '@bgo-ui/common/components/RefreshableContent/refreshableContent-actions';

class EcmFallback extends Component {
  componentDidMount() {
    const { fallback } = this.props;
    this.props.fetchFallback(fallback, 'ecmFallback');
  }

  shouldComponentUpdate(nextProps) {
    const oldContent = get(
      this.props,
      'refreshableHtmlContent.allEcmFallBackContent',
      ''
    );
    const newContent = get(
      nextProps,
      'refreshableHtmlContent.allEcmFallBackContent',
      ''
    );
    return oldContent !== newContent;
  }
  componentDidUpdate() {
    const { refreshableHtmlContent = {} } = this.props;
    const allfallBack = refreshableHtmlContent.allEcmFallBackContent.join('');

    const imagefix = allfallBack.replace(
      /\/category/gi,
      'https://devint.bergdorfgoodman.com/category/'
    );

    const range = document.createRange();
    range.selectNode(document.getElementsByClassName('ecm-fallback').item(0));
    const documentFragment = range.createContextualFragment(imagefix);
    document
      .getElementById('content-ecmFallback')
      .appendChild(documentFragment);
  }
  render() {
    return <div id="content-ecmFallback" />;
  }
}

const mapStateToProps = state => ({
  refreshableHtmlContent: state.refreshableContent.refreshableHtmlContent,
  ecmContent: state.ecm.ecmContent,
});

const mapDispatchToProps = {
  fetchFallback: getRefreshableHtmlContentFallback,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EcmFallback);
