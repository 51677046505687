import React, { Fragment } from 'react';
import { array, string } from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import ContentItem from '../contentItem/contentItem';

/**
 * @function ContentGlobal
 * @param {Array} props.cmsContent
 * @param {String} props.placement
 * @returns {React.ReactElement}
 */
const ContentGlobal = ({ cmsContent = [], placement = 'Main' }) => {
  return (
    <Fragment>
      {!!cmsContent.length &&
        cmsContent.map((item, index) => (
          <ContentItem
            cmsContentItem={item}
            key={index}
            placement={placement}
          />
        ))}
    </Fragment>
  );
};

ContentGlobal.propTypes = {
  cmsContent: array,
  placement: string,
};

const mapStateToProps = state => ({
  cmsContent: get(state, 'cms.global'),
});

export default connect(mapStateToProps)(ContentGlobal);
