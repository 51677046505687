import React from 'react';
import { connect } from 'react-redux';
import Toggle from 'shared/components/Toggle/toggle';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

export const loopSkus = (productDataForSku, price, regularPrice, onlyXLeftThreshold,
  isFreeReturnsEligible = false) => {
  return !isEmpty(productDataForSku.skus) && productDataForSku.skus.map(sku => `{
    "id": "${get(sku, 'id')}",
    "product_id": "${get(productDataForSku, 'id')}",
    "title": "${get(productDataForSku, 'name')}",
    "brand": "${get(productDataForSku, 'designer.name', '')}",
    "price": ${price},
    "regular_price": ${regularPrice},
    "image": "${get(productDataForSku, 'media.main.medium.url')}",
    "in_stock": ${get(sku, 'stockLevel')},
    "color": "${get(sku, 'color.name')}",
    "discount_eligible": ${!!(!isEmpty(get(productDataForSku, 'price.adornments')) || !isEmpty(get(productDataForSku, 'promotions')))},
    "inventory_eligible_flag": ${!((get(sku, 'stockLevel') < onlyXLeftThreshold))},
    "excluded_category": ${get(productDataForSku, 'details.clearanceItem')},
    "parentheticalCharge": ${get(productDataForSku, 'details.parentheticalCharge')},
    "nmexclusive": ${get(productDataForSku, 'productFlags.isOnlyAtNM')},
    "free_returns_eligible": ${isFreeReturnsEligible}
  }`);
};

const GranifyScript = ({
  pageType, productData, trackProduct = false, isInternational, onlyXLeftThreshold, }) => {
  if (!isInternational) return false;
  const adornmentPrice = get(productData, 'price.retailPrice', 0);
  const price = get(productData, 'price.promotionalPrice', adornmentPrice);
  const originalPrice = get(productData, 'price.adornments[0].price', adornmentPrice);
  const color = get(productData, 'options.productOptions[1].values[0]', {});
  const colorValue = get(color, 'name', '');
  const doesCatlogCodeStartsWithOC = get(productData, 'metadata.cmosCatalogId', '').startsWith('OC');
  const isparentheticalChargeGreaterThanZero = Number(get(productData, 'details.parentheticalCharge', '')) > 0;
  const isPerishableProduct = get(productData, 'perishable', false);
  const isMonogramProduct = get(productData, 'isCustomizable', false);
  const isEveningWear = get(productData, 'productFlags.isEvening', false);
  const isFreeReturnsEligible = (!doesCatlogCodeStartsWithOC &&
    !isparentheticalChargeGreaterThanZero &&
    !isPerishableProduct &&
    !isMonogramProduct &&
    !isEveningWear
  );
  return pageType ? (
    <Toggle feature="GRANIFY">
      <div id="granify-script">
        <script src="/assets/scripts/granify.js" asyc="true" />
        <script
          dangerouslySetInnerHTML={{
            __html: `Granify('trackPageView', {"page_type": "${pageType}"});`
          }}
        />
        {
          trackProduct && !isEmpty(productData) &&
          <script
            dangerouslySetInnerHTML={{
              __html: `Granify('trackProduct', [
                  {
                    "id": "${get(productData, 'id')}",
                    "title": "${get(productData, 'name')}",
                    "brand": "${get(productData, 'linkedData.brand', '')}",
                    "price": ${price},
                    "regular_price": ${originalPrice},
                    "image": "${get(productData, 'media.main.medium.url')}",
                    "color": "${colorValue || ''}",
                    "discount_eligible": ${!!(!isEmpty(get(productData, 'price.adornments')) || !isEmpty(get(productData, 'promotions')))},
                    "excluded_category": ${get(productData, 'details.clearanceItem')},
                    "parentheticalCharge": ${get(productData, 'details.parentheticalCharge')},
                    "free_returns_eligible": ${isFreeReturnsEligible},
                    "nmexclusive": ${get(productData, 'productFlags.isOnlyAtNM')}
                  },
                  ${!isEmpty(productData.skus) && loopSkus(productData, price, originalPrice, onlyXLeftThreshold, isFreeReturnsEligible)}
                ]);
                Granify('setCurrentProduct', "${get(productData, 'id')}");`
            }}
          />
          }
      </div>
    </Toggle>
  ) : false;
};

const mapStateToProps = state => {
  const breadcrumbs = get(state, 'navigation.breadcrumbs');
  const categoryId = get(state.navigation, `breadcrumbs[${breadcrumbs.length - 1}].id`, '');
  const onlyXLeftThresholds = state.onlyXLeftThresholds;
  const onlyXLeftThreshold = get(onlyXLeftThresholds, categoryId, onlyXLeftThresholds['defaultThreshold']);
  const isInternational = (state.locale.countryCode === 'US');
  return {
    isInternational,
    onlyXLeftThreshold,
  };
};

export default connect(mapStateToProps)(GranifyScript);
