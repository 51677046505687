export const CLOUDINARY_SIZE = {
  DEFAULT: {
    desktop: {
      normal: { width: 429, height: 602 },
    },
    tablet: {
      normal: { width: 320, height: 448 },
    },
    mobile: {
      normal: { width: 185, height: 256 },
    },
  },
  PRODUCT: {
    desktop: {
      normal: { width: 429, height: 602 },
      large: { width: 554, height: 775 },
      currated: { width: 536, height: 750 },
    },
    tablet: {
      normal: { width: 320, height: 448 },
      large: { width: 395, height: 574 },
      currated: { width: 395, height: 574 },
    },
    mobile: {
      normal: { width: 185, height: 256 },
      large: { width: 375, height: 525 },
      currated: { width: 270, height: 378 },
    },
  },
  WHATSNEW: {
    desktop: {
      normal: { width: 210, height: 294 },
    },
    tablet: {
      normal: { width: 167, height: 233 },
    },
    mobile: {
      normal: { width: 142, height: 199 },
    },
  },
  MINICART: {
    desktop: {
      normal: { width: 48, height: 67 },
    },
    tablet: {
      normal: { width: 48, height: 67 },
    },
    mobile: {
      normal: { width: 48, height: 67 },
    },
  },
  NEWARRIVALS: {
    desktop: {
      normal: { width: 384, height: 480 },
    },
    tablet: {
      normal: { width: 384, height: 480 },
    },
    mobile: {
      normal: { width: 384, height: 480 },
    },
  },
  STYLYZE: {
    desktop: {
      normal: { width: 384, height: 480 },
    },
    tablet: {
      normal: { width: 384, height: 480 },
    },
    mobile: {
      normal: { width: 384, height: 480 },
    },
  },
  WISHLIST: {
    desktop: {
      normal: { width: 142, height: 198 },
    },
    tablet: {
      normal: { width: 142, height: 198 },
    },
    mobile: {
      normal: { width: 140, height: 196 },
    },
  },
  WEARITWITH: {
    desktop: {
      normal: { width: 369, height: 516 },
    },
    tablet: {
      normal: { width: 200, height: 280 },
    },
    mobile: {
      normal: { width: 142, height: 199 },
    },
  },
  PDP: {
    TEMPLATE_A: {
      desktop: {
        normal: { width: 720, height: 1008 },
        large: { width: 720, height: 1008 },
        thumbnail: { width: 554, height: 775 },
        zoom: { width: 1200, height: 1500 },
      },
      tablet: {
        normal: { width: 441, height: 618 },
        large: { width: 395, height: 574 },
        thumbnail: { width: 395, height: 574 },
        zoom: { width: 1200, height: 1500 },
      },
      mobile: {
        normal: { width: 375, height: 525 },
        thumbnail: { width: 79, height: 111 },
        zoom: { width: 1200, height: 1500 },
      },
    },
    TEMPLATE_B: {
      desktop: {
        normal: { width: 424, height: 592 },
        large: { width: 554, height: 775 },
        thumbnail: { width: 554, height: 775 },
        zoom: { width: 1200, height: 1500 },
      },
      tablet: {
        normal: { width: 440, height: 615 },
        large: { width: 395, height: 574 },
        thumbnail: { width: 554, height: 775 },
        zoom: { width: 1200, height: 1500 },
      },
      mobile: {
        normal: { width: 375, height: 525 },
        large: { width: 375, height: 525 },
        thumbnail: { width: 79, height: 111 },
        zoom: { width: 1200, height: 1500 },
      },
    },
    BUNDLE: {
      desktop: {
        normal: { width: 447, height: 624 },
        large: { width: 554, height: 775 },
        thumbnail: { width: 554, height: 775 },
        zoom: { width: 1200, height: 1500 },
      },
      tablet: {
        normal: { width: 382, height: 534 },
        large: { width: 395, height: 574 },
        thumbnail: { width: 554, height: 775 },
        zoom: { width: 1200, height: 1500 },
      },
      mobile: {
        normal: { width: 375, height: 524 },
        large: { width: 375, height: 525 },
        thumbnail: { width: 79, height: 111 },
        zoom: { width: 1200, height: 1500 },
      },
    },
  },
};

export const TEMPLATE_A = 'TEMPLATE_A';
export const TEMPLATE_B = 'TEMPLATE_B';
export const BUNDLE = 'BUNDLE';

export const SEARCH_QUERY_PREFIX = '/search/?N=0&Ntt=';

export const FREE_SHIPPING_RETURNS_LABEL = 'Free Shipping & Returns';
export const FREE_SHIPPING_LABEL = 'Free Shipping';
export const SHIPPING_RETURNS_LABEL = 'Shipping & Returns';
export const SHOP_BY = 'shop by';
export const COMPONENT_CACHE_TTL = 60 * 60 * 2; // 2  hours
export const COMPONENT_HOME_TOP = 15 * 60; // 15 min
export const COMPONENT_SITE_TICKER = 15 * 60; // 15 min
export const COMPONENT_PDP_BOTTOM_BANNER = 10 * 60; // 10 mins
export const STS_TRACKER_COOKIE = 'STS_CLICK';
export const CUSP_CAT_ID = 'cat58930763';
