import { useState, useCallback } from 'react';
import axios from 'axios';

export const useHttp = (axiosInstance = axios) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = useCallback(
    (url, method = 'GET', body = null, headers = {}) => {
      setLoading(true);
      let reqBody = null;
      const reqHeaders = { ...headers };
      if (body) {
        reqBody = body;
        reqHeaders['Content-Type'] = 'application/json';
      }
      return axiosInstance
        .request({
          method,
          url,
          headers: reqHeaders,
          data: reqBody,
        })
        .then(response => {
          const { data } = response;
          setLoading(false);
          return Promise.resolve(data);
        })
        .catch(e => {
          if (e.response) {
            setError(
              e.response?.data?.message ||
                JSON.parse(e.response?.data?.body)?.message ||
                'Something went wrong',
            );
          } else if (e.message) {
            setError(e.message);
          } else {
            setError('Something went wrong');
          }
          setLoading(false);
          return Promise.reject(e.response?.data || e);
        });
    },
    [axiosInstance],
  );

  const clearError = useCallback(() => setError(null), []);

  return { loading, request, error, clearError };
};
