import React, { Component } from 'react';
import logger from 'server/utilities/logger';
import Button from 'shared/components/Button/button';
import { closeModal } from 'shared/components/Modal/actions';
import { connect } from 'react-redux';
import { ESC_KEYCODE } from 'client-utils/keyCodes';
import './cutlineVideoModal.scss';

export class DumbCutlineVideoModal extends Component {
  static isValidComponent(options) {
    return options.html.indexOf('jwplayer') > -1;
  }

  constructor() {
    super();
    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.state = { errorMessage: '' };
    this.player = null;
    this.onEscPress = this.onEscPress.bind(this);
    this.play = this.play.bind(this);
  }

  componentDidMount() {
    if (!this.state.errorMessage) {
      this.setupPlayer();
    }
    addEventListener('keydown', this.onEscPress);
  }

  componentWillUnmount() {
    removeEventListener('keydown', this.onEscPress);
  }

  onEscPress(e) {
    if (e.which === ESC_KEYCODE) {
      this.props.closeModal();
      e.preventDefault();
    }
  }

  setupPlayer() {
    const mediaData = this.getMediaData();
    if (!mediaData) return;

    this.player = window.jwplayer('media').setup({
      file: mediaData.file,
      image: mediaData.image,
      width: '100%',
      height: '100%',
      stretching: 'uniform',
      flashplayer: '/assets/scripts/jwplayer.flash.swf',
      primary: 'html5',
      events: {
        onReady: this.play,
      },
    });
  }

  getMediaData() {
    const html = this.props.__html;
    let mediaData,
      mediaDataStr = '';
    try {
      mediaDataStr = html.match(/(\(\{(.|\n)*?\}\))+/g)[0].slice(1, -1);
      // eslint-disable-next-line no-eval
      eval(`mediaData=${mediaDataStr}`);

      if (!mediaData || !mediaData.file || !mediaData.image) {
        throw new Error('file or image does not exist');
      }
      return mediaData;
    } catch (err) {
      this.setState({
        errorMessage: 'An error occurred while processing your request.',
      });
      logger.error(
        `Media setting error: ${err.message}, Media data: ${mediaDataStr}`,
      );
      return false;
    }
  }

  play() {
    this.player.play();
  }

  handleCloseClick() {
    this.props.closeModal();
  }

  render() {
    if (this.state.errorMessage !== '') {
      return (
        <div
          className="cutlinevideo cutlinevideo--error tablet-grid-100 mobile-grid-100 grid-parent"
          aria-label="We are navigating to the How to Video"
        >
          <div className="grid-100 tablet-grid-100 mobile-grid-100 tablet-grid-100 mobile-grid-100">
            <h2 className="error">Error</h2>
            <p className="cutlinevideo__error-message">
              {this.state.errorMessage}
            </p>
          </div>
          <div className="grid-100 tablet-grid-100 mobile-grid-100 grid-parent">
            <div className="grid-50 tablet-grid-50 mobile-grid-100">
              <Button
                className="bops__close-button"
                value="OK"
                onTouchTap={this.handleCloseClick}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="cutlinevideo tablet-grid-100 mobile-grid-100 grid-parent"
          aria-label="We are navigating to the How to Video"
        >
          <div className="cutlinevideo__mediawrapper tablet-grid-100 mobile-grid-100 grid-parent">
            <div className="cutlinevideo__mediaspace tablet-grid-100 mobile-grid-100 grid-parent">
              <div id="media">Loading...</div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapDispatchToProps = { closeModal };
export default connect(
  undefined,
  mapDispatchToProps,
)(DumbCutlineVideoModal);
