import React, { Fragment, useEffect, useState } from 'react';
import { bool, object } from 'prop-types';
import get from 'lodash/get';
import merge from 'lodash/merge';
import classNames from 'classnames';
import Layout from './layout';
import ContentItem from '../contentItem/contentItem';
import { defaultTrackingTags } from '../../constants/constants';
import { getTags } from '../utils';

const cdnHosts = (global.cloudinary && global.cloudinary.cdnHosts) || {};

/**
 * @function L1Layout
 * @param {Object} props.cmsContentFields
 * @param {Boolean} props.isMobilePhone
 * @param {Boolean} props.isNewHomePage
 * @param {String} props.placement
 * @param {Boolean} props.sessionExpired
 * @param {Boolean} props.validPromo
 * @param {Boolean} props.isSRGBOptionEnabled
 * @returns {React.ReactElement}
 */
const L1Layout = ({
  cmsContentFields,
  isMobilePhone,
  isNewHomePage,
  parentTrackingTags,
  sessionExpired,
  validPromo,
  isCmsL1FullBleedToggle,
  isSRGBOptionEnabled,
  isImageToggle,
  isLazyBgImageToggle,
  isHomePage,
}) => {
  const [bgUrl, setbgUrl] = useState('');
  useEffect(() => {
    if (isLazyBgImageToggle) {
      const background = get(cmsContentFields, 'background[0]', []);
      const backgroundProps = get(background, 'fields', {});
      const { mobileImage, desktopImage } = backgroundProps;
      const imageSrc =
        isMobilePhone && mobileImage ? mobileImage : desktopImage;
      const cdnImageSrc = Object.entries(cdnHosts).reduce(
        (url, [cloudinaryHost, nmHost]) => url.replace(cloudinaryHost, nmHost),
        imageSrc,
      );
      let url = '';
      if (cdnImageSrc && cdnImageSrc[0] && cdnImageSrc[0].secure_url) {
        url = isSRGBOptionEnabled
          ? cdnImageSrc[0].secure_url.replace('q_auto', 'cs_srgb,q_auto')
          : cdnImageSrc[0].secure_url;
      }
      if (url) {
        if (isHomePage === '/') {
          const lazyBackgrounds = [].slice.call(
            document.querySelectorAll('.parallax-wrapper'),
          );
          if ('IntersectionObserver' in window) {
            // eslint-disable-next-line compat/compat
            const lazyBackgroundObserver = new IntersectionObserver(entries => {
              entries.forEach(entry => {
                if (entry.isIntersecting) {
                  lazyBackgrounds.forEach(lazyBackground => {
                    lazyBackground.firstElementChild.style.background = `url(${url})`;
                  });
                  lazyBackgroundObserver.unobserve(entry.target);
                }
              });
            });
            lazyBackgrounds.forEach(lazyBackground => {
              lazyBackgroundObserver.observe(lazyBackground);
            });
          }
        } else {
          setbgUrl(url);
        }
      }
    }
  }, []);

  const subLayouts = get(cmsContentFields, 'l2Layout', []);
  const contentItems = get(cmsContentFields, 'components', []);
  const background = get(cmsContentFields, 'background[0]', []);
  const backgroundProps = get(background, 'fields', {});
  const { mobileImage, desktopImage } = backgroundProps;
  const imageSrc = isMobilePhone && mobileImage ? mobileImage : desktopImage;
  const cdnImageSrc = Object.entries(cdnHosts).reduce(
    (url, [cloudinaryHost, nmHost]) => url.replace(cloudinaryHost, nmHost),
    imageSrc,
  );
  let url = '';
  const widthAttribute = isMobilePhone
    ? cmsContentFields.mobileWidth
    : cmsContentFields.desktopWidth;

  if (cdnImageSrc && cdnImageSrc[0] && cdnImageSrc[0].secure_url) {
    url = isSRGBOptionEnabled
      ? cdnImageSrc[0].secure_url.replace('q_auto', 'cs_srgb,q_auto')
      : cdnImageSrc[0].secure_url;
  }

  const trackingTags = get(cmsContentFields, 'trackingTags', []);
  const currentTags = getTags(trackingTags);
  const mergedTrackingTags = merge({}, parentTrackingTags, currentTags);
  const isFullBleed =
    isCmsL1FullBleedToggle && cmsContentFields?.backgroundFullBleed;

  const content = (
    <Fragment>
      {!!subLayouts.length && (
        <Fragment>
          {subLayouts.map(item => (
            <Layout
              key={item.sys.id}
              cmsLayout={item}
              isMobilePhone={isMobilePhone}
              isNewHomePage={isNewHomePage}
              parentTrackingTags={mergedTrackingTags}
              sessionExpired={sessionExpired}
              validPromo={validPromo}
            />
          ))}
        </Fragment>
      )}
      {!subLayouts.length &&
        contentItems.map(item => (
          <div className="component content-layout" key={item.sys.id}>
            <ContentItem
              cmsContentItem={item}
              isMobilePhone={isMobilePhone}
              parentTrackingTags={mergedTrackingTags}
              sessionExpired={sessionExpired}
              validPromo={validPromo}
            />
          </div>
        ))}
    </Fragment>
  );

  return (
    <div
      className={classNames(`l1-layout width-${widthAttribute}`, {
        parallax: url,
        'l1-layout--full-bleed': isFullBleed,
      })}
    >
      {url && (
        <div className="parallax-wrapper">
          <div
            className={isImageToggle ? 'parallax-image-new' : 'parallax-image'}
            style={{
              backgroundImage: isLazyBgImageToggle
                ? `url(${bgUrl})`
                : `url(${url})`,
            }}
          />
        </div>
      )}
      {isCmsL1FullBleedToggle ? (
        <div className="l1-layout__content-wrapper">{content}</div>
      ) : (
        content
      )}
    </div>
  );
};

L1Layout.defaultProps = {
  parentTrackingTags: defaultTrackingTags,
};

L1Layout.propTypes = {
  cmsContentFields: object,
  isMobilePhone: bool,
  isNewHomePage: bool,
  parentTrackingTags: object,
  sessionExpired: bool,
  validPromo: bool,
  isSRGBOptionEnabled: bool,
  isImageToggle: bool,
  isLazyBgImageToggle: bool,
};

export default L1Layout;
