import URL from 'url-parse';
import getUrl from 'url';

const expectedHostName = `${NMConfig.APP_HOST_NAME}`;

const urlDefaults = {
  protocol: 'https',
  slashes: true,
  hostname: expectedHostName.split('//')[1],
};

export function normalize(address) {
  if (!address) {
    return '';
  }

  const parsed = new URL(address, urlDefaults);
  return parsed.toString();
}

export function getQueryParam(param, url) {
  const currentUrl = !url ? window.location.href : url;
  const parsed = getUrl.parse(currentUrl, true);
  return parsed.query[param];
}

export function hasProtocol(address) {
  const protocols = ['http:', 'https:'];
  return protocols.some(element => {
    return address.startsWith(element);
  });
}

export function prependSlashes(address) {
  return address.startsWith('//') || address.startsWith('/')
    ? address
    : `//${address}`;
}

export function parseUrlId(urlInfo) {
  const lastDashIndex = urlInfo.lastIndexOf('-');
  if (lastDashIndex === -1) {
    return urlInfo;
  }
  return urlInfo.slice(lastDashIndex + 1);
}
