import { formatMoney } from 'accounting';
import { EyeBrow1 } from 'bgo-common/Styleguide/Typography';
import {
  initializeSelectedCustomizationsAllTypes,
  openMonogram,
  setPersonalization,
  setSelectedCustomizations,
} from 'pdp/components/ProductPage/actions';
import {
  FREE_MONOGRAM,
  PERSONALIZATION_OPTIONAL_TEXT,
  SUPPRESSED_MONOGRAM,
} from 'pdp/constants';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './personalization.scss';

export class DumbPersonalization extends Component {
  constructor() {
    super();
    this.state = { showErrorMessage: false };
    this.getAdditionalCharge = this.getAdditionalCharge.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.editCustomizations = this.editCustomizations.bind(this);
    this.removeCustomizations = this.removeCustomizations.bind(this);
  }

  getAdditionalCharge(behaviorCode, additionalCharge) {
    if (behaviorCode === FREE_MONOGRAM || additionalCharge === 0.0) {
      return 'FREE';
    } else {
      return `+${formatMoney(additionalCharge, '$', 2)}`;
    }
  }

  handleClick() {
    const { product, selectedSku } = this.props;
    if (
      !product.isPersonalizationSelected &&
      selectedSku &&
      !selectedSku.sellable
    ) {
      this.setState({ showErrorMessage: true });
      return;
    }
    if (product && !product.isPersonalizationSelected) {
      this.props.openMonogram(product);
      this.setState({ showErrorMessage: false });
    } else {
      this.props.setPersonalization(false);
    }
  }

  removeCustomizations() {
    this.props.setPersonalization(false);
    this.props.setSelectedCustomizations([]);
    this.props.initializeSelectedCustomizationsAllTypes();
  }

  editCustomizations() {
    const { product, selectedSku } = this.props;
    if (selectedSku && !selectedSku.sellable) {
      this.setState({ showErrorMessage: true });
      return;
    }
    this.setState({ showErrorMessage: false });
    this.props.openMonogram(product);
  }

  render() {
    const { product = {} } = this.props;
    const { customization = {}, isPersonalizationSelected } = product;
    const { behaviorCode } = customization;
    // const additionalChargeElement = (<span id="customization-charge" className="personalization__details__text__charge">
    //             ({this.getAdditionalCharge(behaviorCode, additionalCharge)})
    //           </span>);

    // const isRequireMonogramProduct = () => {
    //   return (behaviorCode !== REQUIRED_MONOGRAM);
    // };
    const personalizationSection = (
      <div className="personalization">
        {/* <span className="personalization__title">Personalize this item:</span>
        {!product.productFlags.previewSupported && product.isPersonalizationSelected &&
        <span> No Preview Available</span>
        }
        <div className={isRequireMonogramProduct() ? 'personalization__details__sameline' : 'personalization__details__diff'}>
          <div className={isRequireMonogramProduct() ? 'personalization__details optional' : 'personalization__details required'}>
            { this.state.showErrorMessage && <ErrorMessage message={OOS_ERROR_MESSAGE} /> }
            {isRequireMonogramProduct()
              ? <button
                type="button"
                aria-pressed={isPersonalizationSelected}
                aria-label="Personalize"
                aria-describedby="customization-text"
                className={isPersonalizationSelected ? 'personalization__details__button personalization__details__button__checked' :
                  'personalization__details__button personalization__details__button__unchecked'}
                onClick={this.handleClick}
              />
              : <button
                type="button"
                aria-pressed={isPersonalizationSelected}
                aria-label="Personalize"
                aria-describedby="customization-text"
                className={isPersonalizationSelected ? 'personalization__details__button personalization__details__button__checked' :
                  'personalization__details__button personalization__details__button__unchecked'}
                onClick={this.handleClick}
              />
              }
            { isPersonalizationSelected
              ?
                <div id="customization-text" className={this.props.product.skus && this.props.product.skus[0].dropShip === false && isRequireMonogramProduct() ? "personalization__details__text" : "personalization__details__text personalization__details__text_sameline" }>
                  <span className="personalization__details__text__title">Added</span>
                  { isRequireMonogramProduct() && additionalChargeElement }
                  <PersonalizationCTA
                    editCustomizations={this.editCustomizations}
                    removeCustomizations={this.removeCustomizations}
                    behaviorCode={behaviorCode}
                  />
                </div>
              :
                <div id="customization-text" className="personalization__details__text">
                  <span className="personalization__details__text__title">Add</span>
                  { isRequireMonogramProduct() && additionalChargeElement }
                </div> }
          </div>
          {(isPersonalizationSelected &&
            (this.props.product.skus && this.props.product.skus[0].dropShip === false))
            ?
              <SelectedPersonalizations product={product} /> : null}
          </div> */}
        <button
          type="button"
          aria-pressed={isPersonalizationSelected}
          aria-label="Personalize"
          aria-describedby="customization-text"
          className={
            isPersonalizationSelected
              ? 'personalization__details__button personalization__details__button__checked'
              : 'personalization__details__button personalization__details__button__unchecked'
          }
          onClick={this.handleClick}
        />
        <span className="optional-text">
          <EyeBrow1>{PERSONALIZATION_OPTIONAL_TEXT}</EyeBrow1>
        </span>
      </div>
    );

    if (behaviorCode === SUPPRESSED_MONOGRAM) {
      return false;
    }
    return personalizationSection;
  }
}

const mapDispatchToProps = (dispatch, { product: { id: productId } }) => ({
  openMonogram: (...args) => dispatch(openMonogram(...args)),
  setPersonalization: (...args) =>
    dispatch(setPersonalization(productId, ...args)),
  setSelectedCustomizations: (...args) =>
    dispatch(setSelectedCustomizations(productId, ...args)),
  initializeSelectedCustomizationsAllTypes: (...args) =>
    dispatch(initializeSelectedCustomizationsAllTypes(...args)),
});

export default connect(
  undefined,
  mapDispatchToProps,
)(DumbPersonalization);
