import httpWithLogging from 'universal/http-client';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import logger from 'server/utilities/logger';
import { buildCookieString } from 'universal/utilities-cookies';
import { ECMSlots } from 'ecm/components/EcmGet';

export const types = {
  LOADING_TEMPLATE_TYPE: 'LOADING_TEMPLATE_TYPE',
  RESOLVED_TEMPLATE_TYPE: 'RESOLVED_TEMPLATE_TYPE',
  REJECTED_TEMPLATE_TYPE: 'REJECTED_TEMPLATE_TYPE',
  SET_CATEGORY_TEMPLATES_DATA_TO_UTAG: 'SET_CATEGORY_TEMPLATES_DATA_TO_UTAG',
  SET_FAVORITE_DESIGNER_INDEX_TO_UTAG: 'SET_FAVORITE_DESIGNER_INDEX_TO_UTAG',
  SET_SILO_THUMBNAIL_DATA: 'SET_SILO_THUMBNAIL_DATA',
  DESIGNER_INDEX_EMPTY_DYNAMIC_CONTENT: 'DESIGNER_INDEX_EMPTY_DYNAMIC_CONTENT',
};

const formatSiloBannerEcmForDispatch = dataArray => {
  return isEmpty(dataArray) || isEmpty(dataArray[0]) ? {} : dataArray[0];
};

const formatSiloMainAssetEcmForDispatch = dataArray => {
  return isEmpty(dataArray) || isEmpty(dataArray[1]) ? {} : dataArray[1];
};

const formatAnalyticsPageName = (templateType, data) => {
  if (templateType === 'emag') {
    return 'Emag';
  } else if (templateType === 'F') {
    return 'F0';
  } else if (templateType === 'DesignerIndex') {
    data.push('Designers');
    return data.map(curr => curr).join(':');
  }
  return data.map(curr => curr.name).join(':');
};

const formatCtpCallString = (
  categoryId,
  countryCode,
  navpath,
  ecmPreviewParam
) => {
  let callString;
  if (navpath === 'noNavPath') {
    callString = `${NMConfig.API_CTP}/${countryCode}/categoryTemplate/${categoryId}`;
  } else if (navpath.endsWith(categoryId)) {
    callString = `${NMConfig.API_CTP}/${countryCode}/categoryTemplate/${navpath}`;
  } else {
    callString = `${NMConfig.API_CTP}/${countryCode}/categoryTemplate/${categoryId}`;
  }
  return `${callString}${
    !isEmpty(ecmPreviewParam) ? '?' : ''
  }${ecmPreviewParam}`;
};

export function setUtagDataFunc(data) {
  let pageName = get(data, 'templateAttributes.navigation.breadcrumbs', []);
  if (pageName == null) {
    pageName = [];
  }
  const analyticsPageNameValue = formatAnalyticsPageName(
    data.templateType,
    pageName
  );
  let pageType =
    get(data, 'analyticsAttributes.boutique', false) === false
      ? 'category'
      : 'boutique';
  pageType =
    data.templateType === 'Silo' || data.templateType === 'DesignerIndex'
      ? 'Silo'
      : pageType;
  let pageTemplate = '';
  const pageTitle = get(data, 'pageAttributes.seoContent.title', '');
  if (data.templateType === 'DesignerIndex') {
    pageTemplate = 'Silo';
  } else if (data.templateType === 'F') {
    pageTemplate = 'F0';
  } else if (data.templateType === 'Entry') {
    pageTemplate = 'entry';
  } else {
    pageTemplate = data.templateType;
  }
  const pageDefinitionId = data.templateType === 'F' ? 'F0' : data.templateType;
  return pageTemplate === 'F0' || pageTemplate === 'emag'
    ? {
        pageDefinitionId,
        analyticsPageNameValue,
        pageTemplate,
        pageType,
        pageTitle,
      }
    : {
        pageDefinitionId,
        analyticsPageNameValue,
        pageTemplate,
        pageType,
      };
}

export function setFavoriteDesignerUtag(designerName, customerIsFavoriting) {
  return dispatch => {
    dispatch({
      type: types.SET_FAVORITE_DESIGNER_INDEX_TO_UTAG,
      payload: {
        event_name: 'FavoriteItem',
        item_type: 'designer',
        favorite_item_status: customerIsFavoriting ? 'favorite' : 'unfavorite',
        favorite_designer: designerName,
      },
    });
  };
}

export function getTemplateTypeDetails(
  categoryId,
  countryCode,
  navpath,
  facet,
  page
) {
  return (dispatch, getState) => {
    dispatch({ type: types.LOADING_TEMPLATE_TYPE });
    const state = getState();
    const { session, abTests = {} } = state;
    const context = state.api && state.api.requestContext;
    const apiTimeouts = get(state, 'apiTimeouts', {});
    const NAV_CAT_TEMP_API_TIMEOUT = apiTimeouts.CTP_SVC_TIMEOUT || 2000;
    const requestApi = httpWithLogging(state, NAV_CAT_TEMP_API_TIMEOUT);
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        TLTSID: get(session, 'TLTSID', ''),
        W2A: get(session, 'W2A', ''),
      }),
    };
    if (context && context['TRUE_CLIENT_IP']) {
      headers['true-client-ip'] = get(context, 'TRUE_CLIENT_IP');
    }
    if (facet) {
      headers.facet = facet;
    }
    if (page) {
      headers.page = page;
    }
    if (abTests.aemAbTestUsergroup) {
      headers.abTestUserGroup = abTests.aemAbTestUsergroup;
    }
    let ecmPreviewParam = '';
    if (context) {
      ecmPreviewParam = isEmpty(context.ECMPreview)
        ? ''
        : `ECMPreview=${context.ECMPreview}`;
    }
    const ctpCallString = formatCtpCallString(
      categoryId,
      countryCode,
      navpath,
      ecmPreviewParam
    );

    return requestApi
      .get(ctpCallString, { headers })
      .then(res => {
        const status = get(res.data, 'statusCode', 200);
        if (status !== 200) {
          logger.info(
            'Fetching category template type resulted in 404 status, with response: ',
            JSON.stringify(res)
          );
          return dispatch({ type: types.REJECTED_TEMPLATE_TYPE });
        } else {
          dispatch({ type: types.RESOLVED_TEMPLATE_TYPE, payload: res.data });
          logger.info(
            'Fetching category template type was successful, resulting in template type: ',
            res.data.templateType
          );
          if (get(res.data, 'pageAttributes.seoContent', false)) {
            dispatch({
              type: 'SET_META_INFO_CTP',
              payload: {
                ctpMetaInfo: get(
                  res.data,
                  'pageAttributes.seoContent.description',
                  ''
                ),
                metaTitle: res.data.pageAttributes.seoContent.title,
                metaDescription: res.data.pageAttributes.seoContent.description,
                canonicalUrl: res.data.pageAttributes.seoContent.canonicalUrl,
              },
            });
          }
          const setUtagData = setUtagDataFunc(res.data);
          if (
            setUtagData.analyticsPageNameValue === 'F0' ||
            setUtagData.analyticsPageNameValue === 'Emag'
          ) {
            if (setUtagData.pageTitle) {
              dispatch({
                type: types.SET_CATEGORY_TEMPLATES_DATA_TO_UTAG,
                payload: {
                  page_definition_id: setUtagData.pageDefinitionId,
                  page_name: setUtagData.analyticsPageNameValue,
                  page_template: setUtagData.pageTemplate,
                  page_type: setUtagData.pageType,
                  page_title: setUtagData.pageTitle,
                },
              });
            }
          } else {
            dispatch({
              type: types.SET_CATEGORY_TEMPLATES_DATA_TO_UTAG,
              payload: {
                page_definition_id: setUtagData.pageDefinitionId,
                page_name: setUtagData.analyticsPageNameValue,
                page_template: setUtagData.pageTemplate,
                page_type: setUtagData.pageType,
              },
            });
          }

          if (
            get(
              res.data,
              'templateAttributes.dynamicContent.thumbnails',
              null
            ) &&
            get(res.data, 'templateType', '') === 'Silo'
          ) {
            dispatch({
              type: types.SET_SILO_THUMBNAIL_DATA,
              payload: get(
                res.data,
                'templateAttributes.dynamicContent.thumbnails',
                []
              ),
            });
          }
          if (get(res.data, 'templateAttributes.ecmContent', false)) {
            if (get(res.data, 'templateType', '') === 'Silo') {
              dispatch({
                type: `RESOLVED_ECM${ECMSlots.PLP_BANNER.contentId}`,
                payload: formatSiloBannerEcmForDispatch(
                  get(res.data, 'templateAttributes.ecmContent', [])
                ),
                contentId: ECMSlots.PLP_BANNER.contentId,
              });
              dispatch({
                type: `RESOLVED_ECM${ECMSlots.SILO_FULL_ASSET.contentId}`,
                payload: formatSiloMainAssetEcmForDispatch(
                  get(res.data, 'templateAttributes.ecmContent', [])
                ),
                contentId: ECMSlots.SILO_FULL_ASSET.contentId,
              });
            } else {
              dispatch({
                type: `RESOLVED_ECM${ECMSlots.PLP_BANNER.contentId}`,
                payload: res.data.templateAttributes.ecmContent,
                contentId: ECMSlots.PLP_BANNER.contentId,
              });
            }
          }
          if (
            get(res.data, 'templateAttributes.navigation.breadcrumbs', false)
          ) {
            dispatch({
              type: 'RESOLVED_BREADCRUMB_CONTENT',
              payload: {
                breadcrumbs: res.data.templateAttributes.navigation.breadcrumbs,
              },
            });
          }
          if (
            get(res.data, 'templateAttributes.navigation.leftNavigation', false)
          ) {
            dispatch({
              type: 'RESOLVED_LEFTNAV_CONTENT',
              payload: res.data.templateAttributes.navigation.leftNavigation,
            });
          }
        }

        if (
          get(res.data, 'templateType', null) === 'X' &&
          get(res.data, 'templateAttributes.redirectUrl', '') === null
        ) {
          logger.error(
            `Category ID: ${res.data.id} with Template type: ${res.data.templateType} without long description defined results in 404`
          );
          return dispatch({ type: types.REJECTED_TEMPLATE_TYPE });
        }

        if (
          get(res.data, 'templateType', '') === 'DesignerIndex' &&
          isEmpty(res.data.templateAttributes.dynamicContent.designers)
        ) {
          logger.error(
            `Category ID: ${res.data.id} with Template type: ${res.data.templateType}, error: received null or empty reponse for dynamicContent.designers from CTP-SVC, Redirecting to 404: ${NMConfig.ERROR_PAGE_URL}?source=DesignerIndex`
          );
          return dispatch({ type: types.DESIGNER_INDEX_EMPTY_DYNAMIC_CONTENT });
        }

        return dispatch({
          type: types.RESOLVED_TEMPLATE_TYPE,
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch({ type: types.REJECTED_TEMPLATE_TYPE });
      });
  };
}
