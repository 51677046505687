import React from 'react';
import classNames from 'classnames';
import './RegisterInputField.scss';

export function RegisterInputField(props) {
  const {
    title,
    maxLength,
    value,
    name,
    onChange,
    error,
    type = 'text',
    onFocus,
    noBottomMargin,
  } = props;

  const mainClassName = classNames('register-input-field', {
    'register-input-field--has-error': !!error,
    'register-input-field--mb0': noBottomMargin || !!error,
  });

  const inputClassName = classNames('register-input-field__input', {
    'register-input-field__input_error': error,
  });

  const inputType = type === 'password' ? 'password' : 'text';

  const id = `register-input-${name}`;

  return (
    <div className={mainClassName}>
      <label className="register-input-field__label" htmlFor={id}>
        <input
          id={id}
          maxLength={maxLength}
          className={inputClassName}
          value={value}
          name={name}
          onChange={onChange}
          onFocus={onFocus}
          type={inputType}
          placeholder=" "
        />
        <span className="register-input-field__label-text">{title}</span>
      </label>
      {error && <div className="register-input-field__error">{error}</div>}
    </div>
  );
}
