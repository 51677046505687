/* eslint-disable no-case-declarations */
import React from 'react';
import { BADGES_PRIORITY_ORDER } from 'bgo-common/client/common/constants';
import './badge.scss';

const findDisplayableBadges = ({
  preOrder,
  isNewArrival,
  isExclusivelyOurs,
  promotions,
  promoBadge,
  backOrder,
  newMarkDown,
}) => {
  const displayableBadges = [];
  const maxBadgesToShow = 4;
  const productBadges = [
    'PRE-ORDER',
    'NEW ARRIVAL',
    'EXCLUSIVELY BG',
    'New Markdown',
    'PROMO-BADGE',
    'BACK-ORDER',
  ];
  BADGES_PRIORITY_ORDER.forEach(value => {
    if (displayableBadges.length < maxBadgesToShow && value.key) {
      switch (value.key) {
        case 'promotions':
          const promotionWithoutPrice =
            promotions && promotions.promotionWithoutPrice;
          if (promotionWithoutPrice && promotionWithoutPrice.length > 0) {
            for (
              let i = 0;
              i < Math.min(promotionWithoutPrice.length) && i < 3;
              i++
            ) {
              promotions.field &&
                promotionWithoutPrice[i][promotions.field] &&
                displayableBadges.push(
                  promotionWithoutPrice[i][promotions.field],
                );
            }
          }
          break;
        case 'PRE-ORDER':
          preOrder &&
            !displayableBadges.some(r => productBadges.indexOf(r) >= 0) &&
            displayableBadges.unshift(value.key);
          break;
        case 'NEW ARRIVAL':
          isNewArrival &&
            !displayableBadges.some(r => productBadges.indexOf(r) >= 0) &&
            displayableBadges.unshift(value.key);
          break;
        case 'EXCLUSIVELY BG':
          isExclusivelyOurs &&
            !displayableBadges.some(r => productBadges.indexOf(r) >= 0) &&
            displayableBadges.unshift(value.key);
          break;
        case 'PROMO-BADGE':
          promoBadge &&
            !displayableBadges.some(r => productBadges.indexOf(r) >= 0) &&
            displayableBadges.unshift(value.key);
          break;
        case 'BACK-ORDER':
          backOrder &&
            !displayableBadges.some(r => productBadges.indexOf(r) >= 0) &&
            displayableBadges.unshift(value.key);
          break;
        case 'New Markdown':
          newMarkDown &&
            !displayableBadges.some(r => productBadges.indexOf(r) >= 0) &&
            displayableBadges.unshift('New to Sale');
          break;
        default:
          break;
      }
    }
  });
  return displayableBadges;
};

const badges = props => {
  return findDisplayableBadges(props).map((value, index) => {
    return (
      <span className="badgeTxt" key={index}>
        <span
          className="badgeMsg"
          dangerouslySetInnerHTML={{ __html: value }}
        />
      </span>
    );
  });
};

export default badges;
