import { isValidPhoneNumber } from 'react-phone-number-input';
import { validationError } from '../../../constants';

export const validations = {
  notEmpty: (data = '') =>
    data.trim() !== '' ? null : validationError.requiredField,
  email: (data = '') =>
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      data,
    )
      ? null
      : validationError.emailIsNotValid,
  phone: (data = '') =>
    isValidPhoneNumber(data) ? null : validationError.phoneIsNotValid,
  services: (data = []) =>
    data.length ? null : validationError.servicesNotSelected,
};

export const validate = (data, validations = []) => {
  const validationFunctions = !Array.isArray(validations)
    ? [validations]
    : validations;
  return validationFunctions.reduce((acc, fun) => acc || fun(data), null);
};

export const formatters = {
  name: (data = '') => data.replace(/[^a-zA-Z .]/g, ''),
  email: (data = '') => data.replace(/[^a-zA-Z0-9.!#$%&'*+/=?^_`{|}@~-]/g, ''),
};
