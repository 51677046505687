import React from 'react';
import AppMyAccountWrapper from './app-my-account-wrapper';
import WishlistPage from '../../myaccount/pages/WishlistPage/WishlistPage';

export default function AppWishlistWrapper() {
  return (
    <AppMyAccountWrapper>
      <WishlistPage />
    </AppMyAccountWrapper>
  );
}
