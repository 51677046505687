import httpWithLogging from 'universal/http-client';
import { sanitizeBloomReach } from 'client-utils/utilities-html-sanitizer';
import logger from 'server-utils/logger';
import get from 'lodash/get';
import { defaultApiTimeout } from './constants';

export const RESOLVED_TH_HTML = 'RESOLVED_TH_HTML';
export const REJECTED_TH_HTML = 'REJECTED_TH_HTML';
export const REJECTED_TOGGLE = 'REJECTED_TOGGLE';
export const REJECTED_TH_ERROR = 'REJECTED_TH_ERROR';

export function getThPageHtml(thPageParam) {
  return (dispatch, getState) => {
    try {
      const state = getState();
      const isThematicToggle = get(state, 'toggles.THEMATIC', false);

      if (isThematicToggle) {
        const requestURI = `${NMConfig.API_THEMATIC +
          global.Thematic_API_Key +
          NMConfig.API_THEMATIC_VERSION +
          thPageParam}.html`;
        const requestApi = httpWithLogging(state, defaultApiTimeout);

        return requestApi
          .get(requestURI)
          .then(successResponse => {
            const responseHeader = JSON.stringify(
              sanitizeBloomReach(
                successResponse.data.slice(
                  0,
                  successResponse.data.indexOf('<style>'),
                ),
                [],
                {},
                ['title'],
              ),
            );
            const responseHtml = JSON.stringify(
              sanitizeBloomReach(
                successResponse.data.slice(
                  successResponse.data.indexOf('<style>'),
                ),
                [],
                { img: ['src'], option: ['selected'], input: ['checked'] },
                [],
              ),
            );
            return dispatch({
              type: RESOLVED_TH_HTML,
              payload: {
                data: responseHtml,
                header: responseHeader,
              },
            });
          })
          .catch(error => {
            dispatch({
              type: REJECTED_TH_HTML,
              payload: thPageParam,
            });
            logger.error(`Fetching ${thPageParam} BR page Failed: ${error}`);
          });
      }
      return dispatch({ type: REJECTED_TOGGLE });
    } catch (e) {
      logger.error(`Action Failed: ${e}`);
      return dispatch({ type: REJECTED_TH_ERROR });
    }
  };
}
