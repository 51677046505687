import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import CloseIcon from '@bgo-ui/common/client/common/components/CloseIcon/closeIcon';
import { closeModal } from '@bgo-ui/common/client/common/components/Modal/actions';
import { getABTestAssignments } from 'shared/actions/actions-page';
import './modal.scss';
import { isNewMyAccountEnabled } from '../../../myaccount/utilities/is-new-myaccount';
import NewAccountRegisterPage from '../register/NewAccountRegisterPage/NewAccountRegisterPage';
import { getContentForLoginPage } from '../../actions';
import AccountAccess from '../../AccountAccess/AccountAccess';

const signupModal = props => {
  const [activeTab, setActiveTab] = useState('Signin');

  useEffect(() => {
    props.getContentForLoginPage();
  }, []);
  const handleCloseClick = e => {
    props.closeModal();
    e.preventDefault();
  };

  const handleTabSwitch = tab => {
    setActiveTab(tab);
  };

  const RenderPage =
    activeTab === 'Signin' ? AccountAccess : NewAccountRegisterPage;
  return (
    <div className="signup-popup">
      <CloseIcon onClick={handleCloseClick} />
      <div className="signup-popup-wrapper">
        <RenderPage newLayout handleTabSwitch={handleTabSwitch} />
      </div>
    </div>
  );
};

signupModal.propTypes = {
  verificationCodeSent: PropTypes.bool.isRequired,
  updateBasicAttributesSuccess: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  verificationCodeSent: get(
    state,
    'myaccount.myAccountDetails.verificationCode.success',
    false,
  ),
  updateBasicAttributesSuccess: get(
    state,
    'myaccount.myAccountDetails.updateBasicAttributesSuccess',
    false,
  ),
  newMobileNavToggle: state.toggles.NEW_MOBILE_NAV,
  abtestAemContentToggle: state.toggles.ABTEST_AEM_CONTENT_TARGETING,
  abTestApi: state.api.abtest || {},
  isNewMyAccountEnabled: isNewMyAccountEnabled(state),
});

const mapDispatchToProps = {
  closeModal,
  getABTestAssignments,
  getContentForLoginPage,
};

const ChangeEmailModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(signupModal);

export default ChangeEmailModal;
