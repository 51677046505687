import $ from 'jquery';
import React from 'react';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import Button from 'shared/components/Button/button';
import './addToBagConfigModal.scss';

const getImageUrl = (imageUrl = '') => {
  if (imageUrl.indexOf(NMConfig.SCENE7_URL) !== -1) {
    return imageUrl;
  }
  return `http://neimanmarcus.com${imageUrl}`;
};

const getProductHtmls = content => {
  return $(content)
    .find('tr')
    .map((index, row) => {
      const $row = $(row);
      const $img = $row.find('img');
      return {
        content: $row.find('td').get(0).innerHTML,
        image: {
          url: getImageUrl($img.attr('src')),
          alt: $img.attr('alt'),
        },
      };
    })
    .toArray();
};

const AddToBagConfigModal = ({ content, securityStatus }) => {
  let checkoutUrl = NMConfig.CHECKOUT_PAGE_URL;
  if (securityStatus && securityStatus.toLowerCase() === 'authenticated') {
    checkoutUrl = NMConfig.ORDER_REVIEW_URL;
  }
  const strapPageUrl = NMConfig.STRAP_PAGE_URL;

  return (
    <div className="addToBagConfigModal grid-offset">
      <div className="grid-offset grid-100 tablet-grid-100">
        <div className="mobile-grid-100 grid-100 table-grid-100">
          <h2>Added to your shopping bag</h2>
        </div>
        {getProductHtmls(content).map((product, index) => (
          <div
            key={index}
            className="mobile-grid-100 tablet-grid-50 grid-50 productInfo"
          >
            <div className="mobile-grid-25 grid-25 tablet-grid-25">
              <img src={product.image.url} alt={product.image.alt} />
            </div>
            <div className="mobile-grid-75 grid-75 tablet-grid-75">
              {renderHTML(product.content)}
            </div>
          </div>
        ))}
      </div>
      <br />
      <div
        id="addAnotherStrapInfo"
        className="mobile-grid-100 grid-100 tablet-grid-100"
      >
        Would you like to buy another strap to mix and match your watch?
      </div>
      <br />
      <div className="mobile-grid-100 grid-50 tablet-grid-50">
        <a href={strapPageUrl}>
          <Button secondary value="BUY ANOTHER STRAP" />
        </a>
      </div>

      <div
        id="checkout-button"
        className="mobile-grid-100 grid-50 tablet-grid-50"
      >
        <a href={checkoutUrl}>
          <Button value="CHECKOUT" />
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  content: state.modal.content,
  securityStatus: state.user.securityStatus,
});
export default connect(mapStateToProps)(AddToBagConfigModal);
