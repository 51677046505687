import httpWithLogging from 'universal/http-client';
import { getGuestTokens, setGuestTokens } from '.';

export const isGuestValid = state => {
  const { AccessToken, ExpiresAt, RefreshToken } = getGuestTokens([
    'AccessToken',
    'ExpiresAt',
    'RefreshToken',
  ]);

  if (!AccessToken) return Promise.reject(Error('No access token'));
  if (Date.now() < ExpiresAt) return Promise.resolve('Valid access Token');
  const requestApi = httpWithLogging(state, 8000);
  const body = {
    token: RefreshToken,
  };
  return requestApi
    .post(NMConfig.API_GUEST_IDENTITY, body)
    .then(response => {
      setGuestTokens(response);
      return Promise.resolve('Token Refreshed!');
    })
    .catch(() => Promise.reject(Error('Invalid Refresh Token')));
};
