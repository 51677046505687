import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { closeModal } from 'shared/components/Modal/actions';
import { goToForgotPasswordStep2, forgotPassword } from '../actions';
import ForgotPasswordModalForm from './ForgotPasswordModalForm';
import ForgotPasswordModalConfirmation from './ForgotPasswordModalConfirmation';
import './ForgotPasswordModal.scss';

class ForgotPasswordModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: null,
    };
  }

  onSubmit(e) {
    e.preventDefault();

    const email = e.target.email.value;

    if (email) {
      this.setState({ email });
      this.props.goToForgotPasswordStep2();
    }
  }

  render() {
    const { email } = this.state;
    const { isLoading, error, closeModal, forgotPassword } = this.props;

    return email ? (
      <ForgotPasswordModalConfirmation
        email={email}
        isLoading={isLoading}
        error={error}
        onConfirm={() => forgotPassword({ email })}
        onCancel={() => closeModal()}
      />
    ) : (
      <ForgotPasswordModalForm onSubmit={e => this.onSubmit(e)} />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: get(state, 'login.forgotPassword.isLoading', false),
  error: get(state, 'login.forgotPassword.error', false),
});
const mapDispatchToProps = {
  closeModal,
  goToForgotPasswordStep2,
  forgotPassword,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotPasswordModal);
