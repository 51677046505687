import { types } from 'client/common/components/App/app-actions';

const defaultState = '';

const isNavPathPresent = (action) => {
  return action.payload.request.query.navpath;
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.REQUEST_RECEIVED:
      if (isNavPathPresent(action)) {
        return action.payload.request.query.navpath;
      } else {
        return action.payload.request.query.xbcpath || defaultState;
      }
    default:
      return state;
  }
};
