import httpWithLogging from 'universal/http-client';
import * as Scroll from 'react-scroll';
import { getUserDataWithGuestIdentityOn } from '@bgo-ui/common/client/utilities/utilities-amplify';

export const types = {
  LOADING_DT_MINI_CART_DATA: 'LOADING_DT_MINI_CART_DATA',
  RESOLVED_DT_MINI_CART_DATA: 'RESOLVED_DT_MINI_CART_DATA',
  REJECTED_DT_MINI_CART_DATA: 'REJECTED_DT_MINI_CART_DATA',
  SHOW_DT_MINI_CART: 'SHOW_DT_MINI_CART',
  HIDE_DT_MINI_CART: 'HIDE_DT_MINI_CART',
  UPDATE_DT_MINI_CART_DATA: 'UPDATE_DT_MINI_CART_DATA',
  START_TIMER: 'START_TIMER',
  SET_CLOSE_TIMER: 'SET_CLOSE_TIMER',
};

const startCloseTimer = dispatch => {
  return setTimeout(() => {
    dispatch({ type: types.HIDE_DT_MINI_CART });
  }, 4000);
};

export const startTimer = () => {
  return dispatch => {
    const timerId = startCloseTimer(dispatch);
    dispatch({ type: types.SET_CLOSE_TIMER, timerId });
  };
};

export const showDtMinicart = () => {
  Scroll.animateScroll.scrollToTop();
  return dispatch =>
    dispatch({
      type: types.SHOW_DT_MINI_CART,
    });
};

export const hideDtMiniCart = () => {
  return dispatch =>
    dispatch({
      type: types.HIDE_DT_MINI_CART,
    });
};

export const updateDtMiniCart = cartData => {
  return dispatch =>
    dispatch({
      type: types.UPDATE_DT_MINI_CART_DATA,
      payload: cartData,
    });
};

export function loadDtMiniCartData() {
  return (dispatch, getState) => {
    dispatch({ type: types.LOADING_DT_MINI_CART_DATA });
    const state = getState();
    const disableATGToggle = state.toggles.DISABLE_ATG_CALLS;
    const { amplifyConfig } = state;
    const requestApi = httpWithLogging(state, 20000);

    return getUserDataWithGuestIdentityOn(state, true, true, disableATGToggle)
      .then(info => {
        const ucaId = info?.ucaId || null;
        const idToken = info?.idToken || null;
        const headers = {
          Authorization: idToken,
          accountId: ucaId,
        };
        return requestApi.post(
          `${NMConfig.API_DT_CART_DATA}`,
          {},
          {
            headers,
          },
        );
      })
      .then(res => {
        if (res?.data?.shoppingCart?.totalQuantity > 0) {
          dispatch({
            type: types.RESOLVED_DT_MINI_CART_DATA,
            payload: res.data,
          });
        }
      })
      .catch(() => {
        dispatch({ type: types.REJECTED_DT_MINI_CART_DATA });
      });
  };
}
