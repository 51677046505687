export const SET_EMAIL = 'SET_EMAIL';

export function setEmail(email) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_EMAIL,
      payload: email,
    });
  };
}
