import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  setHideOnDesktop,
  setHideOnTablet,
  setHideOnMobile,
} from 'ecm/actions/actions-ecmcontent';
import { EyeBrow4 } from '@bgo-ui/common/Styleguide/Typography';
import EcmImageMap from '../EcmImageMap/ecmImageMap';
import EcmTextOverlays from '../EcmTextOverlays/ecmTextOverlays';
import EcmButtonOverlays from '../EcmButtonOverlays/EcmButtonOverlays';
import {
  ecmGetAdaptiveStyleBlock,
  ecmGetOptimizedAdaptiveStyleBlock,
} from '../../utilities';

import {
  MIN_DESKTOP_WIDTH,
  MIN_TABLET_WIDTH,
  MAX_TABLET_WIDTH,
  MIN_MOBILE_WIDTH,
  MAX_MOBILE_WIDTH,
} from 'bgo-common/client/common/constants';

const wrapAnchor = (anchor, element) => {
  let pictureElt = element;
  if (!isEmpty(anchor)) pictureElt = <a href={anchor}>{pictureElt}</a>;
  return pictureElt;
};

export class EcmAdaptiveImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultImage: `${get(
        this.props.picture,
        'properties.images.desktop.imagesrc',
        '',
      )}`,
      temp: null,
    };
  }
  componentDidMount() {
    const { picture, ecmSlot } = this.props;
    if (get(picture, 'properties.images.desktop.hide', false))
      this.props.setHideOnDesktop(ecmSlot);
    if (get(picture, 'properties.images.tablet.hide', false))
      this.props.setHideOnTablet(ecmSlot);
    if (get(picture, 'properties.images.mobile.hide', false))
      this.props.setHideOnMobile(ecmSlot);
  }
  handleMouseEnter = event => {
    let target = event.target,
      altImg = target.dataset.altImg,
      curSrc = target.currentSrc;
    altImg && this.setState({ defaultImage: altImg, temp: curSrc });
  };
  handleMouseLeave = event => {
    let target = event.target,
      altImg = target.dataset.altImg,
      tempImg = this.state.temp;
    altImg && this.setState({ defaultImage: tempImg, temp: altImg });
  };

  render() {
    const {
      picture,
      ecmSlot,
      formatAdaptiveStyleBlock = true,
      textOverlayElements,
      buttonOverlayElements,
      buttonOverlayToggle,
      improvePerformanceOfStyleToggle = false,
      showCaption,
    } = this.props;

    const ecmStyle = get(ecmSlot, 'className', '');
    const ecmComponentId = get(picture, 'componentId', '');
    const ecmImages = get(picture, 'properties.images', '');
    const ecmPictureStyle = improvePerformanceOfStyleToggle
      ? `ecm-${ecmComponentId}`
      : `ecm-container__image__${ecmComponentId}`;
    const linkurl = get(picture, 'properties.linkurl', '');
    const classes = improvePerformanceOfStyleToggle
      ? classNames('ecm-container__image optst', ecmStyle, ecmPictureStyle)
      : classNames('ecm-container__image', ecmStyle, ecmPictureStyle);

    const adaptiveStyleBlockFunc = () => {
      if (formatAdaptiveStyleBlock) {
        return improvePerformanceOfStyleToggle
          ? ecmGetOptimizedAdaptiveStyleBlock(ecmImages, ecmPictureStyle)
          : ecmGetAdaptiveStyleBlock(ecmImages, ecmPictureStyle);
      } else {
        return '';
      }
    };

    const heroContent = {
      ctaContent: {
        label: 'Shop the Style',
        url: '#',
      },
      desktopPosition: 'right',
      mobilePosition: 'center',
    };

    const { ctaContent, desktopPosition, mobilePosition } = heroContent;

    const adaptiveStyleBlock = adaptiveStyleBlockFunc();
    const titleHtml =
      picture.properties.title === null || isEmpty(picture.properties.title)
        ? ''
        : picture.properties.title;
    const altHtml =
      picture.properties.alttext === null || isEmpty(picture.properties.alttext)
        ? ''
        : picture.properties.alttext;
    const desktopImg = this.state.defaultImage;
    const tabletImg = `${
      get(ecmImages, 'tablet.imagesrc', '') === null ||
      isEmpty(get(ecmImages, 'tablet.imagesrc', ''))
        ? ''
        : get(ecmImages, 'tablet.imagesrc', '')
    }`;
    const mobileImg = `${
      get(ecmImages, 'mobile.imagesrc', '') === null ||
      isEmpty(get(ecmImages, 'mobile.imagesrc', ''))
        ? ''
        : get(ecmImages, 'mobile.imagesrc', '')
    }`;
    const defaultImg = `${get(ecmImages, 'desktop.imagesrc', '')}`;

    let pictureElt = (
      <React.Fragment>
        <picture>
          <source
            media={`(min-width: ${MIN_DESKTOP_WIDTH}px)`}
            srcSet={desktopImg}
          />
          <source
            media={`(min-width: ${MIN_TABLET_WIDTH}px) and (max-width: ${MAX_TABLET_WIDTH}px)`}
            srcSet={tabletImg}
          />
          <source
            media={`(min-width: ${MIN_MOBILE_WIDTH}px) and (max-width: ${MAX_MOBILE_WIDTH}px)`}
            srcSet={mobileImg}
          />
          <img
            srcSet={defaultImg}
            alt={altHtml}
            title={titleHtml}
            data-alt-img={get(ecmImages, 'desktop.altSrc', '')}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          />
        </picture>
        {showCaption && (
          <div className="slide-image-caption">
            <p>{titleHtml}</p>
          </div>
        )}
      </React.Fragment>
    );
    pictureElt = wrapAnchor(linkurl, pictureElt);
    const hasImageMaps =
      !isEmpty(get(ecmImages, 'desktop.map')) ||
      !isEmpty(get(ecmImages, 'tablet.map')) ||
      !isEmpty(get(ecmImages, 'mobile.map'));
    const imageMaps = hasImageMaps && <EcmImageMap picture={picture} />;

    if (improvePerformanceOfStyleToggle) {
      return (
        <div>
          {adaptiveStyleBlock}
          <div className={classes}>
            <div
              className={classNames('ecm-container__image__picture', {
                'no-image-on-mobile': !mobileImg,
              })}
            >
              {pictureElt}
              {imageMaps}
              {textOverlayElements &&
                (isEmpty(linkurl) ? (
                  <EcmTextOverlays
                    dataObj={textOverlayElements}
                    ecmSlot={ecmSlot}
                  />
                ) : (
                  <a href={linkurl}>
                    <EcmTextOverlays
                      dataObj={textOverlayElements}
                      ecmSlot={ecmSlot}
                    />
                  </a>
                ))}
              {buttonOverlayToggle && buttonOverlayElements && (
                <EcmButtonOverlays
                  dataObj={buttonOverlayElements}
                  inheritedTabImage={
                    desktopImg === tabletImg || isEmpty(tabletImg)
                  }
                  inheritedMobileImage={
                    desktopImg === mobileImg || isEmpty(mobileImg)
                  }
                  ecmSlot={ecmSlot}
                />
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className={classes}>
            <div
              className={classNames('ecm-container__image__picture', {
                'no-image-on-mobile': !mobileImg,
              })}
            >
              {pictureElt}
              {imageMaps}
              {textOverlayElements &&
                (isEmpty(linkurl) ? (
                  <EcmTextOverlays
                    dataObj={textOverlayElements}
                    ecmSlot={ecmSlot}
                  />
                ) : (
                  <a href={linkurl}>
                    <EcmTextOverlays
                      dataObj={textOverlayElements}
                      ecmSlot={ecmSlot}
                    />
                  </a>
                ))}
              {buttonOverlayToggle && buttonOverlayElements && (
                <EcmButtonOverlays
                  dataObj={buttonOverlayElements}
                  inheritedTabImage={
                    desktopImg === tabletImg || isEmpty(tabletImg)
                  }
                  inheritedMobileImage={
                    desktopImg === mobileImg || isEmpty(mobileImg)
                  }
                  ecmSlot={ecmSlot}
                />
              )}
              {adaptiveStyleBlock}
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapDispatchToProps = {
  setHideOnDesktop,
  setHideOnTablet,
  setHideOnMobile,
};

const mapStateToProps = state => ({
  buttonOverlayToggle: state.toggles.ECM_BUTTON_OVERLAY,
});

EcmAdaptiveImage.defaultProps = {
  heroContent: {
    eyeBrowContent: 'Editorial',
    titleContent: 'OUR NEW FAVORITE LABELS',
    subCopyContent: 'Add instant glamour with these pieces',
    ctaContent: {
      label: 'Shop the Style',
      url: '#',
    },
    desktopPosition: 'right',
    mobilePosition: 'center',
  },
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EcmAdaptiveImage);
