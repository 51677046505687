import log from 'server/utilities/logger';
import consul from 'consul';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import set from 'lodash/set';

class TogglesRepository {
  constructor() {
    this.toggles = {};
    this.host = process.env.CONSUL_ADDR || NMConfig.CONSUL_HOST;
    this.port = process.env.CONSUL_PORT || NMConfig.CONSUL_PORT;
    this.consulKeyPath = 'toggles/data';
  }

  getToggles(servicesOnly, isNoTogglesHeaderCookie) {
    if (isEmpty(this.toggles)) {
      log.info(
        `Consul: connecting at '${this.host}:${this.port}. Key: ${this.consulKeyPath}'`,
      );
      const consulClient = consul({ host: this.host, port: this.port });

      return this.fetchToggles(this.consulKeyPath, consulClient)
        .then(toggles => set(this, 'toggles', toggles))
        .then(this.watchForConsulUpdates(consulClient))
        .then(() => this.toggles)
        .catch(e => {
          log.error(`getToggles - ERROR ${e}`);
        });
    }
    const noHeaderToggle = get(this.toggles, 'NO_TOGGLES_HEADER', false);
    const isNoTogglesHeaderOn = noHeaderToggle.isTurnedOn;
    const shouldFilterToggles =
      servicesOnly && (isNoTogglesHeaderCookie || isNoTogglesHeaderOn);

    return shouldFilterToggles
      ? this.getServiceToggles()
      : Promise.resolve(this.toggles);
  }

  static filterServiceOnlyToggles(togglesObject) {
    const serviceOnlyToggles = isEmpty(this.serviceOnlyToggles)
      ? {}
      : this.serviceOnlyToggles;
    if (isEmpty(this.serviceOnlyToggles)) {
      Object.keys(togglesObject).forEach(toggle => {
        const isServicesUndefined =
          typeof togglesObject[toggle].services === 'undefined';
        const isServicesPopulated = togglesObject[toggle]?.services?.length > 0;
        const isServiceToggle = !isServicesUndefined && isServicesPopulated;
        if (isServiceToggle) {
          serviceOnlyToggles[toggle] = togglesObject[toggle];
        }
      });
    }

    return serviceOnlyToggles;
  }

  getServiceToggles() {
    this.serviceOnlyToggles = TogglesRepository.filterServiceOnlyToggles(
      this.toggles,
    );
    return Promise.resolve(this.serviceOnlyToggles);
  }

  // eslint-disable-next-line class-methods-use-this
  fetchToggles(key, consulClient) {
    return new Promise((resolve, reject) => {
      consulClient.kv.get({ key }, (err, result) => {
        if (err) {
          log.warn(`Consul: Consul Key is missing for '${key}'`);
          reject(err);
        }
        try {
          log.info(
            `Consul: received initial value for '${key}': ${result.Value}`,
          );
          resolve(JSON.parse(result.Value));
        } catch (e) {
          log.error(
            `Consul: JSON parse exception for toggles object: ${key}`,
            e,
          );
          reject(e);
        }
      });
    });
  }

  watchForConsulUpdates(consulClient) {
    log.info(
      `Consul: watching for toggles changes. Key: '${this.consulKeyPath}'`,
    );
    const watch = consulClient.watch({
      method: consulClient.kv.get,
      options: {
        key: this.consulKeyPath,
      },
    });

    watch.on('change', data => {
      if (data) {
        try {
          this.toggles = JSON.parse(get(data, 'Value', {}));
          log.info('Consul: toggles changed');
        } catch (e) {
          log.error('Consul: JSON parse exception for toggles:', e);
        }
      }
    });

    watch.on('error', err => {
      log.error('Consul error: ', err);
    });
  }
}

export default new TogglesRepository();
