import React from 'react';
import './index.scss';
import appImage from 'assets/images/icons-app.svg';
import { getAppConfigByKey } from 'server-utils/utilities-page';
import { ConsulConfigKeys } from '../../../../server/constants';

const getMobileAppiOSLink = () => {
  const value = getAppConfigByKey(ConsulConfigKeys.MOBILE_APP_DOWNLOAD_LINK);
  return value ? value.ios : '';
};

const AppDownLoadLink = props => {
  const data = props.appDownload;
  return (
    <a
      className="appDownloadLink"
      href={getMobileAppiOSLink()}
      target="_blank"
      rel="noreferrer noopener"
    >
      <div className="app-download">
        <span>
          <img src={appImage} alt={data.label} alr={data.label} />
        </span>
        <span className="lebel">{data.label}</span>
      </div>
    </a>
  );
};
AppDownLoadLink.defaultProps = {
  appDownload: {
    link: '#',
    label: 'Download our App for iOS',
  },
};
export default AppDownLoadLink;
