import React from 'react';
import classNames from 'classnames';
import minusIcon from 'assets/images/icons-minus.svg';
import plusIcon from 'assets/images/icons-plus.svg';

const IncircleConciergeAccordionItem = ({
  id,
  title,
  isOpen,
  toggle,
  children,
}) => (
  <li className="incircle-concierge-accordion__item">
    <div
      className={classNames('incircle-concierge-accordion__item__title', {
        'incircle-concierge-accordion__item__title--is-closed': !isOpen,
      })}
    >
      <h3 className="incircle-concierge-accordion__item__title__content">
        {title}
      </h3>
      <div
        className="incircle-concierge-accordion__item__title__icon"
        onClick={() => toggle(id)}
        onKeyDown={() => toggle(id)}
      >
        {isOpen ? (
          <img src={minusIcon} alt="Close" alr="Close" />
        ) : (
          <img src={plusIcon} alt="Open" alr="Open" />
        )}
      </div>
    </div>
    {isOpen && (
      <div className="incircle-concierge-accordion__item__content">
        {children}
      </div>
    )}
  </li>
);

export default IncircleConciergeAccordionItem;
