import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { isMobile } from 'client-utils/utilities-page';
import './customDropDownComponent.scss';

class CustomDropDownComponent extends Component {
  constructor() {
    super();
    this.state = {
      dropdownOpen: false,
      triggerAnalytics: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  onKeyPressed(e, optionVal) {
    if (e.key === 'Enter') {
      this.handleChange(optionVal);
    }
  }

  handleChange(index) {
    if (this.props.name === 'colorDropdown' && !this.state.triggerAnalytics) {
      this.props.handleAnalytics();
      this.setState({ triggerAnalytics: true });
    }
    this.props.onClick(index, this.props.label);
    this.toggleDropdownMenu();
  }

  handleChangeDefault(event) {
    this.props.onClick(event.target.value, this.props.label);
    this.closeDropDown();
  }

  handleClick = e => {
    if (this.node.contains(e.target)) {
      return;
    }
    this.closeDropDown();
  };

  closeDropDown = () => {
    this.setState({ dropdownOpen: false });
  };

  // Unused method in this component. So commenting now
  // toCamelCase(str) {
  //   return str.replace(/\w\S*/g, txt => {
  //     return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  //   });
  // }

  toggleDropdownMenu = () => {
    if (this.props.disableCustomDropDown && isMobile()) {
      this.setState({ dropdownOpen: true });
    } else {
      this.setState({ dropdownOpen: !this.state.dropdownOpen });
    }
  };

  render() {
    const { options, optionSettings } = this.props;
    let selectedKey = this.props.selectedValue // eslint-disable-line no-nested-ternary
      ? this.props.selectedValue
      : this.props.selectedIndex > -1 && optionSettings && optionSettings.label
      ? getValue(
          this.props.disableCustomDropDown
            ? optionSettings.value
            : optionSettings.label,
          options[this.props.selectedIndex],
          this.props.selectedIndex,
        )
      : this.props.selectedIndex;

    if (options.length === 0) return false;

    selectedKey =
      selectedKey < 0 && optionSettings && optionSettings.selectLabel
        ? optionSettings.selectLabel
        : selectedKey;

    let defaultOption = null;
    if (
      optionSettings &&
      optionSettings.selectLabel &&
      optionSettings.selectLabel === selectedKey
    ) {
      const defaultSelectLabel = optionSettings.selectLabel;
      defaultOption =
        options.length > 0 ? ( // eslint-disable-line no-nested-ternary
          this.props.disableCustomDropDown ? (
            <option key="default" value={defaultSelectLabel}>
              {defaultSelectLabel}
            </option>
          ) : null
        ) : null;
    }

    function getValue(value, data, defaultValue) {
      let optionValue = defaultValue;
      switch (value) {
        case 'index_index':
          optionValue = defaultValue;
          break;

        case 'index_value':
          optionValue = typeof data !== 'object' ? data : defaultValue;
          break;

        default:
          optionValue =
            data && data[value] && typeof data[value] !== 'object'
              ? data[value]
              : defaultValue;
          break;
      }
      return optionValue;
    }

    const optionList = options.map((optionValue, index) => {
      const label = optionSettings && optionSettings.label;
      const optionLabel = label ? getValue(label, optionValue, index) : index;

      const value = optionSettings && optionSettings.value;
      const optionVal = value ? getValue(value, optionValue, index) : index;
      const { mouseOver, mouseOut } = this.props;

      return this.props.disableCustomDropDown ? (
        <option
          className={classnames({ 'disabled-select': optionValue.inactive })}
          disabled={optionValue.inactive}
          value={optionVal}
          key={index}
        >
          {optionLabel}{' '}
          {optionValue.inactive && this.props.disableText
            ? this.props.disableText
            : ''}
        </option>
      ) : (
        <li
          className={classnames({ 'disabled-select': optionValue.inactive })}
          onClick={() => this.handleChange(optionVal)}
          onKeyPress={e => this.onKeyPressed(e, optionVal)}
          onMouseOver={mouseOver}
          onMouseOut={mouseOut}
          tabIndex="0"
          aria-label={`${this.props.label}-${optionLabel}`}
          key={optionVal}
          data-index={index}
        >
          {optionLabel}{' '}
          {optionValue.inactive && this.props.disableText
            ? this.props.disableText
            : ''}
        </li>
      );
    });

    return (
      <div className="product-options-optimized">
        <div
          className="select-wrapper"
          ref={node => {
            this.node = node;

            return this.node;
          }}
        >
          {this.props.disableCustomDropDown ? (
            <select
              value={`${selectedKey}`}
              onChange={e => this.handleChangeDefault(e)}
              onClick={this.toggleDropdownMenu}
              className={classnames({
                'default-select-active-arrow': this.state.dropdownOpen,
              })}
            >
              {defaultOption}
              {optionList}
            </select>
          ) : (
            <Fragment>
              <div
                className={classnames('select-selected', {
                  'select-active-arrow': this.state.dropdownOpen,
                })}
                onClick={this.toggleDropdownMenu}
                onKeyDown={this.toggleDropdownMenu}
                tabIndex="0"
                aria-label={`${this.props.label}-drop-down`}
              >
                {`${selectedKey}`}
              </div>
              {this.state.dropdownOpen && (
                <ul
                  value={`${selectedKey}`}
                  className="select-lists"
                  onClick={this.toggleDropdownMenu}
                >
                  {defaultOption}
                  {optionList}
                </ul>
              )}
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default CustomDropDownComponent;
