import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import CloseIcon from '@bgo-ui/common/client/common/components/CloseIcon/closeIcon';
import { closeModal } from '@bgo-ui/common/client/common/components/Modal/actions';
import './ChangeEmailModal.scss';
import ChangeEmailForm from './ChangeEmailForm/ChangeEmailForm';
import ChangeEmailConfirmation from './ChangeEmailConfirmation/ChangeEmailConfirmation';
import ChangeEmailSuccess from './ChangeEmailSuccess/ChangeEmailSuccess';
import { redirectToLoginPage } from '../../../helpers';

const DumbChangeEmailModal = props => {
  let modalContent;
  if (props.updateBasicAttributesSuccess) {
    modalContent = <ChangeEmailSuccess />;
  } else {
    modalContent = props.verificationCodeSent ? (
      <ChangeEmailForm />
    ) : (
      <ChangeEmailConfirmation />
    );
  }

  const handleCloseClick = e => {
    if (props.updateBasicAttributesSuccess) {
      redirectToLoginPage();
    } else {
      props.closeModal();
    }

    e.preventDefault();
  };

  return (
    <div className="change-email-modal">
      <CloseIcon onClick={handleCloseClick} />
      <div className="change-email-modal__content-wrapper">
        <div className="change-email-modal__content">
          <h1 className="change-email-modal__header">Change Email Address</h1>
          {modalContent}
        </div>
      </div>
    </div>
  );
};

DumbChangeEmailModal.propTypes = {
  verificationCodeSent: PropTypes.bool.isRequired,
  updateBasicAttributesSuccess: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  verificationCodeSent: get(
    state,
    'myaccount.myAccountDetails.verificationCode.success',
    false,
  ),
  updateBasicAttributesSuccess: get(
    state,
    'myaccount.myAccountDetails.updateBasicAttributesSuccess',
    false,
  ),
});

const mapDispatchToProps = {
  closeModal,
};

const ChangeEmailModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbChangeEmailModal);

export default ChangeEmailModal;
