import React, { Component } from 'react';
import { connect } from 'react-redux';
import { shouldLoad } from 'universal/http-client';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import {
  getECMRequest,
  getECMRequestWithPersonalization,
  getECMRequestWithCPPersonalization,
} from '../../actions/actions-ecmcontent';
import EcmSimple from '../EcmSimple';
import EcmComplex from '../EcmComplex';
import ECMRequestSlots from '../../constants';
import EcmFallback from '../EcmFallback/ecmFallback';

import './styles.scss';

class EcmGet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fallback: false,
    };
  }

  UNSAFE_componentWillMount() {
    const {
      api,
      dispatch,
      ecmSlot,
      catId = '',
      coherentPathPersonalization,
      ecmPreview,
      productId = '',
    } = this.props;

    if (
      ecmSlot.personalize &&
      coherentPathPersonalization &&
      isEmpty(ecmPreview) &&
      shouldLoad(api[`ecm${ecmSlot.contentId.toLowerCase()}`])
    ) {
      dispatch(getECMRequestWithCPPersonalization(ecmSlot, catId));
      return;
    }
    if (
      ecmSlot.personalize &&
      isEmpty(ecmPreview) &&
      shouldLoad(api[`ecm${ecmSlot.contentId.toLowerCase()}`])
    ) {
      dispatch(getECMRequestWithPersonalization(ecmSlot, catId));
      return;
    }
    if (shouldLoad(api[`ecm${ecmSlot.contentId.toLowerCase()}`])) {
      dispatch(getECMRequest(ecmSlot, catId, '', productId));
    }
  }

  componentDidMount() {
    const { ecmContent, ecmSlot } = this.props;
    if (isEmpty(ecmContent[ecmSlot.contentId]) && ecmSlot.fallback.length > 0) {
      this.setState({ fallback: true });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const apiProp = `ecm${this.props.ecmSlot.contentId.toLowerCase()}`;
    const api = this.props.api[apiProp];

    return (
      this.state.fallback !== nextState.fallback ||
      !isEqual(api, nextProps.api[apiProp])
    );
  }

  renderGet() {
    const {
      ecmContent,
      ecmSlot,
      ecmCallToActionButtonWithTextToggle,
      improvePerformanceOfStyleToggle,
      removeEcmVideoHeightToggle,
      device,
    } = this.props;

    switch (ecmContent[ecmSlot.contentId].layoutType) {
      case 'simple':
        return (
          <EcmSimple
            ecmContent={ecmContent[ecmSlot.contentId]}
            ecmSlot={ecmSlot}
            improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
          />
        );
      case 'complex':
        return (
          <EcmComplex
            ecmCallToActionButtonWithTextToggle={
              ecmCallToActionButtonWithTextToggle
            }
            ecmContent={ecmContent[ecmSlot.contentId]}
            ecmSlot={ecmSlot}
            improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
            removeEcmVideoHeightToggle={removeEcmVideoHeightToggle}
            isMobile={device.isMobile}
            isMobilePhone={device.isMobilePhone}
          />
        );
      default:
        return <div />;
    }
  }

  render() {
    const { ecmContent, ecmSlot, defaultValue } = this.props;
    const containerId = `ecm-container-${ecmSlot.contentId}`;

    if (this.state.fallback) {
      return (
        <div className="ecm-fallback">
          <EcmFallback fallback={ecmSlot.fallback} />
        </div>
      );
    }

    if (
      ecmContent[ecmSlot.contentId] &&
      ecmContent[ecmSlot.contentId].layoutType
    ) {
      return (
        <div className="ecm-container" id={containerId}>
          {this.renderGet()}
        </div>
      );
    } else if (defaultValue) {
      return <div>{defaultValue}</div>;
    } else {
      return false;
    }
  }
}

const mapStateToProps = state => ({
  api: state.api,
  ecmContent: state.ecm.ecmContent,
  ecmCallToActionButtonWithTextToggle:
    state.toggles.ECM_CALL_TO_ACTION_BUTTONS_WITH_TEXT,
  coherentPathPersonalization:
    state.toggles.COHERENT_PATH_PERSONALIZATION &&
    state.locale.countryCode === 'US',
  ecmPreview: get(state.api, 'requestContext.ECMPreview', ''),
  improvePerformanceOfStyleToggle: get(
    state,
    'toggles.IMPROVE_PERFORMANCE_OF_STYLE',
    false,
  ),
  removeEcmVideoHeightToggle: get(
    state,
    'toggles.REMOVE_ECM_VIDEO_HEIGHT',
    false,
  ),
  device: state.device,
});

export default connect(mapStateToProps)(EcmGet);
export const ECMSlots = ECMRequestSlots;
