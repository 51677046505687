import get from 'lodash/get';
import getSelectedColorIndex from 'pdp/components/ProductPage/selectors/getSelectedColorIndex';
import getSelectedBuckleFinishIndex from 'pdp/components/ProductPage/selectors/getSelectedBuckleFinishIndex';
import { getColorOptions } from 'pdp/components/ProductPage/selectors/getOptions';
import { DISCONTINUED_CODES_YR, NON_DISCONTINUED_CODE } from 'pdp/constants';

export const ADD_TO_CART = 'ADD_TO_CART';
export const BOPS = 'BOPS';

export default (
  productState,
  selectedSku,
  vendorQtyRestrictions,
  type,
  customizationOptionTypePDP = false,
  isNewPDP = false,
  excludeBackOrderBOPSToggle = false,
) => {
  const { name = '', quantity = 0, departmentCode } = productState;
  const selectedColorIndex = getSelectedColorIndex(productState);
  const selectedBuckleFinishIndex = getSelectedBuckleFinishIndex(productState);
  const selectedSizeIndex = get(productState, 'options.selectedSizeIndex', -1);
  const selectedFrequencyIndex =
    productState.replenishInterval === undefined
      ? -1
      : productState.replenishInterval;
  const restrictedQty = get(vendorQtyRestrictions, departmentCode);
  const isNotDiscontinued = () =>
    selectedSku.discontinuedCode === NON_DISCONTINUED_CODE;
  const inStock = () =>
    selectedSku.inStock && selectedSku.stockLevel >= quantity;
  const onOrder = () => {
    return (
      (selectedSku.preOrder || selectedSku.backOrder) &&
      selectedSku.purchaseOrderQuantity >= quantity
    );
  };
  const isValidBackOrder = () => {
    return (
      selectedSku.sellable &&
      DISCONTINUED_CODES_YR.includes(selectedSku.discontinuedCode) &&
      productState.quantity <=
        selectedSku.stockLevel + selectedSku.purchaseOrderQuantity
    );
  };
  let colorName = '';
  if (!selectedSku) {
    if (selectedSizeIndex === -1) {
      const colorSkus = getColorOptions(productState);
      if (colorSkus && colorSkus.values.length > 0) {
        colorName = `, ${colorSkus.values[selectedColorIndex].name}`;
      }
      return `Please choose a size for the product ${name}${colorName}.`;
    }

    return `Please choose a color, size, and/or Monogram option for the product ${name}${colorName}.`;
  }
  if (customizationOptionTypePDP && selectedBuckleFinishIndex === -1) {
    return `Please choose a Buckle Finish for the product ${name}.`;
  }
  if (productState.replenishable && selectedFrequencyIndex === -1) {
    return `Please choose a frequency for the product ${name}.`;
  }
  if (quantity === 0) {
    return 'Please choose a quantity larger than 0.';
  }

  if (excludeBackOrderBOPSToggle && type === BOPS && selectedSku.backOrder) {
    return 'Items on back order are not available for in-store pickup.';
  }

  if (quantity > restrictedQty) {
    return `You may not purchase more than ${restrictedQty} of any one item from this brand at a time. Please update quantity to continue.`;
  }

  if (productState.perishable && !productState.deliveryDate) {
    return 'Please select a date to proceed';
  }

  if (type === BOPS && productState.isPersonalizationSelected) {
    return 'Personalized items are not available for in-store pickup.';
  }

  if (type !== ADD_TO_CART && type !== BOPS) return false;
  if (
    isNotDiscontinued() ||
    inStock() ||
    onOrder() ||
    selectedSku.dropShip ||
    isValidBackOrder()
  ) {
    return false;
  } else return `The quantity requested for ${name} is not available.`;
};
