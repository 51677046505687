export const countryCurrencyData =
  [
    {
      countryCode: 'AL',
      countryName: 'Albania',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-al'
    },
    {
      countryCode: 'DZ',
      countryName: 'Algeria',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-dz'
    },
    {
      countryCode: 'AD',
      countryName: 'Andorra',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-ad'
    },
    {
      countryCode: 'AO',
      countryName: 'Angola',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-ao'
    },
    {
      countryCode: 'AI',
      countryName: 'Anguilla',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-ai'
    },
    {
      countryCode: 'AG',
      countryName: 'Antigua and Barbuda',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-ag'
    },
    {
      countryCode: 'AM',
      countryName: 'Armenia',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-am'
    },
    {
      countryCode: 'AW',
      countryName: 'Aruba',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-aw'
    },
    {
      countryCode: 'AU',
      countryName: 'Australia',
      currencyCode: 'AUD',
      currencyName: 'Australian Dollar',
      url: '/en-au'
    },
    {
      countryCode: 'AT',
      countryName: 'Austria',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-at'
    },
    {
      countryCode: 'AZ',
      countryName: 'Azerbaijan',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-az'
    },
    {
      countryCode: 'BS',
      countryName: 'Bahamas',
      currencyCode: 'BSD',
      currencyName: 'Bahamian Dollar',
      url: '/en-bs'
    },
    {
      countryCode: 'BH',
      countryName: 'Bahrain',
      currencyCode: 'BHD',
      currencyName: 'Bahraini Dinar',
      url: '/en-bh'
    },
    {
      countryCode: 'BD',
      countryName: 'Bangladesh',
      currencyCode: 'BDT',
      currencyName: 'Taka',
      url: '/en-bd'
    },
    {
      countryCode: 'BB',
      countryName: 'Barbados',
      currencyCode: 'BBD',
      currencyName: 'Barbados Dollar',
      url: '/en-bb'
    },
    {
      countryCode: 'BY',
      countryName: 'Belarus',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-by'
    },
    {
      countryCode: 'BE',
      countryName: 'Belgium',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-be'
    },
    {
      countryCode: 'BZ',
      countryName: 'Belize',
      currencyCode: 'BZD',
      currencyName: 'Belize Dollar',
      url: '/en-bz'
    },
    {
      countryCode: 'BJ',
      countryName: 'Benin',
      currencyCode: 'XOF',
      currencyName: 'CFA Franc BCEAO',
      url: '/en-bj'
    },
    {
      countryCode: 'BM',
      countryName: 'Bermuda',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-bm'
    },
    {
      countryCode: 'BT',
      countryName: 'Bhutan',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-bt'
    },
    {
      countryCode: 'BO',
      countryName: 'Bolivia',
      currencyCode: 'BOB',
      currencyName: 'Boliviano',
      url: '/en-bo'
    },
    {
      countryCode: 'BQ',
      countryName: 'Bonaire, Sint Eustatius and Saba',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-bq'
    },
    {
      countryCode: 'BA',
      countryName: 'Bosnia and Herzegovina',
      currencyCode: 'BAM',
      currencyName: 'Bosnian Convertible Mark',
      url: '/en-ba'
    },
    {
      countryCode: 'BW',
      countryName: 'Botswana',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-bw'
    },
    {
      countryCode: 'BR',
      countryName: 'Brazil',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-br'
    },
    {
      countryCode: 'BN',
      countryName: 'Brunei Darussalam',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-bn'
    },
    {
      countryCode: 'BG',
      countryName: 'Bulgaria',
      currencyCode: 'BGN',
      currencyName: 'Bulgarian Lev',
      url: '/en-bg'
    },
    {
      countryCode: 'BF',
      countryName: 'Burkina Faso',
      currencyCode: 'XOF',
      currencyName: 'CFA Franc BCEAO',
      url: '/en-bf'
    },
    {
      countryCode: 'BI',
      countryName: 'Burundi',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-bi'
    },
    {
      countryCode: 'KH',
      countryName: 'Cambodia',
      currencyCode: 'KHR',
      currencyName: 'Riel',
      url: '/en-kh'
    },
    {
      countryCode: 'CM',
      countryName: 'Cameroon',
      currencyCode: 'XAF',
      currencyName: 'CFA Franc BEAC',
      url: '/en-cm'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      currencyCode: 'CAD',
      currencyName: 'Canadian Dollar',
      url: '/en-ca'
    },
    {
      countryCode: 'CV',
      countryName: 'Cape Verde',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-cv'
    },
    {
      countryCode: 'KY',
      countryName: 'Cayman Islands',
      currencyCode: 'KYD',
      currencyName: 'Cayman Islands Dollar',
      url: '/en-ky'
    },
    {
      countryCode: 'CF',
      countryName: 'Central African Republic',
      currencyCode: 'XAF',
      currencyName: 'CFA Franc BEAC',
      url: '/en-cf'
    },
    {
      countryCode: 'TD',
      countryName: 'Chad',
      currencyCode: 'XAF',
      currencyName: 'CFA Franc BEAC',
      url: '/en-td'
    },
    {
      countryCode: 'CL',
      countryName: 'Chile',
      currencyCode: 'CLP',
      currencyName: 'Chilean Peso',
      url: '/en-cl'
    },
    {
      countryCode: 'CN',
      countryName: 'China',
      currencyCode: 'CNY',
      currencyName: 'Yuan Renminbi',
      url: '/en-cn'
    },
    {
      countryCode: 'CX',
      countryName: 'Christmas Island',
      currencyCode: 'AUD',
      currencyName: 'Australian Dollar',
      url: '/en-cx'
    },
    {
      countryCode: 'CC',
      countryName: 'Cocos (Keeling) Islands',
      currencyCode: 'AUD',
      currencyName: 'Australian Dollar',
      url: '/en-cc'
    },
    {
      countryCode: 'CO',
      countryName: 'Colombia',
      currencyCode: 'COP',
      currencyName: 'Colombian Peso',
      url: '/en-co'
    },
    {
      countryCode: 'KM',
      countryName: 'Comoros',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-km'
    },
    {
      countryCode: 'CD',
      countryName: 'Congo, Democratic Republic of the',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-cd'
    },
    {
      countryCode: 'CG',
      countryName: 'Congo, Republic of the',
      currencyCode: 'XAF',
      currencyName: 'CFA Franc BEAC',
      url: '/en-cg'
    },
    {
      countryCode: 'CK',
      countryName: 'Cook Islands',
      currencyCode: 'NZD',
      currencyName: 'New Zealand Dollar',
      url: '/en-ck'
    },
    {
      countryCode: 'CR',
      countryName: 'Costa Rica',
      currencyCode: 'CRC',
      currencyName: 'Costa Rican Colon',
      url: '/en-cr'
    },
    {
      countryCode: 'CI',
      countryName: 'Côte d’Ivoire',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-ci'
    },
    {
      countryCode: 'HR',
      countryName: 'Croatia',
      currencyCode: 'HRK',
      currencyName: 'Croatian Kuna',
      url: '/en-hr'
    },
    {
      countryCode: 'CW',
      countryName: 'Curaçao',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-cw'
    },
    {
      countryCode: 'CY',
      countryName: 'Cyprus',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-cy'
    },
    {
      countryCode: 'CZ',
      countryName: 'Czech Republic',
      currencyCode: 'CZK',
      currencyName: 'Czech Koruna',
      url: '/en-cz'
    },
    {
      countryCode: 'DK',
      countryName: 'Denmark',
      currencyCode: 'DKK',
      currencyName: 'Danish Krone',
      url: '/en-dk'
    },
    {
      countryCode: 'DJ',
      countryName: 'Djibouti',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-dj'
    },
    {
      countryCode: 'DM',
      countryName: 'Dominica',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-dm'
    },
    {
      countryCode: 'DO',
      countryName: 'Dominican Republic',
      currencyCode: 'DOP',
      currencyName: 'Dominican Peso',
      url: '/en-do'
    },
    {
      countryCode: 'EC',
      countryName: 'Ecuador',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-ec'
    },
    {
      countryCode: 'EG',
      countryName: 'Egypt',
      currencyCode: 'EGP',
      currencyName: 'Egyptian Pound',
      url: '/en-eg'
    },
    {
      countryCode: 'SV',
      countryName: 'El Salvador',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-sv'
    },
    {
      countryCode: 'GQ',
      countryName: 'Equatorial Guinea',
      currencyCode: 'XAF',
      currencyName: 'CFA Franc BEAC',
      url: '/en-gq'
    },
    {
      countryCode: 'ER',
      countryName: 'Eritrea',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-er'
    },
    {
      countryCode: 'EE',
      countryName: 'Estonia',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-ee'
    },
    {
      countryCode: 'ET',
      countryName: 'Ethiopia',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-et'
    },
    {
      countryCode: 'FK',
      countryName: 'Falkland Islands (Malvinas)',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-fk'
    },
    {
      countryCode: 'FO',
      countryName: 'Faroe Islands',
      currencyCode: 'DKK',
      currencyName: 'Danish Krone',
      url: '/en-fo'
    },
    {
      countryCode: 'FJ',
      countryName: 'Fiji',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-fj'
    },
    {
      countryCode: 'FI',
      countryName: 'Finland',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-fi'
    },
    {
      countryCode: 'FR',
      countryName: 'France',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-fr'
    },
    {
      countryCode: 'GF',
      countryName: 'French Guiana',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-gf'
    },
    {
      countryCode: 'PF',
      countryName: 'French Polynesia',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-pf'
    },
    {
      countryCode: 'GA',
      countryName: 'Gabon',
      currencyCode: 'XAF',
      currencyName: 'CFA Franc BEAC',
      url: '/en-ga'
    },
    {
      countryCode: 'GM',
      countryName: 'Gambia',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-gm'
    },
    {
      countryCode: 'GE',
      countryName: 'Georgia',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-ge'
    },
    {
      countryCode: 'DE',
      countryName: 'Germany',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-de'
    },
    {
      countryCode: 'GH',
      countryName: 'Ghana',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-gh'
    },
    {
      countryCode: 'GI',
      countryName: 'Gibraltar',
      currencyCode: 'GBP',
      currencyName: 'British Pound',
      url: '/en-gi'
    },
    {
      countryCode: 'GR',
      countryName: 'Greece',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-gr'
    },
    {
      countryCode: 'GL',
      countryName: 'Greenland',
      currencyCode: 'DKK',
      currencyName: 'Danish Krone',
      url: '/en-gl'
    },
    {
      countryCode: 'GD',
      countryName: 'Grenada',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-gd'
    },
    {
      countryCode: 'GP',
      countryName: 'Guadeloupe',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-gp'
    },
    {
      countryCode: 'GT',
      countryName: 'Guatemala',
      currencyCode: 'GTQ',
      currencyName: 'Quetzal',
      url: '/en-gt'
    },
    {
      countryCode: 'GG',
      countryName: 'Guernsey',
      currencyCode: 'GBP',
      currencyName: 'British Pound',
      url: '/en-gg'
    },
    {
      countryCode: 'GN',
      countryName: 'Guinea',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-gn'
    },
    {
      countryCode: 'GW',
      countryName: 'Guinea-Bissau',
      currencyCode: 'XOF',
      currencyName: 'CFA Franc BCEAO',
      url: '/en-gw'
    },
    {
      countryCode: 'GY',
      countryName: 'Guyana',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-gy'
    },
    {
      countryCode: 'HT',
      countryName: 'Haiti',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-ht'
    },
    {
      countryCode: 'VA',
      countryName: 'Holy See (Vatican City State)',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-va'
    },
    {
      countryCode: 'HN',
      countryName: 'Honduras',
      currencyCode: 'HNL',
      currencyName: 'Lempira',
      url: '/en-hn'
    },
    {
      countryCode: 'HK',
      countryName: 'Hong Kong',
      currencyCode: 'HKD',
      currencyName: 'Hong Kong Dollar',
      url: '/en-hk'
    },
    {
      countryCode: 'HU',
      countryName: 'Hungary',
      currencyCode: 'HUF',
      currencyName: 'Hungarian Forint',
      url: '/en-hu'
    },
    {
      countryCode: 'IS',
      countryName: 'Iceland',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-is'
    },
    {
      countryCode: 'IN',
      countryName: 'India',
      currencyCode: 'INR',
      currencyName: 'Indian Rupee',
      url: '/en-in'
    },
    {
      countryCode: 'ID',
      countryName: 'Indonesia',
      currencyCode: 'IDR',
      currencyName: 'Indonesian Rupiah',
      url: '/en-id'
    },
    {
      countryCode: 'IE',
      countryName: 'Ireland',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-ie'
    },
    {
      countryCode: 'IM',
      countryName: 'Isle of Man',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-im'
    },
    {
      countryCode: 'IL',
      countryName: 'Israel',
      currencyCode: 'ILS',
      currencyName: 'Israeli Shekel',
      url: '/en-il'
    },
    {
      countryCode: 'IT',
      countryName: 'Italy',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-it'
    },
    {
      countryCode: 'JM',
      countryName: 'Jamaica',
      currencyCode: 'JMD',
      currencyName: 'Jamaican Dollar',
      url: '/en-jm'
    },
    {
      countryCode: 'JP',
      countryName: 'Japan',
      currencyCode: 'JPY',
      currencyName: 'Japanese Yen',
      url: '/en-jp'
    },
    {
      countryCode: 'JE',
      countryName: 'Jersey',
      currencyCode: 'GBP',
      currencyName: 'British Pound',
      url: '/en-je'
    },
    {
      countryCode: 'JO',
      countryName: 'Jordan',
      currencyCode: 'JOD',
      currencyName: 'Jordanian Dinar',
      url: '/en-jo'
    },
    {
      countryCode: 'KZ',
      countryName: 'Kazakhstan',
      currencyCode: 'KZT',
      currencyName: 'Tenge',
      url: '/en-kz'
    },
    {
      countryCode: 'KE',
      countryName: 'Kenya',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-ke'
    },
    {
      countryCode: 'KI',
      countryName: 'Kiribati',
      currencyCode: 'AUD',
      currencyName: 'Australian Dollar',
      url: '/en-ki'
    },
    {
      countryCode: 'KR',
      countryName: 'Korea, Republic of',
      currencyCode: 'KRW',
      currencyName: 'Korean Won',
      url: '/en-kr'
    },
    {
      countryCode: 'KW',
      countryName: 'Kuwait',
      currencyCode: 'KWD',
      currencyName: 'Kuwaiti Dinar',
      url: '/en-kw'
    },
    {
      countryCode: 'KG',
      countryName: 'Kyrgyzstan',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-kg'
    },
    {
      countryCode: 'LA',
      countryName: "Lao People's Democratic Republic",
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-la'
    },
    {
      countryCode: 'LV',
      countryName: 'Latvia',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-lv'
    },
    {
      countryCode: 'LB',
      countryName: 'Lebanon',
      currencyCode: 'LBP',
      currencyName: 'Lebanese Pound',
      url: '/en-lb'
    },
    {
      countryCode: 'LS',
      countryName: 'Lesotho',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-ls'
    },
    {
      countryCode: 'LR',
      countryName: 'Liberia',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-lr'
    },
    {
      countryCode: 'LI',
      countryName: 'Liechtenstein',
      currencyCode: 'CHF',
      currencyName: 'Swiss Franc',
      url: '/en-li'
    },
    {
      countryCode: 'LT',
      countryName: 'Lithuania',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-lt'
    },
    {
      countryCode: 'LU',
      countryName: 'Luxembourg',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-lu'
    },
    {
      countryCode: 'MO',
      countryName: 'Macao',
      currencyCode: 'HKD',
      currencyName: 'Hong Kong Dollar',
      url: '/en-mo'
    },
    {
      countryCode: 'MK',
      countryName: 'Macedonia, the former Yugoslav Republic of',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-mk'
    },
    {
      countryCode: 'MG',
      countryName: 'Madagascar',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-mg'
    },
    {
      countryCode: 'MW',
      countryName: 'Malawi',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-mw'
    },
    {
      countryCode: 'MY',
      countryName: 'Malaysia',
      currencyCode: 'MYR',
      currencyName: 'Malayan Ringgit',
      url: '/en-my'
    },
    {
      countryCode: 'MV',
      countryName: 'Maldives',
      currencyCode: 'MVR',
      currencyName: 'Rufiyaa',
      url: '/en-mv'
    },
    {
      countryCode: 'ML',
      countryName: 'Mali',
      currencyCode: 'XOF',
      currencyName: 'CFA Franc BCEAO',
      url: '/en-ml'
    },
    {
      countryCode: 'MT',
      countryName: 'Malta',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-mt'
    },
    {
      countryCode: 'MH',
      countryName: 'Marshall Islands',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-mh'
    },
    {
      countryCode: 'MQ',
      countryName: 'Martinique',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-mq'
    },
    {
      countryCode: 'MR',
      countryName: 'Mauritania',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-mr'
    },
    {
      countryCode: 'MU',
      countryName: 'Mauritius',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-mu'
    },
    {
      countryCode: 'YT',
      countryName: 'Mayotte',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-yt'
    },
    {
      countryCode: 'MX',
      countryName: 'Mexico',
      currencyCode: 'MXN',
      currencyName: 'Mexican Peso',
      url: '/en-mx'
    },
    {
      countryCode: 'FM',
      countryName: 'Micronesia, Federated States of',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-fm'
    },
    {
      countryCode: 'MD',
      countryName: 'Moldova, Republic of',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-md'
    },
    {
      countryCode: 'MC',
      countryName: 'Monaco',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-mc'
    },
    {
      countryCode: 'MN',
      countryName: 'Mongolia',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-mn'
    },
    {
      countryCode: 'ME',
      countryName: 'Montenegro',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-me'
    },
    {
      countryCode: 'MS',
      countryName: 'Montserrat',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-ms'
    },
    {
      countryCode: 'MA',
      countryName: 'Morocco',
      currencyCode: 'MAD',
      currencyName: 'Moroccan Dirham',
      url: '/en-ma'
    },
    {
      countryCode: 'MZ',
      countryName: 'Mozambique',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-mz'
    },
    {
      countryCode: 'MM',
      countryName: 'Myanmar',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-mm'
    },
    {
      countryCode: 'NA',
      countryName: 'Namibia',
      currencyCode: 'NAD',
      currencyName: 'Namibia Dollar',
      url: '/en-na'
    },
    {
      countryCode: 'NR',
      countryName: 'Nauru',
      currencyCode: 'AUD',
      currencyName: 'Australian Dollar',
      url: '/en-nr'
    },
    {
      countryCode: 'NP',
      countryName: 'Nepal',
      currencyCode: 'NPR',
      currencyName: 'Nepalese Rupee',
      url: '/en-np'
    },
    {
      countryCode: 'NL',
      countryName: 'Netherlands',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-nl'
    },
    {
      countryCode: 'NC',
      countryName: 'New Caledonia',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-nc'
    },
    {
      countryCode: 'NZ',
      countryName: 'New Zealand',
      currencyCode: 'NZD',
      currencyName: 'New Zealand Dollar',
      url: '/en-nz'
    },
    {
      countryCode: 'NI',
      countryName: 'Nicaragua',
      currencyCode: 'NIO',
      currencyName: 'Cordoba Oro',
      url: '/en-ni'
    },
    {
      countryCode: 'NE',
      countryName: 'Niger',
      currencyCode: 'XOF',
      currencyName: 'CFA Franc BCEAO',
      url: '/en-ne'
    },
    {
      countryCode: 'NG',
      countryName: 'Nigeria',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-ng'
    },
    {
      countryCode: 'NU',
      countryName: 'Niue',
      currencyCode: 'NZD',
      currencyName: 'New Zealand Dollar',
      url: '/en-nu'
    },
    {
      countryCode: 'NO',
      countryName: 'Norway',
      currencyCode: 'NOK',
      currencyName: 'Norwegian Krone',
      url: '/en-no'
    },
    {
      countryCode: 'OM',
      countryName: 'Oman',
      currencyCode: 'OMR',
      currencyName: 'Omani Rial',
      url: '/en-om'
    },
    {
      countryCode: 'PK',
      countryName: 'Pakistan',
      currencyCode: 'PKR',
      currencyName: 'Pakistan Rupee',
      url: '/en-pk'
    },
    {
      countryCode: 'PW',
      countryName: 'Palau',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-pw'
    },
    {
      countryCode: 'PA',
      countryName: 'Panama',
      currencyCode: 'PAB',
      currencyName: 'Balboa',
      url: '/en-pa'
    },
    {
      countryCode: 'PG',
      countryName: 'Papua New Guinea',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-pg'
    },
    {
      countryCode: 'PY',
      countryName: 'Paraguay',
      currencyCode: 'PYG',
      currencyName: 'Guarani',
      url: '/en-py'
    },
    {
      countryCode: 'PE',
      countryName: 'Peru',
      currencyCode: 'PEN',
      currencyName: 'Peruvian Nuevo Sol',
      url: '/en-pe'
    },
    {
      countryCode: 'PH',
      countryName: 'Philippines',
      currencyCode: 'PHP',
      currencyName: 'Philippine Peso',
      url: '/en-ph'
    },
    {
      countryCode: 'PL',
      countryName: 'Poland',
      currencyCode: 'PLN',
      currencyName: 'Polish Zloty',
      url: '/en-pl'
    },
    {
      countryCode: 'PT',
      countryName: 'Portugal',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-pt'
    },
    {
      countryCode: 'QA',
      countryName: 'Qatar',
      currencyCode: 'QAR',
      currencyName: 'Qatari Riyal',
      url: '/en-qa'
    },
    {
      countryCode: 'RE',
      countryName: 'Réunion',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-re'
    },
    {
      countryCode: 'RO',
      countryName: 'Romania',
      currencyCode: 'RON',
      currencyName: 'New Romanian Leu',
      url: '/en-ro'
    },
    {
      countryCode: 'RU',
      countryName: 'Russian Federation',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-ru'
    },
    {
      countryCode: 'RW',
      countryName: 'Rwanda',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-rw'
    },
    {
      countryCode: 'BL',
      countryName: 'Saint Barthélemy',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-bl'
    },
    {
      countryCode: 'KN',
      countryName: 'Saint Kitts and Nevis',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-kn'
    },
    {
      countryCode: 'LC',
      countryName: 'Saint Lucia',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-lc'
    },
    {
      countryCode: 'PM',
      countryName: 'Saint Pierre and Miquelon',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-pm'
    },
    {
      countryCode: 'VC',
      countryName: 'Saint Vincent and the Grenadines',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-vc'
    },
    {
      countryCode: 'WS',
      countryName: 'Samoa',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-ws'
    },
    {
      countryCode: 'SM',
      countryName: 'San Marino',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-sm'
    },
    {
      countryCode: 'ST',
      countryName: 'Sao Tome and Principe',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-st'
    },
    {
      countryCode: 'SA',
      countryName: 'Saudi Arabia',
      currencyCode: 'SAR',
      currencyName: 'Saudi Riyal',
      url: '/en-sa'
    },
    {
      countryCode: 'SN',
      countryName: 'Senegal',
      currencyCode: 'XOF',
      currencyName: 'CFA Franc BCEAO',
      url: '/en-sn'
    },
    {
      countryCode: 'RS',
      countryName: 'Serbia',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-rs'
    },
    {
      countryCode: 'SC',
      countryName: 'Seychelles',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-sc'
    },
    {
      countryCode: 'SL',
      countryName: 'Sierra Leone',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-sl'
    },
    {
      countryCode: 'SG',
      countryName: 'Singapore',
      currencyCode: 'SGD',
      currencyName: 'Singapore Dollar',
      url: '/en-sg'
    },
    {
      countryCode: 'SX',
      countryName: 'Sint Maarten (Dutch part)',
      currencyCode: 'ANG',
      currencyName: 'Netherlands Antillan Guilder',
      url: '/en-sx'
    },
    {
      countryCode: 'SK',
      countryName: 'Slovakia',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-sk'
    },
    {
      countryCode: 'SI',
      countryName: 'Slovenia',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-si'
    },
    {
      countryCode: 'SB',
      countryName: 'Solomon Islands',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-sb'
    },
    {
      countryCode: 'SO',
      countryName: 'Somalia',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-so'
    },
    {
      countryCode: 'ZA',
      countryName: 'South Africa',
      currencyCode: 'ZAR',
      currencyName: 'South African Rand',
      url: '/en-za'
    },
    {
      countryCode: 'ES',
      countryName: 'Spain',
      currencyCode: 'EUR',
      currencyName: 'Euro',
      url: '/en-es'
    },
    {
      countryCode: 'LK',
      countryName: 'Sri Lanka',
      currencyCode: 'LKR',
      currencyName: 'Sri Lanka Rupee',
      url: '/en-lk'
    },
    {
      countryCode: 'SR',
      countryName: 'Suriname',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-sr'
    },
    {
      countryCode: 'SZ',
      countryName: 'Swaziland',
      currencyCode: 'SZL',
      currencyName: 'Lilangeni',
      url: '/en-sz'
    },
    {
      countryCode: 'SE',
      countryName: 'Sweden',
      currencyCode: 'SEK',
      currencyName: 'Swedish Krona',
      url: '/en-se'
    },
    {
      countryCode: 'CH',
      countryName: 'Switzerland',
      currencyCode: 'CHF',
      currencyName: 'Swiss Franc',
      url: '/en-ch'
    },
    {
      countryCode: 'TW',
      countryName: 'Taiwan',
      currencyCode: 'TWD',
      currencyName: 'Taiwan Dollar',
      url: '/en-tw'
    },
    {
      countryCode: 'TJ',
      countryName: 'Tajikistan',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-tj'
    },
    {
      countryCode: 'TZ',
      countryName: 'Tanzania, United Republic of',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-tz'
    },
    {
      countryCode: 'TH',
      countryName: 'Thailand',
      currencyCode: 'THB',
      currencyName: 'Thai Baht',
      url: '/en-th'
    },
    {
      countryCode: 'TL',
      countryName: 'Timor-Leste',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-tl'
    },
    {
      countryCode: 'TG',
      countryName: 'Togo',
      currencyCode: 'XOF',
      currencyName: 'CFA Franc BCEAO',
      url: '/en-tg'
    },
    {
      countryCode: 'TO',
      countryName: 'Tonga',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-to'
    },
    {
      countryCode: 'TT',
      countryName: 'Trinidad and Tobago',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-tt'
    },
    {
      countryCode: 'TN',
      countryName: 'Tunisia',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-tn'
    },
    {
      countryCode: 'TR',
      countryName: 'Turkey',
      currencyCode: 'TRY',
      currencyName: 'Turkish Lira',
      url: '/en-tr'
    },
    {
      countryCode: 'TC',
      countryName: 'Turks and Caicos Islands',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-tc'
    },
    {
      countryCode: 'TV',
      countryName: 'Tuvalu',
      currencyCode: 'AUD',
      currencyName: 'Australian Dollar',
      url: '/en-tv'
    },
    {
      countryCode: 'UG',
      countryName: 'Uganda',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-ug'
    },
    {
      countryCode: 'UA',
      countryName: 'Ukraine',
      currencyCode: 'UAH',
      currencyName: 'Ukraine Hryvnia',
      url: '/en-ua'
    },
    {
      countryCode: 'AE',
      countryName: 'United Arab Emirates',
      currencyCode: 'AED',
      currencyName: 'United Arab Emirates Dirham',
      url: '/en-ae'
    },
    {
      countryCode: 'GB',
      countryName: 'United Kingdom',
      currencyCode: 'GBP',
      currencyName: 'British Pound',
      url: '/en-gb'
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-us'
    },
    {
      countryCode: 'UY',
      countryName: 'Uruguay',
      currencyCode: 'UYU',
      currencyName: 'Uruguay New Peso',
      url: '/en-uy'
    },
    {
      countryCode: 'UZ',
      countryName: 'Uzbekistan',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-uz'
    },
    {
      countryCode: 'VU',
      countryName: 'Vanuatu',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-vu'
    },
    {
      countryCode: 'VN',
      countryName: 'Vietnam',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-vn'
    },
    {
      countryCode: 'VG',
      countryName: 'Virgin Islands, British',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-vg'
    },
    {
      countryCode: 'WF',
      countryName: 'Wallis and Futuna',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-wf'
    },
    {
      countryCode: 'YE',
      countryName: 'Yemen',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-ye'
    },
    {
      countryCode: 'ZM',
      countryName: 'Zambia',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-zm'
    },
    {
      countryCode: 'ZW',
      countryName: 'Zimbabwe',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-zw'
    }
  ];
