import {
  RESOLVED_PRODUCT,
  REJECTED_PRODUCT,
  RESOLVED_GROUP,
} from 'pdp/components/ProductPage/actions';
import {
  RESOLVED_QUICK_LOOK_PRODUCT,
  RESET_QUICK_LOOK_PRODUCT,
} from 'plp/ProductListPage/actions';
import utagData from './selector/getUtagData';
import groupUtagData from './selector/getGroupUtagData';
import productAnalytics from './selector/getProductAnalytics';

export default (state = {}, action) => {
  switch (action.type) {
    case RESOLVED_PRODUCT: {
      const payload = action.payload;
      return {
        productInfo: utagData(payload),
        productAnalytics: productAnalytics(payload),
      };
    }
    case RESOLVED_GROUP: {
      const payload = action.payload;
      return {
        productInfo: groupUtagData(payload),
        productAnalytics: productAnalytics(payload),
      };
    }
    case RESOLVED_QUICK_LOOK_PRODUCT: {
      return {
        productAnalytics: productAnalytics(action.payload),
      };
    }
    case RESET_QUICK_LOOK_PRODUCT: {
      return {};
    }
    case REJECTED_PRODUCT:
      return {};
    default:
      return state;
  }
};
