import httpWithLogging from 'universal/http-client';
import reactCookie from 'react-cookie';
import { setGuestTokens } from '.';

const sendGuestId = (id, accessToken, state) => {
  const requestApi = httpWithLogging(state, 8000);
  const disableATGToggle = state?.toggles?.DISABLE_ATG_CALLS || false;
  const headers = {
    authorization: accessToken,
  };
  const body = {
    id,
  };
  return !disableATGToggle
    ? requestApi
        .post(NMConfig.API_SYNC_GUEST_IDENTITY, body, { headers })
        .then(() => {
          return Promise.resolve('Token Synced!');
        })
        .catch(e => e)
    : Promise.resolve();
};

export const createGuestId = state => {
  const requestApi = httpWithLogging(state, 8000);
  return requestApi
    .get(NMConfig.API_GUEST_IDENTITY)
    .then(response => {
      const { Sub, AccessToken } = response.data.AuthenticationResult;
      const getCustomerID = data => {
        if (data && data.logged_in_status === 'true') {
          return data.web_id;
        } else {
          return Sub;
        }
      };

      const userSession = state?.utag?.userSession || '';
      reactCookie.save('guestID', Sub, { path: '/' });
      const cstmr = {
        customerId: getCustomerID(userSession) || '',
        cmdId: '',
        isLoggedin: userSession.logged_in_status || 'false',
        isGuest:
          userSession && userSession.logged_in_status === 'false'
            ? 'true'
            : 'false',
      };
      reactCookie.save('cstmr', JSON.stringify(cstmr), { path: '/' });
      setGuestTokens(response);
      AccessToken && sendGuestId(Sub, AccessToken, state);
    })
    .catch(e => e);
};
