import isEmpty from 'lodash/isEmpty';
import React, { Component } from 'react';
import NO_IMAGE_AVAILABLE from 'assets/images/no-image.jpeg';

export const NO_IMAGE_AVAILABLE_CDN = NO_IMAGE_AVAILABLE;
export const NO_IMAGE_AVAILABLE_LARGE_CDN = NO_IMAGE_AVAILABLE;

class Image extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: props.src,
      alt: props.alt,
    };
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    if (this.image && this.image.complete && this.image.naturalWidth === 0) {
      this.handleError();
    }

    if (this.image && !this.image.complete) {
      setTimeout(() => {
        if (this.image && this.image.naturalWidth === 0) {
          this.handleError();
        }
      }, this.props.timeout || 500);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.src !== this.state.src) this.setState({ src: nextProps.src });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.src !== this.props.src;
  }

  handleError() {
    if (this.props.isPdp && this.props.quantumImageErrorToggle) {
      window &&
        window.QuantumMetricAPI &&
        window.QuantumMetricAPI.sendEvent(159, 0, '');
    }
    if (this.props.isPlp === true) {
      this.props.updateSwatchesValue(
        this.props.swatchIndex,
        this.props.productId,
      );
    }
    if (!isEmpty(this.props.errorImage)) {
      this.setState({ src: this.props.errorImage });
      return null;
    }
    if (
      this.state.src !== NO_IMAGE_AVAILABLE_CDN &&
      this.state.src !== NO_IMAGE_AVAILABLE
    ) {
      this.setState({
        src: NO_IMAGE_AVAILABLE_CDN,
        alt: 'No image available',
      });
    } else if (this.state.src === NO_IMAGE_AVAILABLE_CDN) {
      this.setState({
        src: NO_IMAGE_AVAILABLE,
        alt: 'No image available',
      });
    }
    return null;
  }

  render() {
    return (
      <img
        className={this.props.className}
        src={this.state.src}
        ref={ref => {
          this.image = ref;
        }}
        alt={this.state.alt}
        onClick={this.props.onClick}
        onError={this.handleError}
        title={this.props.title}
      />
    );
  }
}

export default Image;
