import React, { Component } from 'react';
import get from 'lodash/get';
import merge from 'lodash/merge';
import { connect } from 'react-redux';
import { InView } from 'react-intersection-observer';

import { openShopTheStyleModal } from 'shared/actions/actions-shopthestyle';
import {
  getChildProducts,
  openShopTheLookModal,
} from 'shared/actions/actions-shopthelook';
import classNames from 'classnames';
import { calculateActualDimensions } from './dimensionCalculator';
import { getModalPopup, setActiveEntryId } from '../../actions/get-cms-content';
import { openModal } from '../../../common/components/Modal/actions';
import Image from './image';
import { defaultTrackingTags } from '../../constants/constants';
import { addTrackingParam, getTags, getTrackingTagStr } from '../utils';
import './imageAsset.scss';

const CN = 'image-asset';
const LINK_TYPE_DEFAULT = 'default';
const LINK_TYPE_STS = 'Shop The Style ID';
const LINK_TYPE_STL = 'Shop The Look ID';

export class DumbImageAsset extends Component {
  openSTSModal(e, categoryId, productId) {
    e.preventDefault();
    this.props.openShopTheStyleModal({ productId, categoryId });
  }

  openSTLModal(e, productId) {
    e.preventDefault();
    const entryPointPage = 'ImageAsset';

    this.props.getChildProducts(entryPointPage, productId);
    this.props.openShopTheLookModal();
  }

  openHomeMenuModal(e, entryId) {
    e.preventDefault();
    this.props.getModalPopup(entryId);
    this.props.setActiveEntryId(entryId);
    if (this.props.modalContent) {
      this.props.openModal({
        type: 'HomeMenuModal',
        className: 'home-menu-style-modal',
      });
    }
  }

  render() {
    const {
      cmsContentItem,
      isMobilePhone,
      lazyloadCmsContent,
      parentTrackingTags,
      isDesktop,
      hoverToggle,
    } = this.props;
    const {
      altText,
      linkTo,
      linkToType,
      openLinkInNewTab,
      desktopImage: [desktopImage] = [],
      desktopImage: [{ secure_url: desktopImageSrc } = {}] = [],
      desktopImageMaps = [],
      mobileImage: [mobileImage] = [],
      mobileImage: [{ secure_url: mobileImageSrc } = {}] = [],
      desktopHoverImage: [{ secure_url: desktopHoverImageSrc } = {}] = [],
      mobileImageMaps = [],
      title,
      isLazyLoaded = true,
      trackingTags = [],
      zoomOnHover,
      zoomPercentage = 30,
      hoverColorOverlay,
    } = get(cmsContentItem, 'fields', {});

    // Set up Tracking Tags
    const currentTags = getTags(trackingTags);
    const mergedTrackingTags = merge({}, parentTrackingTags, currentTags);
    const trackingTagStr = getTrackingTagStr(mergedTrackingTags);

    const cdnHosts = (global.cloudinary && global.cloudinary.cdnHosts) || {};
    const imageSrc =
      isMobilePhone && mobileImageSrc ? mobileImageSrc : desktopImageSrc;
    const imageMaps =
      isMobilePhone && mobileImageSrc ? mobileImageMaps : desktopImageMaps;

    const cdnImageSrc = Object.entries(cdnHosts).reduce(
      (url, [cloudinaryHost, nmHost]) => url.replace(cloudinaryHost, nmHost),
      imageSrc,
    );

    const activeImage =
      isMobilePhone && mobileImage ? mobileImage : desktopImage;
    const actualImageDimensions = activeImage
      ? calculateActualDimensions(activeImage)
      : null;

    const wrapperConfig = classNames(
      'image-asset-wrapper',
      { 'zoom-on-hover': zoomOnHover && isDesktop },
      { [`zoom-on-hover-${zoomPercentage}`]: zoomOnHover && isDesktop },
      { [`zoom-${zoomPercentage}`]: zoomOnHover && !isDesktop },
      { 'overlay-on-hover': hoverColorOverlay && isDesktop },
      { 'show-overlay': hoverColorOverlay && !isDesktop },
      {
        'change-image-on-hover':
          (!isMobilePhone && desktopHoverImageSrc) ||
          (isMobilePhone && desktopHoverImageSrc && !mobileImageSrc),
      },
    );

    const wrapWithLink = elem => {
      if (!linkTo) return elem;

      const linkUrl = addTrackingParam(linkTo, trackingTagStr);

      if (typeof openLinkInNewTab !== 'undefined' && !openLinkInNewTab) {
        return (
          <div
            onClick={e => this.openHomeMenuModal(e, linkUrl)}
            onKeyUp={e =>
              e.keyCode === 13 && this.openHomeMenuModal(e, linkUrl)}
          >
            {elem}
          </div>
        );
      }

      switch (linkToType) {
        case LINK_TYPE_STS: {
          const [categoryId, productId] = linkUrl.split(',');
          return (
            <div
              onClick={e => this.openSTSModal(e, categoryId, productId)}
              onKeyUp={e =>
                e.keyCode === 13 && this.openSTSModal(e, categoryId, productId)}
            >
              {elem}
            </div>
          );
        }
        case LINK_TYPE_STL: {
          return (
            <div
              onClick={e => this.openSTLModal(e, linkUrl)}
              onKeyUp={e => e.keyCode === 13 && this.openSTLModal(e, linkUrl)}
            >
              {elem}
            </div>
          );
        }
        case LINK_TYPE_DEFAULT:
        default:
          return <a href={linkUrl}>{elem}</a>;
      }
    };

    if (!cdnImageSrc) return null;

    if (lazyloadCmsContent && isLazyLoaded) {
      return (
        <InView rootMargin="500px" triggerOnce>
          {({ inView, ref }) => {
            return wrapWithLink(
              <div
                ref={ref}
                className={hoverToggle ? wrapperConfig : `${CN}-wrapper`}
              >
                <Image
                  actualImageDimensions={actualImageDimensions}
                  altText={altText}
                  cdnImageSrc={cdnImageSrc}
                  CN={CN}
                  imageMaps={imageMaps}
                  isLazyLoad={isLazyLoaded}
                  parentTrackingTags={mergedTrackingTags}
                  inView={inView}
                  title={title}
                  hoverColorOverlay={hoverColorOverlay}
                  desktopHoverImageSrc={desktopHoverImageSrc}
                  isMobilePhone={isMobilePhone}
                  mobileImageSrc={mobileImageSrc}
                  hoverToggle={hoverToggle}
                />
              </div>,
            );
          }}
        </InView>
      );
    }

    return wrapWithLink(
      <div className={hoverToggle ? wrapperConfig : `${CN}-wrapper`}>
        <Image
          actualImageDimensions={actualImageDimensions}
          altText={altText}
          cdnImageSrc={cdnImageSrc}
          CN={CN}
          imageMaps={imageMaps}
          parentTrackingTags={mergedTrackingTags}
          title={title}
          hoverColorOverlay={hoverColorOverlay}
          desktopHoverImageSrc={desktopHoverImageSrc}
          isMobilePhone={isMobilePhone}
          mobileImageSrc={mobileImageSrc}
          hoverToggle={hoverToggle}
        />
      </div>,
    );
  }
}

DumbImageAsset.defaultProps = {
  parentTrackingTags: defaultTrackingTags,
};

const mapStateToProps = state => ({
  isMobilePhone: get(state, 'device.isMobilePhone', false),
  lazyloadCmsContent: state.toggles.LAZYLOAD_CMS_CONTENT,
  modalContent: get(state, 'cms.modal', []),
  isDesktop: get(state, 'device.isDesktop'),
  hoverToggle: state.toggles.HOVER_BG_IMAGE,
});

const mapDispatchToProps = {
  openShopTheStyleModal,
  openShopTheLookModal,
  getChildProducts,
  getModalPopup,
  setActiveEntryId,
  openModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbImageAsset);
