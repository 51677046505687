// Get Group Details from productCatalog
export const getGroup = state => {
  const productCatalog = state.productCatalog;
  return productCatalog.group || {};
};

// Check productCatalog is Group or not
export const isGroup = state => {
  const productCatalog = state.productCatalog;
  return productCatalog.product.isGroup;
};

export default (state, productId) => {
  const productCatalog = state.productCatalog;
  const isGroup =
    productCatalog && productCatalog.product && productCatalog.product.isGroup;
  return isGroup
    ? productCatalog.group.childProducts[productId]
    : productCatalog.product;
};
