import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import get from 'lodash/get';
import { closeModal } from 'shared/components/Modal/actions';
import Button from 'shared/components/Button/button';
import { handleTabEventWith } from 'client/utilities/handleTabbing';
import { ESC_KEYCODE, ENTER_KEYCODE } from 'client/utilities/keyCodes';
import { isMobile } from 'client/utilities/utilities-page';
import { updateProfile } from '../../Profile/profile-actions';
import CountryChooser from '../CountryChooser/countryChooser';
import CurrencyChooser from '../CountryChooser/currencyChooser';
import './countrySelectorModal.scss';

class CountryCurrencyChooser extends Component {
  constructor() {
    super();
    this.onClickConfirm = this.onClickConfirm.bind(this);
    this.onClickCancel = this.OnClickCancel.bind(this);
    this.handleTabEvent = this.handleTabEvent.bind(this);
    this.onEscPress = this.onEscPress.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    this.handleTabEvent();
    addEventListener('keydown', this.onEscPress);
  }

  componentWillUnmount() {
    removeEventListener('keydown', this.onEscPress);
  }

  onClickConfirm() {
    this.props.closeModal();
    if (this.props.selectedCurrencyCode !== this.props.currencyCode) {
      this.props.updateProfile(
        this.props.countryCode,
        this.props.selectedCountryCode,
        this.props.selectedCurrencyCode,
        this.props.selectedLocaleUrl,
      );
    }
  }

  onEscPress(e) {
    if (e.which === ESC_KEYCODE) {
      this.props.closeModal();
      e.preventDefault();
    }
  }

  handleKeyDown(event) {
    if (event.which === ENTER_KEYCODE) {
      event.stopPropagation();
      this.onClickConfirm();
    }
  }

  OnClickCancel() {
    this.props.closeModal();
  }

  handleTabEvent() {
    // eslint-disable-next-line react/no-find-dom-node
    const countryChooserSelect = ReactDOM.findDOMNode(
      this.countryChooserSelect,
    );
    const firstElement = get(countryChooserSelect, 'lastElementChild');

    // eslint-disable-next-line react/no-find-dom-node
    const lastElement = ReactDOM.findDOMNode(this.countryChooserCancel);

    handleTabEventWith(firstElement, lastElement);
  }

  render() {
    return (
      <div
        className="country-selector-modal grid-parent"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="country-selector-label country-selector-title country-selector-description"
        ref={ref => {
          isMobile() && ref && setImmediate(() => ref.focus());
        }}
      >
        <div className="country-selector-modal__image hide-on-mobile grid-50 tablet-grid-50 grid-parent">
          <img
            id="country-selector-label"
            src="https://media.bergdorfgoodman.com/f_auto,q_auto/01/international/bg-country-selector-main-image"
            alt="Choose Country"
          />
        </div>
        <div className="country-selector-modal__copyText grid-50 tablet-grid-50 mobile-grid-100 grid-parent">
          <p id="country-selector-title">
            To shop, please select your country for shipping &amp; the correct
            currency.{' '}
          </p>
          <p id="country-selector-description">
            Please Note: Due to certain restrictions, we are unable to ship
            select items to international destinations. Additionally, some
            promotions may be unavailable for international guests, including
            gift wrap, free shipping &amp; free returns. Please see disclaimers
            for full details.
          </p>

          <CountryChooser
            ref={ref => {
              this.countryChooserSelect = ref;
            }}
          />
          <CurrencyChooser />
          <div
            className="country-selector-modal__confirm-button grid-50 tablet-grid-50 mobile-grid-100"
            onClick={this.onClickConfirm}
          >
            <Button value="CONFIRM" onKeyDown={this.handleKeyDown} />
          </div>
          <div
            className="country-selector-modal__cancel-button grid-50 tablet-grid-50 mobile-grid-100"
            onClick={this.onClickCancel}
          >
            <Button
              inverse
              value="CANCEL"
              ref={ref => {
                this.countryChooserCancel = ref;
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  closeModal,
  updateProfile,
};

const mapStateToProps = state => ({
  selectedCountryCode: state.locale.selectedCountryCode,
  selectedCurrencyCode: state.locale.selectedCurrencyCode,
  selectedLocaleUrl: state.locale.selectedLocaleUrl,
  countryCode: state.locale.countryCode,
  currencyCode: state.locale.currencyCode,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CountryCurrencyChooser);
