import AWS from 'aws-sdk';
import get from 'lodash/get';
import log from 'server/utilities/logger';
import Cache from './cache';

AWS.config.update({ region: 'us-east-2' });
const SSM = new AWS.SSM();
const SSMCache = new Cache();

const getParameter = key =>
  new Promise((resolve, reject) => {
    log.info('Fetching SSM Parameter', key);
    return SSM.getParameter(
      { Name: key, WithDecryption: true },
      (err, data) => {
        if (err) {
          log.info(`Unable to fetch SSM Parameter ${key} : `, err);
          reject(err);
        } else {
          const value = get(data, 'Parameter.Value', '');
          resolve(value);
        }
      },
    );
  });

const getParameters = keys =>
  new Promise((resolve, reject) => {
    log.info('Fetching SSM Parameters', keys);
    return SSM.getParameters(
      { Names: keys, WithDecryption: true },
      (err, data) => {
        if (err) {
          log.info(`Unable to fetch SSM Parameters ${keys} : `, err);
          reject(err);
        } else {
          resolve(get(data, 'Parameters', []).map(({ Value }) => Value));
        }
      },
    );
  });

export const getSSMValue = async key => {
  if (SSMCache.exists(key)) {
    return SSMCache.get(key);
  } else {
    const value = await getParameter(key);
    SSMCache.set(key, value);
    return value;
  }
};

export const getSSMValues = async keys => {
  const keysNotExistInCache = keys.filter(key => !SSMCache.exists(key));

  if (keysNotExistInCache.length) {
    const parameters = await getParameters(keysNotExistInCache);
    keysNotExistInCache.forEach((key, idx) =>
      SSMCache.set(key, parameters[idx]),
    );
  }

  return keys.map(key => SSMCache.get(key));
};

export const parseSSMValue = async key => {
  let value;

  try {
    value = JSON.parse(await getSSMValue(key));
  } catch (err) {
    log.error(`Unable to parse ${key} from AWS SSM`, err);
  }

  return value;
};

export const getFieldFromSSMValue = async (key, path) => {
  const value = await parseSSMValue(key);
  return get(value, path);
};
