import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import httpWithLogging from 'universal/http-client';
import { normalizeContentfulJSON } from './utils';

export const LOADING_CONTENTFUL_DATA = 'LOADING_CONTENTFUL_DATA';
export const RESOLVED_CONTENTFUL_DATA = 'RESOLVED_CONTENTFUL_DATA';
export const REJECTED_CONTENTFUL_DATA = 'REJECTED_CONTENTFUL_DATA';
export const SET_CONTENTFUL_DATA = 'SET_CONTENTFUL_DATA';

const isClient = typeof window !== 'undefined';

export const getContentfulRequestUrl = ({
  channel,
  identifier,
  useCMSLambda,
  usePreview,
  contentfulAccess = {},
  version,
}) => {
  if (useCMSLambda) {
    let url = NMConfig.API_CONTENT_MGT_V2;
    url = `${url}/content?cPath=${identifier}&brand=BG`;
    url = usePreview ? url.concat('&preview=true') : url;
    if (channel) url += `&channel=${channel}`;

    return url;
  } else {
    const {
      contentfulSpace,
      contentfulAccessToken,
      contentfulEnv,
      contentfulCdnHost,
    } = contentfulAccess;

    return `https://${contentfulCdnHost}/spaces/${contentfulSpace}/environments/${contentfulEnv}/entries?access_token=${contentfulAccessToken}&content_type=contentSlot&fields.metaContentAsset.sys.contentType.sys.id=metaContentAsset&fields.metaContentAsset.fields.identifier=${identifier}&include=10`;
  }
};

const getContentfulDataFromServer = ({
  dispatch,
  identifier,
  version,
  useCMSLambda,
  usePreview,
  addApiKeyToHeader,
  requestApi,
}) => {
  const contentfulAccess = get(global, 'contentfulAccess');

  if (!useCMSLambda && !contentfulAccess) {
    return null;
  }

  dispatch({ type: `${LOADING_CONTENTFUL_DATA}_${identifier.toUpperCase()}` });

  const url = getContentfulRequestUrl({
    identifier,
    useCMSLambda,
    usePreview,
    contentfulAccess,
    version,
  });
  const headers =
    useCMSLambda && usePreview ? { 'Cache-Control': 'max-age=0' } : {};

  if (addApiKeyToHeader && global.contentMgtV1_SSMKey) {
    headers['x-api-key'] = global.contentMgtV1_SSMKey;
  }

  return requestApi.get(url, { headers });
};

const getContentfulDataFromClient = ({
  identifier,
  version,
  useCMSLambda,
  usePreview,
  requestApi,
}) =>
  requestApi.get(
    `${NMConfig.API_CONTENTFUL}?identifier=${identifier}&useCMSLambda=${useCMSLambda}&usePreview=${usePreview}&version=${version}`,
  );

export const getContentfulData = (identifier = '', version = 1) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const { toggles, api } = state;
  const useCMSLambda = get(toggles, 'CMS_SERVICE', false);
  const usePreview = isEmpty(get(api, 'requestContext.TRUE_CLIENT_IP'));
  const addApiKeyToHeader = get(toggles, 'CONTENT_LAMBDA_API_KEY', false);
  const requestApi = httpWithLogging(state, 5000);
  const getData = isClient
    ? getContentfulDataFromClient
    : getContentfulDataFromServer;
  return getData({
    dispatch,
    getState,
    identifier,
    version,
    useCMSLambda,
    usePreview,
    addApiKeyToHeader,
    requestApi,
  })
    .then(response => {
      const data = useCMSLambda
        ? response.data
        : normalizeContentfulJSON(response.data);
      dispatch({
        type: `${RESOLVED_CONTENTFUL_DATA}_${identifier.toUpperCase()}`,
      });
      dispatch({
        type: SET_CONTENTFUL_DATA,
        payload: { identifier, data },
      });
    })
    .catch(() => {
      dispatch({
        type: `${REJECTED_CONTENTFUL_DATA}_${identifier.toUpperCase()}`,
      });
      dispatch({
        type: SET_CONTENTFUL_DATA,
        payload: { identifier, data: null },
      });
    });
};
