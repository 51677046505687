import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import EditButton from '../EditButton/EditButton';
import { EDIT_SECTION_PASSWORD, editButtonText } from '../../../constants';
import { openChangePasswordModal } from '../actions';
import { getEditState } from '../helpers';

const SECTION = EDIT_SECTION_PASSWORD;

function DumbPasswordField(props) {
  const { isEditDisabled, isGreyCssFix } = props;

  return (
    <div className="account-details-page__field-wrapper" aria-live="polite">
      <div className="account-details-page__field-header">
        <h3 className="account-details-page__field-label">Password</h3>
        <div className="account-details-page__field-edit">
          <EditButton
            editText={editButtonText}
            isDisabled={isEditDisabled}
            onButtonClick={props.openChangePasswordModal}
            isGreyCssFix={isGreyCssFix}
          />
        </div>
      </div>
      <div className="account-details-page__field-content" />
    </div>
  );
}

const mapStateToProps = state => ({
  ...getEditState(state, SECTION),
  isGreyCssFix: get(state, 'toggles.GREYCOLOR_CSS_FIX', false),
});

const mapDispatchToProps = {
  openChangePasswordModal,
};

const PasswordField = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbPasswordField);

export default PasswordField;
