import {
  RESOLVED_ECMSTOREEVENTS,
  RESET_ECMSTOREEVENTS,
} from '../actions/actions-ecmstoreevents';

export const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESOLVED_ECMSTOREEVENTS:
      return { stores: action.payload };
    case RESET_ECMSTOREEVENTS:
      return defaultState;
    default:
      return state;
  }
};
