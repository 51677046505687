export const LOADING_CMS_CONTENT = 'LOADING_CMS_CONTENT';
export const REJECTED_CMS_CONTENT = 'REJECTED_CMS_CONTENT';
export const RESOLVED_CMS_CONTENT = 'RESOLVED_CMS_CONTENT';

export const LOADING_CMS_GLOBAL = 'LOADING_CMS_GLOBAL';
export const REJECTED_CMS_GLOBAL = 'REJECTED_CMS_GLOBAL';
export const RESOLVED_CMS_GLOBAL = 'RESOLVED_CMS_GLOBAL';

export const LOADING_MODAL_CONTENT = 'LOADING_MODAL_CONTENT';
export const RESOLVED_MODAL_CONTENT = 'RESOLVED_MODAL_CONTENT';
export const REJECTED_MODAL_CONTENT = 'REJECTED_MODAL_CONTENT';

export const SET_ACTIVE_ENTRY_ID = 'SET_ACTIVE_ENTRY_ID';
