import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { shouldLoad } from 'universal/http-client';
import { getModalPopup, setActiveEntryId } from '../../actions/get-cms-content';
import { openModal } from '../../../common/components/Modal/actions';

export class DumbEntryHyperlink extends Component {
  componentDidMount() {
    const { modalContentApi } = this.props;
    const entryId = this.getEntryId();

    if (entryId && shouldLoad(modalContentApi)) {
      this.props.dispatch(getModalPopup(entryId));
    }
  }

  onPopoutAnchorClick(e) {
    e.preventDefault();
    this.props.dispatch(setActiveEntryId(this.getEntryId()));

    if (this.props.modalContent) {
      this.props.dispatch(
        openModal({
          type: 'InfoModal',
        }),
      );
    }
  }

  getEntryId() {
    const { node } = this.props;

    return node.data && node.data.target ? node.data.target.sys.id : '';
  }

  render() {
    return (
      <a onClick={e => this.onPopoutAnchorClick(e)}>{this.props.children}</a>
    );
  }
}

const mapStateToProps = state => {
  return {
    modalContentApi: get(state, 'api.modal_content', {}),
    modalContent: get(state, 'cms.modal', []),
  };
};

export default connect(mapStateToProps)(DumbEntryHyperlink);
