import React, { Component, Fragment } from 'react';
import ErrorMessage from 'productpage/components/ErrorMessage/errorMessage';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { getSelectedCustomizationsAllTypesById } from 'pdp/components/ProductPage/reducers/productReducer';
import { setSelectedCustomizationObjectById } from 'pdp/components/ProductPage/actions';

export class DumbGenericTextNotReqInput extends Component {
  constructor() {
    super();
    this.getErrorMessage = this.getErrorMessage.bind(this);
    this.state = {
      enteredValue: '',
    };
  }

  componentDidMount() {
    const {
      id = '',
      selectedValues = '',
      touched = false,
    } = this.props.selectedCustomizationData;

    this.props.setSelectedCustomizationObjectById({
      id,
      isValid: true,
      selectedValues,
      touched,
    });
  }

  getErrorMessage() {
    const {
      touched = false,
      isValid = false,
    } = this.props.selectedCustomizationData;

    if (touched && !isValid) {
      return 'Please use letters only, special characters are not allowed.';
    }
    return '';
  }

  handleChange(event) {
    const value = event.target.value;
    const trimmed = this.trimSpaceBefore(value);
    const { inputLength } = this.props.option.choices[0];
    this.setState({ enteredValue: event.target.value });

    if (value.length <= inputLength) {
      this.props.setSelectedCustomizationObjectById({
        id: get(this.props.selectedCustomizationData, 'id', ''),
        isValid: true && this.isASCII(trimmed),
        selectedValues: trimmed,
        touched: true,
      });
    }
  }

  trimSpaceBefore(str) {
    return str.replace(/^\s+/g, '');
  }

  isASCII(str) {
    return /^[\x00-\x7F]*$/.test(str);
  }

  render() {
    const { displayName = '' } = this.props.option;
    const { inputLength } = this.props.option.choices[0];
    const { selectedValues = '' } = this.props.selectedCustomizationData;

    return (
      <Fragment>
        <div className="monogram-text-dmg__container">
          <input
            type="text"
            value={selectedValues}
            maxLength={inputLength}
            onChange={e => this.handleChange(e)}
            id={displayName}
          />
          <label htmlFor={displayName}>{displayName}</label>
          <span className="monogram-text-dmg__character">{`${this.state.enteredValue.length}/${inputLength}`}</span>
        </div>
        <ErrorMessage message={this.getErrorMessage()} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    selectedCustomizationData: getSelectedCustomizationsAllTypesById(
      state,
      props,
    ),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setSelectedCustomizationObjectById: (...args) =>
    dispatch(setSelectedCustomizationObjectById(ownProps.productId, ...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbGenericTextNotReqInput);
