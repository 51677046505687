import React from 'react';
import classNames from 'classnames';
import Column from '../Common/Column/column';
import buildArrayOfCategoriesByColumnBreakFlg from '../drawerUtility';
import './cuspDrawer.scss';

const CuspDrawer = ({ silo, style, closeFunction, hidden,
  delayHoverOnSilos = false, getPromoContent }) => {
  const arrayOfCategoriesGrouped = buildArrayOfCategoriesByColumnBreakFlg(silo.categories);
  const arrayOfColumns = arrayOfCategoriesGrouped
    .map((categories, index) => Column({ key: index, categories }));

  const promoBannerJsx = getPromoContent ? <div className="cusp-refreshable-content grid-100 cusp-drawer-promo-container">{getPromoContent()}</div> : false;
  const columnCount =  arrayOfColumns.length;

  if (delayHoverOnSilos) {
    return (
      <section
        className={classNames('silo-group cusp-silo-grp delay-hover-on-silos', { 'drawer-hidden': hidden })}
        data-silo-columns={columnCount}
      >
      <div className="silo-parent">
        <div className="cusp-drawer-content silo-column-group">
          {arrayOfColumns}
        </div>
        {promoBannerJsx ? (
          <div className="silo-column-promo-group">
            {promoBannerJsx}
          </div>
        ) : null}
      </div>
      </section>
    );
  } else {
    return (
      <section
        style={style}
        className={classNames('silo-group cusp-silo-grp', { 'drawer-hidden': hidden })}
        data-silo-columns={columnCount}
      >
        <div className="silo-parent">
          <div className="cusp-drawer-content silo-column-group">
            {arrayOfColumns}
          </div>
          {promoBannerJsx ? (
            <div className="silo-column-promo-group">
              {promoBannerJsx}
            </div>
          ) : null}
      </div>
      </section>
    );
  }
};


export default CuspDrawer;
