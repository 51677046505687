import get from 'lodash/get';

export function getEditState(state, sectionName) {
  const currentEditSection = get(
    state,
    'myaccount.myAccountDetails.currentEditSection',
    null,
  );

  return {
    isEditOpened: currentEditSection === sectionName,
    isEditDisabled: !!(
      currentEditSection && currentEditSection !== sectionName
    ),
  };
}

export function redirectToLoginPage() {
  window.location.replace(
    '/e/login?returnToURL=%2Fe%2Fmyaccount%2Faccountdetails',
  );
}
