import React from 'react';
import MyAccount from './MyAccount/myAccount';
import FooterLinks from './FooterLinks/footerLinks';
import CustomerSupportContact from './CustomerSupportContact/customerSupportContact';
import { BodyMsg } from '@bgo-ui/common/Styleguide/Typography';
import './footerContent.scss';
import FooterRightContainer from './FooterLinks/FooterRightContainer';
import Toggle from '../../../client/common/components/Toggle/toggle';

export const FooterContent = ({
  promoMessage,
  desktopContent,
  mobileContent,
}) => (
  <div className="footer-wrapper">
    <div className="footer-content-container">
      <div className="footer-content-container-main">
        <div className="footer-content-container-msg">
          <BodyMsg>{promoMessage}</BodyMsg>
        </div>
        <CustomerSupportContact footerLinks={mobileContent} />
        <div className="footer-content-container__nav-links">
          <Toggle feature="INTERNATIONAL">
            <MyAccount />
          </Toggle>
          <div className="hide-on-footer-mobile">
            <FooterLinks footerLinks={desktopContent} />
          </div>
        </div>
      </div>
      <div className="footer-content-container__social-box">
        <div className="hide-on-footer-desktop full-width">
          <FooterRightContainer />
        </div>
      </div>
    </div>
  </div>
);

export default FooterContent;
