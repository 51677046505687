import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import values from 'lodash/values';
import { getColorOptions } from './getOptions';
import getSelectedColorIndex from './getSelectedColorIndex';
import getHoveredColorIndex from './getHoveredColorIndex';

export const getMedia = productState => get(productState, 'media', {});

export const getSelectedOptionMedia = productState => {
  const colorOptions = getColorOptions(productState);
  const selectedColorIndex = getSelectedColorIndex(productState);
  const selectedColorMedia = get(
    colorOptions,
    `values[${selectedColorIndex}].media`,
    {},
  );

  return selectedColorMedia;
};

export const getHoveredOptionMedia = productState => {
  const colorOptions = getColorOptions(productState);
  const hoveredColorIndex = getHoveredColorIndex(productState);
  const hoveredColorMedia = get(
    colorOptions,
    `values[${hoveredColorIndex}].media`,
    {},
  );
  return hoveredColorMedia;
};

export const getOptionMedia = productState => {
  const selectedColorMedia = getSelectedOptionMedia(productState);
  const hoveredColorMedia = getHoveredOptionMedia(productState);
  return { ...selectedColorMedia, ...hoveredColorMedia };
};

export const getActiveMedia = (
  productState,
  isPDP = false,
  isMediaMergeLogicDisabled,
) => {
  const baseMedia = getMedia(productState);
  const optionMedia = getOptionMedia(productState);
  const mergedMedia = merge({}, baseMedia, optionMedia);

  // If a product in PDP has more than 1 color (sku), picks the media from sku-level.
  // Else uses the merged media (product-level and sku-level).
  // if (isPDP) return isEmpty(optionMedia) ? mergedMedia : optionMedia;

  if (isPDP && isMediaMergeLogicDisabled) return { optionMedia, mergedMedia };

  return mergedMedia;
};

export const getActiveMediaIndex = (productState, checkDynamic = false) => {
  let activeMediaIndex = get(productState, 'activeMediaIndex', 0);

  if (checkDynamic && activeMediaIndex === 0) {
    const optionMedia = getOptionMedia(productState);
    const activeMedia = getActiveMedia(productState);
    if (!optionMedia.main && !isEmpty(optionMedia.alternate)) {
      const firstDynamic = values(optionMedia.alternate)[0];
      activeMediaIndex =
        findIndex(values(activeMedia.alternate), firstDynamic) + 1;
    }
  }

  return activeMediaIndex;
};

export const getActiveMediaFirstDynamic = productState => {
  const optionMedia = getOptionMedia(productState);
  const baseMedia = getMedia(productState);
  return optionMedia.main || values(optionMedia.alternate)[0] || baseMedia.main;
};
