import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Copy from '../../../../common/Copy/Copy';
import {
  editMyAccountAddress,
  deleteMyAccountAddress,
  closeAddressModal,
} from '../actions';
import {
  deleteButtonText,
  cancelButtonText,
  DAB_MODAL_TYPE,
} from '../../../constants';
import './AddressActionModal.scss';

const ADDRESS_TYPE = 'shipping';

const AddressActionModal = props => {
  return (
    <div className="address-del-modal__content">
      <Copy
        content={get(props.myAccountContent, 'ADDRESSBOOK-DELETE-TITLE', '')}
      />
      <Copy
        content={get(props.myAccountContent, 'ADDRESSBOOK-DELETE-DEFAULT', '')}
      />
      <button
        className="default-select"
        type="button"
        onClick={() => deleteAddress(props)}
      >
        {deleteButtonText}
      </button>
      <button
        type="button"
        onClick={() => props.closeAddressModal(DAB_MODAL_TYPE)}
      >
        {cancelButtonText}
      </button>
    </div>
  );
};

function deleteAddress(props) {
  const {
    addressId,
    myAccountAddress,
    editMyAccountAddress,
    deleteMyAccountAddress,
  } = props;
  const isDefault = [
    myAccountAddress.find(item => item.addressId === addressId),
  ].some(data => data.defaultAddress);
  if (isDefault) {
    if (myAccountAddress.length === 1) {
      editMyAccountAddress({ defaultAddress: false }, addressId, true);
    } else {
      const filterAddress = myAccountAddress
        .filter(data => data.addressType === ADDRESS_TYPE)
        .sort((x, y) => y.defaultAddress - x.defaultAddress);
      editMyAccountAddress(
        { defaultAddress: true },
        filterAddress[1].addressId,
        true,
      );
    }
  } else {
    deleteMyAccountAddress();
  }
}

const mapStateToProps = state => ({
  myAccountContent: get(state, 'myaccount.myAccountContent', {}),
  myAccountAddress: get(state, 'myaccount.myAccountAddress', []),
  addressId: get(state, 'myaccount.addressId', ''),
});

const mapDispatchToProps = {
  editMyAccountAddress,
  deleteMyAccountAddress,
  closeAddressModal,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddressActionModal);
