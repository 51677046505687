import React, { Fragment } from 'react';
import { array, string } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import merge from 'lodash/merge';
import { addTrackingParam, getTags, getTrackingTagStr } from '../utils';
import { defaultTrackingTags } from '../../constants/constants';

/**
 * @function ImageMapsComponent
 * @param {Array} props.imageMaps
 * @param {String} props.CN
 * @param {Object} props.parentTrackingTags
 * @returns {React.ReactElement}
 */
const ImageMapsComponent = ({ imageMaps, CN, parentTrackingTags }) => {
  if (isEmpty(imageMaps)) return null;

  return (
    <Fragment>
      {imageMaps.map((item, index) => {
        const { height, left, top, url, width } = item.fields;

        // Set up Tracking Tags
        const trackingTags = get(item.fields, 'trackingTags', []);
        const currentTags = getTags(trackingTags);
        const mergedTrackingTags = merge({}, parentTrackingTags, currentTags);
        const trackingTagStr = getTrackingTagStr(mergedTrackingTags);
        const linkUrl = addTrackingParam(url, trackingTagStr);

        return (
          // eslint-disable-next-line
          <a
            className={`${CN}-map`}
            key={`${CN}-map-${index}`}
            style={{
              position: 'absolute',
              top: `${top}%`,
              left: `${left}%`,
              height: `${height}%`,
              width: `${width}%`,
            }}
            href={linkUrl}
          />
        );
      })}
    </Fragment>
  );
};

ImageMapsComponent.defaultProps = {
  parentTrackingTags: defaultTrackingTags,
};

ImageMapsComponent.propTypes = {
  imageMaps: array.isRequired,
  CN: string.isRequired,
};

export default ImageMapsComponent;
