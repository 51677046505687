import get from 'lodash/get';
import NO_IMAGE_AVAILABLE from 'assets/images/no-image.png';
import { VIEW_BY_OPTIONS } from 'plp/constants';
import { TEMPLATE_B, CLOUDINARY_SIZE } from '../constants';

export const getProductImagePath = (product, isCloudinary) => {
  if (isCloudinary) {
    return get(product, 'media.main.dynamic.url');
  }
  return get(product, 'media.main.medium.url');
};

export const getProductAlternateImage = (product, isCloudinary) => {
  if (!isCloudinary) {
    const LAImage = get(product, 'media.alternate.l.medium.url');
    const AAImage = get(product, 'media.alternate.a.medium.url');
    return LAImage || AAImage;
  } else {
    const LAImage = get(product, 'media.alternate.l.dynamic.url');
    let AAImage = get(product, 'media.alternate.a.dynamic.url');

    // When there is no alternate image is available in "a" object, use the main media as fallback.
    if (AAImage === undefined) {
      AAImage = get(product, 'media.main.dynamic.url');
    }

    return LAImage || AAImage;
  }
};

export const isValidCloudinaryUrl = url => {
  if (!url || url.indexOf('q_auto') === -1 || url.indexOf('f_auto') === -1) {
    return undefined;
  }
  return url;
};

export const getProductImagePathByViewByOption = (
  product,
  viewByOption,
  selectedParentCategoryConfig,
) => {
  const getImageUrl = key =>
    isValidCloudinaryUrl(get(product, key === 'm0' ? 'main' : 'alt', ''));

  const productPaths = [
    getImageUrl(selectedParentCategoryConfig.outfit),
    getImageUrl(selectedParentCategoryConfig.product) ||
      getImageUrl(
        selectedParentCategoryConfig.fallback ||
          selectedParentCategoryConfig.outfit,
      ),
  ];

  if (viewByOption === VIEW_BY_OPTIONS[0]) {
    return productPaths.reverse();
  } else {
    return productPaths;
  }
};

export const transformCloudinaryUrl = (
  url,
  imageSize,
  imageTypeDesktopTab = 'normal',
  imageTypeMob = 'normal',
  ar = '5:7',
) => {
  if (!isValidCloudinaryUrl(url)) {
    return NO_IMAGE_AVAILABLE;
  }
  const defaultDpr = {
    value: '2.0',
    quality: 'low',
  };

  const dprSteps = [
    {
      value: '1.0',
      quality: 'good',
    },
    {
      value: '1.5',
      quality: 'good',
    },
    {
      value: '2.0',
      quality: 'low',
    },
  ];

  const qAutoIndex = url.indexOf('q_auto') + 6;

  if (qAutoIndex === -1 || qAutoIndex === undefined) return NO_IMAGE_AVAILABLE;
  const imageSource = {
    desktop: {
      imageSrc: '',
      srcSet: [],
    },
    tablet: {
      imageSrc: '',
      srcSet: [],
    },
    mobile: {
      imageSrc: '',
      srcSet: [],
    },
  };

  Object.keys(imageSource).forEach(key => {
    const imageType = key === 'mobile' ? imageTypeMob : imageTypeDesktopTab;
    const imageWidth = imageSize[key][imageType].width;

    const getImageSrc = (quality, dpr) => {
      const imageDynamicParams = `:${quality},ar_${ar},c_fill,dpr_${dpr},w_${imageWidth}`;
      return (
        url.slice(0, qAutoIndex) + imageDynamicParams + url.slice(qAutoIndex)
      );
    };

    // default image URL
    imageSource[key].imageSrc = getImageSrc(
      defaultDpr.quality,
      defaultDpr.value,
    );
    // srcSet image URLs for different device scaling factors
    imageSource[key].srcSet = dprSteps.map(step => {
      return {
        rule: `${step.value}x`,
        src: getImageSrc(step.quality, step.value),
      };
    });
  });
  return imageSource;
};

export const getPDPImageUrl = (
  image,
  imageSizeUrl,
  templateType,
  isCloudinary,
  imageTypeDesktopTab = 'normal',
  imageTypeMob = 'normal',
) => {
  const cloudinaryUrl = get(image, 'dynamic.url', false);
  const imageSize = CLOUDINARY_SIZE.PDP[templateType];
  if (isCloudinary && !cloudinaryUrl) {
    return NO_IMAGE_AVAILABLE;
  }
  return isCloudinary && cloudinaryUrl
    ? transformCloudinaryUrl(
        cloudinaryUrl,
        imageSize,
        imageTypeDesktopTab,
        imageTypeMob,
      )
    : get(image, imageSizeUrl, NO_IMAGE_AVAILABLE);
};

export const getPDPImageSmallUrl = (
  image,
  isCloudinary,
  templateType = TEMPLATE_B,
) => {
  const imageSizeUrl = 'thumbnail.url';
  const imageType = 'thumbnail';
  return getPDPImageUrl(
    image,
    imageSizeUrl,
    templateType,
    isCloudinary,
    imageType,
    imageType,
  );
};

export const getPDPImageMediumUrl = (
  image,
  isCloudinary,
  templateType = TEMPLATE_B,
) => {
  const imageSizeUrl = 'medium.url';
  return getPDPImageUrl(image, imageSizeUrl, templateType, isCloudinary);
};

export const getPDPImageLargeUrl = (
  image,
  isCloudinary,
  templateType = TEMPLATE_B,
) => {
  const imageSizeUrl = 'large.url';
  return getPDPImageUrl(image, imageSizeUrl, templateType, isCloudinary);
};

export const getPDPImageZoomUrl = (image, isCloudinary) => {
  return isCloudinary
    ? get(image, 'dynamic.url', NO_IMAGE_AVAILABLE)
    : get(image, 'large.url', NO_IMAGE_AVAILABLE);
  // const imageSizeUrl = 'large.url';
  // const imageType = 'zoom';
  // return getPDPImageUrl(image, imageSizeUrl, templateType, isCloudinary, imageType, imageType);
};

export const getWhatsNewImage = (
  image,
  imageSizeUrl,
  isCloudinary,
  imageType,
) => {
  const cloudinaryUrl = get(image, 'dynamic.url', false);
  if (imageType === 'alt' && isCloudinary && !cloudinaryUrl) return undefined;
  if (isCloudinary && !cloudinaryUrl) {
    return NO_IMAGE_AVAILABLE;
  }
  return isCloudinary
    ? transformCloudinaryUrl(cloudinaryUrl, CLOUDINARY_SIZE.WHATSNEW)
    : get(image, imageSizeUrl, NO_IMAGE_AVAILABLE);
};

export const getWishlistImage = (image, imageSizeUrl, isCloudinary) => {
  if (!image) {
    return NO_IMAGE_AVAILABLE;
  }
  const cloudinaryUrl = get(image, 'main.dynamic.url', false);
  if (isCloudinary && !cloudinaryUrl) {
    return NO_IMAGE_AVAILABLE;
  }
  return isCloudinary
    ? transformCloudinaryUrl(cloudinaryUrl, CLOUDINARY_SIZE.WISHLIST)
    : get(image, imageSizeUrl, NO_IMAGE_AVAILABLE);
};

export const getMiniCartImage = imageUrl => {
  if (!imageUrl) {
    return NO_IMAGE_AVAILABLE;
  }
  if (isValidCloudinaryUrl(imageUrl)) {
    return transformCloudinaryUrl(imageUrl, CLOUDINARY_SIZE.MINICART);
  } else {
    return imageUrl;
  }
};
