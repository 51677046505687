export const contactUsContent = {
  id: 'contact-us',
  title: 'Contact Us',
};

export const bespokeExperiencesContent = {
  id: 'bespoke-experiences',
  title: 'Bespoke Experiences',
};

export const ourPartnersContent = {
  id: 'our-partners',
  title: 'Our Partners',
};

export const commonContent = {
  contactUs: contactUsContent,
  bespokeExperiences: bespokeExperiencesContent,
  ourPartners: ourPartnersContent,
};
