import get from 'lodash/get';
import { getLinkedDataPDP } from 'client-utils/utilities-linked-data';
import { isIpadPro } from 'client-utils/utilities-page';
import {
  RESOLVED_PRODUCT,
  RESOLVED_GROUP,
  REJECTED_PRODUCT,
  RESET_PRODUCT,
  INCREASE_PRODUCT_QUANTITY,
  DECREASE_PRODUCT_QUANTITY,
  UPDATE_PRODUCT_QUANTITY,
  SET_DELIVERY_DATE,
  ZOOM_PRODUCT_IMAGE,
  ZOOM_PRODUCT_IMAGE_QL,
  SET_ACTIVE_MEDIA_INDEX,
  ADD_PRODUCT_TO_BAG,
  UNSET_PRODUCT_VIDEO,
  SET_PRODUCT_VIDEO,
  SET_REPLENISH_INTERVAL,
  SET_BOPS_ERROR_FOR_REPLENISHMENT,
  BOPS_NO_SELECTION_ERR_MSG_FOR_REPLENISHMENT,
  TOGGLE_FAVORITE,
  SET_PERSONALIZATION,
  SET_SELECTED_CUSTOMIZATIONS,
  SET_SELECTED_CUSTOMIZATIONS_ALL_TYPES,
  SET_SELECTED_CUSTOMIZATION_OBJECT,
  SET_SELECTED_CUSTOMIZATIONS_TOUCHED_TRUE,
  SET_SELECTED_CUSTOMIZATIONS_SAVEDVALUES,
  SET_SELECTED_CUSTOMIZATIONS_SELECTEDVALUES_FROM_SAVEDVALUES,
  SET_DYNAMIC_IMAGE_URL,
  QUICK_LOOK_ADD_PRODUCT_TO_BAG,
  SET_ACTIVE_PDP_TAB,
  RESOLVED_WEARITWITH_PRODUCTS,
  RESOLVED_ADD_TO_BAG,
  RESOLVED_STYLYZE_MINIFIED_PRODUCT,
  RESOLVED_MINIFIED_PRODUCT_INFO,
  RESOLVED_YAML_MINIFIED_RECOMMENDATIONS,
  RESOLVED_PDP_RELATED_CATS,
  RESOLVED_PDP_BROUGHT_CATS,
} from '../actions';
import skuReducer from './skuReducer';
import errorReducer from './errorReducer';

const isChanel = product => {
  const designerName = get(product, 'designer.name', '');
  return designerName.toLowerCase() === 'chanel';
};

export const isZeroDollarProduct = product =>
  parseFloat(get(product, 'price.retailPrice', '0')) <= 0;

export function updateProductState(state, product) {
  return {
    ...state,
    ...product,
    quantity: 1,
    linkedData: getLinkedDataPDP(product),
    options: {
      ...state.options,
      ...product.options,
    },
    deliveryDate: '',
    isChanel: isChanel(product),
    isZeroDollarProduct: isZeroDollarProduct(product),
  };
}

export const defaultState = {
  quantity: 1,
  activeMediaIndex: 0,
  linkedData: {},
  videoActive: false,
  activePDPTab: 0,
  deliveryDate: '',
  vendorRestrictedDates: [],
  bopsErrorForReplenishment: false,
  favAddRemoveStatus: '',
  isPersonalizationSelected: false,
  addToBagError: '',
  bopsError: '',
  isChanel: false,
  isZeroDollarProduct: false,
  isGroup: false,
  wearItWithProducts: [],
  similarStyleRecs: [],
  ymalMinifiedRecs: [],
  pdpRelatedCategories: [],
  pdpBroughtCategories: [],
};

// High order reducer to avoid unncessary updates for groups
export default (state = defaultState, action) => {
  switch (action.type) {
    case RESOLVED_PRODUCT:
      return updateProductState(state, action.payload);
    case RESOLVED_GROUP:
      return {
        ...state,
        isGroup: true,
      };
    case REJECTED_PRODUCT:
      return state;
    case RESET_PRODUCT:
      return defaultState;
    default:
      return productInteractionReducer(state, action);
  }
};

// Common actions used for group and single product
export function productInteractionReducer(state = defaultState, action) {
  let quantity;
  let deliveryDate;
  let productsAddedToCart;

  switch (action.type) {
    case ZOOM_PRODUCT_IMAGE:
      return { ...state, zoomedImage: { ...action.payload } };
    case ZOOM_PRODUCT_IMAGE_QL:
      return { ...state, zoomedImageQl: { ...action.payload } };
    case SET_ACTIVE_MEDIA_INDEX:
      return { ...state, activeMediaIndex: action.payload };
    case SET_PRODUCT_VIDEO:
      return { ...state, videoActive: true };
    case UNSET_PRODUCT_VIDEO:
      return { ...state, videoActive: false };
    case SET_ACTIVE_PDP_TAB:
      return { ...state, activePDPTab: action.payload };
    case ADD_PRODUCT_TO_BAG:
      return {
        ...state,
        quantity: 1,
        addToBagError: '',
      };
    case QUICK_LOOK_ADD_PRODUCT_TO_BAG:
      return {
        ...state,
        quantity: 0,
        addToBagError: '',
      };
    case INCREASE_PRODUCT_QUANTITY:
      quantity = state.quantity < 999 ? action.payload + 1 : 999;
      return { ...state, quantity };
    case DECREASE_PRODUCT_QUANTITY:
      quantity = state.quantity > 1 ? action.payload - 1 : 1;
      return { ...state, quantity };
    case UPDATE_PRODUCT_QUANTITY:
      quantity = parseInt(action.payload.toString().substr(0, 3), 10);
      return { ...state, quantity };
    case SET_DELIVERY_DATE:
      deliveryDate = action.payload;
      return { ...state, deliveryDate };
    case SET_REPLENISH_INTERVAL:
      return {
        ...state,
        replenishInterval: action.payload,
        bopsErrorForReplenishment: false,
        bopsNoSelectionErrorForReplenishment: false,
        addToBagError: '',
      };
    case SET_BOPS_ERROR_FOR_REPLENISHMENT:
      return { ...state, bopsErrorForReplenishment: true };
    case BOPS_NO_SELECTION_ERR_MSG_FOR_REPLENISHMENT:
      return { ...state, bopsNoSelectionErrorForReplenishment: true };
    case TOGGLE_FAVORITE:
      return { ...state, favAddRemoveStatus: action.payload };
    case SET_PERSONALIZATION:
      return { ...state, isPersonalizationSelected: action.payload };
    case SET_SELECTED_CUSTOMIZATIONS:
      return {
        ...state,
        customization: {
          ...state.customization,
          selectedCustomizations: action.payload,
        },
      };
    case SET_SELECTED_CUSTOMIZATIONS_ALL_TYPES:
      return {
        ...state,
        customization: {
          ...state.customization,
          selectedCustomizationsAllTypes: action.payload,
        },
      };
    case SET_DYNAMIC_IMAGE_URL:
      return {
        ...state,
        customization: {
          ...state.customization,
          dynamicImageURL: action.payload,
        },
      };
    case SET_SELECTED_CUSTOMIZATION_OBJECT: {
      return {
        ...state,
        customization: {
          ...state.customization,
          selectedCustomizationsAllTypes: updateSelectedCustomizationObject(
            state.customization
              ? state.customization.selectedCustomizationsAllTypes
              : [],
            action.payload,
          ),
        },
      };
    }
    case SET_SELECTED_CUSTOMIZATIONS_TOUCHED_TRUE:
      return {
        ...state,
        customization: {
          ...state.customization,
          selectedCustomizationsAllTypes: setSelectedCustomizationTouchedTrue(
            state.customization.selectedCustomizationsAllTypes,
          ),
        },
      };
    case SET_SELECTED_CUSTOMIZATIONS_SAVEDVALUES:
      return {
        ...state,
        customization: {
          ...state.customization,
          selectedCustomizationsAllTypes: setSelectedCustomizationSavedValues(
            state.customization.selectedCustomizationsAllTypes,
          ),
        },
      };
    case SET_SELECTED_CUSTOMIZATIONS_SELECTEDVALUES_FROM_SAVEDVALUES:
      return {
        ...state,
        customization: {
          ...state.customization,
          selectedCustomizationsAllTypes: setSelectedCustomizationSelectedValuesFromSavedValues(
            state.customization.selectedCustomizationsAllTypes,
          ),
        },
      };
    case RESOLVED_WEARITWITH_PRODUCTS:
      return {
        ...state,
        wearItWithProducts: [
          ...state.wearItWithProducts,
          ...action.payload.data,
        ],
      };
    case RESOLVED_ADD_TO_BAG:
      productsAddedToCart =
        isIpadPro() &&
        (state.productsAddedToCart || []).concat([action.payload.skuId]);
      return {
        ...state,
        productsAddedToCart,
      };
    case RESOLVED_STYLYZE_MINIFIED_PRODUCT:
      return {
        ...state,
        similarStyleRecs: action.payload,
      };
    case RESOLVED_MINIFIED_PRODUCT_INFO:
      return {
        ...state,
        recentlyViewedProducts: action.payload,
      };
    case RESOLVED_YAML_MINIFIED_RECOMMENDATIONS:
      return {
        ...state,
        ymalMinifiedRecs: action.payload,
      };
    case RESOLVED_PDP_RELATED_CATS:
      return {
        ...state,
        pdpRelatedCategories: action.payload,
      };
    case RESOLVED_PDP_BROUGHT_CATS:
      return {
        ...state,
        pdpBroughtCategories: action.payload,
      };
    default:
      return [errorReducer, skuReducer].reduce(
        (state, reducer) => reducer(state, action),
        state,
      );
  }
}

function updateSelectedCustomizationObject(
  selectedCustomizationsAllTypes,
  payload,
) {
  return selectedCustomizationsAllTypes.map(customization => {
    if (customization.id !== payload.id) {
      return customization;
    }

    return {
      ...customization,
      isValid: payload.isValid,
      selectedValues: payload.selectedValues,
      touched: payload.touched,
    };
  });
}

function setSelectedCustomizationTouchedTrue(selectedCustomizationsAllTypes) {
  return selectedCustomizationsAllTypes.map(customization => {
    return {
      ...customization,
      touched: true,
    };
  });
}

function setSelectedCustomizationSavedValues(selectedCustomizationsAllTypes) {
  return selectedCustomizationsAllTypes.map(customization => {
    return {
      ...customization,
      savedValues: customization.selectedValues,
    };
  });
}

function setSelectedCustomizationSelectedValuesFromSavedValues(
  selectedCustomizationsAllTypes,
) {
  return selectedCustomizationsAllTypes.map(customization => {
    return {
      ...customization,
      selectedValues: customization.savedValues,
      isValid: true,
    };
  });
}

export function getSelectedCustomizationsAllTypesById(state, props) {
  const isGroup = get(state, 'productCatalog.product.isGroup', false);
  const productCatalog = get(state, 'productCatalog');
  const productId = get(props, 'productId', '');

  return isGroup
    ? productCatalog.group.childProducts[
        productId
      ].customization.selectedCustomizationsAllTypes.find(
        selectedCustomization => selectedCustomization.id === props.option.id,
      )
    : productCatalog.product.customization.selectedCustomizationsAllTypes.find(
        selectedCustomization => selectedCustomization.id === props.option.id,
      );
}

export function getSelectedCustomizationsAllTypes(state, productId) {
  const isGroup = get(state, 'productCatalog.product.isGroup', false);
  const productCatalog = get(state, 'productCatalog');

  return isGroup
    ? productCatalog.group.childProducts[productId].customization
        .selectedCustomizationsAllTypes
    : productCatalog.product.customization.selectedCustomizationsAllTypes;
}
