import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { RIGHT_ARROW_KEY, LEFT_ARROW_KEY } from 'client-utils/keyCodes';
import './promoCarousel.scss';
import classnames from 'classnames';

class PromoCarousel extends Component {
  constructor(props) {
    super(props);

    // const defaultPromoText = `<div><p style="text-align: center;"><span style="color: rgb(255,255,255);"><a class="centered dateselection" href="#" data-modal-url="https://www.bergdorfgoodman.com/category/cat000000/popup/freefree/freefreepopup.html" data-modal-width="500" tabindex="-1"><b>FREE SHIPPING, FREE RETURNS</b>. ONLINE &amp; IN-STORE</a></span></p>`;

    const { carouselDivs, promoClass } = props;
    this.state = {
      promoIndex: 0,
      promoDiv: !isEmpty(carouselDivs) ? carouselDivs[0] : '',
      bannerInFocus: false,
      promoClass: !isEmpty(promoClass) ? promoClass[0] : 'promo-ticker',
    };
    this.switchPromo = this.switchPromo.bind(this);
    this.setBannerInFocus = this.setBannerInFocus.bind(this);
    this.checkBannerFocusAndSwitch = this.checkBannerFocusAndSwitch.bind(this);
    this.nextPromo = this.nextPromo.bind(this);
    this.onKeyDownHandler = this.onKeyDownHandler.bind(this);
  }

  componentDidMount() {
    setInterval(this.checkBannerFocusAndSwitch, 6000);
  }

  componentDidUpdate() {
    if (
      this.state.bannerInFocus &&
      this.tickerController &&
      this.setFocusToBanner
    )
      this.tickerController.focus();
    this.setFocusToBanner = false;
  }
  componentWillUnmount() {
    clearInterval(this.checkBannerFocusAndSwitch);
  }

  onKeyDownHandler(e) {
    if (e.which === RIGHT_ARROW_KEY) {
      this.setFocusToBanner = true;
      this.nextPromo();
    } else if (e.which === LEFT_ARROW_KEY) {
      this.setFocusToBanner = true;
      this.previousPromo();
    }
    this.setBannerInFocus(true);
  }

  setBannerInFocus(inFocus) {
    this.setState({ bannerInFocus: inFocus });
  }
  switchPromo(promoIndex) {
    const { carouselDivs } = this.props;
    if (carouselDivs && carouselDivs.length > 0) {
      let nextIndex;
      if (promoIndex >= 0) {
        nextIndex = promoIndex % carouselDivs.length;
      } else {
        nextIndex = carouselDivs.length - 1;
      }
      this.setState({
        promoDiv: carouselDivs[nextIndex],
        promoIndex: nextIndex,
      });
    }
  }

  nextPromo() {
    this.switchPromo(this.state.promoIndex + 1);
  }

  previousPromo() {
    this.switchPromo(this.state.promoIndex - 1);
  }

  checkBannerFocusAndSwitch() {
    const { bannerInFocus } = this.state;
    if (!bannerInFocus) {
      this.nextPromo();
    }
  }
  promoPausePlayToggledOff() {
    return (
      <div
        className="promo-ticker"
        onMouseEnter={() => this.setBannerInFocus(true)}
        onMouseLeave={() => this.setBannerInFocus(false)}
        onFocus={() => this.setBannerInFocus(true)}
        onBlur={() => this.setBannerInFocus(false)}
        dangerouslySetInnerHTML={{ __html: this.state.promoDiv }}
      />
    );
  }

  render() {
    return (
      <div
        className={classnames(this.state.promoClass, {
          large: this.props.isFullWidth,
        })}
        ref={ref => {
          this.tickerController = ref;
        }}
        onMouseEnter={() => this.setBannerInFocus(true)}
        onMouseLeave={() => this.setBannerInFocus(false)}
        onFocus={() => this.setBannerInFocus(true)}
        onBlur={() => this.setBannerInFocus(false)}
        onKeyDown={e => this.onKeyDownHandler(e)}
        tabIndex={0}
        dangerouslySetInnerHTML={{ __html: this.state.promoDiv }}
      />
    );
  }
}

const mapStateToProps = state => ({
  carouselDivs: state.promoCarousel.carouselDivs,
  apiStatus: state.api.promo_carousel_content,
  isFullWidth: state.toggles.HOMEPAGE_FULL_WIDTH,
  promoClass: state.promoCarousel.class,
});

export default connect(mapStateToProps)(PromoCarousel);
