import httpWithLogging from 'universal/http-client';
import { buildCookieString } from 'universal/utilities-cookies';
import get from 'lodash/get';

export const LOADING_ECMRECOMMENDATIONS = 'LOADING_ECMRECOMMENDATIONS';
export const RESOLVED_ECMRECOMMENDATIONS = 'RESOLVED_ECMRECOMMENDATIONS';
export const REJECTED_ECMRECOMMENDATIONS = 'REJECTED_ECMRECOMMENDATIONS';
export const RESET_ECMRECOMMENDATIONS = 'RESET_ECMRECOMMENDATIONS';
export const SET_SELECTED_ITEM_INDEX = 'SET_SELECTED_ITEM_INDEX';
export const RESOLVE_BV_RECOMMENDATIONS = 'RESOLVE_BV_RECOMMENDATIONS';
export const REJECT_BV_RECOMMENDATIONS = 'REJECT_BV_RECOMMENDATIONS';

export function getEcmRecommendations() {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_ECMRECOMMENDATIONS });
    const state = getState();
    const RECOMMENDATION_API_TIMEOUT = get(
      state,
      'apiTimeouts.RECOMMENDATION_API_TIMEOUT'
    );
    const { user, session } = state;
    const requestApi = httpWithLogging(state, RECOMMENDATION_API_TIMEOUT);
    const bvCategories = get(
      state,
      'ecm.ecmRecommendations.bvRecommendations.bvCategories',
      []
    );
    const categoryIds = bvCategories.map(rec => rec.category).join('|');
    let headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        TLTSID: get(session, 'TLTSID', ''),
        W2A: get(session, 'W2A', ''),
        ucid: get(user, 'ucid', ''),
        rid: get(user, 'rid', 'US'),
      }),
      PlacementType: 'jfy',
      ucid: get(user, 'ucid', ''),
      rid: get(user, 'rid', 'US'),
    };
    if (categoryIds.length > 0) {
      headers = { ...headers, categoryIds };
    }

    return requestApi
      .get(NMConfig.API_ECMRECOMMENDATIONS, { headers })
      .then(successResponse =>
        dispatch({
          type: RESOLVED_ECMRECOMMENDATIONS,
          payload: successResponse.data,
        })
      )
      .catch(() => dispatch({ type: REJECTED_ECMRECOMMENDATIONS }));
  };
}
export function clearEcmRecommendations() {
  return dispatch => dispatch({ type: RESET_ECMRECOMMENDATIONS });
}
export function setSelectedIndex(selectedIndex) {
  return dispatch => {
    dispatch({ type: SET_SELECTED_ITEM_INDEX, payload: selectedIndex });
  };
}

export function resolveBVRecommendations(catIds) {
  return dispatch =>
    dispatch({ type: RESOLVE_BV_RECOMMENDATIONS, payload: catIds });
}

export function rejectBVRecommendations(err) {
  return dispatch =>
    dispatch({ type: REJECT_BV_RECOMMENDATIONS, payload: err.message });
}
