import httpWithLogging from 'universal/http-client';
import { isMobile } from 'client-utils/utilities-page';
import get from 'lodash/get';

export const LOADING_ECMSTOREEVENTS = 'LOADING_ECMSTOREEVENTS';
export const RESOLVED_ECMSTOREEVENTS = 'RESOLVED_ECMSTOREEVENTS';
export const REJECTED_ECMSTOREEVENTS = 'REJECTED_ECMSTOREEVENTS';
export const RESET_ECMSTOREEVENTS = 'RESET_ECMSTOREEVENTS';

const hasStoreParams = storeContext =>
  (storeContext.lat && storeContext.long) || storeContext.dtFavoriteStore;

const getMobileStoreParams = storeContext => {
  let qs;
  if (storeContext.lat.length > 0 && storeContext.long.length > 0) {
    qs = `brandCode=bg&latitude=${storeContext.lat}&longitude=${storeContext.long}`;
  } else if (storeContext.dtFavoriteStore.length > 0) {
    qs = `storeId=${storeContext.dtFavoriteStore}`;
  }
  return qs;
};

const getDesktopStoreParams = storeContext => {
  let qs;
  if (storeContext.dtFavoriteStore.length > 0) {
    qs = `storeId=${storeContext.dtFavoriteStore}`;
  } else if (storeContext.lat.length > 0 && storeContext.long.length > 0) {
    qs = `brandCode=bg&latitude=${storeContext.lat}&longitude=${storeContext.long}`;
  }
  return qs;
};

const getStoreParams = storeContext => {
  if (isMobile()) {
    return getMobileStoreParams(storeContext);
  } else {
    return getDesktopStoreParams(storeContext);
  }
};

export function getEcmStoreEvents() {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_ECMSTOREEVENTS });
    const state = getState();
    const context = state.api && state.api.requestContext;
    const lat = get(context, 'akamaiEdgescape.lat', '');
    const long = get(context, 'akamaiEdgescape.long', '');
    const dtFavoriteStore = get(context, 'dtFavoriteStore', '');
    const requestApi = httpWithLogging(state);

    if (hasStoreParams({ dtFavoriteStore, lat, long })) {
      return requestApi
        .get(
          `${NMConfig.API_STORE}?${getStoreParams({
            dtFavoriteStore,
            lat,
            long,
          })}`
        )
        .then(successResponse =>
          dispatch({
            type: RESOLVED_ECMSTOREEVENTS,
            payload: successResponse.data,
          })
        )
        .catch(() => dispatch({ type: REJECTED_ECMSTOREEVENTS }));
    } else {
      return dispatch({ type: RESOLVED_ECMSTOREEVENTS, payload: [] });
    }
  };
}

export function clearEcmStoreEvents() {
  return dispatch => dispatch({ type: RESET_ECMSTOREEVENTS });
}
