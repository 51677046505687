import React, { Fragment } from 'react';
import { array, bool, string } from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import ContentItem from '../contentItem/contentItem';

/**
 * @function Content
 * @param {Array} props.cmsContent
 * @param {String} props.placement
 * @param {Boolean} props.sessionExpired
 * @param {Boolean} props.validPromo
 * @returns {React.ReactElement}
 */
const Content = ({
  cmsContent = [],
  placement = 'Main',
  sessionExpired,
  validPromo,
}) => {
  return cmsContent.length ? (
    <Fragment>
      {cmsContent.map((item, index) => (
        <ContentItem
          cmsContentItem={item}
          key={index}
          placement={placement}
          validPromo={validPromo}
          sessionExpired={sessionExpired}
        />
      ))}
    </Fragment>
  ) : null;
};

Content.propTypes = {
  cmsContent: array,
  placement: string,
  sessionExpired: bool,
  validPromo: bool,
};

const mapStateToProps = state => ({
  cmsContent: get(state, 'cms.entries'),
  validPromo: get(
    state,
    'navigation.segmentSailo.eliteCustomer.validPromo',
    false,
  ),
  sessionExpired: get(state, 'user.sessionExpired', true),
});

export default connect(mapStateToProps)(Content);
