export const US_COUNTRY_CODE = 'US';
export const PRECIOUS_JEWELRY_PAYPAL_MESSAGE =
  'Item may not be purchased with Paypal';
export const PERISHABLE_MESSAGE =
  'To ensure freshness, perishables cannot be delivered on Sat, Sun, Mon, or Holidays. Please select a date on which you would like to receive this item by clicking on the calendar icon below.';
export const ASSISTANCE_REQUIRED = 'ASSISTANCE REQUIRED';
export const MONOGRAM_HELPLINE =
  'This item requires special assistance, to order call';
export const MONOGRAM_HELPLINE_MOBILE =
  'This item requires special assistance, call to place an order';
export const MONOGRAM_HELPLINE_NUMBER = '1-888-774-2424';
export const REQUIRED_MONOGRAM = 'R';
export const SUPPRESSED_MONOGRAM = 'N';
export const FREE_MONOGRAM = 'F';
export const MONOGRAM_HEADER = 'Personalize Your Item';
export const PDP_PAGINATION_LOCAL_STORAGE_LIMIT = 100;
export const OOS_ERROR_MESSAGE =
  'Sorry, your selection is currently out of stock. Please try another color or size.';
export const PDP_PAGINATION_LOOK_AHEAD_THRESHOLD = 3;
export const MONOGRAM_PREVIEW_HELPTEXT =
  'Initials may be reordered to reflect traditional monogram style. Capitalization may also be altered by design style.';
export const MONOGRAM_HELP_TEXT =
  'Enter your initials in order of first, middle, and last name.';
export const DISCONTINUED_CODES_YR = 'YR';
export const NON_DISCONTINUED_CODE = 'N';
export const DISCONTINUED_CODE_V = 'V';
export const DISCONTINUED_CODE_W = 'W';
export const DISCONTINUED_CODE_Q = 'Q';
export const DISCONTINUED_CODE_Y = 'Y';
export const DISCONTINUED_CODE_T = 'T';
export const DISCONTINUED_CODE_I = 'I';
export const DISCONTINUED_CODE_R = 'R';
export const PROMOTION_TYPE_113 = '113';
export const PAGE_SIZE = 5;
export const PDP_BG_CITY = 'NewYork';
export const LIMITED_INVENTORY = 'Limited Inventory';
export const PERSONALIZATION_OPTIONAL_TEXT =
  'Add personalization (optional) — Free';
export const PERSONALIZATION_REQUIRED = 'personalization required';
export const PERSONALIZATION_REQUIRED_TEXT =
  'Personalized items may not be ordered blank.';
export const BADGES_PRIORITY_ORDER = [
  'GIFT CARD',
  "LINDA'S AT BG",
  'PRE-ORDER',
  'BACK-ORDER',
  'NEW ARRIVAL',
  'EXCLUSIVELY BG',
];
export const GWP_TEXT = 'GIFT WITH PURCHASE ITEM';
export const OOS_TEXT = 'OUT OF STOCK';
export const BEAUTY_SPECIALIST = 'BEAUTY SPECIALIST';
export const GIFT_CARD = 'gift card';
export const PREVIOUS_BUTTON = 'Previous Button';
export const NEXT_BUTTON = 'Next Button';
export const RIGHT_ARROW = 'Right Arrow';
export const WEAR_IT_WITH = 'WEAR IT WITH';
export const GO_TO_ITEM_PAGE = 'Go to Item Page';
export const WHATS_INCLUCED_TITLE = 'WHAT’S INCLUDED';
export const ITEM_UNAVAILABLE = 'THIS ITEM IS NOT AVAILABLE.';
export const STORE_NUMBERS_AVAILABLE_FOR_SSD = ['63', '64'];
export const ITEM_NOT_AVAILABLE = 'Item Not Available';
export const SELECT_PAYMENT = 'SELECT PAYMENT';
export const CHECKOUT_FASTER = 'Buying one item? Checkout faster.';
export const BUCKLE_FINISH = 'buckleFinish';
