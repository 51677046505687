import React from 'react';
import './accordion.scss';

const Accordion = (props) => (
  <div className="accordion-wrapper">
    <ul className="accordion-list">
      {props.children}
    </ul>
  </div>
)

export default Accordion;
