import React from 'react';
import { connect } from 'react-redux';
import Button from 'shared/components/Button/button';
import { closeModal } from 'shared/components/Modal/actions';
import './modalWithoutHeader.scss';

const defaultMessage =
  'Sorry, something went wrong on our end. Please try again.';
const ModalWithoutHeader = ({
  closeModal = () => {},
  message,
  className,
  image,
}) => (
  <div className="modal-without-header">
    <p>{message}</p>
    <img src={image} alt="" />
    <Button className={className} value="OK" onClick={() => closeModal()} />
  </div>
);

const mapDispatchToProps = { closeModal };
const mapStateToProps = (state, ownProps) => ({
  message: state.modal.message || ownProps.message || defaultMessage,
  className: state.modal.className || ownProps.className || '',
  image: state.modal.image || ownProps.image || '',
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalWithoutHeader);
