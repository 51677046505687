import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import closeIcon from 'assets/images/icons-close.svg';
import incircleImage from 'assets/images/incircle-image.svg';
import PointCards from './PointCards/pointCards';
import YourPoints from './YourPoints/yourPoints';
import YourBenefits from './YourBenefits/yourBenefits';
import CallToActions from './CallToActions/callToActions';
import { openProfilePanel } from './miniHub-actions';
import { getLoyalityTier, hasLoyalityBenefits } from './helpers';
import { MiniHubTexts } from './constants';
import './miniHub.scss';

const MiniHub = ({
  isOpenMiniHub,
  handleMiniHubToggle,
  openProfilePanel,
  tierCode,
  pointBalance,
  totalPointsToNextReward,
  error,
  allPointCards,
  allPointCardsError,
}) => {
  useEffect(() => {
    if (isOpenMiniHub) {
      openProfilePanel();
    }
  }, [isOpenMiniHub]);

  const renderMiniHubPointCards = () => {
    if (allPointCardsError) {
      return null;
    }

    const pointCardsAmount = allPointCards.reduce((acc, pointCard) => {
      return acc + parseFloat(pointCard.last_ptd_balance);
    }, 0);

    return (
      <div className="mini-hub-content__incircle-content__item">
        <PointCards pointCardsAmount={pointCardsAmount} />
      </div>
    );
  };

  const renderMiniHubContent = () => {
    if (error) {
      return (
        <Fragment>
          <div className="mini-hub-content__incircle-header">
            <div className="mini-hub-content__incircle-header__body">
              <img src={incircleImage} alt="InCircle" alr="InCircle" />
              <p>{MiniHubTexts.error}</p>
            </div>
          </div>
          <div className="mini-hub-content__incircle-content">
            {renderMiniHubPointCards()}
            <div className="mini-hub-content__incircle-content__item">
              <CallToActions />
            </div>
          </div>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <div className="mini-hub-content__incircle-header">
          <div className="mini-hub-content__incircle-header__body">
            <img src={incircleImage} alt="InCircle" alr="InCircle" />
            <p>
              {MiniHubTexts.statusTitle} {getLoyalityTier(tierCode, false)}
            </p>
          </div>
        </div>
        <div className="mini-hub-content__incircle-content">
          {renderMiniHubPointCards()}
          <div className="mini-hub-content__incircle-content__item">
            <YourPoints
              pointBalance={pointBalance}
              totalPointsToNextReward={totalPointsToNextReward}
            />
          </div>
          {hasLoyalityBenefits(tierCode) && (
            <div className="mini-hub-content__incircle-content__item">
              <YourBenefits />
            </div>
          )}
          <div className="mini-hub-content__incircle-content__item">
            <CallToActions />
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div
        className={classnames('mini-hub-content desktop-only', {
          'mini-hub-content--open': isOpenMiniHub,
        })}
      >
        <div className="mini-hub-content__top">
          <span onClick={handleMiniHubToggle} onKeyDown={handleMiniHubToggle}>
            <img src={closeIcon} alt="Close" alr="Close" />
          </span>
        </div>
        {renderMiniHubContent()}
      </div>
      <div
        className={classnames('mini-hub-overlay desktop-only', {
          'mini-hub-overlay--open': isOpenMiniHub,
        })}
        onClick={handleMiniHubToggle}
        onKeyDown={handleMiniHubToggle}
      />
    </Fragment>
  );
};

const mapStateToProps = state => ({
  tierCode: state.miniHub.data.tierCode,
  pointBalance: state.miniHub.data.pointBalance,
  totalPointsToNextReward: state.miniHub.data.totalPointsToNextReward,
  error: state.miniHub.error,
  allPointCards: state.rewards.items.point,
  allPointCardsError: state.rewards.error,
});

const mapDispatchToProps = {
  openProfilePanel,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MiniHub);
