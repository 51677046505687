import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import EditButton from '../EditButton/EditButton';
import {
  cancelButtonText,
  EDIT_SECTION_NAME,
  editButtonText,
} from '../../../constants';
import {
  closeAccountDetailsEdit,
  editAccountDetails,
  openAccountDetailsEdit,
} from '../actions';
import { getEditState } from '../helpers';
import FormikTextField from '../../../../common/Forms/FormikTextField/FormikTextField';
import SubmitButton from '../../../../common/Forms/FormButtons/SubmitButton/SubmitButton';

const SECTION = EDIT_SECTION_NAME;

function DumbNameField(props) {
  const {
    firstName,
    lastName,
    isEditOpened,
    isEditDisabled,
    isLoading,
    error,
    isGreyCssFix,
  } = props;
  const value = [firstName, lastName].filter(item => !!item).join(' ');

  const handleEditClick = () => {
    props.isEditOpened
      ? props.closeAccountDetailsEdit()
      : props.openAccountDetailsEdit(SECTION);
  };

  const initialFormValue = {
    firstName,
    lastName,
  };

  const validateForm = values => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = 'Enter your first name.';
    }
    if (!values.lastName) {
      errors.lastName = 'Enter your last name.';
    }
    return errors;
  };

  const submitForm = values => {
    if (values.firstName !== firstName || values.lastName !== lastName) {
      props.editAccountDetails(values);
    } else {
      props.closeAccountDetailsEdit();
    }
  };

  return (
    <div className="account-details-page__field-wrapper" aria-live="polite">
      <div className="account-details-page__field-header">
        <h3 className="account-details-page__field-label">Name</h3>
        <div className="account-details-page__field-edit">
          <EditButton
            editText={editButtonText}
            cancelText={cancelButtonText}
            isEditing={isEditOpened}
            isDisabled={isEditDisabled || isLoading}
            onButtonClick={handleEditClick}
            isGreyCssFix={isGreyCssFix}
          />
        </div>
      </div>
      <div className="account-details-page__field-content">
        {isEditOpened ? (
          <Formik
            initialValues={initialFormValue}
            validate={validateForm}
            onSubmit={submitForm}
          >
            {() => (
              <Form>
                <div className="account-details-page__edit-form-fields-container">
                  <FormikTextField
                    name="firstName"
                    id="first-name-field"
                    placeholder="First name"
                    maxLength={40}
                    fontSize
                  />
                  <FormikTextField
                    name="lastName"
                    id="last-name-field"
                    placeholder="Last name"
                    maxLength={40}
                    fontSize
                  />
                </div>
                {error && (
                  <p className="account-details-page__edit-error">{error}</p>
                )}
                <div className="account-details-page__edit-submit">
                  <SubmitButton
                    isSubmitting={isLoading}
                    type="submit"
                    caption="Save"
                    additionalClasses="account-details-page__edit-submit-btn"
                  />
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <p className="account-details-page__field-value">{value}</p>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  firstName: get(state, 'myaccount.myAccountDetails.fields.firstName', null),
  lastName: get(state, 'myaccount.myAccountDetails.fields.lastName', null),
  isLoading: get(state, 'api.update_basic_attributes.loading', false),
  error: get(
    state,
    'myaccount.myAccountDetails.updateBasicAttributesError',
    null,
  ),
  ...getEditState(state, SECTION),
  isGreyCssFix: get(state, 'toggles.GREYCOLOR_CSS_FIX', false),
});

const mapDispatchToProps = {
  openAccountDetailsEdit,
  closeAccountDetailsEdit,
  editAccountDetails,
};

const NameField = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbNameField);

export default NameField;
