import { localStorage } from 'window-or-global';
import sumBy from 'lodash/sumBy';
import NO_IMAGE_AVAILABLE from 'assets/images/no-image.jpeg';

export const internationalMiniCartHtml = intlCartName => {
  const localInternationalCart = (() => {
    const localInternationalCartData = localStorage.getItem(intlCartName);
    return localInternationalCartData
      ? JSON.parse(localInternationalCartData)
      : [];
  })();
  let cartHtmlData = '';
  let totalCartPrice = 0;
  let totalCartQty = 0;
  let currencyCode = '';
  if (localInternationalCart && localInternationalCart.length > 0) {
    totalCartPrice = sumBy(localInternationalCart, item =>
      Number(item.price.retailPrice * item.productQty),
    );
    totalCartQty = sumBy(localInternationalCart, item =>
      Number(item.productQty),
    );
    currencyCode = localInternationalCart[0].price.currencyCode;
    localInternationalCart
      .reverse()
      .slice(0, 3)
      .map(cartItem => {
        cartHtmlData += `<ul class="header-drop-down-content cart-list">
      <li>
      <div class="content">
      <a href="${cartItem.productUrl}">
          <img width="75" src="${cartItem.images.small.url ||
            cartItem.images.thumbnail.url ||
            cartItem.images.dynamic.url ||
            NO_IMAGE_AVAILABLE}" alt="${cartItem.description}" /> </a>
      <div class="description">
      <h5>${cartItem.name}</h5>
			<h5>${cartItem.description}</h5>
			<p>Color: ${cartItem.color}</p>
			<p>Size: ${cartItem.size}</p>
			<input type="hidden" class="prodQty" value="1" />
			<input type="hidden" class="product_id" value="_" />
			<p> Qty: ${cartItem.productQty} </p>
      ${
        cartItem.stockStatusMessage
          ? `<p>${cartItem.stockStatusMessage}</p>`
          : ''
      }
      ${
        cartItem.shippingStatusMessage
          ? `<p>${cartItem.shippingStatusMessage}</p>`
          : ''
      }
			<p>&nbsp;</p>
      <span class="price"><span class="finalPrice OneLinkNoTx price-inline"> ${
        cartItem.price.adornments
          ? `<p class="m-minicart-price"> <span class="del mute "> <span class="price-adorn-label">${
              cartItem.price.adornments[0].label
            }:</span> <span class="price-adorn-value">${
              cartItem.price.currencyCode
            } ${cartItem.price.adornments[0].price *
              cartItem.productQty}</span><br> </span> <span class="price-adornment-highlight "> <span class="price-adorn-label">${
              cartItem.price.adornments[1].label
            }:</span> <span class="price-adorn-value">${
              cartItem.price.currencyCode
            } ${cartItem.price.adornments[1].price *
              cartItem.productQty}</span> </span> </p>`
          : `<p class="m-minicart-price">
        <span class="price-adorn-value"> ${
          cartItem.price.currencyCode
        } ${cartItem.price.retailPrice * cartItem.productQty} </span> </p>`
      }
        </span>
      </span>
      </div>
      </div>
      </li>
      <ul>`;
        return true;
      });
  }
  return `${
    localInternationalCart && localInternationalCart.length > 0
      ? `<div class= "miniCartOverlay">
	<input value=${totalCartQty} type= "hidden" id="miniCartSize" />
	<input value="0" type="hidden" id="sflCount" />
	<div id="cartlinkspandivgutter" class="checkout_bagbox header-drop-down-bgo custom_cart layer">
		<input value=${totalCartQty} type="hidden" id="miniCartSize" />
		<input value="0" type="hidden" id="sflCount" />
		<div class="lr-container">
			<div class="min-cart-add-bg">Added</div>
			<div class="shopping-link"> <span class="min-cart-add-bg">Bag</span><span aria-hidden="true" class="miniCart-count">${totalCartQty}</span> </div>
		</div>
		${cartHtmlData}
		<div class="lr-container subtotalStyle">
			<div>Subtotal</div>
			<div>${currencyCode} ${totalCartPrice}</div>
		</div>
		<div>
        <a href="/international/cart" class="checkout_btn mr-tp"><span>Checkout</span></a> 
        <a class="view-bag" href="/international/cart">View Bag</a> 
    </div>
    </div>
    </div>
<div class="minicart-overlay-shadow"></div>`
      : ''
  }`;
};
