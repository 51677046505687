import React from 'react';
import { formatNumber } from 'client-utils/utilities-number';
import { getMiniCartImage } from '../../Util/product-cloudinary';

const generateSrcSetString = srcSetArray => {
  if (!srcSetArray || srcSetArray.length === 0) {
    return null;
  }

  return srcSetArray.map(item => `${item.src} ${item.rule}`).join(', ');
};

const MiniCartItem = ({ data }) => {
  const imageData = getMiniCartImage(data?.imageUrl)?.desktop;
  const alt = `${data?.productDetails?.designerName || ''} ${data
    ?.productDetails?.displayName || ''}`;
  return (
    <div className="minicart-dt__item">
      <div className="minicart-dt__item__image">
        <a href={data?.productDetails?.canonicalUrl}>
          {imageData?.srcSet && imageData?.imageSrc ? (
            <picture>
              <img
                src={`${imageData?.imageSrc}`}
                srcSet={generateSrcSetString(imageData?.srcSet)}
                alt={alt}
              />
            </picture>
          ) : (
            <img src={data?.imageUrl} alt="alt" />
          )}
        </a>
      </div>
      <div className="minicart-dt__item__description">
        <p
          className="minicart-dt__item__description__designer"
          dangerouslySetInnerHTML={{
            __html: data?.productDetails?.designerName,
          }}
        />
        <p
          dangerouslySetInnerHTML={{
            __html: data?.productDetails?.displayName,
          }}
        />
        {data?.currentSku?.colorName && (
          <p>Color: {data.currentSku.colorName}</p>
        )}
        {data?.currentSku?.sizeName && <p>Size: {data.currentSku.sizeName}</p>}
        <p>Qty: {data?.quantity}</p>
        <p className="minicart-dt__item__description__price">
          ${formatNumber(data?.productDetails?.retailPrice)}
        </p>
      </div>
    </div>
  );
};

export default MiniCartItem;
