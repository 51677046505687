import React from 'react';
import classnames from 'classnames';
import {
  PD_IN_STOCK,
  PD_OUT_OF_STOCK,
} from '../../../../../../../../bgo-ui-pdp/src/client/components/wishlist/constants';
import { ITEM_NOT_AVAILABLE } from '../../../constants';
import './WishlistStockDetails.scss';

const WishlistStockDetails = ({ prodAvailable }) => {
  const isOutofStock =
    prodAvailable.stockLevel === 0 &&
    prodAvailable.stockStatusMessage === ITEM_NOT_AVAILABLE;
  return (
    <p
      className={classnames(
        'stock-details',
        isOutofStock && 'stock-details_redColor',
      )}
    >
      {prodAvailable.sellable && prodAvailable.stockLevel >= 6 && PD_IN_STOCK}
      {prodAvailable.stockLevel < 6 &&
        prodAvailable.stockLevel !== 0 &&
        `Limited Inventory - Only ${prodAvailable.stockLevel} Left `}
      {isOutofStock && PD_OUT_OF_STOCK}
    </p>
  );
};

export default WishlistStockDetails;
