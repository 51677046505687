import {
  APPLE_PAY_ADDING_TO_CART,
  APPLE_PAY_ADD_TO_CART_FAILED,
  APPLE_PAY_ADD_TO_CART_SUCCESS,
  APPLE_PAY_SESSION_FAILED,
  APPLE_PAY_SESSION_SUCCESS,
  APPLE_PAY_SESSION_USER_DATA,
  CREATE_APPLE_PAY_SESSION,
  RESOLVED_APPLE_ELIGIBLE,
} from '../actions';

const applePayReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_APPLE_PAY_SESSION:
      return {
        ...state,
        paymentLoading: true,
      };
    case APPLE_PAY_SESSION_SUCCESS:
      return {
        ...state,
        applePaymentRequest: action.payload,
        paymentLoading: false,
      };
    case APPLE_PAY_SESSION_FAILED:
      return {
        ...state,
        paymentLoading: false,
      };
    case APPLE_PAY_SESSION_USER_DATA:
      return {
        ...state,
        appleUserData: action.payload,
      };
    case RESOLVED_APPLE_ELIGIBLE:
      return {
        ...state,
        isApplePayEligible: action.payload,
      };
    case APPLE_PAY_ADDING_TO_CART:
      return {
        ...state,
        paymentLoading: true,
      };
    case APPLE_PAY_ADD_TO_CART_SUCCESS:
      return {
        ...state,
        paymentLoading: false,
      };
    case APPLE_PAY_ADD_TO_CART_FAILED:
      return {
        ...state,
        paymentLoading: false,
      };

    default:
      return state;
  }
};

export default applePayReducer;
