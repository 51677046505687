import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import NoRewards from '../NoRewards/NoRewards';
import RewardsList from '../RewardsList/RewardsList';
import './InCircleRewards.scss';

const InCircleRewards = ({ myAccountInCircleRewards }) => {
  return (
    <div className="incircle-rewards">
      {isEmpty(myAccountInCircleRewards) ? (
        <NoRewards
          contentId="MYREWARDS-INCIRLE-DEFAULT"
          ctaId="MYREWARDS-INCIRLE-DEFAULT-CTA"
        />
      ) : (
        <RewardsList cards={myAccountInCircleRewards} isIncircle />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  myAccountInCircleRewards: get(state, 'rewards.items.point', true),
});

export default connect(mapStateToProps)(InCircleRewards);
