import get from 'lodash/get';

export const MOBILE_DEVICE = 'Mobile';
export const TABLET_DEVICE = 'Tablet';
export const DESKTOP_DEVICE = 'Desktop';

const getSpacing = (deviceType, spacingValue, layoutWidth = false) => {
  const value = spacingValue || '';

  if (value === '') {
    return '';
  }
  if (deviceType === MOBILE_DEVICE) {
    return layoutWidth
      ? `${layoutWidth}-${value}-mobile`
      : `spacing-${value}-mobile`;
  }
  if (deviceType === TABLET_DEVICE) {
    return layoutWidth
      ? `${layoutWidth}-${value}-tablet`
      : `spacing-${value}-tablet`;
  }

  return layoutWidth
    ? `${layoutWidth}-${value}-desktop`
    : `spacing-${value}-desktop`;
};

export const getLayoutSpacing = (
  cmsContentFields,
  isMobilePhone,
  isTablet,
  isDesktop,
) => {
  const deviceType =
    (isMobilePhone && MOBILE_DEVICE) ||
    (isTablet && TABLET_DEVICE) ||
    DESKTOP_DEVICE;
  const topSpacingDesktop = get(cmsContentFields, 'topSpacingDesktop', '');
  const topSpacingTablet = get(cmsContentFields, 'topSpacingTablet', '');
  const topSpacingMobile = get(cmsContentFields, 'topSpacingMobile', '');
  const topSpacingType =
    (isMobilePhone && topSpacingMobile) ||
    (isTablet && topSpacingTablet) ||
    (isDesktop && topSpacingDesktop);
  const topSpacingStyles = getSpacing(deviceType, topSpacingType);
  const topStyles = topSpacingStyles ? `${topSpacingStyles}-top` : '';
  return { topStyles };
};

const getHoriSpacing = (deviceType, spacingValue, layoutWidth) => {
  const value = spacingValue || '';
  const widthValue = layoutWidth || '';
  if (widthValue === '' || value === '') {
    return '';
  }
  if (deviceType === MOBILE_DEVICE) {
    return `horizontalSpacing-${widthValue}-${value}-mobile`;
  }
  if (deviceType === TABLET_DEVICE) {
    return `horizontalSpacing-${widthValue}-${value}-tablet`;
  }
  return `horizontalSpacing-${widthValue}-${value}-desktop`;
};

export const getHorizontalSpacing = (
  cmsContentFields,
  isMobilePhone,
  isTablet,
  isDesktop,
) => {
  const deviceType =
    (isMobilePhone && MOBILE_DEVICE) ||
    (isTablet && TABLET_DEVICE) ||
    DESKTOP_DEVICE;
  const horizontalSpacingDesktop = cmsContentFields?.horizontalSpacingDesktop;
  const horizontalSpacingTablet = cmsContentFields?.horizontalSpacingTablet;
  const horizontalSpacingMobile = cmsContentFields?.horizontalSpacingMobile;
  const horizontalSpacingType =
    (isMobilePhone && horizontalSpacingMobile) ||
    (isTablet && horizontalSpacingTablet) ||
    (isDesktop && horizontalSpacingDesktop);
  const widthAttribute = isMobilePhone
    ? cmsContentFields.mobileWidth
    : cmsContentFields.desktopWidth;
  const horizontalSpacingStyles = getHoriSpacing(
    deviceType,
    horizontalSpacingType,
    widthAttribute,
  );
  const horizontalStyles = horizontalSpacingStyles
    ? `${horizontalSpacingStyles}`
    : '';
  return { horizontalStyles };
};

const getVertSpacing = (deviceType, spacingValue) => {
  const value = spacingValue || '';

  if (value === '') {
    return '';
  }
  if (deviceType === MOBILE_DEVICE) {
    return `verticalSpacing-${value}-mobile`;
  }
  if (deviceType === TABLET_DEVICE) {
    return `verticalSpacing-${value}-tablet`;
  }

  return `verticalSpacing-${value}-desktop`;
};

export const getVerticalSpacing = (
  cmsContentFields,
  isMobilePhone,
  isTablet,
  isDesktop,
) => {
  const deviceType =
    (isMobilePhone && MOBILE_DEVICE) ||
    (isTablet && TABLET_DEVICE) ||
    DESKTOP_DEVICE;
  const verticalSpacingDesktop = cmsContentFields?.verticalSpacingDesktop;
  const verticalSpacingTablet = cmsContentFields?.verticalSpacingTablet;
  const verticalSpacingMobile = cmsContentFields?.verticalSpacingMobile;
  const verticalSpacingType =
    (isMobilePhone && verticalSpacingMobile) ||
    (isTablet && verticalSpacingTablet) ||
    (isDesktop && verticalSpacingDesktop);
  const verticalSpacingStyles = getVertSpacing(deviceType, verticalSpacingType);
  const verticalStyles = verticalSpacingStyles
    ? `${verticalSpacingStyles}-bottom`
    : '';
  return { verticalStyles };
};
