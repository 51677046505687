import React from 'react';
import classNames from 'classnames';
import {
  BodyB1,
  EyeBrow1,
  EyeBrow3,
} from '@bgo-ui/common/Styleguide/Typography';
import { getLinkDomain } from 'client-utils/utilities-navigation';
import './aToZGrid.scss';

const createGridItem = val => {
  const styles = classNames({
    'view-all': val.id.toLowerCase() === 'view all',
    inactive: !val.url,
  });
  const domain = getLinkDomain();
  const gridElement = val.url ? (
    <a className={styles} href={`${domain}${val.url}`}>
      {' '}
      {val.id.toLowerCase() === 'view all' ? (
        <EyeBrow1>{val.name}</EyeBrow1>
      ) : (
        <EyeBrow3>{val.name}</EyeBrow3>
      )}{' '}
    </a>
  ) : (
    <span className={styles}>
      {' '}
      {val.id.toLowerCase() === 'view all' ? (
        <EyeBrow1>{val.name}</EyeBrow1>
      ) : (
        <EyeBrow3>{val.name}</EyeBrow3>
      )}{' '}
    </span>
  );

  return (
    <span
      className={
        val.id.toLowerCase() === 'view all'
          ? 'a-to-z-data a-to-z-data-block'
          : `a-to-z-data ${  val.id}`
      }
      key={val.name}
    >
      {gridElement}
    </span>
  );
};

const AtoZGrid = ({ dataToDisplay, titleToDisplay }) => {
  const columns = 7;
  const rows = Math.ceil(dataToDisplay.length / columns);
  const rowArr = [];
  let startIndex = 0;
  let endIndex = columns;
  for (let atozRow = 0; atozRow < rows; atozRow += 1) {
    const rowData = dataToDisplay.slice(startIndex, endIndex);
    startIndex = endIndex;
    endIndex += columns;
    rowArr.push(<div key={atozRow}>{rowData.map(createGridItem)}</div>);
  }
  return (
    <div>
      <p className="designersByAlphaText">
        <BodyB1>{titleToDisplay}</BodyB1>
      </p>
      <div className="atoZGrid">{rowArr}</div>
    </div>
  );
};

export default AtoZGrid;
