import React from 'react';
import AppMyAccountWrapper from './app-my-account-wrapper';
import AccountDetailsPage from '../../myaccount/pages/AccountDetailsPage/AccountDetailsPage';

export default function AppAccountDetailsWrapper() {
  return (
    <AppMyAccountWrapper>
      <AccountDetailsPage />
    </AppMyAccountWrapper>
  );
}
