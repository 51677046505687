import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import {
  PRECIOUS_JEWELRY_PAYPAL_MESSAGE,
  REQUIRED_MONOGRAM,
  BUCKLE_FINISH,
} from 'pdp/constants';
import ProductOptionSet from 'productpage/components/ProductOptionSet/productOptionSet';
import ProductQuantity from 'productpage/components/ProductQuantity/productQuantity';
import BopsButton from 'productpage/components/BopsButton/bopsButton';
import GiftNow from 'productpage/components/GiftNow/giftNow';
import BossMessage from 'productpage/components/BossMessage/bossMessage';
import AddToBagButton from 'productpage/components/AddToBagButton/addToBagButton';
import SkuStatusMessages from 'productpage/components/SkuStatusMessages/skuStatusMessages';
import PerishableMessage from 'productpage/components/Perishables/perishableMessage';
import DropDownComponent from 'pdp/components/ProductPage/optimizationcomponents/DropDownComponent/dropDownComponent';
import Personalization from 'productpage/components/Monogram/personalization';
import { getSelectedOptionMedia } from 'productpage/selectors/getMedia';
import getSelectedColorIndex from 'productpage/selectors/getSelectedColorIndex';
import getSelectedBuckleFinishIndex from 'productpage/selectors/getSelectedBuckleFinishIndex';
import getColorSkus from 'productpage/selectors/getColorSkus';
import getSizeSkus from 'productpage/selectors/getSizeSkus';
import {
  setHoveredIndex,
  setSelectedIndex,
  setReplenishInterval,
  openReplenishment,
} from 'productpage/actions';
import {
  getColorOptions,
  getSizeOptions,
} from 'productpage/selectors/getOptions';
import getSelectedSku from 'productpage/selectors/getSelectedSku';
import Loadable from 'react-loadable';
import PersonalizeItemButton from '../PersonalizeItemButton/PersonalizeItemButton';
import SizeGuide from '../SizeGuide/SizeGuide';

const PerishableCalendar = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'perishable-calendar' */ 'productpage/components/Perishables/perishableCalendar'
    ),
  loading: () => false,
});

const CheckoutOptionInfo = props => {
  const { product, boss } = props;
  const behaviorCode = get(product, 'customization.behaviorCode');
  const productState = product;
  const colorOptions = getColorOptions(productState);
  const sizeOptions = getSizeOptions(productState);
  const selectedColorIndex = getSelectedColorIndex(productState);
  const selectedBuckleFinishIndex = getSelectedBuckleFinishIndex(productState);
  const selectedOptionMedia = getSelectedOptionMedia(productState);
  const selectedSizeIndex = get(productState, 'options.selectedSizeIndex', -1);
  const colorSkus = getColorSkus(productState, selectedSizeIndex);
  const sizeSkus = getSizeSkus(productState, selectedColorIndex);
  const noAvailableSizeSelected = selectedSizeIndex > 0 && isEmpty(colorSkus);
  const appendKey = 'productButtons';

  const selectedSku = getSelectedSku(
    { colorSkus, sizeSkus },
    { colorOptions, sizeOptions },
    { selectedColorIndex, selectedSizeIndex },
  );
  const hoveredColorIndex = get(productState, 'options.hoveredColorIndex', -1);
  const hoveredSizeIndex = get(productState, 'options.hoveredSizeIndex', -1);
  const hoveredBuckleIndex = get(
    productState,
    'options.hoveredBuckleFinishIndex',
    -1,
  );
  const { details = {} } = product;
  const { parentheticalCharge = {} } = details;

  const isPreciousJewelry = () => {
    return get(product, 'details.preciousJewelryItem', false);
  };

  const isRequiredMonogram = () => {
    return behaviorCode === REQUIRED_MONOGRAM;
  };
  const isPersonalized = () => {
    return product.isPersonalizationSelected;
  };

  const isCustomizationOptionTypePDP = () => {
    let isCustomOptionPDP = false;
    if (product.customization && product.customization.customizationOptions) {
      isCustomOptionPDP = true;
      product.customization.customizationOptions.forEach(element => {
        if (element.type !== 'PDP') {
          isCustomOptionPDP = false;
        }
      });
    }
    return isCustomOptionPDP;
  };

  const isRequiredMonogramNotCustomized = () => {
    return (
      product.customizationSupported &&
      isRequiredMonogram() &&
      !isPersonalized()
    );
  };

  const shouldShowPersonalizeSection = () => {
    return product.customizationSupported && !isRequiredMonogramNotCustomized();
  };

  const addToBagButton = (
    <AddToBagButton
      productId={product.id}
      selectedSku={selectedSku}
      customizationOptionTypePDP={isCustomizationOptionTypePDP()}
      skuMediaUrl={get(selectedOptionMedia, 'main.thumbnail.url', '')}
    />
  );

  const giftNowButton = <GiftNow product={product} selectedSku={selectedSku} />;

  const isColorSwatchProduct = options => {
    let isColorSwatch = false;

    for (let i = 0; i < options.length; i++) {
      if (options[i].url !== undefined) {
        isColorSwatch = true;
        break;
      }
    }

    return isColorSwatch;
  };

  const getBuckleFinishChoices = () => {
    const customizationOptions = [];
    productState.customization &&
      productState.customization.customizationOptions &&
      productState.customization.customizationOptions.forEach(element => {
        customizationOptions.push(element.choices);
      });
    return flatten(customizationOptions);
  };

  const getFrequencyChoices = () => {
    const frequencyOptions = [
      {
        name: 'Purchase this time only',
        key: '0',
        defaultColor: false,
        displaySkuImg: false,
      },
      {
        name: 'Every 30 Days',
        key: '30',
        defaultColor: false,
        displaySkuImg: false,
        value: 30,
      },
      {
        name: 'Every 45 Days',
        key: '45',
        defaultColor: false,
        displaySkuImg: false,
        value: 45,
      },
      {
        name: 'Every 60 Days',
        key: '60',
        defaultColor: false,
        displaySkuImg: false,
        value: 60,
      },
      {
        name: 'Every 90 Days',
        key: '90',
        defaultColor: false,
        displaySkuImg: false,
        value: 90,
      },
      {
        name: 'Every 120 Days',
        key: '120',
        defaultColor: false,
        displaySkuImg: false,
        value: 120,
      },
    ];
    return flatten(frequencyOptions);
  };
  const selectedFrequencyIndex =
    product.replenishInterval === undefined ? -1 : product.replenishInterval;

  const optionType = get(
    product,
    'customization.customizationOptions[0].type',
    {},
  );
  return (
    <div className="grid-100 tablet-grid-100 mobile-grid-100 grid-parent">
      {sizeOptions.values.length > 0 && (
        <SizeGuide
          labelHeading="Select"
          label="size"
          options={sizeOptions.values}
          selectedIndex={selectedSizeIndex}
          hoveredIndex={hoveredSizeIndex}
          sizeGuide={details.sizeGuide}
        />
      )}
      <div className="grid-100 tablet-grid-100 mobile-grid-100 gutter-bottom-half">
        {sizeOptions.values.length > 0 && (
          <DropDownComponent
            productId={product.id}
            labelHeading="Select"
            label="size"
            options={sizeOptions.values}
            selectedIndex={selectedSizeIndex}
            availableSkus={sizeSkus}
            onClick={props.setSelectedIndex}
            hoveredIndex={hoveredSizeIndex}
            onHover={props.setHoveredIndex}
            optionType=""
            isGroup={props.isGroup}
            isChanel={product.isChanel}
          />
        )}

        {colorOptions.values.length > 0 && (
          <DropDownComponent
            productId={product.id}
            labelHeading="Select"
            label="color"
            options={colorOptions.values}
            selectedIndex={selectedColorIndex}
            availableSkus={colorSkus}
            onClick={props.setSelectedIndex}
            hoveredIndex={hoveredColorIndex}
            onHover={props.setHoveredIndex}
            noAvailableSizeSelected={noAvailableSizeSelected}
            optionType=""
          />
        )}
        {isColorSwatchProduct(colorOptions.values) ? (
          <ProductOptionSet
            productId={product.id}
            labelHeading="Select"
            label="color"
            options={colorOptions.values}
            selectedIndex={selectedColorIndex}
            availableSkus={colorSkus}
            onClick={props.setSelectedIndex}
            hoveredIndex={hoveredColorIndex}
            onHover={props.setHoveredIndex}
            noAvailableSizeSelected={noAvailableSizeSelected}
            optionType=""
            isChanel={product.isChanel}
            isGroup={props.isGroup}
          />
        ) : null}

        {isCustomizationOptionTypePDP() ? (
          <ProductOptionSet
            productId={product.id}
            labelHeading="Select"
            label={BUCKLE_FINISH}
            options={getBuckleFinishChoices()}
            selectedIndex={selectedBuckleFinishIndex}
            onClick={props.setSelectedIndex}
            hoveredIndex={hoveredBuckleIndex}
            onHover={props.setHoveredIndex}
            noAvailableSizeSelected={noAvailableSizeSelected}
            optionType={optionType}
          />
        ) : (
          ''
        )}
      </div>

      {product.replenishable ? (
        <div className="grid-95 tablet-grid-100 mobile-grid-100 gutter-bottom-half">
          <DropDownComponent
            productId={product.id}
            labelHeading="Select"
            label="frequency"
            options={getFrequencyChoices()}
            selectedIndex={selectedFrequencyIndex}
            onClick={props.setReplenishInterval}
            optionType={optionType}
            isReplenish
          />
        </div>
      ) : null}
      {shouldShowPersonalizeSection() && !isCustomizationOptionTypePDP() ? (
        <Personalization product={product} selectedSku={selectedSku} />
      ) : null}
      <div className="grid-offset grid-100 tablet-grid-100 mobile-grid-100 groupPDP_Quantity">
        <ProductQuantity productId={product.id} />
      </div>
      <div className="grid-offset messages grid-100 tablet-grid-100 mobile-grid-100">
        <SkuStatusMessages
          selectedSku={selectedSku}
          perishable={product.perishable}
          selectedSkuQuantity={product.quantity}
          preciousJewelryItem={isPreciousJewelry()}
          isChanel={product.isChanel}
          merchandiseType={product.merchandiseType}
        />
      </div>
      {product.perishable && (
        <div className="grid-offset grid-100 tablet-grid-100 mobile-grid-100">
          <PerishableMessage perishable={product.perishable} />
          <PerishableCalendar
            productId={product.id}
            expectedShipDays={get(selectedSku, 'expectedShipDays', null)}
          />
        </div>
      )}
      <div className="grid-100 tablet-grid-100 mobile-grid-100 boss-msg-pdp">
        <BossMessage
          selectedSku={selectedSku}
          startDate={boss.startDate}
          endDate={boss.endDate}
          message={boss.message}
        />
      </div>
      <div
        id={`productButtons_${product.id}`}
        className="tablet-grid-100 mobile-grid-100 gutter-bottom-half"
      >
        {isPreciousJewelry() && (
          <div className="precious-jewellry">
            <span className="precious-jewelry-message">
              {PRECIOUS_JEWELRY_PAYPAL_MESSAGE}
            </span>
          </div>
        )}
        {isRequiredMonogramNotCustomized() &&
        !isCustomizationOptionTypePDP() ? (
          <PersonalizeItemButton product={product} selectedSku={selectedSku} />
        ) : (
          addToBagButton
        )}
        {props.showFindInStore &&
          (props.suppressBOPSForParentheticalToggle
            ? parentheticalCharge <= 0
            : true) && (
            <div
              id={product.id + appendKey}
              className="grid-100 tablet-grid-100 mobile-grid-100 grid-parent"
            >
              <BopsButton
                product={product}
                selectedSku={selectedSku}
                skuMediaUrl={get(selectedOptionMedia, 'main.thumbnail.url', '')}
              />
            </div>
          )}
        {props.showGiftNow && !isRequiredMonogram() && giftNowButton}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    suppressBOPSForParentheticalToggle:
      state.toggles.SUPPRESS_BOPS_FOR_PARENTHETICAL,
    showFindInStore: state.toggles.PDP_FIND_IN_STORE,
    showGiftNow: state.toggles.PDP_GIFTNOW,
    isGroup: get(state.productCatalog, 'product.isGroup', false),
  };
};

const mapDispatchToProps = (dispatch, { product: { id: productId } }) => ({
  setSelectedIndex: (...args) => dispatch(setSelectedIndex(productId, ...args)),
  setHoveredIndex: (...args) => dispatch(setHoveredIndex(productId, ...args)),
  setReplenishInterval: (...args) =>
    dispatch(setReplenishInterval(productId, ...args)),
  openReplenishment: () => dispatch(openReplenishment()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CheckoutOptionInfo);
