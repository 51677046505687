import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { getParams } from 'client-utils/utilities-router';
import { shouldLoad } from 'universal/http-client';
import { showQLModalWindow } from 'plp/ProductListPage/actions';
import { getEcmQLProductInfo } from 'ecm/actions/actions-ecmcontent';
import './styles.scss';

export class AnchorWithModalKeysIfImageMapTargetIsTop extends Component {
  constructor(props) {
    super(props);
    this.handleModalLaunch = this.handleModalLaunch.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (
      get(this.props, 'imageMap.target', '') === '_quicklook' &&
      get(this.props, 'imageMap.prodId', false)
    ) {
      if (shouldLoad(this.props.api)) {
        this.props.getEcmQLProductInfo(get(this.props, 'imageMap.prodId', ''));
      }
    }
  }

  handleModalLaunch(e) {
    e.preventDefault();
    const { navpath } = getParams(this.props.query);
    this.props.showQLModalWindow(
      get(this.props, 'imageMap.prodId', ''),
      get(this.props, 'prodURL', ''),
      navpath,
    );
  }

  render() {
    const ecmTransImage =
      'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
    let specialAriaLabel = null;
    specialAriaLabel =
      get(this.props, 'imageMap.target', '') === '_quicklook'
        ? `Product Quick Look ${this.props.prodName}`
        : null;
    let anchorProps = {
      href:
        this.props.imageMap.target === '_quicklook'
          ? null
          : get(this.props, 'imageMap.linkurl', ''),
      role: this.props.imageMap.target === '_quicklook' ? 'button' : 'link',
      onClick:
        this.props.imageMap.target === '_quicklook'
          ? e => this.handleModalLaunch(e)
          : null,
    };
    if (this.props.imageMap.target === '_top') {
      specialAriaLabel = `Link to modal ${get(
        this.props,
        'imageMap.alttext',
        '',
      )}`;
      anchorProps = {
        role: 'button',
        href: '#',
        'data-modal-url': get(this.props, 'imageMap.linkurl', ''),
        'data-modal-width': 500,
      };
    } else if (this.props.imageMap.target === '_blank') {
      anchorProps = {
        ...anchorProps,
        target: this.props.imageMap.target,
      };
    }

    return (
      <a
        {...anchorProps}
        className={`${this.props.breakPointClasses} image-map-wrapper`}
        tabIndex={0}
        aria-label={specialAriaLabel}
        style={this.props.mapStyle}
        key={this.props.index}
      >
        <img
          src={ecmTransImage}
          alt={get(this.props, 'imageMap.alttext', '')}
          title={get(this.props, 'imageMap.alttext', '')}
        />
      </a>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const products = get(state.ecm, 'ecmDynamicProductInfo.products', []);
  const idMatchingArray = products.filter(product => {
    return product.id === get(ownProps, 'imageMap.prodId', '');
  });
  const prodURL = isEmpty(idMatchingArray)
    ? ''
    : get(idMatchingArray, '[0].details.canonicalUrl', '');
  const prodName = isEmpty(idMatchingArray)
    ? ''
    : get(idMatchingArray, '[0].name', '');
  return {
    query: state.routing.locationBeforeTransitions,
    api: state.api[`ecm_ql_product_info_${ownProps.imageMap.prodId}`],
    prodURL,
    prodName,
  };
};

export default connect(
  mapStateToProps,
  { showQLModalWindow, getEcmQLProductInfo },
)(AnchorWithModalKeysIfImageMapTargetIsTop);
