import React from 'react';

import './errorMessage.scss';

export default ({ message }) => {
  if (!message) return null;
  return (
    <div className="errorMessage">
      <p>{message}</p>
    </div>
  );
};
