import React, { Component } from 'react';
import ErrorMessage from 'productpage/components/ErrorMessage/errorMessage';
import { connect } from 'react-redux';
import { getSelectedCustomizationsAllTypesById } from 'pdp/components/ProductPage/reducers/productReducer';
import { setSelectedCustomizationObjectById } from 'pdp/components/ProductPage/actions';

export class DumbPhoneInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      incorrectCharacters: ['', '', ''],
      validated: [false, false, false],
    };

    this.getErrorMessage = this.getErrorMessage.bind(this);
  }

  componentDidMount() {
    const {
      id = '',
      touched = false,
      isValid = false,
    } = this.props.selectedCustomizationData;

    this.props.setSelectedCustomizationObjectById({
      id,
      isValid,
      selectedValues:
        this.props.selectedCustomizationData &&
        this.props.selectedCustomizationData.selectedValues
          ? [...this.props.selectedCustomizationData.selectedValues]
          : ['', '', ''],
      touched,
    });
  }

  getErrorMessage() {
    const {
      touched = false,
      isValid = false,
      selectedValues = ['', '', ''],
    } = this.props.selectedCustomizationData;
    const isValidated = this.state.validated.some(this.checkBoolean);
    const incorrectCharacters = this.state.incorrectCharacters.join('');
    const allValues = selectedValues && selectedValues.join('');

    if (touched && allValues.length === 0) {
      return 'Enter required information.';
    } else if (
      touched &&
      isValidated &&
      !isValid &&
      incorrectCharacters.length === 0
    ) {
      return 'Enter required information.';
    } else if (incorrectCharacters.length > 0) {
      return `The ${incorrectCharacters} character(s) is not allowed. Please enter latin characters only.`;
    }
    return '';
  }

  checkBoolean(bool) {
    return bool === true;
  }

  handleChange(event) {
    const { incorrectCharacters } = this.state;
    const {
      selectedValues = ['', '', ''],
    } = this.props.selectedCustomizationData;

    const newValue = event.target.value.trim();
    const name = event.target.name;
    const characters = newValue.replace(/\d+/g, '');
    const inputLength = [3, 3, 4];

    if (newValue.length <= inputLength[name]) {
      selectedValues[name] = newValue;
      incorrectCharacters[name] = characters;

      this.setState({
        incorrectCharacters,
      });

      this.props.setSelectedCustomizationObjectById({
        id: this.props.selectedCustomizationData.id,
        isValid: this.isValid(),
        selectedValues,
        touched: true,
      });
    }
  }

  isValid() {
    const { selectedValues } = this.props.selectedCustomizationData;
    const allValues = selectedValues.join('');
    const isNum = /^\d+$/.test(allValues);

    return allValues.length === 10 && isNum && this.isASCII(allValues);
  }

  isASCII(str) {
    return /^[\x00-\x7F]*$/.test(str);
  }

  validatePhone(event) {
    event.preventDefault();
    const name = event.target.name;
    const { validated } = this.state;
    validated[name] = true;
    this.setState({
      validated,
    });
  }

  render() {
    const { displayName } = this.props.option;
    const {
      selectedValues = ['', '', ''],
    } = this.props.selectedCustomizationData;

    return (
      <div className="monogram-text-dmg__user-input__phone">
        <legend>{displayName}</legend>
        <input
          name="0"
          type="text"
          value={selectedValues[0] || ''}
          maxLength={3}
          onChange={e => this.handleChange(e)}
          onBlur={e => this.validatePhone(e)}
        />
        <input
          name="1"
          type="text"
          value={selectedValues[1] || ''}
          maxLength={3}
          onChange={e => this.handleChange(e)}
          onBlur={e => this.validatePhone(e)}
        />
        <input
          name="2"
          type="text"
          value={selectedValues[2] || ''}
          maxLength={4}
          onChange={e => this.handleChange(e)}
          onBlur={e => this.validatePhone(e)}
        />
        <span className="monogram-text-dmg__character">(10 Characters)</span>
        <ErrorMessage message={this.getErrorMessage()} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    selectedCustomizationData: getSelectedCustomizationsAllTypesById(
      state,
      props,
    ),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setSelectedCustomizationObjectById: (...args) =>
    dispatch(setSelectedCustomizationObjectById(ownProps.productId, ...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbPhoneInput);
