import isEmpty from 'lodash/isEmpty';
import React, { Component } from 'react';
import NO_IMAGE_AVAILABLE from 'bgo-common/assets/images/no-image.png';
import ResponsiveImage from 'shared/components/ResponsiveImage/responsiveImage';

export const NO_IMAGE_AVAILABLE_CDN = NO_IMAGE_AVAILABLE;
export const NO_IMAGE_AVAILABLE_LARGE_CDN = NO_IMAGE_AVAILABLE;

/* ktykhov:timeout = 0 means that component should wait till image be loaded */

class Image extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: props.src !== 'false' ? props.src : props.errorImage,
      alt: props.alt,
    };
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    const { isOOS = false } = this.props;
    const checkImage = () => {
      if (this.image && this.image.naturalWidth === 0) {
        this.handleError();
      }
    };

    if (!isOOS) {
      if (this.image && this.image.complete && this.image.naturalWidth === 0) {
        this.handleError();
      }

      if (this.props.timeout === 0) {
        this.image.onload = checkImage;
        this.image.onerror = () => this.handleError();
      } else if (
        this.image &&
        !this.image.complete &&
        !this.props.isFromShopTheLook
      ) {
        setTimeout(checkImage, this.props.timeout || 500);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.src !== this.state.src) this.setState({ src: nextProps.src });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.reRender || nextState.src !== this.props.src;
  }

  handleError() {
    if (this.props.isPdp && this.props.quantumImageErrorToggle) {
      window &&
        window.QuantumMetricAPI &&
        window.QuantumMetricAPI.sendEvent(159, 0, '');
    }
    if (this.props.isPlp === true) {
      this.props.updateSwatchesValue(
        this.props.swatchIndex,
        this.props.productId,
      );
    }
    if (!isEmpty(this.props.errorImage)) {
      this.setState({ src: this.props.errorImage });
      return null;
    }
    if (
      this.state.src !== NO_IMAGE_AVAILABLE_CDN &&
      this.state.src !== NO_IMAGE_AVAILABLE
    ) {
      this.setState({
        src: NO_IMAGE_AVAILABLE_CDN,
        alt: 'No image available',
      });
    } else if (this.state.src === NO_IMAGE_AVAILABLE_CDN) {
      this.setState({
        src: NO_IMAGE_AVAILABLE,
        alt: 'No image available',
      });
    }
    return null;
  }

  render() {
    const { name, className, onClick, title, isCloudinary } = this.props;

    return isCloudinary ? (
      <ResponsiveImage
        src={this.state.src}
        alt={this.state.alt}
        title={title}
        name={'mainImage'}
        onClick={onClick}
        isCloudinary={isCloudinary}
        className={className}
        name={name} // eslint-disable-line
      />
    ) : (
      <img
        className={className}
        src={this.state.src}
        ref={ref => {
          this.image = ref;
        }}
        alt={this.state.alt}
        onClick={onClick}
        onError={this.handleError}
        title={title}
        name={name}
      />
    );
  }
}

export default Image;
