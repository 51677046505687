import React from 'react';
import './productListErrorModal.scss';
import ModalWithHeader from '../MessageModals/modalWithHeader';

const errorMessage =
  'Sorry, something went wrong on our end. Please try again.';
const ProductListErrorModal = () => (
  <ModalWithHeader
    message={errorMessage}
    header="Error"
    className="product-list-error-modal"
  />
);

export default ProductListErrorModal;
