import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import get from 'lodash/get';
import Button from 'shared/components/Button/button';
import { ENTER_KEYCODE, SPACE_KEYCODE } from 'client-utils/keyCodes';
import getProduct from 'pdp/components/ProductPage/selectors/getProduct';
import screenReaderAnnouncer from 'bgo-common/components/ScreenReaderAlert/screenReaderAnnouncer-actions';
import { isMobile } from 'client-utils/utilities-page';
import { markStart, markTTD } from 'client-utils/utilities-performance';
import {
  resetQLProduct,
  addToBag,
  showAddToBagError,
  clearError,
} from 'pdp/components/ProductPage/actions';
import validatePDP, { ADD_TO_CART } from '../../pdpPageValidator';
import ErrorMessage from '../ErrorMessage/errorMessage';

import './addToBagButton.scss';

export class DumbAddToBagButton extends Component {
  constructor(props) {
    super(props);
    this.onClickAddToBag = this.onClickAddToBag.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    markTTD('PDP_AddToBagButton');
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isMiniCartLoading && nextProps.isResolved) {
      this.handleFocus();
    }
  }

  onClickAddToBag() {
    markStart('PDP_TimeToAddToBag');
    const {
      selectedSku,
      customizationOptionTypePDP,
      skuMediaUrl,
      isLoading,
      vendorQtyRestrictions,
      isNewPDP,
      source,
      productDetails,
      fromWishlist = false,
      showLoading = false,
      isFromQL = false,
    } = this.props;

    const product =
      source === 'wishlist' || isFromQL ? productDetails : this.props.product;

    if (isFromQL) {
      resetQLProduct(this.props);
    }
    if (isLoading) return;
    if (!fromWishlist) {
      if (!isFromQL) {
        this.props.clearError();
        const errorMessage = validatePDP(
          product,
          selectedSku,
          vendorQtyRestrictions,
          ADD_TO_CART,
          customizationOptionTypePDP,
          isNewPDP,
        );
        if (errorMessage) {
          this.props.showAddToBagError(errorMessage);
          this.props.screenReaderAnnouncer(errorMessage);
          return;
        }
      }
    }

    if ((this.props.onClick && this.props.onClick()) || !this.props.onClick) {
      this.props.addToBag(
        product,
        selectedSku,
        skuMediaUrl,
        '',
        source,
        showLoading,
        isFromQL,
      );
      this.props.removeCustomizations && this.props.removeCustomizations();
      const itemAdded = document.getElementById('miniCartContainer');
      if (itemAdded) itemAdded.className += ' itemAdded';
    }
  }

  handleFocus() {
    if (!isMobile()) {
      const checkoutButton = document.getElementById('miniCartButton');
      setImmediate(() => {
        checkoutButton && checkoutButton.focus();
      });
    }
  }

  handleKeyDown(event) {
    if (event.which === ENTER_KEYCODE || event.which === SPACE_KEYCODE) {
      event.stopPropagation();
      this.onClickAddToBag();
    }
  }

  isProductAddedToCart = () => {
    const { productsAddedToCart, selectedSku } = this.props;
    if (productsAddedToCart && selectedSku) {
      return productsAddedToCart.indexOf(selectedSku.id) !== -1;
    }
    return false;
  };

  render() {
    const { quantityEnabled, vendorQtyRestrictions, product } = this.props;

    return (
      <div id="addToBagButton" className="addtobag-btn">
        <div className="atb-btn-wrapper">
          <Button
            ariaLabel="Add to Bag"
            onClick={this.onClickAddToBag}
            onKeyDown={this.handleKeyDown}
            value={!this.isProductAddedToCart() ? 'Add to Bag' : 'Added'}
            className={classnames('addtobag-btn', {
              'button-quantity-enabled': !quantityEnabled,
            })}
            disabled={this.isProductAddedToCart()}
          />
        </div>
        {vendorQtyRestrictions &&
          vendorQtyRestrictions[product.departmentCode] && (
            <ErrorMessage message={product.addToBagError} />
          )}
      </div>
    );
  }
}

const mapStateToProps = (state, { productId }) => {
  const {
    api: {
      add_to_bag: { loading: isLoading, resolved: isResolved } = {},
      mini_cart: {
        loading: isMiniCartLoading,
        resolved: isMiniCartResolved,
      } = {},
    },
  } = state;

  const product = getProduct(state, productId);
  const productsAddedToCart = get(
    state,
    'productCatalog.product.productsAddedToCart',
  );
  return {
    isLoading,
    isResolved,
    isMiniCartLoading,
    isMiniCartResolved,
    product,
    vendorQtyRestrictions: state.productCatalog.vendorQtyRestrictions,
    productsAddedToCart,
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  addToBag: (...args) => dispatch(addToBag(...args)),
  showAddToBagError: (...args) =>
    dispatch(showAddToBagError(productId, ...args)),
  clearError: () => dispatch(clearError(productId)),
  screenReaderAnnouncer: (...args) => dispatch(screenReaderAnnouncer(...args)),
  resetQLProduct: args => dispatch(resetQLProduct(args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbAddToBagButton);
