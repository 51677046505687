import { GET_STORES, CLEAR_STORES, GET_SAMEDAYDELIVERY } from '../actions';

const defaultState = { data: [], status: null, sameDayDelivery: [] };
export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_STORES:
      return {
        data: action.payload.stores,
        status: action.payload.status,
        showStores: action.payload.showStoresFlag,
        sameDayDelivery: state.sameDayDelivery,
      };
    case GET_SAMEDAYDELIVERY:
      return {
        ...state,
        sameDayDelivery: [
          ...state.sameDayDelivery,
          {
            id: action.payload.productId,
            skuStatus: action.payload.skuStatus,
          },
        ],
      };
    case CLEAR_STORES:
      return {
        ...defaultState,
        sameDayDelivery: state.sameDayDelivery,
      };
    default:
      return state;
  }
};
