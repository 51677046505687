export const loadingStatus = {
  loading: 'loading',
  resolved: 'resolved',
  rejected: 'rejected',
};

export const validationError = {
  requiredField: 'Field is required',
  emailIsNotValid: 'Email is not valid',
  phoneIsNotValid: 'Phone is not valid',
  servicesNotSelected: 'Please choose at least one service',
};

export const signUpError = 'Something went wrong. Please try again.';

export const modalContentType = {
  welcome: 'welcome',
  signUp: 'signUp',
};

export const defaultApiTimeout = 20000;
export const defaultInputMaxLength = 524288;
export const nameInputMaxLength = 70;
export const emailInputMaxLength = 254;
