import { getQueryParam } from 'client-utils/utilities-url';
import httpWithLogging from 'universal/http-client';

const requestApi = httpWithLogging({}, 6000, true);
const headers = {
  'access-control-request-headers': 'content-type',
  'Content-Type': 'application/json',
};

export const getEmail = async (setEmail, setLoading, initialFormValue) => {
  const url = `${NMConfig.API_COMM_PREF}/customer-key/decrypt`;
  try {
    const ck = getQueryParam('CustomerKey');
    const resp = await requestApi.post(url, { ck }, { headers });
    setEmail(resp.data.email);
  } catch (error) {
    console.warn('Error', error);
  } finally {
    setLoading(false);
  }
};

export const updateEmail = async payload => {
  const url = `${NMConfig.API_COMM_PREF}/communication-email`;
  try {
    await requestApi.put(url, payload, { headers });
  } catch (error) {
    console.warn('Error Occured');
  }
};

export const handleUnsubscribe = async (
  currentEmailAddr,
  IsEmailUnsubscribe = false,
) => {
  const updatedTime = new Date().toISOString();
  const url = `${NMConfig.API_COMM_PREF}/communication-preferences`;
  try {
    const payload = {
      email: currentEmailAddr,
      channel: 'ONLINE',
      updatedTime,
      source: IsEmailUnsubscribe
        ? 'Unsubscribe All'
        : 'Account:Communication Preferences',
      preferences: {
        marketing: {
          email: {
            optout: true,
          },
          ...(IsEmailUnsubscribe
            ? {}
            : {
                text: {
                  optout: true,
                },
              }),
        },
      },
    };
    await requestApi.put(url, payload, { headers });
  } catch (error) {
    console.warn('Error', error);
  }
};
