const codeRegex = /^\d{6}$/;

export const totpNoCodeError = 'Please enter verification code.';
export const totpNotMatchError = 'Verification code must contain 6 digits.';

export const validateVerificationCode = code => {
  if (!code) {
    return totpNoCodeError;
  }
  if (!codeRegex.test(code.trim())) {
    return totpNotMatchError;
  }
  return undefined;
};
