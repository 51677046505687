import React from 'react';
import _get from 'lodash/get';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { removeLastEmptyParagraph } from '../../utils';

const RichText = ({ content }) => {
  const fields = _get(content, 'fields', {});

  const {
    backgroundColor,
    desktopText,
    textColor,
    textJustification,
    textPadding,
  } = fields;

  const styles = {
    backgroundColor,
    color: textColor,
    padding: textPadding && `${textPadding}px`,
    textAlign: textJustification,
  };

  if (!desktopText) return null;

  return (
    <div className="rich-text-container" style={styles}>
      {documentToReactComponents(removeLastEmptyParagraph(desktopText))}
    </div>
  );
};

export default RichText;
