/* eslint-disable no-nested-ternary */
import React, { Component, Fragment } from 'react';
import get from 'lodash/get';
import logger from 'server/utilities/logger';
import './storeSearchResult.scss';
import '../bopsModal.scss';

export default class StoreSearchResult extends Component {
  constructor() {
    super();
    this.state = { showStoreHours: false };
    this.toggleStoreHours = this.toggleStoreHours.bind(this);
    this.getCurbsideData = this.getCurbsideData.bind(this);
  }

  componentDidMount() {
    const { bopsCurbsideToggle = false } = this.props;

    if (bopsCurbsideToggle) this.getCurbsideData();
  }

  getCurbsideData() {
    const {
      store: { storeNumber },
    } = this.props;
    // eslint-disable-next-line compat/compat
    return fetch(`https://stores.neimanmarcus.com/info/${storeNumber}.json`)
      .then(response => response.json())
      .then(responseJson => {
        const storeDisclaimer = get(
          responseJson,
          'storeServices.curbside.contents.slot1',
          '',
        );
        const storeHours = get(
          responseJson,
          'storeServices.curbside.workingHours',
          [],
        );

        this.setState({
          curbStoreDisclaimer: storeDisclaimer,
          curbStoreHours: storeHours,
        });
      })
      .catch(error => {
        logger.error(`Could not fetch Curbside Store JSON error: ${error}`);
      });
  }

  getAvailabilityMessage() {
    const { store, bopsCurbsideToggle = false } = this.props;

    const { curbStoreDisclaimer = '' } = this.state;

    const status =
      (store.skuAvailability && store.skuAvailability.status) || '';
    const [availStatus, limitedStock] = status.split('.');
    let curbsideStatus = availStatus;

    if (bopsCurbsideToggle) {
      if (availStatus === 'Available for Pickup Today') {
        curbsideStatus = (
          <Fragment>
            In Stock
            <p style={{ 'margin-bottom': '3px' }}>Pickup in Store</p>
          </Fragment>
        );
      }
      if (availStatus === 'Not Available Today') {
        curbsideStatus = (
          <Fragment>
            Out of Stock
            <p style={{ 'margin-bottom': '3px' }}>Ships to Store</p>
          </Fragment>
        );
      }
      if (availStatus === 'This item is not eligible for in-store pickup') {
        curbsideStatus = 'This item is not eligible for curbside pickup';
      }
    }

    if (availStatus && limitedStock && !bopsCurbsideToggle) {
      return (
        <div id="stockStatus">
          {curbsideStatus}
          <p>{limitedStock}</p>
        </div>
      );
    }
    return (
      <span id="stockStatus">
        {curbsideStatus}
        {bopsCurbsideToggle && (
          <p style={{ color: '#B00000' }}>
            <b>{curbStoreDisclaimer}</b>
          </p>
        )}
      </span>
    );
  }

  toggleStoreHours() {
    this.setState({ showStoreHours: !this.state.showStoreHours });
  }

  displayStoreHours() {
    const { bopsCurbsideToggle = false, store } = this.props;

    const { curbStoreHours = [] } = this.state;
    const storeHoursInfo = store.storeHours.split(',');

    const storeHoursBody = bopsCurbsideToggle
      ? curbStoreHours.map(currentDay => {
          return currentDay.hours.map((hour, index) => {
            const day = index === 0 ? currentDay.label : '';
            return (
              <tr key={day}>
                <td>{day}</td>
                <td>{hour}</td>
              </tr>
            );
          });
        })
      : storeHoursInfo.map((storeDayInfo, index) => (
        <tr key={index}>{this.dividedStoreHours(storeDayInfo)}</tr>
        ));

    return (
      <table aria-label="Store Hours">
        {!bopsCurbsideToggle && (
          <thead>
            <tr className="store-result-optimized__storeHours--header">
              <th scope="col">Day</th>
              <th scope="col">Open</th>
              <th scope="col" aria-hidden="true">
                -
              </th>
              <th scope="col">Close</th>
            </tr>
          </thead>
        )}
        <tbody>{storeHoursBody}</tbody>
      </table>
    );
  }

  dividedStoreHours(storeDayInfo) {
    const dividedDate = storeDayInfo.split(' ');
    return (
      <Fragment>
        <td>{dividedDate[0]}</td>
        <td>{dividedDate[1]}</td>
        <td aria-hidden="true">{dividedDate[2]}</td>
        <td>{dividedDate[3]}</td>
      </Fragment>
    );
  }

  render() {
    const {
      store,
      onClickAddToBag,
      defaultStoreValues,
      bopsCurbsideToggle = false,
    } = this.props;
    const skuAvailability = store.skuAvailability || {};
    const buttonClasses = skuAvailability.inventoryAvailable
      ? 'store-result__add-to-cart-btn--available'
      : 'store-result__add-to-cart-btn--not-available-today';

    let addToCartButtonCTA = skuAvailability.addToCartMessage;
    if (bopsCurbsideToggle) {
      if (addToCartButtonCTA === 'Add to Cart for Pickup Today') {
        addToCartButtonCTA = 'Add to Cart for Pickup';
      }
      if (
        addToCartButtonCTA === 'Add to Cart for Pickup in 2-3 Days' ||
        addToCartButtonCTA === 'Add to Cart for Pickup in 3-6 Days'
      ) {
        addToCartButtonCTA = 'Add to Cart for Pickup in 3-7 Days';
      }
    }

    return (
      <div className="store-result grid-parent">
        <div
          role="region"
          className="store-result__store-location grid-35 tablet-grid-25 mobile-grid-50 grid-parent"
        >
          {store.name ? (
            defaultStoreValues.mensStore.storeId === store.storeId ? (
              <div
                aria-labelledby="storeLocationLabel location"
                className="store-result__name"
              >
                <span id="location">{defaultStoreValues.mensStore.name}</span>
              </div>
            ) : (
              <div
                aria-labelledby="storeLocationLabel location"
                className="store-result__name"
              >
                <span id="location">{defaultStoreValues.womensStore.name}</span>
              </div>
            )
          ) : null}
          <img
            className="store-result__image grid-40 hide-on-tablet hide-on-mobile"
            alt=""
            src={store.image}
          />
          <div className="store-result__address grid-60 tablet-grid-60 mobile-grid-100">
            <div>
              <div>
                {store.addressLine1}&nbsp;{store.addressLine2}
              </div>
              {store.city && (
                <div className="store-result__city">{store.city}</div>
              )}
            </div>
            <div className="store-result__phone-number">
              <a href={`tel:+1${store.phoneNumber}`}>{store.phoneNumber}</a>
            </div>
            <button
              aria-expanded={this.state.showStoreHours}
              aria-label={`Store hours for Neiman Marcus ${store.name}`}
              className="store-result__availability"
              onClick={this.toggleStoreHours}
            >
              Store Hours
            </button>
            <div
              ref={ref => {
                this.storeHours = ref;
              }}
              className={
                this.state.showStoreHours
                  ? 'store-result__storeHours--visible'
                  : 'store-result__storeHours'
              }
            >
              {this.displayStoreHours()}
            </div>
          </div>
        </div>

        <div className="store-result__availability--status grid-25 tablet-grid-30 mobile-grid-50">
          <div
            aria-labelledby="storeAvailabilityLabel stockStatus"
            className={`store-result__availability--status${
              bopsCurbsideToggle ? '__curbside' : '__text'
            }`}
          >
            {this.getAvailabilityMessage()}
          </div>
        </div>

        <div className="store-result__add-to-cart grid-40 tablet-grid-45 mobile-grid-100 grid-parent">
          <button
            aria-label={`${addToCartButtonCTA} from ${store.name}`}
            className={buttonClasses}
            onClick={onClickAddToBag}
          >
            {addToCartButtonCTA}
          </button>
        </div>
      </div>
    );
  }
}
