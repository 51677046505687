import React from 'react';
import { object } from 'prop-types';
import get from 'lodash/get';
import merge from 'lodash/merge';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { isNewHomePage } from '@bgo-ui/common/client/utilities/is-new-home-page';
// eslint-disable-next-line import/no-cycle
import RichText from '../richText/richText';
// eslint-disable-next-line import/no-cycle
import ImageAsset from '../imageAsset/imageAsset';
import IWTHelper from './imageWithTextHelper';
import { getTags } from '../utils';
import { defaultTrackingTags } from '../../constants/constants';
import './imageWithText.scss';

/**
 * @function DumbImageWithText
 * @param {Object} props.parentTrackingTags
 * @returns {React.ReactElement}
 */

export function DumbImageWithText({
  cmsContentItem = {},
  isMobilePhone,
  isNewHomePage,
  parentTrackingTags,
}) {
  const contentItemsFields = get(cmsContentItem, 'fields', {});
  const contentIdImage = get(contentItemsFields.image, 'sys.id');
  const contentIdText = get(contentItemsFields.text, 'sys.id');

  const iwtStyles = IWTHelper(contentItemsFields);
  const iwtClasses = 'imagewithtext'.concat(
    isMobilePhone
      ? iwtStyles.iwtClassesMobile
      : classNames(iwtStyles.iwtClassesDesktop, iwtStyles.iwtClassesTablet),
  );
  const imageClasses = isMobilePhone
    ? iwtStyles.imageClassesMobile
    : classNames(iwtStyles.imageClassesDesktop, iwtStyles.imageClassesTablet);
  const textClasses = isMobilePhone
    ? iwtStyles.textClassesMobile // eslint-disable-next-line max-len
    : classNames(iwtStyles.textClassesDesktop, iwtStyles.textClassesTablet);

  // Set up Tracking Tags
  const { trackingTags } = contentItemsFields;
  const currentTags = getTags(trackingTags);
  const mergedTrackingTags = merge({}, parentTrackingTags, currentTags);

  return (
    <div className={iwtClasses}>
      <div className={imageClasses}>
        <ImageAsset
          cmsContentItem={contentItemsFields.image}
          key={contentIdImage}
          parentTrackingTags={mergedTrackingTags}
        />
      </div>
      <div
        className={classNames(
          textClasses,
          'iwt-text',
          isNewHomePage && 'iwt-full-width',
        )}
      >
        <RichText
          cmsContentItem={contentItemsFields.text}
          key={contentIdText}
          parentTrackingTags={mergedTrackingTags}
        />
      </div>
      {// eslint-disable-next-line max-len
      isMobilePhone
        ? iwtStyles.mobileImageTextStyle()
        : iwtStyles.desktopTabletImageTextStyle()
}
    </div>
  );
}

DumbImageWithText.defaultProps = {
  parentTrackingTags: defaultTrackingTags,
};

DumbImageWithText.propTypes = {
  parentTrackingTags: object,
};

const mapStateToProps = state => ({
  isMobilePhone: get(state, 'device.isMobilePhone', false),
  isNewHomePage: isNewHomePage(state),
});

export default connect(mapStateToProps)(DumbImageWithText);
