import reactCookie from 'react-cookie';

export const updateUcaProfileCountCartItems = (cartItemCount = 0) => {
  const ucaProfileDataCookie = reactCookie.load('ucaProfileData');

  reactCookie.save(
    'ucaProfileData',
    JSON.stringify({ ...ucaProfileDataCookie, cartItemCount }),
    {
      path: '/',
      domain: '.bergdorfgoodman.com',
    },
  );
};
