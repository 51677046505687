import httpWithLogging from 'universal/http-client';
import logger from 'server/utilities/logger';
import {
  findAndReplaceLocaleContextInHref,
  getLocaleUrl,
  redirect,
} from 'client/utilities/utilities-page';

export function updateProfile(
  previousCountryCode,
  country,
  currency,
  selectedLocaleUrl,
) {
  return (dispatch, getState) => {
    const state = getState();
    const localeContextPath = getLocaleUrl(previousCountryCode);
    const requestApi = httpWithLogging(state);
    return requestApi
      .post(`${NMConfig.API_PROFILE_COUNTRY_DATA}`, {
        country,
        currency,
      })
      .then(() => {
        redirect(
          findAndReplaceLocaleContextInHref(
            window.location.pathname,
            localeContextPath,
            country,
            selectedLocaleUrl,
          ),
        );
      })
      .catch(() => logger.error('could not update profile information'));
  };
}
