import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import RenderContentItem from '../contentItem/contentItem';
import SimpleSpinner from 'shared/components/Spinner/simpleSpinner';
import './homeMenuModal.scss';

export function ConnectedHomeMenuModal({ modals, activeEntryId }) {
  const modalContent = modals.find(modal => modal.sys.id === activeEntryId);
  const contentItemFields = get(modalContent, 'fields', {});
  const components = get(contentItemFields, 'components', []);

  return (
    <React.Fragment>
      {modals.length ? (
        components.map(contentItem => {
          if (contentItem.fields) {
            return (
              <div className="home-menu-style-modal-content">
                <RenderContentItem cmsContentItem={contentItem} />
              </div>
            );
          } else {
            return null;
          }
        })
      ) : (
        <SimpleSpinner />
      )}
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  modals: get(state, 'cms.modal', []),
  activeEntryId: get(state, 'cms.activeEntryId', ''),
});

export default connect(mapStateToProps)(ConnectedHomeMenuModal);
