import React from 'react';
import SalePrice from 'shared/components/SalePrice/salePrice';
import PromotionItemHtml from './components/PromotionItemHtml/promotionItemHtml';

const getSalePrice = (price, promotionAdornmentColor) => (
  <SalePrice
    price={price}
    promotionAdornmentColor={promotionAdornmentColor}
    showPriceInDecimals
  />
);

const getPromotion = (
  itemPromotions,
  currencyCode,
  itemPromotionalPrice,
  isOOS,
) => (
  <PromotionItemHtml
    promotions={itemPromotions}
    currencyCode={currencyCode}
    promotionalPrice={itemPromotionalPrice}
    isOOS={isOOS}
  />
);

export const renderSalePrice = (
  price,
  promotionAdornmentColor,
  isZeroDollarProduct,
) => {
  return !isZeroDollarProduct && getSalePrice(price, promotionAdornmentColor);
};

export const renderPromotionItemHtml = (itemPromotions, price, isOOS) => {
  const currencyCode = price.currencyCode;
  const itemPromotionalPrice = price.promotionalPrice;
  return getPromotion(
    itemPromotions,
    currencyCode,
    itemPromotionalPrice,
    isOOS,
  );
};
