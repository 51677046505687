import _get from 'lodash/get';

const findEntryById = (id, entries) =>
  entries.find(entry => entry.sys.id === id);

const addFields = (entry, entries) => {
  const matchedEntry = findEntryById(entry.sys.id, entries);
  /* eslint-disable no-param-reassign */
  entry.contentType = matchedEntry.sys.contentType.sys.id;
  entry.fields = matchedEntry.fields;
};

const normalizer = (item, entries) => {
  const { fields } = item;
  const fieldsKeys = Object.keys(fields);

  fieldsKeys.forEach(key => {
    if (Array.isArray(fields[key])) {
      fields[key].forEach(entry => {
        if (_get(entry, 'sys.id')) {
          addFields(entry, entries);
          normalizer(entry, entries);
        }
      });
    } else if (typeof fields[key] === 'object') {
      const entry = fields[key];
      if (_get(entry, 'sys.id')) {
        addFields(entry, entries);
        normalizer(entry, entries);
      }
    }
  });
};

export const normalizeContentfulJSON = rawObject => {
  if (rawObject.total === 0) return [];
  const entries = rawObject.includes.Entry;

  rawObject.items.forEach(item => {
    item.contentType = item.sys.contentType.sys.id;
    normalizer(item, entries);
  });

  return rawObject.items;
};

export const removeLastEmptyParagraph = text => {
  let { content = [] } = text;

  if (content.length) {
    const { nodeType, content: lastNodeContent = [] } = content[
      content.length - 1
    ];
    const isEmptyParagraph =
      nodeType === 'paragraph' &&
      lastNodeContent.length === 1 &&
      lastNodeContent[0].nodeType === 'text' &&
      lastNodeContent[0].value === '';

    if (isEmptyParagraph) {
      content = content.slice(0, -1);
    }
  }

  return { ...text, content };
};
