import React from 'react';
import { YourBenefitsTexts } from '../constants';
import './yourBenefits.scss';

const YourBenefits = () => {
  return (
    <div className="your-benefits">
      <h2 className="your-benefits__title">{YourBenefitsTexts.title}</h2>
      <p className="your-benefits__content">
        {YourBenefitsTexts.content.text}{' '}
        <strong>{YourBenefitsTexts.content.promoCode}</strong>
      </p>
    </div>
  );
};

export default YourBenefits;
