import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Button from 'shared/components/Button/button';
import { Link } from 'react-router';
import { closeModal } from 'shared/components/Modal/actions';
import { resetQLProduct } from '../../../../actions'; // 'plp/ProductListPage/actions';
import './addToBagQLModal.scss';

const AddToBagQLModal = props => {
  const checkoutUrl = NMConfig.CHECKOUT_PAGE_URL;

  useEffect(() => {
    props.dispatch(resetQLProduct(props));
  }, []);

  const handleClick = e => {
    e.preventDefault();
    props.dispatch(closeModal('AddToBagQLModal'));
  };

  return (
    <div className="add-to-bag-ql-modal">
      <h2>Your item has been added to the shopping bag.</h2>
      <div className="checkout-options">
        <Button
          className="continue-shopping"
          secondary
          value="Continue Shopping"
          onClick={handleClick}
        />
        <Link className="checkout-button" target="_self" to={checkoutUrl}>
          <Button value="CHECKOUT" />
        </Link>
      </div>
    </div>
  );
};

export default connect()(AddToBagQLModal);
