import React from 'react';
import closeSVG from 'assets/images/close.svg';
import './closeIcon.scss';

export default function CloseIcon({ onClick }) {
  return (
    <button aria-label="Close" className="close" onClick={onClick}>
      <img
        alt="Close"
        aria-hidden="true"
        className="close-icon"
        height="16"
        src={closeSVG}
        width="16"
      />
    </button>
  );
}
