import get from 'lodash/get';
import { SET_CONTENTFUL_DATA } from './actions';

const defaultState = {};

export default (state = defaultState, action) => {
  if (action.type === SET_CONTENTFUL_DATA) {
    return {
      ...state,
      [get(action, 'payload.identifier')]: get(action, 'payload.data'),
    };
  }

  return state;
};
