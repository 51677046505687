import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import { countryCurrencyData } from './CountryChooser/countryCurrencyData';
import './countrySelector.scss';
import { openCountryChooser, selectUserLocale } from './countrySelector-actions';

class CountrySelector extends Component {
  constructor() {
    super();
    this.onClickCountrySelector = this.onClickCountrySelector.bind(this);
  }

  onClickCountrySelector() {
    this.props.selectUserLocale(this.props.countryCode,
      this.props.currencyCode,
      this.props.localeUrl);
    this.props.openCountryChooser();
  }

  getCountryName(countryCode) {
    return find(countryCurrencyData, country => country.countryCode === countryCode).countryName;
  }

  countryCodeExists(countryCode) {
    return !isEmpty(find(countryCurrencyData, country => country.countryCode === countryCode));
  }


  render() {
    const { countryCode } = this.props;
    let flagImage;
    if (this.countryCodeExists(countryCode)) {
      flagImage = <img className="flag-icon" alt={this.getCountryName(countryCode)} src={`/assets/images/country-flags/${countryCode.toLowerCase()}.svg`} />;
    }

    return (
      <div className={`country-selector ${this.props.visibleOn}`}>
        <button className="country-selector-link" aria-label="Open Country Chooser Modal" onClick={this.onClickCountrySelector}>
          <span>{flagImage}</span>
          <span>
            {
                (this.countryCodeExists(countryCode)) &&
                <span className="desktop-tablet-only">{this.getCountryName(countryCode)}</span>
              }
            <span className="link-text desktop-tablet-only">Change</span>
            <span className="link-text mobile-only">Change Country</span>
          </span>
        </button>
      </div>
    );
  }
}

const mapDispatchToProps = ({ openCountryChooser, selectUserLocale });

const mapStateToProps = state => ({
  countryCode: state.locale.countryCode,
  currencyCode: state.locale.currencyCode,
  localeUrl: state.locale.localeUrl,
});

export default connect(mapStateToProps, mapDispatchToProps)(CountrySelector);
