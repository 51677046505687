import React, { Component } from 'react';
import get from 'lodash/get';
import './productVideo.scss';

class ProductVideo extends Component {
  constructor() {
    super();
    this.player = null;
    this.load = this.load.bind(this);
    this.remove = this.remove.bind(this);
    this.play = this.play.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  getAbsoluteUrl(url) {
    const anchor = document.createElement('a');
    anchor.href = url;
    return anchor.href;
  }

  load() {
    if (!window.jwplayer) return;

    const options = {
      file: this.getAbsoluteUrl(get(this.props.video, 'video.url', '')),
      controls: true,
      width: '100%',
      height: '100%',
      primary: 'html5',
      preload: 'auto',
      flashplayer: '/assets/scripts/jwplayer.flash.swf',
      events: {
        onReady: this.play,
        onPause: this.remove,
        onComplete: this.remove,
        onSetupError: this.remove,
        onError: this.remove,
      },
    };
    this.player = window.jwplayer('video-player').setup(options);
  }

  play() {
    this.player.play();
  }

  remove() {
    this.player.remove();
    this.props.unsetProductVideo();
  }

  render() {
    return (
      <div className="video-player-container">
        <div className="video-player-wrapper">
          <div id="video-player" />
        </div>
      </div>
    );
  }
}

export default ProductVideo;
