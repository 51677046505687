import { formatMoney } from 'accounting';

export function renderFormattedPrice(price, currencyCode = '$') {
  const currencyFormat = currencyCode === '$' ? '%s%v' : '%s %v';
  const currencyThousand = currencyCode === '$' ? ',' : '';
  return Number.isInteger(parseFloat(price))
    ? formatMoney(price, {
        symbol: currencyCode,
        precision: 0,
        format: currencyFormat,
        thousand: currencyThousand,
      })
    : formatMoney(price, {
        symbol: currencyCode,
        precision: 2,
        format: currencyFormat,
        thousand: currencyThousand,
      });
}
