import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import EditButton from '../EditButton/EditButton';
import { EDIT_SECTION_EMAIL, editButtonText } from '../../../constants';
import { openChangeEmailModal } from '../actions';
import { getEditState } from '../helpers';

const SECTION = EDIT_SECTION_EMAIL;

function DumbEmailField(props) {
  const { email: value, isEditDisabled, isEditVisible, isGreyCssFix } = props;
  return (
    <div className="account-details-page__field-wrapper" aria-live="polite">
      <div className="account-details-page__field-header">
        <h3 className="account-details-page__field-label">Email</h3>
        {isEditVisible && (
          <div className="account-details-page__field-edit">
            <EditButton
              editText={editButtonText}
              isDisabled={isEditDisabled}
              onButtonClick={props.openChangeEmailModal}
              isGreyCssFix={isGreyCssFix}
            />
          </div>
        )}
      </div>
      <div className="account-details-page__field-content">
        <p className="account-details-page__field-value">{value}</p>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  email: get(state, 'myaccount.myAccountDetails.fields.email', null),
  isLoading: get(state, 'api.update_basic_attributes.loading', false),
  error: get(
    state,
    'myaccount.myAccountDetails.updateBasicAttributesError',
    null,
  ),
  ...getEditState(state, SECTION),
  isGreyCssFix: get(state, 'toggles.GREYCOLOR_CSS_FIX', false),
});

const mapDispatchToProps = {
  openChangeEmailModal,
};

const EmailField = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbEmailField);

export default EmailField;
