import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SingleDatePicker from 'react-dates/lib/components/SingleDatePicker';
import 'react-dates/initialize';
import $ from 'jquery';
import moment from 'moment';
import some from 'lodash/some';
import { setDeliveryDate } from 'productpage/actions';
import { isMobile } from 'client-utils/utilities-page';
import getProduct from 'pdp/components/ProductPage/selectors/getProduct';
import './perishableCalendar.scss';

const CustomPrev = () => (
  <span
    style={{
      backgroundImage: '../../../../../assets/images/arrow-left.svg',
      width: '10px',
      height: '10px',
    }}
  >
    {'<'}
  </span>
);

const CustomNext = () => (
  <span
    style={{
      backgroundImage: '../../../../../assets/images/arrow-right.svg',
      width: '10px',
      height: '10px',
    }}
  >
    {'>'}
  </span>
);

const getNumberOfMonths = () => {
  if (isMobile()) {
    return 1;
  }
  return 2;
};

const defaultSDPProps = {
  // input related props
  id: 'date',
  placeholder: 'mm/dd/yyyy',
  disabled: false,
  required: false,
  readOnly: true,
  screenReaderInputMessage: '',
  showClearDate: false,
  showDefaultInputIcon: true,

  // calendar presentation and interaction related props
  daySize: 23,
  renderMonth: null,
  orientation: 'horizontal',
  anchorDirection: 'left',
  horizontalMargin: 0,
  withPortal: false,
  withFullScreenPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: getNumberOfMonths(),
  keepOpenOnDateSelect: false,
  reopenPickerOnClearDate: false,
  hideKeyboardShortcutsPanel: true,
  isRTL: false,
  openDirection: 'up',
  // navigation related props
  navPrev: null,
  navNext: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},
  onClose() {},
  // day presentation and interaction related props
  renderDay: null,
  enableOutsideDays: false,
  isDayHighlighted: () => {},

  // internationalization props
  displayFormat: () => moment.localeData().longDateFormat('L'),
  monthFormat: 'MMM YYYY',
};

moment.updateLocale('en', {
  weekdaysMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  monthsShort: [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ],
});

export class DumbPerishableCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      date: null,
    };
    this.onDateChange = this.onDateChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.isRestrictedDay = this.isRestrictedDay.bind(this);
    this.isOutsideRange = this.isOutsideRange.bind(this);
    this.onNextMonthClick = this.onNextMonthClick.bind(this);
    this.onPrevMonthClick = this.onPrevMonthClick.bind(this);
    this.addCloseButton = this.addCloseButton.bind(this);
    this.onCloseClick = this.onCloseClick.bind(this);
    this.displayFullCanlendarOnFocus = this.displayFullCanlendarOnFocus.bind(
      this,
    );
  }

  componentDidMount() {
    if (this.props.isWishlist && this.props.deliveryDate) {
      const date = moment(this.props.deliveryDate);
      this.props.setDeliveryDate(this.props.deliveryDate);
      this.setState({ date });
    }
  }

  componentDidUpdate() {
    const storedDate = moment(this.state.date);
    if (this.state.focused && !$('#calendar-close').length) {
      if (
        this.state.date == null ||
        moment().isSame(this.state.date, 'month')
      ) {
        $('.DayPickerNavigation_leftButton__horizontal').hide();
      } else if (storedDate.endOf('month').diff(moment(), 'months', true) > 6) {
        $('.DayPickerNavigation_rightButton__horizontal').hide();
      }
      this.addCloseButton();
      if (!isMobile()) {
        this.displayFullCanlendarOnFocus();
      }
    }
  }

  onDateChange(date) {
    this.setState({ date });
    const deliveryDate = date.format(date.localeData().longDateFormat('L'));
    this.props.setDeliveryDate(deliveryDate);
  }

  onNextMonthClick(firstMonthDay) {
    const currentDateSelection = moment(firstMonthDay);
    const currentMonthDiff = currentDateSelection
      .endOf('month')
      .diff(moment(), 'months');
    const rightArrowButton = $('.DayPickerNavigation_rightButton__horizontal');
    const leftArrowButton = $('.DayPickerNavigation_leftButton__horizontal');

    if (getNumberOfMonths() === 2) {
      currentMonthDiff > 4 ? rightArrowButton.hide() : leftArrowButton.show();
    } else {
      currentMonthDiff > 5 ? rightArrowButton.hide() : leftArrowButton.show();
    }
  }

  onPrevMonthClick(firstMonthDay) {
    moment().isSame(firstMonthDay, 'month')
      ? $('.DayPickerNavigation_leftButton__horizontal').hide()
      : $('.DayPickerNavigation_rightButton__horizontal').show();
  }

  onFocusChange({ focused }) {
    this.setState({ focused });
  }

  onCloseClick() {
    this.setState({ focused: false });
  }

  displayFullCanlendarOnFocus() {
    const DateInputTopOffset = $('.DateInput').offset().top;
    const scrollTop = $(window).scrollTop();
    const calendarHeight = $('.DayPicker').height();
    if (calendarHeight > DateInputTopOffset - scrollTop) {
      $('html, body').animate({ scrollTop: calendarHeight }, 'slow');
    }
  }

  addCloseButton() {
    const closeButton = $('<div id="calendar-close"></div>').click(
      this.onCloseClick,
    );
    $('.DayPicker__horizontal > div').prepend(closeButton);
  }

  isOutsideRange(day) {
    return day.isBefore(moment()) || day.isAfter(moment().add(6, 'months'));
  }

  isRestrictedDay(day) {
    return (
      this.isNMGHoliday(day) ||
      this.isVendorRestrictedHoliday(day) ||
      this.isBeforeExpectedDeliveryDay(day)
    );
  }

  isVendorRestrictedHoliday(day) {
    return (
      this.props.vendorRestrictedDates &&
      some(this.props.vendorRestrictedDates, restrictedDate =>
        restrictedDate.isSame(day, 'day'),
      )
    );
  }

  isNMGHoliday(day) {
    return some(
      ['Saturday', 'Sunday', 'Monday'],
      holiday => holiday === moment.weekdays(day.weekday()),
    );
  }

  isBeforeExpectedDeliveryDay(day) {
    return day.isBefore(
      moment().add(Number(this.props.expectedShipDays), 'days'),
    );
  }

  render() {
    if (!this.props.perishable) {
      return null;
    }
    const { focused, date } = this.state;

    const perishableDatePicker = (
      <Fragment>
        <div key="dateTxt" className="dateTxt">
          Delivery Date
        </div>
        <SingleDatePicker
          {...defaultSDPProps}
          id="date_input"
          date={date}
          focused={focused}
          onDateChange={this.onDateChange}
          onFocusChange={this.onFocusChange}
          isDayBlocked={this.isRestrictedDay}
          isOutsideRange={this.isOutsideRange}
          navPrev={<CustomPrev />}
          navNext={<CustomNext />}
          onNextMonthClick={this.onNextMonthClick}
          onPrevMonthClick={this.onPrevMonthClick}
          transitionDuration={0}
        />
      </Fragment>
    );

    return perishableDatePicker;
  }
}

const mapStateToProps = (state, props) => {
  const product = getProduct(state, props.productId);
  const vendorRestrictedDates = product.vendorRestrictedDates || [];
  return {
    perishable: product.perishable,
    vendorRestrictedDates: vendorRestrictedDates.map(restrictedDate =>
      moment(restrictedDate, moment.localeData().longDateFormat('L')),
    ),
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  setDeliveryDate: (...args) => dispatch(setDeliveryDate(productId, ...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbPerishableCalendar);
