import get from 'lodash/get';
import log from 'server/utilities/logger';
import {
  getSaleType,
  isSwatchProduct,
  booleanToString,
  getTotalSkuCount,
  getAvailableSkuCount,
  getEDWPricingType,
  getOriginalPrice,
} from './getUtagData';

const groupUtagData = state => {
  const utagData = {
    product_type: [],
    product_id: [],
    product_name: [],
    product_brand: [],
    product_cmos_catalog_id: [],
    product_cmos_item: [],
    product_price: [],
    product_price_original: [],
    product_pricing_adornment_flag: [],
    product_swatch: [],
    product_cmos_sku: [],
    product_expected_availability: [],
    product_sellable_sku: [],
    product_hierarchy: [],
    product_sale_type: [],
    product_suppress_checkout_flag: [],
    product_nm_exclusive: [],
    product_available: [],
    product_dropship_flag: [],
    product_pim_style: [],
    total_sku_count: [],
    available_sku_count: [],
    edw_pricing_adornment: [],
  };
  const { childProducts = [] } = state;
  try {
    utagData.product_type = ['group'];
    utagData.total_sku_count = 0;
    utagData.available_sku_count = 0;
    childProducts.forEach(product => {
      let dropShip = false;
      const { skus } = product;
      utagData.product_id.push(product.id);
      utagData.product_name.push(product.name);
      utagData.product_brand.push(product.designer && product.designer.name);
      utagData.product_cmos_catalog_id.push(product.metadata.cmosCatalogId);
      utagData.product_cmos_item.push(product.metadata.cmosItem);
      utagData.product_pim_style.push(product.metadata.pimStyle);
      utagData.product_price.push(product.price.retailPrice);
      utagData.product_price_original.push(getOriginalPrice(product));
      utagData.product_pricing_adornment_flag.push(
        booleanToString(product.price.adornments),
      );
      utagData.product_swatch.push(
        isSwatchProduct(product.options || { productOptions: [] }),
      );
      utagData.product_suppress_checkout_flag.push(
        booleanToString(product.details.suppressCheckout),
      );
      utagData.product_nm_exclusive.push(
        booleanToString(product.productFlags.isOnlyAtNM),
      );
      utagData.product_available.push(booleanToString(product.displayable));
      utagData.product_sale_type.push(getSaleType(product));
      skus.forEach(sku => {
        utagData.product_cmos_sku.push(sku.metadata.cmosSkuId);
        utagData.product_expected_availability.push(
          get(sku, 'expectedShipDays', ''),
        );
        utagData.product_sellable_sku.push(booleanToString(sku.sellable));
        if (sku.dropShip === true) dropShip = true;
      });
      utagData.total_sku_count += getTotalSkuCount(product);
      utagData.available_sku_count += getAvailableSkuCount(product);
      utagData.product_dropship_flag.push(booleanToString(dropShip));
      utagData.edw_pricing_adornment.push(getEDWPricingType(product));
      utagData.product_review_count = '0';
      utagData.product_review_rating = 'false';
    });
  } catch (err) {
    log.error('Error constructing group PDP analytics data');
  }

  return utagData;
};

export default groupUtagData;
