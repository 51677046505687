import {
  LOADING_FORGOT_PASSWORD,
  RESOLVED_FORGOT_PASSWORD,
  REJECTED_FORGOT_PASSWORD,
  LOADING_RESET_PASSWORD,
  RESOLVED_RESET_PASSWORD,
  REJECTED_RESET_PASSWORD,
  RESOLVED_LOGIN_CONTENT,
  LOADING_REGISTRATION,
  RESOLVED_REGISTRATION,
  REJECTED_REGISTRATION,
} from './actions';

const defaultState = {
  loginContent: {},
  forgotPassword: {
    isLoading: false,
    error: false,
  },
  resetPassword: {
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  registration: {
    error: null,
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOADING_FORGOT_PASSWORD: {
      return { ...state, forgotPassword: { isLoading: true, error: false } };
    }
    case RESOLVED_FORGOT_PASSWORD: {
      return { ...state, forgotPassword: { isLoading: false, error: false } };
    }
    case REJECTED_FORGOT_PASSWORD: {
      return { ...state, forgotPassword: { isLoading: false, error: true } };
    }
    case LOADING_RESET_PASSWORD: {
      return {
        ...state,
        resetPassword: { isLoading: true, isLoaded: false, error: null },
      };
    }
    case RESOLVED_RESET_PASSWORD: {
      return {
        ...state,
        resetPassword: { isLoading: false, isLoaded: true, error: null },
      };
    }
    case REJECTED_RESET_PASSWORD: {
      return {
        ...state,
        resetPassword: {
          isLoading: false,
          isLoaded: false,
          error: action.payload,
        },
      };
    }
    case RESOLVED_LOGIN_CONTENT:
      return {
        ...state,
        loginContent: action.payload,
      };
    case LOADING_REGISTRATION: {
      return {
        ...state,
        registration: {
          error: null,
        },
      };
    }
    case REJECTED_REGISTRATION: {
      return {
        ...state,
        registration: {
          error: action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};
