import React, { Component } from 'react';
import { closeModal } from 'shared/components/Modal/actions';
import { connect } from 'react-redux';
import { ESC_KEYCODE } from 'client-utils/keyCodes';
import { handleTabEventWith } from 'client-utils/handleTabbing';
import './beautyReplenishmentInfoModal.scss';

export class DumbBeautyReplenishmentInfoModal extends Component {
  static isValidComponent(options) {
    return options.type === 'ReplenishmentModal';
  }

  constructor() {
    super();
    this.onEscPress = this.onEscPress.bind(this);
    this.handleTabEvent = this.handleTabEvent.bind(this);
  }

  componentDidMount() {
    addEventListener('keydown', this.onEscPress);
    this.handleTabEvent();
  }

  componentWillUnmount() {
    removeEventListener('keydown', this.onEscPress);
  }

  onEscPress(e) {
    if (e.which === ESC_KEYCODE) {
      this.props.closeModal();
      e.preventDefault();
    }
  }

  handleTabEvent() {
    const modal = document.querySelectorAll('.nm-modal__window')[0];
    modal && setImmediate(() => modal.focus());

    const firstElement = document.querySelectorAll('.nm-modal__content a')[0];
    const closeElement = document.querySelectorAll(
      '.nm-modal__window #close',
    )[0];

    handleTabEventWith(firstElement, closeElement);
  }

  render() {
    return (
      <div
        id="replenish-modal"
        aria-live="polite"
        dangerouslySetInnerHTML={{ __html: this.props.__html }}
      />
    );
  }
}

const mapDispatchToProps = { closeModal };
export default connect(
  undefined,
  mapDispatchToProps,
)(DumbBeautyReplenishmentInfoModal);
