const LoyaltyTierMap = {
  CIRCLE_1: {
    default: 'Circle One',
    analytics: 'Circle One',
  },
  CIRCLE_2: {
    default: 'Circle Two',
    analytics: 'Circle Two',
  },
  CIRCLE_3: {
    default: 'Circle Three',
    analytics: 'Circle Three',
  },
  CIRCLE_4: {
    default: 'Circle Four',
    analytics: 'Circle Four',
  },
  CIRCLE_5: {
    default: 'Circle Five',
    analytics: 'Circle Five',
  },
  CIRCLE_6: {
    default: 'Circle Six',
    analytics: 'Circle Six',
  },
  PRESIDENT: {
    default: 'President',
    analytics: "President's Circle",
  },
  CHAIRMAN: {
    default: 'Chairman',
    analytics: "Chairman's Circle",
  },
};

const LoyaltyTierMapKeys = Object.keys(LoyaltyTierMap);

export const getLoyalityTier = (tierCode, isAnalytics = false) => {
  if (!tierCode || !LoyaltyTierMapKeys.includes(tierCode)) {
    return 'none';
  }

  if (isAnalytics) {
    return LoyaltyTierMap[tierCode].analytics;
  }

  return LoyaltyTierMap[tierCode].default;
};

export const hasLoyalityBenefits = tierCode => {
  if (!tierCode || !LoyaltyTierMapKeys.includes(tierCode)) {
    return false;
  }
  if (tierCode === 'CIRCLE_1') {
    return false;
  }
  return true;
};
