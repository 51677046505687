import React from 'react';
import classNames from 'classnames';
import { Field } from 'formik';

import './FormikFieldPreselected.scss';

const FormikFieldPreSelected = ({
  name,
  id,
  placeholder,
  tooltipText,
  fontSize,
}) => {
  const placeholderClassName = classNames('form-pre-select__placeholder');
  const inputClassName = classNames(
    'form-pre-select__input',
    'read-only',
    fontSize && 'inputFont',
  );
  return (
    <Field name={name}>
      {({ field }) => (
        <div className="form-field form-pre-select">
          <label className="form-pre-select__label" htmlFor={id}>
            <input
              id={id}
              className={inputClassName}
              type="text"
              value={field.value}
              name={field.name}
              readOnly
            />
            <span className="form-pre-select__tooltip-btn">?</span>
            <div className="form-pre-select__tooltip">{tooltipText}</div>
            <span className={placeholderClassName}>{placeholder}</span>
          </label>
        </div>
      )}
    </Field>
  );
};

export default FormikFieldPreSelected;
