import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import AppMyAccountWrapper from './app-my-account-wrapper';
import { openMobileNav } from '../../myaccount/actions';

const AppMyAccountNoContentWrapper = ({ openMobileNav }) => {
  useEffect(() => {
    openMobileNav();
  }, []);

  return <AppMyAccountWrapper />;
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  openMobileNav,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppMyAccountNoContentWrapper);
