import get from 'lodash/get';
import omit from 'lodash/omit';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';
import {
  RESOLVED_ABTEST,
  RESET_ABTEST,
  REJECTED_ABTEST,
  NARVAR_MBOX_ID,
  FACETED_LEFT_NAV_MBOX_ID,
  SET_TEST_FROM_COOKIE,
  SET_TEST_FROM_RESPONSE,
  FACETED_LEFT_NAV_TEST,
  FETCH_PAGE_SIZE_MBOX_ID,
  CP_PERSONALIZATION_MBOX_ID,
  EVENING_WEAR_MBOX_ID,
  AEM_ABTEST_USERGROUP_MBOX_ID,
  NEW_MOBILE_NAV_MBOX_ID,
  FINAL_SALE_MBOX_ID,
  NEW_ASPECT_RATIO_MBOX_ID,
} from 'shared/actions/actions-page';

export const defaultState = {
  responses: [],
  narvarABTest: {
    isTestGroup: false,
    text: '',
    testGroupId: '',
  },
  isFacetsExpereince: false,
  isMobileFetchSizeOn: false,
  isNewMobileNavOn: false,
  cpPersonalization: false,
  eveningWearReturnsFeeExperience: false,
  isPlpAspectRatio2To3: false,
  aemAbTestUsergroup: '',
  tntVals: '',
  abTestResults: {},
  finalSaleExperience: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESOLVED_ABTEST: {
      const stateClone = { ...state };
      stateClone.responses = [...action.payload];
      const tntValsAll = [];
      forEach(action.payload, item => {
        const tntVal = get(item, 'assignment.tntVal', '');
        tntValsAll.push(tntVal);
        if (item.mboxId === NARVAR_MBOX_ID) {
          const assignment = get(item, 'assignment.value', {});
          const experienceId = get(item, 'assignment.experienceId', '');
          if (assignment.text && assignment.text.length) {
            stateClone.narvarABTest = {
              text: assignment.text,
              isTestGroup: true,
              testGroupId: experienceId,
            };
          }
        } else if (item.mboxId === FACETED_LEFT_NAV_MBOX_ID) {
          if (get(item, 'assignment.value.experienceId') === 'FACET_ON') {
            stateClone.isFacetsExpereince = true;
          }
        } else if (item.mboxId === FETCH_PAGE_SIZE_MBOX_ID) {
          if (get(item, 'assignment.value.experienceId') === 'FetchSizeOn') {
            stateClone.isMobileFetchSizeOn = true;
          }
        } else if (item.mboxId === CP_PERSONALIZATION_MBOX_ID) {
          if (
            get(item, 'assignment.value.experienceId') === 'CPPersonalizationOn'
          ) {
            stateClone.cpPersonalization = true;
          }
        } else if (item.mboxId === EVENING_WEAR_MBOX_ID) {
          if (get(item, 'assignment.value.experienceId') === 'B') {
            stateClone.eveningWearReturnsFeeExperience = true;
          }
        } else if (item.mboxId === AEM_ABTEST_USERGROUP_MBOX_ID) {
          stateClone.aemAbTestUsergroup = get(
            item,
            'assignment.value.experienceId',
            'Control',
          );
        } else if (item.mboxId === NEW_MOBILE_NAV_MBOX_ID) {
          if (get(item, 'assignment.value.experienceId') === 'NewMobileNavOn') {
            stateClone.isNewMobileNavOn = true;
          }
        } else if (item.mboxId === FINAL_SALE_MBOX_ID) {
          if (get(item, 'assignment.value.experienceId') === 'B') {
            stateClone.finalSaleExperience = true;
          }
        } else if (item.mboxId === NEW_ASPECT_RATIO_MBOX_ID) {
          if (get(item, 'assignment.value.experienceId') === 'TRUE') {
            stateClone.isPlpAspectRatio2To3 = true;
          }
        }
      });
      stateClone.tntVals = uniq(tntValsAll).join('');
      return stateClone;
    }
    case RESET_ABTEST: {
      return defaultState;
    }
    case REJECTED_ABTEST: {
      return defaultState;
    }
    case SET_TEST_FROM_RESPONSE:
    case SET_TEST_FROM_COOKIE: {
      let isFacetsExpereince = state.isFacetsExpereince;
      if (action.payload.abTestName === FACETED_LEFT_NAV_TEST) {
        isFacetsExpereince = action.payload.abTestCookieValue === 'FACET_ON';
      }
      const content = {
        ...state.abTestResults,
        [action.payload.abTestName]: omit(action.payload, 'abTestName'),
      };
      return {
        ...state,
        abTestResults: content,
        isFacetsExpereince,
      };
    }
    default:
      return state;
  }
};
