const defaultState = {
  messageNumber: 0
};
export default (state = defaultState, action) => {
  if (action.type === 'SCREEN_READER_MESSAGE') {
    return {
      message: action.payload.message,
      messageNumber: state.messageNumber + 1
    };
  }
  return state;
};
