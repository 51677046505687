import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { shouldLoad } from 'universal/http-client';
import { getContentfulData } from './actions';
import ContentType from './components/ContentType/contentType';
import DumbRenderContent from '../cms/components/content/content';
import './contentful.scss';

export class Contentful extends Component {
  constructor(...args) {
    super(...args);
    const { identifier, getContentful, apiStatus, version = 1 } = this.props;

    if (
      identifier &&
      shouldLoad(apiStatus[`contentful_data_${identifier.toLowerCase()}`])
    ) {
      getContentful(identifier, version);
    }
  }

  render() {
    const {
      contentfulData = {},
      identifier = null,
      className = '',
      version = 1,
      placement = 'Main',
    } = this.props;
    const data = contentfulData[identifier];

    if (_isEmpty(data)) return null;

    const content =
      version === 1 ? (
        data.map(item => <ContentType content={item} key={item.sys.id} />)
      ) : (
        <DumbRenderContent cmsContent={data} placement={placement} />
      );

    return <div className={`contentful-container ${className}`}>{content}</div>;
  }
}

Contentful.propTypes = {
  getContentful: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  contentfulData: state.contentful,
  apiStatus: state.api,
});

const mapDispatchToProps = {
  getContentful: getContentfulData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Contentful);
