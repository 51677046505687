import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { shouldLoad } from 'universal/http-client';
import { isGiftCategory } from 'client-utils/utilities-navigation';
import ECMSlots from 'ecm/constants';
import { getECMRequest } from 'ecm/actions/actions-ecmcontent';
import { updateNavLeftRight } from '../actions-navigation';
import Drawer from './Drawer/drawer';
import './navBar.scss';
import { verifyPromoSilo } from '../utils';

export class NavBar extends Component {
  constructor() {
    super();
    this.state = {
      domReady: false,
      initialNavBarOffsetTop: 0,
    };
  }

  componentDidMount() {
    document.addEventListener('readystatechange', () => {
      if (document.readyState === 'complete') {
        this.setState({ domReady: true });
      }
    });

    if (this.props.ecmPreview && this.props.drawerAemInContextPreviewToggle) {
      if (shouldLoad(this.props.ecmApi)) {
        const categoryIds = [
          ...this.props.navigationSilos.map(silo => silo.id),
        ].join(',');
        this.props.getECMRequest(ECMSlots.DRAWER_AEM_ASSET, '', categoryIds);
      }
    }
    this.props.stickyNavBarToggle &&
      NMConfig.LOG_DIR === 'bgo-ui-home' &&
      window.addEventListener('scroll', debounce(this.handleScroll, 100));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isDomLoadComplete !== nextProps.isDomLoadComplete) {
      if (nextProps.isDomLoadComplete && window.picturefill)
        window.picturefill();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.componentInFocus.match(/(drawer)/)) {
      this.props.updateNavLeftRight(this.navBar);
    }

    const retVal = this.props.haveAppSetDomLoadComplete
      ? this.props.isDomLoadComplete !== nextProps.isDomLoadComplete ||
        this.props.navigationSilos !== nextProps.navigationSilos
      : this.state.domReady !== nextState.domReady ||
        this.props.navigationSilos !== nextProps.navigationSilos;

    return retVal;
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  setInitialNavBarOffset = () => {
    this.setState({
      initialNavBarOffsetTop: this.navBar.offsetTop + this.navBar.offsetHeight,
    });
  };

  handleScroll = () => {
    if (
      this.state.initialNavBarOffsetTop <
      this.navBar.offsetTop + this.navBar.offsetHeight
    ) {
      this.setInitialNavBarOffset();
    }
    const stickyNavBarVisible =
      window.pageYOffset >= this.state.initialNavBarOffsetTop;
    if (stickyNavBarVisible) {
      this.navBar.classList.add('sticky-nav');
    } else {
      this.navBar.classList.remove('sticky-nav');
    }
  };

  render() {
    const {
      navigationSilos,
      validPromo,
      sessionExpired,
      segmentSiloAppConfig,
    } = this.props;
    const silos = verifyPromoSilo(
      navigationSilos,
      validPromo && !sessionExpired,
      segmentSiloAppConfig,
    );

    return (
      <nav
        id="silo-navigation"
        className={classNames(
          'silo-nav',
          'silo-drawer-full-width',
          { 'delay-hover-on-silos': this.props.delayHoverOnSilos },
          { 'nav-spacing': this.props.giftSiloToggle },
        )}
        ref={ref => {
          this.navBar = ref;
        }}
      >
        <ul itemScope itemType="https://schema.org/SiteNavigationElement">
          {silos.map((silo, index) => {
            const drawerClassName = classNames({
              'gift-image-new': isGiftCategory(silo, this.props.giftSiloToggle),
            });
            const liProps = drawerClassName
              ? { className: drawerClassName }
              : {};
            return (
              <li key={index} {...liProps} itemProp="name">
                <Drawer silo={silo} router={this.props.router} />
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = state => ({
  ecmApi: state.api[`ecm${ECMSlots.DRAWER_AEM_ASSET.contentId.toLowerCase()}`],
  navigationSilos: state.navigation.silos,
  componentInFocus: state.app.componentInFocus,
  giftSiloToggle: state.toggles.GIFT_SILO_IMAGE,
  haveAppSetDomLoadComplete: get(
    state.toggles,
    'HAVE_APP_SET_DOMLOADCOMPLETE',
    false,
  ),
  isDomLoadComplete: get(state, 'page.isDomLoadComplete', false),
  delayHoverOnSilos: get(state, 'toggles.DELAY_HOVER_ON_SILOS', false),
  drawerAemInContextPreviewToggle: get(
    state,
    'toggles.DRAWER_AEM_IN_CONTEXT_PREVIEW',
    false,
  ),
  stickyNavBarToggle: get(state, 'toggles.SILO_NAV_BAR_STICKY_TOGGLE', false),
  ecmPreview: get(state, 'api.requestContext.ECMPreview', ''),
  segmentSiloAppConfig: get(state, 'config.customerSegment.targetedSilo', {}),
  validPromo: get(
    state,
    'navigation.segmentSailo.eliteCustomer.validPromo',
    false,
  ),
  sessionExpired: get(state, 'user.sessionExpired', true),
});

const mapDispatchToProps = { updateNavLeftRight, getECMRequest };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavBar);
