import React from 'react';
import get from 'lodash/get';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { shape, string, bool, node, instanceOf } from 'prop-types';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import classNames from 'classnames';
import './Copy.scss';

const renderOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      return (
        <img
          src={`https://${node.data.target.fields.file.url}`}
          height={node.data.target.fields.file.details.image.height}
          width={node.data.target.fields.file.details.image.width}
          alt={node.data.target.fields.description}
        />
      );
    },
  },
};

const renderOptionsWithTab = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      return (
        <img
          src={`https://${node.data.target.fields.file.url}`}
          height={node.data.target.fields.file.details.image.height}
          width={node.data.target.fields.file.details.image.width}
          alt={node.data.target.fields.description}
        />
      );
    },
    [INLINES.HYPERLINK]: node => {
      return (
        <a href={node.data.uri} rel="noreferrer" target="_blank">
          {node.content[0].value}
        </a>
      );
    },
  },
};

const Copy = ({ content, loaded = true, fallback = '', newTab = false }) => {
  let render = null;
  if (loaded) {
    render = get(content, 'nodeType', '')
      ? documentToReactComponents(
          content,
          newTab ? renderOptionsWithTab : renderOptions,
        )
      : fallback;
  }

  return (
    <div
      className={classNames(
        'copy-container',
        !loaded && 'copy-container--loading',
      )}
    >
      {render}
    </div>
  );
};

// The same data structure as comes from Contentfull Single Copy:
Copy.propTypes = {
  content: shape({
    nodeType: string.isRequired,
    data: instanceOf(Object).isRequired,
    content: instanceOf(Array).isRequired,
  }),
  loaded: bool,
  fallback: node,
  newTab: bool,
};

export default Copy;
