import { openModal } from 'shared/components/Modal/actions';
import { buildCookieString } from 'universal/utilities-cookies';
import httpWithLogging from 'universal/http-client';
import get from 'lodash/get';
import find from 'lodash/find';
import {
  RESOLVED_PRODUCT,
  RESET_PRODUCT,
} from 'pdp/components/ProductPage/actions';
import { ALTERNATIVE_PRODUCT } from '../actions/actions-common';

export const STL_LOADING_PRODUCT = 'STL_LOADING_PRODUCT';
export const STL_RESOLVED_PRODUCT = 'STL_RESOLVED_PRODUCT';
export const STL_REJECTED_PRODUCT = 'STL_REJECTED_PRODUCT';
export const STL_RESOLVED_CHILD_PRODUCTS = 'STL_RESOLVED_CHILD_PRODUCTS';
export const SKU_STL_PRODUCT_LIST = 'SKU_STL_PRODUCT_LIST';
export const SET_SELECTED_SIZE_INDEX = 'SET_SELECTED_SIZE_INDEX';
export const SET_SELECTED_COLOR_INDEX = 'SET_SELECTED_COLOR_INDEX';
export const STL_PARENT_PRODUCT_ID = 'STL_PARENT_PRODUCT_ID';

export function openShopTheLookModal() {
  return dispatch => {
    return openModal({
      type: 'ShopTheLookModal',
      className: 'shop-the-look-modal',
    })(dispatch);
  };
}

export function getChildProducts(entryPointPage, productId) {
  return (dispatch, getState) => {
    const state = getState();
    const id = productId;

    const compositeEnabled = get(state, 'toggles.PDP_COMPOSITE_MODE', false);
    const requestApi = httpWithLogging(state, 10000);
    const requestURI = compositeEnabled
      ? `${NMConfig.API_PRODUCT_DETAIL_PDP_COMPOSITE}/${id}`
      : `${NMConfig.API_PRODUCT_DETAIL_PDP}/${id}`;

    return requestApi.get(requestURI).then(successResponse => {
      const stlChildProducts = get(successResponse, 'data.childProducts', []);

      const childProductsArr =
        stlChildProducts &&
        stlChildProducts.map(value => {
          return { id: value.id };
        });
      dispatch(getStlFirstLoadProductDetails(childProductsArr[0].id));
      dispatch({ type: STL_PARENT_PRODUCT_ID, productId, entryPointPage });
      dispatch({
        type: STL_RESOLVED_CHILD_PRODUCTS,
        stlProductList: childProductsArr,
      });
    });
  };
}

export function getStlFirstLoadProductDetails(productId) {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state, 10000);
    const requestURI = `${NMConfig.API_PRODUCT_DETAIL_PDP}/${productId}`;
    return requestApi
      .get(requestURI)
      .then(successResponse => {
        const skuStlProductList = {};
        skuStlProductList[productId] = successResponse.data;
        dispatch({ type: STL_RESOLVED_PRODUCT, activeProductId: productId });
        dispatch({
          type: RESOLVED_PRODUCT,
          payload: successResponse.data,
        });
        dispatch({ type: SKU_STL_PRODUCT_LIST, skuStlProductList });
      })
      .catch(() => dispatch({ type: STL_REJECTED_PRODUCT }));
  };
}

export function getStlProduct(id) {
  return (dispatch, getState) => {
    dispatch({ type: STL_LOADING_PRODUCT });
    const state = getState();
    const skuStlProductList = state.shopTheLookReducer.skuStlProductList;
    const { session, user } = state;
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        TLTSID: get(session, 'TLTSID', ''),
        DYN_USER_CONFIRM: get(session, 'DYN_USER_CONFIRM', ''),
        W2A: get(session, 'W2A', ''),
        AGA: get(session, 'AGA', ''),
        WID: get(state, 'utag.userSession.web_id', ''),
        ucid: get(user, 'ucid', ''),
      }),
    };

    const requestURI = `${NMConfig.API_PRODUCT_DETAIL_PDP}/${id}`;
    const requestApi = httpWithLogging(state, 10000);
    return requestApi
      .get(requestURI, { headers })
      .then(successResponse => {
        skuStlProductList[id] = successResponse.data;
        dispatch({ type: SKU_STL_PRODUCT_LIST, skuStlProductList });
      })
      .catch(() => dispatch({ type: STL_REJECTED_PRODUCT }));
  };
}

export function getProductDetails(productId) {
  return (dispatch, getState) => {
    const state = getState();
    const skuStlProductList = state.shopTheLookReducer.skuStlProductList;
    const productDetails = skuStlProductList[productId];
    // add productOptions key to productDetails if productOptions data is not getting from data to update exsting productSate.
    if (
      productDetails &&
      productDetails.options &&
      !productDetails.options.productOptions
    ) {
      productDetails['options']['productOptions'] = [];
    }
    let sizeIndex = -1;
    const sizeOptions = find(
      get(productDetails, 'options.productOptions', []),
      ['label', 'size'],
    );

    if (
      sizeOptions &&
      sizeOptions['values'] &&
      sizeOptions['values'].length === 1
    ) {
      sizeIndex = 0;
    }
    productDetails && (productDetails['addToBagError'] = '');
    dispatch({ type: STL_RESOLVED_PRODUCT, activeProductId: productId });
    // update the product details in productCategory state for add to bag and save to wish list functionalities
    dispatch({ type: RESOLVED_PRODUCT, payload: productDetails });
    // reset selected size and color index if product size, color is selected for previous selected thumbnail
    dispatch({ type: SET_SELECTED_SIZE_INDEX, payload: sizeIndex, productId });
    dispatch({ type: SET_SELECTED_COLOR_INDEX, payload: 0, productId });
    // set analytics data on alternate product click
    dispatch({ type: ALTERNATIVE_PRODUCT, payload: productDetails });
  };
}

export function clearShopTheLookData() {
  return dispatch => {
    dispatch({ type: RESET_PRODUCT });
    dispatch({ type: SKU_STL_PRODUCT_LIST, skuStlProductList: {} });
    dispatch({
      type: STL_PARENT_PRODUCT_ID,
      productId: '',
      entryPointPage: '',
    });
    dispatch({ type: SET_SELECTED_SIZE_INDEX, payload: -1, productId: '' });
    dispatch({ type: STL_RESOLVED_PRODUCT, activeProductId: '' });
  };
}
