import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { getParams } from 'client-utils/utilities-router';
import Button from 'shared/components/Button/button';
import { Product } from 'plp/ProductListPage/components/ProductList/components/Product/product';
import {
  getProductImagePath,
  getProductAlternateImage,
} from 'bgo-common/components/Util/product-cloudinary';
import closeSVG from 'assets/images/close.svg';
import { EyeBrow6 } from 'bgo-common/Styleguide/Typography';
import { closeModal } from 'shared/components/Modal/actions';
import {
  getShopTheStyleProduct,
  clickTracker,
} from '../../../actions/actions-shopthestyle';

class ShopTheStyleGridContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfProducts: 8,
    };

    this.skeleton = this.skeleton.bind(this);
  }

  componentDidMount() {
    const { props } = this;
    props.getShopTheStyleProduct(props.catagoryId);
  }

  closeModal = e => {
    e.preventDefault();
    this.props.closeModal('ShopTheStyleModal');
  };

  // TODO: Move this to a separate generic and dynamic component that can be reused for different mudules.
  skeleton = () => {
    return (
      <div className="skeletons">
        <div className="skeleton" />
        <div className="skeleton" />
        <div className="skeleton" />
        <div className="skeleton" />
      </div>
    );
  };

  render() {
    const { productList, stsCategory } = this.props;
    const categoryPath = `/c/${stsCategory}`;
    const { numberOfProducts } = this.state;
    const navPath = isEmpty(getParams(this.props.location).navpath)
      ? this.props.defaultPath
      : getParams(this.props.location).navpath;
    return !isEmpty(productList) ? (
      <Fragment>
        <div className="shop-the-style-grid-container">
          {productList &&
            productList
              .slice(0, numberOfProducts)
              .map(product => (
                <Product
                  key={product.id}
                  id={product.id}
                  name={product.name}
                  designer={product.designer}
                  price={product.price}
                  url={getProductImagePath(product, true)}
                  altImage={getProductAlternateImage(product, true)}
                  cmosItem={product.cmosItem}
                  cmosCatalogId={product.cmosCatalog}
                  pimStyle={product.pimStyle}
                  promotions={product.promotions}
                  productUrl={get(product, 'canonical', `/p/${product.id}`)}
                  focusProductId={get(product, 'focusId', '')}
                  dispatch={this.props.dispatch}
                  preOrder={product.preOrder}
                  productFlags={product.productFlags}
                  isNewArrivalCategory={this.props.isNewArrivalCategory}
                  isDisplayAsGroups={this.props.isDisplayAsGroups}
                  displayable={product.displayable}
                  isFavorite={product.isFavorite}
                  clickTrackingUrl={product.clickTrackingUrl}
                  navPath={navPath}
                  applicableDiscount={product.applicableDiscount}
                  source={product.source && product.source.toLowerCase()}
                  isGroup={product.isGroup}
                  childProducts={get(product, 'childProducts', [])}
                  childProductIds={get(product, 'childProductIds', [])}
                  hasMoreItems={product.hasMoreItems}
                  hasMoreSwatches={product.moreSwatches}
                  oneSwatch={product.oneSwatch}
                  hasCurrated
                  isCloudinary
                  moveProductTogglesToList={this.props.moveProductTogglesToList}
                  clickTracker={clickTracker}
                />
              ))}
        </div>
        {productList && productList.length > numberOfProducts && (
          <a href={categoryPath}>
            <Button secondary value="See More" onClick={clickTracker} />
          </a>
        )}
        <span
          className="sts-close hide-on-desktop hide-on-tablet"
          onClick={e => this.closeModal(e)}
        >
          <img
            alt="Close"
            aria-hidden="true"
            className="close-icon"
            height="16"
            src={closeSVG}
            width="16"
          />
          <EyeBrow6> Close</EyeBrow6>
        </span>
      </Fragment>
    ) : (
      this.skeleton()
    );
  }
}

const mapStateToProps = state => ({
  productList: state.sts.stsResponse,
  moveProductTogglesToList: get(
    state.toggles,
    'MOVE_PRODUCT_TOGGLES_TO_LIST',
    false,
  ),
  stsCategory: get(state, 'modal.stsProduct.categoryId', ''),
});

const mapDispatchToProps = dispatch => ({
  getShopTheStyleProduct: catagoryId =>
    dispatch(getShopTheStyleProduct(catagoryId)),
  closeModal: () => dispatch(closeModal()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShopTheStyleGridContainer);
