import React from 'react';
import classNames from 'classnames';
import Column from '../Common/Column/column';
import buildArrayOfCategoriesByColumnBreakFlg from '../drawerUtility';
import './fullWidthDrawer.scss';

const StandardDrawer = ({ silo, style, closeFunction,
  hidden, delayHoverOnSilos = false, getPromoContent, getPlpSinglePage }) => {
  const arrayOfCategoriesGrouped = buildArrayOfCategoriesByColumnBreakFlg(silo.categories);

  const promoBannerJsx = getPromoContent() ? <div className="silo-column-promo-container">{getPromoContent()}</div> : false;

  const arrayOfColumns = arrayOfCategoriesGrouped
      .map((categories, index) => Column({ key: index, categories },
        getPlpSinglePage, closeFunction, arrayOfCategoriesGrouped, promoBannerJsx));

  const columnCount = arrayOfColumns.length;
  
  return (
      <section
        className={classNames('silo-group full-width-drawer delay-hover-on-silos', { 'drawer-hidden': hidden })}
      >
        <div className={`silo-parent col-count-${columnCount}`}>
          <div className="silo-column-group">
            {arrayOfColumns}
            {/* {promoBannerJsx && columnCount === 4 ? (
              <div className="silo-column">
                {promoBannerJsx}
              </div>
            ) : null} */}
          </div>  
          {promoBannerJsx && columnCount !== 4 ? (
            <div className="silo-column-promo-group">
              {promoBannerJsx}
            </div>
          ) : null}
        </div>
        <div className="gradient-cover" />
      </section>
  );
};

export default StandardDrawer;