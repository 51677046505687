export const TOGGLE_SILO_SELECTED = 'TOGGLE_SILO_SELECTED';
export const DESIGNER_SEARCH_UPDATE = 'DESIGNER_SEARCH_UPDATE';
export const DESIGNER_INDEX_DESIGNER_SEARCH_UPDATE = 'DESIGNER_INDEX_DESIGNER_SEARCH_UPDATE';
export const TOGGLE_ACCOUNT_SELECTED = 'TOGGLE_ACCOUNT_SELECTED';

export function toggleSiloSelected(id, catmanId) {
  return dispatch => dispatch({ type: TOGGLE_SILO_SELECTED, id, catmanId });
}

export function designerSearchUpdate(searchTerm) {
  return dispatch => dispatch({ type: DESIGNER_SEARCH_UPDATE, searchTerm });
}

export function designerIndexDesignerSearchUpdate(searchTerm) {
  return (dispatch, getState) => {
    const state = getState();
    const { templates } = state;
    dispatch({ type: DESIGNER_INDEX_DESIGNER_SEARCH_UPDATE, payload: { searchTerm, templates } });
  };
}

export function toggleAccountSelected(accountExpanded) {
  return dispatch => dispatch({ type: TOGGLE_ACCOUNT_SELECTED, accountExpanded });
}
