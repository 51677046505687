import React, { Children, useEffect, useState } from 'react';
import { array, object, oneOfType, string } from 'prop-types';
import ReactSlider from 'react-slider';
import { InView } from 'react-intersection-observer';
import classNames from 'classnames';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { StorageHandler } from 'shared/Storage/StorageHandler';
import { EntityWithSlideBarParams } from '../../constants/constants';
import './entityWithSlideBar.scss';

const { itemsPerView, landscapeTag, viewClasses } = EntityWithSlideBarParams;

const initialState = isMainEditLandscapeEnabled => {
  return {
    amount: itemsPerView[isMainEditLandscapeEnabled ? 2 : 1],
    transitioning: false,
    view: 2,
  };
};

const storage = new StorageHandler();

/**
 * @function  changeView
 * @param {Number} value
 * @param {Function} updateState
 * @param {Number} currentView
 */
const changeView = (value, updateState, currentView) => {
  if (value !== currentView) {
    storage.setPreferenceView(value);
    document
      .getElementById('switchedLayout')
      .scrollIntoView({ behavior: 'smooth' });
    updateState({
      view: value,
      amount: itemsPerView[value - 1],
      transitioning: true,
    });

    setTimeout(() => {
      updateState({ transitioning: false });
    }, 400);
  }
};

/**
 * @function EntityWithSlideBar
 * @param {Object} props.children
 * @param {String} [props.string]
 * @returns
 */
const EntityWithSlideBar = ({
  children,
  className,
  isMainEditLandscapeToggle,
  isDesktop,
}) => {
  const [entityState, updateEntityState] = useState(
    initialState(isMainEditLandscapeToggle && isDesktop),
  );
  const updateState = updates =>
    updateEntityState(prevState => ({ ...prevState, ...updates }));
  const { amount, transitioning, view } = entityState;
  const isMainEditLandscapeEnabled = isDesktop && isMainEditLandscapeToggle;
  const filteredChildren = Children.toArray(children).map(child =>
    isArray(get(child.props, 'children'))
      ? get(child.props, 'children').filter((subChild, i) => i < amount)
      : child,
  );
  const filteredWithMobileOld = [...filteredChildren];
  const [filteredWithMobile, updateFilteredWithMobile] = useState([]);

  const filterImages = images => {
    if (isArray(images[0])) {
      images[0].forEach(child => {
        if (
          get(child.props.cmsLayout.fields, 'tags.fields.name') === landscapeTag
        ) {
          const imageFields =
            child.props.cmsLayout.fields.components[0].fields.image.fields;
          if (get(imageFields, 'mobileImage')) {
            imageFields.desktopImage = imageFields.mobileImage;
          }
        }
      });
    }
  };
  if (!isMainEditLandscapeEnabled) {
    filterImages(filteredWithMobileOld);
  }

  useEffect(() => {
    const preference = storage.getPreferenceView();
    const filteredWithMobile = isMainEditLandscapeEnabled
      ? cloneDeep(filteredChildren)
      : [...filteredChildren];
    filterImages(filteredWithMobile);
    updateFilteredWithMobile(filteredWithMobile[0]);
    if (preference)
      updateState({ view: preference, amount: itemsPerView[preference - 1] });
  }, []);

  const loadImages = () => {
    if (view === 1) {
      return filteredChildren;
    } else if (isMainEditLandscapeEnabled) {
      return view === 2
        ? filteredWithMobile.slice(0, itemsPerView[view - 1])
        : filteredWithMobile;
    } else {
      return filteredWithMobileOld;
    }
  };
  return (
    <InView>
      {({ inView, ref }) => (
        <div
          ref={ref}
          id="switchedLayout"
          className={classNames(className, `view-${viewClasses[view - 1]}`, {
            transitioning,
          })}
        >
          {loadImages()}
          {inView && (
            <div className="slider-container">
              <ReactSlider
                min={1}
                max={3}
                step={1}
                value={view}
                ariaLabelledby="slider-label"
                className="view-slider"
                thumbClassName="slider-thumb"
                trackClassName="slider-track"
                onChange={e => changeView(e, updateState, view)}
                id="slider"
              />
              <label htmlFor="slider" id="slider-label">
                VIEW
              </label>
            </div>
          )}
        </div>
      )}
    </InView>
  );
};

EntityWithSlideBar.defaultProps = {
  className: '',
};

EntityWithSlideBar.propTypes = {
  children: oneOfType([array, object]).isRequired,
  className: string,
};

export default EntityWithSlideBar;
