import React from 'react';
// import logo from '../../../assets/images/mobilelogo.svg';
// import fullWidthNMLogo from '../../../assets/images/bg-logo-horizontal.svg';
import { DesktopLogo, MobileLogo } from './logo';

import './styles.scss';

const BrandLogo = () => (
  <div
    role="banner"
    className="headerBannerLogo column is-4"
    itemScope
    itemType="http://schema.org/WPHeader"
    id="http://www.BergdorfGoodman.com"
  >
    <div className="header-brand-site-logo-container__link">
      <a
        href="/"
        itemProp="name"
        title="Bergdorf Goodman"
        className="header-brand-site-logo-container__site-logo"
        aria-label="Bergdorf Goodman"
      >
        <span className="hide-on-desktop hide-on-tablet">
          <MobileLogo />
        </span>
        <span className="hide-on-mobile">
          <DesktopLogo />
        </span>
      </a>
    </div>
  </div>
);

export default BrandLogo;
