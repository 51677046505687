import React from 'react';
import Slider from 'react-slick';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import CarouselPromoTile from '../productCarouselPromoTile';
import ProductCarouselTile from '../productCarouselTile';

class SlickProductCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFlex: false,
      sliderLtoScroll: 6.3,
      sliderMtoScroll: 4.3,
      sliderStoScroll: 2.3,
    };
  }

  getSliderSettings() {
    const { isNewHomePage } = this.props;
    return {
      infinite: false,
      arrows: true,
      autoplay: false,
      slidesToShow: isNewHomePage ? 7.3 : 7,
      slidesToScroll: isNewHomePage ? this.state.sliderLtoScroll : 7,
      className: isNewHomePage && this.state.isFlex ? 'slider-flex' : '',
      responsive: [
        {
          breakpoint: 767,
          settings: {
            arrows: false,
            infinite: false,
            slidesToShow: isNewHomePage ? 2.45 : 3,
            slidesToScroll: isNewHomePage ? this.state.sliderStoScroll : 2,
            touchMove: !isNewHomePage,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            arrows: true,
            slidesToShow: isNewHomePage ? 5.05 : 5,
            slidesToScroll: isNewHomePage ? this.state.sliderMtoScroll : 5,
            draggable: true,
            afterChange: pos => {
              if (isNewHomePage) {
                this.updateSliderSettings(pos, 'sliderM');
              }
            },
          },
        },
        {
          breakpoint: 1919,
          settings: {
            arrows: true,
            slidesToShow: isNewHomePage ? 7.3 : 5,
            slidesToScroll: isNewHomePage ? this.state.sliderLtoScroll : 5,
            afterChange: pos => {
              if (isNewHomePage) {
                this.updateSliderSettings(pos, 'sliderL');
              }
            },
          },
        },
      ],
      onInit: () => {
        if (isNewHomePage) {
          this.setState({ isFlex: true });
        }
      },
      afterChange: pos => {
        if (isNewHomePage) {
          this.updateSliderSettings(pos, 'sliderL');
        }
      },
    };
  }

  getVerticalSliderSettings(numOfProds) {
    return {
      infinite: false,
      arrows: false,
      autoplay: false,
      slidesToShow: numOfProds,
      slidesToScroll: numOfProds,
      vertical: true,
    };
  }

  updateSliderSettings(pos, sliderType) {
    const slidesLength = this.slider.innerSlider.props.children.length;
    const brp = get(this.slider, 'state.breakpoint');
    const brpData = this.slider.props.responsive.find(
      item => item.breakpoint === brp,
    );
    const slidesToShow = brpData
      ? brpData.settings.slidesToShow
      : get(this.slider, 'props.slidesToShow');
    // check if there are more than 2 scrolls:
    if (slidesLength / slidesToShow > 2) {
      // change scroll after first change to make partial for both sides:
      if (pos === this.state[`${sliderType}toScroll`]) {
        // if < 2 scrolls left:
        if (slidesLength / slidesToShow - 1 < 2) {
          this.setState({
            [`${sliderType}toScroll`]: Math.floor(slidesToShow),
          });
        }
        // if >= 2 scrolls left:
        else {
          this.setState({
            [`${sliderType}toScroll`]: Math.floor(slidesToShow) - 1,
          });
        }
      }
      // change scroll to initial value:
      if (pos === 0) {
        this.setState({ [`${sliderType}toScroll`]: slidesToShow - 1 });
      }
    }
  }

  render() {
    const {
      cmsContentItem,
      combinedProducts,
      firstPromoItem,
      lastPromoItem,
      carouselDirection,
      mergedTrackingTags,
      isNewHomePage,
    } = this.props;

    return (
      <Slider
        ref={slider => {
          this.slider = slider;
        }}
        {...(carouselDirection === 'Vertical'
          ? this.getVerticalSliderSettings(
              !isEmpty(combinedProducts) && combinedProducts.length,
            )
          : this.getSliderSettings())}
      >
        {!isEmpty(firstPromoItem) && (
          <div className="promo-tile">
            <CarouselPromoTile
              key={firstPromoItem.sys.id}
              parentTrackingTags={mergedTrackingTags}
              promoItem={firstPromoItem}
            />
          </div>
        )}
        {!isEmpty(combinedProducts) &&
          combinedProducts.map(
            ({
              designerName,
              displayable,
              driveToUrl,
              id,
              imageUrl,
              name,
              price,
              promotions,
            }) => (
              <ProductCarouselTile
                designer={designerName}
                displayable={displayable}
                displayOptions={cmsContentItem && cmsContentItem.fields}
                fromNewHPCarousel={isNewHomePage}
                id={id}
                imageUrl={imageUrl}
                key={id}
                name={name}
                parentTrackingTags={mergedTrackingTags}
                price={price}
                promotions={promotions}
                url={driveToUrl}
              />
            ),
          )}
        {!isEmpty(lastPromoItem) && (
          <div className="promo-tile">
            <CarouselPromoTile
              key={lastPromoItem.sys.id}
              parentTrackingTags={mergedTrackingTags}
              promoItem={lastPromoItem}
            />
          </div>
        )}
      </Slider>
    );
  }
}

export default SlickProductCarousel;
