import React, { useEffect } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { formatNumber } from 'client-utils/utilities-number';
import bagIcon from '../../../assets/images/icons-bag-empty.svg';
import {
  showDtMinicart,
  hideDtMiniCart,
  loadDtMiniCartData,
  startTimer,
} from './miniCartDT-actions';
import MiniCartItem from './MiniCartItem';
import { cartLink, checkoutLink } from './constants';
import './MiniCartDT.scss';

const MiniCartDT = ({
  isMiniCartOpen,
  showDtMinicart,
  hideDtMiniCart,
  miniCartQty,
  miniCartItems,
  miniCartSubtotal,
  loadDtMiniCartData,
  startTimer,
  timerId,
}) => {
  useEffect(() => {
    loadDtMiniCartData();
  }, []);

  const shoppingBagSvg = String(bagIcon);
  const shoppingBagAltText = `Shopping Bag containing ${miniCartQty} items`;

  return (
    <div className="minicart-dt">
      <div className="minicart-dt__desktop">
        <div
          className={classnames(
            'minicart-dt__container',
            isMiniCartOpen && 'minicart-dt__container__open',
          )}
          onMouseOut={startTimer}
          onBlur={startTimer}
          onMouseOver={() => clearTimeout(timerId)}
          onFocus={() => clearTimeout(timerId)}
        >
          <div className="minicart-dt__container__header">
            {miniCartQty > 0 ? (
              <button
                className="minicart-dt__container__header__btn"
                type="button"
                onClick={showDtMinicart}
              >
                <span>Bag</span>
                <span className="minicart-dt__container__header__qty">
                  {miniCartQty}
                </span>
              </button>
            ) : (
              <a
                className="minicart-dt__container__header__link"
                href={cartLink}
              >
                Bag
              </a>
            )}
          </div>
          {isMiniCartOpen && (
            <div className="minicart-dt__content">
              <div className="minicart-dt__items">
                {miniCartItems.slice(0, 3).map(item => (
                  <MiniCartItem key={item.dt_itemId} data={item} />
                ))}
              </div>
              {miniCartSubtotal && (
                <div className="minicart-dt__subtotal">
                  <span>Subtotal</span>
                  <span>${formatNumber(miniCartSubtotal)}</span>
                </div>
              )}
              <div className="minicart-dt__buttons">
                <a
                  href={checkoutLink}
                  className="minicart-dt__buttons__checkout"
                >
                  Checkout
                </a>
                <a href={cartLink} className="minicart-dt__buttons__cart">
                  View Bag
                </a>
              </div>
            </div>
          )}
        </div>
        {isMiniCartOpen && (
          <div
            onClick={hideDtMiniCart}
            onKeyDown={hideDtMiniCart}
            className="minicart-dt__shadow"
          />
        )}
      </div>
      <div className="minicart-dt__mobile">
        <a className="minicart-dt__mobile__link" href={cartLink}>
          <img src={shoppingBagSvg} alt={shoppingBagAltText} />
          {miniCartQty > 0 ? (
            <span className="minicart-dt__mobile__qty">{miniCartQty}</span>
          ) : (
            ''
          )}
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  isMiniCartOpen: state?.miniCartDT?.isMiniCartOpen || false,
  miniCartQty: state?.miniCartDT?.miniCartQty || 0,
  miniCartItems: state?.miniCartDT?.miniCartItems || [],
  miniCartSubtotal: state?.miniCartDT?.miniCartSubtotal || null,
  timerId: state?.miniCartDT?.timerId || 0,
});

const mapDispatchToProps = {
  showDtMinicart,
  hideDtMiniCart,
  loadDtMiniCartData,
  startTimer,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MiniCartDT);
