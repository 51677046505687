import React, { Component } from 'react';
import getSelectedCustomizationChoice from 'productpage/selectors/getSelectedCustomizationChoice';
import './selectedPersonalizations.scss';

export class SelectedPersonalizations extends Component {
  render() {
    const { product } = this.props;
    const selectedMonoStyleChoice = getSelectedCustomizationChoice(
      product,
      'mono style',
    );
    const selectedThreadColorChoice = getSelectedCustomizationChoice(
      product,
      'thread color',
    );
    const enteredText = getSelectedCustomizationChoice(product, 'output data');

    const notValidLegacyData = [
      selectedMonoStyleChoice,
      selectedThreadColorChoice,
      enteredText,
    ].some(data => {
      return data === undefined;
    });

    const getDisplayText = () => {
      const monoStyleChoiceType = selectedMonoStyleChoice.type;
      if (monoStyleChoiceType === 'THREE_INIT') {
        const [input1 = '', input2 = '', input3 = ''] = enteredText;
        return input1 + input3 + input2;
      }
      return enteredText.join('');
    };

    return notValidLegacyData ? null : (
      <div className="tablet-grid-100 mobile-grid-100 selected-personalization grid-parent personalization__spacing">
        <div className="selected-personalization__selections__output-text hide-on-desktop hide-on-tablet">
          <div>{`${'Text: '}${getDisplayText()}`}</div>
        </div>
        <div className="selected-personalization__selections">
          <div className="selected-personalization__selections__style">
            <div className="selected-personalization__selections__style__icon">
              <img
                src={`${selectedMonoStyleChoice.url}`}
                title={`${'Style '}${selectedMonoStyleChoice.displayName}`}
                alt={`${'Style '}${selectedMonoStyleChoice.displayName}`}
              />
            </div>
            <div className="selected-personalization__selections__style__text">
              Style:
              <br />
              <span>{`Style ${selectedMonoStyleChoice.displayName}`}</span>
            </div>
          </div>

          <div className="selected-personalization__selections__output-text hide-on-mobile">
            <div>Text:</div>
            <div>{getDisplayText()}</div>
          </div>

          <div className="selected-personalization__selections__thread-color">
            <div className="selected-personalization__selections__thread-color__icon">
              <img
                src={`${selectedThreadColorChoice.url}`}
                title={`${selectedThreadColorChoice.displayName}`}
                alt={`${selectedThreadColorChoice.displayName}`}
              />
            </div>
            <div className="selected-personalization__selections__thread-color__text">
              <div>Thread Color:</div>
              <div>{`${selectedThreadColorChoice.displayName}`}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
