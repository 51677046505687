/* eslint-disable no-nested-ternary */
import React from 'react';
import { object } from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y, Keyboard, Navigation } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/a11y/a11y.scss';
import RenderContentItem from '../../contentItem/contentItem';

import './swiperVisualNav.scss';

SwiperCore.use([A11y, Keyboard, Navigation]);

const SwiperVisualNav = props => {
  const {
    cmsContentItem,
    mergedTrackingTags,
    plpVisualNav,
    lssShopsCarousel,
    homePage,
  } = props;
  const { slides } = cmsContentItem.fields;

  const handleVisualNavItem = visualNavTxtLink => {
    if (window && window.utag && window.utag.link && window.utag_data) {
      const data = {
        ...window.utag_data,
        previous_visualnav_submit: 'true',
        previous_visualnav_txtlink: visualNavTxtLink,
      };
      window.utag.link(data);
    }
  };

  return (
    <div className="bubble-visual-nav new-home-page-nav swiper-visual-nav">
      <Swiper
        slidesPerView={plpVisualNav ? 2.3 : 1.3}
        slidesPerGroup={1}
        breakpoints={{
          451: {
            slidesPerView: plpVisualNav
              ? 2.3
              : lssShopsCarousel && !homePage
              ? 1.25
              : 2,
            slidesPerGroup: 1,
          },
          500: {
            slidesPerView: 2,
            slidesPerGroup: 1,
          },
          768: {
            slidesPerView: plpVisualNav
              ? 6
              : lssShopsCarousel && !homePage
              ? 4
              : 3,
            slidesPerGroup: 1,
          },
          1441: {
            slidesPerView:
              plpVisualNav || (lssShopsCarousel && !homePage) ? 6 : 4,
            slidesPerGroup: 1,
          },
        }}
        freeMode
        navigation
        keyboard={{
          enabled: true,
        }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide
            key={`${slide.sys.id}${index.toString()}`}
            onClick={() =>
              plpVisualNav && handleVisualNavItem(`${slide?.fields?.name}`)}
          >
            <RenderContentItem
              cmsContentItem={slide}
              parentTrackingTags={mergedTrackingTags}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

SwiperVisualNav.propTypes = {
  cmsContentItem: object.isRequired,
  mergedTrackingTags: object.isRequired,
};

export default SwiperVisualNav;
