import isEmpty from 'lodash/isEmpty';
import logger from 'server/utilities/logger';

export default (skus, options, selectedIndexes, isR2Enabled) => {
  const { colorSkus, sizeSkus } = skus;
  const { colorOptions, sizeOptions } = options;
  const { selectedColorIndex, selectedSizeIndex } = selectedIndexes;
  let selectedSku;

  if (!isEmpty(colorOptions.values) && !isEmpty(colorSkus)) {
    selectedSku = colorSkus[selectedColorIndex];

    if (isR2Enabled) {
      logger.info(selectedSku);
    }
  } else if (!isEmpty(sizeOptions.values) && !isEmpty(sizeSkus)) {
    if (selectedSizeIndex === -1 && sizeOptions.values.length === 1) {
      selectedSku = sizeSkus[0];
      logger.info(selectedSku);
    }

    if (selectedSizeIndex !== -1) {
      selectedSku = sizeSkus[selectedSizeIndex];
    }

    if (isR2Enabled) {
      logger.info(selectedSku);
    }
  } else if (colorSkus.length === sizeSkus.length) {
    selectedSku = colorSkus[0];

    if (isR2Enabled) {
      logger.info(selectedSku);
    }
  }

  return selectedSku;
};
