import React from 'react';
import AppMyAccountWrapper from './app-my-account-wrapper';
import PaymentDetailsPage from '../../myaccount/pages/PaymentDetailsPage/PaymentDetailsPage';

export default function AppPaymentDetailsWrapper() {
  return (
    <AppMyAccountWrapper>
      <PaymentDetailsPage />
    </AppMyAccountWrapper>
  );
}
