export default (dom = window) => () => next => action => {
  if (action.type === 'REJECTED_PRODUCT') {
    const window = dom || {};
    window.location = `${NMConfig.ERROR_PAGE_URL}`;
  } else if (action.type === 'REJECTED_SEARCH_LIST') {
    const window = dom || {};
    window.location = `${NMConfig.ERROR_PAGE_URL}?source=srp`;
  } else {
    next(action);
  }
};
