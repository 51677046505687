import React from 'react';
import get from 'lodash/get';
import { mark, measure } from 'client/utilities/utilities-performance';
import { MONOGRAM_HELPLINE } from 'pdp/constants';
import ItemNotAvailable from './itemNotAvailable';
import CheckoutOptionInfo from './checkoutOptionInfo';
import CallForAvailability from './callForAvailability';
import './callForAvailability.scss';
import './monogramHelpline.scss';

const hasMixedTypeCustomizationOptionsIncludingPDP = product => {
  let hasMixedType = false;
  const types = [];
  if (product.customization && product.customization.customizationOptions) {
    product.customization.customizationOptions.forEach(element => {
      types.push(element.type);
      if (element.type !== 'PDP') {
        hasMixedType = true;
      }
    });
  }
  return hasMixedType && types.filter(type => type === 'PDP').length > 0;
};

export const shouldDisplayMonogramHelpLineText = product => {
  const {
    isCustomizable = false,
    customizationSupported = true,
    customization = {},
  } = product;
  const behaviorCode = get(customization, 'behaviorCode', '');
  return (
    isCustomizable &&
    (!customizationSupported ||
      hasMixedTypeCustomizationOptionsIncludingPDP(product)) &&
    behaviorCode !== 'N'
  );
};

export const renderCheckoutOrHelplineText = (
  product,
  defaultCheckoutInfo,
  itemNotAvailableMessage,
) => {
  const { displayable = false } = product;

  const suppressCheckout = get(product, 'details.suppressCheckout', false);

  const MonogramHelpline = (
    <div className="grid-100 checkout-option__info__spacing">
      <div className="monogramHelpline">{MONOGRAM_HELPLINE}</div>
    </div>
  );

  const suppressCheckoutSection = suppressCheckout ? (
    <CallForAvailability />
  ) : (
    defaultCheckoutInfo
  );

  const getCheckoutInfo = product => {
    return shouldDisplayMonogramHelpLineText(product)
      ? MonogramHelpline
      : suppressCheckoutSection;
  };

  return displayable ? getCheckoutInfo(product) : itemNotAvailableMessage;
};

class CheckoutOptionRenderer extends React.Component {
  constructor() {
    super();
    mark('product options (construct)');
  }

  componentDidMount() {
    measure('product options (TTI)');
    measure(
      'product options (self render time)',
      'product options (construct)',
    );
  }

  render() {
    const { product, boss } = this.props;
    const { metadata = {} } = product;

    const itemNotAvailableMessage = (
      <ItemNotAvailable>
        <span className="undisplayable__code">{`${metadata.cmosCatalogId}_${metadata.cmosItem}`}</span>
      </ItemNotAvailable>
    );

    const defaultCheckoutInfo = (
      <CheckoutOptionInfo product={product} boss={boss} />
    );
    return renderCheckoutOrHelplineText(
      product,
      defaultCheckoutInfo,
      itemNotAvailableMessage,
    );
  }
}

export default CheckoutOptionRenderer;
