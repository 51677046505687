import React from 'react';
import IncircleLogo from './IncircleLogo';
import AmericanExpressUpdate from './AmericanExpressUpdate';
import NotAMember from './NotAMember';
import TermsAndConditions from './TermsAndConditions';

const AmericanExpressUpdatePage = () => {
  return (
    <main className="american-express-update-page">
      <IncircleLogo />
      <AmericanExpressUpdate />
      <NotAMember />
      <TermsAndConditions />
    </main>
  );
};

export default AmericanExpressUpdatePage;
