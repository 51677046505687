import React from 'react';
import { DesktopLogo } from '@bgo-ui/common/components/Header/BrandLogo/logo';
import './LoginCommonLogo.scss';

const LoginCommonLogo = () => (
  <div
    role="banner"
    className="login-common-logo"
    itemScope
    itemType="http://schema.org/WPHeader"
    id="http://www.BergdorfGoodman.com"
  >
    <a
      href="/"
      itemProp="name"
      title="Bergdorf Goodman"
      className="login-common-logo__site-logo"
      aria-label="Bergdorf Goodman"
    >
      <DesktopLogo />
    </a>
  </div>
);

export default LoginCommonLogo;
