import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import sumBy from 'lodash/sumBy';
// import HoverIntent from 'react-hoverintent';
// import shoppingBagSvgPurple from 'assets/images/shopping-bag.svg';
// import shoppingBagSvgBalck from 'assets/images/shopping-bag-black.svg';

import { EyeBrow1, EyeBrow2 } from 'bgo-common/Styleguide/Typography';
import { putComponentInFocus } from 'client/common/components/App/app-actions';
import MiniCartErrorModal from '../MiniCartErrorModal/miniCartErrorModal';
import {
  showMiniCart,
  startMiniCartCloseAnimation,
  startTimer,
  setMouseOverShoppingBagTo,
  showInternationalMiniCart,
  updateMiniCartItemCount,
} from '../miniCart-actions';
import './desktopTabletMiniCart.scss';
import './miniCartATGStyles.scss';
// import {data} from '../html';
// const HTML = data;

const DesktopTabletMiniCart = props => {
  const {
    cartInfo,
    noOfItemsInCart,
    currentFocusedComponent,
    timerId,
    showMiniCart,
    showInternationalMiniCart,
    startMiniCartCloseAnimation,
    animateClosed,
    startTimer,
    cartOpened,
    setMouseOverShoppingBagTo,
    putComponentInFocus,
    user,
    isDomestic,
    internationalToggle,
    updateMiniCartItemCount,
    currencyCode,
    countryCode,
  } = props;

  const [isApplePayClicked, setApplePayClicked] = useState(false);
  const isInFocus = currentFocusedComponent === '#miniCartContainer';
  const isLoggedIn = user.name && user.securityStatus === 'Authenticated';
  const shoppingBagLink =
    isLoggedIn && noOfItemsInCart
      ? '/checkout/cart.jsp?perCatId=&catqo=&co=true'
      : '/checkout/cart.jsp?perCatId=&catqo=&showAllSFL=true';

  const renderMiniCartContents = () => {
    if (isEmpty(cartInfo)) {
      return <MiniCartErrorModal />;
    }
    return <div dangerouslySetInnerHTML={{ __html: cartInfo }} />;
  };

  const clickCloseButtonToHideMiniCart = e => {
    if (e.target.className === 'cart-close-x close_btn') {
      startMiniCartCloseAnimation();
    }
  };

  const openMiniCart = () => {
    if (noOfItemsInCart) {
      setMouseOverShoppingBagTo(true);
      internationalToggle && !isDomestic
        ? showInternationalMiniCart()
        : showMiniCart();
    } else {
      const bagLink =
        internationalToggle && !isDomestic
          ? '/international/cart'
          : shoppingBagLink;
      window.location.assign(bagLink);
    }
  };

  useEffect(() => {
    if (internationalToggle && !isDomestic && localStorage) {
      const intlCartName = `internationalCart_${countryCode}_${currencyCode}`;
      const localInternationalCart = (() => {
        const localInternationalCartData = localStorage.getItem(intlCartName);
        return localInternationalCartData
          ? JSON.parse(localInternationalCartData)
          : [];
      })();
      const cartDataAvailable =
        localInternationalCart && localInternationalCart.length > 0;
      const cartQuantity =
        cartDataAvailable &&
        sumBy(localInternationalCart, item => Number(item.productQty));
      updateMiniCartItemCount(cartQuantity);
    }
    const isApplePayClicked = window?.sessionStorage?.getItem('apple_pay_pdp');
    setApplePayClicked(isApplePayClicked);
  }, []);

  const closeMiniCartFromOutside = () => {
    putComponentInFocus('');
  };

  if (animateClosed) {
    const removeAdded = document.getElementById('miniCartContainer').classList;
    if (removeAdded.contains('itemAdded')) {
      removeAdded.remove('itemAdded');
    }
  }

  // const shoppingBagAltText = `Shopping Bag containing ${noOfItemsInCart} items`; //Changed for BGO-500

  // const shoppingBagSvg = noOfItemsInCart > 0
  //   ? String(shoppingBagSvgPurple)
  //   : String(shoppingBagSvgBalck);

  return (
    <span
      id="miniCartContainer"
      className="miniCart-desktop-container desktop-tablet-only"
    >
      <button
        id="miniCartButton"
        aria-expanded={cartOpened}
        className="shopping-link hide-on-tablet hide-on-mobile"
        onClick={openMiniCart}
      >
        <span>
          <EyeBrow1>Bag</EyeBrow1>
        </span>
        {noOfItemsInCart && !isApplePayClicked ? (
          <span aria-hidden="true" className="miniCart-count">
            <EyeBrow2>{noOfItemsInCart}</EyeBrow2>
          </span>
        ) : (
          ''
        )}
      </button>
      <div
        className={classnames('miniCartOverlay', {
          'miniCartOverlay--animate': animateClosed,
        })}
        onClick={clickCloseButtonToHideMiniCart}
        onMouseOut={startTimer}
        onMouseOver={() => clearTimeout(timerId)}
      >
        {isInFocus && cartOpened ? renderMiniCartContents() : undefined}
      </div>
      {isInFocus && cartOpened ? (
        <div onClick={closeMiniCartFromOutside} className="minicart-shadow" />
      ) : (
        undefined
      )}
    </span>
  );
};

const mapStateToProps = state => ({
  cartInfo: state.miniCart.cartInfo,
  noOfItemsInCart: state.miniCart.noOfItemsInCart,
  currentFocusedComponent: state.app.componentInFocus,
  timerId: state.miniCart.timerId,
  animateClosed: state.miniCart.animateClosed,
  cartOpened: state.miniCart.cartOpened,
  user: state.user,
  isDomestic: get(state, 'locale.countryCode', 'US') === 'US',
  internationalToggle: get(state, 'toggles.INTERNATIONAL', false),
  currencyCode: get(state, 'locale.currencyCode', 'USD'),
  countryCode: get(state, 'locale.countryCode', 'US'),
});

const mapDispatchToProps = {
  showMiniCart,
  startMiniCartCloseAnimation,
  startTimer,
  setMouseOverShoppingBagTo,
  putComponentInFocus,
  showInternationalMiniCart,
  updateMiniCartItemCount,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DesktopTabletMiniCart);
