import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'shared/components/Button/button';
import ErrorMessage from 'productpage/components/ErrorMessage/errorMessage';
import { OOS_ERROR_MESSAGE } from 'pdp/constants';
import { openMonogram } from 'pdp/components/ProductPage/actions';

export class DumbPersonalizeItemButton extends Component {
  constructor() {
    super();
    this.state = { showErrorMessage: false };
    this.onClickPersonalize = this.onClickPersonalize.bind(this);
  }

  onClickPersonalize() {
    const { product, selectedSku } = this.props;
    if (selectedSku && !selectedSku.sellable) {
      this.setState({ showErrorMessage: true });
      return;
    }
    this.setState({ showErrorMessage: false });
    this.props.openMonogram(product);
  }

  render() {
    return (
      <div className="personalization-wrapper">
        {this.state.showErrorMessage && (
          <ErrorMessage message={OOS_ERROR_MESSAGE} />
        )}
        <div
          id="personalizeItemButton"
          className="grid-100 tablet-grid-100 mobile-grid-100 grid-parent"
        >
          <Button onClick={this.onClickPersonalize} value="Personalize" />
        </div>
      </div>
    );
  }
}

export default connect(
  undefined,
  { openMonogram },
)(DumbPersonalizeItemButton);
