import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { scroller, Element } from 'react-scroll';
import { validateEmail } from '@bgo-ui/common/client/utilities/utilities-amplify';
import { content, formInfo, validationErrors } from './constants';
import { submitConciergeData } from './actions';
import {
  IncircleConciergeAccordion,
  IncircleConciergeAccordionItem,
} from '../../widgets/accordion';
import IncircleConciergeForm from '../../widgets/IncircleConciergeForm';
import { STATE_LIST } from '../../../myaccount/stateList';
import './index.scss';

const IncircleConciergeContactUs = ({
  isSubmitting,
  isSubmitted,
  error,
  myAccountContent,
  submitConciergeData,
}) => {
  const [openIds, setOpenIds] = useState([]);

  const stateList =
    myAccountContent && myAccountContent['MYACCOUNT-STATELIST-US']
      ? JSON.parse(
          myAccountContent['MYACCOUNT-STATELIST-US'].content[0].content[0]
            .value,
        )
      : STATE_LIST;

  const initialFormValue = {
    subject: '',
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    state: '',
    postalCode: '',
    phoneNumber: '',
    email: '',
    comments: '',
  };

  const validateForm = values => {
    const errors = {};

    if (!values.subject?.trim()) {
      errors.subject = validationErrors.emptySubject;
    }

    if (!values.firstName?.trim()) {
      errors.firstName = validationErrors.emptyFirstName;
    }

    if (!values.lastName?.trim()) {
      errors.lastName = validationErrors.emptyLastName;
    }

    if (!values.address?.trim()) {
      errors.address = validationErrors.emptyAddress;
    }

    if (!values.city?.trim()) {
      errors.city = validationErrors.emptyCity;
    }

    if (!values.state) {
      errors.state = validationErrors.incorrectState;
    }

    if (!values.postalCode?.trim()) {
      errors.postalCode = validationErrors.emptyPostalCode;
    } else if (values.postalCode.length < 5) {
      errors.postalCode = validationErrors.invalidPostalCode;
    }

    if (!values.phoneNumber?.trim()) {
      errors.phoneNumber = validationErrors.emptyPhoneNumber;
    } else if (values.phoneNumber.length < 12) {
      errors.phoneNumber = validationErrors.invalidPhoneNumber;
    }

    if (!values.email?.trim()) {
      errors.email = validationErrors.emptyEmail;
    } else if (!validateEmail(values.email?.trim())) {
      errors.email = validationErrors.invalidEmail;
    }

    if (!values.comments?.trim()) {
      errors.comments = validationErrors.emptyComments;
    }

    return errors;
  };

  const handleSubmit = values => {
    submitConciergeData(values);
  };

  const updateOpenIds = (prev, id) => {
    let newIds;

    if (prev.includes(id)) {
      newIds = prev.filter(pid => pid !== id);
    } else {
      newIds = [...prev, id];
    }

    return newIds;
  };

  const handleToggle = id => {
    setOpenIds(prev => updateOpenIds(prev, id));
  };

  useEffect(() => {
    if (isSubmitted) {
      scroller.scrollTo(content.id, {
        duration: 500,
        delay: 0,
        smooth: true,
      });
    }
  }, [isSubmitted]);

  return (
    <Element
      name={content.id}
      id={content.id}
      className="incircle-concierge-contact-us"
    >
      <h3 className="incircle-concierge-contact-us__title">{content.title}</h3>
      <p className="incircle-concierge-contact-us__desc">{content.desc}</p>
      <div className="incircle-concierge-contact-us__items">
        <IncircleConciergeAccordion>
          {content.items?.map(item => (
            <IncircleConciergeAccordionItem
              key={`incircle-concierge-accordion-item-${item.id}`}
              id={item.id}
              title={item.title}
              isOpen={openIds.includes(item.id)}
              toggle={handleToggle}
            >
              {item.id === 'email-us' ? (
                <IncircleConciergeForm
                  formInfo={formInfo}
                  stateList={stateList}
                  isSubmitting={isSubmitting}
                  isSubmitted={isSubmitted}
                  error={error}
                  initialFormValue={initialFormValue}
                  validateForm={validateForm}
                  handleSubmit={handleSubmit}
                />
              ) : (
                item.desc
              )}
            </IncircleConciergeAccordionItem>
          ))}
        </IncircleConciergeAccordion>
      </div>
    </Element>
  );
};

const mapStateToProps = state => ({
  isSubmitting: state.conciergeForm.isSubmitting || false,
  isSubmitted: state.conciergeForm.isSubmitted || false,
  error: state.conciergeForm.error || false,
  myAccountContent: get(state, 'myaccount.myAccountContent', false),
});
const mapDispatchToProps = {
  submitConciergeData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IncircleConciergeContactUs);
