import React, { Component, Fragment } from 'react';
import ErrorMessage from 'productpage/components/ErrorMessage/errorMessage';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { getSelectedCustomizationsAllTypesById } from 'pdp/components/ProductPage/reducers/productReducer';
import { setSelectedCustomizationObjectById } from 'pdp/components/ProductPage/actions';

export class DumbEmailInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      enteredValue: '',
    };

    this.validateEmail = this.validateEmail.bind(this);
    this.getErrorMessage = this.getErrorMessage.bind(this);
  }

  getErrorMessage() {
    const { validated } = this.state;
    const {
      selectedValues,
      touched,
      isValid,
    } = this.props.selectedCustomizationData;

    if (touched && !selectedValues) {
      return 'Enter required information.';
    } else if (touched && validated && !isValid) {
      return 'The format of your email address is incorrect.';
    }
    return '';
  }

  validateEmail(value) {
    const lowerCase = value.toLowerCase();
    const regex = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

    if (value) {
      this.props.setSelectedCustomizationObjectById({
        id: get(this.props.selectedCustomizationData, 'id', ''),
        isValid: regex.test(lowerCase),
        selectedValues: value,
        touched: true,
      });
      this.setState({
        validated: true,
      });
    }
  }

  handleChange(event) {
    const value = event.target.value.trim();
    const { inputLength } = this.props.option.choices[0];
    this.setState({ enteredValue: event.target.value });

    if (value.length <= inputLength) {
      this.props.setSelectedCustomizationObjectById({
        id: get(this.props.selectedCustomizationData, 'id', ''),
        isValid: false,
        selectedValues: value,
        touched: true,
      });

      if (!value) {
        this.props.setSelectedCustomizationObjectById({
          id: get(this.props.selectedCustomizationData, 'id', ''),
          isValid: false,
          selectedValues: value,
          touched: true,
        });
        this.setState({
          validated: false,
        });
      }

      if (this.state.validated && value) {
        this.validateEmail(value);
      }
    }
  }

  render() {
    const { displayName } = this.props.option;
    const { inputLength } = this.props.option.choices[0];
    const { selectedValues = '' } = this.props.selectedCustomizationData;

    return (
      <Fragment>
        <div className="monogram-text-dmg__container">
          <input
            type="text"
            value={selectedValues}
            maxLength={inputLength}
            onChange={e => this.handleChange(e)}
            onBlur={e => {
              e.preventDefault();
              this.validateEmail(selectedValues);
            }}
            placeholder=" "
            id={displayName}
          />
          <label htmlFor={displayName}>{displayName}</label>
          <span className="monogram-text-dmg__character">{`${this.state.enteredValue.length}/${inputLength}`}</span>
        </div>

        <ErrorMessage message={this.getErrorMessage()} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    selectedCustomizationData: getSelectedCustomizationsAllTypesById(
      state,
      props,
    ),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setSelectedCustomizationObjectById: (...args) =>
    dispatch(setSelectedCustomizationObjectById(ownProps.productId, ...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbEmailInput);
