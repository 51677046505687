import React from 'react';
import { connect } from 'react-redux';
import { openMobileNav } from '../../../actions';
import './MyAccountMobileBackHeader.scss';

export function DumbMyAccountMobileBackHeader({ openMobileNav, title }) {
  return (
    <div className="my-account-mobile-back-header mobile-only">
      <div
        className="my-account-mobile-back-header__back-btn"
        role="button"
        aria-label="Back"
        onClick={openMobileNav}
        onKeyPress={openMobileNav}
        tabIndex="0"
      >
        <i className="my-account-mobile-back-header__back-btn-icon" />
      </div>
      <h1 className="my-account-mobile-back-header__text">{title}</h1>
    </div>
  );
}

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {
  openMobileNav,
};

const MyAccountMobileBackHeader = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbMyAccountMobileBackHeader);
export default MyAccountMobileBackHeader;
