import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Input from './Input';
import PhoneInput from './PhoneInput';
import Checkbox from './Checkbox';
import { validations, validate, formatters } from './validation';
import { signUp } from '../../../actions';
import {
  loadingStatus,
  signUpError,
  nameInputMaxLength,
  emailInputMaxLength,
} from '../../../constants';

const validateForm = values => {
  const validationErrors = {
    name: validate(values.name, validations.notEmpty),
    phone: validate(values.phone, [validations.notEmpty, validations.phone]),
    email: validate(values.email, [validations.notEmpty, validations.email]),
    services: validate(values.services, validations.services),
  };
  return {
    isValid:
      !validationErrors.name &&
      !validationErrors.phone &&
      !validationErrors.email &&
      !validationErrors.services,
    validationErrors,
  };
};

class SignUpModalContent extends Component {
  state = {
    values: {
      name: '',
      phone: '',
      email: '',
      services: [],
    },
    validationErrors: {
      name: null,
      phone: null,
      email: null,
      services: null,
    },
  };

  onChangeInput(name, value) {
    const values = { ...this.state.values, [name]: value };
    const validationErrors = { ...this.state.validationErrors, [name]: null };
    this.setState({ ...this.state, values, validationErrors });
  }

  onChangeCheckbox(service) {
    const { services } = this.state.values;
    const index = services.indexOf(service);

    if (index === -1) {
      services.push(service);
    } else {
      services.splice(index, 1);
    }

    const values = { ...this.state.values, services };
    const validationErrors = { ...this.state.validationErrors, services: null };
    this.setState({ ...this.state, values, validationErrors });
  }

  onSubmitForm(e) {
    e.preventDefault();

    const { values } = this.state;
    const { isValid, validationErrors } = validateForm(values);

    if (isValid) {
      this.props.signUp({
        name: values.name,
        email: values.email,
        phonenumber: values.phone,
        services: values.services.join(';'),
      });
    } else {
      this.setState({ ...this.state, validationErrors });
    }
  }

  render() {
    const { values, validationErrors } = this.state;
    const { services, signUpLoadingStatus } = this.props;
    const isLoading = signUpLoadingStatus === loadingStatus.loading;
    const isRejected = signUpLoadingStatus === loadingStatus.rejected;

    return (
      <div className="sign-up-container">
        <div className="title">
          Sign up for <span>BG 360°</span>
        </div>
        <form className="sign-up-form" onSubmit={e => this.onSubmitForm(e)}>
          <div className="col-1">
            <Input
              name="name"
              value={values.name}
              error={validationErrors.name}
              placeholder="Name"
              maxLength={nameInputMaxLength}
              onChange={value =>
                this.onChangeInput('name', formatters.name(value))}
            />
          </div>
          <div className="col-2">
            <PhoneInput
              name="phone"
              value={values.phone}
              error={validationErrors.phone}
              placeholder="Phone Number"
              onChange={value => this.onChangeInput('phone', value)}
            />
            <Input
              name="email"
              value={values.email}
              error={validationErrors.email}
              placeholder="Email Address"
              maxLength={emailInputMaxLength}
              onChange={value =>
                this.onChangeInput('email', formatters.email(value))}
            />
          </div>
          <div
            className={`services ${
              validationErrors.services ? 'not-valid' : ''
            }`}
          >
            <div className="services-title">
              <p>Service you’re interested in:</p>
              <p className="validation-error">{validationErrors.services}</p>
            </div>
            <div className="col-2">
              {services.map(service => (
                <Checkbox
                  key={`service-${service.title}`}
                  name={service.title}
                  value={values.services.includes(service.title)}
                  onChange={() => this.onChangeCheckbox(service.title)}
                />
              ))}
            </div>
          </div>
          <p className={`submit-error ${isRejected ? 'show' : ''}`}>
            {isRejected ? signUpError : ''}
          </p>
          <button className="white-button" type="submit" disabled={isLoading}>
            {isLoading ? 'Sending data...' : 'Sign Up'}
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  services: get(state, 'bg360.content.services', []),
  signUpLoadingStatus: get(state, 'bg360.signUpLoadingStatus', null),
});
const mapDispatchToProps = {
  signUp,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignUpModalContent);
