import { CROP } from './constants';
import { parseTransformation } from './parse';
import {
  ScaleTransformationApplier,
  FitTransformationApplier,
  FillTransformationApplier,
  PadTransformationApplier,
  CropTransformationApplier,
} from './cropTransformations';

export function calculateActualDimensions({
  width,
  height,
  raw_transformation: rawTransformation,
}) {
  try {
    const transformations = rawTransformation
      .split('/')
      .map(parseTransformation);
    const dimensions = transformations.reduce(
      (d, t) => applyTransformation(d, t),
      { width, height },
    );

    return {
      width: Math.round(dimensions.width),
      height: Math.round(dimensions.height),
    };
  } catch (e) {
    return undefined;
  }
}

function applyTransformation({ width, height }, transformation) {
  switch (transformation.cropMode) {
    // eslint-disable-next-line max-len
    case CROP.SCALE:
      return new ScaleTransformationApplier().apply(
        { width, height },
        transformation,
      );
    case CROP.FIT:
      return new FitTransformationApplier().apply(
        { width, height },
        transformation,
      );
    case CROP.FILL:
      return new FillTransformationApplier().apply(
        { width, height },
        transformation,
      );
    case CROP.PAD:
      return new PadTransformationApplier().apply(
        { width, height },
        transformation,
      );
    case CROP.CROP:
      return new CropTransformationApplier().apply(
        { width, height },
        transformation,
      );
    default:
      throw new Error('Transformation is not supported.');
  }
}
