import { BodyB1 } from 'bgo-common/Styleguide/Typography';
import { PERISHABLE_MESSAGE } from 'pdp/constants';
import React from 'react';
import './perishableMessage.scss';

const PerishableMessage = ({ perishable }) => {
  if (!perishable) {
    return null;
  }
  const productMessage = (
    <div className="perishable-message">
      <BodyB1>{PERISHABLE_MESSAGE}</BodyB1>
    </div>
  );

  return productMessage;
};

export default PerishableMessage;
