import React, { useEffect, useState, createContext } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import classnames from 'classnames';
import { isLoadingFinished } from '@bgo-ui/common/client/utilities/utilities-api';
import closeSVG from '@bgo-ui/common/assets/images/close.svg';

import LoginCommonLogo from './LoginCommonLogo/LoginCommonLogo';
import './LoginPageCommonLayout.scss';
import { getContentForLoginPage } from '../../../actions';
import Copy from '../../../../common/Copy/Copy';

export const LogoContext = createContext();

const DumbLoginPageCommonLayout = props => {
  const [mobileLogo, setMobileLogo] = useState(true);

  useEffect(() => {
    props.getContentForLoginPage();
  }, []);

  return (
    <div className="login-common-layout">
      <div
        role="presentation"
        className="login-common-layout__background desktop-tablet-only"
      >
        <img
          role="presentation"
          src={get(
            props.loginContent,
            'UserLoginSupportingImage-Desktop.image[0].secure_url',
          )}
          alt={get(
            props.loginContent,
            'UserLoginSupportingImage-Desktop.image[0].altText',
          )}
        />
      </div>
      <div className="login-common-layout__header">
        <div className="login-common-layout__back desktop-tablet-only">
          <Copy
            loaded={props.isContentLoaded}
            content={get(
              props.loginContent,
              'UserLoginSupportingHyperlink-Desktop.text',
            )}
            fallback={<a href="/">Back to homepage</a>}
          />
        </div>
        <div className="login-common-layout__close-button mobile-only">
          <a
            href="/"
            aria-label="Back to homepage"
            className="login-common-layout__close-button-link"
          >
            <img
              alt="Close"
              aria-hidden="true"
              className="close-icon"
              height="32"
              src={closeSVG}
              width="32"
            />
          </a>
        </div>
        <div
          className={classnames(
            'login-common-layout__logo',
            !mobileLogo && 'login-common-layout__logo-hidden-mobile',
          )}
        >
          <LoginCommonLogo />
        </div>
      </div>
      <div id="mainContent">
        <LogoContext.Provider value={{ setMobileLogo }}>
          {props.children}
        </LogoContext.Provider>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  loginContent: get(state, 'login.loginContent', {}),
  isContentLoaded: isLoadingFinished(state, 'login_content'),
});

const mapDispatchToProps = {
  getContentForLoginPage,
};

const LoginPageCommonLayout = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbLoginPageCommonLayout);

export default LoginPageCommonLayout;
