import React from 'react';
import { connect } from 'react-redux';
import '../ProductListErrorModal/productListErrorModal.scss';
import ModalWithoutHeader from './modalWithoutHeader';

// const defaultMessage = 'Sorry, something went wrong on our end. Please try again.';
const ModalWithHeader = ({ message, header, className, image }) => (
  <div className={className}>
    <h2>{header}</h2>
    <ModalWithoutHeader message={message} image={image} className={className} />
  </div>
);
const mapStateToProps = (state, ownProps) => ({
  header: state.modal.header || ownProps.header || 'Error',
  message:
    state.modal.message ||
    ownProps.message ||
    'Sorry, something went wrong on our end. Please try again.',
});
export default connect(
  mapStateToProps,
  null,
)(ModalWithHeader);
