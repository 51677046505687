import NO_IMAGE_AVAILABLE from 'assets/images/no-image.png';
import get from 'lodash/get';
import React from 'react';
import LazyLoad from 'react-lazy-load';
import { connect } from 'react-redux';
import ResponsiveImage from 'shared/components/ResponsiveImage/responsiveImage';
import { isObject } from 'util';
import QuickLookButton from '../QuickLookButton/quickLookButton';
import './image.scss';

const createImagePreloader = (src, onError) => () => {
  const image = new window.Image();
  image.onerror = onError;
  image.src = isObject(src) ? src.desktop.imageSrc : src;
  return image;
};

const activeImages = {
  MAIN_IMAGE: 'MAIN_IMAGE',
  ALT_IMAGE: 'ALT_IMAGE',
};

const defaultState = {
  activeImage: activeImages.MAIN_IMAGE,
  mainImageLoadFailed: false,
  altImageLoadFailed: false,
  isHover: false,
};

class Image extends React.Component {
  constructor(props) {
    super();
    this.state = defaultState;
    this.showAltImage = this.showAltImage.bind(this);
    this.showMainImage = this.showMainImage.bind(this);
    this.imageErrorHandler = this.imageErrorHandler.bind(this);

    const createPreloader = props.createPreloader || createImagePreloader;
    this.preload = createPreloader(props.src, this.imageErrorHandler);
  }

  componentDidMount() {
    if (!this.props.dupImgFixToggle) {
      IS_CLIENT && !this.props.enableLazyLoading && this.preload();
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.src !== nextProps.src) {
      this.setState(defaultState);
    }
  }

  getActiveImageSrc() {
    const { activeImage, mainImageLoadFailed } = this.state;
    const { src: mainImage, altImage } = this.props;
    if (activeImage === activeImages.ALT_IMAGE) {
      return altImage;
    }
    if (activeImage === activeImages.MAIN_IMAGE && mainImageLoadFailed) {
      return NO_IMAGE_AVAILABLE;
    }
    return mainImage;
  }

  // eslint-disable-next-line no-unused-vars
  imageErrorHandler(event) {
    if (this.state.activeImage === activeImages.MAIN_IMAGE) {
      this.setState({
        mainImageLoadFailed: true,
      });
    } else {
      this.setState({
        activeImage: activeImages.MAIN_IMAGE,
        altImageLoadFailed: true,
      });
    }
  }

  showMainImage() {
    this.setState({ activeImage: activeImages.MAIN_IMAGE });
  }

  showAltImage() {
    if (this.state.altImageLoadFailed) return;

    this.setState({ activeImage: activeImages.ALT_IMAGE });
  }

  showQuickLookButton() {
    this.setState({ isHover: true });
  }

  hideQuickLookButton() {
    this.setState({ isHover: false });
  }

  render() {
    const {
      alt,
      title,
      enableLazyLoading = false,
      favoritesQuickLookNewLayout = false,
      showQLModalWindow,
      quickLookToggle = false,
      quickViewPLP = false,
      isCloudinary,
      isBeauty = false,
    } = this.props;
    const altImage =
      this.props.altImage && this.props.altImage !== NO_IMAGE_AVAILABLE
        ? this.props.altImage
        : '';
    const src = this.getActiveImageSrc();
    const favoritesQuickLookNewImageLayout = (
      <div
        className="main-image-container"
        onMouseEnter={() => {
          if (altImage) {
            this.showAltImage();
          }
          if (favoritesQuickLookNewLayout && quickLookToggle) {
            this.showQuickLookButton();
          }
          if (quickViewPLP) {
            this.showQuickLookButton();
          }
        }}
        onMouseLeave={() => {
          if (altImage) {
            this.showMainImage();
          }
          if (
            favoritesQuickLookNewLayout &&
            quickLookToggle &&
            this.state.isHover
          ) {
            this.hideQuickLookButton();
          }
          if (quickViewPLP && this.state.isHover) {
            this.hideQuickLookButton();
          }
        }}
      >
        <ResponsiveImage
          src={src}
          alt={alt}
          title={title}
          error={event => {
            this.imageErrorHandler(event);
          }}
          name="mainImage"
          isCloudinary={isCloudinary}
        />
        {favoritesQuickLookNewLayout && quickLookToggle && (
          <QuickLookButton
            favoritesQuickLookNewLayout={favoritesQuickLookNewLayout}
            showQLModalWindow={showQLModalWindow}
            isHover={this.state.isHover}
          />
        )}
        {quickViewPLP && isBeauty && (
          <QuickLookButton
            favoritesQuickLookNewLayout={favoritesQuickLookNewLayout}
            showQLModalWindow={showQLModalWindow}
            isHover={this.state.isHover}
          />
        )}
      </div>
    );

    if (favoritesQuickLookNewLayout || quickViewPLP) {
      return enableLazyLoading ? (
        <LazyLoad
          debounce={false}
          throttle={0}
          offset={500}
          offsetBottom={1100}
        >
          {favoritesQuickLookNewImageLayout}
        </LazyLoad>
      ) : (
        favoritesQuickLookNewImageLayout
      );
    } else {
      return enableLazyLoading ? (
        <LazyLoad
          debounce={false}
          throttle={0}
          offset={500}
          offsetBottom={1100}
        >
          <ResponsiveImage
            src={src}
            onMouseOver={altImage && this.showAltImage}
            onMouseOut={altImage && this.showMainImage}
            alt={alt}
            title={title}
            error={event => {
              this.imageErrorHandler(event);
            }}
            name="mainImage"
            isCloudinary={isCloudinary}
          />
        </LazyLoad>
      ) : (
        <ResponsiveImage
          src={src}
          onMouseOver={altImage && this.showAltImage}
          onMouseOut={altImage && this.showMainImage}
          alt={alt}
          title={title}
          error={event => {
            this.imageErrorHandler(event);
          }}
          name="mainImage"
          isCloudinary={isCloudinary}
        />
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    isDomLoadComplete: get(state, 'page.isDomLoadComplete', false),
    dupImgFixToggle: state?.toggles?.DUP_IMG_FIX,
  };
}

export default connect(mapStateToProps)(Image);
export const UnconnectedImage = Image;
