import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import renderHTML from 'react-render-html';
import { Helmet } from 'react-helmet';
import reactCookie from 'react-cookie';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addEventListener, removeEventListener } from 'window-or-global';
import { sanitize } from 'client-utils/utilities-html-sanitizer';
import { createCookiesFromAbTests } from 'client-utils/utilities-abtest';
import SkipToContent from 'storefront/components/SkipToContent/skipToContent';
import DownloadappBanner from '@bgo-ui/common/components/DownloadappBanner/banner';
import Loadable from 'react-loadable';
import ScreenReaderAnnouncer from '@bgo-ui/common/components/ScreenReaderAlert/screenReaderAnnouncer';
import IdleTimerWrapper from '@bgo-ui/common/components/Util/IdleTimerWrapper';
import { ECMParams } from 'ecm/constants';
import CPPixel from 'ecm/components/CPPixel/cpPixel';
import { loseFocus } from 'shared/components/App/app-actions';
import Toggle from 'shared/components/Toggle/toggle';
import { domLoadCompleted } from 'shared/actions/actions-page';
import { onModalAnchorClick } from 'shared/components/App/utilities';
import { configureAmplify } from '@bgo-ui/common/client/utilities/utilities-amplify';
import { isNewCartCheckoutEnabled } from 'client-utils/is-new-cart-checkout';
import LoginPageCommonLayout from '../../../login/v2/common/LoginPageCommonLayout/LoginPageCommonLayout';

const Modal = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'modal' */ 'shared/components/Modal/modal'),
  loading: () => false,
});

class AppNewLoginCommon extends Component {
  constructor(...args) {
    super(...args);
    this.handleModalAnchorClick = this.handleModalAnchorClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { location } = this.props;
    if (!isEmpty(get(location, `query.${ECMParams.PARAM_LAUNCH}`, ''))) {
      reactCookie.save(
        ECMParams.PARAM_LAUNCH,
        get(location, `query.${ECMParams.PARAM_LAUNCH}`),
        { path: '/' },
      );
    }
    this.handleClickOutsideFocus = this.handleClickOutsideFocus.bind(this);
    if (this.props.haveAppSetDomLoadComplete) {
      this.handleLoad = this.handleLoad.bind(this);
    }
  }

  componentDidMount() {
    configureAmplify(
      this.props.amplifyConfig,
      this.props.isNewCartCheckoutEnabled,
      this.props.disableATGToggle,
    );

    addEventListener('click', this.handleModalAnchorClick);
    if (this.props.haveAppSetDomLoadComplete) {
      addEventListener('load', this.handleLoad);
    }

    if (this.props.useInternalAbTestService) {
      createCookiesFromAbTests(this.props.abTestResults);
    }
  }

  componentWillUnmount() {
    removeEventListener('click', this.handleModalAnchorClick);
  }

  getLinkTarget(target) {
    if (target.getAttribute('data-modal-url')) {
      return target;
    }
    return target.parentElement;
  }

  handleModalAnchorClick(e) {
    onModalAnchorClick(e, this.props);
  }

  handleClickOutsideFocus(e, currentComponentInFocus) {
    const component = this.container.querySelector(currentComponentInFocus);
    if (component && !component.contains(e.target)) {
      this.props.dispatch(loseFocus(currentComponentInFocus));
    }
  }

  handleLoad() {
    this.props.dispatch(domLoadCompleted());
    removeEventListener('load', this.handleLoad);
  }

  render() {
    const { isSliderMenuVisible, componentInFocus, modal = {} } = this.props;

    const outsideClickHandler = componentInFocus
      ? e => this.handleClickOutsideFocus(e, componentInFocus)
      : undefined;

    return (
      <div id="appContainer">
        <ScreenReaderAnnouncer />
        <IdleTimerWrapper />
        <div
          id="contentContainer"
          className="application-container"
          ref={ref => {
            this.container = ref;
          }}
          onClick={outsideClickHandler}
          onTouchEnd={outsideClickHandler}
          aria-hidden={modal.open || isSliderMenuVisible}
          tabIndex="-1"
        >
          <Helmet>
            {Boolean(this.props.canonical) && (
              <link rel="canonical" href={this.props.canonical} />
            )}
          </Helmet>
          <Helmet>
            {Boolean(this.props.metaSEOOverride) &&
              renderHTML(sanitize(this.props.metaSEOOverride))}
          </Helmet>

          <DownloadappBanner />
          <SkipToContent />
          <LoginPageCommonLayout>{this.props.children}</LoginPageCommonLayout>
        </div>
        <Modal />
        <Toggle feature="COHERENT_PATH_PERSONALIZATION">
          <CPPixel />
        </Toggle>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  canonical: state.page.canonical,
  metaSEOOverride: state.page.metaSEOOverride,
  isSliderMenuVisible: state.navigation.navSlider.sliderMenuVisible,
  componentInFocus: state.app.componentInFocus,
  modal: state.modal,
  haveAppSetDomLoadComplete: state.toggles.HAVE_APP_SET_DOMLOADCOMPLETE,
  useInternalAbTestService: state.toggles.USE_INTERNAL_ABTEST_SERVICE,
  abTestResults: state.abTests.abTestResults,
  amplifyConfig: state.amplifyConfig,
  isNewCartCheckoutEnabled: isNewCartCheckoutEnabled(state),
  disableATGToggle: state?.toggles?.DISABLE_ATG_CALLS || false,
});

export default connect(mapStateToProps)(AppNewLoginCommon);
