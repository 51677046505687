import React, { Fragment } from 'react';
import classnames from 'classnames';
import './quickLookButton.scss';

const QuickLookButton = ({
  showQLModalWindow,
  isHover = true,
  isFavorites,
}) => (
  <Fragment>
    <div
      className={classnames(
        'quicklook-button-background hide-on-mobile hide-on-tablet',
        { 'fade-in-button-background': isHover },
        { 'fade-out-button-background': !isHover },
      )}
    />
    <div
      className={classnames(
        'product-thumbnail__quicklook hide-on-mobile hide-on-tablet quicklook-button-over-image',
      )}
    >
      <button
        className={classnames(
          'quick-look quicklook-button-bottom-with-background',
          { 'fade-in-button': isHover },
          { 'fade-out-button': !isHover },
          { 'padding-margin-reset': isFavorites },
        )}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          showQLModalWindow();
        }}
      >
        Quick Look
      </button>
    </div>
  </Fragment>
);

export default QuickLookButton;
