import {
  LOADING_SAMEDAYDELIVERY,
  RESOLVED_SAMEDAYDELIVERY,
  REJECTED_SAMEDAYDELIVERY,
} from '../actions';

export default (state = {}, action) => {
  switch (action.type) {
    case LOADING_SAMEDAYDELIVERY:
      return {};
    case RESOLVED_SAMEDAYDELIVERY:
      return { ...action.payload };
    case REJECTED_SAMEDAYDELIVERY:
      return {};
    default:
      return state;
  }
};
