import replenishmentIcon from 'bgo-common/assets/images/replenishment-icon.svg';
import { EyeBrow1, EyeBrow4 } from 'bgo-common/Styleguide/Typography';
import classNames from 'classnames';
import { isMobile } from 'client-utils/utilities-page';
import Radio from 'client/common/components/Radio/radio';
import { BEAUTY_REPLENISH } from 'pdp/constants';
import React, { Component } from 'react';
import './beautyReplenishment.scss';

class BeautyReplenishment extends Component {
  constructor(props) {
    super(props);

    this.replenishIntervals = [
      { text: '30 Days', value: 30 },
      { text: '45 Days', value: 45 },
      { text: '60 Days', value: 60 },
      { text: '90 Days', value: 90 },
      { text: '120 Days', value: 120 },
    ];
    this.state = {
      selectedValue: this.props.replenishInterval ? 2 : 1,
      hoveredInterval: this.props.replenishInterval
        ? this.getInterval(this.props.replenishInterval)
        : this.replenishIntervals[0],
      showDropdown: false,
    };

    this.radioOptions = [
      {
        id: 1,
        label: 'One-time purchase',
      },
      {
        id: 2,
        label: 'Deliver every',
      },
    ];

    this.setActiveReplenishment = this.setActiveReplenishment.bind(this);
    this.getIntervalText = this.getIntervalText.bind(this);
    this.handleModalAnchorClick = this.handleModalAnchorClick.bind(this);
    this.setOption = this.setOption.bind(this);
    this.handleDropdown = this.handleDropdown.bind(this);
    this.setActiveReplenishmentForMobile = this.setActiveReplenishmentForMobile.bind(
      this,
    );
  }

  componentDidMount() {
    if (this.props.replenishInterval === undefined) {
      this.props.onIntervalSelect(0);
    }
  }

  componentDidUpdate() {
    if (
      this.props.replenishInterval &&
      this.props.replenishInterval !== this.state.hoveredInterval.value
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectedValue: 2,
        hoveredInterval: this.getInterval(this.props.replenishInterval),
      });
    } else if (
      this.props.replenishInterval === 0 &&
      this.state.selectedValue !== 1
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectedValue: 1,
      });
    }
  }

  setActiveReplenishment(interval) {
    this.props.onIntervalSelect(interval.value);
    this.setState({
      hoveredInterval: interval,
      showDropdown: !this.state.showDropdown,
    });
  }

  getInterval = value => {
    let selectedInterval;
    this.replenishIntervals.forEach(element => {
      if (element.value === value) {
        selectedInterval = element;
      }
    });
    return selectedInterval;
  };

  setActiveReplenishmentForMobile(e) {
    const value = Number(e.target.value);
    this.props.onIntervalSelect(value);
    const selectedInterval = this.getInterval(value);
    this.setState({
      hoveredInterval: selectedInterval,
      showDropdown: !this.state.showDropdown,
    });
  }

  setOption(index) {
    if (index === 1) {
      this.props.onIntervalSelect(0);
    } else {
      this.props.onIntervalSelect(this.state.hoveredInterval.value);
    }
    this.setState({
      selectedValue: index,
      showDropdown: false,
    });
  }

  // eslint-disable-next-line consistent-return
  getIntervalText() {
    if (this.state.hoveredInterval) {
      const { text } = this.state.hoveredInterval;
      return text && `${text}`;
    }
  }

  handleDropdown() {
    if (isMobile()) {
      this.setState({ showDropdown: true });
    } else {
      const showDropdown = !this.state.showDropdown;
      this.setState({ showDropdown });
    }
  }

  handleModalAnchorClick(e) {
    e.preventDefault();
    this.props.openReplenishmentModal();
  }

  render() {
    return (
      <div className="beauty-replenishment grid-100 tablet-grid-100 mobile-grid-100 grid-parent">
        <span className="beauty-replenishment__heading" tabIndex="0">
          Select Frequency:
        </span>
        {this.radioOptions.map((option, index) => {
          return (
            <div className={index === 1 ? 'displayStyle' : ''}>
              <Radio
                label={option.label}
                value={option.id}
                selected={option.id === this.state.selectedValue}
                onClick={() => {
                  this.setOption(index + 1);
                }}
              />
            </div>
          );
        })}

        <span
          className={
            this.state.selectedValue === 1
              ? 'disableOptions selectedInterval hide-on-mobile'
              : 'selectedInterval hide-on-mobile'
          }
          onClick={() => this.handleDropdown()}
          onKeyPress={e => {
            e.key === 'Enter' && this.handleDropdown();
          }}
          tabIndex={this.state.selectedValue !== 1 ? '0' : ''}
        >
          <EyeBrow4>
            <span className="days-text">{this.getIntervalText()}</span>
          </EyeBrow4>
          <span
            className={classNames('accordion-item__icon', {
              'icon-down': this.state.showDropdown,
            })}
          />
        </span>
        <select
          className={
            this.state.selectedValue === 1
              ? 'disableOptions selectedIntervalForMobile hide-on-desktop hide-on-tablet'
              : `selectedIntervalForMobile hide-on-desktop hide-on-tablet ${
                  this.state.showDropdown ? 'icon-down-mobile' : ''
                }`
          }
          onChange={this.setActiveReplenishmentForMobile}
          onClick={() => this.handleDropdown()}
          value={
            this.props.replenishInterval ? this.props.replenishInterval : ''
          }
          aria-label={BEAUTY_REPLENISH}
        >
          {this.replenishIntervals.map(interval => (
            <option value={interval.value}>{interval.text}</option>
          ))}
        </select>
        <img
          src={replenishmentIcon}
          className="beauty-replenishment__faq--link"
          onClick={this.handleModalAnchorClick}
          alt="Replenishment Icon"
        />
        <div className="hide-on-mobile beauty-replenishment_style">
          <ul
            className={
              this.state.showDropdown
                ? 'showDropdown beauty-replenishment__list'
                : 'hideDropdown'
            }
          >
            {this.replenishIntervals.map((interval, index) => (
              <li className="beauty-replenishment__list__item" key={index}>
                <button
                  onClick={() => this.setActiveReplenishment(interval)}
                  className={
                    this.state.hoveredInterval.text === interval.text
                      ? 'itemBorder beauty-replenishment__button'
                      : 'beauty-replenishment__button'
                  }
                >
                  <EyeBrow1>
                    <span className="days-text">{interval.text}</span>
                  </EyeBrow1>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}
export default BeautyReplenishment;
