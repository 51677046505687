import React from 'react';
import get from 'lodash/get';
import arrowRight from 'bgo-common/assets/images/arrow-right.svg';
import { connect } from 'react-redux';
import './outfittingText.scss';

const scrollToOutfitting = () => {
  const outfittingDiv = document.getElementsByClassName(
    'outfitting__container',
  )[0];
  if (typeof outfittingDiv !== 'undefined') {
    outfittingDiv.scrollIntoView(true);
  }
};

function OutfittingTextDisplay(props) {
  const {
    outfittingRecs = [],
    activeStyleBoard,
    minifiedProductResponse = [],
  } = props;

  const lengthMatch =
    activeStyleBoard === 0
      ? minifiedProductResponse[activeStyleBoard] &&
        !minifiedProductResponse[activeStyleBoard].error
      : true;

  return outfittingRecs.length > 0 && lengthMatch ? (
    <button className="outfittingButton" onClick={() => scrollToOutfitting()}>
      <span className="outfittingText">how to wear it</span>
      <img src={arrowRight} className="outfittingImage" alt="" />
    </button>
  ) : (
    false
  );
}

const mapStateToProps = (state, props) => {
  const productCatalog = state.productCatalog || {};
  const outfittingRecs = get(
    productCatalog,
    'outfitting.outfittingRecs.csbList',
    [],
  );
  const minifiedProductResponse = get(
    productCatalog,
    'outfitting.minifiedProductResponse',
    [],
  );
  const activeStyleBoard = get(
    productCatalog,
    'outfitting.activeStyleBoard',
    0,
  );

  return {
    ...props,
    outfittingRecs,
    minifiedProductResponse,
    activeStyleBoard,
  };
};

export default connect(mapStateToProps)(OutfittingTextDisplay);
