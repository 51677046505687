import React from 'react';
import './styles.scss';
import EcmSwitch from '../../client/ecm/components/EcmSwitch';

const Layout = props => {
  const { ecmContent, gridData } = props;

  return (
    ecmContent.length > 0 && (
      <div className="grid-layout-wrapper column merchendiser">
        {ecmContent.map((item, index) => (
          <div
            key={index}
            className={`grid-layout-item grid-desktop-${gridData[index].desktop} grid-tablet-${gridData[index].tablet} grid-mobile-${gridData[index].mobile}`}
          >
            <EcmSwitch ecmContent={item} />
          </div>
        ))}
      </div>
    )
  );
};

export default Layout;
