import React, { Component } from 'react';
import Toggle from 'shared/components/Toggle/toggle';
import TrueFit from 'pdp/components/ProductPage/components/SizeRecommendation/TrueFit';
import get from 'lodash/get';
import classnames from 'classnames';
// import CustomDropdown from "bgo-common/components/CustomDropdown/customDropdown";
import './dropDownComponent.scss';
import ErrorMessage from '../../components/ErrorMessage/errorMessage';

class DropDownComponent extends Component {
  constructor() {
    super();
    this.state = {
      customSelectHash: Math.random()
        .toString(36)
        .substring(7),
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.renderCustomDropdown();
  }

  componentDidUpdate(nextProps) {
    if (this.props !== nextProps) {
      this.renderCustomDropdown();
    }
  }

  handleChange(e) {
    const selectIndex =
      this.props.label === 'color'
        ? e.target.selectedIndex
        : e.target.selectedIndex - 1;
    this.props.onClick(selectIndex, this.props.label);
  }

  isSizeOption(label) {
    return label === 'size';
  }

  toCamelCase(str) {
    return str.replace(/\w\S*/g, txt => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  renderCustomDropdown() {
    const handleChange = index => {
      const selectIndex =
        this.props.label === 'color' || this.props.label === 'quantity'
          ? index
          : index - 1;
      this.props.onClick(selectIndex, this.props.label);
    };

    const { label } = this.props;

    let i, j, selectElement, a, b, c;
    const x = document.getElementsByClassName(`${label}--select-dropdown`);

    for (i = 0; i < x.length; i++) {
      selectElement = x[i].getElementsByTagName('select')[0];
      a = document.createElement('DIV');
      const checkElem = document.querySelectorAll(
        `.${label}--select-dropdown`,
      )[0];
      if (checkElem.querySelectorAll('.select-selected').length > 0) {
        const childEle = checkElem.querySelectorAll('.select-selected')[0];
        childEle.parentNode.removeChild(childEle);
      }
      a.setAttribute('class', 'select-selected');
      a.innerHTML =
        this.props.label === 'quantity'
          ? this.props.quantity
          : selectElement.options[selectElement.selectedIndex].innerHTML;
      x[i].appendChild(a);
      b = document.createElement('DIV');
      if (checkElem.querySelectorAll('.select-items').length > 0) {
        const childEle = checkElem.querySelectorAll('.select-items')[0];
        childEle.parentNode.removeChild(childEle);
      }
      b.setAttribute('class', 'select-items select-hide');
      for (j = 1; j < selectElement.length; j++) {
        c = document.createElement('DIV');
        c.innerHTML = selectElement.options[j].innerHTML;
        c.setAttribute(
          'class',
          selectElement.options[j].disabled ? 'disabled-select' : '',
        );
        // eslint-disable-next-line no-loop-func, func-names
        c.addEventListener('click', function() {
          let y, i, k;
          const s = this.parentNode.parentNode.getElementsByTagName(
            'select',
          )[0];
          const h = this.parentNode.previousSibling;
          for (i = 0; i < s.length; i++) {
            if (s.options[i].innerHTML === this.innerHTML) {
              s.selectedIndex = i;
              h.innerHTML = this.innerHTML;
              y = this.parentNode.getElementsByClassName('same-as-selected');
              for (k = 0; k < y.length; k++) {
                y[k].removeAttribute('class');
              }
              this.setAttribute('class', 'same-as-selected');
              break;
            }
          }
          h.click();
          handleChange(selectElement.selectedIndex);
        });
        b.appendChild(c);
      }
      x[i].appendChild(b);
      // eslint-disable-next-line func-names
      a.addEventListener('click', function(e) {
        e.stopPropagation();
        closeAllSelect(this);
        this.nextSibling.classList.toggle('select-hide');
        this.classList.toggle('select-arrow-active');
      });
    }
    function closeAllSelect(elmnt) {
      let i;
      const arrNo = [];
      const x = document.getElementsByClassName('select-items');
      const y = document.getElementsByClassName('select-selected');
      for (i = 0; i < y.length; i++) {
        if (elmnt === y[i]) {
          arrNo.push(i);
        } else {
          y[i].classList.remove('select-arrow-active');
        }
      }
      for (i = 0; i < x.length; i++) {
        if (arrNo.indexOf(i)) {
          x[i].classList.add('select-hide');
        }
      }
    }
    document.addEventListener('click', closeAllSelect);
  }

  render() {
    const {
      availableSkus,
      label,
      noAvailableSizeSelected,
      options,
      selectedIndex,
      isGroup,
      isChanel,
      optionType,
      addToBagError,
    } = this.props;

    if (options.length === 0) return false;

    const selectComponentLabel = `${label}-select`;

    const addSpaceToCamelCase = str => {
      let result = '';
      for (let i = 0; i < str.length; i++) {
        if (str[i] === str[i].toUpperCase() && i !== 0) {
          result += ' ';
          result += str[i];
        } else {
          result += str[i];
        }
      }
      return result[0].toUpperCase() + result.slice(1);
    };

    const cameCaseLabel = addSpaceToCamelCase(label);

    const defaultSelectLabel =
      optionType !== 'quantity' ? `Select ${cameCaseLabel}` : options[0];

    const defaultOption =
      options.length > 0 && label !== 'color' ? (
        <option key="default" value={defaultSelectLabel}>
          {defaultSelectLabel}
        </option>
      ) : null;

    const isDisabled = index => {
      if (noAvailableSizeSelected) {
        return noAvailableSizeSelected;
      }
      if (!availableSkus || !availableSkus.length) {
        return false;
      }
      if (!availableSkus[index]) {
        return true;
      }
      return !(
        get(availableSkus[index], 'sellable') &&
        get(availableSkus[index], 'inStock')
      );
    };

    const errorMessageClass = addToBagError ? 'form-error' : '';

    const getLabel = (index, value) => {
      if (isDisabled(index)) {
        return optionType !== 'PDP'
          ? `${value.name} (Out of Stock)`
          : `${value.displayName.replace(/[0-9]+/g, '').trim()} - Out of Stock`;
      } else {
        return optionType !== 'PDP'
          ? `${value.name}`
          : `${value.displayName.replace(/[0-9]+/g, '').trim()}`;
      }
    };

    const getOptionButtonClasses = (value, index) =>
      optionType !== 'PDP'
        ? classnames({
            'product-options__Optimized--active ':
              index === selectedIndex && !value.url,
            'product-options__Optimized--swatch': !!value.url,
            'product-options__Optimized--swatch--active':
              index === selectedIndex && !!value.url,
            'product-options__Optimized--disabled': isDisabled(index),
          })
        : classnames({
            'product-options__Optimized--active': index === selectedIndex,
          });

    const getKey = options => {
      let newSelectedIndex = selectedIndex;
      if (this.props.isReplenish) {
        switch (selectedIndex) {
          case 30:
            newSelectedIndex = 1;
            break;
          case 45:
            newSelectedIndex = 2;
            break;
          case 60:
            newSelectedIndex = 3;
            break;
          case 90:
            newSelectedIndex = 4;
            break;
          case 120:
            newSelectedIndex = 5;
            break;
          default:
            newSelectedIndex = 0;
        }
      }
      return optionType !== 'PDP'
        ? options[newSelectedIndex].key
        : options[newSelectedIndex].displayName;
    };

    const selectedKey = selectedIndex !== -1 ? getKey(options) : 'default';

    const optionAttribute = (
      dropDownClassNames,
      value,
      dropDownValue,
      index,
    ) => {
      return dropDownClassNames ? (
        <option
          key={index}
          value={dropDownValue}
          className={dropDownClassNames}
          disabled={isDisabled(index)}
        >
          {getLabel(index, value)}
        </option>
      ) : (
        <option key={index} value={dropDownValue} disabled={isDisabled(index)}>
          {getLabel(index, value)}
        </option>
      );
    };

    const optionList = options.map((value, index) => {
      const dropDownClassNames = getOptionButtonClasses(value, index);
      if (optionType === 'quantity') {
        return <option>{value}</option>;
      }

      return optionType !== 'PDP'
        ? optionAttribute(dropDownClassNames, value, value.key, index)
        : optionAttribute(dropDownClassNames, value, value.displayName, index);
    });

    const isCustomDropDownDisabled =
      this.props.disableCustomDropDown === true ? '--custom-disabled' : '';

    return (
      <div
        id={`product-options__Optimized__${label}`}
        className="product-options__Optimized grid-100 tablet-grid-100 mobile-grid-100 grid-parent"
      >
        {label === 'size' && (
          <div>
            <span
              className={classnames({
                'product-options__heading_oldPDP': isChanel || isGroup,
                'product-options__Optimized__heading': !isChanel && !isGroup,
              })}
            >
              <Toggle feature="TRUEFIT">
                <Toggle
                  feature="DISABLE_NMO_UI_EXTERNAL_LIBRARIES"
                  fallback={
                    this.isSizeOption(label) && (
                      <TrueFit productId={this.props.productId} />
                    )
                  }
                >
                  {false}
                </Toggle>
              </Toggle>
            </span>
          </div>
        )}
        <div
          className={`grid-100 tablet-grid-100 mobile-grid-100 grid-parent ${errorMessageClass}`}
        >
          <div
            className={`custom-select${isCustomDropDownDisabled} ${label}--select-dropdown${isCustomDropDownDisabled}`}
          >
            <select
              className={`${selectComponentLabel}`}
              value={`${selectedKey}`}
              onChange={e => this.handleChange(e)}
              id={`${this.props.label}-pdp-native-dropdown`}
            >
              {defaultOption}
              {optionList}
            </select>
          </div>

          <ErrorMessage message={addToBagError} />
        </div>
      </div>
    );
  }
}

export default DropDownComponent;
