import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import EditButton from '../EditButton/EditButton';
import {
  cancelButtonText,
  EDIT_SECTION_DOB,
  editButtonText,
  datesForSelect,
} from '../../../constants';
import {
  closeAccountDetailsEdit,
  openAccountDetailsEdit,
  updateAdditionalAccountInfo,
} from '../actions';
import { getEditState } from '../helpers';
import FormikDropDownField from '../../../../common/Forms/FormikDropDown/FormikDropDown';
import SubmitButton from '../../../../common/Forms/FormButtons/SubmitButton/SubmitButton';

const SECTION = EDIT_SECTION_DOB;

const validateForm = values => {
  const errors = {};
  if (!values.month) {
    errors.month = 'Select your birth month.';
  } else if (!values.day) {
    errors.day = 'Select your birth day.';
  } else if (Number(values.day) > datesForSelect[values.month]) {
    errors.day = 'Select the correct day.';
  }
  return errors;
};

const DumbDateOfBirthField = ({
  birthday,
  error,
  isEditOpened,
  isEditDisabled,
  closeAccountDetailsEdit,
  openAccountDetailsEdit,
  updateAdditionalAccountInfo,
  isUpdating,
  isGreyCssFix,
}) => {
  const handleEditClick = () => {
    isEditOpened ? closeAccountDetailsEdit() : openAccountDetailsEdit(SECTION);
  };

  const month = (birthday && birthday.split(' ')[0]) || '';
  const day = (birthday && birthday.split(' ')[1]) || '';

  const initialFormValue = {
    month,
    day,
  };

  const submitForm = values => {
    if (values.month !== month || values.day !== day) {
      updateAdditionalAccountInfo({
        birthday: `${values.month} ${values.day}`,
      });
    } else {
      closeAccountDetailsEdit();
    }
  };

  const monthsList = [
    { value: '', name: '' },
    ...Object.keys(datesForSelect).map(i => ({
      value: i,
      name: i,
    })),
  ];
  const daysList = month => [
    { value: '', name: '' },
    ...Array.from({ length: datesForSelect[month] }, (_, i) => i + 1).map(
      i => ({
        value: i,
        name: i,
      }),
    ),
  ];

  return (
    <div className="account-details-page__field-wrapper" aria-live="polite">
      <div className="account-details-page__field-header">
        <h3 className="account-details-page__field-label">Date of birth</h3>
        <div className="account-details-page__field-edit">
          <EditButton
            editText={editButtonText}
            cancelText={cancelButtonText}
            isEditing={isEditOpened}
            isDisabled={isEditDisabled || isUpdating}
            onButtonClick={handleEditClick}
            isGreyCssFix={isGreyCssFix}
          />
        </div>
      </div>
      <div className="account-details-page__field-content">
        {isEditOpened ? (
          <Formik
            initialValues={initialFormValue}
            validate={validateForm}
            onSubmit={submitForm}
          >
            {({ values }) => (
              <Form>
                <div className="account-details-page__edit-form-fields-container custom-mobile-field">
                  <FormikDropDownField
                    placeholder="Month"
                    valuesArray={monthsList}
                    id="month-field"
                    name="month"
                    value={values.month}
                    fontSize
                  />
                  <FormikDropDownField
                    placeholder="Day"
                    valuesArray={daysList(values.month)}
                    id="day-field"
                    name="day"
                    value={values.day}
                    fontSize
                  />
                </div>
                {error && (
                  <p className="account-details-page__edit-error">{error}</p>
                )}
                <div className="account-details-page__edit-submit">
                  <SubmitButton
                    isSubmitting={isUpdating}
                    type="submit"
                    caption="Save"
                    additionalClasses="account-details-page__edit-submit-btn"
                  />
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <p className="account-details-page__field-value">
            {birthday || 'Add your date of birth'}
          </p>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  birthday: get(state, 'myaccount.myAccountDetails.fields.birthday', null),
  isUpdating: get(state, 'api.update_additional_user_info.loading', false),
  error: get(
    state,
    'myaccount.myAccountDetails.updateAdditionalInfoError',
    null,
  ),
  ...getEditState(state, SECTION),
  isGreyCssFix: get(state, 'toggles.GREYCOLOR_CSS_FIX', false),
});

const mapDispatchToProps = {
  openAccountDetailsEdit,
  closeAccountDetailsEdit,
  updateAdditionalAccountInfo,
};

const DateOfBirthField = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbDateOfBirthField);

export default DateOfBirthField;
