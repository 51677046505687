import React from 'react';
import { Link } from 'react-scroll';
import IncircleConciergeTitle from '../../widgets/IncircleConciergeTitle';
import { content } from './constants';
import './index.scss';

const IncircleConciergeLifetimeExperience = () => {
  return (
    <div className="incircle-concierge-lifetime-experience">
      <div className="incircle-concierge-lifetime-experience__title">
        <IncircleConciergeTitle
          content={content.title}
          contentClassName="incircle-concierge-lifetime-experience__title__content"
        />
      </div>
      <div className="incircle-concierge-lifetime-experience__desc">
        <p className="incircle-concierge-lifetime-experience__desc__content">
          {content.desc}
        </p>
      </div>
      <ul className="incircle-concierge-lifetime-experience__links">
        {content.links.map(linkItem => (
          <li
            key={linkItem.id}
            className="incircle-concierge-lifetime-experience__links__item"
          >
            <Link
              to={linkItem.id}
              href={`#${linkItem.id}`}
              smooth
              spy
              hashSpy
              duration={500}
              offset={linkItem.hasScrollOffset ? -39 : 0}
              className="incircle-concierge-lifetime-experience__links__item__link"
            >
              <span className="incircle-concierge-lifetime-experience__links__item__link__content">
                {linkItem.content}
              </span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default IncircleConciergeLifetimeExperience;
