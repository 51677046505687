import {
  STS_RESOLVED_PRODUCT,
  STS_REJECTED_PRODUCT,
  RESOLVED_STS_CLICKED_PRODUCT,
} from '../actions/actions-common';

export const INITIAL_STATE = {
  stsResponse: [],
  stsProduct: null,
  error: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STS_RESOLVED_PRODUCT: {
      return {
        ...state,
        stsResponse: action.payload,
      };
    }
    case STS_REJECTED_PRODUCT: {
      return {
        ...state,
        stsResponse: [],
        error: action.payload,
      };
    }
    case RESOLVED_STS_CLICKED_PRODUCT: {
      return {
        ...state,
        stsProduct: action.payload,
      };
    }
    default:
      return state;
  }
};
