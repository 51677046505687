import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  hideSpinner,
  showSpinner,
} from 'shared/components/Spinner/spinner-actions';
import {
  entryPasswordValidation,
  amplifyErrors,
  amplifyLogin,
} from 'client-utils/utilities-amplify';
import { setKmsiStatus } from 'client-utils/utilities-kmsi';
import LoginInputField from '../LoginInputField';
import FormDisclaimer from '../FormDisclaimer';
import SubmitButton from '../../../../../../../../common/Forms/FormButtons/SubmitButton/SubmitButton';
import { IN_CIRCLE_APPLY_NOW_LINK } from '../../../../constants';
import { signInContent, commonContent } from '../../constants';
import { editEmail } from '../../actions';

const SignInModalContent = ({
  email,
  disableATGToggle,
  ucaProfileCookieToggle,
  dtLoginFlow,
  editEmail,
  startLoading,
  endLoading,
}) => {
  const [loading, setLoading] = useState(false);
  const [passwordInputValue, setPasswordInputValue] = useState('');
  const [displayError, setDisplayError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const errorCallback = error => {
    let errMsg = commonContent.serverError;

    if (
      error === amplifyErrors.NOT_AUTHORIZED_EXCEPTION ||
      error === amplifyErrors.USER_NOT_FOUND_EXCEPTION ||
      error === amplifyErrors.INVALID_PARAMETER_EXCEPTION ||
      error === amplifyErrors.PASSWORD_RESET_REQUIRED_EXCEPTION
    ) {
      errMsg = signInContent.signInError;
    }

    endLoading();
    setLoading(false);
    setDisplayError(true);
    setErrorMessage(errMsg);
  };

  const successCallback = () => {
    window.location.href = IN_CIRCLE_APPLY_NOW_LINK;
  };

  const userLogin = (email, password) => {
    startLoading();
    setLoading(true);
    window.sessionStorage.setItem('login_btn_clicked', 'true');

    amplifyLogin(
      email,
      password,
      errorCallback,
      false,
      successCallback,
      disableATGToggle,
      ucaProfileCookieToggle,
      dtLoginFlow,
    );
  };

  const clearErrorMessage = () => {
    setDisplayError(false);
    setErrorMessage('');
  };

  const handlePasswordInputChange = e => {
    setPasswordInputValue(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const password = passwordInputValue;

    if (!entryPasswordValidation(password)) {
      setDisplayError(true);
      setErrorMessage(signInContent.error);
    } else {
      clearErrorMessage();
      setKmsiStatus(true);
      userLogin(email, password);
    }
  };

  return (
    <div className="sign-in-modal-content">
      <div className="sign-in-modal-content__heading">
        <h2 className="sign-in-modal-content__heading__title">
          {signInContent.title}
        </h2>
      </div>
      <div className="sign-in-modal-content__sub">
        <div className="sign-in-modal-content__sub__item">
          <div className="sign-in-modal-content__sub__item__cnt">
            <p className="sign-in-modal-content__sub__item__cnt__title">
              {commonContent.email}
            </p>
            <p className="sign-in-modal-content__sub__item__cnt__desc">
              {email}
            </p>
          </div>
          <span
            className="sign-in-modal-content__sub__item__edit"
            onClick={editEmail}
            onKeyDown={editEmail}
          >
            {commonContent.edit}
          </span>
        </div>
        <div className="sign-in-modal-content__sub__item">
          <p className="sign-in-modal-content__sub__item__pass">
            {signInContent.passTitle}
          </p>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="sign-in-modal-content__form">
        <div className="sign-in-modal-content__form__inputs">
          <LoginInputField
            id="sign-in-password-input"
            type="password"
            name="password"
            placeholder="Password"
            clearErrorMessage={clearErrorMessage}
            value={passwordInputValue}
            onChange={handlePasswordInputChange}
          />
          {displayError && (
            <div className="sign-in-modal-content__form__inputs__error">
              {errorMessage}
            </div>
          )}
          <p className="sign-in-modal-content__form__inputs__forgot">
            <a
              href="/e/login?forgot-password=true"
              target="_blank"
              rel="noreferrer"
              className="sign-in-modal-content__form__inputs__forgot__link"
            >
              Forgot Password?
            </a>
          </p>
        </div>
        <SubmitButton
          additionalClasses="sign-in-modal-content__form__submit-btn"
          isSubmitting={loading}
          caption="Sign In"
          type="submit"
        />
        <FormDisclaimer />
      </form>
    </div>
  );
};

const mapStateToProps = state => ({
  email: state.applyNowModal.email,
  disableATGToggle: state?.toggles?.DISABLE_ATG_CALLS || false,
  ucaProfileCookieToggle: state?.toggles.UCA_PROFILE_COOKIE || false,
  dtLoginFlow: state?.toggles?.DT_LOGIN_FLOW || false,
});
const mapDispatchToProps = {
  editEmail,
  startLoading: showSpinner,
  endLoading: hideSpinner,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignInModalContent);
