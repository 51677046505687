import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { buildCookieString } from 'universal/utilities-cookies';
import httpWithLogging from 'universal/http-client';

function getPersonalizedPromotions(state) {
  return new Promise((resolve) => {
    const requestApi = httpWithLogging(state);
    const session = get(state, 'session', {});
    const email = get(state, 'user.email', '');
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        DYN_USER_CONFIRM: get(session, 'DYN_USER_CONFIRM', ''),
        W2A: get(session, 'W2A', ''),
        TLTSID: get(session, 'TLTSID', ''),
        AGA: get(session, 'AGA', '')
      }),
      'Customer-Email-Id': email
    };
    const personalizedPromotions = [];

    function giveMePromoExp(date, today = new Date()) {
      const promoDate = new Date(date),
        todayDate = today,
        timeDiff = Math.abs(promoDate.getTime() - todayDate.getTime()),
        dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
      return dayDiff === 1 ? 'Offer ends soon.' : `Only ${dayDiff} days left!`;
    }

    if (!isEmpty(email)) {
      requestApi
      .get(`${NMConfig.API_GET_PERSONALIZED_PROMOTIONS}`, { headers })
      .then(successResponse => {
        const allPersonalizedPromotions = successResponse.data.promotions;
        if (allPersonalizedPromotions) {
          const rewardPromotions = allPersonalizedPromotions.rewards;
          if (rewardPromotions && rewardPromotions.length) {
            rewardPromotions.map((rewardPromotion) => {
              const rewardPromo = {};
              rewardPromo.type = 'rewards';
              rewardPromo.heading = rewardPromotion.displayableAttributes.title;
              rewardPromo.message = giveMePromoExp(rewardPromotion.endDate);
              personalizedPromotions.push(rewardPromo);
              return personalizedPromotions;
            });
          }
          resolve({ PersonalizedPromos: personalizedPromotions });
        } else {
          resolve({ PersonalizedPromos: [] });
        }
      })
      .catch(() => {
        resolve({ PersonalizedPromos: null });
      });
    } else {
      resolve({ PersonalizedPromos: [] });
    }
  });
}

export default getPersonalizedPromotions;
