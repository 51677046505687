import { types as NavTypes } from '../actions-navigation';

export default (state = [], action) => {
  switch (action.type) {
    case NavTypes.RESOLVED_NAVIGATION:
    case NavTypes.REJECTED_NAVIGATION:
      return [...state, ...action.payload];
    case 'RESOLVED_ECMaemDrawerAsset': {
      let silos = [...state];
      const newAemAssets = action.payload;

      const updateSilo = (silos, aemAsset) => {
        return silos.map(silo => {
          if (silo.id === aemAsset.categoryId) {
            return {
              ...silo,
              attributes: {
                ...silo.attributes,
                aem: aemAsset
              }
            };
          }
          return silo;
        });
      };

      newAemAssets.forEach(aemAsset => {
        silos = updateSilo(silos, aemAsset);
      });

      return silos;
    }
    default:
      return state;
  }
};
