import React, { Component } from 'react';
import Slider from 'react-slick';
import EcmSwitch from '../EcmSwitch';

export default class EcmSlider extends Component {
  shouldComponentUpdate(nextProps) {
    if (
      this.props.customClass === 'lifeStyleShopModule' &&
      this.props.domReady !== nextProps.domReady
    ) {
      return true;
    }
    return false; // this.props.domReady !== nextProps.domReady;
  }

  componentDidUpdate() {
    const { domReady = false } = this.props;
    if (domReady && window.picturefill) window.picturefill();
  }

  render() {
    const {
      dataObj,
      ecmSlot,
      sliderSettings,
      domReady = false,
      ecmCallToActionButtonWithTextToggle = false,
      improvePerformanceOfStyleToggle,
      showCaption,
      // disablePlaceholder,
      customClass,
    } = this.props;

    let divOpacity = '1';

    if (customClass === 'lifeStyleShopModule') {
      divOpacity = domReady ? '1' : '0';
    }

    return (
      <div style={{ opacity: divOpacity }}>
        <Slider {...sliderSettings}>
          {dataObj.slides.map((slide, index) => (
            <div key={index}>
              <EcmSwitch
                ecmCallToActionButtonWithTextToggle={
                  ecmCallToActionButtonWithTextToggle
                }
                ecmContent={slide}
                ecmSlot={ecmSlot}
                improvePerformanceOfStyleToggle={
                  improvePerformanceOfStyleToggle
                }
                showCaption={showCaption}
              />
            </div>
          ))}
        </Slider>
        {/* {(domReady || (1==1)) && (
            <Slider {...sliderSettings}>
              {dataObj.slides.map((slide, index) => (
                <div className="slide-item" key={index}>
                  <EcmSwitch
                    ecmCallToActionButtonWithTextToggle={
                      ecmCallToActionButtonWithTextToggle
                    }
                    ecmContent={slide}
                    ecmSlot={ecmSlot}
                    improvePerformanceOfStyleToggle={
                      improvePerformanceOfStyleToggle
                    }
                    showCaption={showCaption}
                  />
                </div>
              ))}
            </Slider>
        )} */}
        {/* {(!disablePlaceholder && !domReady) && (dataObj.slides.length > 0) && (
          <div className="ecm-container__carousel__placeholder">
            <EcmSwitch
              ecmCallToActionButtonWithTextToggle={
                ecmCallToActionButtonWithTextToggle
              }
              ecmContent={dataObj.slides[0]}
              ecmSlot={ecmSlot}
            />
          </div>
        )} */}
      </div>
    );
  }
}
