import { clearGuestTokens } from '.';

export const setGuestTokens = ({ data }) => {
  clearGuestTokens();
  const guestObj = data.AuthenticationResult;
  guestObj.ExpiresAt = Date.now() + guestObj.ExpiresIn * 1000;
  delete guestObj.ExpiresIn;
  Object.keys(guestObj).forEach(key => {
    localStorage.setItem(`guestUser.${guestObj.Sub}.${key}`, guestObj[key]);
  });
};
