import React from 'react';
import { array, bool, object } from 'prop-types';
import get from 'lodash/get';
import merge from 'lodash/merge';
import ContentItem from '../contentItem/contentItem';
import { defaultTrackingTags } from '../../constants/constants';
import { getTags } from '../utils';
import {
  getLayoutSpacing,
  getHorizontalSpacing,
  getVerticalSpacing,
} from './utils';

/**
 * @function L5Layout
 * @param {Array} [props.cmsContentFields]
 * @param {Boolean} [props.isMobilePhone]
 * @param {Boolean} [props.isTablet]
 * @param {Boolean} [props.isDesktop]
 * @param {String} props.layoutWidth
 * @param {Boolean} [props.sessionExpired]
 * @param {Boolean} [props.validPromo]
 * @param {Boolean} [props.isSpacingToggle]
 * @returns {React.ReactElement}
 */
const L5Layout = ({
  cmsContentFields,
  isMobilePhone,
  isTablet,
  isDesktop,
  parentTrackingTags,
  sessionExpired,
  validPromo,
  isSpacingToggle,
}) => {
  const contentItems = get(cmsContentFields, 'components', []);
  const widthAttribute = isMobilePhone
    ? cmsContentFields.mobileWidth
    : cmsContentFields.desktopWidth;
  const spacingStyles = isSpacingToggle
    ? getLayoutSpacing(cmsContentFields, isMobilePhone, isTablet, isDesktop)
    : '';
  const horizontalSpacingStyles = isSpacingToggle
    ? getHorizontalSpacing(cmsContentFields, isMobilePhone, isTablet, isDesktop)
    : '';
  const verticalSpacingStyles = isSpacingToggle
    ? getVerticalSpacing(cmsContentFields, isMobilePhone, isTablet, isDesktop)
    : '';
  const topStyles = spacingStyles.topStyles
    ? `spacing-top ${spacingStyles.topStyles}`
    : '';
  const horizontalStyles = horizontalSpacingStyles.horizontalStyles
    ? horizontalSpacingStyles.horizontalStyles
    : '';
  const verticalStyles =
    verticalSpacingStyles && verticalSpacingStyles.verticalStyles
      ? verticalSpacingStyles.verticalStyles
      : '';
  const layoutWidth = widthAttribute ? `width-${widthAttribute}` : 'width-100';

  // Set up Tracking Tags
  const trackingTags = get(cmsContentFields, 'trackingTags', []);
  const currentTags = getTags(trackingTags);
  const mergedTrackingTags = merge({}, parentTrackingTags, currentTags);

  return (
    <div
      className={`l5-layout width-${layoutWidth} ${topStyles} ${horizontalStyles} ${verticalStyles}`}
    >
      {contentItems.map(item => (
        <div className="component content-layout" key={item.sys.id}>
          <ContentItem
            cmsContentItem={item}
            isMobilePhone={isMobilePhone}
            parentTrackingTags={mergedTrackingTags}
            sessionExpired={sessionExpired}
            validPromo={validPromo}
          />
        </div>
      ))}
    </div>
  );
};

L5Layout.defaultProps = {
  parentTrackingTags: defaultTrackingTags,
};

L5Layout.propTypes = {
  cmsContentFields: array,
  isMobilePhone: bool,
  isTablet: bool,
  isDesktop: bool,
  parentTrackingTags: object,
  sessionExpired: bool,
  validPromo: bool,
  isSpacingToggle: bool,
};

export default L5Layout;
