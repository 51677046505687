import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'shared/components/Button/button';
import Image, {
  NO_IMAGE_AVAILABLE_CDN,
} from 'pdp/components/ProductPage/components/Image/image';
import he from 'he';
import { closeModal } from 'shared/components/Modal/actions';
import {
  completeAddToBag,
  addGiftToBag,
} from 'pdp/components/ProductPage/actions';
import get from 'lodash/get';
import property from 'lodash/property';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import keys from 'lodash/keys';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import has from 'lodash/has';
import mapValues from 'lodash/mapValues';
import first from 'lodash/first';
import DropDown from 'shared/components/DropDown/dropDown';
import { dangerousProperty } from 'client-utils/utilities-html-sanitizer';
import { ENTER_KEYCODE, SPACE_KEYCODE } from 'client-utils/keyCodes';
import ErrorMessage from '../ErrorMessage/errorMessage';
import './gwpModal.scss';

export const DEFAULT_COLOR_OPTION = 'Select Color';
export const DEFAULT_SIZE_OPTION = 'Select Size';
export const DEFAULT_SIZE_OPTION_WHEN_BOTH_AVAILABLE = 'First, Select Size';
export const DEFAULT_COLOR_OPTION_WHEN_BOTH_AVAILABLE = 'Then, Select Color';
export const DEFAULT_COLOR_VALUE = '';
export const DEFAULT_SIZE_VALUE = '';
export const errorMessages = {
  SIZE_NOT_SELECTED: 'Please choose a size option for this product.',
  COLOR_NOT_SELECTED: 'Please choose a color option for this product.',
  SIZE_AND_COLOR_NOT_SELECTED:
    'Please choose a color and size option for this product.',
};

const getOptions = (skus, path) =>
  keys(groupBy(filter(skus, sku => has(sku, path)), path));
export const getColors = skus => getOptions(skus, 'color.name');
export const getSizes = skus => getOptions(skus, 'size.name');

export class GwpMultiSkuModal extends Component {
  constructor(props) {
    super(props);
    const { gwpProduct: product } = this.props;
    const sellableSkus = filter(product.skus, 'sellable');
    const colors = getColors(sellableSkus);
    const sizes = getSizes(sellableSkus);
    const colorsBySize = mapValues(
      groupBy(sellableSkus, property('size.name')),
      skus => keyBy(skus, property('color.name')),
    );

    this.state = {
      colorOption: DEFAULT_COLOR_VALUE,
      sizeOption: DEFAULT_SIZE_VALUE,
      colors,
      sizes,
      colorsAvailable: !isEmpty(colors),
      sizesAvailable: !isEmpty(sizes),
      colorsBySize,
      sellableSkus,
      errorMessage: null,
    };

    this.handleContinue = this.handleContinue.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.selectColor = this.selectColor.bind(this);
    this.selectSize = this.selectSize.bind(this);
  }

  componentDidMount() {
    if (this.state.colors.length === 1 && this.state.sizes.length === 0) {
      this.setState({ colorOption: first(this.state.colors) });
    }

    if (this.state.sizes.length === 1) {
      const sizeOption = first(this.state.sizes);
      this.setState({ sizeOption });
      if (keys(this.state.colorsBySize[sizeOption]).length === 1) {
        this.setState({
          colorOption: first(keys(this.state.colorsBySize[sizeOption])),
        });
      }
    }
  }

  handleKeyDown(event) {
    if (event.which === ENTER_KEYCODE || event.which === SPACE_KEYCODE) {
      event.stopPropagation();
      this.handleContinue();
    }
  }

  isInputInvalid() {
    const {
      colorsAvailable,
      sizesAvailable,
      colorOption,
      sizeOption,
    } = this.state;
    if (
      colorsAvailable &&
      !sizesAvailable &&
      colorOption === DEFAULT_COLOR_VALUE
    ) {
      this.setState({ errorMessage: errorMessages.COLOR_NOT_SELECTED });
      return true;
    }
    if (
      sizesAvailable &&
      !colorsAvailable &&
      sizeOption === DEFAULT_SIZE_VALUE
    ) {
      this.setState({ errorMessage: errorMessages.SIZE_NOT_SELECTED });
      return true;
    }
    if (
      colorsAvailable &&
      sizesAvailable &&
      (sizeOption === DEFAULT_SIZE_VALUE || colorOption === DEFAULT_COLOR_VALUE)
    ) {
      this.setState({
        errorMessage: errorMessages.SIZE_AND_COLOR_NOT_SELECTED,
      });
      return true;
    }
    return false;
  }

  handleContinue() {
    this.setState({ errorMessage: null });
    if (this.isInputInvalid()) {
      return;
    }

    const {
      gwpPromoKey,
      gwpProduct: { id: gwpProductId },
    } = this.props;
    const { colorOption, sizeOption } = this.state;
    this.props.addGiftToBag(gwpProductId, gwpPromoKey, colorOption, sizeOption);
    this.props.closeModal('GwpMultiSkuModal');
  }

  selectColor(color) {
    this.setState({ colorOption: color });
  }

  selectSize(size) {
    if (keys(this.state.colorsBySize[size]).length === 1) {
      this.setState({
        colorOption: first(keys(this.state.colorsBySize[size])),
      });
    } else {
      this.selectColor(DEFAULT_COLOR_VALUE);
    }
    this.setState({ sizeOption: size });
  }

  render() {
    const { gwpProduct: product } = this.props;

    const showDropdown = (
      options,
      defaultOption,
      defaultValue,
      selectedValue,
      onChange,
    ) => (
      <DropDown
        options={options}
        defaultOption={defaultOption}
        defaultValue={defaultValue}
        selectedValue={selectedValue}
        onChange={onChange}
      />
    );

    const showSizeOrColorDropdown = (
      <div className="grid-100 grid-parent">
        {this.state.sizesAvailable && (
          <div className="gwp__dropdown__size gwp__dropdown grid-60 tablet-grid-60 mobile-grid-100">
            {showDropdown(
              getSizes(this.state.sellableSkus),
              DEFAULT_SIZE_OPTION,
              DEFAULT_SIZE_VALUE,
              this.state.sizeOption,
              this.selectSize,
            )}
          </div>
        )}

        {this.state.colorsAvailable && (
          <div className="gwp__dropdown__color gwp__dropdown grid-60 tablet-grid-60 mobile-grid-100">
            {showDropdown(
              getColors(this.state.sellableSkus),
              DEFAULT_COLOR_OPTION,
              DEFAULT_COLOR_VALUE,
              this.state.colorOption,
              this.selectColor,
            )}
          </div>
        )}
      </div>
    );

    const showSizeAndColorDropdown = (
      <div className="grid-100 grid-parent">
        <div className="gwp__dropdown__size gwp__dropdown grid-60 tablet-grid-60 mobile-grid-100">
          {showDropdown(
            getSizes(this.state.sellableSkus),
            DEFAULT_SIZE_OPTION_WHEN_BOTH_AVAILABLE,
            DEFAULT_SIZE_VALUE,
            this.state.sizeOption,
            this.selectSize,
          )}
        </div>
        <div className="gwp__dropdown__color gwp__dropdown grid-60 tablet-grid-60 mobile-grid-100">
          {showDropdown(
            keys(this.state.colorsBySize[this.state.sizeOption]),
            DEFAULT_COLOR_OPTION_WHEN_BOTH_AVAILABLE,
            DEFAULT_COLOR_VALUE,
            this.state.colorOption,
            this.selectColor,
          )}
        </div>
      </div>
    );

    return (
      <div
        className="gwp"
        aria-label="We are navigating to the Gift with Purchase modal to add promotional product in your cart"
        ref={ref => {
          this.gwpRoot = ref;
        }}
      >
        <div
          tabIndex="0"
          aria-label="You've qualified for a Gift With Purchase"
        >
          <h2>You&apos;ve qualified for a Gift With Purchase</h2>
        </div>

        <div className="grid-25 tablet-grid-25 mobile-grid-100 grid-parent">
          <Image
            className="gwp__image"
            alt={product.name}
            src={
              get(product, 'media.main.medium.url', '') !== ''
                ? get(product, 'media.main.medium.url')
                : get(product, 'media.main.dynamic.url', NO_IMAGE_AVAILABLE_CDN)
            }
          />
        </div>

        <div className="grid-75 tablet-grid-75 mobile-grid-100 grid-parent">
          <div className="gwp__title" tabIndex="0">
            <span>{get(product, 'designer.name')}</span>&nbsp;
            <span>{he.decode(product.name || '')}</span>
          </div>

          <div
            className="gwp__description"
            tabIndex="0"
            dangerouslySetInnerHTML={dangerousProperty(
              get(product, 'details.longDesc'),
            )}
          />

          <div className="gwp__itemId" tabIndex="0">
            {get(product, 'metadata.cmosCatalogId')}_
            {get(product, 'metadata.cmosItem')}
            {get(product, 'metadata.pimStyle')}
          </div>

          {this.state.sizesAvailable &&
            this.state.colorsAvailable &&
            showSizeAndColorDropdown}

          {// eslint-disable-next-line no-bitwise
          this.state.sizesAvailable ^ this.state.colorsAvailable
            ? showSizeOrColorDropdown
            : null
}
          <ErrorMessage message={this.state.errorMessage} />

          <Button
            className="gwp__continue"
            onClick={this.handleContinue}
            onKeyDown={this.handleKeyDown}
            value="Continue"
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = { closeModal, completeAddToBag, addGiftToBag };
export default connect(
  undefined,
  mapDispatchToProps,
)(GwpMultiSkuModal);
