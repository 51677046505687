import { formatMoney } from 'accounting';

export function renderFormattedPriceWithDecimals(price, currencyCode = '$') {
  const currencyFormat = currencyCode === '$' ? '%s%v' : '%s %v';
  const currencyThousand = currencyCode === '$' ? ',' : '';
  const money = formatMoney(price, {
    symbol: currencyCode,
    precision: 2,
    format: currencyFormat,
    thousand: currencyThousand,
  });
  const splitedMoneyString = money && money.split('.');
  const decimal = splitedMoneyString[1] && parseInt(splitedMoneyString[1], 10);
  return decimal === 0 ? splitedMoneyString[0] : money;
}
