import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import Loadable from 'react-loadable';
import { ecmGetAdaptiveStyleBlock } from '../../utilities';
import './style.scss';

const JWPlayer = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'JWPlayer' */ 'server/utilities/utilities-JWPlayer'
    ),
  loading: () => false,
});

export class DumbEcmVideo extends Component {
  constructor(props) {
    super(props);
    this.player = null;
    this.load = this.load.bind(this);
    this.remove = this.remove.bind(this);
    this.play = this.play.bind(this);
    this.isYoutubeVideo = this.isYoutubeVideo.bind(this);
  }

  componentDidMount() {
    this.isYoutubeVideo();
  }

  getAbsoluteUrl(url) {
    const anchor = document.createElement('a');
    anchor.href = url;
    return anchor.href;
  }

  isYoutubeVideo() {
    const videoUrl =
      get(this.props.videoData, 'properties.url') === null ||
      isEmpty(get(this.props.videoData, 'properties.url', ''))
        ? ''
        : get(this.props.videoData, 'properties.url');
    document.addEventListener('readystatechange', () => {
      if (document.readyState === 'complete') {
        document.getElementById('posterImgDiv').style.opacity = 0;
        const getVideoId =
          videoUrl.indexOf('youtube.com') !== -1 && videoUrl.split('/').pop();
        const JwPlayerVideoUrl = `https://www.youtube.com/watch?v=${getVideoId}`;
        this.load(JwPlayerVideoUrl);
      }
    });
  }

  load(videoUrl) {
    const { videoData } = this.props;
    const ecmComponentId = get(videoData, 'componentId', '');
    const isAutoPlay =
      this.props.enableAutoPlay &&
      get(videoData, 'properties.behavior', '') === 'autoplay';
    const posterImageUrl =
      get(videoData, 'properties.posterImageUrl', '') === null
        ? ''
        : get(videoData, 'properties.posterImageUrl', '');
    const isloop =
      get(videoData, 'properties.loop', false) !== null &&
      get(videoData, 'properties.loop', false);
    if (!window.jwplayer) return;

    const options = {
      file: this.getAbsoluteUrl(videoUrl),
      width: '100%',
      height: '100%',
      primary: 'html5',
      preload: 'auto',
      repeat: isloop,
      image: !isAutoPlay ? posterImageUrl : '',
      flashplayer: '/assets/scripts/jwplayer.flash.swf',
      events: {
        onReady: isAutoPlay && this.play,
        onSetupError: this.remove,
        onError: this.remove,
      },
    };
    this.player = window.jwplayer(ecmComponentId).setup(options);
  }

  play() {
    if (this.player) {
      this.player.play();
    }
  }

  remove() {
    if (this.player) {
      this.player.remove();
    }
  }

  render() {
    const { videoData } = this.props;
    const ecmComponentId = get(videoData, 'componentId', '');
    const videoUrl =
      get(this.props.videoData, 'properties.url') === null ||
      isEmpty(get(this.props.videoData, 'properties.url', ''))
        ? ''
        : get(this.props.videoData, 'properties.url');
    const isAutoPlay =
      this.props.enableAutoPlay &&
      get(videoData, 'properties.behavior', '') === 'autoplay';
    const posterImageUrl =
      get(videoData, 'properties.posterImageUrl', '') === null
        ? ''
        : get(videoData, 'properties.posterImageUrl', '');
    const showControls =
      get(videoData, 'properties.controls', false) !== null &&
      get(videoData, 'properties.controls', false);
    const isloop =
      get(videoData, 'properties.loop', false) !== null &&
      get(videoData, 'properties.loop', false);
    const ecmObjectStyle = `ecm-container__video__${ecmComponentId}`;
    const ecmPropertiesStyles = get(videoData, 'properties.breakpoints', {});
    const classesOfJWP = classNames(
      'ecm-container__jwp__video',
      ecmObjectStyle
    );
    const classesOfHtmlPlayer = classNames(
      'ecm-container__html5__video',
      ecmObjectStyle
    );
    const adaptiveStyleBlock = ecmGetAdaptiveStyleBlock(
      ecmPropertiesStyles,
      ecmObjectStyle
    );
    const bgStyle = {
      backgroundImage: `url(${posterImageUrl})`,
    };

    return (
      <div className="ecm-video">
        {videoUrl.indexOf('youtube.com') !== -1 ? (
          <div className={classesOfJWP}>
            <div className="video-player-container">
              <JWPlayer />
              <div id="posterImgDiv" style={bgStyle} />
              <div
                className="video-player-wrapper"
                id={`${showControls ? '' : 'hide-controlbar'}`}
              >
                <div id={ecmComponentId} />
              </div>
            </div>
          </div>
        ) : (
          <div className={classesOfHtmlPlayer}>
            <video
              width="100%"
              autoPlay={isAutoPlay}
              controls={showControls}
              poster={posterImageUrl}
              loop={isloop}
              muted={isAutoPlay}
            >
              <source src={videoUrl} type="video/mp4" />
            </video>
          </div>
        )}
        {adaptiveStyleBlock}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  enableAutoPlay:
    get(state, 'page.location.query.ENABLE_AUTOPLAY', 'true') === 'true',
});

export default connect(mapStateToProps)(DumbEcmVideo);
