import {
  RESOLVED_REWARDS_POINT,
  RESOLVED_REWARDS_PROMO,
  RESOLVED_REWARDS_LENGTH,
  REJECTED_REWARDS,
} from '../actions/actions-rewards';

const defaultState = {
  items: {
    point: [],
    promo: [],
  },
  length: 0,
  loaded: false,
  error: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESOLVED_REWARDS_POINT: {
      return {
        ...state,
        items: { ...state.items, point: action.payload },
      };
    }
    case RESOLVED_REWARDS_PROMO: {
      return {
        ...state,
        items: { ...state.items, promo: action.payload },
      };
    }
    case RESOLVED_REWARDS_LENGTH: {
      return {
        ...state,
        length: action.payload,
        loaded: true,
      };
    }
    case REJECTED_REWARDS: {
      return {
        ...state,
        error: true,
      };
    }
    default:
      return state;
  }
};
