import React from 'react';
import { connect } from 'react-redux';
import AppWrapper from './common/app-common';
import BgIncircleConciergePage from '../bgincircleconcierge/BgIncircleConciergePage';

export function AppBgIncircleConciergeWrapper({
  bgoLoyaltyToggle,
  bgoConciergeToggle,
}) {
  if (!(bgoLoyaltyToggle && bgoConciergeToggle)) {
    // don't render anything if feature is disabled
    return null;
  }

  return (
    <AppWrapper forceFullBleed>
      <BgIncircleConciergePage />
    </AppWrapper>
  );
}

const mapStateToProps = state => ({
  bgoLoyaltyToggle: state?.toggles?.BGO_LOYALTY_NEW || false,
  bgoConciergeToggle: state?.toggles?.BGO_CONCIERGE || false,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppBgIncircleConciergeWrapper);
