import React from 'react';
import { PointCardsTexts } from '../constants';
import './pointCards.scss';

const PointCards = ({ pointCardsAmount }) => {
  return (
    <div className="point-cards">
      <h2 className="point-cards__title">{PointCardsTexts.title}</h2>
      <p className="point-cards__info">{PointCardsTexts.info}</p>
      <div className="point-cards__amount">
        <h3 className="point-cards__amount__value">${pointCardsAmount}</h3>
      </div>
    </div>
  );
};

export default PointCards;
