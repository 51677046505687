import React from 'react';
import classNames from 'classnames';
import './PasswordRequirementsBox.scss';

const mainClass = 'password-requirements-box';
const checkedAriaLabel = 'Requirement is met';
const uncheckedAriaLabel = 'Requirement not met';

function PasswordRequirement({ text, checked }) {
  const reqClass = classNames(
    `${mainClass}__requirement`,
    checked && `${mainClass}__requirement--checked`,
  );

  return (
    <div
      aria-label={checked ? checkedAriaLabel : uncheckedAriaLabel}
      className={reqClass}
    >
      <span aria-hidden className="password-requirements-box__icon-container">
        <i className="password-requirements-box__icon" />
      </span>
      {text}
    </div>
  );
}

export default function PasswordRequirementsBox({ requirements }) {
  return (
    <div aria-label="Password requirements" className={mainClass}>
      {requirements.map(req => (
        <PasswordRequirement
          key={req.text}
          text={req.text}
          checked={req.checked}
        />
      ))}
    </div>
  );
}
