import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import React from 'react';
import classNames from 'classnames';
import {
  ecmGetAdaptiveStyleBlock,
  ecmGetAdaptiveNewStyleBlock,
} from '../../utilities';
import EcmTextBreakPointComponent from './textComponentBreakPoints';

const EcmText = ({
  ecmCallToActionButtonWithTextToggle,
  text,
  ecmSlot,
  formatAdaptiveStyleBlock = true,
  newStyleBlock = false,
}) => {
  const styleBlock = newStyleBlock
    ? ecmGetAdaptiveNewStyleBlock
    : ecmGetAdaptiveStyleBlock;
  const ecmStyle = get(ecmSlot, 'className', '');
  const componentId = get(text, 'componentId', '');
  const componentClass = `ecm-container__text__${componentId}`;
  const classes = classNames('ecm-container__text', ecmStyle, componentClass);
  const divStyle = isEmpty(get(text, 'properties.bgcolor', ''))
    ? {}
    : { backgroundColor: get(text, 'properties.bgcolor', '') };
  const desktopContent = get(text, 'properties.text', '');
  const tabletContent = get(text, 'properties.tabletText', '');
  const mobileContent = get(text, 'properties.mobileText', '');
  const adaptiveStyleBlock = formatAdaptiveStyleBlock
    ? styleBlock(get(text, 'properties.padding', {}), componentClass)
    : '';
  let callToActionCustomStylesDesktop = [];
  let callToActionCustomStylesTablet = [];
  let callToActionCustomStylesMobile = [];
  if (ecmCallToActionButtonWithTextToggle) {
    callToActionCustomStylesDesktop = get(
      text,
      'properties.callToActionCustomStyles.desktop',
      [],
    );
    callToActionCustomStylesTablet = get(
      text,
      'properties.callToActionCustomStyles.tablet',
      [],
    );
    callToActionCustomStylesMobile = get(
      text,
      'properties.callToActionCustomStyles.mobile',
      [],
    );
  }
  return ecmCallToActionButtonWithTextToggle ? (
    <EcmTextBreakPointComponent
      callToActionCustomStylesDesktop={callToActionCustomStylesDesktop}
      callToActionCustomStylesTablet={callToActionCustomStylesTablet}
      callToActionCustomStylesMobile={callToActionCustomStylesMobile}
      classes={classes}
      divStyle={divStyle}
      desktopContent={desktopContent}
      tabletContent={tabletContent}
      mobileContent={mobileContent}
      adaptiveStyleBlock={adaptiveStyleBlock}
    />
  ) : (
    <EcmTextBreakPointComponent
      classes={classes}
      divStyle={divStyle}
      desktopContent={desktopContent}
      tabletContent={tabletContent}
      mobileContent={mobileContent}
      adaptiveStyleBlock={adaptiveStyleBlock}
    />
  );
};
export default EcmText;
