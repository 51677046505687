/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';
import { object } from 'prop-types';
import get from 'lodash/get';
import merge from 'lodash/merge';
import EditorialCarousel from './components/editorialCarousel/editorialCarousel';
import FullWidthCarousel from './components/fullWidthCarousel/fullWidthCarousel';
import SlideShow from '../slideShow/slideShow';
import { defaultTrackingTags } from '../../constants/constants';
import { getTags } from '../utils';

const componentMap = {
  Editorial: EditorialCarousel,
  Carousel: FullWidthCarousel,
  'Site Ticker': SlideShow,
};

const Default = () => <div />;

/**
 * @function Carousel
 * @param {Object} props.cmsContentItem
 * @param {Object} props.parentTrackingTags
 * @returns {React.ReactElement}
 */
const Carousel = ({ cmsContentItem, parentTrackingTags }) => {
  const [domReady, setDomReady] = useState(false);
  const cmsContentFields = get(cmsContentItem, 'fields', {});
  const { trackingTags, type } = cmsContentFields;

  // Set up Tracking Tags
  const currentTags = getTags(trackingTags);
  const mergedTrackingTags = merge({}, parentTrackingTags, currentTags);

  useEffect(() => {
    document.addEventListener('readystatechange', () => {
      if (document.readyState === 'complete') {
        setDomReady(true);
      }
    });
  }, []);

  // Set up Component props
  const componentProps = {
    cmsContentItem,
    parentTrackingTags: mergedTrackingTags,
    domReady,
  };

  if (type === 'Site Ticker') componentProps.type = 'site-ticker';

  const Component = componentMap[type];

  return Component ? <Component {...componentProps} /> : <Default />;
};

Carousel.defaultProps = {
  parentTrackingTags: defaultTrackingTags,
};

Carousel.propTypes = {
  parentTrackingTags: object,
};

export default Carousel;
