import { types as MiniCartTypes } from './miniCartDT-actions';

const getProductsList = cartData => {
  const productList =
    cartData?.shoppingCart?.commerceItems?.shipments?.reduce(
      (acc, shipment) => [...acc, ...shipment.items],
      [],
    ) || [];
  if (cartData?.recentlyAddedItem) {
    return [
      ...cartData?.recentlyAddedItem,
      ...productList.filter(
        item => item.dt_itemId !== cartData.recentlyAddedItem.dt_itemId,
      ),
    ];
  }
  return productList;
};

const initialState = {
  isMiniCartOpen: false,
  miniCartQty: 0,
  miniCartItems: [],
  miniCartSubtotal: null,
  timerId: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MiniCartTypes.SHOW_DT_MINI_CART:
      return {
        ...state,
        isMiniCartOpen: true,
      };
    case MiniCartTypes.HIDE_DT_MINI_CART:
      return {
        ...state,
        isMiniCartOpen: false,
      };
    case MiniCartTypes.RESOLVED_DT_MINI_CART_DATA:
      return {
        ...state,
        miniCartItems: getProductsList(action.payload),
        miniCartSubtotal: action.payload.prices.mdseTotalAmt,
        miniCartQty: action.payload.shoppingCart.totalQuantity,
      };
    case MiniCartTypes.UPDATE_DT_MINI_CART_DATA:
      return {
        ...state,
        miniCartItems: getProductsList(action.payload),
        miniCartSubtotal: action.payload.prices.mdseTotalAmt,
        miniCartQty: action.payload.shoppingCart.totalQuantity,
      };
    case MiniCartTypes.SET_CLOSE_TIMER:
      return {
        ...state,
        timerId: action.timerId,
      };

    default:
      return state;
  }
};
