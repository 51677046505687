import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeModal } from 'shared/components/Modal/actions';
import { markStart } from 'client-utils/utilities-performance';
import { addToBag } from '../../../actions';
import StoreSearchResult from './storeSearchResult';

export class DumbStoreSearchResults extends Component {
  constructor() {
    super();
    this.onClickAddToBag = this.onClickAddToBag.bind(this);
  }

  onClickAddToBag(storeNumber) {
    markStart('PDP_TimeToAddToBag');
    const {
      addToBag,
      closeModal,
      product,
      selectedSku,
      skuMediaUrl,
    } = this.props;

    addToBag(product, selectedSku, skuMediaUrl, storeNumber);
    closeModal();
  }

  render() {
    const {
      stores,
      defaultStoreValues,
      bopsCurbsideToggle = false,
    } = this.props;

    return (
      <ul>
        <li
          key="label"
          aria-hidden="true"
          className={`bops__search__results${
            bopsCurbsideToggle ? '__curbside' : '__label'
          } grid-100 tablet-grid-100 mobile-grid-100`}
        >
          <div
            id="storeLocationLabel"
            className="grid-35 tablet-grid-25 mobile-grid-50"
          >
            <span>Store Location</span>
          </div>
          <div
            id="storeAvailabilityLabel"
            className="grid-25 tablet-grid-30 mobile-grid-50"
          >
            <span>Store Availability</span>
          </div>
          <div className="grid-40 tablet-grid-45 hide-on-mobile grid-parent">
            <span>
              {bopsCurbsideToggle
                ? 'Pickup in Store'
                : 'Buy Online Pickup In Store'}
            </span>
          </div>
        </li>

        {stores.map(store => (
          <li
            key={store.storeId}
            className="grid-100 tablet-grid-100 mobile-grid-100 bops-spacing"
          >
            <StoreSearchResult
              store={store}
              onClickAddToBag={() => this.onClickAddToBag(store.storeNumber)}
              defaultStoreValues={defaultStoreValues}
              bopsCurbsideToggle={bopsCurbsideToggle}
            />
          </li>
        ))}
      </ul>
    );
  }
}

const mapDispatchToProps = { addToBag, closeModal };

export default connect(
  undefined,
  mapDispatchToProps,
)(DumbStoreSearchResults);
