import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import getProduct from 'pdp/components/ProductPage/selectors/getProduct';
import {
  setActiveMediaIndex,
  setProductVideo,
  unsetProductVideo,
} from 'pdp/components/ProductPage/actions';
import Image, {
  NO_IMAGE_AVAILABLE_CDN,
} from 'pdp/components/ProductPage/components/Image/image';
import './alternateImages.scss';

export class DumbAlternateImages extends Component {
  constructor() {
    super();
    this.toggleVideo = this.toggleVideo.bind(this);
    this.setAltImage = this.setAltImage.bind(this);
  }

  setAltImage(index) {
    const { unsetProductVideo, setActiveMediaIndex } = this.props;
    unsetProductVideo();
    setActiveMediaIndex(index);
  }

  toggleVideo() {
    const { videoActive, unsetProductVideo, setProductVideo } = this.props;
    videoActive ? unsetProductVideo() : setProductVideo();
  }

  render() {
    const { name, images = [], video = {} } = this.props;
    const videoThumbnail = video.thumbnail && (
      <li key="video" className="video">
        <button onClick={this.toggleVideo}>
          <div className="alternate-images__video-overlay" />
          <Image
            alt={`Video: ${name}`}
            src={get(video, 'thumbnail.url', NO_IMAGE_AVAILABLE_CDN)}
          />
        </button>
      </li>
    );
    const altImages = images.map((image, index) => (
      <li key={index}>
        <button onClick={() => this.setAltImage(index)}>
          <Image
            alt={`Image ${index + 1} of ${images.length}: ${name}`}
            src={get(image, 'thumbnail.url', NO_IMAGE_AVAILABLE_CDN)}
          />
        </button>
      </li>
    ));

    return !isEmpty(images) && images.length > 1 ? (
      <div className="alternate-images hide-on-mobile">
        <ul>{altImages.concat(videoThumbnail)}</ul>
      </div>
    ) : (
      false
    );
  }
}

const mapStateToProps = (state, props) => ({
  videoActive: get(getProduct(state, props.productId), 'videoActive'),
});

const mapDispatchToProps = (dispatch, { productId }) => ({
  setActiveMediaIndex: index => dispatch(setActiveMediaIndex(productId, index)),
  setProductVideo: () => dispatch(setProductVideo(productId)),
  unsetProductVideo: () => dispatch(unsetProductVideo(productId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbAlternateImages);
