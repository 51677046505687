export const PD_IN_STOCK = 'In Stock';
export const PD_OUT_OF_STOCK = 'Out of Stock';
export const WISHLIST_EMPTY_TITLE = 'Your wishlist is empty';
export const WISHLIST_CONTINUE_SHOPPING = 'Continue Shopping';
export const WISHLIST_EMPTY_MSG = `You don’t have any items in your wish list. When you add products, they’ll appear here.`;
export const WISHLIST_EDIT = 'Edit';
export const WISHLIST_ADD_TO_BAG = 'Add to Bag';
export const WISHLIST_REMOVE = 'Remove';
export const SAVE_TO_WISHLIST = 'Save to Wishlist';
export const ERROR_SIZE_NOT_SELECTED = 'Please choose a size for the product';
export const ERROR_PROD_OPTION_NOT_SELECTED =
  'Please choose a color, size, and/or Monogram option for the product';
export const WISHLIST_PAGE_TITLE = 'WISHLIST';
export const BROWSER_TITLE = 'Wishlist ';
export const DELEVERY_DATE_PREFIX = 'DELIVERYDATE-';
export const ERROR_DATE_NOT_SELECTED = 'Please select a date to proceed';
export const REPLENISH_PREFIX = 'Replenish:';
export const REPLENISH_SUFFIX = 'Days';
export const ESTIMATED_DELIVERY_DATE = 'Estimated Delivery Date:';
export const ATG_SIGN_IN_URL = '/account/login.jsp';
export const DT_SIGN_IN_URL = '/e/login';
