import React from 'react';
import _get from 'lodash/get';
import Image from '../Image/image';
import RichText from '../RichText/richText';

const calculateTextOverlayPositionProps = (
  position,
  horizontalMargin = 0,
  verticalMargin = 0,
) => {
  const horizontalMarginValue = horizontalMargin && `${horizontalMargin}%`;
  const verticalMarginValue = verticalMargin && `${verticalMargin}%`;

  switch (position) {
    case 'Top Left':
      return {
        top: verticalMarginValue,
        left: horizontalMarginValue,
      };

    case 'Top Center':
      return {
        top: verticalMarginValue,
        left: '50%',
        transform: 'translate(-50%, 0)',
      };

    case 'Top Right':
      return {
        top: verticalMarginValue,
        right: horizontalMarginValue,
      };

    case 'Middle Left':
      return {
        top: '50%',
        left: horizontalMarginValue,
        transform: 'translate(0, -50%)',
      };

    case 'Middle Center':
      return {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      };

    case 'Middle Right':
      return {
        top: '50%',
        right: horizontalMarginValue,
        transform: 'translate(0, -50%)',
      };

    case 'Bottom Left':
      return {
        left: horizontalMarginValue,
        bottom: verticalMarginValue,
      };

    case 'Bottom Center':
      return {
        left: '50%',
        bottom: verticalMarginValue,
        transform: 'translate(-50%, 0)',
      };

    case 'Bottom Right':
      return {
        right: horizontalMarginValue,
        bottom: verticalMarginValue,
      };

    default:
      return {};
  }
};

const TextOverImage = ({ content }) => {
  const fields = _get(content, 'fields', {});
  const {
    image,
    text,
    desktopTextPosition,
    desktopTextWidth = 'auto',
    desktopTextMarginHorizontal,
    desktopTextMarginVertical,
  } = fields;

  const styles = {
    position: 'absolute',
    ...calculateTextOverlayPositionProps(
      desktopTextPosition,
      desktopTextMarginHorizontal,
      desktopTextMarginVertical,
    ),
    width: desktopTextWidth === 'auto' ? 'auto' : `${desktopTextWidth}%`,
  };

  return (
    <div className="text-over-image-container">
      <Image content={image} />
      <div className="text-container" style={styles}>
        <RichText content={text} />
      </div>
    </div>
  );
};

export default TextOverImage;
