import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import EditButton from '../EditButton/EditButton';
import {
  cancelButtonText,
  editButtonText,
  EDIT_SECTION_PHONE,
} from '../../../constants';
import {
  closeAccountDetailsEdit,
  openAccountDetailsEdit,
  editAccountDetails,
} from '../actions';
import { getEditState } from '../helpers';
import SubmitButton from '../../../../common/Forms/FormButtons/SubmitButton/SubmitButton';
import FormikNumberField from '../../../../common/Forms/FormikNumberField/FormikNumberField';
import { validationErrors } from '../../../../login/constants';

const SECTION = EDIT_SECTION_PHONE;

const validateForm = values => {
  const errors = {};
  if (!values.phoneNumber?.trim()) {
    errors.phoneNumber = validationErrors.phoneNumberEmpty;
  } else if (values.phoneNumber.length < 12) {
    errors.phoneNumber = validationErrors.invalidPhoneNumber;
  }
  return errors;
};

const PhoneField = ({
  phoneNumber,
  error,
  isEditOpened,
  isEditDisabled,
  closeAccountDetailsEdit,
  openAccountDetailsEdit,
  isUpdating,
  isGreyCssFix,
  editAccountDetails,
}) => {
  const handleEditClick = () => {
    isEditOpened ? closeAccountDetailsEdit() : openAccountDetailsEdit(SECTION);
  };

  const formattedPhoneNumber =
    (phoneNumber && phoneNumber?.split('+1')[1])?.replace(
      /(\d{3})(\d{3})(\d{4})/,
      '$1-$2-$3',
    ) || '';
  const initialFormValue = {
    phoneNumber: formattedPhoneNumber,
  };

  const submitForm = values => {
    if (values.phoneNumber) {
      const phoneNumber = `+1${values?.phoneNumber.replaceAll('-', '')}`;
      editAccountDetails({ phoneNumber });
    } else {
      closeAccountDetailsEdit();
    }
  };
  return (
    <div className="account-details-page__field-wrapper" aria-live="polite">
      <div className="account-details-page__field-header">
        <h3 className="account-details-page__field-label">Phone number</h3>
        <div className="account-details-page__field-edit">
          <EditButton
            editText={editButtonText}
            cancelText={cancelButtonText}
            isEditing={isEditOpened}
            isDisabled={isEditDisabled || isUpdating}
            onButtonClick={handleEditClick}
            isGreyCssFix={isGreyCssFix}
          />
        </div>
      </div>
      <div className="account-details-page__field-content">
        {isEditOpened ? (
          <Formik
            initialValues={initialFormValue}
            validate={validateForm}
            onSubmit={submitForm}
          >
            {({ values }) => (
              <Form>
                <div className="account-details-page__edit-form-fields-container custom-mobile-field">
                  <FormikNumberField
                    name="phoneNumber"
                    id="phoneNumber"
                    placeholder="Phone Number"
                    options={{ blocks: [3, 3, 4], delimiter: '-' }}
                    fontSize
                    disabled={!values?.enrollLoyalty}
                  />
                </div>
                {error && (
                  <p className="account-details-page__edit-error">{error}</p>
                )}
                <div className="account-details-page__edit-submit">
                  <SubmitButton
                    isSubmitting={isUpdating}
                    type="submit"
                    caption="Save"
                    additionalClasses="account-details-page__edit-submit-btn"
                  />
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <p className="account-details-page__field-value">
            {formattedPhoneNumber || 'Add your phone number'}
          </p>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  phoneNumber: get(
    state,
    'myaccount.myAccountDetails.fields.phoneNumber',
    null,
  ),
  fields: get(state, 'myaccount.myAccountDetails.fields', null),
  isUpdating: get(state, 'api.update_basic_attributes.loading', false),
  error: get(
    state,
    'myaccount.myAccountDetails.updateBasicAttributesError',
    null,
  ),
  ...getEditState(state, SECTION),
  isGreyCssFix: get(state, 'toggles.GREYCOLOR_CSS_FIX', false),
});

const mapDispatchToProps = {
  openAccountDetailsEdit,
  closeAccountDetailsEdit,
  editAccountDetails,
};

const PhoneNumberField = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PhoneField);

export default PhoneNumberField;
