import React, { Fragment, useEffect, useRef, useState } from 'react';
import { func, bool } from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  PAGE_ID_BG_CREDIT_CARD,
  setPageId,
} from '@bgo-ui/common/client/common/actions/actions-page';
import Spinner from 'shared/components/Spinner/simpleSpinner';
import {
  getLoyaltyProfileId,
  isPhoneAvailableInProfile,
} from '@bgo-ui/common/client/utilities/utilities-loyalty';
import { useConstructor } from '../../../../../utilities/hooks';
import { setResolvedPage } from '../../../actions';
import { RESOLVED_BG_CREDIT_CARD_PAGE } from '../../../constants';
import './NewBgCreditCardPage.scss';
import { openPhoneModal } from '../../../../login/actions';
import {
  BgCreditCard,
  ContentSpot,
  FlyOut,
  IncirclePhone,
  MemberBenefits,
  TermsAndConditions,
  YourBenefits,
} from './widgets';
import { getDateOfChoosing, activateDoublePoint } from '../actions';
import YourStatusAndPoints from './YourPoints.js';
import RewardsList from '../../MyRewardsPage/RewardsList/RewardsList';
import { UNEXPECTED_ERROR } from './constants.js';
import MyAccountMobileBackHeader from '../../MyAccountPage/MyAccountBackHeader/MyAccountMobileBackHeader.js';

const NewBgCreditCardPage = ({
  setPageId,
  setResolvedPage,
  isLoading,
  openPhoneModal,
  amplifyConfig,
  user,
  loyPhoneToggle,
  loyaltyWelcomToggle,
  bgoConciergeToggle,
  appConfigWelcomeMsg,
  userName,
  loyaltyUser,
  isDOCActivated,
  strikingPointsText,
  manualRedemptionText,
  benefitDetails,
  flyoutsData,
  myAccountInCircleRewards,
  bgCreditCardDescription,
  getDateOfChoosing,
  activateDoublePoint,
  isDOCActivating,
  isErrorFetchingActivation,
  phoneNumber,
}) => {
  const isCheckedLoyaltyUser = loyaltyUser.isChecked || false;
  const loyaltySummary = {
    ...(loyaltyUser.data || {}),
    tryAgainLater: loyaltyUser.error || false,
    isLoading: loyaltyUser.isLoading || false,
  };

  const [loyaltyProfileId, setLoyaltyProfileId] = useState('');
  const [isPhoneAvailable, setPhoneAvailablity] = useState(true);
  const [activeFlyout, setFlyOut] = useState('');
  const [flyOutMargin, setFlyOutMargin] = useState(0);

  const flyoutRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (flyoutRef.current && flyoutRef.current === event.target) {
        // Clicked outside of the flyout
        setFlyOut(false);
        document.body.style.overflow = ''; // Enable scrolling of the entire page
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useConstructor(() => {
    setPageId(PAGE_ID_BG_CREDIT_CARD);
  });

  const sendUtagLinkData = data =>
    window.utag && window.utag.link && window.utag.link(data);

  const isLoyaltyEnabled = async () => {
    const id = await getLoyaltyProfileId(user, amplifyConfig);
    setLoyaltyProfileId(id);
    if (loyPhoneToggle) {
      const phone = await isPhoneAvailableInProfile(user, amplifyConfig);
      setPhoneAvailablity(phone);
    }
  };

  useEffect(() => {
    if (isCheckedLoyaltyUser) {
      setResolvedPage(RESOLVED_BG_CREDIT_CARD_PAGE);
    }
  }, [isCheckedLoyaltyUser]);

  useEffect(() => {
    isLoyaltyEnabled();
  }, []);

  useEffect(() => {
    if (activeFlyout === 'doublePoints' && !isDOCActivated) {
      getDateOfChoosing();
    }
  }, [activeFlyout]);

  useEffect(() => {
    setPhoneAvailablity(phoneNumber);
  }, [phoneNumber]);

  const handleFlyout = value => {
    if (value) {
      const flyoutNameMap = [
        { doublePoints: 'viewPointsCard', perk: 'viewPerksCard' },
      ];
      // 167 is header height here
      window?.scrollY < 167
        ? setFlyOutMargin(160 - window?.scrollY / 1.25)
        : setFlyOutMargin(0);
      window.utag_data_dt = {
        ...window.utag_data_dt,
        event_name: flyoutNameMap?.[0][value],
      };
      sendUtagLinkData({ ...window.utag_data_dt });
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    setFlyOut(value);
  };

  const handleDoublePoints = () => {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      event_name: 'activatePoints',
    };
    sendUtagLinkData({ ...window.utag_data_dt });
    // handle api call fro DOC
    activateDoublePoint();
  };

  const loyaltyTierCode = loyaltySummary?.tierCode?.toLowerCase();
  const BGCreditCard = () => {
    return loyaltySummary?.tryAgainLater ? (
      <div className="unexpected-error">
        {UNEXPECTED_ERROR.title}
        <p>{UNEXPECTED_ERROR.desc}</p>
      </div>
    ) : (
      loyaltyProfileId && (
        <div className="loyalty-hub-con">
          {loyPhoneToggle && !isPhoneAvailable && (
            <IncirclePhone openPhoneModal={openPhoneModal} />
          )}
          {loyaltyWelcomToggle && (
            <ContentSpot
              userName={userName || ''}
              content={appConfigWelcomeMsg}
            />
          )}
          <YourStatusAndPoints
            summaryDetails={loyaltySummary}
            strikingPointsText={strikingPointsText}
            manualRedemptionText={manualRedemptionText}
          />
          {myAccountInCircleRewards?.length > 0 && (
            <RewardsList cards={myAccountInCircleRewards} isIncircle />
          )}
          {loyaltyTierCode && (
            <>
              <YourBenefits
                tierCode={loyaltyTierCode}
                benefits={benefitDetails}
                handleFlyout={handleFlyout}
                flyoutsData={flyoutsData}
                bgoConciergeToggle={bgoConciergeToggle}
              />

              <BgCreditCard desc={bgCreditCardDescription} />
              <MemberBenefits />
              <TermsAndConditions
                terms={benefitDetails[loyaltyTierCode]?.terms}
              />
              <FlyOut
                flyOutMargin={flyOutMargin}
                flyoutRef={flyoutRef}
                flyoutsData={flyoutsData}
                activeFlyout={activeFlyout}
                handleFlyout={handleFlyout}
                handleDoublePoints={handleDoublePoints}
                isDOCActivated={isDOCActivated}
                isBtnLoading={isDOCActivating}
                isError={isErrorFetchingActivation}
              />
            </>
          )}
        </div>
      )
    );
  };
  return isLoading || loyaltySummary.isLoading ? (
    <Spinner />
  ) : (
    <Fragment>
      <MyAccountMobileBackHeader title="BG CREDIT CARD" />
      <BGCreditCard />
    </Fragment>
  );
};

NewBgCreditCardPage.propTypes = {
  setPageId: func.isRequired,
  setResolvedPage: func.isRequired,
  isLoading: bool.isRequired,
};

const mapStateToProps = state => ({
  isLoading:
    state?.api?.bg_card?.loading ||
    state?.api?.content?.loading ||
    state?.myaccount?.isLoading ||
    false,
  amplifyConfig: state.amplifyConfig,
  user: state.user,
  loyPhoneToggle: state?.toggles?.LOY_PHONE_UPDATE || false,
  loyaltyWelcomToggle: state?.toggles?.LOY_HUB_WELCOME || false,
  bgoConciergeToggle: state?.toggles?.BGO_CONCIERGE || false,
  userName: get(state, 'user.name', false),
  loyaltyUser: get(state, 'user.loyaltyUser', {}),
  appConfigWelcomeMsg: get(state, 'loyaltyConfig.textConstants.welcomeMsg', ''),
  strikingPointsText: get(
    state,
    'loyaltyConfig.textConstants.strikingPointsText',
    '',
  ),
  manualRedemptionText: get(
    state,
    'loyaltyConfig.textConstants.manualRedemptionText',
    '',
  ),
  benefitDetails: get(state, 'loyaltyConfig.userBenefits', ''),
  flyoutsData: get(state, 'loyaltyConfig.flyouts', ''),
  myAccountInCircleRewards: get(state, 'rewards.items.point', true),
  bgCreditCardDescription: get(
    state,
    'loyaltyConfig.textConstants.bgCreditCardDescription',
    '',
  ),
  isDOCActivated: state?.myaccount?.isDOCActivated,
  isDOCActivating:
    state?.myaccount?.isDOCActivating || state?.myaccount?.isFetchingDOCData,
  isErrorFetchingActivation: state?.myaccount?.isError,
  phoneNumber: state?.myaccount?.myAccountDetails?.fields?.phoneNumber || true,
});

const mapDispatchToProps = {
  setPageId,
  setResolvedPage,
  openPhoneModal,
  getDateOfChoosing,
  activateDoublePoint,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewBgCreditCardPage);
