import React from 'react';
import { connect } from 'react-redux';
import { H2, BodyB1 } from 'bgo-common/Styleguide/Typography';
import Button from 'shared/components/Button/button';
import get from 'lodash/get';
import '../../welcomeMat.scss';
import closeSVG from 'assets/images/close.svg';

const WelcomeMatModal = props => {
  const { intWCMContent } = props;
  return (
    <div className="content-wrapper">
      <div className="heading">
        <H2>{intWCMContent.INT_WC_HEADING}</H2>
        <div className="close" onClick={props.closeModal}>
          <img
            alt="Close"
            aria-hidden="true"
            className="close-icon"
            src={closeSVG}
          />
        </div>
      </div>
      <div className="body">
        <p className="description">
          <BodyB1>{intWCMContent.INT_WC_MESSAGE}</BodyB1>
        </p>
        <div className="action-wrapper">
          <Button
            value="Start Shopping"
            primary
            onClick={props.startShopping}
          />
        </div>
      </div>
      <div className="footer footer-text">
        <BodyB1>
          {intWCMContent.INT_WC_NO_INT_SHIPPING}
          <a
            href={intWCMContent.INT_WC_US_SITE_LINK}
            area-label={intWCMContent.INT_WC_SHOP_US_SITE}
          >
            <span className="link">{intWCMContent.INT_WC_SHOP_US_SITE}</span>
          </a>
        </BodyB1>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  intWCMContent: get(state, 'content.internationalWelcomeMat', {}),
});
export default connect(mapStateToProps)(WelcomeMatModal);
