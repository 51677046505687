import React, { Component } from 'react';
import './fashionCuralate.scss';

export class EcmFashionCuralate extends Component {

  componentDidMount() {
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.src = 'https://d116tqlcqfmz3v.cloudfront.net/bergdorfgoodman-1623/carousel.js';
    scriptTag.async = true;
    scriptTag.onload = this.curalateScriptLoaded;
    document.head.appendChild(scriptTag);
  }

  curalateScriptLoaded = () => {
    window.Curalate.FanReels.Carousel.init({ code: 'bergdorfgoodman' });
  }

  render() {
    return (
      <React.Fragment>
        <div id="curalateHeader" className="curalateHeader">
          <div id="curalateTitleSection">
            <div className="curalateTitle">THE FASHION YOU NEED, NOW</div>
            <hr size="1" className="horizontalLine" />
          </div>
          <div id="curalateSubTitle">
            <div className="curalateBG">@bergdorfs
            <a href="/Curalate/cat557302_cat219100/c.cat"><div className="curalateDiscover">Discover More</div></a>
            </div>
          </div>
        </div>
        <div id="curalate-fan-reel-wrapper" />
      </React.Fragment>
    );
  }
}

export default EcmFashionCuralate;
