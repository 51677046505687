import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import get from 'lodash/get';
import { Form, Formik } from 'formik';
import PasswordRequirementsBox from '../../../../../../../common/Forms/FormikPasswordField/PasswordRequirementsBox/PasswordRequirementsBox';
import FormikPasswordField from '../../../../../../../common/Forms/FormikPasswordField/FormikPasswordField';
import { changeAccountPassword } from '../../../../actions';
import { getPasswordRequirements } from '../../../../../../utilities/validation-utils';
import SubmitButton from '../../../../../../../common/Forms/FormButtons/SubmitButton/SubmitButton';
import FormikTextField from '../../../../../../../common/Forms/FormikTextField/FormikTextField';
import './ChangePasswordForm.scss';
import { validateVerificationCode } from '../../../TotpConfirmation/totp-helpers';

const DumbChangePasswordForm = props => {
  const { isLoading, error } = props;

  const initialFormValue = {
    verificationCode: '',
    newPassword: '',
    confirmPassword: '',
  };

  const validateForm = values => {
    const errors = {};

    const codeError = validateVerificationCode(values.verificationCode);
    if (codeError) {
      errors.verificationCode = codeError;
    }

    if (!values.newPassword) {
      errors.newPassword = 'Please enter your new password.';
    } else if (
      getPasswordRequirements(values.newPassword).some(req => !req.checked)
    ) {
      errors.newPassword = 'Password does not meet criteria.';
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = 'Please confirm your new password.';
    } else if (values.newPassword !== values.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match.';
    }
    return errors;
  };

  const submitForm = values => {
    props.changeAccountPassword({
      totp: values.verificationCode.trim(),
      newPassword: values.newPassword,
    });
  };

  return (
    <Formik
      initialValues={initialFormValue}
      validate={validateForm}
      onSubmit={submitForm}
    >
      {({ values }) => (
        <Form autoComplete="off">
          <input name="DummyUsername" type="text" className="invisible" />
          <input name="DummyPassword" type="password" className="invisible" />
          <div className="change-password-form__form-fields-container">
            <p className="change-password-form__field-description">
              Please enter your verification code below.
            </p>
            <FormikTextField
              name="verificationCode"
              id="verification-code-field"
              placeholder="Verification code"
              autoComplete="verification-code"
              fontSize
            />
            <p className="change-password-form__field-description">
              Enter new password below.
            </p>
            <div className="form-field-wrapper">
              <FormikPasswordField
                name="newPassword"
                id="new-password-field"
                placeholder="New password"
                autoComplete="new-password"
                fontSize
              />
              <PasswordRequirementsBox
                requirements={getPasswordRequirements(values.newPassword)}
              />
            </div>
            <FormikPasswordField
              name="confirmPassword"
              id="confirm-password-field"
              placeholder="Confirm password"
              autoComplete="new-password-confirm"
              fontSize
            />
          </div>
          {error && <p className="change-password-form__error">{error}</p>}
          <div
            className={classnames(
              'change-password-form__submit',
              getPasswordRequirements(values.newPassword).some(
                req => !req.checked,
              ) && 'change-password-form_divDisabled',
            )}
          >
            <SubmitButton
              isSubmitting={isLoading}
              type="submit"
              caption="Change password"
              additionalClasses="change-password-form__submit-btn"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

DumbChangePasswordForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  changeAccountPassword: PropTypes.func.isRequired,
};

DumbChangePasswordForm.defaultProps = {
  error: null,
};

const mapStateToProps = state => ({
  isLoading: get(state, 'api.change_password.loading', false),
  error: get(state, 'myaccount.myAccountDetails.changePasswordError', null),
});
const mapDispatchToProps = {
  changeAccountPassword,
};

const ChangePasswordForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbChangePasswordForm);

export default ChangePasswordForm;
