import get from 'lodash/get';
import httpWithLogging from 'universal/http-client';
import { stringify } from 'query-string';
import { buildCookieString } from 'universal/utilities-cookies';

export const types = {
  LOADING_LEFTNAV_CONTENT: 'LOADING_LEFTNAV_CONTENT',
  RESOLVED_LEFTNAV_CONTENT: 'RESOLVED_LEFTNAV_CONTENT',
  REJECTED_LEFTNAV_CONTENT: 'REJECTED_LEFTNAV_CONTENT',
  RESOLVED_ADD_FAVORITE_CATEGORY: 'RESOLVED_ADD_FAVORITE_CATEGORY',
  REJECTED_ADD_FAVORITE_CATEGORY: 'REJECTED_ADD_FAVORITE_CATEGORY',
  RESOLVED_REMOVE_FAVORITE_CATEGORY: 'RESOLVED_REMOVE_FAVORITE_CATEGORY',
  REJECTED_REMOVE_FAVORITE_CATEGORY: 'REJECTED_REMOVE_FAVORITE_CATEGORY',
  RESOLVED_GET_FAVORITE_CATEGORY_STATUS: 'RESOLVED_GET_FAVORITE_CATEGORY_STATUS',
  REJECTED_GET_FAVORITE_CATEGORY_STATUS: 'REJECTED_GET_FAVORITE_CATEGORY_STATUS',
};

/* istanbul ignore next */
export function getLeftNavTreeByNavPath(navPath, source) {
  return (dispatch, getState) => {
    dispatch({ type: types.LOADING_LEFTNAV_CONTENT });
    const state = getState();
    const NODE_CLIENT_DEFAULT_TIMEOUT = 3000;
    const apiTimeouts = get(state, 'apiTimeouts', {});
    const NAV_API_TIMEOUT = get(apiTimeouts, 'NAVIGATION_API_TIMEOUT', NODE_CLIENT_DEFAULT_TIMEOUT);
    const requestApi = httpWithLogging(state, NAV_API_TIMEOUT);

    return requestApi.get(`${NMConfig.API_LEFTNAV}/${navPath}?source=${source}`)
      .then(successResponse => {
        return dispatch({ type: types.RESOLVED_LEFTNAV_CONTENT,
          payload: successResponse.data });
      })
      .catch(() => dispatch({ type: types.REJECTED_LEFTNAV_CONTENT }));
  };
}

export function dispatchPreFetchedLeftNavData(data) {
  return (dispatch) => {
    return dispatch({ type: types.RESOLVED_LEFTNAV_CONTENT,
      payload: data });
  };
}

/* istanbul ignore next */
export function addFavoriteCategory(isFavoritesPage, favValue, designerName) {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state);
    const query = stringify({ isFavoritesPage, favValue, designerName });
    const wkySendFavDesignerCatIdsToGranifyToggle = get(state, 'toggles.WKY_SEND_FAVORITE_DESIGNERS_TO_GRANIFY', false);

    return requestApi.post(`${NMConfig.API_ADD_FAVORITE_CATEGORY}?${query}`)
      .then(successResponse => {
        if (wkySendFavDesignerCatIdsToGranifyToggle && process.browser) {
          window && window.Granify && window.Granify('trackUserData', {
            Favorite: {
              action: 'add',
              ids: [successResponse.data.categoryId]
            }
          });
        }
        dispatch({
          type: types.RESOLVED_ADD_FAVORITE_CATEGORY,
          data: successResponse.data
        });
      })
      .catch(() => dispatch({ type: types.REJECTED_ADD_FAVORITE_CATEGORY }));
  };
}

/* istanbul ignore next */
export function removeFavoriteCategory(isFavoritesPage, favValue, designerName) {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state);
    const query = stringify({ isFavoritesPage, favValue, designerName });
    const wkySendFavDesignerCatIdsToGranifyToggle = get(state, 'toggles.WKY_SEND_FAVORITE_DESIGNERS_TO_GRANIFY', false);

    return requestApi.post(`${NMConfig.API_REMOVE_FAVORITE_CATEGORY}?${query}`)
      .then(successResponse => {
        if (wkySendFavDesignerCatIdsToGranifyToggle && process.browser) {
          window && window.Granify && window.Granify('trackUserData', {
            Favorite: {
              action: 'remove',
              ids: [successResponse.data.categoryId]
            }
          });
        }
        dispatch({
          type: types.RESOLVED_REMOVE_FAVORITE_CATEGORY,
          data: successResponse.data
        });
      })
      .catch(() => dispatch({ type: types.REJECTED_REMOVE_FAVORITE_CATEGORY }));
  };
}

/* istanbul ignore next */
export function getCategoryFavoriteStatus(categoryId) {
  return (dispatch, getState) => {
    const state = getState();
    const FAVORITE_CATEGORY_DEFAULT_API_TIMEOUT = 200;
    const CATEGORY_FAVORITE_API_TIMEOUT = get(state, 'apiTimeouts.CATEGORY_FAVORITE_API_TIMEOUT', FAVORITE_CATEGORY_DEFAULT_API_TIMEOUT);
    const requestApi = httpWithLogging(state, CATEGORY_FAVORITE_API_TIMEOUT);
    const { session } = state;
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        DYN_USER_CONFIRM: get(session, 'DYN_USER_CONFIRM', ''),
        W2A: get(session, 'W2A', '')
      })
    };

    return requestApi.get(`${NMConfig.API_GET_FAVORITE_CATEGORIES}`, { headers })
      .then(successResponse => {
        return dispatch({ type: types.RESOLVED_GET_FAVORITE_CATEGORY_STATUS,
          payload: successResponse.data,
          categoryId });
      })
      .catch(() => dispatch({ type: types.REJECTED_GET_FAVORITE_CATEGORY_STATUS }));
  };
}
