import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import React, { Component } from 'react';
import classNames from 'classnames';
import ConnectedEcmAdaptiveImage from '../EcmAdaptiveImage';
import {
  ecmGetAdaptiveStyleBlock,
  ecmGetImageWidthStyleBlock,
  ecmGetImageWithTextTextBlocks,
  ecmTextHasAnchor,
  ecmImageHasImageMap,
  ecmGetOptimizedAdaptiveStyleBlock,
} from '../../utilities';
import {
  getCustomStylesForCTADesktop,
  getCustomStylesForCTATablet,
  getCustomStylesForCTAMobile,
} from '../EcmText/textComponentUtilities';

export default class EcmTextWithImage extends Component {
  evaluateImageMaps(ecmDataObj, textHasAnchor, imageHasImageMap) {
    let dataObj = ecmDataObj;
    const linkUrl =
      get(dataObj, 'properties.linkurl') === null ||
      isEmpty(get(dataObj, 'properties.linkurl', ''))
        ? ''
        : get(dataObj, 'properties.linkurl');
    if (imageHasImageMap && !textHasAnchor && linkUrl.length > 0) {
      let textObj = get(dataObj, 'text', {});
      const textProps = {
        ...textObj.properties,
        text: `<a href="${dataObj.properties.linkurl}">${textObj.properties.text}</a>`,
        mobileText: `<a href="${dataObj.properties.linkurl}">${textObj.properties.mobileText}</a>`,
        tabletText: `<a href="${dataObj.properties.linkurl}">${textObj.properties.tabletText}</a>`,
      };
      textObj = {
        ...textObj,
        properties: textProps,
      };
      dataObj = {
        ...dataObj,
        text: textObj,
      };
    }
    return dataObj;
  }
  evaluateTextAnchors(ecmDataObj, textHasAnchor, imageHasImageMap) {
    let imgObj = get(ecmDataObj, 'image', {});
    const linkUrl =
      get(ecmDataObj, 'properties.linkurl') === null ||
      isEmpty(get(ecmDataObj, 'properties.linkurl', ''))
        ? ''
        : get(ecmDataObj, 'properties.linkurl');
    if ((textHasAnchor || imageHasImageMap) && linkUrl.length > 0) {
      const imgProps = {
        ...imgObj.properties,
        linkurl: ecmDataObj.properties.linkurl,
      };
      imgObj = {
        ...imgObj,
        properties: imgProps,
      };
    }
    return imgObj;
  }
  renderComponent(
    ecmCallToActionButtonWithTextToggle,
    ecmDataObj,
    ecmSlot,
    textHasAnchor,
    imageHasImageMap,
    improvePerformanceOfStyleToggle
  ) {
    const heroContent = {
      eyeBrowContent: 'Editorial',
      titleContent: 'OUR NEW FAVORITE LABELS',
      subCopyContent: 'Add instant glamour with these pieces',
      ctaContent: {
        label: 'Shop the Style',
        url: '#',
      },
      desktopPosition: 'right',
      mobilePosition: 'center',
    };

    const dataObj = this.evaluateImageMaps(
      ecmDataObj,
      textHasAnchor,
      imageHasImageMap
    );
    const imgObj = this.evaluateTextAnchors(
      ecmDataObj,
      textHasAnchor,
      imageHasImageMap
    );
    const textComponentClass = `ecm-container__text__${get(
      dataObj,
      'text.componentId',
      {}
    )}`;
    const textAdaptiveStyleBlock = ecmGetAdaptiveStyleBlock(
      get(dataObj, 'text.properties.padding', {}),
      textComponentClass
    );
    const imageComponentClass = improvePerformanceOfStyleToggle
      ? `ecm-${get(dataObj, 'image.componentId', {})}`
      : `ecm-container__image__${get(dataObj, 'image.componentId', {})}`;
    const imageAdaptiveStyleBlock = improvePerformanceOfStyleToggle
      ? ecmGetOptimizedAdaptiveStyleBlock(
          get(dataObj, 'image.properties.images', {}),
          imageComponentClass
        )
      : ecmGetAdaptiveStyleBlock(
          get(dataObj, 'image.properties.images', {}),
          imageComponentClass
        );
    const textBlocks = ecmGetImageWithTextTextBlocks(dataObj, ecmSlot);
    const buttonOverlay = get(dataObj, 'calltoactionbuttons');
    const callToActionCustomStylesDesktop = get(
      dataObj,
      'text.properties.callToActionCustomStyles.desktop',
      []
    );
    const callToActionCustomStylesTablet = get(
      dataObj,
      'text.properties.callToActionCustomStyles.tablet',
      []
    );
    const callToActionCustomStylesMobile = get(
      dataObj,
      'text.properties.callToActionCustomStyles.mobile',
      []
    );
    const classes = classNames(
      textBlocks.mobileTextPosition,
      textBlocks.tabletTextPosition,
      textBlocks.dektopTextPosition
    );
    return (
      <div className={classes}>
        {textAdaptiveStyleBlock}
        {imageAdaptiveStyleBlock}
        {textBlocks.textBefore}
        {textBlocks.mobileTextBefore}
        {textBlocks.tabletTextBefore}
        {textBlocks.desktopTextBefore}
        <div className="textwithimage-image">
          <ConnectedEcmAdaptiveImage
            picture={imgObj}
            ecmSlot={ecmSlot}
            formatAdaptiveStyleBlock={false}
            buttonOverlayElements={buttonOverlay}
            improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
            heroContent={heroContent}
          />
        </div>
        {textBlocks.textAfter}
        {textBlocks.mobileTextAfter}
        {textBlocks.tabletTextAfter}
        {textBlocks.desktopTextAfter}
        {ecmCallToActionButtonWithTextToggle &&
          callToActionCustomStylesDesktop &&
          getCustomStylesForCTADesktop(callToActionCustomStylesDesktop)}
        {ecmCallToActionButtonWithTextToggle &&
          callToActionCustomStylesTablet &&
          getCustomStylesForCTATablet(callToActionCustomStylesTablet)}
        {ecmCallToActionButtonWithTextToggle &&
          callToActionCustomStylesMobile &&
          getCustomStylesForCTAMobile(callToActionCustomStylesMobile)}
      </div>
    );
  }
  render() {
    const {
      dataObj,
      ecmSlot,
      ecmCallToActionButtonWithTextToggle,
      improvePerformanceOfStyleToggle,
    } = this.props;

    const textHasAnchor = ecmTextHasAnchor(get(dataObj, 'text', {}));
    const imageHasImageMap = ecmImageHasImageMap(get(dataObj, 'image', {}));
    const ecmStyle = get(ecmSlot, 'className', '');
    const ecmComponentId = get(dataObj, 'componentId', '');
    const ecmProperties = get(dataObj, 'properties', {});
    const ecmObjectStyle = `ecm-container__textwithimage__${ecmComponentId}`;
    const normalizedLinkurl =
      get(dataObj, 'properties.linkurl') === null ||
      isEmpty(get(dataObj, 'properties.linkurl', ''))
        ? ''
        : get(dataObj, 'properties.linkurl');
    const linkurl = textHasAnchor || imageHasImageMap ? '' : normalizedLinkurl;
    const classes = classNames(
      'ecm-container__textwithimage',
      ecmStyle,
      ecmObjectStyle
    );
    const adaptiveStyleBlock = ecmGetAdaptiveStyleBlock(
      ecmProperties,
      ecmObjectStyle
    );
    const dektopImageWidthValue =
      get(ecmProperties, 'desktop.imageWidth') === null ||
      isEmpty(get(ecmProperties, 'desktop.imageWidth'))
        ? 50
        : get(ecmProperties, 'desktop.imageWidth');
    const tabletImageWidthValue =
      get(ecmProperties, 'tablet.imageWidth') === null ||
      isEmpty(get(ecmProperties, 'tablet.imageWidth'))
        ? dektopImageWidthValue
        : get(ecmProperties, 'tablet.imageWidth');
    const mobileImageWidthValue =
      get(ecmProperties, 'mobile.imageWidth') === null ||
      isEmpty(get(ecmProperties, 'mobile.imageWidth'))
        ? tabletImageWidthValue
        : get(ecmProperties, 'mobile.imageWidth');
    const imageWidthStyleBlock = ecmGetImageWidthStyleBlock(
      {
        desktop: { imageWidth: Number(dektopImageWidthValue) },
        tablet: { imageWidth: Number(tabletImageWidthValue) },
        mobile: { imageWidth: Number(mobileImageWidthValue) },
      },
      ecmObjectStyle,
      dataObj
    );
    if (!isEmpty(linkurl)) {
      return (
        <div className={classes}>
          {adaptiveStyleBlock}
          {imageWidthStyleBlock}
          <a href={linkurl}>
            {this.renderComponent(
              ecmCallToActionButtonWithTextToggle,
              dataObj,
              ecmSlot,
              textHasAnchor,
              imageHasImageMap,
              improvePerformanceOfStyleToggle
            )}
          </a>
        </div>
      );
    } else {
      return (
        <div className={classes}>
          {adaptiveStyleBlock}
          {imageWidthStyleBlock}
          {this.renderComponent(
            ecmCallToActionButtonWithTextToggle,
            dataObj,
            ecmSlot,
            textHasAnchor,
            imageHasImageMap,
            improvePerformanceOfStyleToggle
          )}
        </div>
      );
    }
  }
}
