export const verifyPromoSilo = (
  navigationSilos,
  isUserEligibleForPromo,
  segmentSiloAppConfig = {},
) => {
  const silos = [...navigationSilos];
  const { categoryId, startDate, endDate, timeZone } = segmentSiloAppConfig;

  if (categoryId) {
    const promoSiloIndex = silos.findIndex(
      silo => silo.id === categoryId || silo.catmanId === categoryId,
    );

    if (promoSiloIndex !== -1) {
      const startTime = new Date(`${startDate} ${timeZone}`).getTime();
      const endTime = new Date(`${endDate} ${timeZone}`).getTime();
      const currentTime = new Date().getTime();
      const isPromoExpired = !(
        currentTime > startTime && currentTime < endTime
      );

      if (!isUserEligibleForPromo || isPromoExpired) {
        silos.splice(promoSiloIndex, 1);
      } else {
        silos[promoSiloIndex] = {
          ...silos[promoSiloIndex],
          isPromoSilo: true,
        };
      }
    }
  }

  return silos;
};
