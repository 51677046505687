import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import RenderContentItem from '../contentItem/contentItem';

export function ConnectedInfoModal({ modals, activeEntryId }) {
  let modalContent;

  if (modals) {
    [modalContent] = modals.filter(modal => modal.sys.id === activeEntryId);
  }

  return (
    <React.Fragment>
      {modals.length ? (
        <RenderContentItem cmsContentItem={modalContent} />
      ) : null}
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  modals: get(state, 'cms.modal', []),
  activeEntryId: get(state, 'cms.activeEntryId', ''),
});

export default connect(mapStateToProps)(ConnectedInfoModal);
