import { openModal, closeModal } from 'shared/components/Modal/actions';
import get from 'lodash/get';
import httpWithLogging from 'universal/http-client';
import {
  hideSpinner,
  showSpinner,
} from 'shared/components/Spinner/spinner-actions';
import { getIdToken } from 'client-utils/utilities-amplify';
import { isNewCartCheckoutEnabled } from 'client-utils/is-new-cart-checkout';
import {
  LOADING_PAYMENT,
  RESOLVED_PAYMENT,
  REJECTED_PAYMENT,
} from '../../constants';
import {
  deleteMyAccountAddress,
  editMyAccountAddress,
} from '../AddressBookPage/actions';

export const openPaymentCTAmodal = (
  paymentId,
  billingAddressId,
  editDetails,
) => {
  return dispatch =>
    dispatch(
      openModal({
        type: 'PaymentActionModal',
        props: { paymentId, billingAddressId, editDetails },
        className: 'payment-cta-modal',
      }),
    );
};

export const closePaymentCTAmodal = () => {
  return dispatch =>
    dispatch(
      closeModal({
        type: 'PaymentActionModal',
        className: 'payment-cta-modal',
      }),
    );
};

export const openPaymentDeletemodal = (paymentId, billingAddressId) => {
  return dispatch =>
    dispatch(
      openModal({
        type: 'PaymentDeleteModal',
        props: { paymentId, billingAddressId },
        className: 'payment-del-modal',
      }),
    );
};

export const closePaymentDeletemodal = () => {
  return dispatch =>
    dispatch(
      closeModal({
        type: 'PaymentDeleteModal',
        className: 'payment-del-modal',
      }),
    );
};

export function getPaymentForMyAccount() {
  return (dispatch, getState) => {
    const state = getState();
    const disableATGToggle = state.toggles.DISABLE_ATG_CALLS;
    const { session, amplifyConfig } = state;
    const requestApi = httpWithLogging(state, 8000);
    dispatch({ type: LOADING_PAYMENT });
    getIdToken(amplifyConfig, isNewCartCheckoutEnabled(state), disableATGToggle)
      .then(tokenInfo => {
        const ucaProfileId = tokenInfo?.user?.sub;
        const headers = {
          accountId: disableATGToggle
            ? ucaProfileId
            : get(session, 'DYN_USER_ID', ''),
          authorization: tokenInfo.idToken,
        };
        const callString = disableATGToggle
          ? `${NMConfig.API_MY_PROFILE_PAYMENT}`
          : `${NMConfig.API_MY_ACCOUNT_PAYMENT}`;
        return requestApi.get(callString, { headers });
      })
      .then(res => {
        if (res && res.data) {
          dispatch({
            type: RESOLVED_PAYMENT,
            payload: res.data,
          });
        } else {
          dispatch({ type: REJECTED_PAYMENT });
        }
      })
      .catch(() => dispatch({ type: REJECTED_PAYMENT }));
  };
}

export function deleteMyAccountPayment(
  paymentId,
  billingAddressId,
  defaultAddress = false,
) {
  return (dispatch, getState) => {
    const state = getState();
    const disableATGToggle = state.toggles.DISABLE_ATG_CALLS;
    const { session, amplifyConfig } = state;
    const requestApi = httpWithLogging(state, 8000);
    dispatch(closePaymentDeletemodal());
    dispatch(showSpinner());
    dispatch({ type: LOADING_PAYMENT });
    getIdToken(amplifyConfig, isNewCartCheckoutEnabled(state), disableATGToggle)
      .then(tokenInfo => {
        const ucaProfileId = tokenInfo?.user?.sub;
        const headers = {
          paymentId,
          accountId: disableATGToggle
            ? ucaProfileId
            : get(session, 'DYN_USER_ID', ''),
          authorization: tokenInfo.idToken,
        };
        const callString = disableATGToggle
          ? `${NMConfig.API_MY_PROFILE_PAYMENT}`
          : `${NMConfig.API_MY_ACCOUNT_PAYMENT}`;
        return requestApi.delete(callString, { headers });
      })
      .then(res => {
        if (res) {
          if (res.status === 200) {
            defaultAddress
              ? dispatch(
                  editMyAccountAddress(
                    { defaultAddress: false },
                    billingAddressId,
                    true,
                  ),
                )
              : dispatch(deleteMyAccountAddress());
            dispatch(getPaymentForMyAccount());
          }
        } else {
          dispatch({ type: REJECTED_PAYMENT });
        }
      })
      .catch(err => {
        dispatch(
          openModal({
            type: 'ModalWithoutHeader',
            message: err.response.statusText,
            className: 'modal-without-header',
          }),
        );
      })
      .finally(() => dispatch(hideSpinner()));
  };
}
