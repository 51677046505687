import window from 'window-or-global';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';

import { types as MiniHubTypes } from '@bgo-ui/common/components/Header/MiniHub/miniHub-actions';
import { CallToActionKeys as MiniHubCallToActionKeys } from '@bgo-ui/common/components/Header/MiniHub/constants';

import {
  ADD_PRODUCT_TO_BAG,
  SET_PRODUCT_DETAIL_FAVORITE_UTAG_DATA,
  PLA_ADD_PRODUCT_TO_BAG,
  QUICK_LOOK_ADD_PRODUCT_TO_BAG,
  RESOLVED_QUICK_LOOK_PRODUCT_PDP,
  RESET_QUICK_LOOK_PRODUCT_PDP,
  OUTFITTING_QUICK_LOOK_ADD_PRODUCT_TO_BAG,
  PDP_LOAD_ANALYTICS,
} from 'pdp/components/ProductPage/actions';
import {
  SET_FAVORITE_UTAG_DATA,
  SET_PRODUCT_LIST_DATA_TO_UTAG,
  ADD_RECENT_SIZES_TO_UTAG_DATA,
  RESOLVED_QUICK_LOOK_PRODUCT,
  RESET_QUICK_LOOK_PRODUCT,
  GET_VIEW_BY,
} from 'plp/ProductListPage/actions';
import { SET_SEARCH_LIST_DATA_TO_UTAG } from 'srp/actions/actions';
// Commenting all TomFord related analytics implementation for BG Phase-II
// import {
//   SET_SELECTED_WATCH_SIZE,
//   SET_SELECTED_WATCH_CASE_UTAG,
//   SET_ACTIVE_WATCH_TAB_UTAG,
//   SET_SELECTED_STRAP_COLOR_UTAG,
//   SET_SELECTED_BUCKLE_UTAG,
//   CONF_ADD_TO_BAG_UTAG,
//   START_OVER_SELECTED,
// } from 'tomFord/actions';
import { types as categoryTemplateTypes } from 'templates/dux/templateActions';
import {
  OPEN_MODAL,
  CLOSE_MODAL,
} from 'client/common/components/Modal/actions';
import { getQueryParam } from 'client-utils/utilities-url';
import groupUtagData from 'server/components/Utag/reducers/selector/getGroupUtagData';
import {
  WKY_CARD_DISPLAY,
  WKY_BELL_CLICK,
  WKY_COPY_PROMO_CLICK,
} from 'client/common/components/WkyWidgetFallback/actions';
import { BEST_MATCH, ProductDetail, ShopTheLook } from 'plp/constants';
import { updateFilterTypeWithDisplayName } from 'client-utils/utilities-page';
import {
  DT_LOGIN_FAILURE,
  GO_TO_FORGOT_PASSWORD_STEP_2,
  LOADING_FORGOT_PASSWORD,
  RESOLVED_RESET_PASSWORD,
  RESOLVED_LOGIN_PAGE,
  RESOLVED_REGISTRATION_PAGE,
  RESOLVED_FORGOT_PASSWORD_PAGE,
  RESOLVED_CHECK_YOUR_EMAIL_PAGE,
  RESOLVED_PASSWORD_RESET_NEW_PAGE,
} from 'homeRoot/client/components/login/actions';
import {
  RESOLVED_BG_CREDIT_CARD_PAGE,
  RESOLVED_PAYMENT_DETAILS_PAGE,
  RESOLVED_ACCOUNT_DETAILS_PAGE,
  RESOLVED_MY_ORDERS_PAGE,
  RESOLVED_ORDER_DETAILS_PAGE,
  RESOLVED_REWARDS_PAGE,
  RESOLVED_GIFTCARDS_PAGE,
  RESOLVED_ADDRESS_BOOK_PAGE,
  RESOLVED_NEW_WISHLIST_PAGE,
  RESOLVED_INCIRCLE_CONCIERGE_PAGE,
  RESOLVED_INCIRCLE_LOYALTY_PAGE,
} from 'homeRoot/client/components/myaccount/constants';
import { RESOLVED_BG360_PAGE } from 'homeRoot/client/components/bg360/actions';
import {
  productUtag,
  favoriteUtagData,
  productListUtag,
  facetFlagUtag,
  searchListUtag,
  selectedFilterUtag,
  selectedFacetUtag,
  recentSizesUtag,
  quickLookProductUtagDataKeys,
  quickLookProductUtagData,
  categoryTemplatesUtag,
  ctpFavoriteDesignerUtag,
  // tomFordSizeUtag,
  // tomFordCaseUtag,
  // tomFordTabUtag,
  // tomFordStrapColorUtag,
  // tomFordBuckleUtag,
  addToBagUtag,
  // confAddToBagUtag,
  addToBagDataKeys,
  addToBagDataKeysWishlist,
  // tomFordTransientKeys,
  // tomFordStartOverResetKeys,
  shopTheLookAltProductClickUtag,
  getSTSUtagonModalLoad,
  getSTSUtagOnSeeMore,
  loginPageTemplateData,
  loginReferrerTemplateData,
  registrationReferrerTemplateData,
  registrationPageTemplateData,
  resetPasswordPageTemplateData,
  miniHubCtaReferrerData,
  miniHubOpenProfilePanelData,
  loginReferrerLoyaltyTemplateData,
} from './utagData';
import {
  STS_MODAL_OPEN,
  STS_SEE_MORE,
  ALTERNATIVE_PRODUCT,
  SUPER_GRID_DEFAULT_ANALYTICS,
  SUPER_GRID_3,
  SUPER_GRID_5,
  SET_LSS_ANALYTICS,
} from '../../common/actions/actions-common';
import { SKU_STL_PRODUCT_LIST } from '../../common/actions/actions-shopthelook';

const sendUtagViewData = data => {
  if (window.utag && window.utag.view) {
    window.utag.view(data);
  }
};
// window.utag && window.utag.view && window.utag.view(data);
const sendUtagLinkData = data =>
  window.utag && window.utag.link && window.utag.link(data);

let pageName,
  productCmosCatalogId,
  productCmosItem,
  productPimStyle,
  promoTileFlag;

export const updatePlpTypeForQuicklookAndFavoritesClick = () => {
  window.utag_data_dt = {
    ...window.utag_data_dt,
    plp_type: '',
  };
};

export default store => next => action => {
  const { loyaltyUser } = store.getState().user;
  const disableTealium = getQueryParam('limitTMSOnPage');
  if (disableTealium === 'ALL') {
    return next(action);
  }
  if (action.type === OPEN_MODAL && action.payload.type === 'BopsModal') {
    sendUtagLinkData({
      ...window.utag_data_dt,
      ...productUtag(action.payload.props.product),
      event_name: 'find_in_store',
    });
  } else if (
    action.type === RESOLVED_QUICK_LOOK_PRODUCT ||
    action.type === RESOLVED_QUICK_LOOK_PRODUCT_PDP
  ) {
    pageName = window.utag_data_dt.page_name;
    productCmosCatalogId = window.utag_data_dt.product_cmos_catalog_id;
    productCmosItem = window.utag_data_dt.product_cmos_item;
    productPimStyle = window.utag_data_dt.product_pim_style;

    if (action.payload.displayAsGroups) {
      updatePlpTypeForQuicklookAndFavoritesClick();
    }

    if (action.payload && action.payload.isGroup) {
      window.utag_data_dt = {
        ...window.utag_data_dt,
        ...groupUtagData(action.payload),
        page_name: 'product detail',
        event_name: 'Quickview',
      };
    } else {
      window.utag_data_dt = {
        ...window.utag_data_dt,
        ...quickLookProductUtagData(action.payload),
      };
    }
    if (action.type === RESOLVED_QUICK_LOOK_PRODUCT_PDP) {
      window.utag_data_dt = {
        ...window.utag_data_dt,
        outfitting_product: 'click',
      };
    }
    sendUtagViewData({ ...window.utag_data_dt });
  } else if (action.type === QUICK_LOOK_ADD_PRODUCT_TO_BAG) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      ...addToBagUtag(action.payload),
    };
    sendUtagLinkData({ ...window.utag_data_dt });
  } else if (
    action.type === CLOSE_MODAL &&
    action.payload === 'AddToBagQLModal'
  ) {
    window.utag_data_dt = {
      ...omit(window.utag_data_dt, addToBagDataKeys),
      event_name: 'MiniCartResp',
    };
  } else if (action.type === RESET_QUICK_LOOK_PRODUCT) {
    window.utag_data_dt = {
      ...omit(window.utag_data_dt, quickLookProductUtagDataKeys),
      page_name: pageName,
      event_name: 'pageLoad',
      product_cmos_catalog_id: productCmosCatalogId,
      product_cmos_item: productCmosItem,
      product_pim_style: productPimStyle,
    };
  } else if (action.type === RESET_QUICK_LOOK_PRODUCT_PDP) {
    window.utag_data_dt = {
      ...omit(window.utag_data_dt, quickLookProductUtagDataKeys),
      ...productUtag(action.payload),
      page_name: pageName,
      event_name: 'pageLoad',
      product_cmos_catalog_id: productCmosCatalogId,
      product_cmos_item: productCmosItem,
      outfitting_product: '',
    };
  } else if (
    action.type === ADD_PRODUCT_TO_BAG ||
    action.type === PLA_ADD_PRODUCT_TO_BAG
  ) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      ...addToBagUtag(action.payload),
    };
    if (window && window.NMConfig && window.NMConfig.LOG_DIR === 'bgo-ui-plp') {
      if (window.utag_data_dt['stl_alt_prod']) {
        delete window.utag_data_dt['stl_alt_prod'];
      }
      if (window.utag_data_dt['product_inventory_status']) {
        delete window.utag_data_dt['product_inventory_status'];
      }
      if (window.utag_data_dt['product_showable']) {
        delete window.utag_data_dt['product_showable'];
      }
    }
    sendUtagLinkData({ ...window.utag_data_dt });
  } /* else if (action.type === CONF_ADD_TO_BAG_UTAG) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      ...confAddToBagUtag(action.payload),
    };
    sendUtagLinkData({ ...window.utag_data_dt });
  } */ else if (
    action.type === SET_FAVORITE_UTAG_DATA
  ) {
    if (action.payload.displayAsGroups) {
      updatePlpTypeForQuicklookAndFavoritesClick();
    }
    window.utag_data_dt = {
      ...window.utag_data_dt,
      ...favoriteUtagData(action.payload),
    };
    sendUtagLinkData({ ...window.utag_data_dt });
  } else if (action.type === SET_PRODUCT_DETAIL_FAVORITE_UTAG_DATA) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      ...favoriteUtagData(action.payload),
    };
    sendUtagLinkData({ ...window.utag_data_dt });
  } else if (action.type === SET_PRODUCT_LIST_DATA_TO_UTAG) {
    let utagData = {
      ...window.utag_data_dt,
      ...productListUtag(action.payload),
      ...facetFlagUtag(action.payload),
    };
    if (parseInt(action.payload.page, 10) !== 1) {
      utagData = {
        ...omit(utagData, 'promo_tile_flag'),
      };
    } else if (!isNil(promoTileFlag)) {
      utagData.promo_tile_flag = promoTileFlag;
    }
    if (!isEmpty(action.payload.filterOptions)) {
      utagData = {
        ...utagData,
        ...selectedFilterUtag(action.payload),
      };
    }
    if (!isEmpty(action.payload.facetOptions)) {
      utagData = {
        ...utagData,
        ...selectedFacetUtag(action.payload),
      };
    }
    if (
      !isEmpty(action.payload.sortBy) &&
      action.payload.sortBy === BEST_MATCH
    ) {
      utagData = {
        ...utagData,
      };
    }
    if (action.payload.applicableFilterType) {
      utagData = updateFilterTypeWithDisplayName(
        utagData,
        action.payload.applicableFilterType,
      );
    }
    window.utag_data_dt = utagData;
    sendUtagViewData({ ...window.utag_data_dt });
  } else if (action.type === SET_SEARCH_LIST_DATA_TO_UTAG) {
    let utagData = {
      ...window.utag_data_dt,
      ...searchListUtag(action.payload),
    };
    if (!isEmpty(action.payload.filterOptions)) {
      utagData = {
        ...utagData,
        ...selectedFilterUtag(action.payload),
      };
    }
    window.utag_data_dt = utagData;
    sendUtagViewData({ ...window.utag_data_dt });
  } else if (action.type === ADD_RECENT_SIZES_TO_UTAG_DATA) {
    const utagData = {
      ...window.utag_data_dt,
      ...recentSizesUtag(action.payload),
    };
    window.utag_data_dt = utagData;
  } else if (
    action.type === categoryTemplateTypes.SET_CATEGORY_TEMPLATES_DATA_TO_UTAG
  ) {
    const utagData = {
      ...window.utag_data_dt,
      ...categoryTemplatesUtag(action.payload),
    };
    window.utag_data_dt = utagData;
  } else if (
    action.type === categoryTemplateTypes.SET_FAVORITE_DESIGNER_INDEX_TO_UTAG
  ) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      ...ctpFavoriteDesignerUtag(action.payload),
    };
    sendUtagLinkData({ ...window.utag_data_dt });
  }
  // Commenting all TomFord related analytics implementation for BG Phase-II
  /* else if (action.type === SET_SELECTED_WATCH_SIZE) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      ...tomFordSizeUtag(action.payload),
    };
    sendUtagLinkData({ ...window.utag_data_dt });
  } else if (action.type === SET_SELECTED_WATCH_CASE_UTAG) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      ...tomFordCaseUtag(action.payload),
    };
    sendUtagLinkData({ ...window.utag_data_dt });
  } else if (action.type === SET_ACTIVE_WATCH_TAB_UTAG) {
    window.utag_data_dt = {
      ...omit(window.utag_data_dt, tomFordTransientKeys),
      ...tomFordTabUtag(action.payload),
    };
    sendUtagLinkData({ ...window.utag_data_dt });
  } else if (action.type === SET_SELECTED_STRAP_COLOR_UTAG) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      ...tomFordStrapColorUtag(action.payload),
    };
    sendUtagLinkData({ ...window.utag_data_dt });
  } else if (action.type === SET_SELECTED_BUCKLE_UTAG) {
    window.utag_data_dt = {
      ...omit(window.utag_data_dt, tomFordTransientKeys),
      ...tomFordBuckleUtag(action.payload),
    };
    sendUtagViewData({ ...window.utag_data_dt });
  } else if (action.type === START_OVER_SELECTED) {
    window.utag_data_dt = {
      ...omit(window.utag_data_dt, tomFordStartOverResetKeys),
      ...tomFordTabUtag(action.payload),
    };
    sendUtagViewData({ ...window.utag_data_dt });
  } */
  else if (action.type === WKY_CARD_DISPLAY) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      event_name: 'WKYCard',
      wky_id: action.payload,
    };
    sendUtagLinkData({ ...window.utag_data_dt });
  } else if (action.type === WKY_BELL_CLICK) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      event_name: 'WKYBell',
    };
    sendUtagLinkData({ ...window.utag_data_dt });
  } else if (action.type === WKY_COPY_PROMO_CLICK) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      event_name: 'WKYCopyPromo',
    };
    sendUtagLinkData({ ...window.utag_data_dt });
  } else if (action.type === OUTFITTING_QUICK_LOOK_ADD_PRODUCT_TO_BAG) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      ...addToBagUtag(action.payload),
      outfitting_product: '',
    };
    sendUtagLinkData({ ...window.utag_data_dt });
  } else if (action.type === ALTERNATIVE_PRODUCT) {
    const utagData = {
      ...window.utag_data_dt,
      ...shopTheLookAltProductClickUtag(action.payload),
      ...productUtag(action.payload),
    };
    window.utag_data_dt = utagData;
  } else if (action.type === SUPER_GRID_3) {
    const utagData = {
      ...window.utag_data_dt,
      grid_view: 'default',
    };
    window.utag_data_dt = utagData;
  } else if (action.type === SUPER_GRID_5) {
    const utagData = {
      ...window.utag_data_dt,
      grid_view: 'super grid',
    };
    window.utag_data_dt = utagData;
  } else if (action.type === STS_MODAL_OPEN) {
    const utagData = {
      ...window.utag_data_dt,
      ...getSTSUtagonModalLoad(),
    };
    window.utag_data_dt = utagData;
  } else if (action.type === STS_SEE_MORE) {
    const utagData = {
      ...window.utag_data_dt,
      ...getSTSUtagOnSeeMore(),
    };
    window.utag_data_dt = utagData;
  } else if (action.type === SUPER_GRID_DEFAULT_ANALYTICS) {
    const utagData = {
      ...window.utag_data_dt,
      grid_view: 'default',
    };
    window.utag_data_dt = utagData;
  } else if (
    action.type === SKU_STL_PRODUCT_LIST &&
    !isEmpty(action.skuStlProductList)
  ) {
    const firstKey = Object.keys(action.skuStlProductList)[0];
    const utagData = {
      ...window.utag_data_dt,
      ...quickLookProductUtagData(action.skuStlProductList[firstKey]),
      event_name: ShopTheLook,
      page_name: ProductDetail,
    };
    window.utag_data_dt = utagData;
  } else if (action.type === GET_VIEW_BY) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      product_view: action.viewType.toLowerCase(),
    };
    sendUtagLinkData({ ...window.utag_data_dt });
  } else if (action.type === PDP_LOAD_ANALYTICS) {
    const utagData = {
      ...window.utag_data_dt,
      ...{ product_finding_method: ['Shop the Style'] },
    };
    window.utag_data_dt = utagData;
  } else if (action.type === SET_LSS_ANALYTICS) {
    const tagData = window.utag_data_dt;
    if (action.payload.grid_view && action.payload.product_view) {
      tagData['grid_view'] = action.payload.grid_view;
      tagData['product_view'] = action.payload.product_view;
    }
    window.utag_data_dt = tagData;
  } else if (
    action.type === OPEN_MODAL &&
    action.payload.type === 'ForgotPasswordModal'
  ) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      event_name: 'forgot_pw_step1',
      login_bt_click: 'false',
      page_definition_id: 'account',
      page_name: 'forgot password',
      page_template: 'account',
      page_type: 'account',
    };
    delete window.utag_data_dt.logged_in_status;
    delete window.utag_data_dt.logged_in_previous_page_flag;
    sendUtagLinkData({ ...window.utag_data_dt });
  } else if (action.type === GO_TO_FORGOT_PASSWORD_STEP_2) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      event_name: 'forgot_pw_step2',
      login_bt_click: 'false',
      page_definition_id: 'account',
      page_name: 'forgot password',
      page_template: 'account',
      page_type: 'account',
    };
    sendUtagLinkData({ ...window.utag_data_dt });
  } else if (
    action.type === LOADING_FORGOT_PASSWORD &&
    !action.payload.isNewLayout
  ) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      event_name: 'forgot_pw_step3',
      page_definition_id: 'account',
      page_name: 'forgot password',
      page_template: 'account',
      page_type: 'account',
    };
    sendUtagLinkData({ ...window.utag_data_dt });
  } else if (
    action.type === CLOSE_MODAL &&
    window.utag_data_dt &&
    window.utag_data_dt.page_name === 'forgot password'
  ) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      login_bt_click: 'false',
      page_definition_id: 'account',
      event_name: 'pageLoad',
      page_name: 'login',
      page_template: 'account',
      page_type: 'account',
    };
    // don't send anything, but reset values
  } else if (action.type === RESOLVED_RESET_PASSWORD) {
    const pageData = resetPasswordPageTemplateData(action.payload);
    window.utag_data_dt = {
      ...window.utag_data_dt,
      event_name: 'pageLoad',
      ...pageData,
    };
    sendUtagViewData({ ...window.utag_data_dt });
  } else if (action.type === DT_LOGIN_FAILURE) {
    const pageData = loginPageTemplateData(action.payload);
    window.utag_data_dt = {
      ...window.utag_data_dt,
      event_name: 'pageLoad',
      login_bt_click: 'true',
      logged_in_previous_page_flag: 'false',
      logged_in_status: 'false',
      ...pageData,
    };
    sendUtagLinkData({ ...window.utag_data_dt });
  } else if (action.type === RESOLVED_LOGIN_PAGE) {
    const pageData = loginPageTemplateData(action.payload);
    window.utag_data_dt = {
      ...window.utag_data_dt,
      event_name: 'pageLoad',
      login_bt_click: 'false',
      ...pageData,
    };
    sendUtagViewData({ ...window.utag_data_dt });
  } else if (action.type === RESOLVED_REGISTRATION_PAGE) {
    const pageData = registrationPageTemplateData(action.payload);
    window.utag_data_dt = {
      ...window.utag_data_dt,
      event_name: 'pageLoad',
      ...pageData,
    };
    sendUtagViewData({ ...window.utag_data_dt });
  } else if (action.type === RESOLVED_BG_CREDIT_CARD_PAGE) {
    window.utag_data_dt = {
      ...omit(window.utag_data_dt, addToBagDataKeysWishlist),
      event_name: 'pageLoad',
      page_definition_id: 'account',
      page_name: 'My Account:BG Credit Card',
      page_template: 'Profile',
      page_type: 'Account',
      ...loginReferrerTemplateData(),
      ...registrationReferrerTemplateData(),
      ...miniHubCtaReferrerData(MiniHubCallToActionKeys.INCIRCLE_DETAILS),
      ...loginReferrerLoyaltyTemplateData(loyaltyUser),
    };
    sendUtagViewData({ ...window.utag_data_dt });
  } else if (action.type === RESOLVED_PAYMENT_DETAILS_PAGE) {
    window.utag_data_dt = {
      ...omit(window.utag_data_dt, addToBagDataKeysWishlist),
      event_name: 'pageLoad',
      page_definition_id: 'account',
      page_name: 'My Account:Payment Information',
      page_template: 'Profile',
      page_type: 'Account',
      ...loginReferrerTemplateData(),
      ...registrationReferrerTemplateData(),
      ...loginReferrerLoyaltyTemplateData(loyaltyUser),
    };
    sendUtagViewData({ ...window.utag_data_dt });
  } else if (action.type === RESOLVED_ACCOUNT_DETAILS_PAGE) {
    window.utag_data_dt = {
      ...omit(window.utag_data_dt, addToBagDataKeysWishlist),
      event_name: 'pageLoad',
      page_definition_id: 'account',
      page_name: 'My Account:Account Overview',
      page_template: 'Profile',
      page_type: 'Account',
      ...loginReferrerTemplateData(),
      ...registrationReferrerTemplateData(),
      ...miniHubCtaReferrerData(MiniHubCallToActionKeys.ACCOUNT_DETAILS),
      ...loginReferrerLoyaltyTemplateData(loyaltyUser),
    };
    sendUtagViewData({ ...window.utag_data_dt });
  } else if (action.type === RESOLVED_MY_ORDERS_PAGE) {
    const login_btn_clicked = window.sessionStorage.getItem(
      'login_btn_clicked',
    );
    window.utag_data_dt = {
      ...omit(window.utag_data_dt, addToBagDataKeysWishlist),
      event_name: 'pageLoad',
      page_definition_id: 'account',
      page_name: 'My Account:Order History',
      page_template: 'Profile',
      page_type: 'Account',
      logged_in_previous_page_flag: login_btn_clicked,
      login_bt_click: login_btn_clicked,
      ...loginReferrerTemplateData(),
      ...registrationReferrerTemplateData(),
      ...miniHubCtaReferrerData(MiniHubCallToActionKeys.MY_ORDERS),
      ...loginReferrerLoyaltyTemplateData(loyaltyUser),
    };
    sendUtagViewData({ ...window.utag_data_dt });
  } else if (action.type === RESOLVED_ORDER_DETAILS_PAGE) {
    window.utag_data_dt = {
      ...omit(window.utag_data_dt, addToBagDataKeysWishlist),
      event_name: 'pageLoad',
      page_definition_id: 'account',
      page_name: 'My Account:Order Details',
      page_template: 'Profile',
      page_type: 'Account',
      ...loginReferrerTemplateData(),
      ...registrationReferrerTemplateData(),
    };
    sendUtagViewData({ ...window.utag_data_dt });
  } else if (action.type === RESOLVED_REWARDS_PAGE) {
    window.utag_data_dt = {
      ...omit(window.utag_data_dt, addToBagDataKeysWishlist),
      event_name: 'pageLoad',
      page_definition_id: 'account',
      page_name: 'My Account:My Rewards',
      page_template: 'Profile',
      page_type: 'Account',
      ...loginReferrerTemplateData(),
      ...registrationReferrerTemplateData(),
      ...loginReferrerLoyaltyTemplateData(loyaltyUser),
    };
    sendUtagViewData({ ...window.utag_data_dt });
  } else if (action.type === RESOLVED_GIFTCARDS_PAGE) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      event_name: 'pageLoad',
      page_definition_id: 'account',
      page_name: 'My Account:My Giftcards',
      page_template: 'Profile',
      page_type: 'Account',
      ...loginReferrerTemplateData(),
      ...registrationReferrerTemplateData(),
      ...loginReferrerLoyaltyTemplateData(loyaltyUser),
    };
    sendUtagViewData({ ...window.utag_data_dt });
  } else if (action.type === RESOLVED_ADDRESS_BOOK_PAGE) {
    window.utag_data_dt = {
      ...omit(window.utag_data_dt, addToBagDataKeysWishlist),
      event_name: 'pageLoad',
      page_definition_id: 'account',
      page_name: 'My Account:Address Book',
      page_template: 'Profile',
      page_type: 'Account',
      ...loginReferrerTemplateData(),
      ...registrationReferrerTemplateData(),
      ...loginReferrerLoyaltyTemplateData(loyaltyUser),
    };
    sendUtagViewData({ ...window.utag_data_dt });
  } else if (action.type === RESOLVED_FORGOT_PASSWORD_PAGE) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      event_name: 'pageLoad',
      page_definition_id: 'account',
      page_name: 'Forgot Password',
      page_template: 'Profile',
      page_type: 'Account',
      login_bt_click: 'false',
    };
    sendUtagViewData({ ...window.utag_data_dt });
  } else if (action.type === RESOLVED_CHECK_YOUR_EMAIL_PAGE) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      event_name: 'pageLoad',
      page_definition_id: 'account',
      page_name: 'Check Your Email',
      page_template: 'Profile',
      page_type: 'Account',
    };
    sendUtagViewData({ ...window.utag_data_dt });
  } else if (action.type === RESOLVED_PASSWORD_RESET_NEW_PAGE) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      event_name: 'pageLoad',
      page_definition_id: 'account',
      page_name: 'Password Reset',
      page_template: 'Profile',
      page_type: 'Account',
    };
    sendUtagViewData({ ...window.utag_data_dt });
  } else if (action.type === RESOLVED_NEW_WISHLIST_PAGE) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      event_name: 'pageLoad',
      page_definition_id: 'account',
      page_name: 'Wishlist',
      page_template: 'Profile',
      page_type: 'Account',
      ...loginReferrerTemplateData(),
      ...registrationReferrerTemplateData(),
      ...loginReferrerLoyaltyTemplateData(loyaltyUser),
    };
    sendUtagViewData({ ...window.utag_data_dt });
  } else if (action.type === RESOLVED_BG360_PAGE) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      event_name: 'pageLoad',
      page_definition_id: 'service page',
      page_name: 'BG 360 Services',
      page_template: 'service page',
      page_type: 'services',
    };
    sendUtagViewData({ ...window.utag_data_dt });
  } else if (action.type === MiniHubTypes.PROFILE_PANEL_OPEN) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      event_name: 'profilePanel',
      ...miniHubOpenProfilePanelData(),
    };
    sendUtagLinkData({ ...window.utag_data_dt });
  } else if (action.type === RESOLVED_INCIRCLE_CONCIERGE_PAGE) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      event_name: 'pageLoad',
      page_definition_id: 'incircle concierge',
      page_name: 'InCircle Concierge',
      page_template: 'landing page',
      page_type: 'incircle',
    };

    sendUtagViewData({ ...window.utag_data_dt });
  } else if (action.type === RESOLVED_INCIRCLE_LOYALTY_PAGE) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      event_name: 'pageLoad',
      page_definition_id: 'incircle landing',
      page_name: 'InCircle Loyalty',
      page_template: 'landing page',
      page_type: 'incircle',
    };

    sendUtagViewData({ ...window.utag_data_dt });
  }
  return next(action);
};
