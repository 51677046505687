export const loadingStatus = {
  loading: 'loading',
  resolved: 'resolved',
  rejected: 'rejected',
};

export const getRequiredFieldError = field =>
  `Please provide an entry for the ${field} Field.`;

export const somethingWentWrongError =
  'Something went wrong, Please try again.';

export const invalidEmailError = 'Please provide a valid email address.';

export const contentfulIdentifier = {
  details: 'bg-rsvp_details',
  summary: 'bg-rsvp_summary',
  thankYou: 'bg-rsvp_merci',
};
