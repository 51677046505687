import { TAB_KEYCODE } from 'client-utils/keyCodes';
import isElement from 'lodash/isElement';

export const handleTabEventWith = (firstElement, lastElement) => {
  if (isElement(firstElement) && isElement(lastElement)) {
    lastElement.addEventListener('keydown', event => {
      if (event.which === TAB_KEYCODE && !event.shiftKey) {
        event.preventDefault();
        firstElement.focus();
      }
    });

    firstElement.addEventListener('keydown', event => {
      if (event.which === TAB_KEYCODE && event.shiftKey) {
        event.preventDefault();
        lastElement.focus();
      }
    });
  }
};
