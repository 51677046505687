import get from 'lodash/get';
import React from 'react';
import classNames from 'classnames';
import ConnectedEcmAdaptiveImage from '../EcmAdaptiveImage';
import { ecmGetAdaptiveStyleBlock } from '../../utilities';
import {
  getCustomStylesForCTADesktop,
  getCustomStylesForCTATablet,
  getCustomStylesForCTAMobile,
} from '../EcmText/textComponentUtilities';

const EcmTextOverImage = ({
  ecmCallToActionButtonWithTextToggle,
  dataObj,
  ecmSlot,
  improvePerformanceOfStyleToggle,
}) => {
  const ecmStyle = get(ecmSlot, 'className', '');
  const ecmComponentId = get(dataObj, 'componentId', '');
  const ecmProperties = get(dataObj, 'properties', {});
  const ecmObjectStyle = `ecm-container__textoverimage__${ecmComponentId}`;
  const classes = classNames(
    'ecm-container__textoverimage',
    ecmStyle,
    ecmObjectStyle
  );
  const adaptiveStyleBlock = ecmGetAdaptiveStyleBlock(
    ecmProperties,
    ecmObjectStyle
  );
  const callToActionCustomStylesDesktop = get(
    dataObj,
    'callToActionCustomStyles.desktop',
    []
  );
  const callToActionCustomStylesTablet = get(
    dataObj,
    'callToActionCustomStyles.tablet',
    []
  );
  const callToActionCustomStylesMobile = get(
    dataObj,
    'callToActionCustomStyles.mobile',
    []
  );

  return (
    <div className={classes}>
      {adaptiveStyleBlock}
      <ConnectedEcmAdaptiveImage
        picture={dataObj.image}
        ecmSlot={ecmSlot}
        textOverlayElements={dataObj.textoverlayelements}
        buttonOverlayElements={dataObj.calltoactionbuttons}
        improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
      />
      {ecmCallToActionButtonWithTextToggle &&
        callToActionCustomStylesDesktop &&
        getCustomStylesForCTADesktop(callToActionCustomStylesDesktop)}
      {ecmCallToActionButtonWithTextToggle &&
        callToActionCustomStylesTablet &&
        getCustomStylesForCTATablet(callToActionCustomStylesTablet)}
      {ecmCallToActionButtonWithTextToggle &&
        callToActionCustomStylesMobile &&
        getCustomStylesForCTAMobile(callToActionCustomStylesMobile)}
    </div>
  );
};

export default EcmTextOverImage;
