import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import partition from 'lodash/partition';
import { InView } from 'react-intersection-observer';

import { get113TypePromotion } from 'client-utils/utilities-promotions';
import { renderFormattedPrice } from 'client-utils/utilities-price';

import { SOLD_OUT_TEXT } from 'plp/constants';
import { showQLModalWindow } from 'plp/ProductListPage/actions';
import Image from 'plp/ProductListPage/components/ProductList/components/Product/components/Image/image';
import Promotions from 'plp/ProductListPage/components/ProductList/components/Product/components/Promotions/promotions';
import SalePrice from 'plp/ProductListPage/components/ProductList/components/Product/components/SalePrice/salePrice';

import './productTile.scss';
import { referralSourceClicked } from '../../../common/actions/actions-page';
import {
  isValidCloudinaryUrl,
  transformCloudinaryUrl,
} from '../../../../components/Util/product-cloudinary';
import { CLOUDINARY_SIZE } from '../../../../components/constants';
import { addTrackingParam, getTrackingTagStr } from '../utils';

export class CarouselTile extends Component {
  handleClick(event, url) {
    const { fromNewHPCarousel } = this.props;
    event.stopPropagation();
    referralSourceClicked(
      fromNewHPCarousel ? 'Product_Rail' : 'NEW_ARRIVALS',
      'product',
    );
    window.location.href = url;
  }

  filterPromotions(promotions) {
    return promotions
      ? partition(promotions, { showPromoPrice: true })
      : [[], []];
  }

  render() {
    const {
      id = '',
      imageUrl = '',
      designer = '',
      name = '',
      url = '',
      price = {},
      promotions = [],
      displayable,
      displayOptions = {},
      lazyloadCmsContent,
      parentTrackingTags,
    } = this.props;
    const {
      displayDesigner,
      displayDescription,
      displayPrice,
      displayPromotions,
      carouselDirection,
    } = displayOptions;
    const [promotionWithPrice, promotionWithoutPrice] = this.filterPromotions(
      promotions,
    );
    const originalPrice = get(price, 'adornments[0].price', 0);
    let formattedOGPrice;
    if (originalPrice > 0) {
      formattedOGPrice = renderFormattedPrice(originalPrice);
    }
    originalPrice ? renderFormattedPrice(originalPrice) : null;
    const retailPrice = renderFormattedPrice(
      price.retailPrice,
      price.currencyCode,
    );
    const promotionalPrice = renderFormattedPrice(
      price.promotionalPrice,
      price.currencyCode,
    );
    const descriptionStyle =
      carouselDirection === 'Vertical'
        ? { paddingLeft: '8px', paddingRight: '8px' }
        : {};

    const validCloudinaryUrl = isValidCloudinaryUrl(imageUrl);
    const imageSource = validCloudinaryUrl
      ? transformCloudinaryUrl(
          imageUrl,
          CLOUDINARY_SIZE.NEWARRIVALS,
          'normal',
          'normal',
          '5:7',
        )
      : imageUrl;

    const renderSoldOut = () => {
      return (
        !displayable && (
          <div className="product-thumbnail-list__image__soldout">
            {SOLD_OUT_TEXT}
          </div>
        )
      );
    };

    const trackingTagStr = getTrackingTagStr(parentTrackingTags);
    const linkUrl = addTrackingParam(url, trackingTagStr);

    return (
      <a
        ref={el => {
          this.element = el;
        }}
        className="product-tile-link"
        id={id}
        onClick={e => this.handleClick(e, linkUrl)}
        href={linkUrl}
      >
        <div
          className={classnames('product-carousel-tile', {
            'product-carousel-tile__vertical': carouselDirection === 'Vertical',
          })}
        >
          {lazyloadCmsContent ? (
            <InView rootMargin="500px" triggerOnce>
              {({ inView, ref }) => (
                <div
                  ref={ref}
                  className="product-thumbnail-list__image product-thumbnail-list__image--lazyload"
                >
                  {inView && (
                    <Image
                      isCloudinary={validCloudinaryUrl}
                      src={imageSource}
                      alt={`${designer} ${name}`}
                    />
                  )}
                  <div
                    className={classnames(
                      'product-thumbnail-list__overlay',
                      inView && 'product-thumbnail-list__overlay--fade',
                    )}
                  />
                  {renderSoldOut()}
                </div>
              )}
            </InView>
          ) : (
            <Fragment>
              <Image
                isCloudinary={validCloudinaryUrl}
                src={imageSource}
                alt={`${designer} ${name}`}
              />
              {renderSoldOut()}
            </Fragment>
          )}

          <div className="product-thumbnail-list__description">
            {displayDesigner && (
              <div
                className="product-thumbnail-list__description__designer"
                style={descriptionStyle}
                aria-hidden="true"
              >
                {designer?.includes(' ') ? designer : <span> {designer} </span>}
              </div>
            )}
            {displayDescription && (
              <div
                className="product-thumbnail-list__description__name"
                style={descriptionStyle}
                aria-hidden="true"
              >
                {name}
              </div>
            )}
            <div
              className="product-thumbnail-list__description__price"
              style={descriptionStyle}
            >
              <SalePrice
                currencyCode={price.currencyCode}
                retailPrice={displayPrice && retailPrice}
                adornments={price.adornments}
                isPLP
                original={formattedOGPrice}
                isUIPRICTest
                priceAdornmentColor={`#${get113TypePromotion(promotions)
                  .templatePricingAdornColor || ''}`}
                promotionComponent={
                  displayPromotions && !isEmpty(promotionWithPrice)
                    ? () => (
                      <Promotions
                        promotions={promotions}
                        currencyCode={price.currencyCode}
                        promotionalPrice={promotionalPrice}
                        isPLP
                        promoColor={
                            get113TypePromotion(promotions).thumbnailPromoColor
                          }
                        promoText={
                            get113TypePromotion(promotions).templateHTML
                          }
                        isUIPRICTest
                      />
                      )
                    : null
                }
              />
              {displayPromotions && (
                <Promotions promotions={promotionWithoutPrice} />
              )}
            </div>
          </div>
        </div>
      </a>
    );
  }
}

const mapStateToProps = state => {
  return {
    lazyloadCmsContent: state.toggles.LAZYLOAD_CMS_CONTENT,
  };
};

export default connect(
  mapStateToProps,
  { showQLModalWindow },
)(CarouselTile);
