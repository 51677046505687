import React, { Fragment, useEffect } from 'react';
import { func, object, bool } from 'prop-types';
import { connect } from 'react-redux';
import {
  PAGE_ID_ADDRESS_BOOK,
  setPageId,
} from '@bgo-ui/common/client/common/actions/actions-page';
import Spinner from 'shared/components/Spinner/simpleSpinner';
import { useConstructor } from '../../../../utilities/hooks';
import { getContentForMyAccount, setResolvedPage } from '../../actions';
import { getAddressForMyAccount } from './actions';
import { RESOLVED_ADDRESS_BOOK_PAGE } from '../../constants';
import MyAccountMobileBackHeader from '../MyAccountPage/MyAccountBackHeader/MyAccountMobileBackHeader';
import AddressBookList from './AddressBookList/AddressBookList';

const PAGE_NAME = 'Address Book';

/**
 * @function AddressBookPage
 * @param {Function} props.getAddressForMyAccount
 * @param {Function} props.getContentForMyAccount
 * @param {Object} props.myAccountContent
 * @param {Function} props.setPageId
 * @param {Function} props.setResolvedPage
 * @returns {React.ReactElement}
 */
const AddressBookPage = ({
  getAddressForMyAccount,
  getContentForMyAccount,
  myAccountContent,
  setPageId,
  setResolvedPage,
  isLoading,
  bgoLoyaltyToggle,
  isCheckedLoyaltyUser,
}) => {
  useConstructor(() => {
    setPageId(PAGE_ID_ADDRESS_BOOK);
  });

  useEffect(() => {
    if (bgoLoyaltyToggle && isCheckedLoyaltyUser) {
      setResolvedPage(RESOLVED_ADDRESS_BOOK_PAGE);
    } else if (!bgoLoyaltyToggle) {
      setResolvedPage(RESOLVED_ADDRESS_BOOK_PAGE);
    }
  }, [bgoLoyaltyToggle, isCheckedLoyaltyUser]);

  useEffect(() => {
    getContentForMyAccount();
    getAddressForMyAccount();
  }, []);

  return isLoading ? (
    <Spinner />
  ) : (
    <Fragment>
      <MyAccountMobileBackHeader title={PAGE_NAME} />
      <AddressBookList content={myAccountContent || null} />
    </Fragment>
  );
};

AddressBookPage.propTypes = {
  getAddressForMyAccount: func.isRequired,
  getContentForMyAccount: func.isRequired,
  myAccountContent: object.isRequired,
  setPageId: func.isRequired,
  setResolvedPage: func.isRequired,
  isLoading: bool.isRequired,
};

const mapStateToProps = state => ({
  myAccountContent: state?.myaccount?.myAccountContent,
  isLoading:
    state?.api?.address?.loading || state?.api?.content?.loading || false,
  bgoLoyaltyToggle: state?.toggles?.BGO_LOYALTY_NEW || false,
  isCheckedLoyaltyUser: state.user.loyaltyUser.isChecked || false,
});

const mapDispatchToProps = {
  getContentForMyAccount,
  getAddressForMyAccount,
  setResolvedPage,
  setPageId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddressBookPage);
