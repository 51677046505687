export const FAVORITE_ITEMS_UTAG_VALUES = {
    PAGE_DEFINITION_ID: 'myfavoriteitems',
    PAGE_NAME: 'myfavoriteitems',
    PAGE_TYPE: 'Account'
};
export const DESIGNER_UTAG_VALUES = {
    PAGE_DEFINITION_ID: "RWD",
    PAGE_NAME: "RWD",
    PAGE_TYPE: "Account"
};
export const FAVORITE_UTAG_EVENT_NAME= 'FavoriteItem';
export const FAVORITE_UTAG_ITEM_TYPE= 'product';
export const FAVORITES_DESIGNERS_CATEGORY_PAGE= '/c/designers-a-z-cat000001';
export const IN_STORE = 'In Store';
export const SORT_BY_TEXT = 'SORT BY';
export const FAVORITES_LIMIT_EXCEEDED_MESSAGE = "Sorry, you can only have 600 favorite items.";
export const FAVORITE_MESSAGE = "LimitExceeded";