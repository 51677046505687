import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import find from 'lodash/find';
import intersection from 'lodash/intersection';
import classNames from 'classnames';
import { shouldLoad } from 'universal/http-client';
import { referralSourceClicked } from 'client/common/actions/actions-page';
import {
  getEcmRecommendations,
  clearEcmRecommendations,
  setSelectedIndex,
  resolveBVRecommendations,
  rejectBVRecommendations,
} from '../../actions/actions-ecmrecommendations';
import EcmRecommendedProducts from './ecmRecommendedProducts';

class EcmRecommendations extends Component {
  constructor(props) {
    super(props);
    this.loadEvent = this.loadEvent.bind(this);
    this.bvAPIReady = this.bvAPIReady.bind(this);
    this.recosError = this.recosError.bind(this);
    this.renderCategories = this.renderCategories.bind(this);
    this.validateProductClick = this.validateProductClick.bind(this);
    this.state = {
      isBVExperience: false,
    };
  }
  componentDidMount() {
    const BAZAAR_VOICE = 'BV';
    if (
      this.props.bazaarVoiceToggle &&
      this.props.bazaarVoiceABTest &&
      this.props.bazaarVoiceABTest.assignment.experienceId === BAZAAR_VOICE
    ) {
      this.setState({ isBVExperience: true });
    }
    document.addEventListener('readystatechange', this.loadEvent);
  }

  componentWillUnmount() {
    this.props.clearEcmRecommendations();
  }

  loadEvent() {
    if (document.readyState === 'complete' && !this.props.isSuppressRR) {
      if (this.state.isBVExperience) {
        window.bvCallback = BV => {
          BV.api
            .ready()
            .then(api => {
              this.bvAPIReady(api);
            })
            .catch(err => {
              this.props.rejectBVRecommendations(err);
            });
        };
      } else if (shouldLoad(this.props.api.ecmrecommendations)) {
        this.props.getEcmRecommendations();
      }
      document.removeEventListener('readystatechange', this.loadEvent);
    }
  }
  validateProductClick(e) {
    if (e && e.nativeEvent) {
      const jfyLink = e.currentTarget.querySelectorAll('.jfy-product-link');
      const selectedLink = intersection(
        e.nativeEvent.propagationPath(),
        jfyLink
      );
      if (selectedLink.length > 0) {
        referralSourceClicked('Just For You', this.props.pageType);
      }
    }
  }
  bvAPIReady(bvAPI) {
    bvAPI
      .getRecommendations({
        type: 'category',
        slot: 'slot1',
        pageType: 'home',
      })
      .then(res => this.renderCategories(res))
      .catch(err => this.recosError(err));
  }
  recosError(err) {
    this.props.rejectBVRecommendations(err);
  }
  renderCategories(result) {
    const recos = result.recommendations;
    this.props.resolveBVRecommendations(recos);
    if (shouldLoad(this.props.api.ecmrecommendations)) {
      this.props.getEcmRecommendations();
    }
  }
  renderGet() {
    const { ecmRecommendations } = this.props;
    if (ecmRecommendations.products && ecmRecommendations.products.length > 0) {
      return ecmRecommendations.products.map((ecmRecommendedProduct, index) => (
        <EcmRecommendedProducts
          ecmRecommendedProduct={ecmRecommendedProduct}
          key={index}
          position={index}
          registerClick={this.props.setSelectedIndex}
        />
      ));
    }
    return <div />;
  }
  render() {
    const { dataObj, ecmSlot, jfyFiveSlotsToggle, isSuppressRR } = this.props;
    const componentClass = `ecm-container__thirdparty__${get(
      dataObj,
      'componentId',
      ''
    )}`;
    const ecmStyle = get(ecmSlot, 'className', '');
    const jfySlotCountClass = jfyFiveSlotsToggle
      ? 'jfy-five-slots'
      : 'jfy-four-slots';
    const classes = classNames(
      'grid-100 tablet-grid-100 mobile-grid-100 ecm-container__recs ecm-container__thirdparty',
      jfySlotCountClass,
      ecmStyle,
      componentClass
    );
    return (
      <div
        className={classes}
        onClick={this.validateProductClick}
        data-bv-p13n-slot={
          this.state.isBVExperience && !isSuppressRR ? 'slot1' : ''
        }
      >
        {!isSuppressRR ? this.renderGet() : null}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  api: state.api,
  ecmRecommendations: state.ecm.ecmRecommendations,
  pageType: get(state, 'utag.page.page_definition_id', ''),
  bazaarVoiceToggle: state.toggles.BAZAAR_VOICE,
  bazaarVoiceABTest: find(state.abTests.responses, { mboxId: 'BazaarVoice' }),
  jfyFiveSlotsToggle: state.toggles.JFY_FIVE_PRODUCTS,
  isSuppressRR: state.toggles.SUPPRESS_RR,
});
const mapDispatchToProps = {
  getEcmRecommendations,
  clearEcmRecommendations,
  setSelectedIndex,
  resolveBVRecommendations,
  rejectBVRecommendations,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EcmRecommendations);
