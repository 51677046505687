import React, { Component } from 'react';
import { connect } from 'react-redux';
import uniqBy from 'lodash/uniqBy';
import { selectUserLocale } from '../../CountrySelector/countrySelector-actions';
import { UNITED_STATES } from 'storefront/components/constants';
import { isCountry } from 'client/utilities/utilities-country';
import { countryCurrencyData } from './countryCurrencyData';

class CurrencyChooser extends Component {
  constructor() {
    super();
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
  }
  handleCurrencyChange(e) {
    this.props.selectUserLocale(
      this.props.selectedCountryCode,
      e.target.value,
      this.props.selectedLocaleUrl,
    );
  }

  render() {
    const disabled = isCountry(this.props.selectedCountryCode, UNITED_STATES);
    const optionList = uniqBy(countryCurrencyData, 'currencyCode').map(
      (currencyValues, index) => (
        <option key={index} value={currencyValues.currencyCode}>
          {currencyValues.currencyName}
        </option>
      ),
    );

    return (
      <p>
        <b>Currency</b>
        <select
          className="currency-select"
          onChange={this.handleCurrencyChange}
          value={this.props.selectedCurrencyCode}
          disabled={disabled}
        >
          {optionList}
        </select>
      </p>
    );
  }
}

const mapStateToProps = state => ({
  selectedCountryCode: state.locale.selectedCountryCode,
  selectedCurrencyCode: state.locale.selectedCurrencyCode,
  selectedLocaleUrl: state.locale.selectedLocaleUrl,
});

const mapDispatchToProps = {
  selectUserLocale,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CurrencyChooser);
