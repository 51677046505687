import React from 'react';
import { dangerousProperty } from 'client-utils/utilities-html-sanitizer';
import Toggle from 'shared/components/Toggle/toggle';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Designer from './designerName';

export const productTitle = product => {
  const nameHtml = product.nameHtml || '';
  return (
    nameHtml && (
      <span
        className="product-heading__name__product"
        dangerouslySetInnerHTML={dangerousProperty(nameHtml)}
      />
    )
  );
};

const ProductHeading = ({
  product = {},
  isGroupPDP = false,
  isCommonBundle = false,
}) => {
  const { designer = {}, isZeroDollarProduct = false, details = {} } = product;
  // For PDP CommonBule : If Group doesnt have nameHTML, Add props with name value
  if (isCommonBundle && !product['nameHtml'])
    product['nameHtml'] = product['name'] || '';

  const { suppressCheckout = false } = details;
  const showRating = !isGroupPDP && !suppressCheckout && !isZeroDollarProduct;
  return (
    <div className="product-heading__name">
      <Toggle feature="REVIEW_RATINGS">
        {showRating && <span className="TurnToReviewsTeaser" />}
      </Toggle>
      <h1>
        {(isZeroDollarProduct && (
          <Designer name={designer.name} isChanel={product.isChanel} />
        )) || (
          <Designer
            name={designer.name}
            designerBoutiqueUrl={designer.designerBoutiqueUrl}
            isChanel={product.isChanel}
          />
        )}
        {productTitle(product)}
      </h1>
    </div>
  );
};

const mapStateToProps = state => {
  const productCatalog = state.productCatalog || {};
  const isGroup = get(productCatalog, 'product.isGroup', false);

  return {
    isGroupPDP: isGroup,
  };
};

export default connect(mapStateToProps)(ProductHeading);
