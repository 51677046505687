import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setTitle } from '../../actions/actions-page';

class NotFound extends Component {
  UNSAFE_componentWillMount() {
    this.props.setTitle('404 Page Not Found');
  }

  render() {
    return (
      <main>
        <div className="grid-container">
          <div className="grid-100 tablet-grid-100 mobile-grid-100">
            <p>Uh oh... Go to PDP:</p>
            <ul>
              <li>
                <a href="/prod188950100/p.prod">/prod188950100/p.prod</a>
              </li>
              <li>
                <a href="/prod184420589/p.prod">/prod184420589/p.prod</a>
              </li>
              <li>
                <a href="/prod188640125/p.prod">/prod188640125/p.prod</a>
              </li>
              <li>
                <a href="/prod185190111/p.prod">/prod185190111/p.prod</a>
              </li>
              <li>
                <a href="/prod188640291/p.prod">/prod188640291/p.prod</a>
              </li>
              <li>
                <a href="/prod189270028/p.prod">/prod189270028/p.prod</a>
              </li>
              <li>
                <a href="/prod178150145/p.prod?xbcpath=cat13140748_cat14120827_cat000470_cat000000">
                  /prod178150145/p.prod
                </a>{' '}
                (Breadcrumbs)
              </li>
              <li>
                <a href="/prod191720427/p.prod">/prod191720427/p.prod</a>{' '}
                (Multiple Colors)
              </li>
              <li>
                <a href="/prod190300074/p.prod">/prod190300074/p.prod</a>{' '}
                (Multiple Colors)
              </li>
              <li>
                <a href="/prod181010097/p.prod">/prod181010097/p.prod</a>{' '}
                (Multiple Colors)
              </li>
              <li>
                <a href="/prod192180046/p.prod">/prod192180046/p.prod</a>{' '}
                (Multiple Colors)
              </li>
              <li>
                <a href="/prod8240083/p.prod">/prod8240083/p.prod</a> (No color,
                No Size)
              </li>
              <li>
                <a href="/prod3420003/p.prod">/prod3420003/p.prod</a> (No color,
                No Size)
              </li>
              <li>
                <a href="/prod156110066/p.prod">/prod156110066/p.prod</a> (No
                color, Size)
              </li>
              <li>
                <a href="/prod158220111/p.prod">/prod158220111/p.prod</a> (No
                color, Size)
              </li>
              <li>
                <a href="/prod100010052/p.prod">/prod100010052/p.prod</a>{' '}
                (Color, No Size)
              </li>
              <li>
                <a href="/prod108640008/p.prod">/prod108640008/p.prod</a>{' '}
                (Color, No Size)
              </li>
              <li>
                <a href="/prod190070272/p.prod">/prod190070272/p.prod</a>{' '}
                (Color, Size)
              </li>
              <li>
                <a href="/prod186080011/p.prod">/prod186080011/p.prod</a>{' '}
                (Color, Size)
              </li>
              <li>
                <a href="/prod75200024/p.prod">/prod75200024/p.prod</a> (Swatch
                Color, No Size)
              </li>
              <li>
                <a href="/prod31120009/p.prod">/prod31120009/p.prod</a> (Swatch
                Color, No Size)
              </li>
              <li>
                <a href="/prod148200068/p.prod">/prod148200068/p.prod</a>{' '}
                (Swatch Color, No Size)
              </li>
              <li>
                <a href="/prod180270381/p.prod">/prod180270381/p.prod</a>{' '}
                (Swatch Color, No Size)
              </li>
              <li>
                <a href="/prod39620330/p.prod">/prod39620330/p.prod</a> (Swatch
                Color, No Size)
              </li>
              <li>
                <a href="/prod74720050/p.prod">/prod74720050/p.prod</a> (Swatch
                Color, No Size)
              </li>
              <li>
                <a href="/prod21720065/p.prod">/prod21720065/p.prod</a> (Swatch
                Color, Size)
              </li>
              <li>
                <a href="/prod175910014/p.prod">/prod175910014/p.prod</a>{' '}
                (Swatch Color, Size)
              </li>
              <li>
                <a href="/prod128100052/p.prod">/prod128100052/p.prod</a> (114
                Advertised Promo)
              </li>
              <li>
                <a href="/prodInvalid/p.prod">/prodInvalid/p.prod</a> (Invalid
                Or Unavailable Product)
              </li>
              <li>
                <a href="/prod190070046/p.prod">/prod190070046/p.prod</a> (113
                Advertised Promo + Price)
              </li>
              <li>
                <a href="/prod187180027/p.prod">/prod187180027/p.prod</a> All
                promos
              </li>
              <li>
                <a href="/prod193890353/p.prod">/prod193890353/p.prod</a> Gift
                With Purchase, Free Shipping, 113 Percent Off with promo price,
                Dollar Off
              </li>
              <li>
                <a href="/prod175910014/p.prod?xbcpath=cat5270731_cat000550_cat000470_cat000000">
                  /prod175910014/p.prod
                </a>{' '}
                2 113 Percent Off promos, with and without promo price (promo
                price with higher percentage) (Breadcrumbs)
              </li>
            </ul>
          </div>
        </div>
      </main>
    );
  }
}

const mapDispatchToProps = { setTitle };
export default connect(
  null,
  mapDispatchToProps,
)(NotFound);
