import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { isLoadingFinished } from '@bgo-ui/common/client/utilities/utilities-api';
import { closeModal } from '@bgo-ui/common/client/common/components/Modal/actions';
import Copy from '../../../../../../../common/Copy/Copy';
import SubmitButton from '../../../../../../../common/Forms/FormButtons/SubmitButton/SubmitButton';

export const DumbChangePasswordSuccess = props => (
  <div className="change-password-modal__success">
    <Copy
      content={get(props.myAccountContent, 'CHANGE-PASSWORD-SUCCESS')}
      loaded={props.isContentLoaded}
      fallback={<p>Your password has been successfully changed.</p>}
    />
    <SubmitButton
      caption="OK"
      onClick={props.closeModal}
      additionalClasses="change-password-modal__submit-btn"
    />
  </div>
);

DumbChangePasswordSuccess.propTypes = {
  myAccountContent: PropTypes.object.isRequired,
  isContentLoaded: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  myAccountContent: get(state, 'myaccount.myAccountContent', {}),
  isContentLoaded: isLoadingFinished(state, 'content'),
});

const mapDispatchToProps = {
  closeModal,
};

const ChangePasswordSuccess = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbChangePasswordSuccess);

export default ChangePasswordSuccess;
