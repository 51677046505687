import React from 'react';
import classNames from 'classnames';
import Column from '../Common/Column/column';
import buildArrayOfCategoriesByColumnBreakFlg from '../drawerUtility';
import './standardDrawer.scss';

// eslint-disable-next-line no-unused-vars
const StandardDrawer = ({ silo, style, closeFunction, hidden,
  delayHoverOnSilos = false, getPromoContent }) => {
  const arrayOfCategoriesGrouped = buildArrayOfCategoriesByColumnBreakFlg(silo.categories);
  const arrayOfColumns = arrayOfCategoriesGrouped
      .map((categories, index) => Column({ key: index, categories }));
  const promoBannerJsx = getPromoContent() ? <div className="drawer-promo-container grid-100 tablet-100">{getPromoContent()}</div> : false;
  const columnCount =  arrayOfColumns.length;

  if (delayHoverOnSilos) {
    return (
      <section
        className={classNames('silo-group delay-hover-on-silos', { 'drawer-hidden': hidden })}
      >
      <div className="silo-parent">
        <div className="cusp-drawer-content silo-column-group">
          {arrayOfColumns}
        </div>
        {promoBannerJsx ? (
          <div className="silo-column-promo-group">
            {promoBannerJsx}
          </div>
        ) : null}
      </div>
        <div className="gradient-cover" />
      </section>
    );
  } else {
    return (
      <section
        //style={style}
        className={classNames('silo-group', { 'drawer-hidden': hidden })} 
      >
      <div className="silo-parent">
        <div className="cusp-drawer-content silo-column-group">
          {arrayOfColumns}
        </div>
        {promoBannerJsx ? (
          <div className="silo-column-promo-group">
            {promoBannerJsx}
          </div>
        ) : null}
      </div>
      <div className="gradient-cover" />
      </section>
    );
  }
};


export default StandardDrawer;
