import compact from 'lodash/compact';
import values from 'lodash/values';
import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import MainMedia from './components/MainMedia/mainMedia';
import AlternateImages from './components/AlternateImages/alternateImages';
import ZoomImage from './components/ZoomImage/zoomImage';
import './productMedia.scss';

const ProductMedia = props => {
  const {
    name,
    media,
    productId,
    activeIndex,
    dots = false,
    arrows = false,
    showSoldOutOverlay = false,
    showAltImages = true,
    firstGroupProduct,
    isGroup,
  } = props;
  let groupMedia = [];

  if (isGroup && props.productId === firstGroupProduct) {
    groupMedia = props.groupMedia;
  }
  const images = compact([
    media.main,
    ...values(media.alternate || []),
    ...groupMedia,
  ]);
  const video = get(media, 'video', {});
  return (
    <div className="product-media">
      <MainMedia
        productId={productId}
        name={name}
        images={images}
        video={video}
        activeIndex={activeIndex}
        showSoldOutOverlay={showSoldOutOverlay}
        dots={dots}
        arrows={arrows}
      />
      {showAltImages && (
        <AlternateImages
          name={name}
          productId={productId}
          images={images}
          video={video}
        />
      )}
      <ZoomImage productId={productId} />
    </div>
  );
};

const mapStateToProps = state => {
  const isGroup = get(state, 'productCatalog.product.isGroup');
  const firstGroupProduct = get(
    state,
    'productCatalog.group.childProducts.productIds[0]',
  );
  const media = get(state, 'productCatalog.group.media', {});
  const groupMedia = compact([media.main, ...values(media.alternate || [])]);

  return {
    isGroup,
    firstGroupProduct,
    groupMedia,
  };
};

export default connect(mapStateToProps)(ProductMedia);
