import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { openModal } from 'shared/components/Modal/actions';

const hashCodes = {
  0: 'b',
  1: 'm',
  2: 'k',
  3: 'y',
  4: '2',
  5: 'h',
  6: 'c',
  7: '3',
  8: '5',
  9: 'n',
  '-': 'j',
  '#': 'V',
  $: '8',
  '.': 'a',
  '@': '6',
  _: 'z',
  '~': 'o',
  '+': 'J',
  '<': '9',
  a: 'f',
  b: 'P',
  c: 'l',
  d: 'N',
  e: '0',
  f: 'x',
  g: 'R',
  h: 'Q',
  i: '1',
  j: 'p',
  k: 't',
  l: 'w',
  m: '7',
  n: 'S',
  o: 'g',
  p: 'i',
  q: 'e',
  r: '4',
  s: 'd',
  t: 'E',
  u: 'u',
  v: 'B',
  w: 'r',
  x: 's',
  y: 'v',
  z: 'q',
};

export function getLinkTarget(target) {
  if (target.getAttribute('data-modal-url')) {
    return target;
  }
  return target.parentElement;
}

export function getLinkUrl(args) {
  const { aemAbTestUsergroup = '', session = {}, el = {} } = args;
  let abTestUserGroup = aemAbTestUsergroup;
  if (session.personalize && session.dt_personalize_preview) {
    const dtPersonalizePreview = JSON.parse(session.dt_personalize_preview);
    if (!isEmpty(dtPersonalizePreview.aemAbTestUsergroup)) {
      abTestUserGroup = dtPersonalizePreview.aemAbTestUsergroup;
    }
  }
  return abTestUserGroup === 'Test' && el.getAttribute('data-modal-url-alt')
    ? el.getAttribute('data-modal-url-alt')
    : el.getAttribute('data-modal-url');
}

export function onModalAnchorClick(e, props) {
  const el = getLinkTarget(e.target);
  if (el) {
    const url = getLinkUrl({ ...props, el });
    const width = el.getAttribute('data-modal-width');
    if (url) {
      if (url.indexOf('timetrade.com') >= 0) {
        if (!get(props.device, 'isMobile')) e.preventDefault();
        el.setAttribute('href', url);
        el.setAttribute('target', '_blank');
        if (typeof tt === 'undefined') {
          const b = document;
          const c = 'script';
          const d = document.createElement(c);
          d.src = '//cdn.timetrade.com/scripts/lightbox/latest/lightbox.js';
          d.async = true;
          const a = b.getElementsByTagName(c)[0];
          a.parentNode.insertBefore(d, a);
          d.addEventListener(
            'load',
            () => window.tt && window.tt.launchWorkflow(el),
          );
        } else {
          window.tt && window.tt.launchWorkflow(el);
        }
      } else {
        e.preventDefault();
        props.dispatch(openModal({ url, width }));
      }
    }
  }
}

export const getHashedMailAddress = (emailId = '') => {
  let hashedMailId = '';

  const email = emailId?.toLowerCase() || '';
  for (let i = 0; i < email.length; i++) {
    hashedMailId += hashCodes[email[i]];
  }

  return hashedMailId;
};
