import get from 'lodash/get';
import {
  getProduct,
  openEditWishlist,
  setSelectedIndex,
  setReplenishInterval,
} from 'pdp/components/ProductPage/actions';
import React from 'react';
import { connect } from 'react-redux';
import { WISHLIST_EDIT } from '../../../../constants';
import '../WishlistProdDetails/wishlistProdDetails.scss';

const EditWishlist = ({ wishlistData, dispatch }) => {
  // let wishlistData = wishlistData;
  const productId = wishlistData.id;
  let colorIndex = 0;
  let sizeIndex = 0;
  const sizeName = get(wishlistData, 'sku.size.name');
  const color = get(wishlistData, 'sku.color.name');

  wishlistData &&
    wishlistData.options &&
    wishlistData.options.productOptions &&
    // eslint-disable-next-line array-callback-return
    wishlistData.options.productOptions.map(option => {
      if (option.label === 'color') {
        for (let i = 0; i < option.values.length; i++) {
          if (option.values[i].name === color) {
            colorIndex = i;
            break;
          }
        }
      }
      if (option.label === 'size') {
        for (let j = 0; j < option.values.length; j++) {
          if (option.values[j].name === sizeName) {
            sizeIndex = j;
            break;
          }
        }
      }
    });

  const dispatchCalls = () => {
    dispatch(setSelectedIndex(productId, colorIndex, 'color'));
    dispatch(setSelectedIndex(productId, sizeIndex, 'size'));
    dispatch(
      setReplenishInterval(productId, Number(wishlistData.replenishInterval)),
    );
    dispatch(getProduct(productId));
    dispatch(openEditWishlist(wishlistData));
  };

  return (
    <div className="prod-edit" onClick={() => dispatchCalls()}>
      {WISHLIST_EDIT}
    </div>
  );
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  openEditWishlist: (...args) => dispatch(openEditWishlist(productId, ...args)),
  getProduct: (productId, ...args) => dispatch(getProduct(productId, ...args)),
  setSelectedIndex: (productId, ...args) =>
    dispatch(setSelectedIndex(productId, ...args)),
  setReplenishInterval: (...args) =>
    dispatch(setReplenishInterval(productId, ...args)),
});

export default connect(mapDispatchToProps)(EditWishlist);
