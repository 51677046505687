import isEmpty from 'lodash/isEmpty';
import React from 'react';
import {
  getCustomStylesForCTADesktop,
  getCustomStylesForCTATablet,
  getCustomStylesForCTAMobile,
} from './textComponentUtilities';

const EcmTextBreakPointComponent = props => {
  const desktopStyles = [];
  const tabletStyles = [];
  const mobileStyles = [];
  const {
    classes,
    divStyle,
    desktopContent,
    tabletContent,
    mobileContent,
    adaptiveStyleBlock,
    callToActionCustomStylesDesktop,
    callToActionCustomStylesTablet,
    callToActionCustomStylesMobile,
  } = props;

  if (!isEmpty(mobileContent)) {
    mobileStyles.push('hide-on-desktop');
    mobileStyles.push('hide-on-tablet');
    desktopStyles.push('hide-on-mobile');
    tabletStyles.push('hide-on-mobile');
  }

  if (!isEmpty(tabletContent)) {
    tabletStyles.push('hide-on-desktop');
    desktopStyles.push('hide-on-tablet');
    desktopStyles.push('hide-on-mobile');
  }

  return (
    <div>
      <div
        style={divStyle}
        className={`${classes} ${desktopStyles.join(' ')}`}
        dangerouslySetInnerHTML={{ __html: desktopContent }}
      />
      {adaptiveStyleBlock}
      {!isEmpty(tabletContent) && (
        <div
          style={divStyle}
          className={`${classes} ${tabletStyles.join(' ')}`}
          dangerouslySetInnerHTML={{ __html: tabletContent }}
        />
      )}

      {!isEmpty(mobileContent) && (
        <div
          style={divStyle}
          className={`${classes} ${mobileStyles.join(' ')}`}
          dangerouslySetInnerHTML={{ __html: mobileContent }}
        />
      )}
      {callToActionCustomStylesDesktop &&
        getCustomStylesForCTADesktop(callToActionCustomStylesDesktop)}
      {callToActionCustomStylesTablet &&
        getCustomStylesForCTATablet(callToActionCustomStylesTablet)}
      {callToActionCustomStylesMobile &&
        getCustomStylesForCTAMobile(callToActionCustomStylesMobile)}
    </div>
  );
};
export default EcmTextBreakPointComponent;
