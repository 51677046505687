/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { getHashedMailAddress } from 'shared/components/App/utilities';
import EditButton from '../EditButton/EditButton';
import { editButtonText } from '../../../constants';

function DumbBGNewsletterSection({
  email,
  isEditDisabled,
  isGreyCssFix,
  emailSignupLinksToEpsilon,
}) {
  const editNewsletterClick = () => {
    const customerKeyUrl = email
      ? `?CustomerKey=${getHashedMailAddress(email)}&PrefApp_Campaign_ID=BGO`
      : '';
    let URL = '';

    if (emailSignupLinksToEpsilon) {
      URL = `${NMConfig.EPSILON_LINK}Preferences/${customerKeyUrl}`;
    } else {
      URL = `https://reg.cheetahmail.com/regp?aid=248293074&n=8&a=1&email=${email}`;
    }
    window.open(URL, '_blank', 'height=600,width=644');
  };

  return (
    <section className="account-details-page__section" aria-live="polite">
      <h2 className="account-details-page__section-header">BG Newsletter</h2>
      <div className="account-details-page__bg-newsletter-wrapper">
        <div className="account-details-page__field-value">
          <p>
            Be the first to know about the latest arrivals. We{"'"}ll keep you
            up to date on special promotions, sales and more.
          </p>
          <p>You are currently subscribed</p>
        </div>
        <div className="account-details-page__field-edit">
          <EditButton
            editText={editButtonText}
            isDisabled={isEditDisabled}
            onButtonClick={editNewsletterClick}
            isGreyCssFix={isGreyCssFix}
          />
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = state => ({
  isEditDisabled: !!get(
    state,
    'myaccount.myAccountDetails.currentEditSection',
    null,
  ),
  email: get(state, 'myaccount.myAccountDetails.fields.email', null),
  isGreyCssFix: get(state, 'toggles.GREYCOLOR_CSS_FIX', false),
  emailSignupLinksToEpsilon: get(state, 'toggles.EPSILON_EMAIL', false),
});

const BGNewsletterSection = connect(mapStateToProps)(DumbBGNewsletterSection);

export default BGNewsletterSection;
