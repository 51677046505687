import React from 'react';
import CustomDropDownComponent from 'pdp/components/ProductPage/optimizationcomponents/CustomDropDownComponent/customDropDownComponent';
import { updateQuantity } from 'pdp/components/ProductPage/actions';
import { connect } from 'react-redux';
import { EyeBrow1 } from 'bgo-common/Styleguide/Typography';
import get from 'lodash/get';

class ProductSize extends React.Component {
  componentDidMount() {
    if (this.props.options.length === 1 && this.props.selectedIndex !== 0) {
      this.props.onClick(0, this.props.label);
    }
  }

  onClick = (selectedIndex, label) => {
    this.props.updateQuantity(1);
    this.props.onClick(selectedIndex, label);
  };

  render() {
    const { availableSkus, options } = this.props;

    const isDisabled = index => {
      if (!availableSkus || !availableSkus.length) {
        return false;
      }
      if (!availableSkus[index]) {
        return true;
      }
      return !get(availableSkus[index], 'sellable');
    };

    const FilteredOptions = options.map((value, index) => {
      options[index]['inactive'] = isDisabled(index);
      return options[index];
    });

    const sizeDropdown =
      options.length === 1 ? (
        <EyeBrow1> {options[0]['name']} </EyeBrow1>
      ) : (
        <CustomDropDownComponent
          {...this.props}
          disableText={'(Out Of Stock)'}
          options={FilteredOptions}
          onClick={this.onClick}
        />
      );

    return sizeDropdown;
  }
}

const mapDispatchToProps = (dispatch, { productId }) => ({
  updateQuantity: (...args) => dispatch(updateQuantity(productId, ...args)),
});

export default connect(
  null,
  mapDispatchToProps,
)(ProductSize);
