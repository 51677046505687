import React from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import Image from '@bgo-ui/common/components/Image/image'; // 'pdp/components/ProductPage/components/Image/image';
import NO_IMAGE_ONE_PX from 'assets/images/onePx.svg';

const DumbCPPixel = ({ p13Pxl = '', isDomLoadComplete = false }) => {
  return (
    <React.Fragment>
      {!isEmpty(p13Pxl) && isDomLoadComplete ? (
        <Image
          src={`${NMConfig.API_COHERENT_PATH_PXL}?${p13Pxl}`}
          alt="Personalization"
          title="Personalization"
          errorImage={NO_IMAGE_ONE_PX}
        />
      ) : (
        false
      )}
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  p13Pxl: get(state, 'ecm.ecmP13n.pxlParams', ''),
  isDomLoadComplete: get(state, 'page.isDomLoadComplete', false),
});

export default connect(mapStateToProps)(DumbCPPixel);
