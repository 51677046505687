import React from 'react';
import get from 'lodash/get';
import some from 'lodash/some';

const GiftNow = ({ product, selectedSku }) => {
  const isPerishable = () => get(product, 'perishable', false);

  const isPreciousJewelry = () =>
    get(product, 'details.preciousJewelryItem', false);

  const isOutOfStock = () => {
    return !get(selectedSku, 'sellable', true);
  };

  const isDropShipItem = () => get(selectedSku, 'dropShip', false);

  const not = (...conditions) => !some(conditions);

  const isEligibleForGiftNow = () => {
    return not(
      isPreciousJewelry(),
      isOutOfStock(),
      isDropShipItem(),
      isPerishable(),
    );
  };

  return isEligibleForGiftNow() ? (
    <div className="giftnow-container grid-50 tablet-grid-65 mobile-grid-100" />
  ) : null;
};

export default GiftNow;
