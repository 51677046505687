import React from 'react';
import { EyeBrow1 } from 'bgo-common/Styleguide/Typography';
import { OOS_TEXT } from 'pdp/constants';

import './itemNotAvailable.scss';

const ItemNotAvailable = () => (
  <div className="undisplayable grid-100 tablet-grid-100 mobile-grid-100">
    <EyeBrow1>
      <span className="undisplayable__message out-of-stock-text">
        {OOS_TEXT}
      </span>
    </EyeBrow1>
  </div>
);

export default ItemNotAvailable;
