import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Formik, Form } from 'formik';
import Copy from '../../../../common/Copy/Copy';
import SubmitButton from '../../../../common/Forms/FormButtons/SubmitButton/SubmitButton';
import CancelButton from '../../../../common/Forms/FormButtons/CancelButton/CancelButton';
import FormikTextField from '../../../../common/Forms/FormikTextField/FormikTextField';
import FormikNumberField from '../../../../common/Forms/FormikNumberField/FormikNumberField';
import FormikFieldPreSelected from '../../../../common/Forms/FormikFieldPreselected/FormikFieldPreselected';
import FormikDropDownField from '../../../../common/Forms/FormikDropDown/FormikDropDown';

import { STATE_LIST } from '../../../stateList';
import { isPostalValid } from '../actions';
import {
  saveButtonText,
  updateButtonText,
  cancelButtonText,
} from '../../../constants';

import './AddressBookInfo.scss';

const ADDRESS_BOOK_MISSING_FILEDS_ERROR =
  'Entry error(s). Correct red item(s).';

const ADDRESS_BOOK_ZIP_CODE_INVALID_ERROR =
  'Zip code does not match the state selected.';

const AddressBookInfo = ({
  addressId,
  addressType,
  myAccountAddress,
  myAccountContent,
  onSave,
  onCancel,
  isPostalValid,
  stateZipCodeMismatchToggle,
}) => {
  const initialFormValue = {
    firstName: '',
    lastName: '',
    country: 'United States',
    state: '',
    addressType,
    address1: '',
    address2: '',
    city: '',
    phoneType: 'M',
    postalCode: '',
    phoneNumber: '',
    defaultAddress: false,
  };

  const validatePostalCode = async values => {
    let isValid = false;
    await isPostalValid(values, result => {
      isValid = result;
    });
    return isValid;
  };

  const validateForm = async values => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = '';
    }
    if (!values.lastName) {
      errors.lastName = '';
    }
    if (!values.state) {
      errors.state = '';
    }
    if (!values.address1) {
      errors.address1 = '';
    }
    if (!values.city) {
      errors.city = '';
    }
    if (values.postalCode.length < 5) {
      errors.postalCode = '';
    }
    if (values.phoneNumber.length < 12) {
      errors.phoneNumber = '';
    }
    if (
      stateZipCodeMismatchToggle &&
      !('postalCode' in errors) &&
      values.state
    ) {
      const isValidZipCode = await validatePostalCode(values);
      if (!isValidZipCode) {
        errors.postalCode = '';
        errors.state = '';
        errors.isValidZipCode = '';
      }
    }
    return errors;
  };

  const editFormValue =
    !isEmpty(addressId) &&
    myAccountAddress.find(address => address.addressId === addressId);
  const submitButtonName = isEmpty(addressId)
    ? saveButtonText
    : updateButtonText;
  const stateList =
    myAccountContent && myAccountContent['MYACCOUNT-STATELIST-US']
      ? JSON.parse(
          myAccountContent['MYACCOUNT-STATELIST-US'].content[0].content[0]
            .value,
        )
      : STATE_LIST;
  const ADDRESS_BOOK_MISSING_FIELDS = get(
    myAccountContent,
    'ADDRESSBOOK-MISSING-FIELDS-ERROR',
    ADDRESS_BOOK_MISSING_FILEDS_ERROR,
  );
  return (
    <Formik
      initialValues={
        editFormValue
          ? { ...editFormValue, country: 'United States' }
          : initialFormValue
      }
      validate={validateForm}
      onSubmit={(values, actions) => {
        setTimeout(() => {
          const payload = { ...values, country: 'US' };
          onSave(payload);
          actions.setSubmitting(false);
        }, 1000);
      }}
      onReset={() => onCancel(false)}
    >
      {props => (
        <Form>
          <div className="form-fields-container">
            <FormikTextField
              name="firstName"
              id="address-first-name"
              placeholder="First Name"
              maxLength={40}
              fontSize
            />
            <FormikTextField
              name="lastName"
              id="address-last-name"
              placeholder="Last Name"
              maxLength={40}
              fontSize
            />
            <div className="form-full-width">
              <FormikFieldPreSelected
                name="country"
                id="address-country"
                placeholder="Country"
                tooltipText="We are currently only shipping to the United States."
                fontSize
              />
            </div>
            <div className="form-full-width">
              <FormikTextField
                className="form-full-width"
                name="address1"
                id="address-address1"
                placeholder="Address Line 1"
                maxLength={30}
                fontSize
              />
            </div>
            <div className="form-full-width">
              <FormikTextField
                className="form-full-width"
                name="address2"
                id="address-address2"
                placeholder="Address Line 2"
                maxLength={30}
                fontSize
              />
            </div>
            <FormikTextField
              name="city"
              id="address-city"
              placeholder="City"
              maxLength={30}
              fontSize
            />
            <FormikDropDownField
              caption="States:"
              placeholder="State"
              valuesArray={stateList}
              id="address-state"
              name="state"
              value={props.values.state}
              fontSize
            />
            <FormikNumberField
              name="postalCode"
              id="address-postalCode"
              placeholder="ZIP"
              options={{ blocks: [5] }}
              fontSize
            />
            <FormikNumberField
              name="phoneNumber"
              id="address-phone"
              placeholder="Phone Number"
              options={{ blocks: [3, 3, 4], delimiter: '-' }}
              fontSize
            />
          </div>
          <div
            className={
              props.submitCount > 0 && !isEmpty(props.errors)
                ? 'actions-container-with-error'
                : 'actions-container'
            }
          >
            <SubmitButton
              isSubmitting={props.isSubmitting}
              type="submit"
              caption={submitButtonName}
            />
            <CancelButton
              onClick={props.handleReset}
              caption={cancelButtonText}
            />
          </div>
          {props.submitCount > 0 &&
            !isEmpty(props.errors) &&
            ('isValidZipCode' in props.errors ? (
              <div className="error-msg">
                <div>{ADDRESS_BOOK_ZIP_CODE_INVALID_ERROR}</div>
              </div>
            ) : (
              <div className="error-msg">
                <Copy content={ADDRESS_BOOK_MISSING_FIELDS} />
              </div>
            ))}
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = state => ({
  myAccountAddress: get(state, 'myaccount.myAccountAddress', []),
  myAccountContent: get(state, 'myaccount.myAccountContent', false),
  stateZipCodeMismatchToggle: state.toggles.STATE_ZIP_MISMATCH_FIX,
});

const mapDispatchToProps = {
  isPostalValid,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddressBookInfo);
