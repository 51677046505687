import React from 'react';
import AppMyAccountWrapper from './app-my-account-wrapper';
import MyOrdersPage from '../../myaccount/pages/MyOrdersPage/MyOrdersPage';

export default function AppMyOrdersWrapper() {
  return (
    <AppMyAccountWrapper>
      <MyOrdersPage />
    </AppMyAccountWrapper>
  );
}
