import cookie from 'react-cookie';
import { STS_TRACKER_COOKIE } from 'bgo-common/components/constants';
import get from 'lodash/get';
import logger from 'server/utilities/logger';
import { buildCookieString } from 'universal/utilities-cookies';
import httpWithLogging from 'universal/http-client';
import {
  STS_LOADING_PRODUCT,
  STS_RESOLVED_PRODUCT,
  STS_REJECTED_PRODUCT,
  RESOLVED_STS_CLICKED_PRODUCT,
  STS_MODAL_OPEN,
  STS_SEE_MORE,
} from './actions-common';
import { openModal, closeModal } from '../components/Modal/actions';

export const openShopTheStyleModal = payload => dispatch => {
  dispatch({ type: STS_MODAL_OPEN }); // for analytics
  dispatch(
    openModal({
      type: 'ShopTheStyleModalComponent',
      className: 'shop-the-style-modal',
      stsProduct: payload,
    }),
  );
};

export const closeShopTheStyleModal = dispatch => {
  dispatch(
    closeModal({
      stsProduct: {},
    }),
  );
  dispatch({ type: STS_RESOLVED_PRODUCT, payload: [] });
  dispatch({ type: RESOLVED_STS_CLICKED_PRODUCT, payload: {} });
};

export const getHeaderConfig = ({
  JSESSIONID = '',
  DYN_USER_ID = '',
  TLTSID = '',
  W2A = '',
}) => {
  return {
    headers: {
      Cookie: buildCookieString({
        JSESSIONID,
        DYN_USER_ID,
        TLTSID,
        W2A,
      }),
    },
  };
};

export function getShopTheStyleProduct(categoryId) {
  return (dispatch, getState) => {
    dispatch({ type: STS_LOADING_PRODUCT });
    const state = getState();
    const { session } = state;
    const headers = getHeaderConfig(session);
    const requestURI = `${NMConfig.API_PRODUCT_LIST}?categoryId=${categoryId}`;
    const requestApi = httpWithLogging(state, 10000);
    return requestApi
      .get(requestURI, { headers })
      .then(successResponse => {
        const type = STS_RESOLVED_PRODUCT;
        dispatch({ type, payload: successResponse.data.products || [] });
      })
      .catch(error => {
        logger.info('An error occurred in Shop the Style Service API');
        dispatch({ type: STS_REJECTED_PRODUCT, payload: error });
      });
  };
}

export function getStsProduct(productId) {
  return (dispatch, getState) => {
    const state = getState();

    const requestParams = `?productIds=${productId}`;

    const requestApi = httpWithLogging(state, 10000);

    const requestURI = `${NMConfig.API_MINIFIED_PRODUCT_DETAIL_PDP}${requestParams}`;
    return requestApi.get(requestURI).then(successResponse => {
      const minifiedProductInfo = get(
        successResponse.data,
        'minifiedProductInfo',
        [],
      );

      dispatch({
        type: RESOLVED_STS_CLICKED_PRODUCT,
        payload: minifiedProductInfo[0] || {},
      });
    });
  };
}

export const trackSTSInPLP = () => {
  return dispatch => {
    dispatch({ type: STS_SEE_MORE });
  };
};

export const clickTracker = () => {
  cookie.save(STS_TRACKER_COOKIE, '1', { path: '/' });
};
