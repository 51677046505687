/* eslint-disable jsx-a11y/no-static-element-interactions */
import isEqual from 'lodash/isEqual';
import isElement from 'lodash/isElement';
import get from 'lodash/get';
import find from 'lodash/find';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { addEventListener, removeEventListener } from 'window-or-global';
import { addBodyClass, removeBodyClass } from 'client-utils/bodyClass';
import { handleTabEventWith } from 'client-utils/handleTabbing';
import { closeModal, setOverflowed } from 'shared/components/Modal/actions';
import modals, { ModalsWithHTMLContent } from 'client/modals';
import CloseIcon from 'shared/components/CloseIcon/closeIcon';
import { ESC_KEYCODE } from 'client-utils/keyCodes';
import handlePrevFocus from 'client-utils/modalFocusHelper';
import findTabbable from 'client-utils/tabbable';
import { isMobile } from 'client-utils/utilities-page';
import './modal.scss';

export class DumbModal extends Component {
  constructor() {
    super();
    this.state = {};
    this.determineOverflow = this.determineOverflow.bind(this);
    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.setup = this.setup.bind(this);
    this.handleTabEvent = this.handleTabEvent.bind(this);
    this.addClickEventListener = this.addClickEventListener.bind(this);
    this.removeClickEventListener = this.removeClickEventListener.bind(this);
  }

  componentDidMount() {
    this.setup();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.modal.open !== this.props.modal.open) {
      if (nextProps.modal.open) {
        this.setState({
          prevElem: document.activeElement,
        });
      } else {
        const element = handlePrevFocus(
          nextProps.modal.prevOpen,
          this.state.prevElem,
        );
        if (isElement(element)) {
          setTimeout(() => {
            element.focus();
          }, 0);
        } else {
          setTimeout(() => {
            const newElem = document.getElementById(element);
            newElem && newElem.focus();
          }, 0);
        }
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.modal, nextProps.modal);
  }

  componentDidUpdate(prevProps) {
    const { modal } = this.props;
    this.setup();
    modal.open && this.handleTabEvent();
    if (isMobile() && modal.type === 'QLOutfittingSummary') {
      const nmModalWindow = document.getElementById('nm-modal-window');
      if (nmModalWindow) {
        nmModalWindow.style.top = '0';
      }
    }

    if (
      modal.open &&
      !prevProps.modal.open &&
      modal.type === 'WelcomeMatPopup'
    ) {
      const closeIcon = document.querySelector('.welcome-mat .close-icon');
      closeIcon && this.addClickEventListener(closeIcon);
      const shopTheSiteLink = document.querySelector('.welcome-mat .message a');
      shopTheSiteLink && this.addClickEventListener(shopTheSiteLink);
    }
  }

  componentWillUnmount() {
    if (get(this.props, 'modal.type', '') === 'WelcomeMatPopup') {
      const closeIcon = document.querySelector('.welcome-mat .close-icon');
      closeIcon && this.removeClickEventListener(closeIcon);
      const shopTheSiteLink = document.querySelector('.welcome-mat .message a');
      shopTheSiteLink && this.removeClickEventListener(shopTheSiteLink);
    }
  }

  setup() {
    this.toggleBodyClass();
    this.determineOverflow();
    this.toggleEventListener();
  }

  addClickEventListener(element) {
    element.addEventListener('click', event => {
      event.preventDefault();
      sessionStorage.setItem('WCDismissed', 'true');
      this.props.closeModal();
    });
  }

  removeClickEventListener(element) {
    element.removeEventListener('click', () => {
      this.props.closeModal();
    });
  }

  getCustomHTMLComponent(options) {
    const CustomComponent = find(ModalsWithHTMLContent, c =>
      c.isValidComponent(options),
    );
    if (CustomComponent) {
      return <CustomComponent __html={options.html} />;
    }
    return false;
  }

  determineOverflow() {
    const { modal = {} } = this.props;
    const { scrollable = true } = modal;
    if (modal?.modalFix) {
      return;
    }
    const clientHeight = get(this.content, 'clientHeight', 0);
    const scrollHeight = get(this.content, 'scrollHeight', 0);
    this.props.setOverflowed(scrollable && clientHeight < scrollHeight);
  }

  toggleBodyClass() {
    const { modal } = this.props;
    const modalOpenClass = 'modal-open';

    if (modal.open) {
      addBodyClass(modalOpenClass);
    } else {
      removeBodyClass(modalOpenClass);
    }
  }

  toggleEventListener() {
    const { modal } = this.props;

    if (modal.open) {
      addEventListener('resize', this.determineOverflow);
    } else {
      removeEventListener('resize', this.determineOverflow);
    }
  }

  handleESCKey(e) {
    const {
      modal: { closeDisabled = false },
    } = this.props;
    if (!closeDisabled && e.which === ESC_KEYCODE) {
      this.props.closeModal();
      e.preventDefault();
    }
  }

  handleCloseClick(e) {
    const { modal = {} } = this.props;
    const { closeDisabled = false } = modal;
    if (!closeDisabled) {
      this.props.closeModal();
    }
    e.preventDefault();
  }

  handleTabEvent() {
    // eslint-disable-next-line react/no-find-dom-node
    const modalWindow = ReactDOM.findDOMNode(this.nmModalWindow);
    const focusableElements = findTabbable(modalWindow);
    const firstElement = focusableElements[0];
    const lastElement = focusableElements[focusableElements.length - 1];
    setTimeout(() => {
      firstElement ? firstElement.focus() : modalWindow.focus();
    }, 0);

    handleTabEventWith(firstElement, lastElement);
  }

  render() {
    const { modal = {} } = this.props;
    let showVideo = false;
    const {
      type,
      html,
      headerText = '',
      className,
      scrollable = true,
      overflowed = false,
      closeDisabled = false,
      closeButtonDisabled = false,
      adaProps = {},
    } = modal;
    let content;
    const { role = 'dialog', labelledby = '', describedby = '' } = adaProps;

    if (html) {
      if (html.indexOf('iframe') > -1 && html.indexOf('youtube') > -1) {
        showVideo = true;
      }
      content = this.getCustomHTMLComponent({ html, type });
      if (!content) {
        content = (
          <div
            id="modalHTMLContent"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        );
      }
    } else {
      const props = get(modal, 'props', {});
      const InnerComponent = get(modals, type);
      content = InnerComponent && <InnerComponent {...props} />;
    }

    return modal.open ? (
      <div
        // eslint-disable-next-line jsx-a11y/aria-props
        aria-modal="true"
        aria-labelledby={labelledby ? 'nm-modal-header' : labelledby}
        aria-describedby={describedby ? 'nm-modal-header' : describedby}
        className={classnames(
          'nm-modal',
          { 'nm-modal--scrollable': scrollable },
          { 'nm-modal--overflowed': overflowed },
          { 'nm-modal--video': showVideo },
        )}
        role={role}
        tabIndex="-1"
        ref={ref => {
          this.modalWindow = ref;
        }}
      >
        <div
          className={classnames('nm-modal__overlay', className)}
          onClick={this.handleCloseClick}
        >
          <div
            id="nm-modal-window"
            className="nm-modal__window"
            onClick={e => e.stopPropagation()}
            ref={ref => {
              this.nmModalWindow = ref;
            }}
            role="document"
            onKeyDown={e => this.handleESCKey(e)}
            tabIndex="-1"
          >
            <div
              id="nm-modal-header"
              className="nm-modal__header"
              tabIndex="-1"
            >
              <p>{headerText}</p>
            </div>
            <div
              className="nm-modal__content"
              ref={ref => {
                this.content = ref;
              }}
            >
              {content}
            </div>
            {!closeDisabled && !closeButtonDisabled && (
              <CloseIcon onClick={e => this.handleCloseClick(e)} />
            )}
          </div>
        </div>
      </div>
    ) : (
      false
    );
  }
}

const mapStateToProps = state => ({ modal: state.modal });
const mapDispatchToProps = { closeModal, setOverflowed };
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbModal);
