import { types as NavTypes } from '@bgo-ui/common/components/Navigation/actions-navigation';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

export const breadcrumbsUtag = breadcrumbs => {
  return isEmpty(breadcrumbs)
    ? null
    : {
        bread_crumb: map(breadcrumbs, 'name'),
        cat_id: map(breadcrumbs, 'id'),
      };
};

export default (state = {}, action) => {
  switch (action.type) {
    case NavTypes.RESOLVED_BREADCRUMB_CONTENT:
      return breadcrumbsUtag(action.payload.breadcrumbs);
    case NavTypes.REJECTED_BREADCRUMB_CONTENT:
      return {};
    default:
      return state;
  }
};
