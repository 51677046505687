export const DEFAULT_PAGE = 1;
export const DEFAULT_SORT_OPTION = 'NEWEST_FIRST';
export const BEST_MATCH = 'BEST_MATCH';
export const DEFAULT_REQUEST_OPTIONS = {
  page: DEFAULT_PAGE,
  sortBy: '',
};
export const PAGE_SIZE = 120;
export const SRP_PAGE_SIZE = 60;
export const SWATCHES_LIMIT_MOBILE = 3;
export const SWATCHES_LIMIT_TABLET = 3;
export const SWATCHES_LIMIT_DESKTOP = 5;
export const SWATCHES_LIMIT_MOBILE_MODERN = 3;
export const SWATCHES_LIMIT_TABLET_MODERN = 4;
export const SWATCHES_LIMIT_DESKTOP_MODERN = 4;
export const FILTERS_LIMIT_WITH_CATEGORY = 6;
export const TABLET_FILTER_LIMIT_WITH_CATEGORY = 4;
export const PRE_ORDER_TEXT = 'Pre-Order';
export const SHOW_MORE_COLORS_AVAILABLE_TEXT = 'More Colors Available';
export const ONLY_AT_NM_TEXT = 'Exclusive';
export const MONOGRAM_TEXT = 'Monogram';
export const GIFT_CARD_TEXT = 'Gift Card';
export const SORT_BY_TEXT = 'Sort By';
export const VIEW_BY_TEXT = 'View By';
export const VIEW_BY_OPTIONS = ['Product', 'Outfit'];
export const PRODUCTS_NOT_AVAILABLE_MESSAGE =
  'We currently have no results for';
export const NEW_ARRIVAL_TEXT = 'New Arrival';
export const SOLD_OUT_TEXT = 'SOLD OUT';
export const SOLD_OUT_TEXT_ALIAS = 'Sold Out';
export const BRAND_CODE = 'bg';
export const MY_FAVORITES = 'MY_FAVORITES';
export const FAVORITES_NOT_AVAILABLE_MESSAGE =
  "Sorry, you don't have any favorite items here";
export const PRODUCT_LIST_FILTER_OPTIONS_EMPTY_MESSAGE =
  'Sorry, your last search produced no results. Please adjust your selections and try again.';
export const INSTORE_FILTER_EMPTY_PRODUCT_LIST_ERROR_MESSAGE =
  'We currently have no results for this search. Try changing the city, state, zip or radius.';
export const PROMOTION_TYPE_113 = '113';
export const IN_STORE = 'In Store';
export const DESIGNER = 'Designer';
export const GIFT_CARD = 'gift card';
export const SIZE = 'Size';
export const CATEGORY = 'Category';
export const CATEGORY_LEVEL_2 = 'CategoryLevel2';
export const SWATCH_IMAGE_LOAD_TIMEOUT = 5000;
export const TEMPLATE_TYPE = {
  basic3up: 'P3', // P3
  basic5up: 'B5', // B5
  standard: 'EB3', // EB3
  enhancedPLPTemplate1: 'ELP1', // Enhanced PLP Template 1
  enhancedPLPTemplate2: 'ELP2', // Enhanced PLP Template 2
};
export const CLEAR_FILTER_TEXT = 'Clear Filter';
export const DESIGNERS_NO_RESULTS_FOUND = 'Sorry, no results found.';
export const FILTER_NO_RESULTS_FOUND_MESSAGE =
  'Sorry, no items found. Clear your filters to start over. ';
export const IMAGE_SIZE = {
  desktop: {
    normal: { width: 429, height: 602 },
    large: { width: 554, height: 775 },
  },
  tablet: {
    normal: { width: 305, height: 446 },
    large: { width: 395, height: 574 },
  },
  mobile: {
    normal: { width: 185, height: 256 },
    large: { width: 375, height: 525 },
  },
};

export const GROUP_TYPE = {
  GROUP_A: 'GROUPA',
  GROUP_B: 'GROUPB',
};
export const SUPER_GRID_3 = 'Grid-3x3';
export const SUPER_GRID_5 = 'Grid-5x5';
export const LIFE_STYLE_SHOP = 'Life Style Shop';

// Editorial
export const CURATED_PLP = 'curatedplp';

export const EC1_FULLBLEED = 'ec1-fullbleed';
export const EC2_TWOUP = 'ec2-twoup';
export const EC3_VERTICAL = 'ec3-vertical';
export const EC4_HORIZONTAL = 'ec4-horizontal';
export const EC5_SYMMETRIC = 'ec5-symmetric';
export const TEXT = 'text';
export const LSSTemplateType = 'LSS';
export const LSSPageName = 'lifestyleshop';
export const EditorialTemplateType = 'EDT';
export const EditorialPageName = 'editorial';
export const MagzineTemplateType = 'MAGZ';
export const MagzinePageName = 'magazine';
export const ProductDetail = 'product detail';
export const ShopTheLook = 'ShoptheLook';
export const COOKIE_SUPER_GRID_5_CATEGORY_ID_LIST =
  'COOKIE_SUPER_GRID_5_CATEGORY_ID_LIST';
export const COOKIE_SUPER_GRID_5_SEARCH_TERMS =
  'COOKIE_SUPER_GRID_5_SEARCH_TERMS';
export const CONTENTFUL_IDENTIFIER = {
  SDD_PLP_HEADER: 'BG-DEFAULT-PLP',
};
export const DESIGNER_AZ = 'Designers A-Z';
export const FilterFacetOrder = [
  CATEGORY,
  DESIGNER,
  SIZE,
  'Color',
  'Availability',
  'Same Day Delivery',
  'Price',
];
