import { transformCloudinaryUrl } from 'bgo-common/components/Util/product-cloudinary';
import classnames from 'classnames';
import { handleTabEventWith } from 'client-utils/handleTabbing';
import { ENTER_KEYCODE } from 'client-utils/keyCodes';
import { markStart, measureTTD } from 'client-utils/utilities-performance';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { clearStores, getStores } from 'pdp/components/ProductPage/actions';
import Image from 'pdp/components/ProductPage/components/Image/image';
import { getActiveMediaFirstDynamic } from 'pdp/components/ProductPage/selectors/getMedia';
import React, { Component } from 'react';
import reactCookie from 'react-cookie';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import Button from 'shared/components/Button/button';
import { closeModal } from 'shared/components/Modal/actions';
import { HTTP_RESPONSE_CODE } from 'shared/constants';
import './bopsModal.scss';
import BopsStoreSearchResults from './StoreSearch/storeSearchResults';

export const ERROR_MESSAGE_NO_SKU = 'Please select a size & color first.';
export const ERROR_MESSAGE_NO_QUANTITY =
  'Please select a quantity greater than zero.';

const storeSearchDescCurbside =
  'In stock items are available for same day pickup when ordered by 3pm. ' +
  'You will receive an email as soon as your order is ready for pick up!';
const storeSearchDesc =
  'In stock items are available for same day pickup when ordered by 3pm. ' +
  'You will receive an email as soon as your order is ready for pick up!';

export class DumbBopsModal extends Component {
  constructor() {
    super();
    this.determineError = this.determineError.bind(this);
    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleEnterKeyDown = this.handleEnterKeyDown.bind(this);
    this.getStoresInfo = this.getStoresInfo.bind(this);
  }

  componentDidMount() {
    if (!this.props.showStoresWithoutSearch) {
      measureTTD('PDP_FindInStoreModal');
      const freeFormAddress = reactCookie.load('bopsSearchTerm');
      if (freeFormAddress) {
        this.searchBox.value = freeFormAddress;
      }
      this.handleTabEvent();
    }
  }

  getStoresInfo() {
    const {stores} = this.props;
    const { showStoresWithoutSearch } = this.props;
    const numOfStores = (stores && stores.data && stores.data.length) || 0;
    const serviceUnavailable = (
      <p className="service-unavailable">
        Our service is temporarily unavailable. Please try again later.
      </p>
    );

    const noStoreFound = (
      <p className="no-store-found">
        There are no stores/inventory in your search area.
        <br />
        Order online and we will ship it to you.
      </p>
    );

    const storesFound = (
      <p role="region" aria-live="polite" className="stores-found">
        We have{' '}
        <b>
          {numOfStores} {numOfStores === 1 ? 'store' : 'stores'}
        </b>{' '}
        near your location.
      </p>
    );

    const invalidLocation = (
      <p className="invalid-location">
        We currently have no results for this search. Try changing the city,
        state, zip.
      </p>
    );

    if (this.searchBox && isEmpty(this.searchBox.value)) {
      return invalidLocation;
    }
    if (!stores || !stores.status) {
      return null;
    }
    if (
      stores.status === HTTP_RESPONSE_CODE.INTERNAL_SERVER_ERROR ||
      stores.status === HTTP_RESPONSE_CODE.FAILED_DEPENDENCY ||
      stores.status === HTTP_RESPONSE_CODE.SERVICE_UNAVAILABLE
    ) {
      return serviceUnavailable;
    } else if (stores.status === HTTP_RESPONSE_CODE.BAD_REQUEST) {
      return invalidLocation;
    } else if (
      stores.status === HTTP_RESPONSE_CODE.OK &&
      !showStoresWithoutSearch
    ) {
      return numOfStores ? storesFound : noStoreFound;
    }
    return null;
  }

  handleTabEvent() {
    // eslint-disable-next-line react/no-find-dom-node
    const firstElement = ReactDOM.findDOMNode(this.searchBox);
    const closeElement = document.querySelectorAll(
      '.nm-modal__window .close',
    )[0];
    handleTabEventWith(firstElement, closeElement);
  }

  determineError() {
    const { product = {}, selectedSku = {} } = this.props;
    const { quantity = 0 } = product;

    let message = false;

    if (isEmpty(selectedSku)) {
      message = ERROR_MESSAGE_NO_SKU;
    } else if (quantity < 1) {
      message = ERROR_MESSAGE_NO_QUANTITY;
    }

    return message;
  }

  handleEnterKeyDown(event) {
    if (event.which === ENTER_KEYCODE) {
      event.stopPropagation();
      this.handleSearch();
    }
  }

  handleSearch() {
    markStart('PDP_TimeToFindInStoreSearch');
    const { product = {}, selectedSku = {} } = this.props;
    const { quantity = 0 } = product;
    if (this.searchBox.value) {
      this.props.getStores(this.searchBox.value, selectedSku.id, quantity);
    } else {
      this.props.clearStores();
    }
  }

  handleCloseClick() {
    this.props.closeModal();
  }

  isDesigner = designer => designer.name && designer.name.length > 0;

  isColor = color => color.name && color.name.length > 0;

  retrunStoresWithoutSearch = (showStoresWithoutSearch, bopsCurbsideToggle) => {
    return !showStoresWithoutSearch ? (
      <div className="bops__search-block grid-50 tablet-grid-45 mobile-grid-100 grid-parent">
        <div id="searchLabel" className="bops__search-label">
          SEARCH STORES:
        </div>
        <div className="bops__search">
          <input
            id="searchStore"
            ref={ref => {
              this.searchBox = ref;
            }}
            type="text"
            className="bops__search__box"
            placeholder="City, State, or Zip"
            onKeyDown={this.handleEnterKeyDown}
          />
          <Button
            className="bops__search__button"
            value="Search"
            onClick={this.handleSearch}
            onKeyDown={this.handleEnterKeyDown}
          />
        </div>
      </div>
    ) : (
      <div className="bops__header grid-40 tablet-grid-45 mobile-grid-100 bops-spacing">
        <p id="storeSearchDesc" className="hide-on-mobile">
          {bopsCurbsideToggle ? storeSearchDescCurbside : storeSearchDesc}
        </p>
      </div>
    );
  };

  renderImage = image => {
    const dynamicImageUrl = get(image, 'dynamic.url');
    const dynamicImageSizeProp = {
      normal: {
        width: 456,
      },
    };
    const dynamicImageSize = {
      desktop: dynamicImageSizeProp,
      tablet: dynamicImageSizeProp,
      mobile: dynamicImageSizeProp,
    };
    const mediumImageUrl = get(image, 'medium.url');
    const imageUrl = dynamicImageUrl
      ? transformCloudinaryUrl(dynamicImageUrl, dynamicImageSize).desktop
          .imageSrc
      : mediumImageUrl;
    return (
      imageUrl && (
        <div className="bops__details__image">
          <Image src={imageUrl} alt={name} timeout={0} />
        </div>
      )
    );
  };

  render() {
    const error = this.determineError();
    let markup;
    const { showStoresWithoutSearch, bopsCurbsideToggle = false } = this.props;

    if (error) {
      markup = (
        <div className="bops bops--error">
          <div className="grid-100 tablet-grid-100 mobile-grid-100">
            <h2 className="error">Error</h2>
            <p className="bops__error-message">{error}</p>
          </div>
          <div className="grid-100 tablet-grid-100 mobile-grid-100 grid-parent">
            <div className="grid-50 tablet-grid-50 mobile-grid-100">
              <Button
                className="bops__close-button"
                value="OK"
                onClick={this.handleCloseClick}
              />
            </div>
          </div>
        </div>
      );
    } else {
      // eslint-disable-next-line max-len
      const {
        product = {},
        selectedSku = {},
        stores = {},
        skuMediaUrl,
        defaultStoreValues = {},
      } = this.props;
      const { name, designer = {}, quantity = 0 } = product;
      const { size = {}, color = {} } = selectedSku;
      const image = getActiveMediaFirstDynamic(product) || {};
      const { data: storesInfo = [], showStores: showStoresFlag } = stores;

      markup = (
        <div className="bops grid-100 tablet-grid-100 mobile-grid-100 grid-parent">
          <div className="bops__header grid-100 tablet-grid-100 mobile-grid-100 bops-spacing">
            <h1 id="storeSearchLabel">
              {bopsCurbsideToggle
                ? 'Buy Online, Pickup In Store'
                : 'Get it as soon as today!'}
            </h1>
            <p
              id="storeSearchDesc"
              className={classnames({
                'hide-on-tablet hide-on-desktop': showStoresWithoutSearch,
              })}
            >
              {bopsCurbsideToggle ? storeSearchDescCurbside : storeSearchDesc}
            </p>
          </div>
          <div className="bops__content grid-100 tablet-grid-100 mobile-grid-100 bops-spacing">
            <div className="bops__details-and-search">
              <div className="bops__details-container grid-60 tablet-grid-55 mobile-grid-100 grid-parent">
                <div className="bops__details">
                  {this.renderImage(image)}
                  <ul
                    aria-label="Product Details"
                    className="bops__details__description"
                  >
                    {this.isDesigner(designer) && (
                      <li>
                        {designer.name && (
                          <span className="bops__details__description__designer">
                            {designer.name}
                          </span>
                        )}
                      </li>
                    )}
                    {name.length > 0 && (
                      <li>
                        {name && (
                          <span className="bops__details__description__name">
                            {name}
                          </span>
                        )}
                      </li>
                    )}
                    {this.isColor(color) && (
                      <li>
                        {color.name && (
                          <span className="bops__details__description__color">
                            Color: {color.name}
                          </span>
                        )}
                      </li>
                    )}
                    {size.name && size.name.length > 0 && (
                      <li>
                        {size.name && (
                          <span className="bops__details__description__size">
                            Size: {size.name}
                          </span>
                        )}
                      </li>
                    )}
                    <li>
                      <span className="bops__details__description__quantity">
                        Quantity: {quantity}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              {this.retrunStoresWithoutSearch(
                showStoresWithoutSearch,
                bopsCurbsideToggle,
              )}
            </div>
            <div
              className="bops__search__summary grid-100 tablet-grid-100 mobile-grid-100 bops-spacing"
              aria-live="polite"
            >
              {showStoresFlag ? this.getStoresInfo() : null}
            </div>
            <div className="bops__search__results grid-100 tablet-grid-100 mobile-grid-100">
              {showStoresFlag && storesInfo.length !== 0 && (
                <BopsStoreSearchResults
                  stores={storesInfo}
                  product={product}
                  selectedSku={selectedSku}
                  skuMediaUrl={skuMediaUrl}
                  defaultStoreValues={defaultStoreValues}
                  bopsCurbsideToggle={bopsCurbsideToggle}
                />
              )}
            </div>
          </div>
        </div>
      );
    }
    return markup;
  }
}

const mapStateToProps = state => ({
  stores: state.productCatalog.stores,
  showStoresWithoutSearch: state.toggles.PDP_SHOW_STORES_WITHOUT_SEARCH,
  defaultStoreValues: state.productCatalog.defaultStoreValues,
  bopsCurbsideToggle: state.toggles.BOPS_CURBSIDE,
});
const mapDispatchToProps = { closeModal, getStores, clearStores };
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbBopsModal);
