import isEmpty from 'lodash/isEmpty';

const updateWithAssets = (array, assets) => {
  array.forEach(item => {
    if (item.nodeType === 'embedded-asset-block') {
      item.data.target.fields = assets[item.data.target.sys.id].fields;
    }
    if (item.content && item.content.length > 0) {
      updateWithAssets(item.content, assets);
    }
  });
};

export const parseCopyData = data => {
  const assets = (data.includes['Asset'] || []).reduce((acc, field) => {
    acc[field.id] = field;
    return acc;
  }, {});
  return data.includes['Entry']
    .map(item => item.fields)
    .reduce((acc, field) => {
      if (field.id) {
        if (field.copy) {
          if (field.copy.content) {
            updateWithAssets(field.copy.content, assets);
          }
          acc[field.id] = field.copy;
        } else {
          acc[field.id] = field;
        }
      } else if (field.name) {
        acc[field.name] = field;
      } else if (field.componentName) {
        acc[field.componentName] = field;
      }
      return acc;
    }, {});
};

export const replaceContentVariable = (content, variable, value) => {
  return isEmpty(content)
    ? {}
    : {
        ...content,
        content: content.content.map(item => {
          return {
            ...item,
            content: item.content.map(i => {
              return {
                ...i,
                value: i.value.replace(variable, value),
              };
            }),
          };
        }),
      };
};
