import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { isLoadingFinished } from '@bgo-ui/common/client/utilities/utilities-api';
import {
  PAGE_ID_CHECK_YOUR_EMAIL,
  setPageId,
} from '@bgo-ui/common/client/common/actions/actions-page';
import { resolvedCheckYourEmailPage } from '../../../actions';
import Copy from '../../../../common/Copy/Copy';

import './CheckYourEmail.scss';
import { replaceContentVariable } from '../../../../../utilities/copy-helpers';

const CheckYourEmail = ({
  email,
  loginContent,
  isContentLoaded,
  setPageId,
  resolvedCheckYourEmailPage,
}) => {
  const content = get(loginContent, 'USERLOGIN-DESKTOP-CHECK-YOUR-EMAIL', {});
  const contentWithEmail = replaceContentVariable(content, '__email__', email);

  useEffect(() => {
    setPageId(PAGE_ID_CHECK_YOUR_EMAIL);
    resolvedCheckYourEmailPage();
  }, []);

  return (
    <div className="check-your-email">
      <Copy
        content={contentWithEmail}
        loaded={isContentLoaded}
        fallback={(
          <React.Fragment>
            <h3>Check your email</h3>
            <p>
              An email has been sent to <strong>{email}</strong> with
              instructions to reset your password.
            </p>
            <p>
              Note that it can take up to 5 minutes to receive your e-mail. For
              security purposes, we will remove the credit card information on
              your account.
            </p>
          </React.Fragment>
        )}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  loginContent: get(state, 'login.loginContent', {}),
  isContentLoaded: isLoadingFinished(state, 'login_content'),
});

const mapDispatchToProps = {
  setPageId,
  resolvedCheckYourEmailPage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CheckYourEmail);
