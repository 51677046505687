import React from 'react';
import { bool, object, string } from 'prop-types';
import get from 'lodash/get';
import merge from 'lodash/merge';
import Layout from './layout';
import { defaultTrackingTags } from '../../constants/constants';
import { getTags } from '../utils';

/**
 * @function LayoutFrame
 * @param {Object} props.cmsContentFields
 * @param {Boolean} props.isMobilePhone
 * @param {Boolean} props.isNewHomePage
 * @param {Object} props.parentTrackingTags
 * @param {String} props.placement
 * @param {Boolean} props.sessionExpired
 * @param {Boolean} props.validPromo
 * @returns {<Layout>}
 */
const LayoutFrame = ({
  cmsContentFields,
  isMobilePhone,
  isNewHomePage,
  parentTrackingTags,
  placement,
  sessionExpired,
  validPromo,
}) => {
  const subLayouts = get(cmsContentFields, 'l1Layouts', []);
  const trackingTags = get(cmsContentFields, 'trackingTags', []);
  const currentTags = getTags(trackingTags);
  const mergedTrackingTags = merge({}, parentTrackingTags, currentTags);

  return (
    <div className="cms-frame">
      {subLayouts.map(item => {
        const pagePlacement = get(item, 'fields.placement');

        return pagePlacement && pagePlacement === placement ? (
          <Layout
            cmsLayout={item}
            key={item.sys.id}
            isMobilePhone={isMobilePhone}
            isNewHomePage={isNewHomePage}
            parentTrackingTags={mergedTrackingTags}
            sessionExpired={sessionExpired}
            validPromo={validPromo}
          />
        ) : null;
      })}
    </div>
  );
};

LayoutFrame.defaultProps = {
  parentTrackingTags: defaultTrackingTags,
};

LayoutFrame.propTypes = {
  cmsContentFields: object,
  isMobilePhone: bool,
  isNewHomePage: bool,
  placement: string,
  sessionExpired: bool,
  validPromo: bool,
};

export default LayoutFrame;
