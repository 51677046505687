import React from 'react';
import InCircleLogoBgCreditCard from 'assets/images/InCircleLogoBgCreditCard.svg';

const IncircleLogo = () => {
  return (
    <section className="incircle-logo-section content-section">
      <img
        className="incircle-logo"
        src={InCircleLogoBgCreditCard}
        alt="InCircle"
        alr="InCircle"
      />
    </section>
  );
};

export default IncircleLogo;
