import {
  LOADING_BG360_CONTENT,
  RESOLVED_BG360_CONTENT,
  REJECTED_BG360_CONTENT,
  LOADING_SIGN_UP,
  RESOLVED_SIGN_UP,
  REJECTED_SIGN_UP,
} from './actions';
import { loadingStatus } from './constants';

const defaultState = {
  contentLoadingStatus: null,
  signUpLoadingStatus: null,
  content: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOADING_BG360_CONTENT: {
      return { ...state, contentLoadingStatus: loadingStatus.loading };
    }
    case RESOLVED_BG360_CONTENT: {
      return {
        ...state,
        contentLoadingStatus: loadingStatus.resolved,
        content: action.payload,
      };
    }
    case REJECTED_BG360_CONTENT: {
      return { ...state, contentLoadingStatus: loadingStatus.rejected };
    }
    case LOADING_SIGN_UP: {
      return { ...state, signUpLoadingStatus: loadingStatus.loading };
    }
    case RESOLVED_SIGN_UP: {
      return { ...state, signUpLoadingStatus: loadingStatus.resolved };
    }
    case REJECTED_SIGN_UP: {
      return { ...state, signUpLoadingStatus: loadingStatus.rejected };
    }
    default: {
      return state;
    }
  }
};
