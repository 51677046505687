import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hideSkipToMainComponent } from 'client/common/actions/actions-page';
import './skipToContent.scss';

class SkipToContent extends Component {

  constructor(props) {
    super(props);
    this.scrollToPos = this.scrollToPos.bind(this);
  }

  scrollToPos() {
    document.getElementById('skipToContent').focus();
    window.scrollTo(0, document.getElementById('skipToContent').offsetTop - 5);
    this.props.hideSkipToMainComponent();
  }

  render() {
    return (
    !(this.props.templatetype === 'DesignerIndex') &&
    <a
      className={this.props.focusSkipToMain ? 'skip-content skip-content-focus' : 'skip-content'}
      id="skipToMainContent"
      href="#mainContent"
      onClick={() => { this.scrollToPos(); }}
    >Skip To Main Content
    </a>
    );
  }
}

const mapStateToProps = (state) => ({
  focusSkipToMain: state.page.focusSkipToMain,
  templatetype: state.templates.templateType,
});

const mapDispatchToProps = ({
  hideSkipToMainComponent
});

export default connect(mapStateToProps, mapDispatchToProps)(SkipToContent);
