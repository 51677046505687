import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { shouldLoad } from 'universal/http-client';

import { getCmsProducts } from '../../actions/get-cms-products';
import ProductTile from './productTile';

import './productTile.scss';

export class DumbCmsProducts extends Component {
  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    const { cmsProductsApi, productIds, contentId } = this.props;
    if (shouldLoad(cmsProductsApi)) {
      this.props.dispatch(getCmsProducts(productIds, contentId));
    }
  }

  combineProducts(cmsProducts, childProducts) {
    return cmsProducts.map(product =>
      /* eslint-disable max-len */
      product.isGroup && product.childProductIds
        ? childProducts.find(
            childProduct => childProduct.id === product.childProductIds[0],
          ) || product
        : product,
    );
  }

  renderProducts(combinedProducts, is2x2, isMobilePhone) {
    return combinedProducts.map(product => {
      return (
        <ProductTile
          id={product.id}
          designer={product.designerName}
          name={product.name}
          price={product.price}
          imageUrl={product.imageUrl}
          url={product.url}
          promotions={product.promotions}
          displayable={product.displayable}
          is2x2={is2x2}
          isMobilePhone={isMobilePhone}
        />
      );
    });
  }

  render() {
    const {
      is2x2 = false,
      isMobilePhone = false,
      cmsProducts = [],
      childProducts = [],
    } = this.props;
    let filteredProducts = [];
    if (!isEmpty(childProducts)) {
      filteredProducts = this.combineProducts(cmsProducts, childProducts);
    }
    const combinedProducts = !isEmpty(filteredProducts)
      ? filteredProducts
      : cmsProducts;
    return (
      <div className="product-list">
        {!isEmpty(combinedProducts) &&
          this.renderProducts(combinedProducts, is2x2, isMobilePhone)}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isMobilePhone: get(state, 'device.isMobilePhone'),
  cmsProductsApi: state.api[`cms_products_${ownProps.contentId}`.toLowerCase()],
  cmsProducts: state.cms.products && state.cms.products[ownProps.contentId],
  childProducts:
    state.cms.childProducts && state.cms.childProducts[ownProps.contentId],
});

export default connect(mapStateToProps)(DumbCmsProducts);
