import {
  getLocaleUrl,
  getCurrencyCode,
  stripHtml,
} from 'client-utils/utilities-page';
import { getActiveAbTests } from 'client-utils/utilities-abtest';
import { safelyParse } from 'universal/utilities-cookies';
import find from 'lodash/find';
import { getTealiumEnvironment } from 'server-utils/utilities-tealium';
import { updateMiniCartItemCount } from 'bgo-common/components/Header/MiniCart/miniCart-actions';
import { UNITED_STATES } from 'storefront/components/constants';
import { openModal } from 'shared/components/Modal/actions';
import { countryCurrencyData } from '@bgo-ui/common/components/CountrySelector/CountryChooser/countryCurrencyData';
import { replacePlusWithSpace } from '../../../../universal/utilities-string';

export const types = {
  SET_USER: 'SET_USER',
  SET_SESSION: 'SET_SESSION',
  LOSE_FOCUS: 'LOSE_FOCUS',
  PUT_IN_FOCUS: 'PUT_IN_FOCUS',
  SET_LOCALE: 'SET_LOCALE',
  REQUEST_RECEIVED: 'REQUEST_RECEIVED',
  SET_REVISIT_USER: 'SET_REVISIT_USER',
  SET_LOYALTY_USER: 'SET_LOYALTY_USER',
  PLA_COOKIE_RECEIVED: 'PLA_COOKIE_RECEIVED',
  COOKIES_RECEIVED: 'COOKIES_RECEIVED',
  PARSE_PERSONALIZE: 'PARSE_PERSONALIZE',
  AB_TEST_SESSION_DATA_RECEIVED: 'AB_TEST_SESSION_DATA_RECEIVED',
};

export function loseFocus(currentComponentInFocus) {
  return dispatch =>
    dispatch({ type: types.LOSE_FOCUS, currentComponentInFocus });
}

export function putComponentInFocus(componentInFocus) {
  return dispatch => dispatch({ type: types.PUT_IN_FOCUS, componentInFocus });
}

const getSessionFromRequest = request => {
  const whitelist = [
    'JSESSIONID',
    'DYN_USER_ID',
    'DYN_USER_CONFIRM',
    'W2A',
    'WID',
    'TLTSID',
    'dt_personalize_data',
    'dt_personalize_preview',
    '_cplid',
    '_optuid',
    'AGA',
    'at_preview_token',
    'at_preview_index',
    'at_preview_listed_activities_only',
    'at_preview_evaluate_as_true_audience_ids',
    '__ruid',
    'guestUserid',
    'force-opty',
  ];
  const { 'user-agent': userAgent = '', host, referer } = request.headers;
  const url = `${request.protocol}://${host}${request.originalUrl}`;

  return whitelist.reduce(
    (session, key) => {
      session[key] = stripHtml(request.cookies[key]);
      return session;
    },
    { url, referer, userAgent },
  );
};

const getUserData = (
  tmsData,
  profileData,
  countryCodeByAkamai = '',
  ucaProfileDataCookie,
  toggles,
) => ({
  ucid:
    ucaProfileDataCookie.universal_customer_id || tmsData.universal_customer_id,
  rid: ucaProfileDataCookie.countryPreference || tmsData.country_code,
  email: ucaProfileDataCookie.customer_email || tmsData.customer_email,
  name: toggles?.DISABLE_ATG_CALLS
    ? ucaProfileDataCookie.firstName
    : replacePlusWithSpace(profileData.firstName),
  securityStatus: toggles?.DISABLE_ATG_CALLS
    ? ucaProfileDataCookie.securityStatus
    : profileData.securityStatus,
  countryCodeByAkamai,
});

const getLocaleFromProfileData = (localeData, toggles) => ({
  countryCode: toggles.INTERNATIONAL ? localeData.countryPreference : 'US',
  currencyCode: toggles.INTERNATIONAL ? localeData.currencyPreference : 'USD',
  localeUrl: toggles.INTERNATIONAL
    ? getLocaleUrl(localeData.countryPreference)
    : '/en-us',
  currentCountryCode: localeData.countryPreference,
});

const dispatchUserProfile = (
  request,
  tmsData,
  profileData,
  dispatch,
  countryCodeByAkamai = '',
  ucaProfileDataCookie,
  toggles,
) => {
  dispatch({
    type: types.SET_USER,
    user: getUserData(
      tmsData,
      profileData,
      countryCodeByAkamai,
      ucaProfileDataCookie,
      toggles,
    ),
  });
  dispatch({
    type: types.SET_SESSION,
    session: getSessionFromRequest(request),
  });
  dispatch({
    type: types.SET_LOCALE,
    locale: getLocaleFromProfileData(profileData, toggles),
  });
  if (profileData.cartItemCount >= 0) {
    updateMiniCartItemCount(profileData.cartItemCount)(dispatch);
  }
};
// eslint-disable-next-line no-unused-vars
const replaceLocalePath = (url, localePath) => {
  let replacedUrl = /^\/en-[a-zA-Z]{2}$/.test(url) ? `${url}/` : url;
  replacedUrl = replacedUrl.replace(/^\/en-.*?\//, '/');
  return localePath === '/en-us' ? replacedUrl : localePath + replacedUrl;
};

const getCountryCodeByAkamai = headers => {
  const regex = /country_code=([A-Z]{2})/;
  const { 'akamai-edgescape': akamaiEdgescape } = headers;
  const akamaiEdgescapeRegex = regex.exec(akamaiEdgescape);
  return akamaiEdgescapeRegex && akamaiEdgescapeRegex.length > 1
    ? akamaiEdgescapeRegex[1]
    : '';
};

const getCountryCode = (profileData, headers) => {
  if (!profileData.countryPreference) {
    const regex = /country_code=([A-Z]{2})/;
    const { 'akamai-edgescape': akamaiEdgescape } = headers;
    const akamaiEdgescapeRegex = regex.exec(akamaiEdgescape);
    return akamaiEdgescapeRegex && akamaiEdgescapeRegex.length > 1
      ? akamaiEdgescapeRegex[1]
      : '';
  }
  return profileData.countryPreference;
};
// eslint-disable-next-line no-unused-vars
const urlContainsLocalePath = (url, localePath) => {
  if (localePath !== '/en-us') {
    return url && url.startsWith(`${localePath}/`);
  }
  return url && !url.startsWith('/en-');
};

const dispatchRevisitUser = (revisitUserCookie, dispatch) => {
  if (revisitUserCookie) {
    dispatch({
      type: types.SET_REVISIT_USER,
      revisitUser: revisitUserCookie,
    });
  }
};

export const setRevisitUser = revisitUserValue => dispatch =>
  dispatchRevisitUser(revisitUserValue, dispatch);

export const setLoyaltyUser = loyaltyUserValue => dispatch => {
  dispatch({
    type: types.SET_LOYALTY_USER,
    loyaltyUser: loyaltyUserValue,
  });
};

export const openWelcomeMat = (data, wcMatProps = {}) => {
  return dispatch => {
    dispatch(
      openModal({
        type: 'WelcomeMatPopup',
        ...data,
        url: '/category/intl/mat/r_welcome_mat.html',
        props: { ...wcMatProps },
        className: 'welcome-mat',
      }),
    );
  };
};

export const requestReceived = (
  request,
  response,
  tmsData,
  toggles,
  internalIps,
  abTestIds = {},
) => dispatch => {
  const profileData = safelyParse(request.cookies.profile_data);
  const ucaProfileDataCookie =
    safelyParse(request.cookies.ucaProfileData) || {};
  const countryCode =
    getCountryCode(profileData, request.headers) || UNITED_STATES;
  // eslint-disable-next-line no-unused-vars
  const localeUrl = getLocaleUrl(countryCode);
  const countryCodeByAkamai =
    getCountryCodeByAkamai(request.headers) || UNITED_STATES;

  const userEmail = ucaProfileDataCookie?.customer_email || '';
  let analyticsProfileData = {};
  // Commented out as no locale redirection is required for BG
  // Enabling as locale redirection needed for International
  if (toggles.INTERNATIONAL) {
    if (!urlContainsLocalePath(request.url, localeUrl)) {
      response.redirect(
        302,
        replaceLocalePath(request.url, getLocaleUrl(countryCode)),
      );
      return;
    }
  }

  if (toggles?.DT_COOKIES) {
    const guestId = request.cookies['guestID'] || '';
    const getServerDateTime = () => new Date().getTime().toString();
    const getCountryName = countryCode => {
      return find(
        countryCurrencyData,
        country => country.countryCode === countryCode,
      ).countryName;
    };
    analyticsProfileData = {
      account_registration: 'false',
      country_code: ucaProfileDataCookie?.countryPreference || 'US',
      customer_country: getCountryName(countryCodeByAkamai) || 'United States',
      order_currency_code: ucaProfileDataCookie?.currencyPreference || 'USD',
      profile_type: ucaProfileDataCookie?.profile_type || 'customer',
      customer_email: userEmail,
      customer_registered: ucaProfileDataCookie?.customer_registered
        ? 'true'
        : 'false',
      customer_status: userEmail ? 'Logged In' : 'Not recognized',
      logged_in_status: ucaProfileDataCookie?.logged_in_status
        ? 'true'
        : 'false',
      server_date_time: getServerDateTime(),
      universal_customer_id: ucaProfileDataCookie?.universal_customer_id || '',
      web_id: ucaProfileDataCookie?.universal_customer_id || guestId || '',
      site_environment: getTealiumEnvironment() || '',
    };
  }

  dispatchRevisitUser(request.cookies.revisitUser, dispatch, toggles);
  profileData.countryPreference = countryCode;
  profileData.currencyPreference =
    profileData.currencyPreference || getCurrencyCode(countryCode);
  dispatchUserProfile(
    request,
    tmsData,
    profileData,
    dispatch,
    countryCodeByAkamai,
    ucaProfileDataCookie,
    toggles,
  );
  dispatch({
    type: types.PLA_COOKIE_RECEIVED,
    payload: { ipla: request.headers.ipla },
  });
  dispatch({
    type: types.REQUEST_RECEIVED,
    payload: {
      request,
      tmsData,
      profileData,
      ucaProfileDataCookie,
      analyticsProfileData,
    },
  });
  dispatch({
    type: types.COOKIES_RECEIVED,
    cookies: request.cookies,
    abTestIds: getActiveAbTests(abTestIds),
  });
  dispatch({
    type: types.PARSE_PERSONALIZE,
    payload: { request, internalIps },
  });
  dispatch({
    type: types.AB_TEST_SESSION_DATA_RECEIVED,
    mboxCookie: request.cookies.mbox,
  });
};
