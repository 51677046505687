/* eslint-disable */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import ProductMediaCarousel from 'pdp/components/ProductPage/optimizationcomponents/ProductMediaTemplateD/productMediaCarousel';
import {
  getActiveMedia,
  getActiveMediaIndex,
} from 'pdp/components/ProductPage/selectors/getMedia';
import ProductDetails from 'pdp/components/ProductPage/optimizationcomponents/ProductDetails/productDetails';
import CheckoutOptionRenderer from 'pdp/components/ProductPage/optimizationcomponents/Checkout/checkoutOptionRenderer';

import { sourceQuickLook, resetQLProduct } from '../../../../actions'; // 'plp/ProductListPage/actions';
import CommonProductSummary from '../CommonProductSummary/commonProductSummary';
import './qlProductSummary.scss';

class QLProductSummary extends React.Component {
  constructor(...args) {
    super(...args);
    this.productMediaWrapperRef = React.createRef();
    this.sliderRef = React.createRef();
  }

  componentWillUnmount() {
    this.props.dispatch(resetQLProduct(this.props));
  }

  render() {
    const { ...commonProduct } = this.props;
    commonProduct.options = {
      ...commonProduct?.options,
      ...this.props.qlProductOptions,
    };
    const displayable = false;
    const activeMedia = getActiveMedia(
      commonProduct,
      true,
      this.props.isMediaMergeLogicDisabled,
    );
    const activeMediaIndex = getActiveMediaIndex(commonProduct);
    const departmentSettings =
      this.props.pdpConfigurator &&
      this.props.pdpConfigurator.department_settings &&
      this.props.pdpConfigurator.department_settings[
        (commonProduct?.departmentCode)
      ];

    return commonProduct?.isGroup ? (
      commonProduct?.childProducts && <RenderGroupProduct />
    ) : (
      <div id={commonProduct?.id} className="product-component template-a">
        <div className="product-wrapper">
          <div
            ref={this.productMediaWrapperRef}
            className="product-media-wrapper"
          >
            <ProductMediaCarousel
              productId={commonProduct?.id}
              showSoldOutOverlay={!displayable}
              name={unescape(commonProduct?.name)}
              brand={unescape(get(commonProduct, 'designer.name', ''))}
              media={activeMedia}
              activeIndex={activeMediaIndex}
              displayOutfittingText
              isCloudinary={this.props.isCloudinary}
              isMediaMergeLogicDisabled={this.props.isMediaMergeLogicDisabled}
              setSliderRef={ref => {
                this.sliderRef = ref;
              }}
              isFromQL={true}
            />
          </div>
          <div className="product-details-wrapper">
            <div className="product-component--details">
              <div className="product-detail-wrapper">
                <ProductDetails product={commonProduct} />
                <CheckoutOptionRenderer
                  product={commonProduct}
                  boss={this.props.boss}
                  hasQuantity={departmentSettings?.quantity}
                  isFromQL={true}
                />
                <p className="product-details-link">
                  <a href={commonProduct?.details?.canonicalUrl}>
                    View Full Product Details
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  qlProductOptions: state?.productCatalog?.product?.options,
  product: state?.productCatalog?.product,
  narvarTest: state.abTests.narvarABTest,
  boss: get(state, 'productCatalog.boss', {}),
  isCloudinary: state.toggles.PHASE2_PLP_CLOUDINARY,
  enableR2feature: state.toggles.BG_PHASE_2_RELEASE_2,
  isMediaMergeLogicDisabled: get(
    state,
    'toggles.DISABLE_PDP_MEDIA_MERGE_LOGIC',
    false,
  ),
  pdpConfigurator: get(state, 'productCatalog.pdpConfigurator', {}),
  isColorTextDropdownEnabled: state.toggles.DROPDOWN_TEXT_BEAUTY,
});

export default connect(mapStateToProps)(QLProductSummary);
