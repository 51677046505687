import React from 'react';
import get from 'lodash/get';
import merge from 'lodash/merge';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { defaultTrackingTags } from '../../constants/constants';
import {
  renderMultiDeviceText,
  addTrackingParam,
  getTags,
  getTrackingTagStr,
} from '../utils';
import ContentItem from '../contentItem/contentItem';
import './richText.scss';
import EntryHyperlink from './entryHyperlink';

const CN = 'rich-text';
const CN_LIST = 'list';
const ROMAN_NUMERALS_TYPE = 'Roman Numerals';
/**
 * @function DumbRichText
 * @param {Object} props.parentTrackingTags
 * @returns {React.ReactElement}
 */

export function DumbRichText({
  cmsContentItem = {},
  isMobilePhone,
  isDesktop,
  hoverToggle,
  stsCMSStylingEnabled,
  parentTrackingTags,
  videoAssetToggle,
  h1Toggle,
}) {
  const fields = get(cmsContentItem, 'fields', {});

  const {
    backgroundColor,
    buttonStyle,
    desktopText,
    mobileText,
    tabletText,
    textColor,
    textJustification,
    textPadding,
    buttonBackgroundColor,
    trackingTags,
    listType,
    listTypeMobile,
  } = fields;

  const styles = {
    backgroundColor,
    color: textColor,
    padding: textPadding && `${textPadding}px`,
    textAlign: textJustification,
  };

  const type = isMobilePhone ? listTypeMobile : listType;
  const listTypeStyles =
    type === ROMAN_NUMERALS_TYPE ? `${CN_LIST}-roman` : CN_LIST;
  const componentClassNames = classNames(
    CN,
    videoAssetToggle ? 'video-richtext' : 'noVideo-richtext',
    {
      [`${CN}--${buttonStyle}`]: buttonStyle,
      'enable-hovered-view': hoverToggle && !isDesktop,
      'missing-tag': h1Toggle,
      [buttonBackgroundColor]:
        stsCMSStylingEnabled &&
        (buttonBackgroundColor && buttonStyle === 'button'),
      listTypeStyles,
      [`align-${textJustification?.toLowerCase()}`]: textJustification,
    },
  );

  // Set up Tracking Tags
  const currentTags = getTags(trackingTags);
  const mergedTrackingTags = merge({}, parentTrackingTags, currentTags);

  const options = {
    renderNode: {
      [INLINES.ENTRY_HYPERLINK]: (node, children) => (
        <EntryHyperlink node={node}>{children}</EntryHyperlink>
      ),
      [INLINES.HYPERLINK]: (node, children) => {
        // Set up Tracking Tags
        const trackingTagStr = getTrackingTagStr(mergedTrackingTags);
        const linkUrl = addTrackingParam(node?.data?.uri, trackingTagStr);
        return <a href={linkUrl}>{children}</a>;
      },
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        const embeddedEntry = node?.data?.target;
        return (
          <ContentItem
            parentTrackingTags={mergedTrackingTags}
            cmsContentItem={embeddedEntry}
          />
        );
      },
    },
  };

  return renderMultiDeviceText(
    ({ children }) => (
      <div className={componentClassNames} style={styles}>
        {children}
      </div>
    ),
    {
      isMobilePhone,
      desktopText,
      tabletText,
      mobileText,
      transformText: content => documentToReactComponents(content, options),
    },
  );
}

DumbRichText.defaultProps = {
  parentTrackingTags: defaultTrackingTags,
};

const mapStateToProps = state => ({
  isMobilePhone: get(state, 'device.isMobilePhone', false),
  isDesktop: get(state, 'device.isDesktop', false),
  hoverToggle: state.toggles.HOVER_BG_IMAGE,
  stsCMSStylingEnabled: state.toggles.STS_DARK_LIGHT_BUTTON,
  videoAssetToggle: state.toggles.VIDEO_ASSET_MULTI_BUTTONS,
  h1Toggle: state.toggles.PLP_MISSING_H1,
});

export default connect(mapStateToProps)(DumbRichText);
