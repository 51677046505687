import { openModal } from 'shared/components/Modal/actions';
import { getSSMValue } from 'universal/aws/ssm';
import httpWithLogging from 'universal/http-client';
import { modalContentType, defaultApiTimeout } from './constants';

export const LOADING_BG360_CONTENT = 'LOADING_BG360_CONTENT';
export const RESOLVED_BG360_CONTENT = 'RESOLVED_BG360_CONTENT';
export const REJECTED_BG360_CONTENT = 'REJECTED_BG360_CONTENT';

export const LOADING_SIGN_UP = 'LOADING_SIGN_UP';
export const RESOLVED_SIGN_UP = 'RESOLVED_SIGN_UP';
export const REJECTED_SIGN_UP = 'REJECTED_SIGN_UP';

export const RESOLVED_BG360_PAGE = 'RESOLVED_BG3600_PAGE';

export const loadContent = () => {
  return dispatch => {
    dispatch({ type: LOADING_BG360_CONTENT });
    return getSSMValue(NMConfig.SSM_BG_360_CONTENT_KEY).then(
      value => {
        try {
          const payload = JSON.parse(value);
          dispatch({ type: RESOLVED_BG360_CONTENT, payload });
        } catch (e) {
          dispatch({ type: REJECTED_BG360_CONTENT });
        }
      },
      () => dispatch({ type: REJECTED_BG360_CONTENT }),
    );
  };
};

export const openSignUpModal = () => {
  return dispatch =>
    dispatch(
      openModal({
        type: 'Bg360Modal',
        className: 'bg-360-modal',
        contentType: modalContentType.signUp,
      }),
    );
};

export const openWelcomeModal = () => {
  return dispatch =>
    dispatch(
      openModal({
        type: 'Bg360Modal',
        className: 'bg-360-modal',
        contentType: modalContentType.welcome,
      }),
    );
};

export function signUp(data) {
  return (dispatch, getState) => {
    const requestApi = httpWithLogging(getState(), defaultApiTimeout);

    dispatch({ type: LOADING_SIGN_UP });
    if (data.month && !data.day) {
      data.month = '';
    } else if (data.day && !data.month) {
      data.day = '';
    }
    return requestApi
      .post(NMConfig.API_BG360_SIGNUP, data)
      .then(
        () => dispatch({ type: RESOLVED_SIGN_UP }),
        () => dispatch({ type: REJECTED_SIGN_UP }),
      );
  };
}

const handleLoad = (dispatch, pageType) => {
  dispatch({ type: pageType });
  removeEventListener('load', handleLoad);
};

export function resolvedBG360page() {
  return (dispatch, getState) => {
    const state = getState();
    const isDomLoadComplete = state?.page?.isDomLoadComplete || {};

    if (isDomLoadComplete) {
      return dispatch({ type: RESOLVED_BG360_PAGE });
    } else {
      addEventListener(
        'load',
        handleLoad.bind(null, dispatch, RESOLVED_BG360_PAGE),
      );
    }
    return undefined;
  };
}
