import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { withRouter } from 'react-router';
import get from 'lodash/get';
import {
  window,
  addEventListener,
  removeEventListener,
} from 'window-or-global';
import { sanitize } from 'client-utils/utilities-html-sanitizer';
import renderHTML from 'react-render-html';
import { Helmet } from 'react-helmet';
import isEmpty from 'lodash/isEmpty';
import reactCookie from 'react-cookie';
import SkipToContent from 'storefront/components/SkipToContent/skipToContent';
import Header from '@bgo-ui/common/components/Header/header';
import Navigation from '@bgo-ui/common/components/Navigation/navigation';
import Loadable from 'react-loadable';
import ScreenReaderAnnouncer from '@bgo-ui/common/components/ScreenReaderAlert/screenReaderAnnouncer';
import EcmPreviewControl from 'ecm/components/EcmPreviewControl/ecmPreviewControl';
import IdleTimerWrapper from '@bgo-ui/common/components/Util/IdleTimerWrapper';
import EcmGet, { ECMSlots } from 'ecm/components/EcmGet';
import { loseFocus } from 'shared/components/App/app-actions';
import OldFooter from 'bgo-common/components/Footer/footer';
import { Footer } from '@bgo-ui-resources/bgo-component-library';
import PromoCarousel from 'storefront/components/PromoCarousel/promoCarousel';
import Toggle from 'shared/components/Toggle/toggle';
import Spinner from 'shared/components/Spinner/simpleSpinner';
import {
  PAGE_ID_HOME,
  PAGE_ID_PLP,
  PAGE_ID_SRP,
  PAGE_ID_PDP,
  PAGE_ID_CAT_TMPL,
  domLoadCompleted,
  PAGE_ID_CONF,
  PAGE_ID_PLA,
} from 'shared/actions/actions-page';
import ContentGlobal from 'bgo-common/client/cms/components/contentGlobal/contentGlobal';
import DownloadappBanner from '@bgo-ui/common/components/DownloadappBanner/banner';
import { configureAmplify } from '@bgo-ui/common/client/utilities/utilities-amplify';
import { createCookiesFromAbTests } from 'client-utils/utilities-abtest';
import {
  onModalAnchorClick,
  getHashedMailAddress,
} from 'shared/components/App/utilities';
import NavSliderContianer from '@bgo-ui/common/components/Navigation/NavSlider/navSliderContainer';
import Button from '@bgo-ui/common/client/common/components/Button/button';
import { ECMParams } from 'ecm/constants';
import './unsubscribe.scss';
import { openSignInModal } from 'bgo-ui-home/src/client/components/login/actions';
import { getEmailFromCustomerKey } from '../../actions';

const Modal = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'modal' */ 'shared/components/Modal/modal'),
  loading: () => false,
});

class Unsubscribe extends Component {
  constructor() {
    super();
    this.state = {
      queryParams: {},
      isLoggedIn: null,
    };

    this.handleModalAnchorClick = this.handleModalAnchorClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { location } = this.props;

    if (!isEmpty(get(location, `query.${ECMParams.PARAM_LAUNCH}`, ''))) {
      reactCookie.save(
        ECMParams.PARAM_LAUNCH,
        get(location, `query.${ECMParams.PARAM_LAUNCH}`),
        { path: '/' },
      );
    }
    this.handleClickOutsideFocus = this.handleClickOutsideFocus.bind(this);
    if (this.props.haveAppSetDomLoadComplete) {
      this.handleLoad = this.handleLoad.bind(this);
    }
  }

  async componentDidMount() {
    const { location } = this.props;

    // Parse query parameters
    const queryString = location.search;
    const params = new URLSearchParams(queryString);

    const queryParams = {};
    params.forEach((value, key) => {
      queryParams[key] = value;
    });

    // Set queryParams in state
    this.setState({ queryParams }, async () => {
      // Callback function to ensure state has been updated
      try {
        // Perform other async operations
        const isLoggedInUser =
          !!this.props.user.name &&
          this.props.user.securityStatus === 'Authenticated';
        this.setState({ isLoggedIn: isLoggedInUser });

        if (this.props.dtSignInEnabled) {
          configureAmplify(
            this.props.amplifyConfig,
            this.props.isNewCartCheckoutEnabled,
            this.props.disableATGToggle,
          );
        }

        // Add event listeners
        addEventListener('click', this.handleModalAnchorClick);
        if (this.props.haveAppSetDomLoadComplete) {
          addEventListener('load', this.handleLoad);
        }

        // Handle A/B test cookies if needed
        if (this.props.useInternalAbTestService) {
          createCookiesFromAbTests(this.props.abTestResults);
        }

        // Await asynchronous API call
        if (this.props.bgoCommunicationPref) {
          await this.apiCall();
        }

        // Record page hit
        this.recordPageHit();

        // Log queryParams after all async operations
      } catch (error) {
        console.error('Error during componentDidMount:', error);
      }
    });
  }

  async apiCall() {
    const data = {
      ck: this.state.queryParams.CustomerKey,
    };
    try {
      this.props.dispatch(
        getEmailFromCustomerKey({ data, isLoggedIn: this.state.isLoggedIn }),
      );
    } catch (error) {
      console.error('Error making the API call:', error);
    }
  }

  componentWillUnmount() {
    removeEventListener('click', this.handleModalAnchorClick);
  }

  handleModalAnchorClick(e) {
    onModalAnchorClick(e, this.props);
  }

  handleClickOutsideFocus(e, currentComponentInFocus) {
    const component = this.container.querySelector(currentComponentInFocus);
    if (component && !component.contains(e.target)) {
      this.props.dispatch(loseFocus(currentComponentInFocus));
    }
  }

  handleLoad() {
    this.props.dispatch(domLoadCompleted());
    removeEventListener('load', this.handleLoad);
  }

  getCookie(cname) {
    const name = `${cname}=`;
    if (window?.document?.cookie.length > 0) {
      const ca = window?.document?.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        const c = this.myTrim(ca[i]);
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
    }
    return null;
  }

  myTrim(text) {
    if (typeof text !== 'undefined' && text !== null) {
      return typeof text.trim === 'function'
        ? text.trim()
        : text.replace(/^\s+|\s+$/gm, '');
    }
    return null;
  }

  generateIframeUrl() {
    const { emailid, countryCode, emailSignupLinksToEpsilon } = this.props;
    const getCookieWID = this.getCookie('WID');
    const customerKeyUrl = emailid
      ? `?CustomerKey=${getHashedMailAddress(emailid)}`
      : '';

    let iframeUrl = '';

    if (!emailSignupLinksToEpsilon) {
      iframeUrl =
        emailid !== ''
          ? `https://ats.eccmp.com/ats/show.aspx?cr=1049&fm=27&s_email=${emailid}&s_country=${countryCode}&s_profile_id=${getCookieWID}`
          : `https://ats.eccmp.com/ats/show.aspx?cr=1049&fm=32&country=${countryCode}`;
    } else {
      iframeUrl = `${NMConfig.EPSILON_LINK}Registration/${customerKeyUrl}`;
    }

    return iframeUrl;
  }

  render() {
    const {
      isSliderMenuVisible,
      componentInFocus,
      session = {},
      location = {},
      modal = {},
      pageId = '',
      assistanceToggle,
      storesToggle,
      sitemapToggle,
      showCCPALink,
      specialOffersToggle,
      isBgoUiResourceFooter,
      oneTrustScriptToggle,
      loyaltyToggle,
      loyaltyTLToggle,
      bgoCommunicationPref,
      isLoading,
    } = this.props;
    const styles = isSliderMenuVisible
      ? {
          height: '87vh',
          overflow: 'hidden',
          position: 'relative',
          top: 0,
          left: 0,
        }
      : {};
    const outsideClickHandler = componentInFocus
      ? e => this.handleClickOutsideFocus(e, componentInFocus)
      : undefined;
    const isFullWidth =
      this.props.forceFullWidth ||
      pageId === PAGE_ID_HOME ||
      pageId === PAGE_ID_CONF ||
      pageId === PAGE_ID_PLP ||
      pageId === PAGE_ID_PDP ||
      (this.props.showPla && pageId === PAGE_ID_PLA) ||
      (this.props.isSRPFullWidth && pageId === PAGE_ID_SRP) ||
      pageId === PAGE_ID_CAT_TMPL;
    const isConfPage = pageId === PAGE_ID_CONF;
    const isPlpPage = pageId === PAGE_ID_PLP;
    const useCMSTicker =
      this.props.isSiteTickerAvailable && !isEmpty(this.props.cmsGlobal);

    const redirectToHomePage = () => {
      window.location.href = 'http://bg.com';
    };

    const redirectToSpecialOfferPage = () => {
      window.location.href = '/services/SpecialOffer';
    };

    return (
      <div id="appContainer">
        <ScreenReaderAnnouncer />
        <IdleTimerWrapper />
        <div
          id="contentContainer"
          className="application-container"
          ref={ref => {
            this.container = ref;
          }}
          onClick={outsideClickHandler}
          onTouchEnd={outsideClickHandler}
          aria-hidden={modal.open || isSliderMenuVisible}
          tabIndex="-1"
        >
          <Helmet>
            {Boolean(this.props.canonical) && (
              <link rel="canonical" href={this.props.canonical} />
            )}
          </Helmet>
          <Helmet>
            {Boolean(this.props.metaSEOOverride) &&
              renderHTML(sanitize(this.props.metaSEOOverride))}
          </Helmet>

          <DownloadappBanner />
          <SkipToContent />
          {session.personalize && <EcmPreviewControl location={location} />}
          <div
            className={`grid-container full-bleed ${
              useCMSTicker ? 'cms-siteticker' : 'ecm-siteticker'
            }`}
          >
            {useCMSTicker ? (
              <ContentGlobal
                cmsContent={this.props.cmsGlobal}
                placement="SiteTicker"
              />
            ) : (
              <Toggle
                feature="ECM_SITETICKER"
                fallback={<PromoCarousel tabIndex={0} />}
              >
                <EcmGet ecmSlot={ECMSlots.SITE_TICKER} />
              </Toggle>
            )}
          </div>
          <Header />

          <Toggle
            feature="HIDE_MAIN_NAV"
            fallback={<Navigation router={this.props.router} />}
          >
            {false}
          </Toggle>
          <div
            className={classnames('grid-container full-width-container', {
              'mainContent-large grid-parent': isFullWidth,
              'plp-full-width': isPlpPage,
              'configurator-full-style': isConfPage,
            })}
            style={styles}
          >
            {isLoading && <Spinner />}
            {!isLoading && bgoCommunicationPref ? (
              <main role="main">
                <div className="unsubscribe">
                  <div className="title">Your changes have been saved.</div>
                  <Button
                    className="unsubscribe__button"
                    value="VISIT THE HOMEPAGE"
                    onClick={redirectToHomePage}
                  />
                  <div className="unsubscribe-description">
                    If you&apos;d like to re-subscribe, use the button below:
                  </div>
                  <Button
                    className="unsubscribe__button"
                    value="SUBSCRIBE"
                    onClick={redirectToSpecialOfferPage}
                  />
                </div>
              </main>
            ) : (
              <div className="not-found">Page not found</div>
            )}
          </div>
        </div>
        {!isBgoUiResourceFooter ? (
          <OldFooter isFullWidth showCCPALink={showCCPALink} />
        ) : (
          <Footer
            configData={{ ...this.props, isFullWidth }}
            toggles={{
              assistanceToggle,
              storesToggle,
              sitemapToggle,
              showCCPALink,
              specialOffersToggle,
              oneTrustScriptToggle,
              loyaltyToggle,
              loyaltyTLToggle,
            }}
            openSignInModal={() => this.props.dispatch(openSignInModal())}
          />
        )}
        <Modal />
        <NavSliderContianer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    emailid: get(state, 'emailId.email', ''),
    countryCode: get(state, 'locale.countryCode', 'US'),
    pageId: get(state, 'page.pageId', ''),
    canonical: state.page.canonical,
    brand: state.brand_name?.env,
    isLoading: state.myaccount.isLoadingUnsubscribe,
    metaSEOOverride: state.page.metaSEOOverride,
    isSliderMenuVisible: get(
      state,
      'navigation.navSlider.sliderMenuVisible',
      false,
    ),
    componentInFocus: get(state, 'app.componentInFocus', ''),
    modal: get(state, 'modal', {}),
    showPla: get(state, 'toggles.SHOW_PLA', false),
    isSRPFullWidth: get(state, 'toggles.SRP_FULL_WIDTH', false),
    haveAppSetDomLoadComplete: get(
      state,
      'toggles.HAVE_APP_SET_DOMLOADCOMPLETE',
      false,
    ),
    bgoCommunicationPref: get(state, 'toggles.BGO_COMMUNICATION_PREF', false),
    unsubscribedAuth: get(state, 'myaccount.unsubscribedAuth', false),
    unsubscribedUnAuth: get(state, 'myaccount.unsubscribedUnAuth', false),
    useInternalAbTestService: get(
      state,
      'toggles.USE_INTERNAL_ABTEST_SERVICE',
      false,
    ),
    abTestResults: get(state, 'abTests.abTestResults', {}),
    isFullWidth: get(state, 'toggles.HOMEPAGE_FULL_WIDTH', false),
    showCCPALink: get(state, 'toggles.BG_PHASE_2_CCPA_LINK', false),
    dtSignInEnabled: get(state, 'toggles.DT_SIGN_IN', false),
    amplifyConfig: get(state, 'amplifyConfig', {}),
    cmsGlobal: get(state, 'cms.global', []),
    isSiteTickerAvailable: get(state, 'toggles.CMS_SITE_TICKER', false),
    disableATGToggle: state?.toggles?.DISABLE_ATG_CALLS || false,
    assistanceToggle: state.toggles.NEW_ASSISTANCE_UI,
    user: state.user,
    storesToggle: state.toggles.NEW_STORES_UI,
    sitemapToggle: state.toggles.DT_SiteMap,
    specialOffersToggle: state.toggles.DT_SPECIAL_OFFERS,
    isBgoUiResourceFooter: state.toggles.RESOURCE_FOOTER,
    emailSignupLinksToEpsilon: get(state, 'toggles.EPSILON_EMAIL', false),
    storeAssistancePageEmail: get(
      state,
      'toggles.STORE_ASSISTANCE_EMAIL',
      false,
    ),
    oneTrustScriptToggle: get(state, 'toggles.ONE_TRUST_SCRIPT_BG', false),
    loyaltyToggle: state?.toggles?.LOYALTY || false,
    loyaltyTLToggle: state?.toggles?.BGO_LOYALTY_NEW || false,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Unsubscribe),
);
