import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import EcmSlider from '../EcmSlider';
import { ecmGetAdaptiveStyleBlock } from '../../utilities';

import './style.scss';

export class EcmCarousel extends Component {
  constructor() {
    super();
    this.state = {
      domReady: false,
    };
  }

  componentDidMount() {
    document.addEventListener('readystatechange', () => {
      if (document.readyState === 'complete') {
        this.setState({ domReady: true });
      }
    });
  }

  render() {
    const {
      dataObj,
      ecmSlot,
      enableAutoPlay,
      ecmCallToActionButtonWithTextToggle,
      improvePerformanceOfStyleToggle,
      extraSettings,
      customClass,
      showCaption,
      disablePlaceholder,
    } = this.props;

    const ecmStyle = get(ecmSlot, 'className', '');
    const ecmComponentId = get(dataObj, 'componentId', '');
    const ecmProperties = get(dataObj, 'properties', {});
    const ecmObjectStyle = `ecm-container__carousel__${ecmComponentId}`;
    const classes = classNames(
      'ecm-container__carousel',
      ecmStyle,
      ecmObjectStyle,
    );
    const adaptiveStyleBlock = ecmGetAdaptiveStyleBlock(
      ecmProperties,
      ecmObjectStyle,
    );
    let dots = false;
    if (
      get(dataObj, 'properties.dots') !== 'false' ||
      isEmpty(get(dataObj, 'properties.dots')) ||
      get(dataObj, 'properties.dots') === null
    ) {
      dots = true;
    }
    const speed =
      get(dataObj, 'properties.transitionSpeed') === null ||
      isEmpty(get(dataObj, 'properties.transitionSpeed'))
        ? 300
        : parseInt(get(dataObj, 'properties.transitionSpeed'), 10);
    const autoplaySpeed =
      get(dataObj, 'properties.playSpeed') === null ||
      isEmpty(get(dataObj, 'properties.playSpeed'))
        ? 3000
        : parseInt(get(dataObj, 'properties.playSpeed'), 10);
    const settings = {
      dots,
      speed,
      autoplay: enableAutoPlay,
      autoplaySpeed,
      ...extraSettings,
    };
    return (
      <Fragment>
        {dataObj.title && (
          <div className={`carousel-title  ${customClass}-carousel-title`}>
            {dataObj.title}
          </div>
        )}
        <div
          className={`grid-100 tablet-grid-100 mobile-grid-100 grid-parent ${customClass}`}
        >
          <div className={classes}>
            {adaptiveStyleBlock}
            <EcmSlider
              ecmCallToActionButtonWithTextToggle={
                ecmCallToActionButtonWithTextToggle
              }
              dataObj={dataObj}
              ecmSlot={ecmSlot}
              sliderSettings={settings}
              domReady={this.state.domReady}
              improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
              showCaption={showCaption}
              disablePlaceholder={disablePlaceholder}
              customClass={customClass}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  enableAutoPlay:
    get(state, 'page.location.query.ENABLE_AUTOPLAY', 'true') === 'true',
});

export default connect(mapStateToProps)(EcmCarousel);
