/* eslint-disable import/first */
/* eslint func-names: ["error", "never"] */

import './styles.scss';
import {
  mark,
  measure,
} from 'bgo-common/client/utilities/utilities-performance';
import React from 'react';
import get from 'lodash/get';
import Auth from '@aws-amplify/auth';
import { configureAmplify } from 'client-utils/utilities-amplify';
import getOrderStatus from 'bgo-common/client/common/components/Granify/Events/getOrderStatus/getOrderStatus';
import getFavoriteDesigners from 'bgo-common/client/common/components/Granify/Events/getFavoriteDesigners/getFavoriteDesigners';
import getPersonalizedPromotions from 'bgo-common/client/common/components/Granify/Events/getPersonalizedPromotions/getPersonalizedPromotions';
import { hydrate } from 'react-dom';
import Loadable from 'react-loadable';
import { Provider } from 'react-redux';
import { useRouterHistory, Router } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import createBrowserHistory from 'history/lib/createBrowserHistory';
import { parse, stringify } from 'qs';
import Store from 'bgo-common/client/Store';
import { createGuestId, isGuestValid } from 'bgo-common/client/guestauth';
import { isNewCartCheckoutEnabled } from 'client-utils/is-new-cart-checkout';

/* eslint-enable import/first */

export function run({ routeConfig, reducers }) {
  mark('client bundle startup');
  measure('client bundle startup');
  window &&
    window.addEventListener('load', () => {
      ['responseEnd', 'domInteractive', 'domComplete'].reduce((prev, curr) => {
        measure(`${prev} » ${curr}`, prev, curr);
        measure(curr, 'navigationStart', curr);
        return curr;
      }, 'navigationStart');
    });

  const node = document.getElementById('application');
  const element = document.getElementById('state');
  if (element) {
    const state = JSON.parse(element.textContent);
    const store = Store.createStore(reducers, state);

    const createAppHistory = useRouterHistory(createBrowserHistory);
    const appHistory = createAppHistory({
      parseQueryString: queryString => parse(queryString),
      stringifyQuery: queryObj => stringify(queryObj),
    });
    const history = syncHistoryWithStore(appHistory, store);

    window.store = store;
    const isDomestic =
      get(state, 'locale.countryCode') === 'US' ||
      get(state, 'locale.countryCode') === 'Unites_States';
    const disableATGToggle = get(state, 'toggles.DISABLE_ATG_CALLS', false);
    state &&
    state.toggles &&
    isDomestic &&
    state.toggles.GRANIFY_EVENT_ORDER_STATUS
      ? (window.getOrderStatus = function() {
          return getOrderStatus(state);
        })
      : null;

    state &&
    state.toggles &&
    isDomestic &&
    state.toggles.WKY_SEND_FAVORITE_DESIGNERS_TO_GRANIFY
      ? (window.getFavorites = () => getFavoriteDesigners(state))
      : null;

    state &&
    state.toggles &&
    isDomestic &&
    state.toggles.WKY_SEND_PERSONALIZED_PROMOS_TO_GRANIFY
      ? (window.getPersonalizedPromotions = () =>
          getPersonalizedPromotions(state))
      : null;

    if (state && state.toggles && state.toggles.GUEST_IDENTITY_DT) {
      configureAmplify(
        state.amplifyConfig,
        isNewCartCheckoutEnabled(state),
        disableATGToggle,
      );
      Auth.currentSession().catch(() => {
        isGuestValid(state).catch(e => {
          createGuestId(state);
          return e;
        });
      });
    }

    if (state && state.toggles && state.toggles.WHY_DID_YOU_UPDATE) {
      const { whyDidYouUpdate } = require('why-did-you-update');
      whyDidYouUpdate(React);
    }

    let routes = routeConfig.V0();
    if (state && state.toggles && state.toggles.MOVE_ABTESTS_TO_APP) {
      routes = routeConfig.V1(state.toggles.CRP);
    }

    window.main = () => {
      Loadable.preloadReady().then(() => {
        hydrate(
          <Provider store={store}>
            <Router history={history}>{routes}</Router>
          </Provider>,
          node,
        );
      });
    };
  } else {
    window.main = () => null;
  }
}
