export const types = {
  SHOW_SPINNER: 'SHOW_SPINNER',
  HIDE_SPINNER: 'HIDE_SPINNER',
};

export function showSpinner() {
  return dispatch => dispatch({ type: types.SHOW_SPINNER });
}

export function hideSpinner() {
  return dispatch => dispatch({ type: types.HIDE_SPINNER });
}
