import { getPDPImageZoomUrl } from 'bgo-common/components/Util/product-cloudinary';
import { removeBodyClass } from 'client-utils/bodyClass';
import { handleTabEventWith } from 'client-utils/handleTabbing';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { zoomProductImage } from 'pdp/components/ProductPage/actions';
import getProduct from 'pdp/components/ProductPage/selectors/getProduct';
import { NEXT_BUTTON, PREVIOUS_BUTTON } from 'pdp/constants';
import PhotoSwipe from 'photoswipe/dist/photoswipe';
import photoSwipeTheme from 'photoswipe/dist/photoswipe-ui-default';
import 'photoswipe/src/css/default-skin/default-skin.scss';
import 'photoswipe/src/css/main.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './zoomImage.scss';

export class DumbProductZoom extends Component {
  shouldComponentUpdate(nextProps) {
    const urls = this.props.images.map(img => img.src);
    const nextUrls = nextProps.images.map(img => img.src);
    return !isEqual(urls, nextUrls);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { currentIndex, images = [] } = nextProps;
    const rightArrow = document.querySelector('.pswp__button--arrow--right');
    const close = document.querySelector('.pswp__button--close');
    const leftArrow = document.querySelector('.pswp__button--arrow--left');

    if (currentIndex === 0) {
      handleTabEventWith(close, rightArrow);
      setTimeout(() => {
        leftArrow.focus();
      }, 500);
    }
    this.zoom(images, currentIndex);
  }

  zoom(orderedImages, index) {
    if (orderedImages.length === 0) {
      return;
    }
    this.gallery = new PhotoSwipe(this.pwsp, photoSwipeTheme, orderedImages, {
      index,
      closeOnScroll: false,
    });
    this.gallery.init();
    this.gallery.listen('close', () => {
      this.props.zoomProductImage([]);
      removeBodyClass('zoom-open');
      const zoomButton = document.querySelector(
        `#mainMediaZoom_${this.props.productId}`,
      );
      if (this.props.PDP_OPTIMISATION) {
        const mainContainer = document.querySelector('.main-media__container');
        mainContainer && setImmediate(() => mainContainer.focus());
      } else {
        zoomButton && setImmediate(() => zoomButton.focus());
      }
    });
  }

  render() {
    return (
      <div
        className="pswp"
        ref={pwsp => {
          this.pwsp = pwsp;
        }}
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
        aria-label="Zoom Images"
      >
        <div className="pswp__bg" onTouchStart={this.onClose} />
        <div className="pswp__scroll-wrap">
          <div className="pswp__container">
            <div className="pswp__item" />
            <div className="pswp__item" />
            <div className="pswp__item" />
          </div>
          <div className="pswp__ui pswp__ui--hidden">
            <div className="pswp__top-bar">
              <div className="pswp__counter" />
              <button
                className="pswp__button pswp__button--close"
                title="Close"
              >
                Close
              </button>
              <div className="pswp__preloader">
                <div className="pswp__preloader__icn">
                  <div className="pswp__preloader__cut">
                    <div className="pswp__preloader__donut" />
                  </div>
                </div>
              </div>
            </div>
            <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
              <div className="pswp__share-tooltip" />
            </div>
            <button
              className="pswp__button pswp__button--arrow--left"
              title="Previous"
              aria-label={PREVIOUS_BUTTON}
            />
            <button
              className="pswp__button pswp__button--arrow--right"
              title="Next"
              aria-label={NEXT_BUTTON}
            />
            <div className="pswp__caption">
              <div className="pswp__caption__center" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { images = [], currentIndex } = get(
    getProduct(state, props.productId),
    'zoomedImage',
    {},
  );
  const PDP_OPTIMISATION = get(state, 'toggles.PDP_OPTIMISATION', false);
  return {
    images: images.map(image => {
      const imgURL = getPDPImageZoomUrl(image, props.isCloudinary);
      return {
        msrc: imgURL,
        src: imgURL,
        w: 1200,
        h: 1500,
      };
    }),
    currentIndex,
    PDP_OPTIMISATION,
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  zoomProductImage: (...args) => dispatch(zoomProductImage(productId, ...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbProductZoom);
