/* eslint-disable max-len */
import httpWithLogging from "universal/http-client";

export const RESOLVED_REFRESHABLE_CONTENT = "RESOLVE_REFRESHABLE_CONTENT";
export const REJECTED_REFRESHABLE_CONTENT = "REJECT_REFRESHABLE_CONTENT";
const successHandler = r => ({ response: r, resolved: true });
const failureHandler = e => ({ error: e, resolved: false });

export function getRefreshableHtmlContent(refreshablePath, categoryId) {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state);
    return requestApi
      .get(NMConfig.API_REFRESHABLE_CONTENT, { params: { refreshablePath } })
      .then(successResponse =>
        dispatch({
          type: RESOLVED_REFRESHABLE_CONTENT,
          payload: successResponse.data,
          categoryId
        })
      )
      .catch(() => dispatch({ type: REJECTED_REFRESHABLE_CONTENT }));
  };
}

export function getRefreshableHtmlContentFallback(
  allrefreshablePath,
  categoryId
) {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state);
    const apis = (allrefreshablePath || []).map(refreshablePath =>
      requestApi.get(NMConfig.API_REFRESHABLE_CONTENT, {
        params: { refreshablePath }
      })
    );
    return Promise.all(
      apis.map(p => p.then(successHandler).catch(failureHandler))
    )
      .then(response => {
        const result = (response || [])
          .filter(r => r.resolved)
          .map(r => (r.response ? r.response.data : ""));
        dispatch({
          type: RESOLVED_REFRESHABLE_CONTENT,
          payload: result,
          categoryId
        });
      })
      .catch(() => dispatch({ type: REJECTED_REFRESHABLE_CONTENT }));
  };
}
