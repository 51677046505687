import React from 'react';
import PhoneInput from 'react-phone-number-input/input';

export default ({ name, value, error, placeholder = '', onChange }) => (
  <div className={`input-wrapper ${error ? 'not-valid' : ''}`}>
    <PhoneInput
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      country="US"
    />
    <p className="validation-error">{error}</p>
  </div>
);
