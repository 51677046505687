import find from 'lodash/find';
import React from 'react';
import classNames from 'classnames';
import DesignerByCategory from './DesignerByCategory/designerByCategory';
import AtoZGrid from './AtoZGrid/aToZGrid';
import FeaturedDesigners from './FeaturedDesigners/featuredDesigners';
import './designersDrawer.scss';

function getDesignersSubDrawersByName(silo, name) {
  return find(silo.categories, category => {
    return category.name.toLowerCase() === name.toLowerCase();
  });
}

const DesignersDrawer = ({
  silo,
  closeFunction,
  hidden,
  delayHoverOnSilos = false,
  getPromoContent,
}) => {
  const byAtoZData = getDesignersSubDrawersByName(silo, 'By A-Z');
  if (byAtoZData && byAtoZData.categories) {
    const index = byAtoZData.categories.findIndex(x => x.name === '#');
    if (index > -1) {
      const removedValue = byAtoZData.categories.splice(index, 1)[0];
      removedValue.name = '0-9';
      byAtoZData.categories.unshift(removedValue);
    }
  }
  const byAtoZ =
    byAtoZData &&
    AtoZGrid({
      key: 'byAtoZ',
      dataToDisplay: byAtoZData.categories,
      titleToDisplay: byAtoZData.name,
      closeFunction,
    });

  const designersByCategoryData = getDesignersSubDrawersByName(
    silo,
    'POPULAR DESIGNERS',
  );
  const designersByCategory =
    designersByCategoryData &&
    DesignerByCategory({
      key: 'designersByCategory',
      dataToDisplay: designersByCategoryData.categories,
      titleToDisplay: designersByCategoryData.name,
      closeFunction,
    });

  const mostWantedDesignersData = getDesignersSubDrawersByName(
    silo,
    'Most Wanted',
  );
  const mostWanted =
    mostWantedDesignersData &&
    FeaturedDesigners({
      key: 'mostWanted',
      dataToDisplay: mostWantedDesignersData.categories,
      titleToDisplay: mostWantedDesignersData.name,
      closeFunction,
      getPromoContent,
      silo,
    });

  const newToBgDesignersData = getDesignersSubDrawersByName(silo, 'New to BG');
  const newToBG =
    newToBgDesignersData &&
    FeaturedDesigners({
      key: 'newToBg',
      dataToDisplay: newToBgDesignersData.categories,
      titleToDisplay: newToBgDesignersData.name,
      closeFunction,
      getPromoContent,
      silo,
    });

  return (
    <section
      className={classNames(
        'silo-group designer-drawer',
        { 'drawer-hidden': hidden },
        { 'delay-hover-on-silos': delayHoverOnSilos },
      )}
    >
      <div className="silo-parent silo-parent-designer columns">
        <div className="designer-left-panel-categories left-panel column is-6">
          {byAtoZ}
        </div>
        <div className="designer-right-panel-categories grid-parent right-panel columns column is-6">
          {designersByCategory}
          {mostWanted}
          {newToBG}
        </div>
      </div>
    </section>
  );
};

export default DesignersDrawer;
