import React from 'react';
import { policyUri } from '../../../../../../../../login/constants';
import './index.scss';

const FormDisclaimer = () => (
  <div className="form-disclaimer">
    <div className="form-disclaimer__content">
      By continuing, you agree to our{' '}
      <a href={policyUri} target="_blank" rel="noreferrer noopener">
        Terms and Conditions
      </a>{' '}
      &{' '}
      <a href={policyUri} target="_blank" rel="noreferrer noopener">
        Privacy Policy
      </a>
      .
    </div>
  </div>
);

export default FormDisclaimer;
