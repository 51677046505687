import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import './BGcardContainer.scss';
import HowIncircleWorks from '../HowIncircleWorks/HowIncircleWorks';
import InCircleInfo from '../InCircleInfo/InCircleInfo';
import BGcardSlider from '../BGcardSlider/BGcardSlider';

const BGcardContainer = ({ myAccountBGcard }) => {
  return (
    <Fragment>
      {!isEmpty(myAccountBGcard) && (
        <div className="incircle">
          <InCircleInfo />
          <BGcardSlider cards={myAccountBGcard} />
          <HowIncircleWorks />
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  myAccountBGcard: get(state, 'myaccount.myAccountBGcard', true),
});

export default connect(mapStateToProps)(BGcardContainer);
