import httpWithLogging from 'universal/http-client';
import { buildCookieString } from 'universal/utilities-cookies';
import { normalizeProductJSON } from 'server/utilities/contentful';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import { putCache, getCache } from 'client-utils/utilities-mcache';

export function getCmsProducts(productIds, contentId) {
  return (dispatch, getState) => {
    dispatch({
      type: `LOADING_CMS_PRODUCTS_${contentId}`,
      contentId,
    });
    const state = getState();
    const { user, session } = state;
    const requestApi = httpWithLogging(state, 6000);
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        ucid: get(user, 'ucid', ''),
        rid: get(user, 'rid', 'US'),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        W2A: get(session, 'W2A', ''),
      }),
    };

    const joinProductIds = productIds => {
      return productIds.join(',');
    };
    let prodCallString = `${NMConfig.API_PRODUCT}?productIds=${joinProductIds(
      productIds,
    )}`;
    const internationalToggle = get(state, 'toggles.INTERNATIONAL', false);
    if (internationalToggle) {
      const currencyCode = get(state, 'locale.currencyCode', 'USD');
      if (currencyCode !== 'USD') {
        let currencyQuery = `?currency=${currencyCode}`;
        if (
          prodCallString.indexOf('?') > -1 ||
          prodCallString.indexOf('&') > -1
        ) {
          currencyQuery = `&currency=${currencyCode}`;
        }
        prodCallString = `${prodCallString}${currencyQuery}`;
      }
    }
    return requestApi
      .get(prodCallString, { headers })
      .then(res => {
        /* eslint-disable-next-line max-len */
        const sortedProducts = productIds.map(id =>
          res.data.products.find(product => product.id === id),
        );
        const rawChildIds = sortedProducts.map(product => {
          if (product.isGroup === true) {
            return product.childProductIds[0];
          }
          return null;
        });
        const childProductIds = rawChildIds ? compact(rawChildIds) : [];
        if (!isEmpty(childProductIds)) {
          dispatch(getChildProducts(childProductIds, contentId));
        }
        dispatch({
          type: `RESOLVED_CMS_PRODUCTS_${contentId}`,
          payload: normalizeProductJSON(sortedProducts),
          contentId,
        });
      })
      .catch(() => {
        dispatch({
          type: `REJECTED_CMS_PRODUCTS_${contentId}`,
          contentId,
        });
      });
  };
}

export function getChildProducts(childProductIds, contentId) {
  return (dispatch, getState) => {
    dispatch({
      type: `LOADING_CHILD_PRODUCTS_${contentId}`,
      contentId,
    });
    if (!childProductIds || !childProductIds.length) {
      dispatch({
        type: `REJECTED_CHILD_PRODUCTS_${contentId}`,
        contentId,
      });
    }
    const state = getState();
    const { user, session } = state;
    const requestApi = httpWithLogging(state, 6000);
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        ucid: get(user, 'ucid', ''),
        rid: get(user, 'rid', 'US'),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        W2A: get(session, 'W2A', ''),
      }),
    };

    const joinProductIds = childProductIds => {
      return childProductIds.join(',');
    };
    const prodCallString = `${NMConfig.API_PRODUCT}?productIds=${joinProductIds(
      childProductIds,
    )}`;
    return requestApi
      .get(prodCallString, { headers })
      .then(res => {
        dispatch({
          type: `RESOLVED_CHILD_PRODUCTS_${contentId}`,
          payload: normalizeProductJSON(res.data.products),
          contentId,
        });
      })
      .catch(() => {
        dispatch({
          type: `REJECTED_CHILD_PRODUCTS_${contentId}`,
          contentId,
        });
      });
  };
}

export function getCategoryProducts(catID = '', noOfProducts = 16) {
  return (dispatch, getState) => {
    dispatch({
      type: `LOADING_CMS_CURRATED_PRODUCTS_${catID}`,
      catID,
    });
    const state = getState();
    const { user, session } = state;
    const requestApi = httpWithLogging(state, 6000);
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        ucid: get(user, 'ucid', ''),
        rid: get(user, 'rid', 'US'),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        W2A: get(session, 'W2A', ''),
      }),
    };

    const apiConfig = NMConfig.API_CURATED_PRODUCT_LIST;
    const prodCallString = `${apiConfig}?categoryId=${catID}&fetchSize=${noOfProducts}`;

    let cacheKey;
    let cacheResponse;

    if (prodCallString) {
      cacheKey = prodCallString;
      const cacheData = getCache(cacheKey, state);
      cacheResponse = cacheData ? { data: cacheData } : null;
    }
    const curatedProductResponse = (catID, curratedProductList) => {
      dispatch({
        type: `RESOLVED_CMS_CURRATED_PRODUCTS_${catID}`,
        payload: curratedProductList,
        catID,
      });
    };

    if (cacheResponse) {
      return new Promise(resolve => {
        curatedProductResponse(catID, cacheResponse.data);
        return resolve();
      });
    }
    return requestApi
      .get(prodCallString, { headers })
      .then(res => {
        if (!get(res, 'data.products.length')) {
          dispatch({
            type: `REJECTED_CMS_CURRATED_PRODUCTS_${catID}`,
            catID,
          });
        }

        const curratedProductList = [];
        for (const product of res.data.products) {
          const imageUrl =
            get(product, 'media.main.medium.url') ||
            get(product, 'media.alternate.medium.url') ||
            '/assets/images/no-image.c9a49578722aabed021ab4821bf0e705.jpeg';

          const designerName = get(product, 'designer.name', ' ');
          const name = product.name || '';
          const isGroup = product.isGroup;
          const childProducts = isGroup
            ? get(product, 'childProducts', [])
            : [];
          const displayable = product.displayable;

          curratedProductList.push({
            id: product.id,
            designerName,
            name,
            url: get(product, 'details.canonicalUrl', ''),
            price: product && product.price,
            imageUrl,
            title: getTitle(product),
            promotions: product.promotions,
            isGroup,
            childProducts,
            displayable,
          });
        }
        if (cacheKey) {
          const mcacheTimeout = get(state, 'mcacheTimeouts.cproducts', 900000);
          putCache(cacheKey, curratedProductList, mcacheTimeout, state);
        }
        curatedProductResponse(catID, curratedProductList);
      })
      .catch(() => {
        dispatch({
          type: `REJECTED_CMS_CURRATED_PRODUCTS_${catID}`,
          catID,
        });
      });
  };
}

function getTitle(product) {
  const isGroup = product.isGroup;
  const cmosItemNum = get(product, 'metadata.cmosItem', null);
  const designerName = unescape(get(product, 'designer.name', ' '));
  let productName = stripHtml(unescape(product.name));
  if (isGroup) {
    const childProducts = get(product, 'childProducts', []);
    productName = stripHtml(
      unescape(
        childProducts
          .map(product => {
            return product.name;
          })
          .join(' '),
      ),
    );
  }
  return trimSpaces(`${cmosItemNum} ${designerName} ${productName}`);
}

function stripHtml(html) {
  return html.replace(/<[^>]+>/g, '');
}

function trimSpaces(str) {
  return str.trim().replace(/\s\s+/g, ' ');
}
