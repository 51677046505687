import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import AddToBagButton from 'productpage/components/AddToBagButton/addToBagButton';
import {
  getColorOptions,
  getSizeOptions,
} from 'pdp/components/ProductPage/selectors/getOptions';
import { unescape } from 'he';
import ProductMedia from 'productpage/components/ProductMedia/productMedia';
import {
  setHoveredIndex,
  setSelectedIndex,
  setReplenishInterval,
  openReplenishment,
} from 'productpage/actions';
import getProduct from 'pdp/components/ProductPage/selectors/getProduct';
import getSizeSkus from 'productpage/selectors/getSizeSkus';
import getColorSkus from 'productpage/selectors/getColorSkus';
import getSelectedColorIndex from 'productpage/selectors/getSelectedColorIndex';
import ProductOptionSet from 'productpage/components/ProductOptionSet/productOptionSet';
import BeautyReplenishment from 'productpage/components/BeautyReplenishment/beautyReplenishment';
import SkuStatusMessages from 'productpage/components/SkuStatusMessages/skuStatusMessages';
import getSelectedSku from 'productpage/selectors/getSelectedSku';
import ShippingAndClearanceText from 'productpage/components/ProductDetails/components/Heading/components/ShippingLabel/shippingAndClearanceText';
import 'pdp/components/ProductPage/components/Checkout/callForAvailability.scss';
import Button from 'shared/components/Button/button';
import ItemNotAvailable from 'productpage/components/Checkout/itemNotAvailable';
import {
  renderCheckoutOrHelplineText,
  shouldDisplayMonogramHelpLineText,
} from 'pdp/components/ProductPage/components/Checkout/checkoutOptionRenderer';
import 'pdp/components/ProductPage/components/Checkout/monogramHelpline.scss';
import {
  getActiveMedia,
  getActiveMediaIndex,
  getSelectedOptionMedia,
} from 'productpage/selectors/getMedia';
import './commonProductSummary.scss';
import ProductSummaryHeader from './productSummaryHeader';

const getProductMedia = (product, name) => {
  const activeMedia = getActiveMedia(product) || {};
  activeMedia.video = {};

  return (
    <ProductMedia
      productId={product.id}
      showSoldOutOverlay={!product.displayable}
      name={name}
      media={activeMedia}
      activeIndex={getActiveMediaIndex(product, true)}
      showAltImages={false}
      dots
      arrows
    />
  );
};

const isZeroDollar = product => product.isZeroDollarProduct;
const isExceptionalProduct = product =>
  product.perishable || isZeroDollar(product) || product.isCustomizable;

const continueToProductPage = productUrl => (
  <div
    id="continueButton"
    className="grid-50 tablet-grid-65 mobile-grid-100 grid-parent"
  >
    <Link target="_self" to={productUrl}>
      <Button value="CONTINUE" />
    </Link>
  </div>
);

const addToBag = (productId, selectedSku, skuMediaUrl) => (
  <AddToBagButton
    productId={productId}
    selectedSku={selectedSku}
    skuMediaUrl={skuMediaUrl}
  />
);

const getProductButtons = (product, props, selectedSku, skuMediaUrl) => (
  <div className="productButtons">
    {isExceptionalProduct(product)
      ? continueToProductPage(props.productUrl)
      : addToBag(product.id, selectedSku, skuMediaUrl)}
  </div>
);

const defaultPDPLinkText = 'Read Full Details on Item Page';

const ProductLink = ({ productUrl, message }) => (
  <Link className="view-detail" target="_self" to={productUrl}>
    {message}
  </Link>
);

function displaySizeAndColorOptions(product) {
  return !isZeroDollar(product);
}

function getCheckoutOptions(product, props) {
  const sizeOptions = getSizeOptions(product);
  const selectedSizeIndex = get(product, 'options.selectedSizeIndex', -1);
  const selectedColorIndex = getSelectedColorIndex(product);
  const sizeSkus = getSizeSkus(product, selectedColorIndex);
  const selectedOptionMedia = getSelectedOptionMedia(product);
  const skuMediaUrl = get(selectedOptionMedia, 'main.thumbnail.url', '');

  const colorOptions = getColorOptions(product);
  const colorSkus = getColorSkus(product, selectedSizeIndex);
  const hoveredSizeIndex = get(product, 'options.hoveredSizeIndex', -1);
  const hoveredColorIndex = get(product, 'options.hoveredColorIndex', -1);
  const noAvailableSizeSelected = selectedSizeIndex > 0 && isEmpty(colorSkus);

  const selectedSku = getSelectedSku(
    { colorSkus, sizeSkus },
    { colorOptions, sizeOptions },
    { selectedColorIndex, selectedSizeIndex },
  );

  return (
    <div>
      <div className="product-options__quick-look">
        {displaySizeAndColorOptions(product) && (
          <div className="product-options__size-color">
            <ProductOptionSet
              productId={product.id}
              labelHeading="Select"
              label="size"
              options={sizeOptions.values}
              selectedIndex={selectedSizeIndex}
              availableSkus={sizeSkus}
              onClick={props.setSelectedIndex}
              hoveredIndex={hoveredSizeIndex}
              onHover={props.setHoveredIndex}
            />
            <ProductOptionSet
              productId={product.id}
              labelHeading="Select"
              label="color"
              options={colorOptions.values}
              selectedIndex={selectedColorIndex}
              availableSkus={colorSkus}
              onClick={props.setSelectedIndex}
              hoveredIndex={hoveredColorIndex}
              onHover={props.setHoveredIndex}
              noAvailableSizeSelected={noAvailableSizeSelected}
            />
          </div>
        )}
        {product.replenishable && (
          <div>
            <div className="beautyReplenishment">
              <BeautyReplenishment
                replenishInterval={product.replenishInterval}
                onIntervalSelect={props.setReplenishInterval}
              />
            </div>
          </div>
        )}
      </div>
      <SkuStatusMessages selectedSku={selectedSku} />
      <ProductLink
        productUrl={props.productUrl}
        message={`${
          product.replenishable ? 'Replenish? ' : ''
        }${defaultPDPLinkText}`}
      />
      {getProductButtons(product, props, selectedSku, skuMediaUrl)}
    </div>
  );
}

const renderCheckoutOptionInfo = props => {
  const { product, productUrl } = props;
  const shouldDisplayMonogramHelpline = shouldDisplayMonogramHelpLineText(
    props.product,
  );
  const shouldDisplaySuppressCheckoutHelpline = get(
    product,
    'details.suppressCheckout',
    false,
  );
  return (
    <div id="checkoutOptions">
      {(shouldDisplayMonogramHelpline ||
        shouldDisplaySuppressCheckoutHelpline) && (
        <div>
          <ProductLink productUrl={productUrl} message={defaultPDPLinkText} />
          <div className="helpline-text-product-link-divider" />
        </div>
      )}
      {renderCheckoutOrHelplineText(
        props.product,
        getCheckoutOptions(props.product, props),
        <ItemNotAvailable />,
      )}
    </div>
  );
};

const CommonProductSummary = props => {
  const { product, narvarTest = {} } = props;
  const name = unescape(product.name || '');
  const { nameHtml } = product;

  return (
    <main className="common-summary-page grid-100 tablet-grid-100 mobile-grid-100 grid-parent">
      <div className="image-container grid-40 tablet-grid-40 mobile-grid-100">
        {getProductMedia(product, name)}
      </div>
      <div className="line-item grid-60 tablet-grid-60 mobile-grid-100 grid-parent">
        <ProductSummaryHeader
          name={name}
          nameHtml={nameHtml}
          designer={product.designer}
          price={product.price}
          promotions={get(product, 'promotions')}
          isZeroDollarProduct={product.isZeroDollarProduct}
        />
        <ShippingAndClearanceText
          details={product.details}
          narvarTest={narvarTest}
          customization={product.customization}
          isEvening={get(product, 'productFlags.isEvening', false)}
        />
        {renderCheckoutOptionInfo(props)}
      </div>
    </main>
  );
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  setSelectedIndex: (...args) => dispatch(setSelectedIndex(productId, ...args)),
  setHoveredIndex: (...args) => dispatch(setHoveredIndex(productId, ...args)),
  setReplenishInterval: (...args) =>
    dispatch(setReplenishInterval(productId, ...args)),
  openReplenishment: () => dispatch(openReplenishment()),
});

const mapStateToProps = (state, props) => {
  const product = getProduct(state, props.productId);
  return {
    ...props,
    product,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommonProductSummary);
