import React from 'react';
import './NoPayment.scss';
import Copy from '../../../../common/Copy/Copy';

const NoPayment = ({ content }) => {
  return (
    <div className="no-payment">{content && <Copy content={content} />}</div>
  );
};

export default NoPayment;
