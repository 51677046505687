import { OPEN_MODAL } from 'shared/components/Modal/actions';

export const types = {
  SET_LOCALE: 'SET_LOCALE',
  SELECT_LOCALE: 'SELECT_LOCALE'
};

export function openCountryChooser() {
  return dispatch =>
    dispatch({
      type: OPEN_MODAL,
      payload: {
        type: 'CountrySelectorModal',
        className: 'country-selector-modal',
        closeDisabled: true,
      }
    });
}

export function selectUserLocale(selectedCountryCode, selectedCurrencyCode, selectedLocaleUrl) {
  return dispatch => dispatch({ type: types.SET_LOCALE,
    locale: { selectedCountryCode, selectedCurrencyCode, selectedLocaleUrl } });
}

export function setUserLocale(countryCode, currencyCode, localeUrl) {
  return { type: types.SET_LOCALE,
    locale: { countryCode, currencyCode, localeUrl } };
}

