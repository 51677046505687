import React from 'react';
import { formatNumber } from 'client-utils/utilities-number';
import './CardSlide.scss';

const CARD_PATTERN = '************';
const CARD_REPLACE_VALUE = 'xxxx-xxxx-xxxx-';
const POINTS_TO_NEXT = ['points', 'to next point card'];
const EARNED_TEXT = 'Earned';
const REDEEMED_TEXT = 'Redeemed';
const AVAILABLE_TEXT = 'Available';

const updatedCircleName = name => {
  const mapNumber = {
    '1': 'One',
    '2': 'Two',
    '3': 'Three',
    '4': 'Four',
    '5': 'Five',
    '6': 'Six',
  };
  const containsNumber = name.match(/\d+/g);
  const number = containsNumber ? containsNumber[0] : null;
  return number
    ? name.replace(number.toString(), mapNumber[number.toString()])
    : name;
};

const CardSlide = ({ card }) => {
  const earnedLevel = parseInt(card.earned_level, 10);
  const benefitLevel = parseInt(card.benefit_level, 10);
  const circleName =
    earnedLevel > benefitLevel
      ? card.earned_level_name
      : card.benefit_level_name;
  const rangeOfPoints = (card.current_points % 10000) / 10000;
  const radius = 123;
  const circumference = 2 * Math.PI * radius;
  const mainDash = `${circumference / 2}, ${circumference}`;
  const rangeDash = `${(circumference / 2) * rangeOfPoints}, ${circumference}`;
  const pointsLeft = formatNumber(10000 - (card.current_points % 10000));

  return (
    <div className="card-slide">
      <div className="card-slide__card-number">
        {card.tenderId.replace(CARD_PATTERN, CARD_REPLACE_VALUE)}
      </div>
      <h3 className="card-slide__circle-name">
        {updatedCircleName(circleName)}
      </h3>
      <div className="card-slide__diagram-wrapper">
        <svg className="card-slide__diagram-circle">
          <circle
            r={radius}
            cx="50%"
            cy="50%"
            stroke="#E1E1EB"
            strokeWidth="20"
            strokeDasharray={mainDash}
            fill="none"
          />
          <circle
            r={radius}
            cx="50%"
            cy="50%"
            stroke="#000000"
            strokeWidth="20"
            strokeDasharray={rangeDash}
            fill="none"
          />
        </svg>
        <div className="card-slide__points">
          <h3>{pointsLeft}</h3>
          <p>{POINTS_TO_NEXT[0]}</p>
          <p>{POINTS_TO_NEXT[1]}</p>
        </div>
      </div>
      <div className="card-slide__card-info">
        <div>
          <h3>{formatNumber(card.redeem_earned)}</h3>
          <p>{EARNED_TEXT}</p>
        </div>
        <div>
          <h3>{formatNumber(card.redeemed)}</h3>
          <p>{REDEEMED_TEXT}</p>
        </div>
        <div>
          <h3>{formatNumber(card.redeem_avail)}</h3>
          <p>{AVAILABLE_TEXT}</p>
        </div>
      </div>
    </div>
  );
};

export default CardSlide;
