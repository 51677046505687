/* DO NOT MODIFY THIS CORE ACCORDION DIRECTLY TO FIX ISSUES IN MODULES USING THIS COMPONENT */

import React from 'react';
import './accordion.scss';
import { EyeBrow1, EyeBrow5, BodyB1 } from '../../../../Styleguide/Typography';

const defaultNull = null;

class AccordionItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      triggeredAnalytics: false,
    };
  }

  handleClick(data) {
    this.props.clickFun(data.index);
    const { triggeredAnalytics } = this.state;
    if (
      data?.title === 'Description' &&
      data?.clickedIndex === -1 &&
      !triggeredAnalytics
    ) {
      this.handleAnalyticsEvents({ product_interaction: 'item information' });
      this.setState({ triggeredAnalytics: true });
    }
  }

  handleAnalyticsEvents = eventData => {
    if (window && window.utag && window.utag.link && window.utag_data_dt) {
      const data = {
        ...window.utag_data_dt,
        ...eventData,
      };
      window.utag.link(data);
      this.setState({ triggeredAnalytics: true });
    }
  };

  componentDidUpdate() {
    if (this.state.focused) {
      this.accordion.focus();
    }
  }

  onKeyPressed(e, optionVal) {
    if (e.key === 'Enter') {
      this.setState({ focused: true });
      this.handleClick(optionVal);
    }
  }

  removeFocus = () => {
    this.setState({ focused: false });
  };

  render() {
    const { props } = this;

    const { image, eyeBrow } = this.props;

    return (
      <li className="accordion-list__item" key={Math.random()}>
        <div
          className={`accordion-item ${props.index === props.clickedIndex &&
            'accordion-item--opened'}`}
        >
          <div
            className="accordion-item__line"
            onClick={() => this.handleClick(this.props)}
            onKeyPress={e => this.onKeyPressed(e, this.props)}
            tabIndex="0"
            // eslint-disable-next-line no-return-assign
            ref={ref => (this.accordion = ref)}
            onBlur={this.removeFocus}
          >
            <span
              className={`accordion-item__icon ${props.iconPosition ===
                'right' && 'accordion-item__icon__right'} ${props.index ===
                props.clickedIndex && 'rotate'}`}
            />
            {image && (
              <span className="ac-image">
                <img src={image} alt={eyeBrow} />{' '}
              </span>
            )}
            <div className="accordion-title-wrapper">
              {eyeBrow && <EyeBrow1>{eyeBrow}</EyeBrow1>}
              <h6 className="accordion-item__title">
                <span className="accordion-mannual-title">{props.title}</span>
              </h6>
              {props.titleMoreContent || defaultNull}
            </div>
          </div>
          {props.index === props.clickedIndex && (
            <div className="accordion-item__inner">
              <div className="accordion-item__content">{props.children}</div>
            </div>
          )}
        </div>
      </li>
    );
  }
}

export default AccordionItem;
