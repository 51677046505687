import React from 'react';
import ContentSlot from '../ContentSlot/contentSlot';
import Image from '../Image/image';
import RichText from '../RichText/richText';
import SimpleText from '../SimpleText/simpleText';
import TextOverImage from '../TextOverImage/textOverImage';

const ContentType = ({ content = {} }) => {
  switch (content.contentType) {
    case 'contentSlot':
      return <ContentSlot content={content} />;
    case 'richTextContentAsset':
      return <RichText content={content} />;
    case 'imageContentAsset':
      return <Image content={content} />;
    case 'simpleTextContentAsset':
      return <SimpleText content={content} />;
    case 'textOverImageContentAsset':
      return <TextOverImage content={content} />;
    default:
      return null;
  }
};

export default ContentType;
