import React from 'react';
import { connect } from 'react-redux';
import logoLoader from './assets/images/BGO_Loading_gif.gif';
import './spinner.scss';

const Spinner = ({ active = false, isBGLoadingAnimation = false }) =>
  active &&
  (!isBGLoadingAnimation ? (
    <div className="logoLoader">
      <img src={logoLoader} alt="loading..." />
    </div>
  ) : (
    <div className="spinner" />
  ));

const mapStateToProps = state => ({
  active: false,
  isBGLoadingAnimation: state.toggles.BG_LOADING_ANIMATION,
});
export default connect(mapStateToProps)(Spinner);
