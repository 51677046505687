import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { shouldLoad } from 'universal/http-client';
import {
  getABTestAssignments,
  NEW_MOBILE_NAV_MBOX_ID,
  AEM_ABTEST_USERGROUP_MBOX_ID,
} from 'shared/actions/actions-page';
import Bg360App from './app-bg360';
import { loadContent } from '../bg360/actions';

export class DumbBg360AppWrapper extends Component {
  constructor(...args) {
    super(...args);
    this.state = { callAbTestService: true };
  }

  UNSAFE_componentWillMount() {
    const mBoxIds = [];
    this.props.newMobileNavToggle && mBoxIds.push(NEW_MOBILE_NAV_MBOX_ID);
    this.props.abtestAemContentToggle &&
      mBoxIds.push(AEM_ABTEST_USERGROUP_MBOX_ID);
    if (mBoxIds.length > 0) {
      if (shouldLoad(this.props.abTestApi)) {
        this.props.getABTestAssignments(mBoxIds.join(','), true);
      }
    } else {
      this.setState({ callAbTestService: false });
    }
    if (!this.props.contentLoadingStatus) {
      this.props.loadContent();
    }
  }

  render() {
    const shouldContinue =
      !this.state.callAbTestService ||
      (this.state.callAbTestService &&
        (this.props.abTestApi.resolved || this.props.abTestApi.rejected));
    return (
      <Fragment>
        {shouldContinue ? <Bg360App {...this.props} /> : null}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  newMobileNavToggle: state.toggles.NEW_MOBILE_NAV,
  abtestAemContentToggle: state.toggles.ABTEST_AEM_CONTENT_TARGETING,
  abTestApi: state.api.abtest || {},
  contentLoadingStatus: state.bg360.contentLoadingStatus,
});
const mapDispatchToProps = {
  getABTestAssignments,
  loadContent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbBg360AppWrapper);
