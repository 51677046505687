import React from 'react';
import get from 'lodash/get';
import DesignerName from 'productpage/components/ProductDetails/components/Heading/designerName';
import { productTitle } from 'productpage/components/ProductDetails/components/Heading/productHeading';
import {
  renderSalePrice,
  renderPromotionItemHtml,
} from 'productpage/components/ProductDetails/components/Heading/headingUtil';
import { PROMOTION_TYPE_113 } from 'plp/constants';

const ProductSummaryHeader = ({
  name,
  nameHtml,
  designer = {},
  price = {},
  promotions = [],
  isZeroDollarProduct = false,
}) => {
  const promotionAdornmentColor = get(promotions, ['0', 'priceAdornmentColor']);
  const itemPromotions = promotions.filter(
    promo =>
      promo.itemHTML &&
      promo.showPromoPrice &&
      promo.type === PROMOTION_TYPE_113,
  );
  return (
    <header>
      <DesignerName name={designer.name} />
      {productTitle({ name, nameHtml })}
      {renderSalePrice(price, promotionAdornmentColor, isZeroDollarProduct)}
      {renderPromotionItemHtml(itemPromotions, price)}
    </header>
  );
};

export default ProductSummaryHeader;
