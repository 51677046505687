import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';
import every from 'lodash/every';
import capitalize from 'lodash/capitalize';
import ErrorMessage from 'productpage/components/ErrorMessage/errorMessage';
import { isMobile } from 'client-utils/utilities-page';
import { MONOGRAM_HELP_TEXT, MONOGRAM_PREVIEW_HELPTEXT } from 'pdp/constants';
import './monogramText.scss';

export default class MonogramText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      NAME: [''],
      1: [''],
      3: ['', '', ''],
    };

    this.scrollToTop = this.scrollToTop.bind(this);
  }

  componentDidMount() {
    const { selectedText = [], monoStyleChoiceType = '' } = this.props;
    const isEmptyText = every(selectedText, value => {
      return !value;
    });
    if (isEmptyText) {
      return;
    }

    const newState = {};
    const key = this.getStateKeyByType(monoStyleChoiceType);
    newState[key] = [...selectedText];
    this.setState(newState);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { monoStyleChoiceType = '' } = nextProps;
    const { monoStyleChoiceType: prevMonoStyleChoiceType = '' } = this.props;

    const key = this.getStateKeyByType(monoStyleChoiceType);
    const selectedText = this.state[key] || [];
    const isValidText = every(selectedText, value => {
      return value ? /^[a-zA-Z]+$/.test(value) : true;
    });

    if (!isEqual(monoStyleChoiceType, prevMonoStyleChoiceType)) {
      if (isValidText) {
        this.props.setSelectedText([...selectedText]);
      } else {
        this.props.setSelectedText([]);
      }
      this.props.setIsInvalidText(!isValidText);
    }
  }

  getStateKeyByType(choiceType) {
    switch (choiceType) {
      case 'SINGLE_INIT':
        return 1;
      case 'THREE_INIT':
      case 'THREE_INIT_FML':
        return 3;
      default:
        return choiceType;
    }
  }

  getLabel(choiceType) {
    switch (choiceType) {
      case 'NAME':
        return 'Name';
      case 'SINGLE_INIT':
        return 'Initial';
      case 'THREE_INIT':
      case 'THREE_INIT_FML':
        return 'Initials';
      default:
        return '';
    }
  }

  getTextBoxes(choiceType) {
    const { maxLengthOfText = 0 } = this.props;
    const key = this.getStateKeyByType(choiceType);
    const state = this.state[key] || [];

    switch (choiceType) {
      case 'NAME':
        return (
          <div className="monogram-text__user-input__name">
            <input
              key="Name"
              aria-label="Name"
              type="text"
              value={state[0]}
              maxLength={maxLengthOfText}
              onChange={e => this.handleChange(e, 'NAME', 0)}
              onFocus={this.scrollToTop}
            />
          </div>
        );
      case 'SINGLE_INIT':
        return (
          <div className="monogram-text__user-input__initials">
            <input
              key="First initial"
              aria-label="First initial"
              type="text"
              value={state[0]}
              size={1}
              maxLength={1}
              onChange={e => this.handleChange(e, 1, 0)}
              onFocus={this.scrollToTop}
            />
          </div>
        );
      case 'THREE_INIT':
      case 'THREE_INIT_FML':
        return (
          <div className="monogram-text__user-input__initials">
            <input
              key="First initial"
              aria-label="First initial"
              type="text"
              value={state[0]}
              size={1}
              maxLength={1}
              onChange={e => this.handleChange(e, 3, 0)}
              onFocus={this.scrollToTop}
            />
            <input
              key="Middle initial"
              aria-label="Middle initial"
              type="text"
              value={state[1]}
              size={1}
              maxLength={1}
              onChange={e => this.handleChange(e, 3, 1)}
              onFocus={this.scrollToTop}
            />
            <input
              key="Last initial"
              aria-label="Last initial"
              type="text"
              value={state[2]}
              size={1}
              maxLength={1}
              onChange={e => this.handleChange(e, 3, 2)}
              onFocus={this.scrollToTop}
            />
          </div>
        );
      default:
        return <div />;
    }
  }

  getDisplay() {
    const {
      monoStyleChoiceType = {},
      defaultText = [],
      selectedText = [],
    } = this.props;
    let activeText = [...selectedText];
    const isEmptyText = every(activeText, value => {
      return !value;
    });
    if (isEmptyText) {
      activeText = defaultText;
    }

    if (monoStyleChoiceType === 'THREE_INIT') {
      const [input1 = '', input2 = '', input3 = ''] = activeText;
      return input1 + input3 + input2;
    }
    return activeText.join('');
  }

  getErrorMessage() {
    const { isInvalidText = false, isIncompleteText = false } = this.props;
    if (isInvalidText) {
      return 'Please use letters only, special characters are not allowed.';
    } else if (isIncompleteText) {
      return 'Enter required information.';
    } else {
      return '';
    }
  }

  handleChange(e, type, index) {
    const { maxLengthOfText = 0 } = this.props;
    let value = e.target.value || '';
    value =
      type === 'NAME'
        ? capitalize(value.substr(0, maxLengthOfText))
        : value.substr(0, 1).toUpperCase();

    const selectedText = this.state[type] || [];
    selectedText[index] = value;
    const newState = {};
    newState[type] = selectedText;
    this.setState(newState);

    this.props.isIncompleteText && this.props.setIsIncompleteText(false);

    const isValidText = every(selectedText, value => {
      return value ? /^[a-zA-Z]+$/.test(value) : true;
    });
    this.props.setIsInvalidText(!isValidText);

    if (!isValidText) {
      this.props.setSelectedText([]);
      return;
    }

    this.props.setSelectedText([...newState[type]]);
  }

  scrollToTop() {
    if (!isMobile()) {
      return;
    }
    const monogramTextElement = document.getElementsByClassName(
      'monogram-text',
    )[0];
    if (typeof monogramTextElement !== 'undefined') {
      monogramTextElement.scrollIntoView(true);
    }
  }

  render() {
    const { monoStyleChoiceType = '' } = this.props;
    const label = this.getLabel(monoStyleChoiceType);
    const displayText = this.getDisplay();

    return (
      <div className="monogram-text monogram_title_options">
        <div className="monogram-text__user-input grid-45 tablet-grid-45 mobile-grid-50 grid-parent">
          <fieldset>
            <legend>Enter Your {label}:</legend>
            {this.getTextBoxes(monoStyleChoiceType)}
          </fieldset>
          {monoStyleChoiceType !== 'NAME' && (
            <p className="monogram-text__help-text">{MONOGRAM_HELP_TEXT}</p>
          )}
        </div>
        <div className="monogram-text__preview grid-55 tablet-grid-55 mobile-grid-50 grid-parent">
          Preview Your {label}:
          <div className="monogram-text__preview-text">{displayText}</div>
          {monoStyleChoiceType !== 'NAME' && (
            <p className="monogram-text__help-text">
              {MONOGRAM_PREVIEW_HELPTEXT}
            </p>
          )}
        </div>
        <ErrorMessage message={this.getErrorMessage()} />
      </div>
    );
  }
}
