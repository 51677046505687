import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import classNames from 'classnames';
import {
  UltimateInsider,
  AlreadyMember,
  PlanOnlinePurchase,
  MemberBenefits,
  InCircleLevels,
  InCircleTerms,
} from '../widgets';
import './index.scss';
import { openApplyNowModal } from './ApplyNowModal/actions';

const WhyInCircle = ({
  isLoggedIn,
  incircleFeatures,
  incircleLevels,
  incircleTerms,
  openApplyNowModal,
}) => {
  return (
    <div
      className={classNames('why-in-circle', {
        'why-in-circle--is-logged-in': isLoggedIn,
      })}
    >
      <div className="why-in-circle__insider-benefits-container">
        <UltimateInsider
          features={incircleFeatures}
          isLoggedIn={isLoggedIn}
          openApplyNowModal={openApplyNowModal}
        />
        {isLoggedIn ? <PlanOnlinePurchase /> : <AlreadyMember />}
        <MemberBenefits hasNoTableMargin={isLoggedIn} />
      </div>
      <div className="why-in-circle__in-circle-levels-container">
        <InCircleLevels levels={incircleLevels} />
      </div>
      <div className="why-in-circle__in-circle-terms-container">
        <InCircleTerms terms={incircleTerms} />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  incircleFeatures: get(state, 'loyaltyConfig.incircleFeatures', ''),
  incircleLevels: get(state, 'loyaltyConfig.incircleLevels', ''),
  incircleTerms: get(state, 'loyaltyConfig.incircleTerms', ''),
});
const mapDispatchToProps = {
  openApplyNowModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WhyInCircle);
