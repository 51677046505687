import React from 'react';

export const SitemapFooter = ({ assistanceToggle, storesToggle }) => {
  return (
    <div
      id="content-footer"
      className="grid-parent grid-100 sitemap-footer-container"
    >
      <div
        id="sitemap-footer-nav"
        className="grid-parent grid-25 tablet-grid-30 mobile-grid-100 hide-on-mobile"
      >
        &nbsp;
      </div>
      <div
        id="sitemap-footer-content"
        className="grid-75 tablet-grid-70 mobile-grid-100 grid-parent sitemap-footer-content"
      >
        <div className="grid-100 grid-parent gutter-top gutter-bottom">
          <div className="grid-100 tablet-grid-100 grid-parent gutter-bottom sitemap-columns-top">
            <h1 className="sitemap-category">
              <a
                href={
                  assistanceToggle
                    ? 'https://assistance.bergdorfgoodman.com/'
                    : '/assistance/assistance.jsp'
                }
              >
                Assistance
              </a>
            </h1>
            <h3 className="sitemap-link">
              Contact us! 7 Days a week, 6 am to Midnight Central Time
            </h3>

            <div className="grid-33 tablet-grid-33 grid-parent sitemap-column">
              <h3 className="sitemap-link-gray">
                <a href="tel:18888772424">1.888.774.2424</a>
              </h3>
            </div>

            <div className="grid-33 tablet-grid-33 grid-parent sitemap-column">
              <h3 className="sitemap-link-gray">
                <a
                  href={
                    assistanceToggle
                      ? 'https://assistance.bergdorfgoodman.com/contactUs?emailType=custemailforwarding'
                      : '/assistance/contactUs.jsp?emailType=custemailforwarding'
                  }
                >
                  Email
                </a>
              </h3>
            </div>
          </div>

          <div className="grid-100 tablet-grid-100 sitemap-columns-bottom">
            <div className="grid-33 tablet-grid-33 grid-parent gutter-bottom sitemap-column">
              <h3 className="sitemap-link-gray">
                <a
                  href={
                    assistanceToggle
                      ? 'https://assistance.bergdorfgoodman.com/shipping'
                      : '/assistance/assistance.jsp?itemId=cat533701'
                  }
                >
                  Shipping and Order Status
                </a>
              </h3>
              <h3 className="sitemap-link-gray">
                <a
                  href={
                    assistanceToggle
                      ? 'https://assistance.bergdorfgoodman.com/returns'
                      : '/assistance/assistance.jsp?itemId=cat403603'
                  }
                >
                  Returns &amp; Exchanges
                </a>
              </h3>
              <h3 className="sitemap-link-gray">
                <a
                  href={
                    assistanceToggle
                      ? 'https://assistance.bergdorfgoodman.com/shop'
                      : '/assistance/assistance.jsp?itemId=cat403605'
                  }
                >
                  Shop Online and in Store
                </a>
              </h3>
              <h3 className="sitemap-link-gray">
                <a
                  href={
                    assistanceToggle
                      ? 'https://assistance.bergdorfgoodman.com/gifts'
                      : '/assistance/assistance.jsp?itemId=cat533703'
                  }
                >
                  Gift Card Balance
                </a>
              </h3>
              <h3 className="sitemap-link-gray hide-on-desktop hide-on-tablet">
                <br />
              </h3>
            </div>

            <div className="grid-33 tablet-grid-33 grid-parent gutter-bottom sitemap-column">
              <h3 className="sitemap-link-gray">
                <a href="https://www.bergdorfgoodman.com/e/bg360?icid=storeservices?storeId=63/NY">
                  Store Services
                </a>
              </h3>
              <h3 className="sitemap-link-gray">
                <a
                  href={
                    storesToggle
                      ? 'https://stores.bergdorfgoodman.com/stores/bergdorf-goodman-womens-store'
                      : '/stores/bergdorf-goodman-womens-store'
                  }
                >
                  About BG
                </a>
              </h3>
              <h3 className="sitemap-link-gray">
                <a
                  href={
                    storesToggle
                      ? 'https://stores.bergdorfgoodman.com/stores/home'
                      : '/stores/index.jsp'
                  }
                >
                  Bergdorf Goodman Store
                </a>
              </h3>
              <h3 className="sitemap-link-gray">
                <br />
              </h3>
            </div>

            <div className="grid-33 tablet-grid-33 grid-parent gutter-bottom sitemap-column">
              <h3 className="sitemap-link-gray">
                <a href="/e/myaccount/bgcreditcard">BG Credit Card</a>
              </h3>
              <h3 className="sitemap-link-gray">
                <a href="https://www.incircle.com/index.jsp?rid=cat000012">
                  InCircle®
                </a>
              </h3>
              <h3 className="sitemap-link-gray">
                <a
                  href={
                    assistanceToggle
                      ? 'https://assistance.bergdorfgoodman.com/privacy'
                      : '/assistance/assistance.jsp?itemId=cat403606'
                  }
                >
                  Security &amp; Privacy
                </a>
              </h3>
              <h3 className="sitemap-link-gray">
                <br />
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
