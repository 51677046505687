import { SET_EMAIL } from './actions';

const defaultState = {
  email: '',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_EMAIL: {
      return {
        ...state,
        email: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
