import React, { Component } from 'react';
import classNames from 'classnames';
import EcmText from 'ecm/components/EcmText';
import ConnectedEcmAdaptiveImage from 'ecm/components/EcmAdaptiveImage';
import EcmTextWithImage from 'ecm/components/EcmTextWithImage';
import EcmRecommendations from 'ecm/components/EcmRecommendations/ecmRecommendations';
import ConnectedEcmCarousel from 'ecm/components/EcmCarousel';
import EcmSiteTicker from 'ecm/components/EcmSiteTicker/ecmSiteTicker';
import EcmStoreEvents from 'ecm/components/EcmStoreEvents/ecmStoreEvents';
import EcmTextOverImage from 'ecm/components/EcmTextOverImage/ecmTextOverImage';
import EcmVideo from 'ecm/components/EcmVideo';
import EcmHostedVideo from 'ecm/components/EcmHostedVideo';
import EcmSiloThirdParty from 'ecm/components/EcmSiloThirdParty';
// import ConnectedEcmCurratedProducts from 'ecm/components/EcmCurratedProducts';
// eslint-disable-next-line import/no-named-as-default
import EcmFashionCuralate from 'ecm/components/EcmFashionCuralate';
import EcmIframe from 'ecm/components/EcmIframe';
import ConnectedEcmFacets from 'ecm/components/EcmFacets/ecmFacets';
import { isMobile } from 'client-utils/utilities-page';
import get from 'lodash/get';

import WhatsNewComponent from '../../../storefront/components/WhatsNew/whatsNew';
// import ErrorBoundary from '@bgo-ui/common/components/Util/ErrorBoundary';

import { H2 } from '@bgo-ui/common/Styleguide/Typography';

export default class EcmColumn extends Component {
  constructor(props) {
    super(props);
    this.curratedEvent = false;
  }

  componentDidMount() {
    if (this.curratedEvent) {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', true, false);
      window.dispatchEvent(event);
    }
  }

  renderLSS() {
    const {
      ecmCallToActionButtonWithTextToggle,
      ecmContent,
      ecmSlot,
      improvePerformanceOfStyleToggle,
    } = this.props;

    const settings = {
      dots: false,
      infinite: false,
      draggable: true,
      slidesToShow: 6,
      autoplay: false,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 6,
            arrows: false,
            draggable: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: false,
            draggable: false,
          },
        },
        {
          breakpoint: 375,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: false,
            draggable: false,
            dots: false,
          },
        },
      ],
    };

    if (ecmContent && ecmContent.componentType) {
      return (
        <ConnectedEcmCarousel
          ecmCallToActionButtonWithTextToggle={
            ecmCallToActionButtonWithTextToggle
          }
          dataObj={ecmContent}
          ecmSlot={ecmSlot}
          improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
          customClass="lifeStyleShopModule"
          extraSettings={settings}
          showCaption
          disablePlaceholder
        />
      );
    }

    return null;
  }

  renderComponent() {
    const {
      ecmCallToActionButtonWithTextToggle,
      ecmContent,
      ecmSlot,
      improvePerformanceOfStyleToggle,
      removeEcmVideoHeightToggle,
    } = this.props;

    if (ecmContent && ecmContent.componentType) {
      if (
        ecmContent.componentType === 'carousel' &&
        ecmContent.experience === 'bubble'
      ) {
        return this.renderLSS();
      }

      if (
        ecmContent.componentType === 'productrail' &&
        ecmContent.properties.experience === 'catid'
      ) {
        const properties = ecmContent.properties;
        if (properties) {
          const catId = properties.categoryId;
          const textOverImage = {
            imageText: properties.imageText,
            src: properties.imagesrc,
            componentId: ecmContent.componentId,
            linkTo: properties.linkurl,
            properties: {
              mobile: properties.mobile,
              desktop: properties.desktop,
              tablet: properties.tablet,
            },
            noOfProducts: properties.noOfProducts,
          };
          return (
            <WhatsNewComponent
              ecmCallToActionButtonWithTextToggle={
                ecmCallToActionButtonWithTextToggle
              }
              catId={catId}
              ecmSlot={ecmSlot}
              improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
              showCaption
              textOverImage={textOverImage}
              title={ecmContent.properties.title}
              properties={properties}
            />
          );
        }
        return null;
      }
      // if (ecmContent.componentType === 'imagewithtext' && this.props.ecmLayout === 'Z') {
      //   if (ecmContent.slides) {
      //     return (
      // //       <ErrorBoundary>
      //         <Layout
      //           ecmCallToActionButtonWithTextToggle={ecmCallToActionButtonWithTextToggle}
      //           ecmContent={ecmContent}
      //           ecmSlot={ecmSlot}
      //           improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
      //         />;
      // //       </ErrorBoundary>
      //     )
      //   }
      //   return null;
      // }

      // if (ecmContent.componentType === 'imagewithtext' && ecmContent.experience === 'merchendiser') {
      //   return (
      //     <div className="grid-100 tablet-grid-100 mobile-grid-100 grid-parent merchendiser">
      //       {ecmContent.slides && ecmContent.slides.map((obj) => {
      //         return (
      //           <EcmTextWithImage
      //             ecmCallToActionButtonWithTextToggle={ecmCallToActionButtonWithTextToggle}
      //             dataObj={obj}
      //             ecmSlot={ecmSlot}
      //             improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
      //           />);
      //       }
      //       )}
      //     </div>);
      // }

      if (
        ecmContent.componentType === 'carousel' &&
        ecmContent.experience === 'editorial'
      ) {
        return (
          <div className="shopOurEdits-container">
            <H2 className="shopOurEdits-title">
              <span>{ecmContent.title}</span>
            </H2>
            <ConnectedEcmCarousel
              ecmCallToActionButtonWithTextToggle={
                ecmCallToActionButtonWithTextToggle
              }
              dataObj={ecmContent}
              ecmSlot={ecmSlot}
              improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
              customClass="shopOurEdits"
              extraSettings={{
                slidesToShow: 5,
                dots: false,
                infinite: false,
                arrows: false,
                swipe: false,
                touchMove: false,
                draggable: false,
              }}
              showCaption
              disablePlaceholder
            />
          </div>
        );
      }

      switch (ecmContent.componentType) {
        // eslint-disable-next-line no-case-declarations
        case 'video':
          const hideOnMobile = get(
            ecmContent,
            'properties.breakpoints.mobile.hide',
            false,
          );
          // eslint-disable-next-line no-nested-ternary
          return ecmContent.properties &&
            ecmContent.properties.type === 'hosted' ? (
              <EcmHostedVideo
                videoData={ecmContent}
                removeEcmVideoHeightToggle={removeEcmVideoHeightToggle}
              />
          ) : isMobile() && hideOnMobile ? null : (
            <EcmVideo videoData={ecmContent} />
          );
        case 'text':
          return (
            <EcmText
              ecmCallToActionButtonWithTextToggle={
                ecmCallToActionButtonWithTextToggle
              }
              text={ecmContent}
              ecmSlot={ecmSlot}
            />
          );
        case 'adaptive-image':
          return (
            <ConnectedEcmAdaptiveImage
              picture={ecmContent}
              ecmSlot={ecmSlot}
              buttonOverlayElements={ecmContent.calltoactionbuttons}
              improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
            />
          );
        case 'imagewithtext':
          return (
            <EcmTextWithImage
              ecmCallToActionButtonWithTextToggle={
                ecmCallToActionButtonWithTextToggle
              }
              dataObj={ecmContent}
              ecmSlot={ecmSlot}
              improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
              customClass="merchendizer"
            />
          );
        case 'carousel':
          return (
            <ConnectedEcmCarousel
              ecmCallToActionButtonWithTextToggle={
                ecmCallToActionButtonWithTextToggle
              }
              dataObj={ecmContent}
              ecmSlot={ecmSlot}
              improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
              customClass="default"
              componentType={ecmContent.componentType}
              extraSettings={{ infinite: true }}
            />
          );
        case 'siteticker':
          return (
            <EcmSiteTicker
              ecmCallToActionButtonWithTextToggle={
                ecmCallToActionButtonWithTextToggle
              }
              dataObj={ecmContent}
              ecmSlot={ecmSlot}
              improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
            />
          );
        case 'textoverimage':
          return (
            <EcmTextOverImage
              ecmCallToActionButtonWithTextToggle={
                ecmCallToActionButtonWithTextToggle
              }
              dataObj={ecmContent}
              ecmSlot={ecmSlot}
              improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
            />
          );
        case 'iframe':
          return <EcmIframe content={ecmContent} ecmSlot={ecmSlot} />;
        case 'thirdparty':
          switch (ecmContent.vendor) {
            case 'richrelevance':
              return (
                <EcmRecommendations dataObj={ecmContent} ecmSlot={ecmSlot} />
              );
            case 'featuredsubcats':
              return <EcmSiloThirdParty />;
            // case 'curatedproducts':
            //   this.curratedEvent = true;
            //   return (<ConnectedEcmCurratedProducts
            //     key={ecmContent.catId}
            //     navPath={ecmContent.navPath}
            //     catId={ecmContent.catId}
            //     parentCatId={ecmContent.parentCatId}
            //   />);
            case 'curalate':
              return <EcmFashionCuralate />;
            case 'facets':
              return <ConnectedEcmFacets content={ecmContent} />;
            default:
              return <div />;
          }
        case 'storeevents':
          return <EcmStoreEvents dataObj={ecmContent} ecmSlot={ecmSlot} />;
        default:
          return <div />;
      }
    } else {
      return <div />;
    }
  }
  render() {
    const classes = classNames('ecm-container__col', this.props.ecmLayout);
    return (
      <div className={classes}>
        {/* {isMobile && this.props.ecmContent.componentType === 'carousel' && this.props.ecmContent.experience === 'lifestyleshop' && (
          this.renderLSS()
        )} */}
        {this.renderComponent()}
      </div>
    );
  }
}
