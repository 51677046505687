import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import './PasswordInputWidget.scss';
import { RegisterInputField } from '../../common/RegisterInputField/RegisterInputField';

const PasswordStrengthMeter = ({ level, visible }) => {
  let strength = 'Weak';
  let width = 10;
  let progressModClass = 'password-strength-meter__progress-bar--weak';
  if (level === 1) {
    width = 15;
  }
  if (level === 2) {
    width = 35;
  }
  if (level === 3) {
    width = 55;
  }
  if (level >= 4) {
    strength = 'Strong';
    width = 100;
    progressModClass = 'password-strength-meter__progress-bar--strong';
  }

  const mainClass = classNames('password-strength-meter', {
    'password-strength-meter--visible': visible,
  });

  return (
    <div className={mainClass}>
      <div className="password-strength-meter__progress-container" aria-hidden>
        <div
          className={`password-strength-meter__progress-bar ${progressModClass}`}
          style={{
            width: `${width}%`,
          }}
        />
      </div>
      <div className="password-strength-meter__text">
        Password strength: {strength}
      </div>
    </div>
  );
};

export class PasswordInputWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      pwHasAtLeastEightChars: false,
      pwHasAtLeastOneNumber: false,
      pwHasAtLeastOneUpperCase: false,
      pwHasNoMoreThanTwoConsecutiveCharacters: false,
    };
  }

  checkValidPassword = () => {
    const {
      password,
      pwHasAtLeastEightChars,
      pwHasAtLeastOneNumber,
      pwHasAtLeastOneUpperCase,
      pwHasNoMoreThanTwoConsecutiveCharacters,
    } = this.state;

    return (
      password &&
      pwHasAtLeastEightChars &&
      pwHasAtLeastOneNumber &&
      pwHasAtLeastOneUpperCase &&
      pwHasNoMoreThanTwoConsecutiveCharacters
    );
  };

  validatePassword = event => {
    this.props.onChange(event);
    const enteredPassword = event.target.value;
    const regexAtLeastEightChars = /^(.{8,})$/;
    const regexAtLeastOneNumber = /([0-9])/;
    const regexAtLeastOneUpperCase = /([A-Z])/;
    const regexForHavingMoreThanTwoConsecutiveChars = /(?:(.)\1\1)+/;

    this.setState(
      {
        password: enteredPassword,
        confirmPwMatches: enteredPassword === this.state.confirmPassword,
        pwHasAtLeastEightChars: regexAtLeastEightChars.test(enteredPassword),
        pwHasAtLeastOneNumber: regexAtLeastOneNumber.test(enteredPassword),
        pwHasAtLeastOneUpperCase: regexAtLeastOneUpperCase.test(
          enteredPassword,
        ),
        pwHasNoMoreThanTwoConsecutiveCharacters:
          !isEmpty(enteredPassword) &&
          !regexForHavingMoreThanTwoConsecutiveChars.test(enteredPassword),
      },
      () => {
        this.props.onValidChange(this.checkValidPassword());
      },
    );
  };

  render() {
    const { name, title, submitted } = this.props;
    const {
      password,
      pwHasAtLeastEightChars,
      pwHasAtLeastOneNumber,
      pwHasAtLeastOneUpperCase,
      pwHasNoMoreThanTwoConsecutiveCharacters,
    } = this.state;

    const passwordErrorMessage = () => {
      if (submitted) {
        if (!password) {
          return 'Passwords must be populated.';
        }
        if (!this.checkValidPassword()) {
          return 'Your password does not meet the minimum requirements.';
        }
      }
      return null;
    };

    const pwStrengthVisible = password && password.length > 0;
    let pwStrengthLevel = 0;
    if (pwHasAtLeastEightChars) pwStrengthLevel++;
    if (pwHasAtLeastOneNumber) pwStrengthLevel++;
    if (pwHasAtLeastOneUpperCase) pwStrengthLevel++;
    if (pwHasNoMoreThanTwoConsecutiveCharacters) pwStrengthLevel++;

    return (
      <div className="password-widget">
        <RegisterInputField
          title={title}
          error={passwordErrorMessage()}
          name={name}
          maxLength={40}
          type="password"
          value={password}
          onChange={this.validatePassword}
          noBottomMargin
        />
        <PasswordStrengthMeter
          level={pwStrengthLevel}
          visible={pwStrengthVisible}
        />
        <div className="password-widget__requirements-box">
          <p>Password must contain</p>
          <ul className="requirements">
            <li className="flex-container">At least 8 characters</li>
            <li className="flex-container">At least 1 number</li>
            <li className="flex-container">At least 1 capital letter</li>
            <li className="flex-container">
              No more than 2 consecutive repeating characters
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
