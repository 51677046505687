import {
  getPDPImageSmallUrl,
  getPDPImageZoomUrl,
} from 'bgo-common/components/Util/product-cloudinary';
import Image from 'pdp/components/ProductPage/components/Image/image';
import React, { Component } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import classnames from 'classnames';
// import get from 'lodash/get';
import './productZoomModal.scss';
// import {
//     NO_IMAGE_AVAILABLE_CDN
//   } from '../../../ProductPage/components/Image/image';

SwiperCore.use([Navigation]);
class ProductZoomModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: props.index,
      images: props.images,
      count: 0,
      scrolledToActive: false,
    };
    this.moveImage = this.moveImage.bind(this);
    this.handler = this.handler.bind(this);
  }

  componentDidMount() {
    if (window.IntersectionObserver) {
      const sentinel = document.querySelectorAll('.image');
      if (sentinel.length > 0) {
        this.scrollImage(this.state.activeIndex);
        // eslint-disable-next-line compat/compat
        const observer = new IntersectionObserver(this.handler);
        sentinel.forEach(image => observer.observe(image));
        const { zoomPdpToggle } = this.props;
        if (zoomPdpToggle) {
          if (
            !(
              sentinel[this.state.activeIndex]?.naturalWidth &&
              sentinel[this.state.activeIndex]?.naturalHeight
            )
          ) {
            this.setState({ scrolledToActive: false });
          } else {
            this.scrollToActiveImage();
          }
        }
      }
    }
  }

  componentDidUpdate() {
    const { scrolledToActive } = this.state;
    const { zoomPdpToggle } = this.props;
    if (!scrolledToActive && zoomPdpToggle) {
      this.scrollToActiveImage();
    }
  }

  scrollToActiveImage = () => {
    const { zoomPdpToggle } = this.props;
    const sentinel = document.querySelectorAll('.image');
    if (zoomPdpToggle) {
      if (
        sentinel[this.state.activeIndex]?.naturalWidth &&
        sentinel[this.state.activeIndex]?.naturalHeight
      ) {
        this.moveImage(this.state.activeIndex);
        this.setState({ scrolledToActive: true });
      } else {
        // Calls after 200ms as image is not yet rendered
        setTimeout(this.scrollToActiveImage, 200);
      }
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.activeIndex >= 0;
  }

  handler(entries) {
    if (entries.length === 1) {
      for (const entry of entries) {
        const index =
          Number(entry.target.name[entry.target.name.length - 1]) - 1;
        if (entry.intersectionRatio > 0 && entry.isIntersecting) {
          this.setState({ activeIndex: index });
        }
      }
    }
  }

  scrollImage(index) {
    const ele = document.getElementsByClassName('image')[index];
    if (ele) {
      let height = ele.clientHeight;
      if (height === 0) {
        height = 1761;
      }
      document
        .getElementsByClassName('nm-modal__content')[0]
        .scroll(0, index * height);
    }
  }

  moveImage(index) {
    this.setState({ activeIndex: index }, () => this.scrollImage(index));
  }

  render() {
    return (
      <div>
        {this.props?.mobileZoom && (
          <div className="zoom-modal-fraction">
            {this.state?.activeIndex}/{this.state.images.length}
          </div>
        )}
        {!this.props?.mobileZoom && (
          <div className="thumbnailContainer hide-on-mobile scroll-con">
            <div className="hidden-scrollbar">
              {this.state.images &&
                this.state.images.map((image, index) => {
                  return (
                    <Image
                      className={
                        this.state.activeIndex === index ? 'addBorder' : ''
                      }
                      alt={`Image ${index + 1} of ${this.state.images.length}`}
                      isCloudinary={this.props.isCloudinary}
                      src={getPDPImageSmallUrl(image, this.props.isCloudinary)}
                      isPdp
                      key={index}
                      onClick={() => this.moveImage(index)}
                      reRender
                    />
                  );
                  // return (<img
                  //   className={this.state.activeIndex === index ? 'addBorder' : ''}
                  //   src={getPDPImageSmallUrl(image, this.props.isCloudinary).desktop.imageSrc}
                  //   //src={get(image, this.props.isCloudinary ? 'dynamic.url' : 'thumbnail.url', NO_IMAGE_AVAILABLE_CDN)}
                  //   alt={`Image ${index + 1} of ${this.state.images.length}`} key={index} onClick={() => this.moveImage(index)}
                  // />);
                })}
            </div>
          </div>
        )}
        <div
          className={classnames('imageContainer', {
            'hide-image':
              this.props.zoomPdpToggle &&
              (!this.state.scrolledToActive && !this.props?.mobileZoom),
          })}
        >
          {!this.props?.mobileZoom &&
            this.state.images &&
            this.state.images.map((image, index) => {
              return (
                <Image
                  className="image"
                  alt={`Image ${index + 1} of ${this.state.images.length}`}
                  src={getPDPImageZoomUrl(image, this.props.isCloudinary)}
                  isPdp
                  isCloudinary={this.props.isCloudinary}
                  key={index}
                  name={`image ${index + 1}`}
                />
              );

              // return (<img
              //   className="image" src={get(image, this.props.isCloudinary ? 'dynamic.url' : 'large.url', NO_IMAGE_AVAILABLE_CDN)}
              //   alt={`Image ${index + 1} of ${this.state.images.length}`} name={`image ${index + 1}`} key={index}
              // />);
            })}
          {this.props?.mobileZoom && (
            <div>
              <Swiper
                initialSlide={this.state.activeIndex}
                navigation={this.state.images.length > 1}
                allowTouchMove={!(this.state.images.length === 1)}
                className="swiper-mobile-zoom"
                loop
                lazy
                onSlideChange={swiper => {
                  this.setState({ activeIndex: swiper.realIndex + 1 });
                }}
                spaceBetween={30}
                zoom
              >
                {this.state.images &&
                  this.state.images.map((image, index) => (
                    <SwiperSlide>
                      <Image
                        alt={`Image ${index + 1} of ${
                          this.state.images.length
                        }`}
                        src={getPDPImageZoomUrl(image, this.props.isCloudinary)}
                        isCloudinary={this.props.isCloudinary}
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ProductZoomModal;
