export const overridenCountryCurrencyData =
  [
    {
      countryCode: 'CA',
      countryName: 'Canada',
      currencyCode: 'USD',
      currencyName: 'US Dollar',
      url: '/en-ca'
    },
  ];
