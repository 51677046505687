import React from 'react';
import { object } from 'prop-types';
import get from 'lodash/get';
import merge from 'lodash/merge';
import Slider from 'react-slick';
import ContentItem from '../../../contentItem/contentItem';
import { defaultTrackingTags } from '../../../../constants/constants';
import { getTags } from '../../../utils';
import './editorialCarousel.scss';

const CN = 'editorial-carousel';
const CN_TRACK = `${CN}__track`;

/**
 * @function EditorialCarousel
 * @param {Object} props.cmsContentItem
 * @param {Object} props.parentTrackingTags
 * @returns {React.ReactElement}
 */
const EditorialCarousel = ({ cmsContentItem, parentTrackingTags }) => {
  const cmsContentFields = get(cmsContentItem, 'fields', {});
  const cmsContentAssets = get(cmsContentFields, 'components', []);
  const title = get(cmsContentFields, 'title', '');

  // Set up tracking tags
  const { trackingTags } = cmsContentFields;
  const currentTags = getTags(trackingTags);
  const mergedTrackingTags = merge({}, parentTrackingTags, currentTags);

  const settings = {
    arrows: false,
    className: CN_TRACK,
    infinite: false,
    slidesToShow: 5,
    swipeToSlide: true,
  };

  return (
    <React.Fragment>
      <div className={CN}>
        {title && <h3 className={`${CN}__title`}>{title}</h3>}

        <Slider {...settings}>
          {cmsContentAssets.map(contentItem => {
            if (contentItem.fields) {
              return (
                <ContentItem
                  cmsContentItem={contentItem}
                  parentTrackingTags={mergedTrackingTags}
                />
              );
            }
            return null;
          })}
        </Slider>
      </div>
    </React.Fragment>
  );
};

EditorialCarousel.defaultProps = {
  parentTrackingTags: defaultTrackingTags,
};

EditorialCarousel.propTypes = {
  cmsContentItem: object,
  parentTrackingTags: object,
};

export default EditorialCarousel;
