export const checkClientSupportForApplePayment = () => {
  const isHostedOnHttps = window?.location?.protocol === 'https:';
  if (!isHostedOnHttps) return false;

  const isGlobalApplePayObjectAvailable = window?.ApplePaySession;
  if (!isGlobalApplePayObjectAvailable) return false;

  // eslint-disable-next-line no-undef
  const isApplePayAPIAvailable = ApplePaySession;
  if (!isApplePayAPIAvailable) return false;

  const isSupportedByThisBrowser = window?.ApplePaySession.supportsVersion(1);
  if (!isSupportedByThisBrowser) return false;

  // eslint-disable-next-line no-undef
  const canAppleMakePayments = ApplePaySession.canMakePayments();
  if (!canAppleMakePayments) return false;

  return true;
};

const SESSION_STORAGE_STORAGE_METHODS = key => ({
  set: objectToMemorise =>
    sessionStorage.setItem(key, JSON.stringify(objectToMemorise)),
  get: () => {
    const SSdata = sessionStorage.getItem(key);
    return SSdata ? JSON.parse(SSdata) : null;
  },
  remove: () => sessionStorage.removeItem(key),
});

export const APPLE_PAY_SESSION_STORAGE = {
  error: SESSION_STORAGE_STORAGE_METHODS('ApplePay_error'),
  paymentEvent: SESSION_STORAGE_STORAGE_METHODS(
    'ApplePay_paymentEvent_OnPaymentAuthorised',
  ),
};
