import React from 'react';
import AppWrapper from '../common/app-common';
import IncircleTermsAndConditions from '../../myaccount/pages/BgCreditCardPage/NewBgCreditCardPage/IncircleTerms';

export default function AppIncircleTermsWrapper() {
  return (
    <AppWrapper forceFullBleed>
      <IncircleTermsAndConditions />
    </AppWrapper>
  );
}
