import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { isLoadingFinished } from '@bgo-ui/common/client/utilities/utilities-api';
import { replaceContentVariable } from '../../../../../../../../utilities/copy-helpers';
import Copy from '../../../../../../../common/Copy/Copy';
import TotpConfirmation from '../../../TotpConfirmation/TotpConfirmation';

export const DumbChangePasswordConfirmation = props => {
  const { email } = props;
  const description = get(
    props.myAccountContent,
    'CHANGE-PASSWORD-CONFIRMATION',
    {},
  );
  const descriptionWithEmail = replaceContentVariable(
    description,
    '__email__',
    email,
  );

  return (
    <TotpConfirmation>
      <Copy
        content={descriptionWithEmail}
        loaded={props.isContentLoaded}
        fallback={(
          <React.Fragment>
            <p>
              We&apos;ll send an email to {email} with a verification code to
              change your password. For security purposes, please be aware you
              will have 5 minutes to verify the code.
            </p>
            <p>Would you like to proceed to change your password?</p>
          </React.Fragment>
        )}
      />
    </TotpConfirmation>
  );
};

DumbChangePasswordConfirmation.propTypes = {
  myAccountContent: PropTypes.object.isRequired,
  isContentLoaded: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  myAccountContent: get(state, 'myaccount.myAccountContent', {}),
  isContentLoaded: isLoadingFinished(state, 'content'),
  email: get(state, 'myaccount.myAccountDetails.fields.email', null),
});

const ChangePasswordConfirmation = connect(mapStateToProps)(
  DumbChangePasswordConfirmation,
);

export default ChangePasswordConfirmation;
