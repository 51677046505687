import React from 'react';
import { connect } from 'react-redux';
import { shouldLoad } from 'universal/http-client';
import { H2 } from 'bgo-common/Styleguide/Typography';
import { getWhatsNewData } from './whatsNew-actions';
import ConnectedEcmCarousel from '../../../ecm/components/EcmCarousel';
import { referralSourceClicked } from '../../../../client/common/actions/actions-page';

const getSliderSetting = isTouchDevice => {
  return {
    slidesToShow: 6,
    slidesToScroll: 6,
    dots: false,
    infinite: false,
    draggable: true,
    autoplay: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
          draggable: false,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          arrows: !isTouchDevice,
          draggable: true,
          dots: false,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          // arrows: true,
          draggable: true,
          infinite: false,
        },
      },
    ],
  };
};

const handleClick = elemRef => {
  const target = elemRef.target;
  if (target.tagName === 'A' || target.tagName === 'P') {
    referralSourceClicked('NEW_ARRIVALS', target.textContent);
  } else {
    referralSourceClicked('NEW_ARRIVALS', 'product');
  }
};

const WhatsNewComponent = ({
  ecmCallToActionButtonWithTextToggle,
  ecmSlot,
  improvePerformanceOfStyleToggle,
  showCaption,
  contentData,
  catId,
  textOverImage,
  title,
  apiWhatsNew,
  getWhatsNewData,
  device,
}) => {
  if (shouldLoad(apiWhatsNew)) {
    getWhatsNewData(catId, textOverImage);
  }

  if (contentData.data && contentData.data.length) {
    const finalData = {
      componentId: textOverImage.componentId,
      componentType: 'carousel',
      slides: contentData.data,
    };
    return (
      <React.Fragment>
        <div className="whatsNewModule-title">
          <H2>{title}</H2>
        </div>
        <div onClick={event => handleClick(event)}>
          <ConnectedEcmCarousel
            ecmCallToActionButtonWithTextToggle={
              ecmCallToActionButtonWithTextToggle
            }
            dataObj={finalData}
            ecmSlot={ecmSlot}
            improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
            customClass="whatsNewModule"
            extraSettings={getSliderSetting(device.isMobile)}
            showCaption={showCaption}
          />
        </div>
      </React.Fragment>
    );
  }
  return null;
};

const mapStateToProps = state => ({
  contentData: state.whatsNew,
  apiWhatsNew: state.api.whatsnew,
  device: state.device,
});

const mapDispatchToProps = {
  getWhatsNewData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WhatsNewComponent);
