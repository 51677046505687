import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import get from 'lodash/get';
import { isLoadingFinished } from '@bgo-ui/common/client/utilities/utilities-api';
import {
  PAGE_ID_LOGIN,
  setPageId,
} from '@bgo-ui/common/client/common/actions/actions-page';
import { validateEmail } from '@bgo-ui/common/client/utilities/utilities-amplify';
import { resolvedLoginPage, forgotPassword } from '../../../actions';
import FormikTextField from '../../../../common/Forms/FormikTextField/FormikTextField';
import SubmitButton from '../../../../common/Forms/FormButtons/SubmitButton/SubmitButton';
import CancelButton from '../../../../common/Forms/FormButtons/CancelButton/CancelButton';
import Copy from '../../../../common/Copy/Copy';
import CheckYourEmail from '../CheckYourEmail/CheckYourEmail';

import { validationErrors, forgotPasswordError } from '../../../constants';
import { LogoContext } from '../../common/LoginPageCommonLayout/LoginPageCommonLayout';
import './NewForgotPassword.scss';

const NewForgotPassword = ({
  error,
  isLoading,
  setForgotPassword,
  forgotPassword,
  isResolved,
  isContentLoaded,
  loginContent,
  setPageId,
  resolvedLoginPage,
}) => {
  const initialFormValues = { email: '' };
  const [isLinkSent, setLink] = useState(false);
  const [email, setEmail] = useState('');
  const [showError, setShowError] = useState(true);
  const { setMobileLogo } = useContext(LogoContext);

  const validateForm = values => {
    const errors = {};
    if (!values.email) {
      errors.email = validationErrors.emptyEmail;
    } else if (!validateEmail(values.email)) {
      errors.email = validationErrors.invalidEmail;
    }
    return errors;
  };

  useEffect(() => {
    setMobileLogo(false);
  }, []);

  useEffect(() => {
    if (!isLoading && isResolved) {
      setLink(true);
    }
  }, [isLoading, isResolved]);

  const submitForm = values => {
    setEmail(values.email);
    setShowError(true);
    forgotPassword({ email: values.email }, true);
  };

  const handleCancel = e => {
    setPageId(PAGE_ID_LOGIN);
    resolvedLoginPage(true);
    setMobileLogo(true);
    setForgotPassword(false);
  };

  if (isLinkSent) {
    return <CheckYourEmail email={email} />;
  }

  return (
    <div className="new-forgot-password">
      <div className="new-forgot-password__info">
        <Copy
          content={get(loginContent, 'FORGOT-PASSWORD-DEFAULT')}
          loaded={isContentLoaded}
          fallback={(
            <React.Fragment>
              <h3>Forgot Password</h3>
              <p>Please enter the email address you used to sign in.</p>
            </React.Fragment>
          )}
        />
      </div>
      <Formik
        initialValues={initialFormValues}
        validate={validateForm}
        onSubmit={submitForm}
        onReset={handleCancel}
      >
        {({ values, handleReset }) => (
          <Form autoComplete="off">
            <FormikTextField
              name="email"
              type="email"
              id="email-field"
              placeholder="Email address"
              onFocus={() => setShowError(false)}
              fontSize
            />
            {error && showError && (
              <p className="new-forgot-password__error">
                {forgotPasswordError}
              </p>
            )}
            <SubmitButton
              isSubmitting={isLoading}
              type="submit"
              caption="Reset password"
              additionalClasses="new-forgot-password__submit-btn"
            />
            <CancelButton
              caption="Cancel"
              onClick={handleReset}
              additionalClasses="new-forgot-password__cancel-btn"
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = state => ({
  isLoading: get(state, 'login.forgotPassword.isLoading', false),
  isResolved: get(state, 'api.forgot_password.resolved', false),
  error: get(state, 'login.forgotPassword.error', false),
  loginContent: get(state, 'login.loginContent', {}),
  isContentLoaded: isLoadingFinished(state, 'login_content'),
});

const mapDispatchToProps = {
  forgotPassword,
  setPageId,
  resolvedLoginPage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewForgotPassword);
