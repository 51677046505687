import { connect } from 'react-redux';


const Toggle = ({ featureToggle, children, fallback, toggles = {} }) => {
  return (
    toggles[featureToggle] ? children : fallback
  );
};

const mapStateToProps = (state, ownProps) => {
  return ({
    featureToggle: ownProps.feature,
    children: ownProps.children,
    fallback: ownProps.fallback || false,
    toggles: state.toggles,
  });
};

export default connect(mapStateToProps)(Toggle);

