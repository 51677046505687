import React from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Field } from 'formik';

import './FormikDropDown.scss';

const FormikDropDown = props => {
  const { name, placeholder, valuesArray, id, onChange, fontSize } = props;

  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        const isError =
          form.submitCount > 0 && (meta.error === '' ? true : meta.error);
        const containerClassName = classNames(
          'form-select',
          isError && 'error',
        );
        const placeholderClassName = classNames(
          'form-select__placeholder',
          field.value && 'value-selected',
          isError && 'error',
        );
        const inputClassName = classNames(
          'form-select__input',
          isError && 'error',
          fontSize && 'inputFont',
        );
        return (
          <div className="form-field">
            <div className={containerClassName}>
              <label htmlFor={id} className="form-select__label">
                <select
                  id={id}
                  name={name}
                  onChange={
                    onChange
                      ? e => {
                          form.handleChange(e);
                          onChange(e);
                        }
                      : form.handleChange
                  }
                  onBlur={form.handleBlur}
                  className={inputClassName}
                  value={props.value}
                >
                  {valuesArray.map(item => {
                    return (
                      <option
                        key={`${item.value} ${item.name}`}
                        value={item.value}
                        label={item.name}
                        hidden={item.value === '' && item.name === ''}
                      >
                        {item.name}
                      </option>
                    );
                  })}
                  ;
                </select>
                <span className={placeholderClassName}>{placeholder}</span>
              </label>
            </div>
            {isError && !isEmpty(meta.error) && (
              <div className="form-select__error-message">{meta.error}</div>
            )}
          </div>
        );
      }}
    </Field>
  );
};

export default FormikDropDown;
