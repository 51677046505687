import httpWithLogging from 'universal/http-client';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const SET_OVERFLOWED = 'SET_OVERFLOWED';

export function openModal(options) {
  return (dispatch, getState) => {
    if (options.url) {
      const state = getState();
      const requestApi = httpWithLogging(state);
      return requestApi.get(NMConfig.API_REFRESHABLE_CONTENT, {
        params: { refreshablePath: options.url }
      })
      .then((response) =>
        dispatch({ type: OPEN_MODAL, payload: { ...options, html: response.data } }));
    } else {
      return dispatch({ type: OPEN_MODAL, payload: options });
    }
  };
}

export function closeModal(modalType) {
  return dispatch => dispatch({ type: CLOSE_MODAL, payload: modalType });
}

export function setOverflowed(overflowed) {
  return dispatch => dispatch({ type: SET_OVERFLOWED, payload: overflowed });
}
