import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  hideSpinner,
  showSpinner,
} from 'shared/components/Spinner/spinner-actions';
import { entryEmailValidation } from 'client-utils/utilities-amplify';
import { enterEmailContent, commonContent } from '../../constants';
import LoginInputField from '../LoginInputField';
import FormDisclaimer from '../FormDisclaimer';
import SubmitButton from '../../../../../../../../common/Forms/FormButtons/SubmitButton/SubmitButton';
import { checkUserExists, clearProcessEmailError } from '../../actions';

const EnterEmailModalContent = ({
  email,
  isLoading,
  checkUserExists,
  clearProcessEmailError,
  startLoading,
  endLoading,
}) => {
  const [emailInputValue, setEmailInputValue] = useState(email);
  const [displayError, setDisplayError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const clearErrorMessage = () => {
    setDisplayError(false);
    setErrorMessage('');
    clearProcessEmailError();
  };

  const handleEmailInputChange = e => {
    setEmailInputValue(e.target.value);
  };

  const errorCallback = () => {
    endLoading();
    setDisplayError(true);
    setErrorMessage(commonContent.serverError);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const email = emailInputValue;

    if (!entryEmailValidation(email)) {
      setDisplayError(true);
      setErrorMessage(enterEmailContent.error);
    } else {
      clearErrorMessage();
      startLoading();
      checkUserExists(email, errorCallback);
    }
  };

  return (
    <div className="enter-email-modal-content">
      <div className="enter-email-modal-content__heading">
        <h2 className="enter-email-modal-content__heading__title">
          {enterEmailContent.title}
        </h2>
        <p className="enter-email-modal-content__heading__desc">
          {enterEmailContent.desc}
        </p>
      </div>
      <form onSubmit={handleSubmit} className="enter-email-modal-content__form">
        <div className="enter-email-modal-content__form__inputs">
          <LoginInputField
            id="enter-email-input"
            type="email"
            name="email"
            placeholder="Email"
            clearErrorMessage={clearErrorMessage}
            value={emailInputValue}
            onChange={handleEmailInputChange}
          />
          {displayError && (
            <div className="enter-email-modal-content__form__inputs__error">
              {errorMessage}
            </div>
          )}
        </div>
        <SubmitButton
          additionalClasses="enter-email-modal-content__form__submit-btn"
          isSubmitting={isLoading}
          caption="Next"
          type="submit"
        />
        <FormDisclaimer />
      </form>
    </div>
  );
};

const mapStateToProps = state => ({
  email: state.applyNowModal.email,
  isLoading: state.applyNowModal.processEmail.isLoading,
});
const mapDispatchToProps = {
  checkUserExists,
  clearProcessEmailError,
  startLoading: showSpinner,
  endLoading: hideSpinner,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EnterEmailModalContent);
