import React from 'react';
import { dangerousProperty } from 'client-utils/utilities-html-sanitizer';
import Toggle from 'shared/components/Toggle/toggle';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Designer from 'pdp/components/ProductPage/components/ProductDetails/components/Heading/designerName';
import { setActivePDPTab } from 'pdp/components/ProductPage/actions';

export const productTitle = product => {
  const nameHtml = product.nameHtml || '';
  return (
    nameHtml && (
      <span
        className="product-heading__name__product"
        dangerouslySetInnerHTML={dangerousProperty(nameHtml)}
      />
    )
  );
};

const ProductHeading = ({
  product = {},
  isGroupPDP = false,
  setActivePDPTab = null,
}) => {
  const { designer = {}, isZeroDollarProduct = false, details = {} } = product;
  const { suppressCheckout = false } = details;
  const showRating = !isGroupPDP && !suppressCheckout && !isZeroDollarProduct;
  return (
    <div className="product-heading__name">
      <Toggle feature="REVIEW_RATINGS">
        {showRating && (
          <span
            className="TurnToReviewsTeaser"
            onClick={() => setActivePDPTab(4)}
          />
        )}
      </Toggle>
      <h1>
        <Designer
          name={designer.name}
          designerBoutiqueUrl={designer.designerBoutiqueUrl}
          isChanel={product.isChanel}
        />
        {productTitle(product)}
      </h1>
    </div>
  );
};

const mapStateToProps = state => {
  const productCatalog = state.productCatalog || {};
  const isGroup = get(productCatalog, 'product.isGroup', false);

  return {
    isGroupPDP: isGroup,
  };
};

const mapDispatchToProps = (dispatch, { product: { id: productId } }) => ({
  setActivePDPTab: index => dispatch(setActivePDPTab(productId, index)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductHeading);
