import React from 'react';
import AppLoginCommon from './app-new-login-common';
import AccountAccess from '../../../login/AccountAccess/AccountAccess';

export default function AppNewLogin(props) {
  return (
    <AppLoginCommon {...props}>
      <AccountAccess newLayout {...props} />
    </AppLoginCommon>
  );
}
