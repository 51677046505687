import { Link } from 'react-router';
import React from 'react';
import { connect } from 'react-redux';
import { logout } from 'client-utils/utilities-amplify';
import './MyAccountNavigation.scss';
import togglesRepository from '@bgo-ui/common/server/repositories/togglesRepository';

const linkItems = [
  {
    path: '/e/myaccount/myorders',
    title: 'My orders',
  },
  {
    path: '/e/myaccount/accountdetails',
    title: 'Account details',
  },
  {
    path: '/e/myaccount/paymentdetails',
    title: 'Payment details',
  },
  {
    path: '/e/myaccount/addressbook',
    title: 'Address book',
  },
  {
    path: '/e/myaccount/bgcreditcard',
    title: 'BG Credit Card',
  },
  {
    path: '/e/myaccount/wishlist',
    title: 'Wishlist',
  },
  {
    path: '/e/myaccount/myrewards',
    title: 'My Rewards',
    notification: true,
  },
];

const commLink = '/e/my/communication/preferences';

const MyAccountNavigation = ({
  onNavClick,
  rewardsLength,
  userName,
  myAccountUser,
  disableATGToggle,
  bgoLoyaltyToggle,
  isCommunicationPrefEnable,
}) => {
  const updatedLinkItems = !bgoLoyaltyToggle
    ? linkItems
    : linkItems.map(item => {
        if (item.path === '/e/myaccount/myrewards') {
          return { ...item, title: 'My Gift Cards' };
        }
        return item;
      });

  if (isCommunicationPrefEnable) {
    const menuItems = updatedLinkItems.flatMap(item => item.path);
    if (menuItems.indexOf(commLink) === -1) {
      updatedLinkItems.push({
        path: commLink,
        title: 'Communication Preferences',
      });
    }
  }

  return (
    <nav className="my-account-navigation">
      <h1 className="my-account-navigation__main-header mobile-only">
        My account
      </h1>
      <h2 className="my-account-navigation__greeting">
        Hi, {myAccountUser || userName}
      </h2>
      <ul className="my-account-navigation__nav-list">
        {updatedLinkItems.map(item => (
          <li className="my-account-navigation__nav-item" key={item.path}>
            <Link
              to={item.path}
              activeClassName="my-account-navigation__nav-link--active"
              onClick={onNavClick}
              onlyActiveOnIndex
            >
              <span className="my-account-navigation__nav-link-text">
                {item.title}
              </span>
              {item.notification && rewardsLength > 0 && !bgoLoyaltyToggle && (
                <span className="my-account-navigation__nav-link-notify">
                  {rewardsLength}
                </span>
              )}
            </Link>
          </li>
        ))}
        <li className="my-account-navigation__nav-item my-account-navigation__sign-out">
          <span
            className="my-account-navigation__nav-link-text"
            onClick={() => logout(disableATGToggle)}
            onKeyPress={() => logout(disableATGToggle)}
          >
            Sign Out
          </span>
        </li>
      </ul>
    </nav>
  );
};

const mapStateToProps = state => ({
  rewardsLength: state?.rewards?.length || 0,
  userName: state?.user?.name || '',
  myAccountUser: state?.myaccount?.myAccountDetails?.fields?.firstName || '',
  disableATGToggle: state?.toggles?.DISABLE_ATG_CALLS || false,
  bgoLoyaltyToggle: state?.toggles?.BGO_LOYALTY_NEW || false,
  isCommunicationPrefEnable: state?.toggles?.BGO_COMMUNICATION_PREF || false,
});

export default connect(mapStateToProps)(MyAccountNavigation);
