import React, { useEffect, useState } from 'react';
import { Link } from 'react-router';
import httpWithLogging from 'universal/http-client';
import './MyCommunicationPrefPage.scss';
import { defaultApiTimeout } from '../../../bg360/constants';

function CommunicationPrefPage({ emailID, getUserData, openMobileNav }) {
  const [email, setEmail] = useState(false);
  const [isloading, setIsLoading] = useState(true);
  const handleEmailApi = async () => {
    try {
      const requestApi = httpWithLogging({}, defaultApiTimeout, true);
      const userData = await getUserData();
      const headers = {
        Authorization: `Bearer ${userData.idToken}`,
        'Content-Type': 'application/json',
      };

      const payload = {
        email: emailID,
      };
      const resp = await requestApi.post(
        `${NMConfig.API_COMM_PREF}/communication/${userData.ucaId}/preferences/fetch`,
        payload,
        { headers },
      );
      // for first time response in empty object
      if (typeof resp?.data?.preferences.marketing.email.optout === 'boolean') {
        setEmail(!resp?.data?.preferences.marketing.email.optout);
      } else {
        setEmail(false);
      }
    } catch (error) {
      setEmail(false);
      console.debug('Somethng went wrong', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleEmailApi();
  }, []);

  const handlSubmit = async () => {
    try {
      const requestApi = httpWithLogging({}, defaultApiTimeout, true);
      const userData = await getUserData().then(userData => userData);
      const headers = {
        Authorization: `Bearer ${userData.idToken}`,
        'Content-Type': 'application/json',
      };
      const dt = new Date();

      const payload = {
        email: emailID,
        ucaId: userData.ucaId,
        preferences: {
          marketing: { email: { optout: !email }, text: { optout: true } },
        },
        channel: 'ONLINE',
        updatedTime: dt.toISOString(),
        source: 'Account:Communication Preferences',
      };
      await requestApi.put(
        `${NMConfig.API_COMM_PREF}/communication/${userData.ucaId}/preferences`,
        payload,
        { headers },
      );
    } catch (error) {
      setEmail(false);
      console.debug('Somethng went wrong', error);
    }
  };
  return (
    <div className="Comm-pref-container">
      {!isloading && (
        <>
          <div className="comm_header not-mobile">
            COMMUNICATION PREFERENCES
          </div>
          <div className="my-account-mobile-back-header mobile-only">
            <div
              className="my-account-mobile-back-header__back-btn"
              role="button"
              aria-label="Back"
              onClick={openMobileNav}
              onKeyPress={openMobileNav}
              tabIndex="0"
            >
              <i className="my-account-mobile-back-header__back-btn-icon" />
            </div>
            <h1 className="my-account-mobile-back-header__text">
              COMMUNICATION PREFERENCES
            </h1>
          </div>
          <div className="comm_sub_header">CHANNEL</div>
          <div className="comm_text">
            Select your preferred communication method to stay up-to-date with
            marketing communications about products, services and promotions.
            You can add or update your email and phone number in your{' '}
            <Link to="/e/myaccount/accountdetails">Account Overview.</Link>
          </div>
          <label className="comm-checkbox-wrapper" htmlFor="email">
            <div className="checkbox-emulator">
              <label
                htmlFor="email"
                className="Comm-pref-container__kmsi-checkbox"
              >
                <input
                  id="email"
                  type="checkbox"
                  className="Comm-pref-container__kmsi-checkbox-input"
                  checked={email}
                  onChange={e => setEmail(e.target.checked)}
                />
              </label>
            </div>
            <span>Email</span>
          </label>
          <button className="btn-save" type="submit" onClick={handlSubmit}>
            Save Changes
          </button>
        </>
      )}
    </div>
  );
}

export default CommunicationPrefPage;
