import {
  STL_RESOLVED_PRODUCT,
  SKU_STL_PRODUCT_LIST,
  STL_PARENT_PRODUCT_ID,
  STL_RESOLVED_CHILD_PRODUCTS,
} from '../actions/actions-shopthelook';

export default (state = {}, action) => {
  switch (action.type) {
    case STL_RESOLVED_PRODUCT: {
      return {
        ...state,
        activeProductId: action.activeProductId,
      };
    }
    case STL_RESOLVED_CHILD_PRODUCTS: {
      return {
        ...state,
        stlProductList: action.stlProductList,
      };
    }
    case SKU_STL_PRODUCT_LIST: {
      return {
        ...state,
        skuStlProductList: action.skuStlProductList,
      };
    }
    case STL_PARENT_PRODUCT_ID: {
      return {
        ...state,
        stlParentProductId: action.productId,
        entryPointPage: action.entryPointPage,
      };
    }
    default:
      return state;
  }
};
