import React, { Component } from 'react';
import { connect } from 'react-redux';
import './cookiePopup.scss';

const COOKIE_POPUP_LOCALSTORAGE_KEY = 'cookiePopupWasShown';

class CookiePopup extends Component {
  state = {
    popupShouldBeShown: !(
      localStorage.getItem(COOKIE_POPUP_LOCALSTORAGE_KEY) === 'true'
    ),
    popupIsClosed: false,
  };

  setCookiePopupFlag() {
    localStorage.setItem(COOKIE_POPUP_LOCALSTORAGE_KEY, 'true');
  }

  hideCookiePopup() {
    this.setState({ popupIsClosed: true });
  }

  render() {
    const { cookiePopupToggle } = this.props;
    const shouldShowPopup =
      cookiePopupToggle &&
      this.state.popupShouldBeShown &&
      !this.state.popupIsClosed;
    let result = null;

    if (shouldShowPopup) {
      this.setCookiePopupFlag();

      result = (
        <div className="cookie-popup-wrapper">
          <div className="cookie-popup-text">
            <p>
              We use cookies for operational, analytic, and marketing purposes,
              including to improve your site experience.
              <br />
              By interacting with our website, you agree to our use of cookies
              as described in our
              <a href="https://www.bergdorfgoodman.com/assistance/assistance.jsp?itemId=cat403606&icid=foot_secur_priv">
                {' '}
                Privacy Policy.
              </a>
            </p>
          </div>
          <div className="cookie-popup-button">
            <button onClick={() => this.hideCookiePopup()}>I Accept</button>
          </div>
        </div>
      );
    }

    return result;
  }
}

const mapStateToProps = state => ({
  cookiePopupToggle: state.toggles.COOKIE_POPUP,
});

export default connect(mapStateToProps)(CookiePopup);
