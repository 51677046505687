import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import reject from 'lodash/reject';
import flatMap from 'lodash/flatMap';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
import every from 'lodash/every';
import join from 'lodash/join';
import omit from 'lodash/omit';
import isString from 'lodash/isString';
import httpWithLogging, {
  X_ABTEST_INFO,
  X_DEVICE_TYPE,
  X_FEATURE_TOGGLES_HEADER_NAME,
  VISITORID,
} from 'universal/http-client';
import { buildCookieString } from 'universal/utilities-cookies';
import { stringify } from 'query-string';
import {
  setPlaceholderValue,
  setSearchTermToEmpty,
} from 'bgo-common/components/Header/Search/search-actions';
import {
  showSpinner,
  hideSpinner,
} from 'shared/components/Spinner/spinner-actions';
import { getABTestAssignments } from 'shared/actions/actions-page';
import {
  UTAG_VALUES,
  SRC_VALUE_FOR_UTAG,
  GEO_LOCATION_SERVICE_ERROR,
} from 'srp/constants';
// import includes from 'lodash/includes';
/* import {
  INSTORE_FILTER_EMPTY_PRODUCT_LIST_ERROR_MESSAGE,
  IN_STORE,
} from 'plp/constants'; */
import { NO_RESULTS_FOR_FILTER } from 'plp/ProductListPage/actions';
import { openModal } from 'shared/components/Modal/actions';
// import { isEmptyInStoreFilterValue } from 'plp/ProductListPage/components/ProductList/components/Filters/components/InStoreFilter/inStoreFilter';
import { atgConfigWithCookie } from 'client/utilities/utilities-request-headers';
import reactCookie from 'react-cookie';
import { isObject } from 'util';
import {
  CATEGORY,
  CATEGORY_LEVEL_2,
} from 'bgo-ui-plp/src/client/components/plp/constants';

export const LOADING_SEARCH_LIST = 'LOADING_SEARCH_LIST';
export const RESOLVED_SEARCH_LIST = 'RESOLVED_SEARCH_LIST';
export const REJECTED_SEARCH_LIST = 'REJECTED_SEARCH_LIST';
export const RESOLVED_SEARCH_SEO_DATA = 'RESOLVED_SEARCH_SEO_DATA';
export const SET_SEARCH_LIST_DATA_TO_UTAG = 'SET_SEARCH_LIST_DATA_TO_UTAG';
export const SET_SEARCH_CATEGORY_CRUMB = 'SET_SEARCH_CATEGORY_CRUMB';
export const LOADING_NULL_SEARCH_LIST = 'LOADING_NULL_SEARCH_LIST';
export const RESOLVED_NULL_SEARCH_LIST = 'RESOLVED_NULL_SEARCH_LIST';
export const REJECTED_NULL_SEARCH_LIST = 'REJECTED_NULL_SEARCH_LIST';
export const LOADING_NULL_SEARCH_FOOTER = 'LOADING_NULL_SEARCH_FOOTER';
export const RESOLVED_NULL_SEARCH_FOOTER = 'RESOLVED_NULL_SEARCH_FOOTER';
export const REJECTED_NULL_SEARCH_FOOTER = 'REJECTED_NULL_SEARCH_FOOTER';
export const LOADING_SEARCH_GRAPHIC_HEADER = 'LOADING_SEARCH_GRAPHIC_HEADER';
export const RESOLVED_SEARCH_GRAPHIC_HEADER = 'RESOLVED_SEARCH_GRAPHIC_HEADER';
export const REJECTED_SEARCH_GRAPHIC_HEADER = 'REJECTED_SEARCH_GRAPHIC_HEADER';
export const UPDATE_SEARCHLIST_CHECKVALUE = 'UPDATE_SEARCHLIST_CHECKVALUE';
export const LOADING_DELIVERY_SHIP = 'LOADING_DELIVERY_SHIP';
export const RESOLVED_DELIVERY_SHIP = 'RESOLVED_DELIVERY_SHIP';
export const REJECTED_DELIVERY_SHIP = 'REJECTED_DELIVERY_SHIP';

export const types = {
  SET_SRP_PAGE: 'SET_SRP_PAGE',
  SET_SRP_SORT_BY: 'SET_SRP_SORT_BY',
  SET_SRP_FILTER_OPTIONS: 'SET_SRP_FILTER_OPTIONS',
};

const traverseCategories = (nav = []) => {
  let activeCategoryCrumb = '';
  if (nav.length === 1) {
    if (nav[0].categories) {
      if (nav[0].categories.length === 1) {
        activeCategoryCrumb = traverseCategories(nav[0].categories);
      } else {
        activeCategoryCrumb = nav[0].crumb;
      }
    } else {
      activeCategoryCrumb = nav[0].crumb;
    }
  }
  return activeCategoryCrumb;
};

export const getActiveCategory = (filterOptions = {}, leftNav = []) => {
  let activeCategoryCrumb;
  const existingFilters = isEmpty(filterOptions)
    ? {}
    : JSON.parse(filterOptions);
  const { Category: existingCategoryFilter = [] } = existingFilters;
  if (isEmpty(existingCategoryFilter)) {
    if (leftNav.length === 1) {
      if (leftNav[0].categories) {
        if (leftNav[0].categories.length === 1) {
          activeCategoryCrumb = traverseCategories(leftNav[0].categories);
        } else {
          activeCategoryCrumb = leftNav[0].crumb;
        }
      } else {
        activeCategoryCrumb = leftNav[0].crumb;
      }
    } else {
      activeCategoryCrumb = '';
    }
  } else {
    activeCategoryCrumb = `/${existingCategoryFilter.join('/')}`;
  }
  return activeCategoryCrumb;
};

export function getSearchOptions(requestOptions = {}) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch(getSearchResults(requestOptions))
        .then(resolve)
        .catch(err => {
          if (err.type === NO_RESULTS_FOR_FILTER) {
            dispatch(
              openModal({
                type: 'ModalWithoutHeader',
                message: err.message,
                className: 'modal-without-header',
              }),
            );
          } else if (err.type === GEO_LOCATION_SERVICE_ERROR) {
            dispatch(openModal({ type: 'ProductListErrorModal' }));
          }
          reject();
        });
    });
}

export function getABTestAndSearchResults(requestOptions = {}, MBOX_IDS) {
  return dispatch =>
    dispatch(getABTestAssignments(MBOX_IDS.join(','), true))
      .then(() => {
        dispatch(getSearchResults(requestOptions));
      })
      .catch(() => {
        dispatch(getSearchResults(requestOptions));
      });
}

export const updateSrpGrsUtagData = data => {
  try {
    const {
      grsAttributionToken: attribution_token,
      grsFilter: facet_grs,
      grsOrderBy: sort_method_grs,
      products,
    } = data;
    const productPrice = map(products, product => {
      const { price } = product;
      const adornments = price?.adornments || [];
      if (adornments?.length > 0) {
        const currentPriceObj = adornments.find(
          adornment => adornment.label === 'NOW',
        );
        return currentPriceObj
          ? currentPriceObj.price
          : price.promotionalPrice || price.retailPrice;
      }
      return price.promotionalPrice || price.retailPrice;
    });
    const originalProductPrice = map(products, product => {
      const { price } = product;
      const adornments = price?.adornments || [];
      if (adornments?.length > 0) {
        const originalPriceObj = adornments.find(
          adornment => adornment.label === 'Original',
        );
        return originalPriceObj
          ? originalPriceObj.price
          : price.retailPrice || price.promotionalPrice;
      }
      return price.retailPrice || price.promotionalPrice;
    });
    return {
      attribution_token,
      facet_grs,
      sort_method_grs,
      product_price: productPrice,
      product_price_original: originalProductPrice,
    };
  } catch (error) {
    return {};
  }
};

export function getSearchResults(requestOptions = {}) {
  return (dispatch, getState) => {
    dispatch(showSpinner());
    dispatch({ type: LOADING_SEARCH_LIST });
    const rfkUuid = reactCookie.load('__ruid');
    const state = getState();
    const isSlsSrpToggleOn = get(state, 'toggles.SRP_SLS', false);
    const isSrpQueryRelaxationToggleOn = get(
      state,
      'toggles.SRP_QUERY_RELAXATION',
      false,
    );
    const searchListUpdateCheckValue = get(state, 'search.expand', false);
    const currentPage = get(state, 'srp.search.currentPage', 1);
    const { session } = state;
    const context = state.api && state.api.requestContext;
    const {
      page = currentPage,
      sortBy = '',
      filterOptions,
      keyword,
      src = '',
      profileId = get(state, 'utag.userSession.web_id', ''),
    } = requestOptions;
    const fetchSize = getFetchSize(state);
    const currentUrl = get(state.session, 'url', '');
    let deviceType;
    if (
      get(session, 'deviceType.isMobile', false) &&
      !get(session, 'deviceType.isTablet', false)
    ) {
      deviceType = 'M';
    } else if (get(session, 'deviceType.isTablet', false)) {
      deviceType = 'T';
    } else {
      deviceType = 'D';
    }
    const headersWithoutUCID = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        DYN_USER_CONFIRM: get(session, 'DYN_USER_CONFIRM', ''),
        W2A: get(session, 'W2A', ''),
        TLTSID: get(session, 'TLTSID', ''),
        AGA: get(session, 'AGA', ''),
      }),
      uuid: get(session, '__ruid', '') || (rfkUuid || '') || '',
      url: currentUrl.split('?')[0],
      _br_uid_2: get(context, '_br_uid_2', ''),
      ref_url: get(session, 'referer', ''),
      [X_ABTEST_INFO]: join(
        get(state, 'abTests.responses', []).map(
          i => `${i.mboxId}:${get(i, 'assignment.value.experienceId', '')}`,
        ),
        '~',
      ),
      [X_DEVICE_TYPE]: deviceType,
    };

    const userId = get(state.user, 'ucid', '');
    const headers = userId
      ? { ...headersWithoutUCID, user_id: userId }
      : headersWithoutUCID;
    // const SRP_SVC_API_TIMEOUT = get(
    //   state,
    //   'apiTimeouts.SRP_SVC_API_TIMEOUT',
    //   15000,
    // );
    headers[X_FEATURE_TOGGLES_HEADER_NAME] = JSON.stringify({
      SRP_GRAPHIC_HEADER: get(state, 'toggles.SRP_GRAPHIC_HEADER', false),
      SRP_AUTO_CORRECT: get(state, 'toggles.SRP_AUTO_CORRECT', false),
      SRP_QUERY_RELAXATION: get(state, 'toggles.SRP_QUERY_RELAXATION', false),
      BG_VERTEX_AI: get(state, 'toggles.BG_VERTEX_AI', false),
      ABTEST_VERTEXAI_SRP: get(state, 'toggles.ABTEST_VERTEXAI_SRP', false),
      BG_CATEGORY_FACET_SRP: get(state, 'toggles.BG_CATEGORY_FACET_SRP', false),
    });

    const abTestsOpt = {};
    const abTests = get(state, 'abTestsOpt', {});
    Object.entries(abTests).forEach(([tst, grp]) => {
      abTestsOpt[tst] = grp?.variation;
    });
    headers['abTestsOpt'] = JSON.stringify(abTestsOpt);

    // When vertex toggle on passing df_cid or customer Id as visitor Id
    try {
      const vertexAiToggle =
        get(state, 'toggles.BG_VERTEX_AI', false) ||
        (get(state, 'toggles.ABTEST_VERTEXAI_SRP', false) &&
          get(state, `abTestsOpt.tl364.variation`, 'a') === 'b');
      if (vertexAiToggle) {
        const customerId = get(session, 'customerId', null);
        const isLoggedIn = get(session, 'isLoggedin', false);
        const defaultCustomerId = get(session, 'defaultCustomerId', null);
        headers[VISITORID] = !isEmpty(defaultCustomerId)
          ? defaultCustomerId
          : customerId || '';
        if (isLoggedIn) {
          headers['userId'] = customerId || '';
        }
      }
    } catch (e) {
      console.error(
        `Error occurred while setting visitorId when vertex toggle enabled. Error: ${e}`,
      );
    }

    const requestApi = httpWithLogging(state, 200000);
    function getSortByParams(sortBy) {
      const sortByParams = sortBy ? `&sortBy=${escape(sortBy)}` : '';
      return sortByParams;
    }
    const sortByParams = getSortByParams(sortBy);
    const getUtagFiltersData = filterOptions => {
      const inStoreFilter = 'In Store';

      const filterOptionsWithoutInstoreZipCode = {
        ...filterOptions,
        [inStoreFilter]: get(filterOptions, inStoreFilter, []).slice(0, 1),
      };
      return {
        filterSelection: flatten(
          reject(filterOptionsWithoutInstoreZipCode, isEmpty),
        ),
        filterType: flatMap(
          Object.keys(filterOptionsWithoutInstoreZipCode),
          key =>
            Array(filterOptionsWithoutInstoreZipCode[key].length).fill(
              key === CATEGORY_LEVEL_2 ? 'Subcategory' : key,
            ),
        ),
      };
    };

    function isSortOptionChanged(sortBy) {
      const previousSortOption = get(state, 'srp.selectedSortBy');
      return (
        !isEmpty(sortBy) &&
        !isEmpty(previousSortOption) &&
        previousSortOption !== sortBy
      );
    }

    function isUserFilterEmpty(selectedFilters) {
      return every(Object.keys(selectedFilters), key => {
        return selectedFilters[key].length === 0;
      });
    }

    function isFilterOptionsChanged(selectedFilters) {
      let previousFilterOptions = get(state, 'srp.selectedFilterOptions');

      previousFilterOptions =
        isObject(previousFilterOptions) && isSlsSrpToggleOn
          ? omit(previousFilterOptions, ['Category'])
          : previousFilterOptions;

      const selectedFiltersEdited =
        isObject(selectedFilters) && isSlsSrpToggleOn
          ? omit(selectedFilters, ['Category'])
          : selectedFilters;

      if (
        isString(previousFilterOptions) &&
        isUserFilterEmpty(selectedFilters)
      ) {
        return false;
      }

      const isClient = typeof window !== 'undefined';

      return (
        !isEmpty(selectedFiltersEdited) &&
        ((isSlsSrpToggleOn && isClient) || !isEmpty(previousFilterOptions)) &&
        !isEqual(previousFilterOptions, selectedFiltersEdited)
      );
    }

    /* function shouldShowInStoreFilterMessage(data) {
      return (
        isEmpty(data.products) &&
        !isEmpty(filterOptions) &&
        includes(filterOptions, IN_STORE) &&
        !isEmptyInStoreFilterValue(JSON.parse(filterOptions)[IN_STORE]) &&
        !data.geoLocationServiceError
      );
    } */

    /* function shouldShowInStoreErrorModal(data) {
      return (
        isEmpty(data.products) &&
        !isEmpty(filterOptions) &&
        includes(filterOptions, IN_STORE) &&
        !isEmptyInStoreFilterValue(JSON.parse(filterOptions)[IN_STORE]) &&
        data.geoLocationServiceError
      );
    } */

    function getFetchSize(state) {
      const abtestMobileFetchSizeToggle = get(
        state,
        'toggles.ABTEST_SRP_MOBILE_FETCH_SIZE',
        false,
      );
      const isMobileAndNotTablet =
        get(state, 'session.deviceType.isMobile', false) &&
        !get(state, 'session.deviceType.isTablet', false);
      const abtestResponds = get(state, 'abTests.isMobileFetchSizeOn', false);
      const srpFetchSizeToggle = get(
        state,
        'toggles.SRP_MOBILE_FETCH_SIZE',
        false,
      );
      const fetchSizeFromConfig = get(
        state,
        'mobileConfig.pagination.fetchSize',
        null,
      );

      if (isMobileAndNotTablet) {
        if (abtestMobileFetchSizeToggle) {
          return abtestResponds ? fetchSizeFromConfig : null;
        } else if (srpFetchSizeToggle) {
          return fetchSizeFromConfig;
        }
      } else if (!get(state, 'session.deviceType.isMobile', false)) {
        return get(state, 'desktopConfig.pagination.fetchSize', null);
      }
      return null;
    }

    function replaceFacetidWithName(filters) {
      const appFilters = get(state, 'srp.search.applicableFilters', []);
      forEach(filters, (value, key) => {
        const facetInfoArr = filter(appFilters, elem => {
          return key === elem.filterKey;
        });
        if (facetInfoArr && facetInfoArr.length) {
          const facetInfo = [];
          forEach(value, val => {
            const facetIdArr = filter(facetInfoArr[0].facetInfo, elem => {
              return elem.facetId === val;
            });
            if (facetIdArr && facetIdArr.length) {
              facetInfo.push(facetIdArr[0].name);
            }
          });
          filters[key] = facetInfo;
        }
      });
      return filters;
    }

    return new Promise((resolve, reject) => {
      let params = { filterOptions, keyword, page, profileId };
      if (isSlsSrpToggleOn) {
        params.brand = 'BG';
      }

      if (fetchSize != null) {
        params = { ...params, fetchSize };
      }

      const searchApiHost = isSlsSrpToggleOn
        ? NMConfig.API_SEARCH_V2
        : NMConfig.API_SEARCH;
      let href = `${searchApiHost}/keywordsearch?${stringify(
        params,
      )}${sortByParams}`;
      const IS_BLOOMREACH = get(state, 'toggles.SEARCH_BLOOMREACH', false);

      // eslint-disable-next-line no-nested-ternary
      const selectedFilters = isEmpty(filterOptions)
        ? {}
        : IS_BLOOMREACH
        ? JSON.parse(filterOptions)
        : replaceFacetidWithName(JSON.parse(filterOptions));
      // const UtagselectedFilters = clone(selectedFilters);
      // UtagselectedFilters.Category = isEmpty(filterOptions)
      //   ? {}
      //   : uniq(
      //       compact(
      //         replaceFacetidWithCatName(
      //           get(state, 'srp.search.leftNav', []),
      //           UtagselectedFilters.Category,
      //         ),
      //       ),
      //     );
      requestOptions.filterOptions = JSON.stringify(selectedFilters);

      const hasAnyFilter = Object.keys(selectedFilters).some(
        key => selectedFilters[key].length > 0,
      );
      const hasAnyFilterExceptCategory =
        hasAnyFilter &&
        Object.keys(selectedFilters)
          .filter(key => key !== CATEGORY)
          .some(key => selectedFilters[key].length > 0);
      const internationalToggle = get(state, 'toggles.INTERNATIONAL', false);
      if (internationalToggle) {
        const currencyCode = get(state, 'locale.currencyCode', 'USD');
        if (currencyCode !== 'USD') {
          let currencyQuery = `?currency=${currencyCode}`;
          if (href.indexOf('?') > -1 || href.indexOf('&') > -1) {
            currencyQuery = `&currency=${currencyCode}`;
          }
          href = `${href}${currencyQuery}`;
        }
      }
      if (currentUrl.includes('/keyword/')) {
        href = `${href}&indexable=true`;
      }
      const request = isSlsSrpToggleOn
        ? requestApi.post(
            href,
            { abTestsOpt: JSON.stringify(abTestsOpt) },
            { headers },
          )
        : requestApi.get(href, { headers });

      request
        .then(successResponse => {
          /* if (shouldShowInStoreErrorModal(successResponse.data)) {
            return Promise.reject({ type: GEO_LOCATION_SERVICE_ERROR });
          }
           if (shouldShowInStoreFilterMessage(successResponse.data)) {
            return Promise.reject({
              type: NO_RESULTS_FOR_FILTER,
              message: INSTORE_FILTER_EMPTY_PRODUCT_LIST_ERROR_MESSAGE,
            });
          } */
          dispatch({
            type: RESOLVED_SEARCH_LIST,
            payload: successResponse.data,
            params: requestOptions,
            href,
            searchListUpdateCheckValue,
            shouldUpdateTotalCounter: isSlsSrpToggleOn ? !hasAnyFilter : false,
            shouldUpdateCategoryCounters: isSlsSrpToggleOn
              ? !hasAnyFilterExceptCategory
              : false,
          });
          dispatch({ type: types.SET_SRP_PAGE, page });
          dispatch({
            type: SET_SEARCH_LIST_DATA_TO_UTAG,
            payload: {
              page_definition_id: IS_BLOOMREACH
                ? UTAG_VALUES.PAGE_DEFINITION_ID
                : 'ReflektionSearch',
              page_name: UTAG_VALUES.PAGE_NAME,
              page_type: UTAG_VALUES.PAGE_TYPE,
              page_version: 'test',
              search_type: IS_BLOOMREACH
                ? UTAG_VALUES.SEARCH_TYPE
                : 'Reflektion',
              total: successResponse.data.total,
              sortBy: successResponse.data.selectedSortOption,
              page,
              // filterOptions: isMobileAndNotTablet
              //   ? getUtagFiltersData(UtagselectedFilters)
              //   : getUtagFiltersData(omit(selectedFilters, ['Category'])),
              filterOptions: getUtagFiltersData(
                isSlsSrpToggleOn
                  ? selectedFilters
                  : omit(selectedFilters, ['Category']),
              ),
              page_template: UTAG_VALUES.PAGE_TEMPLATE,
              // previousViewFilterSubmit: isMobileAndNotTablet
              //   ? isFilterOptionsChanged(selectedFilters, isMobileAndNotTablet)
              //   : isFilterOptionsChanged(
              //       omit(selectedFilters, ['Category']),
              //       isMobileAndNotTablet,
              //     ),
              previousViewFilterSubmit: isFilterOptionsChanged(
                isSlsSrpToggleOn
                  ? selectedFilters
                  : omit(selectedFilters, ['Category']),
              ),
              previousViewSortSubmit: isSortOptionChanged(sortBy),
              internal_search_term: IS_CLIENT ? '' : keyword,
              previousViewSearchSubmit: IS_CLIENT ? '' : true,
              productsMetadata: map(successResponse.data.products, 'metadata'),
              search_type_ahead: IS_CLIENT ? true : src === SRC_VALUE_FOR_UTAG,
              product_id: map(successResponse.data.products, 'id'),
              ...updateSrpGrsUtagData(successResponse.data),
            },
          });
          dispatch({
            type: types.SET_SRP_FILTER_OPTIONS,
            // eslint-disable-next-line no-nested-ternary
            selectedFilterOptions: IS_BLOOMREACH
              ? isEmpty(filterOptions)
                ? 'No filter options selected'
                : JSON.parse(filterOptions)
              : isEmpty(filterOptions)
              ? { Color: [], Designer: [], Price: [], Size: [] }
              : selectedFilters,
          });
          dispatch({
            type: types.SET_SRP_SORT_BY,
            sortBy: successResponse.data.selectedSortOption,
          });
          if (
            successResponse?.data?.seo &&
            !isEmpty(successResponse.data.seo)
          ) {
            dispatch({
              type: RESOLVED_SEARCH_SEO_DATA,
              payload: {
                ...successResponse.data,
                seo: successResponse?.data?.seo,
              },
            });
          }
          const categoryCrumb = getActiveCategory(
            filterOptions,
            successResponse.data.leftNav,
          );
          dispatch({ type: SET_SEARCH_CATEGORY_CRUMB, payload: categoryCrumb });
          dispatch(hideSpinner());
          return resolve();
        })
        .catch(err => {
          if (
            err &&
            err.type &&
            err.type !== GEO_LOCATION_SERVICE_ERROR &&
            err.type !== NO_RESULTS_FOR_FILTER
          ) {
            dispatch({
              type: REJECTED_SEARCH_LIST,
              payload: {},
              params: requestOptions,
              href,
            });
          }
          dispatch(hideSpinner());
          reject(err);
        });
    });
  };
}

export function getRecommendationsForNullSearchPage(keyword = '') {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_NULL_SEARCH_LIST });
    const state = getState();
    const RECOMMENDATION_API_TIMEOUT = get(
      state,
      'apiTimeouts.RECOMMENDATION_API_TIMEOUT',
    );
    const { user = {} } = state;
    const headers = {
      Cookie: buildCookieString({
        rid: get(user, 'rid', 'US'),
      }),
      PlacementType: 'nullsearch',
    };
    const requestApi = httpWithLogging(state, RECOMMENDATION_API_TIMEOUT);
    return requestApi
      .get(`${NMConfig.API_RECOMMENDATIONS}?searchTerm=${keyword}`, { headers })
      .then(successResponse =>
        dispatch({
          type: RESOLVED_NULL_SEARCH_LIST,
          payload: successResponse.data,
        }),
      )
      .catch(() => dispatch({ type: REJECTED_NULL_SEARCH_LIST }));
  };
}

export function getRefreshableContentNullSearchFooter() {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_NULL_SEARCH_FOOTER });
    const state = getState();
    const requestApi = httpWithLogging(state);

    const dymToggle = get(state, 'toggles.BG_PHASE_2_DID_YOU_MEAN', false);

    return requestApi
      .get(NMConfig.API_REFRESHABLE_CONTENT, {
        params: {
          refreshablePath: dymToggle
            ? '/category/search/r_dt_noresults_with_dym.html'
            : '/category/search/r_dt_noresults_no_dym.html',
        },
      })
      .then(response => {
        dispatch({ type: RESOLVED_NULL_SEARCH_FOOTER, payload: response.data });
      })
      .catch(() => {
        dispatch({ type: REJECTED_NULL_SEARCH_FOOTER });
      });
  };
}

export function getGraphciHeaderRefreshableContent(graphicHeaderUrl) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_SEARCH_GRAPHIC_HEADER });
    const state = getState();
    const requestApi = httpWithLogging(state);
    const config = atgConfigWithCookie({});
    const { headers } = config;
    const refreshableUrl = graphicHeaderUrl.startsWith('http')
      ? graphicHeaderUrl
      : `${NMConfig.API_REFRESHABLE_CONTENT}${graphicHeaderUrl}`;
    return requestApi
      .get(refreshableUrl, { headers })
      .then(response => {
        dispatch({
          type: RESOLVED_SEARCH_GRAPHIC_HEADER,
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({ type: REJECTED_SEARCH_GRAPHIC_HEADER });
      });
  };
}

export function setPlaceholderToTextBoxValueAndClearTextBox(searchTerm) {
  return (dispatch, getState) => {
    const state = getState();
    const vertexAiToggle =
      get(state, 'toggles.BG_VERTEX_AI', false) ||
      (get(state, 'toggles.ABTEST_VERTEXAI_SRP', false) &&
        get(state, `abTestsOpt.tl364.variation`, 'a') === 'b');
    setPlaceholderValue(searchTerm)(dispatch);
    setSearchTermToEmpty('', false, vertexAiToggle)(dispatch);
  };
}

export function setSearchListUpdateCheckValue(expand) {
  return dispatch => dispatch({ type: UPDATE_SEARCHLIST_CHECKVALUE, expand });
}

export function getDeliveryShipOptions(zipCode) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_DELIVERY_SHIP });
    const headers = {
      zipCode,
    };
    const state = getState();
    const requestApi = httpWithLogging(state);
    return requestApi
      .get(`${NMConfig.API_DELIVERY_SHIP}`, { headers })
      .then(successResponse =>
        dispatch({
          type: RESOLVED_DELIVERY_SHIP,
          payload: successResponse.data,
        }),
      )
      .catch(() => dispatch({ type: REJECTED_DELIVERY_SHIP }));
  };
}
