/* eslint-disable import/no-cycle */
import React, { Fragment } from 'react';
import { array, bool, string } from 'prop-types';
import EntityWithSlideBar from '../entityWithSlideBar/entityWithSlideBar';
import Layout from './layout';
import ContentItem from '../contentItem/contentItem';

/**
 * @function MainEdits
 * @param {String} props.className
 * @param {Array} props.contentItems
 * @param {Boolean} props.isMobilePhone
 * @param {Boolean} props.isNewHomePage
 * @param {String} props.placement
 * @param {Boolean} props.sessionExpired
 * @param {Array} props.subLayouts
 * @param {Boolean} props.validPromo
 * @param {Boolean} props.isMainEditLandscapeToggle
 * @param {Boolean} props.isDesktop
 * @returns {React.ReactElement}
 */
const MainEdits = ({
  className,
  contentItems,
  isMobilePhone,
  isNewHomePage,
  sessionExpired,
  subLayouts,
  validPromo,
  parentTrackingTags,
  isMainEditLandscapeToggle,
  isDesktop,
}) => (
  <EntityWithSlideBar
    className={className}
    isMainEditLandscapeToggle={isMainEditLandscapeToggle}
    isDesktop={isDesktop}
  >
    {!!subLayouts.length && (
      <Fragment>
        {subLayouts.map(item => (
          <Layout
            key={item.sys.id}
            cmsLayout={item}
            isMobilePhone={isMobilePhone}
            validPromo={validPromo}
            sessionExpired={sessionExpired}
            isNewHomePage={isNewHomePage}
            parentTrackingTags={parentTrackingTags}
          />
        ))}
      </Fragment>
    )}
    {!subLayouts.length &&
      contentItems.map(item => (
        <div className="component content-layout" key={item.sys.id}>
          <ContentItem
            cmsContentItem={item}
            isMobilePhone={isMobilePhone}
            validPromo={validPromo}
            sessionExpired={sessionExpired}
          />
        </div>
      ))}
  </EntityWithSlideBar>
);

MainEdits.propTypes = {
  className: string,
  contentItems: array,
  isMobilePhone: bool,
  isNewHomePage: bool,
  sessionExpired: bool,
  subLayouts: array,
  validPromo: bool,
};

export default MainEdits;
