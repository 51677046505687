import get from 'lodash/get';
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { InView } from 'react-intersection-observer';
import './video.scss';

const notSupportedText = 'Your browser does not support the video tag.';

export function DumbVideoAsset({
  cmsContentItem,
  isMobilePhone,
  lazyloadHomeVideoContent = true,
  enableAutoPlay,
}) {
  const {
    source,
    mobileSource,
    poster,
    mobilePoster,
    autoPlay,
    loop,
    mute,
    hideControls,
    linkTo = null,
    lazyloadVideo = true,
  } = cmsContentItem.fields;

  const videoProps = {
    className: 'video-content-asset',
    controls: !hideControls,
    poster: isMobilePhone ? mobilePoster : poster,
    loop,
    muted: mute || autoPlay,
    autoPlay:
      autoPlay &&
      (!lazyloadHomeVideoContent || !lazyloadVideo) &&
      enableAutoPlay,
    src: isMobilePhone ? mobileSource || source : source,
    playsInline: true,
  };

  const videoRef = useRef();

  const handleInView = inView => {
    const { node } = videoRef.current;
    if (inView) {
      node?.play?.();
    } else {
      node?.pause?.();
    }
  };

  const videoComponent = () => {
    return (
      <span>
        {lazyloadHomeVideoContent && lazyloadVideo ? (
          <InView
            tag="video"
            rootMargin="100px"
            onChange={inView => autoPlay && handleInView(inView)}
            {...videoProps}
            preload="none"
            ref={videoRef}
          >
            <track src="" kind="captions" />
            {notSupportedText}
          </InView>
        ) : (
          <video {...videoProps}>
            <track src="" kind="captions" />
            {notSupportedText}
          </video>
        )}
      </span>
    );
  };

  return linkTo ? <a href={linkTo}>{videoComponent()}</a> : videoComponent();
}

const mapStateToProps = state => ({
  isMobilePhone: get(state, 'device.isMobilePhone', false),
  enableAutoPlay:
    get(state, 'page.location.query.ENABLE_AUTOPLAY', 'true') === 'true',
  lazyloadHomeVideoContent: state.toggles.LAZYLOAD_BG_VIDEO_ASSET,
});

export default connect(mapStateToProps)(DumbVideoAsset);
