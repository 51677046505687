import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Toggle from 'shared/components/Toggle/toggle';
import {
  setMetaInfo,
  PAGE_ID_BG360,
  setPageId,
} from 'shared/actions/actions-page';

import {
  openSignUpModal,
  openWelcomeModal,
  resolvedBG360page,
} from '../../actions';
import { loadingStatus } from '../../constants';
import './Bg360Page.scss';
import Bg360Logo from '../../../../assets/bg-360-logo.gif';

export class DumbBg360Page extends Component {
  constructor(...args) {
    super(...args);
    this.props.setMetaInfo({
      metaTitle: get(this.props, 'meta.title', ''),
      metaDescription: get(this.props, 'meta.description', ''),
      metaKeywords: get(this.props, 'meta.keywords', ''),
    });
    if (this.props.isAnalyticsEnabled) {
      this.props.setPageId(PAGE_ID_BG360);
    }
  }

  componentDidMount() {
    if (this.props.isAnalyticsEnabled) {
      this.props.resolvedBG360page();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.signUpLoadingStatus === loadingStatus.resolved &&
      prevProps.signUpLoadingStatus !== loadingStatus.resolved
    ) {
      this.props.openWelcomeModal();
    }
  }

  render() {
    if (this.props.contentLoadingStatus !== loadingStatus.resolved) {
      return null;
    }

    const {
      signUpLoadingStatus,
      services = [],
      openWelcomeModal,
      openSignUpModal,
    } = this.props;
    const openModal =
      signUpLoadingStatus === loadingStatus.resolved
        ? openWelcomeModal
        : openSignUpModal;

    return (
      <main className="bg-360-wrapper">
        <section className="introduction">
          <div className="logo">
            <img src={Bg360Logo} alt="" />
          </div>
          <div className="description-wrapper">
            <h1
              className="title"
              dangerouslySetInnerHTML={{
                __html: get(this.props, 'header.title'),
              }}
            />
            <p
              className="description"
              dangerouslySetInnerHTML={{
                __html: get(this.props, 'header.description'),
              }}
            />
          </div>
        </section>
        <section className="services-list">
          <ul>
            {services.map(({ title, description, color }) => (
              <li key={title} style={{ backgroundColor: color }}>
                <h2
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: title,
                  }}
                />
                <p
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              </li>
            ))}
            <Toggle feature="BG360_CTA">
              <li className="sign-up">
                <button
                  onClick={openModal}
                  style={{ backgroundColor: get(this.props, 'action.color') }}
                >
                  <p>{get(this.props, 'action.name')}</p>
                  <i />
                </button>
              </li>
            </Toggle>
          </ul>
        </section>
      </main>
    );
  }
}

const mapStateToProps = state => ({
  contentLoadingStatus: get(state, 'bg360.contentLoadingStatus', null),
  signUpLoadingStatus: get(state, 'bg360.signUpLoadingStatus', null),
  isAnalyticsEnabled: get(state, 'toggles.BG360_ANALYTICS', false),
  ...get(state, 'bg360.content', {}),
});
const mapDispatchToProps = {
  setMetaInfo,
  openSignUpModal,
  openWelcomeModal,
  resolvedBG360page,
  setPageId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbBg360Page);
