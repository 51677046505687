import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import classnames from 'classnames';
import { setSelectedFacet } from 'ecm/actions/actions-ecmcontent';
import { ECMSlots } from 'ecm/components/EcmGet';
import './ecmFacets.scss';

export class EcmFacets extends Component {
  constructor(props) {
    super(props);
    this.setSelectedFacet = this.setSelectedFacet.bind(this);
  }

  setSelectedFacet(facet) {
    this.props.setSelectedFacet(facet, ECMSlots.MAGAZINE_INDEX);
  }

  render() {
    const { content, selectedFacet } = this.props;
    return (
      <React.Fragment>
        <div className="ecm-facet-wrapper">
          <div className="ecm-facet-overlay hide-on-tablet hide-on-desktop" />
          <ul className="ecm-facet-scrollmenu">
            {get(content, 'facets', []).map((facet, index) => {
              const isFacetSelected =
                selectedFacet.toLowerCase() === facet.name.toLowerCase();
              return (
                <li key={index}>
                  <button
                    className={classnames('ecm-facet__button', {
                      'ecm-facet__button__active': isFacetSelected,
                    })}
                    onClick={() => {
                      this.setSelectedFacet(facet.name ? facet.name : facet);
                    }}
                  >
                    {facet.title ? facet.title : facet}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const slotInState =
    state.ecm.ecmContent[ECMSlots.MAGAZINE_INDEX.contentId] || {};
  return {
    selectedFacet: slotInState.selectedFacet || 'featured',
  };
};

export default connect(
  mapStateToProps,
  { setSelectedFacet }
)(EcmFacets);
