import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { unescape } from 'he';
import './restrictedProduct.scss';

export const DEFAULT_PRODUCT_IMAGE = 'http://neimanmarcus.scene7.com/is/image/NeimanMarcus/vnt_error?&wid=400&height=500';

const RestrictedProduct = ({ product }) => {
  const {
   name,
   metadata,
    designer
  } = product;

  return (
    <li className="restricted-product-row">
      <div className="image">
        <img
          src={get(product, 'media.main.thumbnail.url', DEFAULT_PRODUCT_IMAGE)}
          alt={unescape(name)}
          aria-hidden="true"
        />
      </div>
      <div className="description">
        { !isEmpty(designer) && <p className="designer">{unescape(designer.name)}</p> }
        <p>{unescape(name)}</p>
        { !isEmpty(metadata) && <p>Item: {metadata.cmosCatalogId}_{metadata.cmosItem}</p> }
      </div>
    </li>
  );
};

const mapStateToProps = (state, ownProps) => ({
  product: ownProps.product
});


export default connect(mapStateToProps)(RestrictedProduct);
