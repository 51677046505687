import React, { Fragment } from 'react';
import get from 'lodash/get';
import { EyeBrow1 } from 'bgo-common/Styleguide/Typography';

const groupHeaderText = {
  address: 'Enter Your Address:',
  name: 'Enter Your Name(s):',
  birth: 'Enter Birth Information:',
  child: 'Enter Name(s):',
  line: 'Enter Personalization:',
  personalization: 'Enter Personalization:',
  what: 'Enter Item(s)',
};

const MonogramHeader = ({ group, name }) => {
  const getHeader = (group, name) => {
    const groupHeader = get(groupHeaderText, [group], '');
    const legacyHeader = name ? `Enter Your ${name}:` : '';

    return groupHeader || legacyHeader;
  };

  return (
    <Fragment>
      <span className="monogram-text-dmg__heading">
        <EyeBrow1>{getHeader(group, name)}</EyeBrow1>
      </span>
    </Fragment>
  );
};

export default MonogramHeader;
