import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Form, Formik } from 'formik';
import { validateEmail } from '@bgo-ui/common/client/utilities/utilities-amplify';
import { editAccountDetails } from '../../../../actions';
import SubmitButton from '../../../../../../../common/Forms/FormButtons/SubmitButton/SubmitButton';
import FormikTextField from '../../../../../../../common/Forms/FormikTextField/FormikTextField';
import './ChangeEmailForm.scss';
import { validateVerificationCode } from '../../../TotpConfirmation/totp-helpers';
import { validationErrors } from '../../../../../../../login/constants';

const DumbChangeEmailForm = props => {
  const { isLoading, error } = props;

  const initialFormValue = {
    verificationCode: '',
    email: '',
    confirmEmail: '',
  };

  const validateForm = values => {
    const errors = {};
    if (!values) return {};

    const codeError = validateVerificationCode(values.verificationCode);
    if (codeError) {
      errors.verificationCode = codeError;
    }

    if (!values.email) {
      errors.email = 'Please enter your email.';
    } else if (!validateEmail(values.email)) {
      errors.email = validationErrors.invalidEmail;
    }
    if (!values.confirmEmail) {
      errors.confirmEmail = 'Please confirm your email.';
    } else if (values.email !== values.confirmEmail) {
      errors.confirmEmail =
        'The new email address must match the confirm email address.';
    }
    return errors;
  };

  const submitForm = values => {
    props.editAccountDetails({
      totp: values.verificationCode.trim(),
      email: values.email.trim(),
    });
  };

  return (
    <Formik
      initialValues={initialFormValue}
      validate={validateForm}
      onSubmit={submitForm}
    >
      {() => (
        <Form autoComplete="off">
          <input name="DummyUsername" type="text" className="invisible" />
          <input name="DummyPassword" type="password" className="invisible" />
          <div className="change-email-form__form-fields-container">
            <p className="change-email-form__field-description">
              Please enter your verification code below.
            </p>
            <FormikTextField
              name="verificationCode"
              id="verification-code-field"
              placeholder="Verification code"
              autoComplete="verification-code"
              fontSize
            />
            <p className="change-email-form__field-description">
              Enter new email below.
            </p>
            <FormikTextField
              name="email"
              type="text"
              id="email-field"
              placeholder="New email address"
              fontSize
            />
            <FormikTextField
              name="confirmEmail"
              type="text"
              id="confirm-email-field"
              placeholder="Confirm new email"
              fontSize
            />
          </div>
          {error && <p className="change-email-form__error">{error}</p>}
          <div className="change-email-form__submit">
            <SubmitButton
              isSubmitting={isLoading}
              type="submit"
              caption="Change email"
              additionalClasses="change-email-form__submit-btn"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

DumbChangeEmailForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  editAccountDetails: PropTypes.func.isRequired,
};

DumbChangeEmailForm.defaultProps = {
  error: null,
};

const mapStateToProps = state => ({
  isLoading: get(state, 'api.update_basic_attributes.loading', false),
  error: get(
    state,
    'myaccount.myAccountDetails.updateBasicAttributesError',
    null,
  ),
});
const mapDispatchToProps = {
  editAccountDetails,
};

const ChangeEmailForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbChangeEmailForm);

export default ChangeEmailForm;
