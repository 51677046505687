import {
  bespokeExperiencesContent,
  contactUsContent,
} from '../../common/constants';

export const content = {
  id: bespokeExperiencesContent.id,
  title: bespokeExperiencesContent.title,
  desc:
    "Our network of well-connected, InCircle seasoned travel professionals are available to arrange reservations at the world's most sought-after hotels and resorts, on ships, planes and trains - with access to knowledgeable on-site guides, expert outfitters, and private transfers. In addition, InCircle Concierge lifestyle managers are here to secure in-demand tickets, seating at top restaurants, and more.<br /><br />Below are a few of our select partners with a special offer just for you! All arrangements are based on availability at time of request.",
  cta: {
    to: contactUsContent.id,
    title: 'CONTACT US TO BOOK YOUR EXPERIENCE',
  },
  galleryItems: [
    {
      id: 'the-ritz-carlton-new-york-central-park',
      src:
        'https://www.bergdorfgoodman.com/category/loyalty/the-ritz-carlton-new-york-central-park.png',
      title: 'The Ritz-Carlton New York, Central Park',
      desc:
        'The Ritz-Carlton New York, Central Park, a landmark property, stands as a beacon of hospitality with its accommodations attesting to its prestigious stature. With its unparalleled location overlooking the iconic Central Park, guests are treated to breathtaking views of lush greenery and the city skyline right from their rooms.',
    },
    {
      id: 'mauna-lani-auberge-resorts-collection',
      src:
        'https://www.bergdorfgoodman.com/category/loyalty/mauna-lani-auberge-resorts-collection.png',
      title: 'Mauna Lani, Auberge Resorts Collection',
      desc:
        "Nestled along the dramatic Kohala Coast, in the center of five great mountains, Mauna Lani, Auberge Resorts Collection is a sacred oasis offering the perfect playground with one-of-a-kind ocean adventures on its swimmable beach, remarkable dining including the storied CanoeHouse, immersive cultural experiences, 2 world-class golf courses, tennis, pickleball and a complimentary kids' club.",
    },
    {
      id: 'canyon-ranch',
      src: 'https://www.bergdorfgoodman.com/category/loyalty/canyon-ranch.png',
      title: 'Canyon Ranch',
      desc:
        "The original leader and innovator in the wellness movement, Canyon Ranch's all-inclusive resorts in Tucson, AZ, Lenox, MA, and an intimate retreat in Woodside, CA offer world-class services and experiences from a team of highly trained experts. Discover renowned spa treatments, fitness programs, beauty services, outdoor sports, culinary arts, mind-and-spirit offerings, and more.",
    },
    {
      id: 'new-york-yankees',
      src:
        'https://www.bergdorfgoodman.com/category/loyalty/new-york-yankees.png',
      title: 'New York Yankees',
      desc: 'New York Yankees. Savings on New York Yankees home games.',
    },
    {
      id: 'hotel-casa-del-mar',
      src:
        'https://www.bergdorfgoodman.com/category/loyalty/hotel-casa-del-mar.png',
      title: 'Hotel Casa del Mar',
      desc:
        'Inspired by the grand villas of the Mediterranean and the beachy vibe of Santa Monica, Hotel Casa del Mar invites you to unwind in style. Its vibrant ocean view lobby and Terrazza restaurant are a contemporary contrast to its classic Italian Renaissance exterior.',
    },
    {
      id: 'regent-seven-seas-cruises',
      src:
        'https://www.bergdorfgoodman.com/category/loyalty/regent-seven-seas-cruises.png',
      title: 'Regent Seven Seas Cruises',
      desc:
        "Every journey is as varied as the travelers taking it. So, at Regent Seven Seas Cruises - the only truly all-inclusive luxury cruise line - we offer thousands of Unlimited Shore Excursions to explore however you please when sailing to the over 550 ports of call that we sail to across the globe. Travel with the security and comfort of The World's Most Luxurious Fleet™, enjoying the freedom that comes with our Unrivaled Space at Sea®.",
    },
  ],
};
